import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { Delete2, Get, ImagePost } from '../../../constants/apiMethods';
import { getSpillsList, spillUpload } from '../../../constants/apiRoutes';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import ThreeDotsIcon from '../../../assets/images/FormImages/threeDots.svg';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import EditSvg from '../../../assets/images/FormImages/edit.svg';
import FadedDownloadDoc from '../../../assets/images/FormImages/fadedDownloadIcon.svg';
import EyeIcon from '../../../assets/images/EyeIcon.svg';
import data from '../../../mockData/mockData';
import moment from 'moment';
import SelectDropdownAction from '../../../components/formBuilder/SelectDropdownAction';
import { Modal } from 'react-bootstrap';
import ChangingProgressProvider from '../../../components/ChangingProgressProvider/ChangingProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import DateRangePicker from '../../../components/formBuilder/DateRangePicker';
import './SpillsManagement.css';
import FileUploader from '../../../components/FileUploader';
import Tooltip from '../../../components/Tooltip/index';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import Table from '../../../components/Table';
import FileUploadProgressContainer from '../../../components/FileUpload/FileUploadProgressContainer';
import { ACCESS_TOKEN, CLOSED_STATUS, COMPANY_ADMIN, COMPLIANCE, FAILED_STATUS, IMAGE_JPG_FORMAT, NA_STATUS, NULL_VALUE, OPEN_STATUS, PERMISSIONS, ROLE, STEP_ONE_SUBMITTED_STATUS, SUBMITTED_STATUS, SUCCESS_STATUS, USER, XLSX_FILE_FORMAT } from '../../../constants/Global';
import { HISTORICAL_SPILL_TYPE, NON_REPORTABLE_SPILL_TYPE, RECENT_SPILL_TYPE, REPORTABLE_SPILL_TYPE, SPILL_ID } from '../../../constants/Spills';
import { DRAFT_STATUS } from '../../../constants/Complaints';

const SpillsManagement = () => {

    let permissionsObj;
    const role = localStorage.getItem(ROLE);
    if (role === USER) {
        let permissions = JSON.parse(localStorage.getItem(PERMISSIONS));
        let safetyPermissionObject = permissions.filter(item => item.moduleName.toLowerCase() === COMPLIANCE);
        permissionsObj = {
            dashboardAccess: safetyPermissionObject[0].dashboardChecked,
            exportAccess: safetyPermissionObject[0].exportAccess,
            readAccess: safetyPermissionObject[0].readAccess,
            writeAccess: safetyPermissionObject[0].writeAccess
        }
    }

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const getPage = localStorage.getItem('getPage');
    const token = localStorage.getItem(ACCESS_TOKEN);
    const [page, setPage] = useState(1);
    const [spillsListData, setSpillsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showActive, setShowActive] = useState(getPage === 1 ? false : true);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideChangeStatusSpills, setshowHideChangeStatusSpills] = useState({ show: false, id: '' });
    const [fileData, setFile] = useState('');
    const [showUpload, setShowUpload] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showFileContainer, setShowFileContainer] = useState(false);
    const [showHideUploadContainer, setShowHideUploadContainer] = useState({ show: false });
    const [currentFile, setCurrentFile] = useState('');
    const [showFilters, setFilters] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [clearDateValue, setClearDateValue] = useState(true);
    const [categoryValue, setCategoryValue] = useState('');
    const [spillTypeValue, setSpillTypeValue] = useState('');
    const [spillStatusValue, setSpillStatusValue] = useState('');
    const [recordStatusValue, setRecordStatusValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const columnsTable = [
        { label: 'Spill Name', field: 'name', render: (item) => <Tooltip text={item?.name}>{truncateString(item?.name, 20)}</Tooltip> },
        { label: 'Discovery Date', field: 'date_of_discovery', render: (item) => item?.date_of_discovery ? moment(item?.date_of_discovery).format('MM-DD-YYYY') : 'NA' },
        { label: 'Category', field: 'category', render: (item) => item?.category ? item?.category : 'NA' },
        {
            label: 'Facility Name', field: 'facility_name', render: (item) => {
                const facilityName = item?.facility && !item?.location ? truncateString(item?.facility, 15) : <span>{truncateString(`Others (${item?.location})`, 15)}</span>;
                return (
                    <Tooltip text={item?.facility ? item?.facility : item?.location ? `Others (${item?.location})` : 'NA'}>
                        <span>{item?.facility || item?.location ? facilityName : "NA"}</span>
                    </Tooltip>
                );
            }
        },
        { label: 'Spill Type', field: 'spill_type', render: (item) => item?.spill_type ? item?.spill_type : 'NA' },
        { label: 'Spill Status', field: 'spill_status', render: (item) => item?.spill_status ? item?.spill_status : 'NA' },
        { label: 'Record Status', field: 'record_status', render: (item) => item?.record_status ? item?.record_status : 'NA' },
        showActive && {
            label: 'Actions', field: 'actions', render: (item) => {
                return item?.record_status === DRAFT_STATUS ?
                    <SelectDropdownAction
                        headingData={ThreeDotsIcon}
                        itemData={item}
                        data={data.SpillsActionDraftItems}
                        onDelete={() => setshowHideChangeStatusSpills({ show: true, id: item.id })}
                        onUpdate={() => onUpdateSpill(item)}
                        isImg={true} />
                    :
                    <SelectDropdownAction
                        headingData={ThreeDotsIcon}
                        itemData={item}
                        data={data.SpillsActionItems}
                        onDelete={() => setshowHideChangeStatusSpills({ show: true, id: item.id })}
                        onView={() => onViewSpillDetails(item.id)}
                        onUpdate={() => onUpdateSpill(item)}
                        isImg={true} />
            }
        },
        !showActive && {
            label: 'Actions', field: 'actions', render: (item) => {
                return (
                    <>
                        <img className="editIcn" src={FadedDownloadDoc} />
                        <img src={EyeIcon} className='editIcn cursor' onClick={() => onViewSpillDetails(item.id)} />
                    </>
                )

            }
        }
    ];

    useEffect(() => {
        localStorage.removeItem(STEP_ONE_SUBMITTED_STATUS);
        localStorage.removeItem(SPILL_ID);
        getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page);
    }, [showActive])

    setTimeout(() => {
        localStorage.removeItem('getPage');
    }, 1);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const getSpillsListing = (searchValue, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page) => {
        hideElement();
        setSearchValue(searchValue);
        setCategoryValue(categoryValue);
        setSpillTypeValue(spillTypeValue);
        setSpillStatusValue(spillStatusValue);
        setRecordStatusValue(recordStatusValue);
        setDateRangeValue(dateRangeValue);
        setStartDate(dateRangeValue && dateRangeValue ? dateRangeValue[0] : '');
        setEndDate(dateRangeValue && dateRangeValue ? dateRangeValue[1] : '');
        setLoading(true);
        Get(getSpillsList, token, `?page=${page}&search=${searchValue}&agency=${!showActive}&category=${categoryValue}&spill_type=${spillTypeValue}&spill_status=${spillStatusValue}&record_status=${recordStatusValue}&dod_start=${dateRangeValue && dateRangeValue[0] ? dateRangeValue[0] : ''}&dod_end=${dateRangeValue && dateRangeValue[1] ? dateRangeValue[1] : ''}`, true)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setSpillsList(data.data);
                    setCurrentPage(data.paginator);
                    hideElement();
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(FAILED_STATUS);
                    setLoading(false);
                    setSuccessRes(true);
                }
            });
        hideElement();
    };

    const deleteSpill = () => {
        setSuccessRes(false);
        setLoading(true);
        Delete2(getSpillsList, token, showHideChangeStatusSpills?.id, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                setshowHideChangeStatusSpills({ show: false, id: '' });
                setSuccessRes(true);
                if (data.success) {
                    getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page);
                    setAlertType(SUCCESS_STATUS);
                    setShowSuccessMessage(data.detail);
                }
                if (!data.success) {
                    setAlertType(FAILED_STATUS);
                    setShowSuccessMessage(data.detail);
                }
            });
    }

    const onFileChange = (files) => {
        setShowFileContainer(false);
        setCurrentFile(files[0]);
        setFile(files[0]);
        setShowUpload(false);
        setShowProcessing(true);
        setTimeout(() => {
            setShowProcessing(false);
            setShowFileContainer(true);
        }, 5000)
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const truncateString = (str, maxLength) => {
        if (!str) {
            return '';
        }

        return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
    };

    const handleFileUpload = () => {
        setLoading(true);
        const selectedFile = fileData;
        if (selectedFile) {
            const fileName = selectedFile.name;
            const fileType = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

            if (fileType !== XLSX_FILE_FORMAT) {
                setSuccessRes(true);
                setAlertType(FAILED_STATUS);
                setShowSuccessMessage('Invalid file type. Please select a .xlsx file.');
                setLoading(false);
                return;
            }

            if (fileType === XLSX_FILE_FORMAT) {
                const formData = new FormData();
                formData.append(`file`, fileData);
                ImagePost(spillUpload, token, formData, false)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setShowSuccessMessage(res.detail);
                            setAlertType(SUCCESS_STATUS);
                            setLoading(false);
                            setSuccessRes(true);
                            setShowHideUploadContainer({ show: false });
                        }
                        if (!res.success) {
                            setShowSuccessMessage(res.detail);
                            setAlertType(FAILED_STATUS);
                            setLoading(false);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {

                    });
            }
        }
    }

    const sampleFormat = () => {
        window.open(`${process.env.REACT_APP_SAMPLE_FORMAL_URL}sample_format/SpillSample.xlsx`, '_self')
    }

    const addSpills = () => {
        navigate('/add-spill');
    }

    const pageChange = (page) => {
        setPage(page + 1);
        getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page + 1);
    }

    const onViewSpillDetails = (id) => {
        if (showActive) {
            navigate('/view-spill', { state: { id: id } });
        } else {
            navigate('/view-agency-spill', { state: { id: id } });
        }
    }

    const onUpdateSpill = (item) => {
        if (item?.record_status === SUBMITTED_STATUS) {
            navigate('/update-spill', { state: { id: item.id } });
        } else {
            navigate('/add-spill', { state: { id: item.id, isDraftMode: item.record_status } });
        }
    }

    const clearFilters = () => {
        setCategoryValue('');
        setSpillTypeValue('');
        setSpillStatusValue('');
        setSpillStatusValue('');
        setRecordStatusValue('');
        setStartDate('');
        setEndDate('');
        setDateRangeValue();
        setIsOpen(false);
        setClearDateValue(false);
        setTimeout(() => {
            setClearDateValue(true);
        }, 1)
        getSpillsListing('', '', '', '', '', '', page);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const downloadAsExcelSheet = () => {
        setLoading(true);
        Get(getSpillsList, token, `?page=${page}&is_active=${showActive}&search=${searchValue}&export=${true}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(SUCCESS_STATUS);
                    setSuccessRes(true);
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(FAILED_STATUS);
                    setSuccessRes(true);
                }
            });
    };

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
        const element3 = document.getElementById('dropdownHidden3');
        if (element3) {
            element3.classList.remove('show');
        }
        const element4 = document.getElementById('dropdownHidden4');
        if (element4) {
            element4.classList.remove('show');
        }
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={5000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-6">
                        <div> <span className="head_1 cursor" onClick={() => { navigate('../spills-dashboard') }}>Spills Dashboard</span> / <span className="head_3">Spill Management</span></div>
                        <div className="head_2">Spill Management</div>
                    </div>
                    <div className="col-md-6 text-right">
                        {((role === COMPANY_ADMIN) || (role === USER && (permissionsObj?.writeAccess))) &&
                            <>
                                <button className='btn-cancel border-0' onClick={sampleFormat}>Sample Format</button>
                                <button className='btn-add-data mr-15 mt-0' onClick={() => { setShowHideUploadContainer({ show: true }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); }}>Bulk Upload</button>
                                <button className='onboard_btn set-onbBtn-wd border-0' onClick={addSpills}>Add Spill</button></>
                        }
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-10">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Internal Spills</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Agency Spills</p>

                        </div>
                    </div>

                    <div className="col-md-2 text-right">
                        <button className='export_btn' onClick={downloadAsExcelSheet}>
                            <img src={EditSvg} alt='editImg' /> Export
                        </button>
                    </div>
                </div>

                <div className="spill_filter_options">
                    {showFilters ? <><div className="spill_filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${categoryValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Category
                                <img className="ml-8" src={categoryValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, 'Reportable', spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page) }}>
                                    <div className="">
                                        {categoryValue === REPORTABLE_SPILL_TYPE ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${categoryValue === REPORTABLE_SPILL_TYPE ? 'selected-filter-color ml-8' : ''}`}>Reportable</label>
                                    </div>
                                </a>
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, NON_REPORTABLE_SPILL_TYPE, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page) }}>
                                    <div className="">
                                        {categoryValue === NON_REPORTABLE_SPILL_TYPE ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${categoryValue === NON_REPORTABLE_SPILL_TYPE ? 'selected-filter-color ml-8' : ''}`}>Non-Reportable</label>
                                    </div>
                                </a>
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getSpillsListing(searchValue, '', spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page) }}>Clear</button>
                                </div>
                            </div>

                            <button className={`btn ${spillTypeValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Spill Type
                                <img className="ml-8" src={spillTypeValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden2' aria-labelledby="dropdownMenuButton1">
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, categoryValue, 'Historical', spillStatusValue, recordStatusValue, dateRangeValue, page) }}>
                                    <div className="">
                                        {spillTypeValue === HISTORICAL_SPILL_TYPE ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${spillTypeValue === HISTORICAL_SPILL_TYPE ? 'selected-filter-color ml-8' : ''}`}>Historical</label>
                                    </div>
                                </a>
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, categoryValue, 'Recent', spillStatusValue, recordStatusValue, dateRangeValue, page) }}>
                                    <div className="">
                                        {spillTypeValue === RECENT_SPILL_TYPE ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${spillTypeValue === RECENT_SPILL_TYPE ? 'selected-filter-color ml-8' : ''}`}>Recent</label>
                                    </div>
                                </a>
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getSpillsListing(searchValue, categoryValue, '', spillStatusValue, recordStatusValue, dateRangeValue, page) }}>Clear</button>
                                </div>
                            </div>

                            <button className={`btn dropdown-toggle ${spillStatusValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Spill Status
                                <img className="ml-8" src={spillStatusValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden3' aria-labelledby="dropdownMenuButton1">
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, 'Open', recordStatusValue, dateRangeValue, page) }}>
                                    <div className="">
                                        {spillStatusValue === OPEN_STATUS ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${spillStatusValue === OPEN_STATUS ? 'selected-filter-color ml-8' : ''}`}>Open</label>
                                    </div>
                                </a>
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, 'Closed', recordStatusValue, dateRangeValue, page) }}>
                                    <div className="">
                                        {spillStatusValue === CLOSED_STATUS ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${spillStatusValue === CLOSED_STATUS ? 'selected-filter-color ml-8' : ''}`}>Closed</label>
                                    </div>
                                </a>
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, '', recordStatusValue, dateRangeValue, page) }}>Clear</button>
                                </div>
                            </div>

                            <button className={`btn dropdown-toggle ${recordStatusValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Record Status
                                <img className="ml-8" src={recordStatusValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden4' aria-labelledby="dropdownMenuButton1">
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, 'Draft', dateRangeValue, page) }}>
                                    <div className="">
                                        {recordStatusValue === DRAFT_STATUS ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${recordStatusValue === DRAFT_STATUS ? 'selected-filter-color ml-8' : ''}`}>Draft</label>
                                    </div>
                                </a>
                                <a className="dropdown-item cursor" onClick={(e) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, 'Submitted', dateRangeValue, page) }}>
                                    <div className="">
                                        {recordStatusValue === SUBMITTED_STATUS ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${recordStatusValue === SUBMITTED_STATUS ? 'selected-filter-color ml-8' : ''}`}>Submitted</label>
                                    </div>
                                </a>
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, '', dateRangeValue, page) }}>Clear</button>
                                </div>
                            </div>

                            <button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${dateRangeValue ? 'selected-filter-color' : ''}`}>
                                {dateRangeValue ? startDate + ' - ' + endDate : 'Discovery Date Range'}
                                <img className="ml-8" src={dateRangeValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpen && (
                                <ul className="spill-datepicker">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateString, page) }} onClear={() => { setDateRangeValue(); toggleDropdown(); getSpillsListing(searchValue, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, '', page) }} />}
                                </ul>
                            )}
                        </div>
                    </div>

                        <div className="spill_clear_filter">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by typing Spill Name, Facility Name" onChange={(event) => { getSpillsListing(event.target.value, categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page); setSearchValue(event.target.value) }} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); getSpillsListing('', categoryValue, spillTypeValue, spillStatusValue, recordStatusValue, dateRangeValue, page); setSearchValue(''); }} />
                        </div>}

                </div>

                <Table columns={columnsTable} data={spillsListData} onPageChange={pageChange} currentPage={currentPage} />


            </div>

            <ConfirmationModal
                show={showHideChangeStatusSpills.show}
                onHide={() => setshowHideChangeStatusSpills({ show: false, id: '' })}
                onConfirm={deleteSpill}
                message="Are you sure you would like to delete this Spill?" />

            <Modal show={showHideUploadContainer.show} dialogClassName="uploadModal" onHide={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>
                <Modal.Header className="sideOnRight" closeButton>
                    <div className='inputFieldHead'>Upload File</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0">
                    {showUpload && <FileUploader onFileUpload={onFileChange} />
                    }

                    {!showUpload && showProcessing && (
                        <FileUploadProgressContainer
                            type='imageProcessing'
                            convertBytesToKb={convertBytesToKb}
                            currentFile={currentFile}
                            setShowUpload={setShowUpload}
                            setFile={setFile}
                            setCurrentFile={setCurrentFile}>
                            <ChangingProgressProvider values={[0, 25, 50, 75, 100]}>
                                {percentage => (
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                )}
                            </ChangingProgressProvider>
                        </FileUploadProgressContainer>
                    )}

                    {!showUpload && showFileContainer && (
                        <FileUploadProgressContainer
                            type='uploadedImage'
                            convertBytesToKb={convertBytesToKb}
                            currentFile={currentFile}
                            setShowUpload={setShowUpload}
                            setFile={setFile}
                            setCurrentFile={setCurrentFile}>
                            <img src={DownloadCompleteImg} />
                        </FileUploadProgressContainer>
                    )}

                    <div className="modal-footer mr-48">
                        <button type="button" className="btn-cancel-popup" onClick={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>Cancel</button>
                        <button type="button" className="btn-upload border-0" onClick={handleFileUpload} disabled={fileData === '' || showProcessing}>Upload</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default SpillsManagement