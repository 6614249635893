import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Delete2, Get, ImagePost } from '../../../constants/apiMethods';
import { getContractorList, getStateList, manHours, manHoursUpload } from '../../../constants/apiRoutes';
import data from '../../../mockData/mockData';
import EditIcon from '../../../assets/images/FormImages/editIcon.svg';
import DeleteIcon from '../../../assets/images/FormImages/deleteIcon.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import UploadIcon from '../../../assets/images/FormImages/upload.svg';
import CrossImg from '../../../assets/images/crossImage.svg';
import DeleteImg from '../../../assets/images/deleteImg.svg';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { Modal } from 'react-bootstrap';
import './ManHoursListing.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import ChangingProgressProvider from '../../../components/ChangingProgressProvider/ChangingProgressProvider';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import Table from '../../../components/Table';
import FileUploadProgressContainer from '../../../components/FileUpload/FileUploadProgressContainer';
import FileUploader from '../../../components/FileUploader';

const ManHoursListing = () => {
    const navigate = useNavigate();
    const inputFile = useRef(null);
    const isContractor = localStorage.getItem('isContractor');
    const [showEmployee, setShowEmployee] = useState(isContractor != 1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [manHoursListData, setManHoursList] = useState([]);
    const [contractorListData, setContractorList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideDelete, setshowHideDelete] = useState({ show: false, viewData: {} });
    const [yearsData, setYearsData] = useState([]);
    const [showFilters, setFilters] = useState(true);
    const [monthValue, setMonthValue] = useState(0);
    const [yearValue, setYearValue] = useState(0);
    const [contractorValue, setContractorValue] = useState(0);
    const [statesListData, setStatesList] = useState([]);
    const [selectedStatesId, setSelectedStatesId] = useState([]);
    const [selectedStateString, setSelectedStateString] = useState('');
    const [filesArr, setFilesArr] = useState([]);
    const [fileData, setFile] = useState('');
    const [showUpload, setShowUpload] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false); 
    const [showFileContainer, setShowFileContainer] = useState(false);
    const [showHideUploadContainer, setShowHideUploadContainer] = useState({ show: false });
    const [currentFile, setCurrentFile] = useState('');
    const [actualStateIds, setActualStateIds] = useState([]);
    const token = localStorage.getItem("access_token");

    const columnsTable = [
        { label: 'Month & Year', field: 'complaint_name', render: (item) => item?.year ? `${getMonthName(item?.month)}, ${item?.year}` : 'NA' },
        { label: 'State', field: 'date_of_complaint', render: (item) => item?.state ? item?.state : 'NA' },
        !showEmployee && { label: 'Contractor Name', field: 'complaint_type', render: (item) => item?.contractor ? item?.contractor : 'NA' },
        { label: 'Man Hours', field: 'complaint_type', render: (item) => item?.man_hours ? item?.man_hours : 'NA' },
        {
            label: 'Actions', field: 'actions', render: (item) => {
                return (
                    <><img className="editIcn cursor" src={EditIcon} onClick={() => updateSelectedRecord(item)} />
                        <img className="editIcn cursor" src={DeleteIcon} onClick={() => setshowHideDelete({ show: true, viewData: item })} />
                    </>)
            }
        }
    ];

    useEffect(() => {
        setPage(1);
        setMonthValue(0);
        setYearValue(0);
        setContractorValue(0);
        getStatesList();
        getManHoursListing(1, 0, 0, 0, '');
        setYearsData(getPreviousYears());
    }, [showEmployee])

    setTimeout(() => {
        localStorage.removeItem('isContractor');
    }, 10)

    const getManHoursListing = (page, month, year, contractor, selectedStateId) => {
        hideElement();
        setLoading(true);
        setMonthValue(month);
        setYearValue(year);
        setContractorValue(contractor);
        let selectedStateStr = '';
        selectedStateStr = selectedStateId.toString();
        setSelectedStateString(selectedStateStr);
        showEmployee && setContractorValue(0);
        showEmployee && (contractor = 0);
        Get(manHours, token, `?page=${page}&is_employee=${showEmployee}&month=${month}&year=${year}&contractor=${contractor}&state=${selectedStateStr}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setManHoursList(data?.data);
                    setCurrentPage(data?.pagination_info);
                    !showEmployee && getContractorListing();
                    setActualStateIds(selectedStateId);
                }
            });
    };

    const getContractorListing = () => {
        Get(getContractorList, token, '?min=true', false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setContractorList(data?.data);
                }
            });
    };

    const getStatesList = () => {
        Get(getStateList + `?is_active=true&min=true&sort=name`, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const statesListData = data.data?.map((resp) => {
                        return { ...resp, checked: false }
                    })
                    setStatesList(statesListData);
                }
            });
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const deleteDataFromList = () => {
        setSuccessRes(false);
        setLoading(true);
        Delete2(manHours, token, showHideDelete?.viewData?.id, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    getManHoursListing(1, monthValue, yearValue, contractorValue, selectedStatesId);
                    setLoading(false);
                    setSuccessRes(true);
                    setAlertType('success');
                    setshowHideDelete({ show: false, viewData: '' })
                    setShowSuccessMessage(data.detail);
                }
            });
    };

    const pageChange = (page) => {
        getManHoursListing(page + 1, monthValue, yearValue, contractorValue, selectedStatesId);
    }

    const addManHours = () => {
        navigate('/add-man-hours');
    }

    const getPreviousYears = () => {
        const currentYear = new Date().getFullYear();
        const previousYears = [];

        for (let i = 0; i < 4; i++) {
            previousYears.push(currentYear - i);
        }

        return previousYears.reverse();
    }

    const getMonthName = (monthName) => {
        const monthToShow = data.MonthData.filter((resp) => resp?.id == monthName)[0]?.label;
        return monthToShow;
    }

    const onChange = (e, index) => {
        const updatedData = [...statesListData];
        updatedData[index].checked = e.target.checked;
        setStatesList(updatedData);
        let selectedIds = statesListData?.map((resp) => {
            if (resp.checked) {
                return resp.id
            } else {
                return ''
            }
        })
        selectedIds = selectedIds.filter(x => x != '');
        setSelectedStatesId(selectedIds);
    }

    const handleStateDropdown = () => {
        setSelectedStatesId(actualStateIds);
        const updatedArray = statesListData.map(item => ({
            ...item,
            checked: actualStateIds.includes(item.id)
        }));
        setStatesList(updatedArray);
    }

    const selectAll = () => {
        return statesListData.every(item => item.checked === true);
    }

    const onAllFeaturesSelect = (e) => {
        const updatedData = statesListData.map(item => ({
            ...item,
            [e.target.name]: e.target.checked,
        }));
        // Update the state with the new array
        setStatesList(updatedData);
        const selectedIds = statesListData?.map((resp) => {
            return resp.id
        })
        if (e.target.checked) {
            setSelectedStatesId(selectedIds);
        } else {
            setSelectedStatesId([]);
        }
    }

    const onClearFeature = () => {
        const updatedData = statesListData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setStatesList(updatedData);
        setSelectedStatesId([]);
        getManHoursListing(page, monthValue, yearValue, contractorValue, '');

    }

    const clearFilters = () => {
        setMonthValue(0);
        setYearValue(0);
        setContractorValue(0);
        const updatedData = statesListData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setStatesList(updatedData);
        setSelectedStatesId([]);
        getManHoursListing(1, 0, 0, 0, '');
    }

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
        const element3 = document.getElementById('dropdownHidden3');
        if (element3) {
            element3.classList.remove('show');
        }
    }

    const sampleFormat = () => {
        window.open(`${process.env.REACT_APP_SAMPLE_FORMAL_URL}sample_format/manhours.xlsx`, '_self')
    }

    const updateSelectedRecord = (item) => {
        navigate('/update-man-hours', { state: { id: item?.id } });
    }

    const onFileChange = (files) => {
        setShowFileContainer(false);
        setCurrentFile(files[0]);
        setFile(files[0]);
        setFilesArr(files[0]);
        setShowUpload(false);
        setShowProcessing(true);
        setTimeout(() => {
            setShowProcessing(false);
            setShowFileContainer(true);
        }, 5000)
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const handleFileUpload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append(`file`, fileData);
        ImagePost(manHoursUpload, token, formData, false)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setShowSuccessMessage(res.detail);
                    setAlertType('success');
                    setLoading(false);
                    setSuccessRes(true);
                    setShowHideUploadContainer({ show: false });
                }
                if (!res.success) {
                    setShowSuccessMessage(res.detail);
                    setAlertType('failed');
                    setLoading(false);
                    setSuccessRes(true);
                }
            })
            .catch((error) => {

            });
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-6">
                        <div className="head_2">Man Hours</div>
                    </div>
                    <div className="col-md-6 text-right">
                        <button className='btn-cancel border-0' onClick={sampleFormat}>Sample Format</button>
                        <button className='btn-add-data mr-15 mt-0' onClick={() => { setShowHideUploadContainer({ show: true }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); }}>Bulk Upload</button>
                        <button className='onboard_btn border-0' onClick={addManHours}>Add Man Hours</button>
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-10">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showEmployee ? 'active-plan' : 'plans'}`} onClick={() => setShowEmployee(true)}>Employee</p>
                            <p className={`ml-20 cursor ${!showEmployee ? 'active-plan' : 'plans'}`} onClick={() => setShowEmployee(false)}>Contractor</p>
                        </div>
                    </div>
                </div>

                <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${yearValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Years
                                <img className="ml-8" src={yearValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton2">
                                {yearsData.map((parts, i) => (
                                    <a className="dropdown-item cursor" onClick={(e) => { getManHoursListing(page, monthValue, parts, contractorValue, selectedStatesId); }}>
                                        <div className="">
                                            {yearValue == parts ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${yearValue == parts ? 'selected-filter-color ml-8' : ''}`}>{parts}</label>
                                        </div>
                                    </a>
                                ))}
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getManHoursListing(page, monthValue, 0, contractorValue, selectedStatesId) }}>Clear</button>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${monthValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Month
                                <img className="ml-8" src={monthValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden2' aria-labelledby="dropdownMenuButton2">
                                {data.MonthData.map((parts, i) => (
                                    <a className="dropdown-item cursor" onClick={(e) => { getManHoursListing(page, parts?.id, yearValue, contractorValue, selectedStatesId); }}>
                                        <div className="">
                                            {monthValue == parts?.id ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${monthValue == parts?.id ? 'selected-filter-color ml-8' : ''}`}>{parts?.label}</label>
                                        </div>
                                    </a>
                                ))}
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getManHoursListing(page, 0, yearValue, contractorValue, selectedStatesId) }}>Clear</button>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${selectedStateString != '' ? 'selected-filter-color' : ''}`} onClick={handleStateDropdown} type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                State
                                <img className="ml-8" src={selectedStateString != '' ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden3' aria-labelledby="dropdownMenuButton3">
                                <a className="dropdown-item" href="#">
                                    <div className="form-check">
                                        <label className="form-check-label selectAll" for="Checkme1">Select All</label>
                                        <input className="form-check-input" type="checkbox" id="Checkme1" name='checked' checked={selectAll()} onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {statesListData.map((item, i) => (
                                    <a className="dropdown-item" href="#">
                                        <div className="form-check">
                                            <label className="form-check-label" for={`Checkme ${i}`}>{item?.name}</label>
                                            <input className="form-check-input" type="checkbox" value={item.id} id={`Checkme ${i}`} name={item.name} checked={item?.checked} onChange={(e) => onChange(e, i, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={() => { getManHoursListing(1, monthValue, yearValue, contractorValue, selectedStatesId) }}>Done</button>
                                </div>
                            </div>
                        </div>

                        {!showEmployee && <div className="dropdown">
                            <button className={`btn dropdown-toggle ${contractorValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Contractor Name
                                <img className="ml-8" src={contractorValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden3' aria-labelledby="dropdownMenuButton2">
                                {contractorListData.map((parts, i) => (
                                    <a className="dropdown-item cursor" onClick={(e) => { getManHoursListing(page, monthValue, yearValue, parts?.id, selectedStatesId); }}>
                                        <div className="">
                                            {contractorValue == parts?.id ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${contractorValue == parts?.id ? 'selected-filter-color ml-8' : ''}`}>{parts?.name}</label>
                                        </div>
                                    </a>
                                ))}
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getManHoursListing(page, monthValue, yearValue, 0, selectedStatesId) }}>Clear</button>
                                </div>
                            </div>
                        </div>}

                    </div><div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                        </div></> : ''}

                </div>

                <Table columns={columnsTable} data={manHoursListData} onPageChange={pageChange} currentPage={currentPage} />;

                <ConfirmationModal
                    show={showHideDelete.show}
                    onHide={() => setshowHideDelete({ show: false, viewData: '' })}
                    onConfirm={deleteDataFromList}
                    message={`Are you sure you would like to delete Man hours for ${getMonthName(showHideDelete?.viewData?.month)}, ${showHideDelete?.viewData?.year} ?`} />

                <Modal show={showHideUploadContainer.show} dialogClassName="uploadModal" onHide={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>
                    <Modal.Header className="sideOnRight" closeButton>
                        <div className='inputFieldHead'>Upload File</div>
                    </Modal.Header>
                    <Modal.Body className="auth_modal_header border-0">

                        {showUpload &&
                            <FileUploader onFileUpload={onFileChange} />
                        }
                        {!showUpload && showProcessing && (
                            <FileUploadProgressContainer
                                type='imageProcessing'
                                convertBytesToKb={convertBytesToKb}
                                currentFile={currentFile}
                                setShowUpload={setShowUpload}
                                setFile={setFile}
                                setCurrentFile={setCurrentFile}>
                                <ChangingProgressProvider values={[0, 25, 50, 75, 100]}>
                                    {percentage => (
                                        <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                    )}
                                </ChangingProgressProvider>
                            </FileUploadProgressContainer>
                        )}

                        {!showUpload && showFileContainer && (
                            <FileUploadProgressContainer
                                type='uploadedImage'
                                convertBytesToKb={convertBytesToKb}
                                currentFile={currentFile}
                                setShowUpload={setShowUpload}
                                setFile={setFile}
                                setCurrentFile={setCurrentFile}>
                                <img src={DownloadCompleteImg} />
                            </FileUploadProgressContainer>
                        )}
                        <div className="modal-footer">
                            <button type="button" className="btn-cancel-popup" onClick={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>Cancel</button>
                            <button type="button" className="btn-upload border-0" onClick={handleFileUpload} disabled={fileData == '' || showProcessing}>Upload</button>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>

        </div>
    )
}

export default ManHoursListing;