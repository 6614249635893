import React, { useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router";

const SelectDropdownAction = ({ headingData, data, itemData, isImg, handler, onDelete, onDuplicate, onDownloadAsExcelSheet, onDownloadAsWordDoc, onUpdate, onActivate, onDeactivate, onView }) => {
    const navigate = useNavigate();
    useEffect(() => {
	}, []);
    const handleClick = (item) => {
        if (item.name == 'View' 
        // && itemData.type == 'incident'
        ) {
            onView();
            // navigate('/incident-details', {state: {id: itemData.id}});
        }
        if (item.name == 'Update' 
        // && itemData.type == 'event'
        ) {
            onUpdate();
            // navigate('/event-details', {state: {id: itemData.id}});
        }
        if (item.name === 'Delete') {
            onDelete();
        }
        if (item.name === 'Duplicate') {
            onDuplicate();
        }
        if (item.name === 'Download as Excel Sheet') {
            onDownloadAsExcelSheet();
        }
        if (item.name === 'Download as CSV') {
            onDownloadAsWordDoc();
        }
        if (item.name === 'Activate') {
            onActivate();
        }
        if (item.name === 'Deactivate') {
            onDeactivate();
        }
        if (item.name === 'Update') {
            onUpdate();
        }
    }
    return (
        <>
            {isImg && <img className="editIcn cursor" src={headingData} id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />}
            {!isImg && <button className='export_btn' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {headingData.icon} {headingData.label}
            </button>}

            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {data && data.map((item, index) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="dropdown-item dwnld cursor" key={index} onClick={() => { handleClick(item) }}>{item.icon} {item.name}</a>
                ))}
            </div>
        </>
    )
}

export default SelectDropdownAction;