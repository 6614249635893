import React from "react";
import './css/Global.css';
import Routes from "./routes/index";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-9JEYQFFR8E"

ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
      <Routes />
  );
}

export default App;