import React, { useEffect, useState } from 'react';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import EditIcon from '../../../assets/images/FormImages/editIcon.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import DateRangePicker from "../../../components/formBuilder/DateRangePicker";
import data from '../../../mockData/mockData';
import "./SubscriptionPlan.css";
import OnboardingData from '../../../mockData/OnboardingData/mockData';
import { useNavigate } from 'react-router';
import { modules, subscription } from '../../../constants/apiRoutes';
import { Delete, Delete2, Get, Put } from '../../../constants/apiMethods';
import LoadingSpinner from '../../../components/Loader';
import { Modal } from "react-bootstrap";
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import moment from 'moment';

const SubscriptionPlan = () => {
    const navigate = useNavigate();
    const [showFilters, setFilters] = useState(true);
    const [showActive, setShowActive] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [subscriptionListData, setSubscriptionList] = useState([]);
    const [modulesListData, setModulesList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showHideChangeStatusPlan, setshowHideChangeStatusPlan] = useState({ show: false, id: '' });
    const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideModulesName, setShowHideModulesNameModal] = useState({ show: false, modulesData: [], planData: {} });
    const [selectedModulesId, setSelectedModulesId] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState(['', '']);
    const [clearDateValue, setClearDateValue] = useState(true);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [selectedModuleString, setSelectedModuleString] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [actualModuleIds, setActualModuleIds] = useState([]);
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        getSubscriptionList('', currentPage, '', ['', '']);
        getModulesList();
    }, [showActive]);

    const getSubscriptionList = (searchValue, page, selectedModulesId, dateRangeValue) => {
        hideElement();
        if (dateRangeValue) {
            setDateRangeValue(dateRangeValue);
            setStartDate(dateRangeValue[0]);
            setEndDate(dateRangeValue[1]);
        }
        let selectedModuleStr = '';
        selectedModuleStr = selectedModulesId.toString();
        setSelectedModuleString(selectedModuleStr);
        setLoading(true);
        Get(subscription, token, `?page=${page}&is_active=${showActive}&name=${searchValue}&modules=${selectedModuleStr}&start_date=${dateRangeValue[0]}&end_date=${dateRangeValue[1]}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data) {
                    setSubscriptionList(data.data);
                    setActualModuleIds(selectedModulesId);
                }
            });
    };

    const getModulesList = () => {
        Get(modules, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setModulesList(data.data.data);
                    const modulesListData = data.data?.map((resp) => {
                        return { ...resp, checked: false }
                    })
                    setModulesList(modulesListData);
                }
            });
    };

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const changePlanStatus = () => {
        setShowSuccessNotifier(false);
        setSuccessRes(false);
        setLoading(true);
        const body = {
            is_active: true
        }
        if (showActive) {
            Delete2(subscription, token, showHideChangeStatusPlan?.id, false)
                .then((response) => response)
                .then((data) => {
                    if (data) {
                        getSubscriptionList(searchValue, 1, selectedModulesId, dateRangeValue);
                        setLoading(false);
                        setshowHideChangeStatusPlan({ show: false, id: '' });
                        setShowSuccessNotifier(true);
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(`Plan Deactivated successfully`);
                    }
                });
        } else {
            Put(`${subscription}/${showHideChangeStatusPlan.id}/`, token, body, false)
                .then((response) => response)
                .then((data) => {
                    if (data) {
                        getSubscriptionList(searchValue, 1, selectedModulesId, dateRangeValue);
                        setLoading(false);
                        setshowHideChangeStatusPlan({ show: false, id: '' });
                        setShowSuccessNotifier(true);
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(`Plan Activated successfully`);
                    }
                });
        }
    }

    const onChange = (e, index) => {
        const updatedData = [...modulesListData];
        updatedData[index].checked = e.target.checked;
        setModulesList(updatedData);
        let selectedIds = modulesListData?.map((resp) => {
            if (resp.checked) {
                return resp.id
            } else {
                return ''
            }
        })
        selectedIds = selectedIds.filter(x => x != '');
        setSelectedModulesId(selectedIds);
        showElement();
    }

    const selectAll = () => {
        return modulesListData.every(item => item.checked === true);
    }


    const onAllFeaturesSelect = (e) => {
        const updatedData = modulesListData.map(item => ({
            ...item,
            [e.target.name]: e.target.checked,
        }));
        // Update the state with the new array
        setModulesList(updatedData);
        const selectedIds = modulesListData?.map((resp) => {
            return resp.id
        })
        if (e.target.checked) {
            setSelectedModulesId(selectedIds);
        } else {
            setSelectedModulesId([]);
        }
        showElement();
    }

    const onClearFeature = () => {
        const updatedData = modulesListData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setModulesList(updatedData);
        setSelectedModulesId([]);
        getSubscriptionList(searchValue, 1, [], dateRangeValue);
    }

    const createEditPlan = (id) => {
        // navigate('/create-subscription-plan');
        navigate('/create-subscription-plan', { state: { id: id, is_active: showActive } });
    }

    const clearFilters = () => {
        setDateRangeValue('');
        setStartDate('');
        setEndDate('');
        const removeAllChecked = modulesListData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setModulesList(removeAllChecked);
        setSelectedModuleString('');
        setSelectedModulesId([]);
        setIsOpen(false);
        setClearDateValue(false);
        setTimeout(() => {
            setClearDateValue(true);
        }, 1)
        getSubscriptionList(searchValue, currentPage, [], ['', '']);
    }

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
    }

    const showElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.add('show');
        }
    }

    const handleStateDropdown = () => {
        setSelectedModulesId(actualModuleIds);
        const updatedArray = modulesListData.map(item => ({
            ...item,
            checked: actualModuleIds.includes(item.id)
        }));
        setModulesList(updatedArray);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-10">
                        <div className="head_2">Subscription Plan</div>
                    </div>
                    <div className="col-md-2 text-right">
                        <button className='report_btn border-0' onClick={() => createEditPlan()}>Create New Plan</button>
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-12">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active Plans</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated Plans</p>
                        </div>
                    </div>
                </div>

                <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${selectedModuleString != '' ? 'selected-filter-color' : ''}`} onClick={handleStateDropdown} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Module Type
                                {/* <img className="ml-8" src={DropCollapseDown} alt="dropdownIcon" /> */}
                                <img className="ml-8" src={selectedModuleString != '' ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton2">
                                <a className="dropdown-item">
                                    <div className="form-check">
                                        <label className="form-check-label selectAll" for="Checkme1">Select All</label>
                                        <input className="form-check-input" type="checkbox" id="Checkme1" name='checked' checked={selectAll()} onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {modulesListData.map((item, i) => (
                                    <a className="dropdown-item" href="#">
                                        <div className="form-check">
                                            <label className="form-check-label" for={`Checkme ${i}`}>{item?.name}</label>
                                            <input className="form-check-input" type="checkbox" value={item.id} id={`Checkme ${i}`} name={item.name} checked={item?.checked} onChange={(e) => onChange(e, i, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={() => { getSubscriptionList(searchValue, 1, selectedModulesId, dateRangeValue) }}>Done</button>
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${startDate && endDate != '' ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {startDate && endDate ? startDate + ' - ' + endDate : 'Plan creation Date Range'}
								<img className="ml-8" src={startDate && endDate ? DropCollapseDownSelected : DropCollapseDown}  alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu showDateRange" aria-labelledby="dropdownMenuButton3">
                                <div className="datePickDown">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => getSubscriptionList(searchValue, 1, selectedModulesId, dateString)} />}
                                </div>
                            </div>
                        </div> */}

                        <div className="dropdown-container">
                            <button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${startDate && endDate != '' ? 'selected-filter-color' : ''}`}>
                                {startDate && endDate ? startDate + ' - ' + endDate : 'Plan creation Date Range'}
                                <img className="ml-8" src={startDate && endDate ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpen && (
                                <ul className="dropdown-list">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getSubscriptionList(searchValue, 1, selectedModulesId, dateString) }} onClear={() => { setStartDate(''); setEndDate(''); toggleDropdown(); getSubscriptionList(searchValue, 1, selectedModulesId, ['', '']) }} />}
                                </ul>
                            )}
                        </div>

                    </div><div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by typing Plan" onChange={(event) => getSubscriptionList(event.target.value, currentPage, selectedModulesId, dateRangeValue)} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); setSearchValue(''); getSubscriptionList(searchValue, currentPage, selectedModulesId, dateRangeValue); }} />
                        </div>}

                </div>

                {(subscriptionListData?.length > 0) ?
                    <>
                        {subscriptionListData.map((item, index) => {
                            return (
                                <div className='container_div'>

                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='plan-name'>{item?.name}</div>
                                        </div>
                                        <div className='col-md-6 text-right'>
                                            <div className='change-plan-status cursor' onClick={() => { setshowHideChangeStatusPlan({ show: true, id: item?.id }) }}>{showActive ? 'Deactivate' : 'Activate'}</div>
                                            <img className="editIcn edit-placement cursor mt-1" src={EditIcon} onClick={() => createEditPlan(item?.id)} />
                                        </div>
                                    </div>

                                    <div className='row mt-30'>
                                        <div className='col-md-3'>
                                            <div className='inputFieldHead'>Duration of the Plan</div>
                                            <p className="text-content">{item?.duration} {item.duration == 1 ? 'month' : 'months'}</p>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='inputFieldHead'>Created On</div>
                                            <p className="text-content">{item?.created_on !== null ? moment(item?.created_on).format('MM-DD-YYYY') : 'NA'}</p>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='inputFieldHead'>Last Updated On</div>
                                            <p className="text-content">{item?.updated_on !== null ? moment(item?.updated_on).format('MM-DD-YYYY') : 'NA'}</p>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='inputFieldHead'>Created By</div>
                                            <p className="text-content">{item?.created_by?.name}</p>
                                        </div>
                                    </div>

                                    <div className='row mt-30'>
                                        <div className='col-md-12'>
                                            <div className='inputFieldHead'>Modules of the Plan</div>
                                            <div className='flex'>
                                                <p className="text-content mt-16"> {item?.modules.slice(0, 6).map((item, index) => (
                                                    <span key={index}>
                                                        <span className="feature-wrapper">{item.name}</span>
                                                    </span>
                                                ))}</p>
                                                {item?.modules?.length > 6 && <p className='additional-features cursor mt-10' onClick={() => setShowHideModulesNameModal({ show: true, modulesData: item.modules, planData: item })}>+ {item?.modules?.length - 6} {item?.modules?.length < 1 ? 'Features' : 'Feature'}</p>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            );
                        })}
                    </>
                    :
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center no-data">No Data Found</div>
                        </div>
                    </div>}

            </div>

            <Modal show={showHideChangeStatusPlan.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusPlan({ show: false, id: '' })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} this Plan?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusPlan({ show: false, id: '' })}>No</button>
                        <button type="button" className="btn-yes" onClick={changePlanStatus}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <Modal show={showHideModulesName.show} dialogClassName="modulesModal" onHide={() => setShowHideModulesNameModal({ show: false, modulesData: [], planData: {} })}>
                <Modal.Header className="sideOnRight" closeButton>
                    <div className='feature-modal-head'>Modules of {showHideModulesName?.planData?.name}</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0">
                    <p className="text-content single-plan-container"> {showHideModulesName.modulesData.map((item, index) => (
                        <span key={index}>
                            <span className="feature-wrapper plan-label">{item.name}</span>
                        </span>
                    ))}</p>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default SubscriptionPlan;