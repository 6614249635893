import React from "react";
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import './module.formBuilder.css';

const CheckboxSelect = (props) => {

    const {
        index,
        data,
        name,
        colSize,
        editLabel,
        onChange,
        checkedValues
    } = props;
    return (
        <Row>
            {data?.map((item, checkboxIndex) => (
                <Col md={colSize} key={checkboxIndex}>
                    <Form>
                        <Form.Group controlId={`checkbox-${index}-${checkboxIndex}`}>
                            <Form.Check
                                className="custom-checkbox"
                                inline
                                key={item.id}
                                label={item.label}
                                name={item.name}
                                type={'checkbox'}
                                checked={checkedValues && checkedValues.includes(item.id)}
                                id={`checkbox-${index}-${checkboxIndex}`}
                                disabled={editLabel ? editLabel : false}
                                onChange={() => onChange(item, name)}
                            />
                        </Form.Group>
                    </Form>
                </Col>
            ))}
        </Row>
    )
}

export default CheckboxSelect;