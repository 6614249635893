import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../../../../components/Loader";
import AlertNotifier from "../../../../../components/Notifications/AlertNotifier";
import data from '../../../../../mockData/mockData';
import errorIcon from "../../../../../assets/images/error_icon.svg";
import DownloadAlertImg from '../../../../../assets/images/downloadImageAlert.svg';
import DownloadCompleteImg from '../../../../../assets/images/downloadComplete.svg';
import { useForm } from "react-hook-form";
import { Delete, Get, ImagePost, Post, Put } from "../../../../../constants/apiMethods";
import { getSpillsList, spill, spillInvestigation, uploadImageSpill } from "../../../../../constants/apiRoutes";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import './InvestigationSpillDetails.css'
import CheckboxSelect from "../../../../../components/formBuilder/CheckboxSelect";
import FileUploader from "../../../../../components/FileUploader";
import EditUploadedFiles from "../../../../../components/FileUpload/EditUploadedFiles";
import { CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT, FAILED_STATUS, IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, PDF_FORMAT, SUBMITTED_STATUS, SUCCESS_STATUS, TOO_LARGE_FILE, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT } from "../../../../../constants/Global";
import FilePreviewFinal from "../../../../../components/FileUpload/FilePreviewFinal";

const SpillInvestigationDetails = ({ editMode, responseFromDetail, isDraftMode }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [successDetail, setSuccessDetail] = useState('');
    const [images, setImages] = useState([]);
    const [conclusionArr, setConclusionArr] = useState([]);
    const [filesArr, setFilesArr] = useState([]);
    const inputFile = useRef(null);
    const [imageUserId, setImageUserId] = useState(responseFromDetail.spill_id);
    const [showHideAddUpdateSpill, setshowHideAddUpdatePopup] = useState(false);
    const [recordStatus, setRecordStatus] = useState('');
    const [investigationConclusionValues, setinvestigationConclusionValues] = useState([]);
    const [viewData, setViewData] = useState();
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [changeEditMode, setEditMode] = useState(editMode);
    const [showCancelModal, setshowHideAddCancelPopup] = useState(false);
    const [documentArr, setDocumentArr] = useState([]);
    const [docFormatType, setDocFormatType] = useState('');
    const token = localStorage.getItem('access_token');

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues,
        control,
        trigger
    } = useForm();

    useEffect(() => {
        if (isDraftMode == 'Draft') {
            setEditMode(true);
        }
        (editMode || isDraftMode == 'Draft') && getDetailsById();
    }, [])

    const getDetailsById = () => {
        setLoading(true);
        Get(getSpillsList, token, '?id=' + responseFromDetail?.spill_id, false)
            .then((response) => response)
            .then((resp) => {
                setLoading(false)
                if (resp.success) {
                    setViewData(resp?.data);
                    setRecordStatus(resp?.data?.record_status);
                    setValue('spill_description', resp?.data?.spill_description);
                    setValue('spill_add_more_detail', resp?.data?.more_details);

                    const filteredArray = data.InvestigationConclusion.filter(item => {
                        return resp?.data?.investigation_conclusion?.includes(item.value)
                    })

                    const arrayOfLabels = filteredArray.map(obj => obj.label);
                    setinvestigationConclusionValues(arrayOfLabels);
                    setConclusionArr(arrayOfLabels);
                    const updatedDocuments = resp?.data?.supporting_documents && resp?.data?.supporting_documents.length > 0 && resp?.data?.supporting_documents.map(doc => {
                        const extension = doc.usd_name.split('.').pop();
                        return { ...doc, extension };
                    });
                    setImages(updatedDocuments ? updatedDocuments : []);
                    // setImages(resp?.data?.supporting_documents ? resp?.data?.supporting_documents : []);
                }
            })
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        setshowHideAddUpdatePopup(true);
    }

    const onSubmitInvestigationDetails = (status) => {
        setRecordStatus(status);
        setLoading(true);
        var imagesIds = [];
        console.log(images)
        imagesIds = images?.map(res => {
            return {
                id: res.uploadsd_id ? res.uploadsd_id : res.id
            }
        })
        console.log(imagesIds)


        var imageValuesArray = [];
        imageValuesArray = imagesIds?.map(obj => obj.id);

        const body = {
            spill_id: imageUserId,
            spill_description: getValues('spill_description') ? getValues('spill_description') : '',
            spill_investigation_conclusion: conclusionArr,
            spill_add_more_detail: getValues('spill_add_more_detail') ? getValues('spill_add_more_detail') : '',
            record_status: status,
            documents: imageValuesArray,
        }

        if (!changeEditMode) {
            Post(spillInvestigation, token, body, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (status == 'Draft') {
                            navigate('/spills-management');
                        } else {
                            setSuccessDetail(data.detail);
                            setAlertType('success');
                            setSuccessRes(true);
                            setTimeout(() => {
                                navigate('/spills-management');
                                setLoading(false);
                            }, 2000)
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                });
        } else {
            (viewData?.investigation_id ? Put(spillInvestigation + viewData?.investigation_id, token, body, false) : Post(spillInvestigation, token, body, false))
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (status == 'Draft') {
                            navigate('/spills-management');
                        } else {
                            setSuccessDetail(data.detail);
                            setAlertType('success');
                            setSuccessRes(true);
                            setTimeout(() => {
                                navigate('/spills-management');
                                setLoading(false);
                            }, 2000)
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                });
        }
    }

    const onFileChange = (e) => {
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg') {
            let itemArr = [...filesArr];
            const filePath = handleFileUpload(e);
            setFilesArr((prevElements) => [...prevElements, filePath]);
            itemArr.push(filePath);

            if (inputFile.current) {
                inputFile.current.value = null;
            }
        }
    }

    // const handleFileUpload = (files) => {
    //     let imageType = files[0].type;
    //     if (imageType == 'image/jpeg' || imageType == 'image/jpg') {
    //         setLoading(true);
    //         if (files[0].size > 26214400) {
    //             let data = {
    //                 // id: generateTwoDigitRandomId(),
    //                 formid: '000',
    //                 image_link: 'too large'
    //             }
    //             const updatedArray = [...images, data];
    //             setImages(updatedArray);
    //             setLoading(false);
    //         }
    //         if (files[0].size < 26214400) {
    //             setLoading(true);
    //             const formData = new FormData();
    //             formData.append(`file`, files[0]);
    //             // formData.append('spill_id', imageUserId);
    //             formData.append('tag', 'Spill Investigation')
    //             formData.append('usd_size', convertBytesToKb(files[0].size));
    //             ImagePost(uploadImageSpill, token, formData, true)
    //                 .then((res) => {
    //                     setLoading(false);
    //                     if (res.success) {
    //                         setSuccessDetail('Image added successfully!');
    //                         setAlertType('success');
    //                         setLoading(false);
    //                         setSuccessRes(true);
    //                         const updatedArray = [...images, res];
    //                         setImages(updatedArray);
    //                     }
    //                     if (!res.success) {
    //                         setSuccessDetail(res.message);
    //                         setAlertType('failed');
    //                         setLoading(false);
    //                         setSuccessRes(true);
    //                     }
    //                 })
    //                 .catch((error) => {

    //                 });
    //         }
    //     }
    // }

    const handleFileUpload = (files) => {
        let imageType = files[0].type;
        const allowedCharactersRegex = /^[a-zA-Z0-9_()-. ]+$/;

        if ([IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, PDF_FORMAT, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT, CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT].includes(imageType)) {
            setLoading(true);

            if (files[0].size > 26214400) {
                let data = {
                    formid: '000',
                    image_link: TOO_LARGE_FILE
                }
                const updatedArray = [...images, data];
                setImages(updatedArray);
                setLoading(false);
            }

            if (files[0].name && !allowedCharactersRegex.test(files[0].name)) {
                setSuccessDetail('Document name should not contain special characters!');
                setAlertType(FAILED_STATUS);
                setLoading(false);
                setSuccessRes(true);
                return;
            }

            if (files[0].size < 26214400) {
                setLoading(true);
                const formData = new FormData();
                formData.append(`file`, files[0]);
                formData.append('tag', 'Spill Investigation');
                formData.append('usd_size', convertBytesToKb(files[0].size));
                ImagePost(uploadImageSpill, token, formData, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res?.detail);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            let extension = res.image_link.split('.').pop();
                            let extensionObj = { ...res, extension };
                            const updatedArray = [...images, extensionObj];
                            setImages(updatedArray);
                            const documentItemArr = [...documentArr, res.upload_id];
                            setDocumentArr(documentItemArr);
                        }
                        if (!res.success) {
                            setSuccessDetail(res.message);
                            setAlertType(FAILED_STATUS);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred while uploading image:', error);
                    });
            }
        }
        else {
            setSuccessDetail('Document type not supported!');
            setAlertType(FAILED_STATUS);
            setLoading(false);
            setSuccessRes(true);
        }
    }

    const deleteImg = (image) => {
        if (image && image.image_link === 'too large') {
            const currImages = images.filter((item) => (item.uploadsd_id ? item.uploadsd_id : item.id) !== data.id);
            setImages(currImages);
        }
        if (image && image.image_link !== 'too large') {
            setLoading(true);
            let data = {
                id: image.uploadsd_id ? image.uploadsd_id : image.id
            }
            Delete(uploadImageSpill + data.id, token, '', true)
                .then((res) => {
                    setLoading(false);
                    if (res.success) {
                        setSuccessDetail(res?.detail);
                        setAlertType('success');
                        setLoading(false);
                        setSuccessRes(true);
                        var currImages;
                        // if (!changeEditMode) {
                        currImages = images.filter((item) => (item.uploadsd_id ? item.uploadsd_id : item.id) !== data.id);
                        // } else {
                        //     currImages = images.filter((item) => item.id !== image.id);
                        // }

                        setImages(currImages);
                    }
                    if (!res.success) {
                        setSuccessDetail(res.detail);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                    inputFile.current.value = null;
                })
                .catch((error) => {
                });
        }
    }

    const onCancel = () => {
        if (!changeEditMode) {
            Delete(spill + responseFromDetail?.spill_id + '/?is_cancel=true', token, '', true)
                .then((res) => {
                    setLoading(false);
                    if (res.success) {
                        navigate('/spills-management');
                    }
                })
                .catch((error) => {
                });
        } else {
            navigate('/spills-management');
        }
    }

    const onCheckboxInvChange = (e, name) => {
        const label = e.label;
        let itemArr = changeEditMode ? investigationConclusionValues : [...conclusionArr];

        if (!itemArr.includes(label)) {
            itemArr.push(label);
        }
        else if (itemArr.includes(label)) {
            itemArr = itemArr.filter((el) => el !== label);
        }
        // setConclusionArr(itemArr);
        setConclusionArr(prevArray => {
            const updatedArray = [...itemArr];
            return updatedArray;
        });
        setinvestigationConclusionValues(itemArr);
    }

    const showPreview = (item, index) => {
        // setShowPreviewImg(true);
        // setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
        // setImageToPreview(`${imgUrl}${item?.usd_path ? item?.usd_path : item?.image_link}`)
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        if (item?.usd_path) {
            setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
            setImageToPreview(`${imgUrl}${item?.usd_path}`)
        }
        if (item?.image_link) {
            setImageName(item?.image_name ? item?.image_name : item?.usd_name);
            setImageToPreview(`${imgUrl}${item?.image_link}`)
        }
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <>

            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>

                    <div className='row'>

                        <div className='col-md-12'>
                            <div className='inputFieldHead'>Spill Description<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='spill_description'
                                placeholder='Add Spill details'
                                rows="2"
                                {...register("spill_description", { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.spill_description && errors.spill_description.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Investigation Conclusion</div>
                            <div className="row">
                                <CheckboxSelect
                                    data={data.InvestigationConclusion}
                                    colSize={4}
                                    name='spill_investigation_conclusion'
                                    onChange={onCheckboxInvChange}
                                    checkedValues={investigationConclusionValues}
                                />

                            </div>
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Add more details<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='spill_add_more_detail'
                                placeholder='Add more details'
                                rows="2"
                                {...register("spill_add_more_detail", { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.spill_add_more_detail && errors.spill_add_more_detail.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Upload Supporting Documentation</div>
                            <FileUploader onFileUpload={handleFileUpload} />

                            <div className='wthoutSpcl'>Up to 25 MB - File name without special characters</div>
                        </div>

                        <div className='col-md-6 img-container'>
                            {images?.map((image, i) => (
                                <div key={i}>
                                    {image.image_link === 'too large' && <EditUploadedFiles
                                        type='alertImageUpload'
                                        deleteImg={deleteImg}
                                        index={i}
                                        image={image}
                                        showPreview={showPreview} >
                                        <img src={DownloadAlertImg}
                                        />
                                    </EditUploadedFiles>}
                                    {image.image_link !== 'too large' && <EditUploadedFiles
                                        type='uploadedImage'
                                        deleteImg={deleteImg}
                                        index={i}
                                        image={image}
                                        showPreview={showPreview} >
                                        <img src={DownloadCompleteImg}
                                        />
                                    </EditUploadedFiles>}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={() => setshowHideAddCancelPopup(true)}>Cancel</button>
                    {recordStatus !== SUBMITTED_STATUS && <button type="button" className='btn-add-data mr-15' onClick={() => { onSubmitInvestigationDetails('Draft') }}>Save As Draft</button>}
                    <button type="submit" className='btn-event-incident in-blck mt-30' onClick={() => setRecordStatus('Submitted')}>{!editMode ? 'Submit Spill' : 'Update Spill'}</button>
                </div>
            </form>

            <Modal show={showHideAddUpdateSpill} dialogClassName="reportConfrmModal" onHide={() => setshowHideAddUpdatePopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {!editMode ? 'add this Spill' : 'update the Spill'}?
                    </div>
                    <Modal.Footer className="js-center">
                        <button type="button" className="btn-no" onClick={() => setshowHideAddUpdatePopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setshowHideAddUpdatePopup(false); onSubmitInvestigationDetails('Submitted') }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <Modal size="lg"
                dialogClassName="modal-90w"
                show={showPreviewImg}
                onHide={() => setShowPreviewImg(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                    {imageName}
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    {['pdf'].includes(docFormatType) && <FilePreviewFinal pdfFile={imageToPreview ? imageToPreview : ''} />}
                    {['jpg', 'jpeg', 'png'].includes(docFormatType) && <div className="modal-body reportText">
                        <img src={imageToPreview} className="img-preview" />
                    </div>}
                </Modal.Body>
            </Modal>

            <Modal show={showCancelModal} dialogClassName="reportConfrmModal" onHide={() => setshowHideAddCancelPopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText width_text">
                        <div>Are you sure you want to cancel it ?</div>
                        <div className="mt-2">By canceling, you will lose all the information you've {!changeEditMode ? 'entered' : 'updated'}.</div>
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideAddCancelPopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setshowHideAddCancelPopup(false); onCancel() }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default SpillInvestigationDetails;