import React, { useState, useEffect } from "react";
import './CreateUpdateStateCity.css';
import BtnCrossIcon from '../../../../assets/images/FormImages/btnCross.svg';
import errorIcon from "../../../../assets/images/error_icon.svg";
import DeleteIcon from '../../../../assets/images/FormImages/deleteIcon.svg';
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { Delete, Delete2, Get, Post, Put } from "../../../../constants/apiMethods";
import { actionState, addCityState, cityState, deleteCity, getCityList } from "../../../../constants/apiRoutes";
import LoadingSpinner from "../../../../components/Loader";

const CreateUpdateStateCity = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('access_token');
    const [loading, setLoading] = useState(false);
    const [showAddStateCity, setShowAddStateCity] = useState(false);
    const [stateCityData, setStateCityData] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [citiesData, setCitiesData] = useState('');
    const [deleteIdArray, setDeleteIdArray] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [show, setShow] = useState(false);
    const {
        register,
        control,
        setValue,
        getValues,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            city_data: [{ name: '', zip_code: '' }],
        }
    });

    const {
        fields,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'city_data'
    });

    const onHandleAppend = () => {
        const formData = getValues();
        const lastIndex = formData.city_data.length - 1;
        if (formData.city_data[lastIndex]?.name || formData.city_data?.length == 0) {
            append({ name: '', zip_code: '' });
        }
    }

    const removeCityState = (index, item) => {
        remove(index);
        if (props.editMode) {
            const deleteId = citiesData.filter((resp) => resp?.zip_code == item?.zip_code)[0]?.id;
            if (deleteId) {
                deleteIdArray.push(deleteId);
                setDeleteIdArray(deleteIdArray);
            }
        }
    }

    useEffect(() => {
        if (props.editMode) {
            getStateCityDetails();
        }
    }, []);

    const getStateCityDetails = () => {
        setLoading(true);
        Get(actionState, token, `?id=${location.state.id}&is_active=${location.state.is_active}`, false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    setLoading(false);
                    setValue('name', data.name);
                    setValue('city_data', data.cities);
                    setCitiesData(data.cities);
                }
            });
    };

    const onSubmit = (data) => {
        setShowAddStateCity({ show: true });
        setStateCityData(data);
    }

    const onSubmitStateCityDetails = () => {
        console.log(stateCityData);
        if(stateCityData?.city_data[0]?.name == '') {
            stateCityData.city_data = [];
        }
        setShowAddStateCity({ show: false });
        setLoading(true);
        if (!props.editMode) {
            Post(addCityState, token, stateCityData, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        setAlertType('success');
                        setShowSuccessMessage(data.detail);
                        setSuccessRes(true);
                        setTimeout(() => {
                            navigate('../state-city');
                        }, 2000);
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                        setSuccessRes(true);
                    }
                });
        } else {
            stateCityData.is_active = location.state.is_active;
            Put(`${cityState}/${location.state.id}/`, token, stateCityData, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        deleteIdArray?.length > 0 && deleteIdArray.map((item, index) => {
                            deleteCityData(item);
                        })
                        setAlertType('success');
                        setShowSuccessMessage(data.detail);
                        setSuccessRes(true);
                        setTimeout(() => {
                            !location.state.is_active && localStorage.setItem('getPage', 1);
                            navigate('../state-city');
                        }, 2000);
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                        setSuccessRes(true);
                    }
                });
        }
    }

    const deleteCityData = (id) => {
        Delete2(deleteCity, token, id, false)
            .then((response) => response)
            .then((data) => {
            });
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const handleClick = () => {
        setShow(true);
        !location.state.is_active && localStorage.setItem('getPage', 1);
        navigate('/state-city');
    }

    const keypressNumber = (event) => {
        if (event.charCode >= 48 && event.charCode <= 57) {
            return event;
        }
        else {
            event.preventDefault();
            return false;
        }
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div className="head_1">
                        <span className='click_head_1 cursor' onClick={handleClick}>State & City</span> /
                        <span>{props.editMode ? 'Update' : 'Add New'} State & City </span>
                        {/* State & City/ {props.editMode ? 'Update' : 'Add'} State & City */}
                    </div>
                    <div className="head_2">{props.editMode ? 'Update' : 'Add New'} State & City</div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='row mt-30 light-container'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Name of the State*</div>
                            <input type='text'
                                className='form-control'
                                name="eventName"
                                placeholder='Type here'
                                {...register('name', {
                                    required: true,
                                })}
                            />
                            {errors.name?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>
                    </div>
                    <div className='containerHeading'>{props.editMode ? 'Update' : 'Add'} Cities to the State</div>

                    {fields.map((stateCity, index) => (

                        <div className='row mt-30 light-container' key={stateCity.id}>
                            {index != 0 && <span className='text-right' onClick={() => removeCityState(index, stateCity)}><img className="editIcn cursor h-26" src={DeleteIcon} /></span>}

                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Name of the City</div>
                                <input type='text'
                                    className='form-control'
                                    name="eventName"
                                    placeholder='Type here'
                                    {...register(`city_data[${index}].name`, {
                                        required: false
                                    })}
                                    control={control}
                                />
                                {errors.city_data?.[index]?.name && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                            </div>

                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Zip Code</div>
                                <Controller
                                    name={`city_data[${index}].zip_code`}
                                    control={control}
                                    render={({ field }) => (
                                        <input type='text'
                                            {...field}
                                            className='form-control'
                                            name="zipCode"
                                            placeholder='Type here'
                                            control={control}
                                            maxLength={5}
                                            onKeyPress={(e) => {
                                                field.onChange(e);
                                                keypressNumber(e);
                                            }}
                                        />
                                    )}
                                    rules={{ required: false }}
                                />
                                {/* <input type='text'
                                    className='form-control'
                                    name="eventName"
                                    placeholder='Type here'
                                    maxLength={5}
                                    keypress={(event) => keypressNumber(event)}
                                    {...register(`city_data[${index}].zip_code`, {
                                        required: true
                                    })}
                                    control={control}
                                /> */}
                                {errors.city_data?.[index]?.zip_code && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                            </div>
                        </div>
                    ))}

                    <div className='text-right'>
                        <button type="button" className='btn-add-incident'
                            disabled={false}
                            onClick={() => onHandleAppend()}
                        >
                            Add City</button>
                    </div>

                    <div className='text-right'>
                        <button type="button" className="btn-cancel border-0 mr-15" onClick={handleClick}>Cancel</button>
                        <button type="submit" className='btn-event-incident in-blck mt-30' onClick={handleSubmit}> {props.editMode ? 'Update' : 'Add'} State & City</button>
                    </div>

                </form>

                <Modal show={showAddStateCity.show} dialogClassName="reportConfrmModal" onHide={() => setShowAddStateCity({ show: false })}>
                    <Modal.Header className="sideOnRight" closeButton>
                    </Modal.Header>
                    <Modal.Body className="auth_modal_header border-0 mx-auto">
                        <div className="modal-body reportText wd-460">
                            Are you sure you would like to {props.editMode ? 'update' : 'add the new'}  State & City?
                        </div>
                        <Modal.Footer>
                            <button type="button" className="btn-no" onClick={() => setShowAddStateCity({ show: false })}>No</button>
                            <button type="button" className="btn-yes" onClick={onSubmitStateCityDetails}>Yes</button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>

            </div>
        </>
    )
}

export default CreateUpdateStateCity;