import React, { useState, useEffect } from "react";
import Select from 'react-select';
import ForwardIcon from '../../../assets/images/forwardIcon.svg';
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import errorIcon from "../../../assets/images/error_icon.svg";
import './AddUsers.css';
import { useLocation, useNavigate } from "react-router";
import { Get, Post, Put } from "../../../constants/apiMethods";
import { Controller, useForm } from "react-hook-form";
import { getRoleList, getStateList, registerUser, singleUser, user } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import { Modal } from "react-bootstrap";
import moment from "moment";

const AddUsers = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rolesListData, setRolesList] = useState([]);
    const [showHideAddUser, setShowHideAddUser] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [viewData, setViewData] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [statesDataList, setStatesDataList] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const token = localStorage.getItem("access_token");
    const isSidebarShow = JSON.parse(localStorage.getItem('isSidebarShow'));

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm();

    const handleAddUpdate = () => {
        navigate('/company-admin');
    }

    useEffect(() => {
        setEditMode(props.editMode);
        getRolesList();
        getStatesList();
        if (props.editMode) {
            getUserDetails();
        }
    }, []);

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const getUserDetails = () => {
        setLoading(true);
        Get(user, token, `?id=${location.state.id}&is_active=${location.state.is_active}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setUserData(data.data);
                    setViewData(data.data);
                }
            });
    };

    const setUserData = (data) => {
        setValue('name', data.name);
        setValue('email', data.username);
        let obj = {
            name: data?.role?.name,
            label: data?.role?.name,
            value: data?.role?.name,
            id: data?.role?.id
        }
        setValue('role_id', obj);
        setSelectedRole(obj);
        const statesData = data.locations?.map((resp) => {
            return { ...resp, label: resp.name, value: resp.name }
        })
        setSelectedStates(statesData);
        setValue('location_ids', statesData);
    }

    const getRolesList = () => {
        Get(getRoleList, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const rolesData = data.data.data?.map((resp) => {
                        return { ...resp, label: resp.name, value: resp.name }
                    })
                    setRolesList(rolesData);
                }
            });
    };

    const forRole = () => {
        // if (editMode) {

        let obj = {
            name: selectedRole?.role?.name,
            label: selectedRole?.role?.name,
            value: selectedRole?.role?.name,
            id: selectedRole?.role?.id
        }
        setValue('role_id', obj);
        const statesData = selectedStates?.map((resp) => {
            return { ...resp, label: resp.name, value: resp.name }
        })
        setSelectedStates(statesData);
        setValue('location_ids', statesData);
        // }
    }

    const onSubmit = (data) => {
        setValue('name', data.name);
        setValue('email', data.email);
        setValue('role_id', data.role);
        data.location_ids?.map((resp) => {
            return delete resp.name, delete resp.value, delete resp.label;
        })
        const valuesArray = data.location_ids?.flatMap(obj => Object.values(obj));

        data.location_ids = valuesArray;
        setValue('location_ids', data.location_ids);
        setShowHideAddUser(true);
    };

    const onRoleChange = (selection, { name }) => {
        setValue(name, selection.id);
        trigger(name);
        setSelectedRole((selection));
    }

    const addEditUser = () => {
        let data = {
            name: getValues('name'),
            email: getValues('email'),
            role_id: selectedRole.id,
            location_ids: getValues('location_ids')
        }
        setLoading(true);
        setSuccessRes(false);
        if (!editMode) {
            Post(registerUser, token, data, false)
                .then((res) => {
                    setLoading(false);
                    setShowHideAddUser(false);
                    if (res.success) {
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(res.detail);
                        setTimeout(() => {
                            localStorage.setItem('getPage', 1);
                            if (isSidebarShow) {
                                navigate('/users-list');
                            } else {
                                navigate('/company-admin');
                            }
                        }, 2000)
                    } else {
                        setSuccessRes(true);
                        setAlertType('failed');
                        setShowSuccessMessage(res.detail);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else {
            Put(`${singleUser}/${location.state.id}/`, token, data, false)
                .then((res) => {
                    setLoading(false);
                    setShowHideAddUser(false);
                    if (res.success) {
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(res.detail);
                        setTimeout(() => {
                            localStorage.setItem('getPage', 1);
                            if (isSidebarShow) {
                                navigate('/users-list');
                            } else {
                                navigate('/company-admin');
                            }
                        }, 2000)
                    } else {
                        setSuccessRes(true);
                        setAlertType('failed');
                        setShowSuccessMessage(res.detail);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }

    const onStateChange = (selection, { name }) => {
        setValue(name, selection);
        setSelectedStates((selection));
        trigger(name);
    }

    const handleDelete = (id) => {
        const addedState = selectedStates.filter((item) => item.id !== id);
        setSelectedStates(addedState);
        setValue('location_ids', addedState);
    }

    const getStatesList = () => {
        Get(getStateList + `?is_active=true&min=true&sort=name`, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const statesData = data.data?.map((resp) => {
                        return { ...resp, label: resp.name, value: resp.name }
                    })
                    setStatesDataList(statesData);
                }
            });
    }

    const backToUserListing = () => {
        localStorage.setItem('getPage', 1);
        if (isSidebarShow) {
            navigate('/users-list');
        } else {
            navigate('/company-admin');
        }
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : null}
            {successRes ? <AlertNotifier timer={2000} alertType={alertType} alertText={showSuccessMessage} setVisibility={setVisibility} /> : null}
            <div className="full-cont">
                <div className="flex border_head_1">
                    <img className="frwrdImg cursor" src={ForwardIcon} onClick={backToUserListing} alt="forwardImage" />
                    <div className="head_2 ml-15">{editMode ? `Update User Details added on ${viewData?.created_on ? moment(viewData?.created_on).format('MM-DD-YYYY') : ''}` : 'Add User'}</div>
                </div>

                 {/* will be added in later stages */}
                {/* <div className="userDet mt-30">System Details</div>
                {isSidebarShow && <div className='container_div'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Added By</div>
                            <p className="text-content">{viewData?.name ? viewData?.name : '-'}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Added On</div>
                            <p className="text-content">{viewData?.created_on ? viewData?.created_on : '-'}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Last Updated By</div>
                            <p className="text-content">{viewData?.created_on ? viewData?.created_on : '-'}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Last Updated On</div>
                            <p className="text-content">{viewData?.spill_status ? viewData?.spill_status : '-'}</p>
                        </div>
                    </div>
                </div>} */}


                <div className="mt-30">
                    <div className="userDet">User Details</div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='container_div'>

                        <div className='row mt-30'>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Name*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="eventName"
                                    placeholder='Type here'
                                    {...register('name', {
                                        required: true,
                                    })}
                                />
                                {errors.name?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} alt="errorIcon" /> This field is mandatory </p>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Email ID*</div>
                                <input
                                    type='email'
                                    className='form-control'
                                    name="eventName"
                                    placeholder='Type Here'
                                    {...register('email', {
                                        required: true,
                                    })}
                                />
                                {errors.email?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} alt="errorIcon" /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        <div className="row mt-30">
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Role*</div>
                                <Controller
                                    name="role_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix="an-simple-select"
                                            placeholder='Choose Role'
                                            options={rolesListData}
                                            onChange={(selection) => onRoleChange(selection, field)}
                                            value={selectedRole}
                                        />
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.role_id && (
                                    <p className="error"> <img src={errorIcon} alt="errorIcon" /> {errors.role_id.message} </p>
                                )}
                            </div>

                            <div className='col-md-6'>
                                <div className='inputFieldHead'>States*</div>
                                <Controller
                                    name="location_ids"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix="an-simple-select"
                                            placeholder='Choose states'
                                            options={statesDataList}
                                            onChange={(selection) => onStateChange(selection, field)}
                                            value={selectedStates}
                                            isMulti
                                        />
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.location_ids && (
                                    <p className="error"> <img src={errorIcon} alt="errorIcon" /> {errors.location_ids.message} </p>
                                )}
                                <div className="row mt-30">
                                    <div className="col-md-12">
                                        {selectedStates.map((item, index) => (
                                            <span key={index} className="line-h">
                                                <span className="feature-wrapper">{item.name}<img className="cross-btn-size" src={BtnCrossIcon} onClick={() => handleDelete(item.id)} alt="crossIcon" /></span>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="text-right">
                        <button type="button" className='btn-add-data mr-15' onClick={backToUserListing}>Back</button>
                        <button type="submit" className='btn-event-incident in-blck mt-30'>{editMode ? 'Update User' : 'Add User'}</button>
                    </div>
                </form>

            </div>

            <Modal show={showHideAddUser} dialogClassName="reportConfrmModal" onHide={() => { setShowHideAddUser(false); forRole() }}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you want to {editMode ? 'Update' : 'Add'} this user?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => { setShowHideAddUser(false); forRole() }}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { addEditUser(); forRole() }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AddUsers;