import React, { useEffect, useState } from 'react';
import { Get } from "../../../constants/apiMethods";
import { profileUrl } from "../../../constants/apiRoutes";
import './CompanyAdminProfile.css';
import LoadingSpinner from '../../../components/Loader';

const CompanyAdminProfile = () => {

    const token = localStorage.getItem('access_token');
    const[loading, setLoading] = useState(false);
    const [viewData, setViewData] = useState('');

    useEffect(() => {
        getAdminProfile();
    }, []);

    const getAdminProfile = () => {
        setLoading(true);
        Get(profileUrl, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setViewData(data.data);
                    setLoading(false);
                }
            });
    };

    return (
        <div className="full-cont pb-20">
            {loading ? <LoadingSpinner /> : null}
            <div className="border_head_1">
                <div className="head_2">{'My Profile'}</div>
            </div>
            <div className='row mt-30 light-container'>
                <div className='col-md-6'>
                    <div className='inputFieldHead'>Name</div>
                    <input type='text' className='form-control' value={viewData?.name} name="eventName" placeholder='Type here' disabled />
                </div>
                <div className='col-md-6'>
                    <div className='inputFieldHead'>Email ID</div>
                    <input type='email' className='form-control' value={viewData?.username} name="eventName" placeholder='abc@gmail.com' disabled />
                </div>
            </div>
        </div>
    )
}

export default CompanyAdminProfile;