import CrossImg from '../../../assets/images/crossImage.svg';
import DeleteImg from '../../../assets/images/deleteImg.svg';

const FileUploadProgressContainer = (props) => {
    const {
        type,
        showUpload,
        showProcessing,
        showFileContainer,
        currentFile,
        setShowUpload,
        setFile,
        setCurrentFile,
        children,
        convertBytesToKb
      } = props;
    
      return (
        <div className={`row boxWidth ml-30 mt-20 ${type === 'imageProcessing' ? 'percentProcess' : type === 'uploadedImage' ? 'imageUpload' : ''}`}>
          <div className={`col-md-2 ${type === 'imageProcessing' ? 'bgPercentProcessColor' : type === 'uploadedImage' ? 'bgUploadCompleted' : ''}`}>
            <div>{children}</div>
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-11">
                <div className="imageName">{currentFile && currentFile.name}</div>
                <div className="imageSize">{convertBytesToKb(currentFile.size)} KB</div>
              </div>
              <div className="col-md-1 flex contentCenter cursor" onClick={() => { setShowUpload(true); setFile(''); setCurrentFile(''); }}>
                <img src={type === 'imageProcessing' ? CrossImg : DeleteImg} alt='alternateIcon' />
              </div>
            </div>
          </div>
        </div>
      );
}

export default FileUploadProgressContainer;