import InjuryIcon from '../assets/images/Injuries.png';
import FirstAidIcon from '../assets/images/FirstAid.png';
import FireIcon from '../assets/images/Fire.png';
import MissesIcon from '../assets/images/Misses.png';
import DownloadXL from '../assets/images/FormImages/downloadXL.svg';
import DownloadDoc from '../assets/images/FormImages/downloadDoc.svg';
import FadedDownloadDoc from '../assets/images/FormImages/fadedDownloadIcon.svg';
import DownloadPdf from '../assets/images/FormImages/downloadPdf.svg';
import EditSvg from '../assets/images/FormImages/edit.svg';
import ViewUpdateIcon from '../assets/images/FormImages/view-updateIcon.svg';
import ViewIcon from '../assets/images/FormImages/view.svg';
import DuplicateIcon from '../assets/images/FormImages/duplicateIcon.svg';
import DeleteIcon from '../assets/images/FormImages/deleteIcon.svg';
import UpdateIcon from '../assets/images/UpdateIcon.svg';
import ActivateIcon from '../assets/images/ActivateIcon.svg';
import DeactivateIcon from '../assets/images/DeactivateIcon.svg';

const RadioData = [
    { label: 'Employee', value: 'employee', id: 1, name: 'personImpacted' },
    { label: 'Contractor', value: 'contractor', id: 2, name: 'personImpacted' },
];

const Locations = [
    { id: 1, value: 'Others', label: 'Others' },
    { id: 2, value: 'Facility B1', label: 'Facility B1' },
    { id: 3, value: 'Facility B2', label: 'Facility B2' },
    { id: 4, value: 'Facility B3', label: 'Facility B3' },
    { id: 5, value: 'Facility B4', label: 'Facility B4' }
];

const BodyParts = [
    { id: 'head', value: 'head', label: 'Head' },
    { id: 'hand', value: 'hand', label: 'Hand' },
    { id: 'foot', value: 'foot', label: 'Foot' },
    { id: 'arm', value: 'arm', label: 'Arm' },
    { id: 'leg', value: 'leg', label: 'Leg' },
    { id: 'trunk', value: 'trunk', label: 'Trunk' },
    { id: 'eye', value: 'eye', label: 'Eye' },
];

const Hours = [
    { id: 1, value: '01', label: '01' },
    { id: 2, value: '02', label: '02' },
    { id: 3, value: '03', label: '03' },
    { id: 4, value: '04', label: '04' },
    { id: 5, value: '05', label: '05' },
    { id: 6, value: '06', label: '06' },
    { id: 7, value: '07', label: '07' },
    { id: 8, value: '08', label: '08' },
    { id: 9, value: '09', label: '09' },
    { id: 10, value: '10', label: '10' },
    { id: 11, value: '11', label: '11' },
    { id: 12, value: '12', label: '12' },
];

const Minutes = [
    { id: 0, value: '00', label: '00' },
    { id: 1, value: '01', label: '01' },
    { id: 2, value: '02', label: '02' },
    { id: 3, value: '03', label: '03' },
    { id: 4, value: '04', label: '04' },
    { id: 5, value: '05', label: '05' },
    { id: 6, value: '06', label: '06' },
    { id: 7, value: '07', label: '07' },
    { id: 8, value: '08', label: '08' },
    { id: 9, value: '09', label: '09' },
    { id: 10, value: '10', label: '10' },
    { id: 11, value: '11', label: '11' },
    { id: 12, value: '12', label: '12' },
    { id: 13, value: '13', label: '13' },
    { id: 14, value: '14', label: '14' },
    { id: 15, value: '15', label: '15' },
    { id: 16, value: '16', label: '16' },
    { id: 17, value: '17', label: '17' },
    { id: 18, value: '18', label: '18' },
    { id: 19, value: '19', label: '19' },
    { id: 20, value: '20', label: '20' },
    { id: 21, value: '21', label: '21' },
    { id: 22, value: '22', label: '22' },
    { id: 23, value: '23', label: '23' },
    { id: 24, value: '24', label: '24' },
    { id: 25, value: '25', label: '25' },
    { id: 26, value: '26', label: '26' },
    { id: 27, value: '27', label: '27' },
    { id: 28, value: '28', label: '28' },
    { id: 29, value: '29', label: '29' },
    { id: 30, value: '30', label: '30' },
    { id: 31, value: '31', label: '31' },
    { id: 32, value: '32', label: '32' },
    { id: 33, value: '33', label: '33' },
    { id: 34, value: '34', label: '34' },
    { id: 35, value: '35', label: '35' },
    { id: 36, value: '36', label: '36' },
    { id: 37, value: '37', label: '37' },
    { id: 38, value: '38', label: '38' },
    { id: 39, value: '39', label: '39' },
    { id: 40, value: '40', label: '40' },
    { id: 41, value: '41', label: '41' },
    { id: 42, value: '42', label: '42' },
    { id: 43, value: '43', label: '43' },
    { id: 44, value: '44', label: '44' },
    { id: 45, value: '45', label: '45' },
    { id: 46, value: '46', label: '46' },
    { id: 47, value: '47', label: '47' },
    { id: 48, value: '48', label: '48' },
    { id: 49, value: '49', label: '49' },
    { id: 50, value: '50', label: '50' },
    { id: 51, value: '51', label: '51' },
    { id: 52, value: '52', label: '52' },
    { id: 53, value: '53', label: '53' },
    { id: 54, value: '54', label: '54' },
    { id: 55, value: '55', label: '55' },
    { id: 56, value: '56', label: '56' },
    { id: 57, value: '57', label: '57' },
    { id: 58, value: '58', label: '58' },
    { id: 59, value: '59', label: '59' },
];

const Meridian = [
    { id: 1, value: 'AM', label: 'AM' },
    { id: 2, value: 'PM', label: 'PM' },
];

const JobTitle = [
    { id: 1, value: 'Field', label: 'Field' },
    { id: 2, value: 'Office', label: 'Office' },
];

const InjuryTypes = [
    { id: 1, value: 'Caught Between', label: 'Caught Between' },
    { id: 2, value: 'Struck by', label: 'Struck by' },
    { id: 3, value: 'Pressure', label: 'Pressure' },
    { id: 4, value: 'Laceration', label: 'Laceration' },
    { id: 5, value: 'Fall', label: 'Fall' },
    { id: 6, value: 'Over Exertion', label: 'Over Exertion' },
    { id: 7, value: 'Bodily Reaction', label: 'Bodily Reaction' },
    { id: 8, value: 'Vehicle Accident', label: 'Vehicle Accident' },
    { id: 9, value: 'Repetetive Motion', label: 'Repetetive Motion' },
    { id: 10, value: 'Burn', label: 'Burn' },
];

const IncidentType = [
    { id: 1, value: 'First Aid Injury', label: 'First Aid Injury' },
    { id: 2, value: 'Recordable Injury', label: 'Recordable Injury' },
    { id: 3, value: 'Lost Time Injury', label: 'Lost Time Injury' },
    { id: 4, value: 'Fatality', label: 'Fatality' },
    { id: 5, value: 'Property Damage', label: 'Property Damage' },
    { id: 6, value: 'Fire', label: 'Fire' },
    { id: 7, value: 'Near Miss', label: 'Near Miss' },
    { id: 8, value: 'Observation', label: 'Observation' },
    { id: 9, value: 'Vehicle Accident', label: 'Vehicle Accident' },
    { id: 10, value: 'Vandalism/Theft', label: 'Vandalism/Theft' },
];

const graphCustomizationData = {
    data: {
        "leadingIndicators": [
            {
                "name": "Near Miss and Observation",
                "label": "Near Miss and Observation",
                "id": "Near Miss and Observation",
                "checked": true,
            }
        ],
        "laggingIndicators": [
            {
                "id": "Recordable Injury",
                "name": "Recordable Injury",
                "label": "Recordable Injury",
                "checked": true,
            },
            {
                "id": "Lost Time Injury",
                "name": "Lost Time Injury",
                "label": "Lost Time Injury",
                "checked": true,
            },
            {
                "id": "First Aid Injury",
                "name": "First Aid Injury",
                "label": "First Aid Injury",
                "checked": true
            },
            {
                "id": "Fatalities",
                "name": "Fatalities",
                "label": "Fatalities",
                "checked": true
            },
            {
                "id": "Recordable Injury Types",
                "name": "Recordable Injury Types",
                "label": "Recordable Injury Types",
                "checked": true
            },
            {
                "id": "First Aid Injury Types",
                "name": "First Aid Injury Types",
                "label": "First Aid Injury Types",
                "checked": true
            },
            {
                "id": "Impact on Body Part Due to Recordable",
                "name": "Impact on Body Part Due to Recordable",
                "label": "Impact on Body Part Due to Recordable",
                "checked": true
            },
            {
                "id": "Impact on Body Part Due to First Aid",
                "name": "Impact on Body Part Due to First Aid",
                "label": "Impact on Body Part Due to First Aid",
                "checked": true
            },
            {
                "id": "Fire",
                "name": "Fire",
                "label": "Fire",
                "checked": true
            },
            {
                "id": "Vehicle Accident",
                "name": "Vehicle Accident",
                "label": "Vehicle Accident",
                "checked": true
            },
            {
                "id": "Property Damage Incident",
                "name": "Property Damage Incident",
                "label": "Property Damage Incident",
                "checked": true
            },
            {
                "id": "Vandalism/Theft",
                "name": "Vandalism/Theft",
                "label": "Vandalism/Theft",
                "checked": true
            }
        ]
    }
}


const Months = [
    { name: 'Select All', id: 12, label: 'Select All' },
    { name: 'Jan', id: 0, label: 'Jan' }, { name: 'Feb', id: 1, label: 'Feb' },
    { name: 'Mar', id: 2, label: 'Mar' }, { name: 'April', id: 3, label: 'April' },
    { name: 'May', id: 4, label: 'May' }, { name: 'June', id: 5, label: 'June' },
    { name: 'Jul', id: 6, label: 'Jul' }, { name: 'Aug', id: 7, label: 'Aug' },
    { name: 'Sept', id: 8, label: 'Sept' }, { name: 'Oct', id: 9, label: 'Oct' },
    { name: 'Nov', id: 10, label: 'Nov' }, { name: 'Dec', id: 11, label: 'Dec' }];

const Duration = [
    { name: 'Yearly', id: 1, label: 'Yearly', filter_number: 1 },
    { name: 'Quarterly', id: 2, label: 'Quarterly', filter_number: 2 },
    { name: 'Monthly', id: 3, label: 'Monthly', filter_number: 3 }
];

const EmpDdnData = [
    { id: 1, value: 'Employee', label: 'Employee', name: 'Employee', checked: false },
    { id: 2, value: 'Contractor', label: 'Contractor', name: 'Contractor', checked: false },
];

const RecordableInjuriesFilterData = [
    { id: 1, value: 'Employee', label: 'Employee', name: 'Employee', checked: false },
    { id: 2, value: 'Contractor', label: 'Contractor', name: 'Contractor', checked: false },
    { id: 3, value: 'Combined', label: 'Combined', name: 'Combined', checked: false },
];

const FirstAidInjuryFilterData = [
    { id: 4, value: 'Employee', label: 'Employee', name: 'Employee', checked: false },
    { id: 5, value: 'Contractor', label: 'Contractor', name: 'Contractor', checked: false },
    { id: 6, value: 'Combined', label: 'Combined', name: 'Combined', checked: false },
];

const LostTimeInjuryFilterData = [
    { id: 7, value: 'Employee', label: 'Employee', name: 'Employee', checked: false },
    { id: 8, value: 'Contractor', label: 'Contractor', name: 'Contractor', checked: false },
    { id: 9, value: 'Combined', label: 'Combined', name: 'Combined', checked: false },
];

const ContractorDdnData = [
    { id: 1, value: 'Lorem1', label: 'Lorem1' },
    { id: 2, value: 'Lorem2', label: 'Lorem2' },
    { id: 3, value: 'Lorem3', label: 'Lorem3' },
    { id: 4, value: 'Lorem4', label: 'Lorem4' },
];

const DashboardCount = [
    { name: 'Fire', icon: <img src={FireIcon} /> },
    { name: 'First Aid Injury', icon: <img src={FirstAidIcon} /> },
    { name: 'Near Miss', icon: <img src={MissesIcon} /> },
    { name: 'Recordable Injury', icon: <img src={InjuryIcon} /> },
];


const YearData = [
    { id: 1, value: '2020', label: '2020' },
    { id: 2, value: '2021', label: '2021' },
    { id: 3, value: '2022', label: '2022' },
    { id: 4, value: '2023', label: '2023' }
];

const MonthData = [
    { id: 1, value: 1, label: 'January' },
    { id: 2, value: 2, label: 'February' },
    { id: 3, value: 3, label: 'March' },
    { id: 4, value: 4, label: 'April' },
    { id: 5, value: 5, label: 'May' },
    { id: 6, value: 6, label: 'June' },
    { id: 7, value: 7, label: 'July' },
    { id: 8, value: 8, label: 'August' },
    { id: 9, value: 9, label: 'September' },
    { id: 10, value: 10, label: 'October' },
    { id: 11, value: 11, label: 'November' },
    { id: 12, value: 12, label: 'December' }
];

const IndexData = [
    { id: 1, value: 1, label: 'First' },
    { id: 2, value: 2, label: 'Second' },
    { id: 3, value: 3, label: 'Third' },
    { id: 4, value: 4, label: 'Fourth' },
    { id: 5, value: 5, label: 'Fifth' },
    { id: 6, value: 6, label: 'Sixth' },
    { id: 7, value: 7, label: 'Seventh' },
    { id: 8, value: 8, label: 'Eighth' },
    { id: 9, value: 9, label: 'Ninth' },
    { id: 10, value: 10, label: 'Tenth' },
];

const Quarters = [
    { id: 5, name: 'Select All', label: 'Select All' },
    { id: 1, name: 'Q1', label: 'Q1' },
    { id: 2, name: 'Q2', label: 'Q2' },
    { id: 3, name: 'Q3', label: 'Q3' },
    { id: 4, name: 'Q4', label: 'Q4' }
]

const States = [
    { id: 1, name: 'Colorado', label: 'Colorado', value: 'Colorado' },
    { id: 2, name: 'Texas', label: 'Texas', value: 'Texas' },
    { id: 3, name: 'North Dakota', label: 'North Dakota', value: 'North Dakota' },
    { id: 4, name: 'Oklahoma', label: 'Oklahoma', value: 'Oklahoma' }];

const InvestigationConclusion = [
    { id: 'Equipment Failure', value: 'Equipment Failure', label: 'Equipment Failure' },
    { id: 'Human Error', value: 'Human Error', label: 'Human Error' },
    { id: 'Procedural Deviation', value: 'Procedural Deviation', label: 'Procedural Deviation' },
    { id: 'Environmental Factors', value: 'Environmental Factors', label: 'Environmental Factors' },
    { id: 'Communication Failure', value: 'Communication Failure', label: 'Communication Failure' },
    { id: 'Inadequate Maintenance', value: 'Inadequate Maintenance', label: 'Inadequate Maintenance' },
    { id: 'Regulatory Non-Compliance', value: 'Regulatory Non-Compliance', label: 'Regulatory Non-Compliance' },
    { id: 'Design Flaw', value: 'Design Flaw', label: 'Design Flaw' },
    { id: 'Lack of Risk Management', value: 'Lack of Risk Management', label: 'Lack of Risk Management' },
];

const CorrectiveActions = [
    { id: 1, value: 'Training', label: 'Training' },
    { id: 2, value: 'Strengthened Safety Procedures', label: 'Strengthened Safety Procedures' },
    { id: 3, value: 'Improved Maintenance Practices', label: 'Improved Maintenance Practices' },
    { id: 4, value: 'Enhanced Communication', label: 'Enhanced Communication' },
    { id: 5, value: 'Risk Assessment', label: 'Risk Assessment' },
    { id: 6, value: 'Incident Reporting', label: 'Incident Reporting' },
    { id: 7, value: 'Continuous Improvement Culture', label: 'Continuous Improvement Culture' },
    { id: 8, value: 'Collaboration & Knowledge Sharing', label: 'Collaboration & Knowledge Sharing' },
];

const Failures = [
    { id: 1, value: 'equipment_failure', label: 'Equipment Failure' },
    { id: 2, value: 'human_error', label: 'Human Error' },
    { id: 3, value: 'procedural_deviation', label: 'Procedural Deviation' },
    { id: 4, value: 'environmental_factors', label: 'Environmental Factors' },
    { id: 5, value: 'communication_failure', label: 'Communication Failure' },
    { id: 6, value: 'inadequate_maintenance', label: 'Inadequate Maintenance' },
    { id: 7, value: 'regulatory_non_compliance', label: 'Regulatory Non Compliance' },
    { id: 8, value: 'design_flaw', label: 'Design Flaw' },
    { id: 9, value: 'lack_of_risk_management', label: 'Lack of Risk Management' },
];

const Downloads = [
    // { name: 'Download as PDF', label: 'Download as PDF', icon: <img src={DownloadPdf} /> },
    // { name: 'Download as Excel Sheet', id: 1, label: 'Download as Excel Sheet', icon: <img src={DownloadXL} /> },
    { name: 'Download as CSV', id: 1, label: 'Download as CSV', icon: <img src={DownloadDoc} /> },
];

const RoleActiveActionItems = [
    { name: 'Update', label: 'View/Update', icon: <img src={UpdateIcon} /> },
    { name: 'Activate', id: 1, label: 'Activate', icon: <img src={ActivateIcon} /> },
];

const RoleDeactiveActionItems = [
    { name: `Update`, label: 'View/Update', icon: <img src={UpdateIcon} /> },
    { name: 'Deactivate', id: 2, label: 'Deactivate', icon: <img src={DeactivateIcon} style={{ width: '18px', height: '20px' }} /> }
];

const CompanyActiveActionItems = [
    { name: 'View', label: 'View', icon: <img src={ViewIcon} className='view-icon' /> },
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} /> },
    { name: 'Activate', id: 1, label: 'Activate', icon: <img src={ActivateIcon} /> },
];

const CompanyDeactiveActionItems = [
    { name: 'View', label: 'View', icon: <img src={ViewIcon} className='view-icon' /> },
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} /> },
    { name: 'Deactivate', id: 2, label: 'Deactivate', icon: <img src={DeactivateIcon} style={{ width: '18px', height: '20px' }} /> }
];

const ActionItems = [
    { name: 'View', label: 'View', icon: <img src={ViewIcon} className='view-icon' style={{ width: '16px', height: '20px' }}/> },
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} style={{ width: '15px', height: '20px' }}/> },
    { name: 'Delete', id: 2, label: 'Delete', icon: <img src={DeleteIcon} style={{ width: '18px', height: '20px' }} /> }
];

const DraftActionItems = [
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} style={{ width: '15px', height: '20px' }}/> },
    { name: 'Delete', id: 2, label: 'Delete', icon: <img src={DeleteIcon} style={{ width: '18px', height: '20px' }} /> }
];

const ManHoursActionItems = [
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} /> },
    { name: 'Delete', id: 2, label: 'Delete', icon: <img src={DeleteIcon} /> }
];

const ExportHeading = { label: 'Export', icon: <img src={EditSvg} /> }

const Roles = [
    { name: 'User', label: 'User' },
    { name: 'Admin', label: 'Admin' },
];

const FeatureType = [
    { name: 'Feature1', label: 'Feature1' },
    { name: 'Feature2', label: 'Feature2' },
];

const StateType = [
    { name: 'State1', label: 'State 1' },
    { name: 'State2', label: 'State 2' },
];

const AddressType = [
    { name: 'Corporate Office', label: 'Corporate Office', value: 'Corporate Office' },
    { name: 'Field Office', label: 'Field Office', value: 'Field Office' },
];

const FluidType = [
    { name: 'Oil', label: 'Oil', value: 'Oil' },
    { name: 'Produced Water', label: 'Produced Water', value: 'Produced Water' },
    { name: 'Flow Back Fluid', label: 'Flow Back Fluid', value: 'Flow Back Fluid' },
    { name: 'Other E&P Waste', label: 'Other E&P Waste', value: 'Other E&P Waste' },
    { name: 'Drilling Fluid', label: 'Drilling Fluid', value: 'Drilling Fluid' },
    { name: 'Condensate', label: 'Condensate', value: 'Condensate' },
];

const SpillsActionItems = [
    { name: 'View', label: 'View', icon: <img src={ViewIcon} className='view-icon' /> },
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} /> },
    { name: 'Delete', id: 2, label: 'Delete', icon: <img src={DeleteIcon} style={{ width: '18px', height: '20px' }} /> }
];

const SpillsActionDraftItems = [
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} /> },
    { name: 'Delete', id: 2, label: 'Delete', icon: <img src={DeleteIcon} style={{ width: '18px', height: '20px' }} /> }
];

export default {
    graphCustomizationData, Months,
    EmpDdnData, Duration, DashboardCount, Quarters, States,
    Locations, BodyParts, Hours, Minutes, Meridian, JobTitle, IncidentType, InjuryTypes,
    InvestigationConclusion, Failures, CorrectiveActions, Failures, Downloads,
    ActionItems, DraftActionItems, ExportHeading, Roles, FeatureType, RadioData, StateType, RoleActiveActionItems, RoleDeactiveActionItems,
    ContractorDdnData, MonthData, YearData, CompanyActiveActionItems, CompanyDeactiveActionItems, ManHoursActionItems,
    FirstAidInjuryFilterData, LostTimeInjuryFilterData, RecordableInjuriesFilterData, IndexData, AddressType, FluidType,
    SpillsActionItems, SpillsActionDraftItems
};