import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import ForwardIcon from '../../../assets/images/forwardIcon.svg';
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import errorIcon from "../../../assets/images/error_icon.svg";
import { useLocation, useNavigate } from 'react-router';
import { county, facility, getRoleList } from '../../../constants/apiRoutes';
import { Controller, useForm } from 'react-hook-form';
import { Get, Post, Put } from '../../../constants/apiMethods';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';

const AddFacility = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [countyListData, setCountyList] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState('');
    const [showHideAddFacility, setShowHideAddFacility] = useState(false);
    const [facilityName, setFacilityName] = useState('');
    const [inputValueLat, setInputValueLat] = useState('');
    const [inputValueLong, setInputValueLong] = useState('');
    const token = localStorage.getItem("access_token");
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        setEditMode(props.editMode);
        getCountyList();
        props.editMode && getFacilityDetails();
    }, []);

    const getCountyList = () => {
        Get(county, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    const countyData = data?.map((resp) => {
                        return { ...resp, label: resp.name, value: resp.name }
                    })
                    setCountyList(countyData);

                }
            });
    };

    const getFacilityDetails = () => {
        setLoading(true);
        Get(facility, token, `?id=${location.state.id}&is_active=${location.state.is_active}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data) {
                    setFacilityData(data);
                }
            });
    };

    const setFacilityData = (data) => {
        setValue('facility_name', data?.facility_name);
        setValue('facility_id', data?.facility_id);
        setValue('qtr_qtr', data?.qtr_qtr);
        setValue('range', data?.range);
        setValue('location_id', data?.location_id);
        setValue('api_sequence_number', data?.api_sequence_number);
        setValue('lat', data?.lat);
        setValue('long', data?.long);
        setValue('township', data?.township);
        setValue('section', data?.section);
        setInputValueLat(data?.lat);
        setInputValueLong(data?.long);
        let obj = {
            name: data?.county?.name,
            label: data?.county?.name,
            value: data?.county?.name,
            id: data?.county?.id
        }
        setValue('county_id', obj);
        setSelectedCounty(obj);
    }

    const onCountyChange = (selection, { name }) => {
        setValue(name, selection.id);
        trigger(name);
        setSelectedCounty((selection));
    }

    const onSubmit = (data) => {
        setFacilityName(data.facility_name);
        setShowHideAddFacility(true);
    }

    const addEditFacility = () => {
        const data = getValues();
        data.county_id = selectedCounty.id;
        setLoading(true);
        setSuccessRes(false);
        if (!editMode) {
            Post(`${facility}/`, token, data, false)
                .then((res) => {
                    setLoading(false);
                    setShowHideAddFacility(false);
                    if (res.success) {
                        setSuccessRes(true);
                        setAlertType('success');
                            setShowSuccessMessage(`${facilityName} added successfully`);
                        setTimeout(() => {
                        navigate('/facility-management');
                    }, 2000)
                    } else {
                        setSuccessRes(true);
                        setAlertType('failed');
                        setShowSuccessMessage(res.detail);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else {
            Put(`${facility}/${location.state.id}/`, token, data, false)
                .then((res) => {
                    setLoading(false);
                    setShowHideAddFacility(false);
                    if (res.success) {
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(`${facilityName} updated successfully`);
                        setTimeout(() => {
                            !location.state.is_active && localStorage.setItem('getPage', 1);
                            navigate('/facility-management');
                        }, 2000)
                    } else {
                        setSuccessRes(true);
                        setAlertType('failed');
                        setShowSuccessMessage(res.detail);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }

    const keypressNumber = (event) => {
        if (event.charCode >= 48 && event.charCode <= 57) {
            return event;
        }
        else {
            event.preventDefault();
            return false;
        }
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const backToFacilityListing = () => {
       editMode && !location.state.is_active && localStorage.setItem('getPage', 1);
        navigate('/facility-management');
    }

    const handleInputChangeLat = (event) => {
        const newInputValue = event.target.value;

        if (!isNaN(newInputValue) || newInputValue === '' || newInputValue === '-') {
            setInputValueLat(newInputValue);
        } else {
            event.preventDefault();
        }
    };

    const handleInputChangeLong = (event) => {
        const newInputValue = event.target.value;

        if (!isNaN(newInputValue) || newInputValue === '' || newInputValue === '-') {
            setInputValueLong(newInputValue);
        } else {
            event.preventDefault();
        }
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div className="head_1 cursor"><span className="color-grey" onClick={backToFacilityListing}>Facility Management /</span> <span className="head_3">{editMode ? 'Update Facility' : 'Add Facility'}</span></div>
                    <div className="head_2">{editMode ? getValues('facility_name') : 'Add Facility'}</div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='container_div'>

                        <div className='row mt-30'>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Facility Name*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="facilityName"
                                    placeholder='Type here'
                                    {...register('facility_name', {
                                        required: true,
                                    })}
                                />
                                {errors.facility_name?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Facility Id*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="facilityId"
                                    placeholder='Type Here'
                                    onKeyPress={(e) => {
                                        keypressNumber(e);
                                    }}
                                    {...register('facility_id', {
                                        required: true,
                                    })}
                                />
                                {errors.facility_id?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        <div className="row mt-30">
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Qtr Qtr*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="qtr"
                                    placeholder='Type Here'
                                    {...register('qtr_qtr', {
                                        required: true,
                                    })}
                                />
                                {errors.qtr_qtr?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>County*</div>
                                <Controller
                                    name="county_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix="an-simple-select"
                                            placeholder='Choose County'
                                            options={countyListData}
                                            onChange={(selection) => onCountyChange(selection, field)}
                                            value={selectedCounty}
                                        />
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.county_id && (
                                    <p className="error"> <img src={errorIcon} /> {errors.county_id.message} </p>
                                )}
                            </div>

                        </div>

                        <div className='row mt-30'>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Range*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="range"
                                    placeholder='Type here'
                                    {...register('range', {
                                        required: true,
                                    })}
                                />
                                {errors.range?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Location Id*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="locationId"
                                    placeholder='Type Here'
                                    onKeyPress={(e) => {
                                        keypressNumber(e);
                                    }}
                                    {...register('location_id', {
                                        required: true,
                                    })}
                                />
                                {errors.location_id?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        <div className='row mt-30'>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>API Number*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="apiNumber"
                                    placeholder='Type here'
                                    onKeyPress={(e) => {
                                        keypressNumber(e);
                                    }}
                                    {...register('api_sequence_number', {
                                        required: true,
                                    })}
                                />
                                {errors.api_sequence_number?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Latitude*</div>
                                {/* <input
                                    type='text'
                                    className='form-control'
                                    name="lat"
                                    // placeholder='Type Here'
                                    // value={inputValue}
                                    onKeyPress={(e) => handleInputChange(e)}
                                    placeholder="e.g., -103.33868"
                                    {...register('lat', {
                                        required: true,
                                    })}
                                /> */}
                                <Controller
                                    name={`lat`}
                                    control={control}
                                    render={({ field }) => (
                                        <input type='text'
                                            {...field}
                                            className='form-control'
                                            name="lat"
                                            placeholder='Type Here'
                                            control={control}
                                            value={inputValueLat}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChangeLat(e);
                                            }}
                                        />
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.lat?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        <div className='row mt-30'>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Longitude*</div>
                                <Controller
                                    name={`long`}
                                    control={control}
                                    render={({ field }) => (
                                        <input type='text'
                                            {...field}
                                            className='form-control'
                                            name="lat"
                                            placeholder='Type Here'
                                            control={control}
                                            value={inputValueLong}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleInputChangeLong(e);
                                            }}
                                        />
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.long?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Township*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="township"
                                    placeholder='Type Here'
                                    {...register('township', {
                                        required: true,
                                    })}
                                />
                                {errors.township?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        <div className='row mt-30'>
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Section*</div>
                                <input
                                    type='text'
                                    className='form-control'
                                    name="section"
                                    placeholder='Type here'
                                    onKeyPress={(e) => {
                                        keypressNumber(e);
                                    }}
                                    {...register('section', {
                                        required: true,
                                    })}
                                />
                                {errors.section?.type == "required" && (
                                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="text-right">
                        <button type="button" className='btn-add-data mr-15' onClick={backToFacilityListing}>Back</button>
                        <button type="submit" className='btn-event-incident in-blck mt-30'>{editMode ? 'Update Facility' : 'Submit Facility'}</button>
                    </div>
                </form>

            </div>

            <Modal show={showHideAddFacility} dialogClassName="reportConfrmModal" onHide={() => setShowHideAddFacility(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you want to {editMode ? 'update' : 'add'} {facilityName}?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" data-bs-dismiss="modal" onClick={() => { setShowHideAddFacility(false) }}>No</button>
                        <button type="button" className="btn-yes" onClick={addEditFacility}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddFacility