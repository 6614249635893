import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import errorIcon from "../../../../../assets/images/error_icon.svg";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import data from '../../../../../mockData/mockData';
import { Post, Get, Put } from "../../../../../constants/apiMethods";
import { agency, getFacilities, getSpillsList, getStateListing, spill } from "../../../../../constants/apiRoutes";
import LoadingSpinner from '../../../../../components/Loader';
import AlertNotifier from '../../../../../components/Notifications/AlertNotifier';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router';
import { Modal } from 'react-bootstrap';
import { SUBMITTED_STATUS } from '../../../../../constants/Global';

const SpillDetails = ({ next, editMode, isDraftMode }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [agencyList, setAgencyList] = useState([]);
    const [isFieldRequired, setisFieldRequired] = useState(false);
    const [ifCategoryReportableRequired, setifCategoryReportableRequired] = useState(false);
    const [closureDate, setClosureDate] = useState(null);
    const [discoveryDate, setDiscoveryDate] = useState(null);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [checkIfOtherLocationIsRequired, setcheckIfOtherLocationIsRequired] = useState(false);
    const [selectedFluidType, setSelectedFluidType] = useState([]);
    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [selectedMeridian, setSelectedMeridian] = useState('');
    const [recordStatus, setRecordStatus] = useState('');
    const [changeEditMode, setEditMode] = useState(editMode);
    const [showCancelModal, setshowHideAddCancelPopup] = useState(false);
    const [isFluidTypeSelected, setIsFluidTypeSelected] = useState(false);
    const [newFacility, setNewFacility] = useState(false);
    const token = localStorage.getItem('access_token');

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        trigger,
        clearErrors,
        formState: { errors },
    } = useForm();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        getStateList();
        if (isDraftMode == 'Draft') {
            setEditMode(true);
        }
        (editMode || isDraftMode == 'Draft') && getDetailsById();
    }, []);

    const getDetailsById = () => {
        const step_one_submitted = localStorage.getItem('step_one_submitted');
        let spill_id = '';
        if (step_one_submitted) {
            spill_id = localStorage.getItem('spill_id');
        }
        let id = step_one_submitted ? spill_id : location.state.id
        setLoading(true);
        Get(getSpillsList, token, '?id=' + id, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setRecordStatus(data?.data?.record_status);
                    setValue('name', data?.data?.name);
                    setValue('spill_status', data?.data?.spill_status);
                    if (getValues('spill_status') == 'Closed') {
                        setValue('date_of_closure', data?.data?.date_of_closure);
                        setClosureDate(data?.data?.date_of_closure ? new Date(data?.data?.date_of_closure) : null);
                        setisFieldRequired(true);
                    }
                    setValue('category', data?.data?.spill_category);
                    if (getValues('category') == 'Reportable') {
                        getAgency();
                        const addedAgencyData = data?.data?.agency?.map(res => {
                            return {
                                label: res, value: res
                            }
                        })
                        setSelectedAgency(addedAgencyData);
                        setValue('agency', addedAgencyData);
                    }
                    setValue('date_of_discovery', data?.data?.date_of_discovery);
                    setDiscoveryDate(data?.data?.date_of_discovery ? new Date(data?.data?.date_of_discovery) : null);
                    if (data?.data?.time) {
                        const splitTime = data?.data?.time.split(':');
                        const splitMeridian = splitTime[1].split(' ');
                        let hourObj = {
                            value: splitTime[0] ? splitTime[0] : '',
                            label: splitTime[0] ? splitTime[0] : '',
                            name: splitTime[0] ? splitTime[0] : '',
                        }
                        let minuteObj = {
                            value: splitMeridian[0] ? splitMeridian[0] : '',
                            label: splitMeridian[0] ? splitMeridian[0] : '',
                            name: splitMeridian[0] ? splitMeridian[0] : '',
                        }
                        let meridianObj = {
                            value: splitMeridian[1] ? splitMeridian[1] : '',
                            label: splitMeridian[1] ? splitMeridian[1] : '',
                            name: splitMeridian[1] ? splitMeridian[1] : '',
                        }
                        setValue('hour', hourObj);
                        setSelectedHour(hourObj);
                        setValue('minutes', minuteObj);
                        setSelectedMinutes(minuteObj);
                        setValue('meridian', meridianObj);
                        setSelectedMeridian(meridianObj);
                    }
                    let stateObj = {
                        value: data?.data?.state ? data?.data?.state : '',
                        label: data?.data?.state ? data?.data?.state : '',
                        id: data?.data?.state_id ? data?.data?.state_id : 'null',
                    }
                    let locationObj = {
                        value: data?.data?.facility_name ? data?.data?.facility_name : 'Others',
                        label: data?.data?.facility_name ? data?.data?.facility_name : 'Others',
                        id: data?.data?.facility_id ? data?.data?.facility_id : 'null',
                    }
                    setValue('state_id', stateObj);
                    setSelectedState(stateObj);
                    getLocationById(data?.data?.state_id);
                    setValue('facility_id', locationObj);
                    setSelectedLocation(locationObj);
                    if (data?.data?.facility_name == null) {
                        setValue('location', data?.data?.location);
                    }
                    setValue('spill_type', data?.data?.spill_type);
                    const fluidTypeData = data?.data?.fluid_type?.map((resp) => {
                        return {
                            label: resp,
                            value: resp,
                            name: resp,
                            errorKey_released: fluidTypeKeyNames(resp) + '_volume_released',
                            errorKey_recovered: fluidTypeKeyNames(resp) + '_volume_recovered'
                        }
                    })
                    setSelectedFluidType(fluidTypeData);
                    setValue('fluid_type', fluidTypeData);
                    fluidTypeData?.map((resp) => {
                        setValue(`${resp.errorKey_released}`, data?.data[resp?.errorKey_released]);
                        setValue(`${resp.errorKey_recovered}`, data?.data[resp?.errorKey_recovered])
                    });
                    if (fluidTypeData?.length > 0) {
                        setIsFluidTypeSelected(true);
                    } else {
                        setIsFluidTypeSelected(false);
                    }
                }
            });
    }

    const getAgency = () => {
        setLoading(true);
        Get(agency, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    setLoading(false);
                    const agencyData = data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    setAgencyList(agencyData);
                }
            });
    }

    const onSubmit = (data) => {
        setLoading(true);

        data.oil_spill_volume_recovered = Number(data.oil_spill_volume_recovered);
        data.oil_spill_volume_released = Number(data.oil_spill_volume_released);
        data.produced_water_volume_recovered = Number(data.produced_water_volume_recovered);
        data.produced_water_volume_released = Number(data.produced_water_volume_released);
        data.flow_back_fluid_spill_volume_recovered = Number(data.flow_back_fluid_spill_volume_recovered);
        data.flow_back_fluid_spill_volume_released = Number(data.flow_back_fluid_spill_volume_released);
        data.ep_waste_spill_volume_recovered = Number(data.ep_waste_spill_volume_recovered);
        data.ep_waste_spill_volume_released = Number(data.ep_waste_spill_volume_released);
        data.condensate_spill_volume_recovered = Number(data.condensate_spill_volume_recovered);
        data.condensate_spill_volume_released = Number(data.condensate_spill_volume_released);
        data.drilling_fluid_volume_recovered = Number(data.drilling_fluid_volume_recovered);
        data.drilling_fluid_volume_released = Number(data.drilling_fluid_volume_released);

        if (getValues('spill_status') == 'Open') {
            delete data.date_of_closure;
        }

        delete data.state;

        if (!changeEditMode) {

            data.facility_id = data.facility_id == 'null' ? null : data.facility_id;
            if (data.facility_id) {
                delete data.location;
            }
            data.time = (data.meridian == 'AM' || data.meridian == 'PM') ? data.hour + ':' + data?.minutes + ' ' + data.meridian : null;

            delete data.hour;
            delete data.minutes;
            delete data.meridian;

        }

        data.record_status = recordStatus;

        data.agency?.map((resp) => {
            return delete resp.name, delete resp.value, delete resp.id;
        })
        const agencyArray = data.agency?.flatMap(obj => Object.values(obj));

        data.agency = agencyArray;

        const fluidTypeArray = [];
        data.fluid_type?.map((resp) => {
            fluidTypeArray.push(resp.name);
        })

        data.fluid_type = [...fluidTypeArray];

        const step_one_submitted = localStorage.getItem('step_one_submitted');
        let spiil_id = '';
        if (step_one_submitted) {
            spiil_id = localStorage.getItem('spiil_id');
        }
        if (!changeEditMode) {
            Post(spill, token, data, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (recordStatus == 'Draft') {
                            navigate('/spills-management');
                        } else {
                            next(data);
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                });
        } else {
            const hour = (data?.hour?.name) ? data?.hour?.name : data?.hour;
            const minutes = (data?.minutes?.name) ? data?.minutes?.name : data?.minutes;
            const meridian = (data?.meridian?.name) ? data?.meridian?.name : data?.meridian;
            data.time = (meridian == 'AM' || meridian == 'PM') ? hour + ':' + minutes + ' ' + meridian : null;
            delete data.hour;
            delete data.minutes;
            delete data.meridian;
            data.state_id = data?.state_id?.id ? data?.state_id?.id : data?.state_id;
            if (newFacility) {
                data.facility_id = data.facility_id;
                if (data.facility_id == 'null') {
                    data.facility_id = null;
                }
            } else {
                data.facility_id = data.facility_id.id ? data.facility_id.id : data.facility_id;
                if (data.facility_id == 'null') {
                    data.facility_id = null;
                }
            }

            if (data?.facility_id) {
                delete data.location;
            }

            const step_one_submitted = localStorage.getItem('step_one_submitted');
            let spill_id = '';
            if (step_one_submitted) {
                spill_id = localStorage.getItem('spill_id');
            }
            let id = step_one_submitted ? spill_id : location.state.id
            Put(spill + id + '/', token, data, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (recordStatus == 'Draft') {
                            navigate('/spills-management');
                        } else {
                            next(data);
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                });
        }
    }

    const onCancel = () => {
        navigate('/spills-management');
    }

    const getStateList = () => {
        setLoading(true);
        Get(getStateListing, token, `?sort=name`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    const stateData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    setStateList(stateData);
                }
            });
    }

    const getLocationById = (id) => {
        setLoading(true);
        Get(getFacilities, token, `?min=${true}&state=${id}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(true);
                let transformData = data;
                let updatedData = transformData.map(item => ({
                    ...item,
                    value: `${item.facility_name}`,
                    label: `${item.facility_name}`
                }));
                const otherLocationObject = { id: 'null', facility_name: 'Others', value: 'Others', label: 'Others' };
                const newArray = [otherLocationObject, ...updatedData];
                setFacilities(newArray);
                setLoading(false);
            });
    }

    const onStatusRadioChange = (e) => {
        const { value } = e.target;
        setValue('spill_status', value);
        trigger('spill_status');
        if (value == 'Open') {
            setisFieldRequired(false);
            clearErrors('date_of_closure');
        } else {
            setisFieldRequired(true);
        }
    }

    const onCategoryRadioChange = (e) => {
        const { value } = e.target;
        setValue('category', value);
        trigger('category');
        if (value == 'Reportable') {
            setifCategoryReportableRequired(true);
            clearErrors('agency');
            getAgency();
        } else {
            setifCategoryReportableRequired(false);
        }
    }

    const onSpillTypeRadioChange = (e) => {
        const { value } = e.target;
        setValue('spill_type', value);
        trigger('spill_type');
    }

    const setDateOfClosureValidation = (dateObj) => {
        const transformDate = moment(dateObj).format('MM-DD-YYYY');
        setValue('date_of_closure', transformDate);
        trigger('date_of_closure');
    }

    const setDateOfDiscoveryValidation = (dateObj) => {
        const transformDate = moment(dateObj).format('MM-DD-YYYY');
        setValue('date_of_discovery', transformDate);
        trigger('date_of_discovery');
    }

    const onSelectionChange = (selection, { name }) => {
        setValue(name, selection.value);
        trigger(name);
        if (name === 'hour') {
            setSelectedHour(selection)
        }
        if (name === 'minutes') {
            setSelectedMinutes(selection)
        }
        if (name === 'meridian') {
            setSelectedMeridian(selection)
        }
        if (name == 'agency') {
            setValue(name, selection);
            setSelectedAgency((selection));
            trigger(name);
        }
        if (name === 'state_id') {
            setSelectedState(selection);
            getLocationById(selection.id);
            setValue(name, selection.id);
            trigger(name);
            setSelectedLocation('');
        }
        if (name == 'facility_id') {
            setSelectedLocation(selection);
            setValue(name, selection.id ? selection?.id : null);
            trigger(name);
            setNewFacility(true);
            if (selection.value == 'Others') {
                setcheckIfOtherLocationIsRequired(true);
            } else {
                setcheckIfOtherLocationIsRequired(false);
            }
        }
        if (name == 'fluid_type') {
            setValue(name, selection);
            trigger(name);
            let updatedData = selection.map(item => ({
                ...item,
                errorKey_released: fluidTypeKeyNames(item?.value) + '_volume_released',
                errorKey_recovered: fluidTypeKeyNames(item?.value) + '_volume_recovered'
            }));
            setSelectedFluidType(updatedData);
            if (updatedData?.length > 0) {
                setIsFluidTypeSelected(true);
            } else {
                setIsFluidTypeSelected(false);
            }
        }
    }

    const fluidTypeKeyNames = (value) => {
        switch (value) {
            case 'Oil':
                return 'oil_spill';

            case 'Produced Water':
                return 'produced_water';

            case 'Flow Back Fluid':
                return 'flow_back_fluid_spill';

            case 'Other E&P Waste':
                return 'ep_waste_spill';

            case 'Drilling Fluid':
                return 'drilling_fluid';

            case 'Condensate':
                return 'condensate_spill';
        }
    }

    const keypressNumber = (event) => {
        if (event.charCode >= 48 && event.charCode <= 57) {
            return Number(event);
        }
        else {
            event.preventDefault();
            return false;
        }
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            <form className="mt-30" onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>

                    <div className='row'>

                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Spill Name*</div>
                            <input
                                type='text'
                                className='form-control'
                                name='spill_name'
                                placeholder='Type here'
                                {...register('name', { required: true })}
                            />
                            {errors.name?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Spill Status*</div>
                            <div className='rdImpactdGroup mt-16'>
                                <Controller
                                    name='spill_status'
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type='radio'
                                                id='spill_status_closed'
                                                value={'Closed'}
                                                name='spill_status'
                                                onChange={(e) => onStatusRadioChange(e)}
                                                checked={getValues('spill_status') == 'Closed'}
                                            />

                                            <label htmlFor={'spill_status_closed'}>Closed</label>

                                            <input
                                                type='radio'
                                                id='spill_status_open'
                                                value={'Open'}
                                                name='spill_status'
                                                onChange={(e) => onStatusRadioChange(e)}
                                                checked={getValues('spill_status') == 'Open'}
                                            />
                                            <label htmlFor={'spill_status_open'}>Open</label>
                                        </>
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.spill_status && (
                                    <p className="error mt-2"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        {getValues('spill_status') == 'Closed' && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Date of Closure*</div>
                            <Controller
                                name='date_of_closure'
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={closureDate}
                                        onChange={(date) => { setClosureDate(date); setDateOfClosureValidation(date) }}
                                        className='form-control'
                                        placeholderText='Choose the closure date'
                                        dateFormat="MM-dd-yyyy"
                                        minDate={discoveryDate}
                                        maxDate={today}
                                    />
                                )}
                                rules={{ required: isFieldRequired }}
                            />
                            {isFieldRequired && errors.date_of_closure && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>}

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Spill Category*</div>
                            <div className='rdImpactdGroup mt-16'>
                                <Controller
                                    name='category'
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type='radio'
                                                id='spill_category_reportable'
                                                value={'Reportable'}
                                                name='category'
                                                onChange={(e) => onCategoryRadioChange(e)}
                                                checked={getValues('category') == 'Reportable'}
                                            />

                                            <label htmlFor={'spill_category_reportable'}>Reportable</label>

                                            <input
                                                type='radio'
                                                id='spill_category_non_reportable'
                                                value={'Non-Reportable'}
                                                name='category'
                                                onChange={(e) => onCategoryRadioChange(e)}
                                                checked={getValues('category') == 'Non-Reportable'}
                                            />
                                            <label htmlFor={'spill_category_non_reportable'}>Non Reportable</label>
                                        </>
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.category && (
                                    <p className="error mt-2"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        {getValues('category') == 'Reportable' && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Reportable (Agency)*</div>
                            <Controller
                                name="agency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        placeholder='Choose the Fluid spill type'
                                        options={agencyList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedAgency}
                                        isMulti
                                    />
                                )}
                                rules={{ required: ifCategoryReportableRequired }}
                            />
                            {errors.agency && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>}

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>State*</div>
                            <Controller
                                name="state_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="state"
                                        placeholder='Choose the State where the spill occured'
                                        options={stateList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedState}
                                    />
                                )}
                                rules={{ required: true }}
                            />
                            {errors.state_id && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Facility Name*</div>
                            <Controller
                                name="facility_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="location"
                                        placeholder='Choose the location where the spill occured'
                                        options={facilities}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedLocation}
                                    />
                                )}
                                rules={{ required: true }}
                            />
                            {errors.facility_id && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>

                        {selectedLocation?.value == 'Others' && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Please type in the Location*</div>
                            <input
                                type='text'
                                className='form-control'
                                name="location"
                                placeholder='Type here'
                                {...register("location", { required: checkIfOtherLocationIsRequired })}
                            />
                            {errors.location?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>}

                    </div>

                </div>

                <div className='container_div'>

                    <div className='row'>
                        <div className='col-md-6 pt-2'>
                            <div className='inputFieldHead'>Discovery Date{getValues('spill_status') == 'Closed' && '*'}</div>
                            <Controller
                                name='date_of_discovery'
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={discoveryDate}
                                        onChange={(date) => { setDiscoveryDate(date); setDateOfDiscoveryValidation(date) }}
                                        className='form-control'
                                        placeholderText='Choose Date'
                                        dateFormat="MM-dd-yyyy"
                                        maxDate={closureDate ? closureDate : today}
                                    />
                                )}
                                rules={{ required: isFieldRequired }}
                            />
                            {isFieldRequired && errors.date_of_discovery && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Time{getValues('spill_status') == 'Closed' && '*'}</div>
                            <div className='row mt-16'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="hour"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="hour"
                                                placeholder='Hour'
                                                options={data.Hours}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedHour}
                                            />
                                        )}
                                        rules={{ required: isFieldRequired }}
                                    />
                                    {isFieldRequired && errors.hour && (
                                        <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="minutes"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="minutes"
                                                placeholder='Minutes'
                                                options={data.Minutes}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMinutes}
                                            />
                                        )}
                                        rules={{ required: isFieldRequired }}
                                    />
                                    {isFieldRequired && errors.minutes && (
                                        <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="meridian"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="meridian"
                                                placeholder='Meridian'
                                                options={data.Meridian}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMeridian}
                                            />
                                        )}
                                        rules={{ required: isFieldRequired }}
                                    />
                                    {isFieldRequired && errors.meridian && (
                                        <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Spill Type{getValues('spill_status') == 'Closed' && '*'}</div>
                            <div className='rdImpactdGroup mt-16'>
                                <Controller
                                    name='spill_type'
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type='radio'
                                                id='spill_type_historical'
                                                value={'Historical'}
                                                name='spill_type'
                                                onChange={(e) => onSpillTypeRadioChange(e)}
                                                checked={getValues('spill_type') == 'Historical'}
                                            />

                                            <label htmlFor={'spill_type_historical'}>Historical</label>

                                            <input
                                                type='radio'
                                                id='spill_type_recent'
                                                value={'Recent'}
                                                name='spill_type'
                                                onChange={(e) => onSpillTypeRadioChange(e)}
                                                checked={getValues('spill_type') == 'Recent'}
                                            />
                                            <label htmlFor={'spill_type_recent'}>Recent</label>
                                        </>
                                    )}
                                    rules={{ required: isFieldRequired }}
                                />
                                {isFieldRequired && errors.spill_type && (
                                    <p className="error mt-2"> <img src={errorIcon} /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Fluid Type{getValues('spill_status') == 'Closed' && '*'}</div>
                            <Controller
                                name="fluid_type"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix="an-simple-select"
                                        placeholder='Choose the Fluid spill type'
                                        options={data.FluidType}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedFluidType}
                                        isMulti
                                    />
                                )}
                                rules={{ required: isFieldRequired }}
                            />
                            {isFieldRequired && errors.fluid_type && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>

                        {selectedFluidType?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className='col-md-6 mt-30'>
                                        <div className='inputFieldHead'>{item?.value} Volume Released (bbl){getValues('spill_status') == 'Closed' && '*'}</div>
                                        <Controller
                                            name={`${item?.errorKey_released}`}
                                            control={control}
                                            render={({ field }) => (
                                                <input type='text'
                                                    {...field}
                                                    className='form-control'
                                                    placeholder='Type here'
                                                    onKeyPress={(e) => {
                                                        keypressNumber(e);
                                                    }}
                                                    control={control}
                                                />
                                            )}
                                            rules={{ required: isFieldRequired || isFluidTypeSelected }}
                                        />
                                        {(isFieldRequired || isFluidTypeSelected) && errors[`${item.errorKey_released}`] && (
                                            <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                                        )}

                                    </div>

                                    <div className='col-md-6 mt-30'>
                                        <div className='inputFieldHead'>{item?.value} Volume Recovered (bbl){getValues('spill_status') == 'Closed' && '*'}</div>
                                        <Controller
                                            name={`${item?.errorKey_recovered}`}
                                            control={control}
                                            render={({ field }) => (
                                                <input type='text'
                                                    {...field}
                                                    className='form-control'
                                                    placeholder='Type here'
                                                    onKeyPress={(e) => {
                                                        keypressNumber(e);
                                                    }}
                                                    control={control}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: isFieldRequired || isFluidTypeSelected,
                                                    message: "This field is mandatory",
                                                },
                                                validate: {
                                                    lessThanOrEqualRecovered: (value) => {
                                                        const recoveredValue = getValues(`${item?.errorKey_released}`);
                                                        return parseFloat(value) <= parseFloat(recoveredValue) || `${item?.value} recovered volume should be less than or equal to ${item?.value} released volume.`;
                                                    },
                                                }
                                            }}
                                        />
                                        {(isFieldRequired || isFluidTypeSelected) && errors[`${item.errorKey_recovered}`] && (
                                            <p className="error"> <img src={errorIcon} /> {errors[`${item.errorKey_recovered}`]?.message} </p>
                                        )}
                                        {/* {errors[`${item.value}_volume_recovered`] && errors[`${item.value}_volume_recovered`]?.type === "lessThanOrEqualRecovered" && (
                                            <p className="error"> <img src={errorIcon} /> {errors[`${item.value}_volume_recovered`]?.message}</p>
                                        )} */}

                                    </div>
                                </React.Fragment>
                            )
                        })}

                    </div>

                </div>

                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={() => setshowHideAddCancelPopup(true)}>Cancel</button>
                    {recordStatus !== SUBMITTED_STATUS &&<button type="submit" className='btn-add-data mr-15' onClick={() => setRecordStatus('Draft')}>Save As Draft</button>}
                    <button type="submit" className='btn-proceed in-blck mt-30' onClick={() => setRecordStatus('Submitted')}>Proceed</button>
                </div>

            </form>

            <Modal show={showCancelModal} dialogClassName="reportConfrmModal" onHide={() => setshowHideAddCancelPopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText width_text">
                    <div>Are you sure you want to cancel it ?</div>
                        <div className="mt-2">By canceling, you will lose all the information you've {!changeEditMode ? 'entered' : 'updated'}.</div>
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideAddCancelPopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setshowHideAddCancelPopup(false); onCancel() }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SpillDetails;