import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import logoImg from '../../../assets/images/logo.svg';
import CollapseIn from '../../../assets/images/CollapseIn.svg';
import CollapseOut from '../../../assets/images/CollapseOut.svg';
import liticoIcon from '../../../assets/images/LiticoIcon.svg';
import OverviewImg from '../../../assets/images/Overview.svg';
import PermittanceImg from '../../../assets/images/Permittance.svg';
import SafetyImg from '../../../assets/images/Safety.svg';
import ComplianceImg from '../../../assets/images/Compliance.svg';
import MnAImg from '../../../assets/images/M&A.svg';
import EsgImg from '../../../assets/images/ESG.svg';
import AirImg from '../../../assets/images/Air.svg';
import BenchmarkingImg from '../../../assets/images/Benchmarking.svg';
import SettingsImg from '../../../assets/images/Settings.svg';
import DropCollapseUp from '../../../assets/images/DropCollapseUp.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import CustomizeEventImg from '../../../assets/images/CustomizeEvent.svg';
import CustomizeIncidentImg from '../../../assets/images/CustomizeIncident.svg';
import FaqImg from '../../../assets/images/Faq.svg';
import ContactUsImg from '../../../assets/images/ContactUs.svg';
import FeedbackImg from '../../../assets/images/Feedback.svg';
import SupportImg from '../../../assets/images/Support.svg';
import SelectedOverview from '../../../assets/images/SelectedOverview.svg';
import SelectedSafety from '../../../assets/images/SelectedSafety.svg';
import SelectedPermitting from '../../../assets/images/SelectedPermitting.svg';
import SelectedComplaince from '../../../assets/images/SelectedComplaince.svg';
import SelectedMnA from '../../../assets/images/SelectedM&A.svg';
import SelectedEsg from '../../../assets/images/SelectedESG.svg';
import SelectedAir from '../../../assets/images/SelectedAir.svg';
import SelectedBenchMarking from '../../../assets/images/SelectedBenchMarking.svg';
import SelectedSettings from '../../../assets/images/SelectedSettings.svg';
import SelectedSupport from '../../../assets/images/SelectedSupport.svg';
import SubscriptionImg from '../../../assets/images/subscriptionIcon.svg';
import SelectedSubscriptionIcon from '../../../assets/images/subscriptionActiveIcon.svg';
import OnboardingImg from '../../../assets/images/onboardingIcon.svg';
import SelectedOnboardingImg from '../../../assets/images/onboardingActiveIcon.svg';
import StateCityIcon from '../../../assets/images/stateCityIcon.svg';
import SelectedStateCityImg from '../../../assets/images/stateCityActiveIcon.svg';
import ManagementImg from '../../../assets/images/ManagementImg.svg';
import SelectedManagement from '../../../assets/images/SelectedManagement.svg';
import FacilityManagement from '../../../assets/images/FacilityManagement.svg';
import RoleandAccessManagement from '../../../assets/images/RoleandAccessManagement.svg';
import UserManagement from '../../../assets/images/UserManagement.svg';
import ContractorManagement from '../../../assets/images/ContractorManagement.svg';
import Spills from '../../../assets/images/Spills.svg';
import Remediation from '../../../assets/images/Remediation.svg';
import Complaints from '../../../assets/images/Complains.svg';
import Production from '../../../assets/images/Production.svg';
import Inspection from '../../../assets/images/Inspection.svg';
import Wildlife from '../../../assets/images/Wildlife.svg';
import "./Sidebar.css";

const myFunction = () => {
    console.log('Function called');
  };

let sidebarItems = [
    {
        path: "/overview",
        name: "Overview",
        icon: <img src={OverviewImg} />,
        selectedIcon: <img src={SelectedOverview} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/safety",
        name: "Safety",
        // subData: "Event-list",
        icon: <img src={SafetyImg} />,
        selectedIcon: <img src={SelectedSafety} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/permitting",
        name: "Permitting",
        icon: <img src={PermittanceImg} />,
        selectedIcon: <img src={SelectedPermitting} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        // path: "/compliance",
        name: "Compliance",
        icon: <img src={ComplianceImg} />,
        selectedIcon: <img src={SelectedComplaince} />,
        isSubMenuVisible: false,
        isNested: false,
        subNav: [
            {
                path: '/spills-dashboard',
                name: 'Spills',
                icon: <img src={Spills} />,
                mainModuleName: 'Compliance',
                isNested: true,
            },
            {
                path: '/remediation',
                name: 'Remediation',
                icon: <img src={Remediation} />,
                mainModuleName: 'Compliance',
                isNested: true,
            },
            {
                path: '/complaints-dashboard',
                name: 'Complaints',
                icon: <img src={Complaints} />,
                mainModuleName: 'Compliance',
                isNested: true
            },
            {
                path: '/production',
                name: 'Production',
                icon: <img src={Production} />,
                mainModuleName: 'Compliance',
                isNested: true
            },
            {
                path: '/inspection',
                name: 'Inspection',
                icon: <img src={Inspection} />,
                mainModuleName: 'Compliance',
                isNested: true
            },
            {
                path: '/wildlife',
                name: 'Wildlife',
                icon: <img src={Wildlife} />,
                mainModuleName: 'Compliance',
                isNested: true
            },
        ]
    },
    {
        path: "/m&a",
        name: "M&A",
        icon: <img src={MnAImg} />,
        selectedIcon: <img src={SelectedMnA} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/esg",
        name: "ESG",
        icon: <img src={EsgImg} />,
        selectedIcon: <img src={SelectedEsg} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/air",
        name: "Air",
        icon: <img src={AirImg} />,
        selectedIcon: <img src={SelectedAir} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/benchmarking",
        name: "Benchmarking",
        icon: <img src={BenchmarkingImg} />,
        selectedIcon: <img src={SelectedBenchMarking} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        name: "Management",
        icon: <img src={ManagementImg} />,
        selectedIcon: <img src={SelectedManagement} />,
        isSubMenuVisible: false,
        isNested: false,
        subNav: [
            {
                path: '/facility-management',
                name: 'Facility',
                icon: <img src={FacilityManagement} />,
                mainModuleName: 'Management',
                isNested: true
                // selectedIcon: <img src={SelectedFacilityManagement} />,
            },
            {
                path: '/roles-list',
                name: 'Role Access',
                icon: <img src={RoleandAccessManagement} />,
                mainModuleName: 'Management',
                isNested: true
                // selectedIcon: <img src={SelectedRoleAndAccessManagement} />,
            },
            {
                path: '/users-list',
                name: 'User',
                icon: <img src={UserManagement} />,
                mainModuleName: 'Management',
                isNested: true
                // selectedIcon: <img src={SelectedUserManagement} />,
            },
            {
                path: '/contractor-management',
                name: 'Contractor',
                icon: <img src={ContractorManagement} />,
                mainModuleName: 'Management',
                isNested: true
                // selectedIcon: <img src={SelectedContractorManagement} />,
            },
        ]
    },
    {
        name: "Settings",
        icon: <img src={SettingsImg} />,
        selectedIcon: <img src={SelectedSettings} />,
        isSubMenuVisible: false,
        isNested: false,
        subNav: [
            {
                path: '/customize-event-label',
                name: 'Event Label',
                icon: <img src={CustomizeEventImg} />,
                mainModuleName: 'Settings',
                isNested: true
                // selectedIcon: <img src={SelectedCustomiozeEvent} />,
            },
            {
                path: '/customize-incident-label',
                name: 'Incident Label',
                icon: <img src={CustomizeIncidentImg} />,
                mainModuleName: 'Settings',
                isNested: true
                // selectedIcon: <img src={SelectedCustomiozeIncident} />,
            },
        ]
    },
    {
        name: "Support",
        icon: <img src={SupportImg} />,
        selectedIcon: <img src={SelectedSupport} />,
        isSubMenuVisible: false,
        isNested: false,
        subNav: [
            {
                path: '/faq',
                name: 'FAQ',
                icon: <img src={FaqImg} />,
                mainModuleName: 'Support',
                isNested: true
                // selectedIcon: <img src={SelectedFAQ} />,
            },
            {
                path: '/contact',
                name: 'Contact',
                icon: <img src={ContactUsImg} />,
                mainModuleName: 'Support',
                isNested: true
                // selectedIcon: <img src={SelectedContactUs} />,
            },
            {
                path: '/feedback',
                name: 'Feedback',
                icon: <img src={FeedbackImg} />,
                mainModuleName: 'Support',
                isNested: true
                // selectedIcon: <img src={SelectedFeedback} />,
            },
        ]

    }
]

const sidebarAdminItems = [
    {
        path: "/subscriptions",
        name: "Subscriptions",
        icon: <img src={SubscriptionImg} />,
        selectedIcon: <img src={SelectedSubscriptionIcon} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/company-list",
        name: "Onboarding",
        icon: <img src={OnboardingImg} />,
        selectedIcon: <img src={SelectedOnboardingImg} />,
        isSubMenuVisible: false,
        isNested: false
    },
    {
        path: "/state-city",
        name: "State & City",
        icon: <img src={StateCityIcon} />,
        selectedIcon: <img src={SelectedStateCityImg} />,
        isSubMenuVisible: false,
        isNested: false
    },
]


const Sidebar = ({ children, onSelectSidebarIcon }) => {
    let copiedSidebarItems = sidebarItems;
    const location = useLocation();
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    const isSidebarShow = localStorage.getItem('isSidebarShow');
    let userSubItems = sidebarItems.filter((resp) => resp?.name !== 'Management');

    if (role === 'user') {
        const filteredSidebar = userSubItems.filter(item => {
            return permissions?.some(permission => permission.moduleName === item.name);
        });
        userSubItems = [...filteredSidebar];
    }

    if (role === 'company_admin') {
        let filteredSidebar = sidebarItems.filter(item => {
            return permissions?.some(permission => permission.moduleName === item.name);
        });
        let supportSidebarItems = sidebarItems?.filter(item => item.name === 'Support' || item.name === 'Management' || item.name === 'Settings');
        sidebarItems = [...filteredSidebar, ...supportSidebarItems];
    }

    const [menuItem, setmenuItem] = useState(isSidebarShow && (role == 'super_admin' ? 
    sidebarAdminItems : role == 'user' ? userSubItems : role == 'company_admin' ? sidebarItems : []));
    const [subNav, showSubNav] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(location.pathname.split('/')[1].charAt(0).toUpperCase() + location.pathname.split('/')[1].slice(1));
    const [subName, setSubName] = useState(location.pathname.split('/')[1].charAt(0).toUpperCase() + location.pathname.split('/')[1].slice(1));
    const [footerText, setFooterText] = useState('2023  All rights reserved')
    const toggle = () => {
        showSubNav(false);
        setIsOpen(!isOpen);
        onSelectSidebarIcon(!isOpen);
        const onCloseItems = menuItem.map((item) => {
            // item.isVisible = false;
            if (item.isVisible) {
                item.isVisible = true;
            } else {
                item.isVisible = false;
            }
            return item;
        });

        setmenuItem(onCloseItems);
    };

    const handleSidebarSubMenuItems = (name) => {
        const triggeredItems = menuItem.map((item) => {
            if (item.name === name) {
                item.isVisible = !item.isVisible;
            }

            return item;
        });

        setmenuItem(triggeredItems);
    };

    const navigateToPage = (item) => {
        if (!item.isNested) {
            setName(item.name);
            navigate(item.path);
        } else {
            setSubName(item.name);
            navigate(item.path);
            setName(item.mainModuleName);
        }
    };

    return (
        <div className="container">
            <div className={isOpen ? 'open-sidebar sidebar' : 'close-sidebar sidebar'}>
                <div className="top_section">
                    <h1 className={isOpen ? 'litico-logo' : 'litico-icon'}><img className='litico-img' src={isOpen ? liticoIcon : logoImg} /></h1>
                    <div style={{ marginLeft: isOpen ? "100px" : "0px" }} className="bars">
                        <img src={isOpen ? CollapseIn : CollapseOut} className='cursor' onClick={toggle} />
                    </div>
                </div>
                {menuItem.map((item, index) => (
                    <div key={index}>
                        <div className={(item?.subData ? item.subData : item.name) == name ? 'active-sidebar link' : 'link'} onClick={() => {
                            navigateToPage(item);
                        }}>
                            <div className="icon" onClick={() => {
                                handleSidebarSubMenuItems(item.name);
                            }}>{(item?.subData ? item.subData : item.name) == name ? item.selectedIcon : item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} onClick={() => {
                                handleSidebarSubMenuItems(item.name);
                            }} className="link-text"><span>{item.name}</span>
                                {/* {item.subNav && <span className=''><img className='px-2'
                                    src={item.isVisible ? DropCollapseUp : DropCollapseDown} /></span>} */}
                            </div>
                            {item.subNav && <span className='ml-move' onClick={() => {
                                handleSidebarSubMenuItems(item.name);
                            }}><img className='img-width'
                                    src={item.isVisible ? DropCollapseUp : DropCollapseDown} /></span>}
                        </div>
                        <div className={(item?.subNav && isOpen && item.isVisible ? 'sidebarStyle pt-1' : 'sidebarStyle')}>
                            {item.subNav && item.subNav.map((subItem, subIndex) => (
                                <div key={subIndex} hidden={!item.isVisible} className={subItem.name == subName ? 'active-sub-menu link py-2 mt-2' : 'link py-2 mt-2'} onClick={() => {
                                    navigateToPage(subItem);
                                }}>
                                    <div className="icon px-2">{subItem.icon}</div>
                                    <div style={{ display: isOpen ? "block" : "none" }} className="link-text">{subItem.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                <div className="footer-text pb-4">&copy; {footerText}</div>
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;
