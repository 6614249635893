import React, { useState } from "react";
import LabelIncidentCustomization from "../LabelCustomizationInvestigations/LabelIncidentCustomization";
import IncidentLabelDetails from "../LabelCustomizationDetails/IncidentLabelDetails";
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import { useNavigate } from "react-router";
import { Steps } from 'antd';

const CustomizeIncidentLabel = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);

    const steps = [
        { label: 'Incident Details' },
        { label: 'Investigation Details' },
    ];

    const next = () => {
        setCurrent(current + 1);
    };

    const items = steps.map((item) => ({
        key: item.label,
        title: item.label,
    }));

    const handleClick = () => {
        navigate('/event-list');
    }

    return (
        <>
            <div className="full-cont">
                <div className="border_head_1">
                    <div className="head_2">Customize Incident Form Labels</div>
                </div>

                <Steps current={current} items={items} className='margin-top-36'/>

                {current < steps.length - 1 && (
                    <IncidentLabelDetails />
                )}
                {current === steps.length - 1 && (
                    <LabelIncidentCustomization />
                )}

                <div className='text-right'>
                    {/* <button type="button" className='btn btn-close mt-30 mr-15'>Cancel</button> */}
                    <button type="button" className='btn-add-data mr-15'>Restore Original</button>
                    {(current < steps.length - 1) ?
                        <button type="button" className='btn-proceed in-blck mt-30' onClick={() => next()}>Proceed</button>
                        : <button type="button" className='btn-proceed in-blck mt-30' data-bs-toggle="modal" data-bs-target="#exampleModal">Update</button>

                    }
                </div>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog reportConfrmModal" role="document">
                    <div className="modal-content">
                        <div className="modal-header sideOnRight">
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={BtnCrossIcon} />
                            </button>
                        </div>
                        <div className="modal-body reportText">
                            Are you sure you would like update the incident form label
                        </div>
                        <div className="modal-footer middle">
                            <button type="button" className="btn-no" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn-yes" onClick={handleClick} data-bs-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomizeIncidentLabel;