import React, { useEffect, useState } from "react";
import CheckboxSelect from "../formBuilder/CheckboxSelect";
import { Modal, Tab, Tabs } from 'react-bootstrap';
import mockData from '../../mockData/mockData';
import DropDownData from '../../mockData/DropdownData';
import BtnCrossIcon from '../../../src/assets/images/FormImages/btnCross.svg';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './TabContainerModal.css';
import { getStateList } from "../../constants/apiRoutes";
import { Get } from "../../constants/apiMethods";

const TabContainerModal = ({ open, showModal }) => {
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [show, setShow] = useState(open);
    const [tabPosition, setTabPosition] = useState('left');
    const [selectedStartYear, setSelectedStartYear] = useState(null);
    const [selectedEndYear, setSelectedEndYear] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState('Yearly');
    const [statesList, setStatesList] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const token = localStorage.getItem("access_token");
    const maxYear = 2024;
    const currentYear = new Date(maxYear, 11, 31);
    const changeTabPosition = (e) => {
        setTabPosition(e.target.value);
    };

    useEffect(() => {
        getStatesList();
    }, [])

    const getStatesList = () => {
        Get(getStateList + `?is_active=true&min=true&sort=name`, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const statesData = data.data?.map((resp) => {
                        return { ...resp, label: resp.name, value: resp.name }
                    })
                    setStatesList(statesData);
                }
            });
    };

    const onStateChange = (selection) => {
        setSelectedStates((selection));
    }

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            showModal(!open)
        }, 3000);
    };

    const handleCancel = () => {
        showModal(false);
    };

    const onSelect = () => {
        //to be used in the future for the API integration
    }

    const onRemove = () => {
        //to be used in the future for the API integration
    }

    const onChange = () => {
        //to be used in the future for the API integration
    }

    return (
        // <Modal
        //     title="Customize Graph"
        //     open={open}
        //     onOk={handleOk}
        //     confirmLoading={confirmLoading}
        //     onCancel={handleCancel}
        //     width={800}
        //     closeIcon={<img src={BtnCrossIcon}/>}
        //     className="modalStyle"
        //     bodyStyle={{ height: 350, border: '1px solid #F2F2F4', borderRadius: '5px', fontFamily: 'Montserrat' }}
        //     footer={[
        //         <button type="button" className='btn-add-data mr-15'>Reset</button>,
        //         <button type="button" className='report_btn mr-15 border-0'>Customize</button>,
        //     ]}
        // >
        //     <Tabs
        //         tabPosition={tabPosition}
        //         size="middle"
        //         tabBarGutter={10}
        //         defaultActiveKey="1"
        //         style={{height: "fit-content",font: "#363636"}}
        //         className="custom-tabs"
        //         tabBarStyle={{ border: '1px solid #F2F2F4', borderRadius: '5px', fontFamily: 'Montserrat', fontWeight: 500, fontSize: '14px', lineHeight: '17px', color: '#50596B' }}
        //     >
        //         <Tabs.TabPane tab="Yearly" key="tab1">
        //             <Row className="my-3">
        //                 <Col span={24}>
        //                     <p className="tab-heading-font py-1">Select Year Range</p>
        //                 </Col>

        //                 <Col className="margin-right-30" span={10}>
        //                     <p className="tab-from-to-heading-font">From</p>

        //                 </Col>
        //                 <Col span={10}>
        //                     <p className="tab-from-to-heading-font">To</p>

        //                 </Col>
        //             </Row>

        //         </Tabs.TabPane>
        //         <Tabs.TabPane tab="Quarterly" key="tab2">
        //             <Row className="my-3">
        //                 <Col span={24}>
        //                     <p className="tab-heading-font py-1">Select Year Range</p>
        //                 </Col>

        //                 <Col className="margin-right-30" span={10}>
        //                     <p className="tab-from-to-heading-font">From</p>

        //                 </Col>
        //                 <Col span={10}>
        //                     <p className="tab-from-to-heading-font">To</p>

        //                 </Col>
        //             </Row>


        //             <p className="tab-heading-font pt-1 pb-2">Select Quarters</p>
        //             <CheckboxSelect data={mockData.Quarters} colSize={2} onChange={onChange}/>


        //         </Tabs.TabPane>
        //         <Tabs.TabPane tab="Monthly" key="tab3">
        //             <Row className="my-3">
        //                 <Col span={24}>
        //                     <p className="tab-heading-font py-2 margin-bottom-5" >Select years for monthly comparison</p>
        //                     <p className="sub-text">You can only select upto three years</p>
        //                 </Col>

        //                 <Col className="margin-right-30" span={10}>
        //                     <p className="tab-from-to-heading-font">From</p>

        //                 </Col>
        //                 <Col span={10}>
        //                     <p className="tab-from-to-heading-font">To</p>

        //                 </Col>
        //             </Row>
        //             <Row className="pt-0">
        //                 <p className="tab-heading-font pt-3">Select Months for comparison</p>
        //                 <CheckboxSelect data={DropDownData.Months} colSize={3} onChange={onChange}/>
        //             </Row>
        //         </Tabs.TabPane>
        //         <Tabs.TabPane tab="States" key="tab4">
        //             <Row>
        //                 <Col span={21}>
        //                     <p className="tab-heading-font pt-4">Select States</p>
        //                 </Col>
        //                 <Col span={21}>
        //                     {/* multi selection states checkbox array to be used here */}
        //                 </Col>

        //             </Row>
        //             <Row className="my-3">
        //                 <Col span={24}>
        //                     <p className="tab-heading-font py-2 margin-bottom-5">Select Year Range</p>
        //                     <p className="mb-4 yearly-sub-text">Select same year in from and To to see comparison of one year</p>
        //                 </Col>

        //                 <Col span={10} className="from-to-heading-font margin-right-25">
        //                     <p className="tab-from-to-heading-font">From</p>

        //                 </Col>
        //                 <Col span={10}>
        //                     <p className="tab-from-to-heading-font">To</p>

        //                 </Col>
        //             </Row>

        //         </Tabs.TabPane>
        //     </Tabs>
        // </Modal>

        <Modal show={open} dialogClassName="customize-modal" onHide={() => showModal(false)}>
            <Modal.Header className="sideOnRight" closeButton>
                <h5 className="modal-title report_head" id="exampleModalLabel">Customize Graph</h5>
            </Modal.Header>
            <Modal.Body className="border-0 pdtp-12">
                <div className="row brd-top">
                    <div className="col-md-3 select-text mt-10 brd-cover">
                        <div className={selectedDuration == 'Yearly' ? 'current-select-text' : ''} onClick={() => setSelectedDuration('Yearly')}>Yearly</div>
                        <div className={selectedDuration == 'Quarterly' ? 'current-select-text' : ''} onClick={() => setSelectedDuration('Quarterly')}>Quarterly</div>
                        <div className={selectedDuration == 'Monthly' ? 'current-select-text' : ''} onClick={() => setSelectedDuration('Monthly')}>Monthly</div>
                        <div className={selectedDuration == 'States' ? 'current-select-text' : ''} onClick={() => setSelectedDuration('States')}>States</div>
                    </div>
                    <div className="col-md-9">

                        {selectedDuration == 'Yearly' &&
                            <>
                                <div className="year-range mt-4">Select Year Range</div>
                                <div className="row mt-3">
                                    <div className="col-md-5">
                                        <div className="text-head-set">From</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedStartYear}
                                            onChange={date => setSelectedStartYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-head-set">To</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedEndYear}
                                            onChange={date => setSelectedEndYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                </div>
                            </>}

                        {selectedDuration == 'Quarterly' &&
                            <>
                                <div className="year-range mt-4">Select Year Range</div>
                                <div className="row mt-3">
                                    <div className="col-md-5">
                                        <div className="text-head-set">From</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedStartYear}
                                            onChange={date => setSelectedStartYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-head-set">To</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedEndYear}
                                            onChange={date => setSelectedEndYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="year-range mt-4">Select Quarters</div>
                                        <div className="mt-2">
                                            <CheckboxSelect data={mockData.Quarters} colSize={3} onChange={onChange} />
                                        </div>
                                    </div>
                                </div>
                            </>}

                        {selectedDuration == 'Monthly' &&
                            <>
                                <div className="year-range mt-4">Select years for monthly comparison</div>
                                <p className="sub-text">You can only select upto three years</p>
                                <div className="row mt-3">
                                    <div className="col-md-5">
                                        <div className="text-head-set">From</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedStartYear}
                                            onChange={date => setSelectedStartYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-head-set">To</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedEndYear}
                                            onChange={date => setSelectedEndYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="year-range mt-4">Select Months for comparison</div>
                                        <div className="mt-2">
                                            <CheckboxSelect data={DropDownData.Months} colSize={3} onChange={onChange} />
                                        </div>
                                    </div>
                                </div>
                            </>}

                        {selectedDuration == 'States' &&
                            <>
                                <div className="row col-md-10">
                                    <div className="year-range mt-4">Select States</div>
                                    <Select
                                        classNamePrefix="an-simple-select mt-1"
                                        placeholder='Choose states'
                                        options={statesList}
                                        onChange={(selection) => onStateChange(selection)}
                                        value={selectedStates}
                                        isMulti
                                    />
                                </div>
                                <div className="year-range mt-4">Select the Year Range</div>
                                <p className="sub-text">Select same year in from and To to see comparison of one year.</p>
                                <div className="row mt-3">
                                    <div className="col-md-5">
                                        <div className="text-head-set">From</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedStartYear}
                                            onChange={date => setSelectedStartYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-head-set">To</div>
                                        <DatePicker
                                            className='form-control mt-1'
                                            placeholderText='Choose Year'
                                            selected={selectedEndYear}
                                            onChange={date => setSelectedEndYear(date)}
                                            dateFormat="yyyy"
                                            showYearPicker
                                            yearItemNumber={12}
                                            maxDate={currentYear} />
                                    </div>
                                </div>
                            </>}

                        {/* <div className="place-end">
                            <button type="button" className="btn-no" onClick={() => showModal(false)}>Reset</button>
                            <button type="button" className="btn-yes ml-6-" onClick={() => showModal(false)}>Customize</button>
                        </div> */}

                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer className="place-end">
                <button type="button" className="btn-no" onClick={() => showModal(false)}>Reset</button>
                <button type="button" className="btn-yes ml-6-" onClick={() => showModal(false)}>Customize</button>
            </Modal.Footer>

        </Modal>

    )
}

export default TabContainerModal;