import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import data from '../../../mockData/mockData';
import CheckboxSelect from '../../../components/formBuilder/CheckboxSelect';
import TrashIcon from '../../../assets/images/TrashIcon.svg';
import { Controller, useForm } from "react-hook-form";
import './EventDetails.css';
import errorIcon from "../../../assets/images/error_icon.svg";
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { Post, Get } from "../../../constants/apiMethods";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { reportEvent, updateEvent, getDataById, getFacilities, getStateListing } from "../../../constants/apiRoutes";
import ReactGA from "react-ga4";
import { NA_STATUS, MMDDYYYY_FORMAT, ACCESS_TOKEN, NULL_VALUE, IMAGE_JPG_FORMAT, OTHERS_VALUE } from '../../../constants/Global';

function EventDetails({
    next,
    editMode
}) {

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        control,
        trigger,
    } = useForm();

    const navigate = useNavigate();
    const location = useLocation();
    const loggedInUserName = localStorage.getItem('name');
    const [draftMode, setDraftMode] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem(ACCESS_TOKEN);
    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [selectedMeridian, setSelectedMeridian] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [incidentObj, setIncidentObj] = useState([
        { incidentType: '' },
        { incidentType: '' }
    ]);
    const [date, setDate] = useState(null);
    const [facilities, setFacilities] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [incidentTypeArr, setIncidentTypeArr] = useState(data.IncidentType);
    const [initialSubmission, setInitialSubmission] = useState(true);
    const [initiallyReportedBy, setInitiallyReportedBy] = useState('');
    const [stateId, setStateId] = useState('');
    const [checkIfOtherLocationIsRequired, setcheckIfOtherLocationIsRequired] = useState(false);
    const [isDraft, setIsDraft] = useState();
    const [eventObj, setEventObj] = useState({
        "id": '',
        "userid": 1,
        "type": 'incident',
        "draft": draftMode ? 1 : 0,
        "formdata": {
            "date": '',
            "reportedby": "",
            "updatedby": "",
            "name": '',
            "location": '',
            "state": '',
            "hour": '',
            "minutes": '',
            "meridian": '',
            "correctiveAction": '',
            "investigationConclusion": '',
            "eventDescription": '',
            "correctiveMoreDetails": '',
            "investigationMoreDetails": ''
        },
        "incidentdata": [],
    })

    const getFacilityList = () => {
        getLocationById(stateId);
    }

    const getStateList = () => {
        setLoading(true);
        Get(getStateListing, token, `?sort=name`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    const stateData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    setStateList(stateData);
                }
            });
    }

    const getLocationById = (id) => {
        setLoading(true);
        Get(getFacilities, token, `?min=${true}&state=${id}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(true);
                let transformData = data;
                let updatedData = transformData.map(item => ({
                    ...item,
                    value: `${item.facility_name}`,
                    label: `${item.facility_name}`
                }));
                const otherLocationObject = { id: null, facility_name: OTHERS_VALUE, value: OTHERS_VALUE, label: OTHERS_VALUE };
                const newArray = [otherLocationObject, ...updatedData];
                setFacilities(newArray);
                setLoading(false);
            });
    }

    const onCancel = () => {
        navigate('/event-list');
    }

    const handleDraft = () => {
        setDraftMode(true);
    }

    const handleProceed = () => {
        setDraftMode(false);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const onCheckboxChange = (e, index) => {
        const id = e.id;
        let itemArr = incidentObj && incidentObj[index]?.affectedBodyParts || [];
        if (!itemArr.includes(id)) {
            itemArr.push(id);
        }
        else if (itemArr.includes(id)) {
            itemArr = itemArr.filter((el) => el !== id);
        }

        const obj = [...incidentObj];
        obj[index]['affectedBodyParts'] = itemArr;
        setIncidentObj(obj);
        setValue(`incidentdata[${index}][${'affectedBodyParts'}]`, itemArr);
        trigger(`incidentdata[${index}][${'affectedBodyParts'}]`);
    }

    const onIncidentSelect = (val, index, e) => {
        setValue(`incidentdata[${index}][${e.name}]`, val.value);
        trigger(`incidentdata[${index}][${e.name}]`);
        if (incidentObj[index].incidentType.value && ['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(val.value) && (!['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(incidentObj[index].incidentType.value))) {
            const updatedIncidentArr = { id: index, value: incidentObj[index].incidentType.value, label: incidentObj[index].incidentType.value };
            setIncidentTypeArr(prevIncidentType => [...prevIncidentType, updatedIncidentArr]);
        }
        const obj = [...incidentObj];
        setIncidentObj(obj);
        obj[index][e.name] = { value: val.value, label: val.value };
        if (e.name === 'incidentType') {
            if (['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(obj[index]['incidentType'].value)) {
                obj[index]['personImpacted'] = '';
                obj[index]['jobTitle'] = '';
                obj[index]['injuryType'] = '';
                obj[index]['affectedBodyParts'] = [];
            }
            if (!['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(obj[index]['incidentType'].value)) {
                let updatedIncidentArr = incidentTypeArr.filter(item => item.value !== val.value);
                setIncidentTypeArr(updatedIncidentArr);
            }
        }
    }

    const onRadioChange = (e, index) => {
        const { value } = e.target;
        const updatedIncidentData = [...incidentObj];
        updatedIncidentData[index] = { ...updatedIncidentData[index], personImpacted: value };
        setIncidentObj(updatedIncidentData);

        setValue(`incidentdata[${index}].personImpacted`, e.target.value);
        trigger(`incidentdata[${index}].personImpacted`);
    }

    const pushIncidentObject = () => {
        setIncidentObj(oldArray => [...oldArray,
        { incidentType: '', personImpacted: '', jobTitle: '', injuryType: '', affectedBodyParts: [] }]);
    }

    const removeFromIncidentObj = (index, item) => {
        const updatedItems = [...incidentObj];
        updatedItems.splice(index, 1);
        setIncidentObj(updatedItems);
        const newIncidentType = { id: index, value: item.incidentType.value, label: item.incidentType.label };
        const isValueExists = incidentTypeArr.some(item => item.value === newIncidentType.value);
        if (!isValueExists) {
            const newIncidentType = { id: index, value: item.incidentType.value, label: item.incidentType.label };
            setIncidentTypeArr(prevIncidentType => [...prevIncidentType, newIncidentType]);
        }
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const formatDate = (date) => {
        if (date) {
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${month}-${day}-${year}`;
        }
        return '';
    };

    const onSubmit = (e) => {
        const transDate = formatDate(date);
        const newArray = incidentObj.map((obj) => {
            if (['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(obj.incidentType.value)) {
                return {
                    jobTitle: obj.jobTitle.value,
                    injuryType: obj.injuryType.value,
                    incidentType: obj.incidentType.value,
                    personImpacted: obj.personImpacted,
                    affectedBodyParts: obj.affectedBodyParts
                };
            }
            if (!['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(obj.incidentType.value)) {
                return {
                    incidentType: obj.incidentType.value,
                };
            }
        });
        const step_one_submitted = localStorage.getItem('step_one_submitted');
        let userId = '';
        if (step_one_submitted) {
            userId = localStorage.getItem('userId');
        }
        let data = {
            "id": step_one_submitted ? userId : (editMode ? location.state.id : ''),
            "userid": 1,
            "type": 'event',
            "draft": draftMode ? 1 : 0,
            "formdata": {
                "date": transDate,
                "reportedby": editMode ? initiallyReportedBy : loggedInUserName,
                "updatedby": "",
                "name": getValues('name'),
                "location": editMode ? selectedLocation.value : e.location,
                "location_id": selectedLocation.id,
                "state": editMode ? selectedState.value : e.state,
                "state_id": selectedState.id,
                "hour": editMode ? selectedHour.value : e.hour,
                "minutes": editMode ? selectedMinutes.value : e.minutes,
                "meridian": editMode ? selectedMeridian.value : e.meridian,
                "time": editMode ? selectedHour.value : e.hour + ':' + editMode ? selectedMinutes.value : e.minutes + ' ' + editMode ? selectedMeridian.value : e.meridian,
                "correctiveAction": eventObj.correctiveAction,
                "investigationConclusion": eventObj.investigationConclusion,
                "eventDescription": eventObj.eventDescription,
                "correctiveMoreDetails": eventObj.correctiveMoreDetails,
                "investigationMoreDetails": eventObj.investigationMoreDetails,
            },
            "initialSubmission": initialSubmission,
            "incidentdata": [...newArray],
        };

        if (data.formdata.location == 'Others') {
            data.formdata.location = null;
            data.formdata.location_id = null;
            data.formdata.other_location = getValues('other_location');
        }

        if (incidentObj.length >= 0 && incidentObj.length <= 1) {
            setSuccessDetail('Please add atleast 2 incidents');
            setAlertType('failed');
            setSuccessRes(true);
        }
        if (incidentObj.length === 1) {
            setSuccessDetail('Please add 1 more incident');
            setAlertType('failed');
            setSuccessRes(true);
        }

        if (incidentObj.length >= 2) {
            setLoading(true);
            let apiCallMethod = editMode ? updateEvent : reportEvent;
            Post(apiCallMethod, token, data, true)
                .then((res) => {
                    if (res.success) {
                        setSuccessDetail(`Event details are ${editMode ? 'updated' : 'added'} successfully!`);
                        setAlertType('success');
                        setSuccessRes(true);
                        if (draftMode) {
                            setLoading(false)
                            setSuccessDetail('Event saved successfully!');
                            setAlertType('success');
                            setSuccessRes(true);
                            setTimeout(() => {
                                navigate('/event-list')
                            }, 2000);
                        }
                        if (!draftMode) {
                            setLoading(false);
                            next(res, editMode);
                            ReactGA.event({
                                category: `(Event ${editMode ? 'updated' : 'added'})`,
                                action: `(Event details ${editMode ? 'updated' : 'added'} successfully)`,
                                label: 'By - ' + localStorage.getItem('name'),
                            });
                        }
                        setLoading(false);
                    }

                    if (!res.success) {
                        setLoading(false);
                        setSuccessDetail(res.message);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                })
                .catch((error) => {
                });
        }

    }

    const onSelectionChange = (selection, { name }) => {

        setValue(name, selection.value);
        trigger(name);

        if (name === 'hour') {
            setSelectedHour(selection)
        }
        if (name === 'minutes') {
            setSelectedMinutes(selection)
        }
        if (name === 'meridian') {
            setSelectedMeridian(selection)
        }
        if (name === 'state') {
            setSelectedState(selection)
            getLocationById(selection.id)
            setSelectedLocation('');
        }
        if (name === 'location') {
            setSelectedLocation(selection);
            if (selection.value == 'Others') {
                setcheckIfOtherLocationIsRequired(true);
            } else {
                setcheckIfOtherLocationIsRequired(false);
            }
        }

    }

    const patchIncidentArrObj = (item) => {
        const transformedData = item.map((item, index) => {
            if (!['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(item.incidentType)) {
                let updatedIncidentArr = incidentTypeArr.filter(res => res.value !== item.incidentType);
                setIncidentTypeArr(updatedIncidentArr);
                setValue(`incidentdata[${index}][${'incidentType'}]`, item.incidentType);
                trigger(`incidentdata[${index}][${'incidentType'}]`);
            }
            if (['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(item.incidentType)) {
                setValue(`incidentdata[${index}][${'incidentType'}]`, item.incidentType);
                trigger(`incidentdata[${index}][${'incidentType'}]`);
                setValue(`incidentdata[${index}][${'jobTitle'}]`, item.jobTitle);
                trigger(`incidentdata[${index}][${'jobTitle'}]`);
                setValue(`incidentdata[${index}][${'injuryType'}]`, item.injuryType);
                trigger(`incidentdata[${index}][${'injuryType'}]`);
                setValue(`incidentdata[${index}][${'affectedBodyParts'}]`, item.affectedBodyParts);
                trigger(`incidentdata[${index}][${'affectedBodyParts'}]`);
                setValue(`incidentdata[${index}][${'personImpacted'}]`, item.personImpacted);
                trigger(`incidentdata[${index}][${'personImpacted'}]`);
            }
            return {
                affectedBodyParts: item.affectedBodyParts,
                incidentType: { value: item.incidentType, label: item.incidentType },
                injuryType: { value: item.injuryType, label: item.injuryType },
                jobTitle: { value: item.jobTitle, label: item.jobTitle },
                personImpacted: item.personImpacted,
            };

        });
        setIncidentObj(transformedData);
    }

    const setDateValidation = (dateObj) => {
        setValue('date', dateObj);
        trigger('date');
    }

    const getDetailsById = () => {
        const step_one_submitted = localStorage.getItem('step_one_submitted');
        let userId = '';
        if (step_one_submitted) {
            userId = localStorage.getItem('userId');
        }
        let body = {
            id: step_one_submitted ? userId : location.state.id
        }
        let data = initialSubmission === true ? false : false;
        setInitialSubmission(data);

        Post(getDataById, token, body, true)
            .then((response) => response)
            .then((data) => {
                patchIncidentArrObj(data.result[0].safetyformdata[0].incidentdata);
                setDate(data.result[0]?.safetyformdata[0]?.formdata.date ? new Date(data.result[0]?.safetyformdata[0]?.formdata.date) : null);
                setDateValidation(data.result[0]?.safetyformdata[0]?.formdata.date);
                setEventObj(data.result[0]?.safetyformdata[0]?.formdata)
                setIsDraft(data.result[0]?.safetyformdata[0]?.draft);
                setValue('name', data.result[0]?.safetyformdata[0]?.formdata.name);
                setInitiallyReportedBy(data.result[0]?.safetyformdata[0]?.formdata.reportedby)
                let hourObj = {
                    value: data.result[0]?.safetyformdata[0]?.formdata.hour,
                    label: data.result[0]?.safetyformdata[0]?.formdata.hour,
                }
                let minuteObj = {
                    value: data.result[0]?.safetyformdata[0]?.formdata.minutes,
                    label: data.result[0]?.safetyformdata[0]?.formdata.minutes,
                }
                let meridianObj = {
                    value: data.result[0]?.safetyformdata[0]?.formdata.meridian,
                    label: data.result[0]?.safetyformdata[0]?.formdata.meridian,
                }
                let stateObj = {
                    value: data.result[0]?.safetyformdata[0]?.formdata.state,
                    label: data.result[0]?.safetyformdata[0]?.formdata.state,
                    id: data.result[0]?.safetyformdata[0]?.formdata.state_id,
                }

                let locationObj = {
                    value: data.result[0]?.safetyformdata[0]?.formdata.location ? data.result[0]?.safetyformdata[0]?.formdata.location : 'Others',
                    label: data.result[0]?.safetyformdata[0]?.formdata.location ? data.result[0]?.safetyformdata[0]?.formdata.location : 'Others',
                    id: data.result[0]?.safetyformdata[0]?.formdata.location_id ? data.result[0]?.safetyformdata[0]?.formdata.location_id : null,
                }
                setSelectedHour(hourObj);
                setSelectedMinutes(minuteObj);
                setSelectedMeridian(meridianObj);
                setSelectedState(stateObj);
                setSelectedLocation(locationObj);
                setValue('hour', hourObj);
                setValue('minutes', minuteObj);
                setValue('meridian', meridianObj);
                setValue('location', locationObj);
                setValue('state', stateObj);
                if (!data.result[0]?.safetyformdata[0]?.formdata.location) {
                    setcheckIfOtherLocationIsRequired(true);
                    setValue('other_location', data.result[0]?.safetyformdata[0]?.formdata.other_location);
                }
            });
    }

    useEffect(() => {
        editMode && getDetailsById();
        editMode && getFacilityList();
        getStateList();
    }, []);

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>
                    <div className='row'>
                        <div className='col-md-6 pt-2'>
                            <div className='inputFieldHead'>Date of Occurrence*</div>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => { setDate(date); setDateValidation(date) }}
                                        className='form-control'
                                        placeholderText='Choose Date'
                                        dateFormat="MM-dd-yyyy"
                                        maxDate={today}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {formState.errors.date && (
                                <p className="error"> <img src={errorIcon} /> {formState.errors.date.message} </p>
                            )}
                        </div>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Time*</div>
                            <div className='row mt-16'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="hour"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="hour"
                                                placeholder='Hour'
                                                options={data.Hours}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedHour}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {formState.errors.hour && (
                                        <p className="error"> <img src={errorIcon} /> {formState.errors.hour.message} </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="minutes"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="minutes"
                                                placeholder='Minutes'
                                                options={data.Minutes}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMinutes}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {formState.errors.minutes && (
                                        <p className="error"> <img src={errorIcon} /> {formState.errors.minutes.message} </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="meridian"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="meridian"
                                                placeholder='Meridian'
                                                options={data.Meridian}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMeridian}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {formState.errors.meridian && (
                                        <p className="error"> <img src={errorIcon} /> {formState.errors.meridian.message} </p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Name of the Event*</div>
                            <input
                                type='text'
                                className='form-control'
                                name="name"
                                placeholder='Type here For eg Refinery Fire'
                                {...register("name", { required: true })}
                            />
                            {formState.errors.name?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Choose State*</div>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="state"
                                        placeholder='Choose the state where the event occured'
                                        options={stateList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedState}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {formState.errors.state && (
                                <p className="error"> <img src={errorIcon} /> {formState.errors.state.message} </p>
                            )}
                        </div>
                    </div>

                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Location of Occurrence*</div>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="location"
                                        placeholder='Choose the location where the event occured'
                                        options={facilities}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedLocation}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {formState.errors.location && (
                                <p className="error"> <img src={errorIcon} /> {formState.errors.location.message} </p>
                            )}

                        </div>

                        {selectedLocation?.value == 'Others' && <div className='col-md-6'>
                            <div className='inputFieldHead'>Please type in the Location*</div>
                            <input
                                type='text'
                                className='form-control'
                                name="other_location"
                                placeholder='Type here For eg Near basin'
                                {...register("other_location", { required: checkIfOtherLocationIsRequired })}
                            />
                            {formState.errors.other_location?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>}
                    </div>

                </div>

                {incidentObj.length > 0 && incidentObj.map((item, index) => {
                    return (
                        <li key={index} className='incidentCont mt-30 '>
                            <div className='incLabel'>Incident {index + 1}</div>
                            <div className='container_div'>
                                <div className='text-right'>
                                    {index > 1 && <img src={TrashIcon} onClick={() => removeFromIncidentObj(index, item)} className='cursor' />}
                                </div>
                                <div className='row mt-30'>
                                    <div className='col-md-6'>
                                        <div className='inputFieldHead'>Incident Type*</div>
                                        <Controller
                                            name={`incidentdata[${index}].incidentType`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    classNamePrefix="an-simple-select"
                                                    key={index}
                                                    placeholder='Choose the incident type'
                                                    name={`incidentType`}
                                                    options={incidentTypeArr}
                                                    onChange={(val, e) => onIncidentSelect(val, index, e)}
                                                    id={item?.id}
                                                    value={incidentObj[index]?.incidentType}
                                                />
                                            )}
                                            rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                        />
                                        {formState?.errors?.incidentdata?.[index]?.incidentType && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                                    </div>
                                    {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(item?.incidentType?.value) &&
                                        <div className='col-md-6'>
                                            <div className='inputFieldHead'>Type of Person Impacted*</div>
                                            <div className='rdImpactdGroup mt-16'>
                                                <Controller
                                                    name={`incidentdata[${index}].personImpacted`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                id={`employee_${index}`}
                                                                name={`personImpacted_${index}`}
                                                                value={'Employee'}
                                                                onChange={(e) => onRadioChange(e, index)}
                                                                checked={incidentObj[index]?.personImpacted === 'Employee'}
                                                            />

                                                            <label htmlFor={`employee_${index}`}>Employee</label>

                                                            <input
                                                                type="radio"
                                                                id={`contractor_${index}`}
                                                                name={`personImpacted_${index}`}
                                                                value={'Contractor'}
                                                                onChange={(e) => onRadioChange(e, index)}
                                                                checked={incidentObj[index]?.personImpacted === 'Contractor'}
                                                            />
                                                            <label htmlFor={`contractor_${index}`}>Contractor</label>
                                                        </>
                                                    )}
                                                    rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                                />
                                                {formState?.errors?.incidentdata?.[index]?.personImpacted && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                                            </div>
                                        </div>}
                                </div>
                                <div className='row mt-30'>

                                    {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(item?.incidentType?.value) &&
                                        <div className='col-md-6'>
                                            <div className='inputFieldHead'>Area of work of person*</div>
                                            <Controller
                                                name={`incidentdata[${index}].jobTitle`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        classNamePrefix="an-simple-select"
                                                        key={index}
                                                        placeholder='Choose the area of work of person'
                                                        name={'jobTitle'}
                                                        options={data?.JobTitle}
                                                        onChange={(val, e) => onIncidentSelect(val, index, e)}
                                                        id={item?.id}
                                                        value={incidentObj[index]?.jobTitle}
                                                    />
                                                )}
                                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                            />
                                            {formState?.errors?.incidentdata?.[index]?.jobTitle && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                                        </div>}

                                    {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(item?.incidentType?.value) &&
                                        <div className='col-md-6'>
                                            <div className='inputFieldHead'>Type of Injury*</div>
                                            <Controller
                                                name={`incidentdata[${index}].injuryType`}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        classNamePrefix="an-simple-select"
                                                        key={index}
                                                        placeholder='Choose the injury type'
                                                        name={'injuryType'}
                                                        options={data.InjuryTypes}
                                                        onChange={(val, e) => onIncidentSelect(val, index, e)}
                                                        value={incidentObj[index]?.injuryType}
                                                    />
                                                )}
                                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                            />
                                            {formState?.errors?.incidentdata?.[index]?.injuryType && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                                        </div>}
                                </div>

                                <div className='row mt-30'>

                                    {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(item?.incidentType?.value) &&
                                        <div className='col-md-6'>
                                            <div className='inputFieldHead'>Affected Body Part*</div>
                                            <Controller
                                                name={`incidentdata[${index}].affectedBodyParts`}
                                                control={control}
                                                render={({ field }) => (
                                                    <CheckboxSelect
                                                        classNamePrefix="an-simple-select"
                                                        key={index}
                                                        index={index}
                                                        name={`affectedBodyParts`}
                                                        data={data.BodyParts}
                                                        colSize={3}
                                                        onChange={(e) => onCheckboxChange(e, index, item)}
                                                        checkedValues={incidentObj[index]?.affectedBodyParts}
                                                    />
                                                )}
                                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                            />
                                            {formState?.errors?.incidentdata?.[index]?.affectedBodyParts && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                                        </div>}
                                </div>
                            </div>
                        </li>
                    )
                })}

                <div className='text-right'>
                    <button type="button" className='btn-add-incident'
                        onClick={pushIncidentObject}> Add Incident to this Event</button>
                </div>
                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={onCancel}>Cancel</button>
                    {isDraft !== 0 && <button type="submit" className='btn-add-data mr-15' onClick={handleDraft}>Save As Draft</button>}
                    <button type="submit" className='btn-proceed in-blck mt-30' onClick={handleProceed}>Proceed</button>
                </div>
            </form>

        </div>
    )
}

export default EventDetails;
