import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { Delete2, Get, ImagePost } from '../../../../constants/apiMethods';
import { getComplaintsList, complaintDocUploader } from '../../../../constants/apiRoutes';
import ThreeDotsIcon from '../../../../assets/images/FormImages/threeDots.svg';
import DownloadCompleteImg from '../../../../assets/images/downloadComplete.svg';
import SearchIcon from '../../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../../assets/images/FormImages/clear.svg';
import DropCollapseDownSelected from '../../../../assets/images/DropCollapseDownSelected.svg';
import DropCollapseDown from '../../../../assets/images/DropCollapseDown.svg';
import FilterTickIcon from '../../../../assets/images/FilterTickIcon.svg';
import ClearIcon from '../../../../assets/images/FormImages/clear_icon.svg';
import EditSvg from '../../../../assets/images/FormImages/edit.svg';
import FadedDownloadDoc from '../../../../assets/images/FormImages/fadedDownloadIcon.svg';
import EyeIcon from '../../../../assets/images/EyeIcon.svg';
import complaintsData from '../../../../mockData/complaintsMockData/index';
import moment from 'moment';
import SelectDropdownAction from '../../../../components/formBuilder/SelectDropdownAction';
import { Modal } from 'react-bootstrap';
import ChangingProgressProvider from '../../../../components/ChangingProgressProvider/ChangingProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import DateRangePicker from '../../../../components/formBuilder/DateRangePicker';
import './index.css';
import FileUploader from '../../../../components/FileUploader';
import LoadingSpinner from '../../../../components/Loader';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';
import Tooltip from '../../../../components/Tooltip/index';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import Table from '../../../../components/Table';
import FileUploadProgressContainer from '../../../../components/FileUpload/FileUploadProgressContainer';
import { ACCESS_TOKEN, CLOSED_STATUS, COMPANY_ADMIN, COMPLIANCE, FAILED_STATUS, NULL_VALUE, OPEN_STATUS, PERMISSIONS, ROLE, STEP_ONE_SUBMITTED_STATUS, SUBMITTED_STATUS, SUCCESS_STATUS, USER } from '../../../../constants/Global';
import { COMPLAINT_ID, DRAFT_STATUS } from '../../../../constants/Complaints';

const ComplaintsManagement = () => {

    let permissionsObj;
    const role = localStorage.getItem(ROLE);
    if (role === USER) {
        let permissions = JSON.parse(localStorage.getItem(PERMISSIONS));
        let safetyPermissionObject = permissions.filter(item => item.moduleName.toLowerCase() === COMPLIANCE);
        permissionsObj = {
            dashboardAccess: safetyPermissionObject[0].dashboardChecked,
            exportAccess: safetyPermissionObject[0].exportAccess,
            readAccess: safetyPermissionObject[0].readAccess,
            writeAccess: safetyPermissionObject[0].writeAccess
        }
    }

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const getPage = localStorage.getItem('getPage');
    const token = localStorage.getItem(ACCESS_TOKEN);
    const [page, setPage] = useState(1);
    const [complaintsListData, setComplaintsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showActive, setShowActive] = useState(getPage != 1);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideChangeStatusComplaints, setshowHideChangeStatusComplaints] = useState({ show: false, id: '' });
    const [fileData, setFile] = useState('');
    const [showUpload, setShowUpload] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showFileContainer, setShowFileContainer] = useState(false);
    const [showHideUploadContainer, setShowHideUploadContainer] = useState({ show: false });
    const [currentFile, setCurrentFile] = useState('');
    const [showFilters, setFilters] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [clearDateValue, setClearDateValue] = useState(true);
    const [categoryValue, setCategoryValue] = useState('');
    const [complaintTypeValue, setComplaintTypeValue] = useState('');
    const [complaintStatusValue, setComplaintStatusValue] = useState('');
    const [recordStatusValue, setRecordStatusValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const columnsTable = [
        showActive && { label: 'Complaint Name', field: 'complaint_name', render: (item) => item?.complaint_name ? <Tooltip text={item?.complaint_name}>{truncateString(item?.complaint_name, 20)}</Tooltip> : 'NA' },
        { label: 'Date of Complaint', field: 'date_of_complaint', render: (item) => item?.date_of_complaint ? moment(item?.date_of_complaint).format('MM-DD-YYYY') : 'NA' },
        {
            label: 'Complaint Type',
            field: 'complaint_type',
            render: (item) => (
                item?.complaint_type && item.complaint_type.trim() !== '' ? (
                    <Tooltip text={item.complaint_type !== 'Others' ? item.complaint_type : `Others (${item.complaint_type_other})`}>
                        <span>{truncateString(item.complaint_type !== 'Others' ? item.complaint_type : `Others (${item.complaint_type_other})`, 20)}</span>
                    </Tooltip>
                ) : 'NA'
            )
        },
        {
            label: 'Facility Name', field: 'facility_name', render: (item) => {
                const facilityName = item?.facility_name && !item?.location ? truncateString(item?.facility_name, 15) : <span>{truncateString(`Others (${item?.location})`, 15)}</span>;
                return (
                    <Tooltip text={item?.facility_name ? item?.facility_name : item?.location ? `Others (${item?.location})` : 'NA'}>
                        <span>{item?.facility_name || item?.location ? facilityName : "NA"}</span>
                    </Tooltip>
                );
            }
        },
        showActive && { label: 'Complaint Status', field: 'complaint_status', render: (item) => item?.complaint_status ? <Tooltip text={item?.complaint_status}>{truncateString(item?.complaint_status, 20)}</Tooltip> : 'NA' },
        showActive && { label: 'Record Status', field: 'record_status', render: (item) => item?.record_status ? item?.record_status : 'NA' },
        !showActive && { label: 'Complainant Name', field: 'complainant_name', render: (item) => item?.complainant_name ? <Tooltip text={item?.complainant_name}>{truncateString(item?.complainant_name, 20)}</Tooltip> : 'NA' },
        !showActive && { label: 'Phone Number', field: 'phone_number', render: (item) => item?.phone_number ? item?.phone_number : 'NA' },
        showActive && {
            label: 'Actions', field: 'actions', render: (item) => {
                return item?.record_status === DRAFT_STATUS ?
                    <SelectDropdownAction
                        headingData={ThreeDotsIcon}
                        itemData={item}
                        data={complaintsData.complaintDraftItems}
                        onDelete={() => setshowHideChangeStatusComplaints({ show: true, id: item.id })}
                        onUpdate={() => onUpdateComplaint(item)}
                        isImg={true} />
                    :
                    <SelectDropdownAction
                        headingData={ThreeDotsIcon}
                        itemData={item}
                        data={complaintsData.complaintActionItems}
                        onDelete={() => setshowHideChangeStatusComplaints({ show: true, id: item.id })}
                        onView={() => onViewComplaintDetails(item.id)}
                        onUpdate={() => onUpdateComplaint(item)}
                        isImg={true} />
            }
        },
        !showActive && {
            label: 'Actions', field: 'actions', render: (item) => {
                return (
                    <>
                        <img className="editIcn" src={FadedDownloadDoc} />
                        <img src={EyeIcon} className='editIcn cursor' onClick={() => onViewComplaintDetails(item.id)} />
                    </>
                )

            }
        }
    ];

    useEffect(() => {
        localStorage.removeItem(STEP_ONE_SUBMITTED_STATUS);
        localStorage.removeItem(COMPLAINT_ID);
        getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateRangeValue, page);
    }, [showActive])

    setTimeout(() => {
        localStorage.removeItem('getPage');
    }, 1);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const getComplaintsListing = (searchValue, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateRangeValue, page) => {
        hideElement();
        setSearchValue(searchValue);
        setCategoryValue(categoryValue);
        setComplaintTypeValue(complaintTypeValue);
        setComplaintStatusValue(complaintStatusValue);
        setRecordStatusValue(recordStatusValue);
        setDateRangeValue(dateRangeValue);
        setStartDate(dateRangeValue && dateRangeValue ? dateRangeValue[0] : '');
        setEndDate(dateRangeValue && dateRangeValue ? dateRangeValue[1] : '');
        setLoading(true);
        Get(getComplaintsList, token, `?page=${page}&search=${searchValue}&agency=${!showActive}&category=${categoryValue}&complaint_type=${complaintTypeValue}&complaint_status=${complaintStatusValue}&record_status=${recordStatusValue}&dod_start=${dateRangeValue && dateRangeValue[0] ? dateRangeValue[0] : ''}&dod_end=${dateRangeValue && dateRangeValue[1] ? dateRangeValue[1] : ''}`, true)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setComplaintsList(data.data);
                    setCurrentPage(data.paginator);
                    hideElement();
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(FAILED_STATUS);
                    setLoading(false);
                    setSuccessRes(true);
                }
            });
        hideElement();
    };

    const deleteComplaint = () => {
        setSuccessRes(false);
        setLoading(true);
        Delete2(getComplaintsList, token, showHideChangeStatusComplaints?.id, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                setshowHideChangeStatusComplaints({ show: false, id: '' });
                setSuccessRes(true);
                if (data.success) {
                    getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateRangeValue, page);
                    setAlertType(SUCCESS_STATUS);
                    setShowSuccessMessage(data.detail);
                }
                if (!data.success) {
                    setAlertType(FAILED_STATUS);
                    setShowSuccessMessage(data.detail);
                }
            });
    }

    const onFileChange = (files) => {
        setShowFileContainer(false);
        setCurrentFile(files[0]);
        setFile(files[0]);
        setShowUpload(false);
        setShowProcessing(true);
        setTimeout(() => {
            setShowProcessing(false);
            setShowFileContainer(true);
        }, 5000)
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const truncateString = (str, maxLength) => {
        if (!str) {
            return '';
        }

        return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
    };

    const handleFileUpload = () => {
        setLoading(true);
        const selectedFile = fileData;
        if (selectedFile) {
            const fileName = selectedFile.name;
            const fileType = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

            if (fileType !== 'xlsx') {
                setSuccessRes(true);
                setAlertType(FAILED_STATUS);
                setShowSuccessMessage('Invalid file type. Please select a .xlsx file.');
                setLoading(false);
                return;
            }

            if (fileType === 'xlsx') {
                const formData = new FormData();
                formData.append(`file`, fileData);
                ImagePost(complaintDocUploader, token, formData, false)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setShowSuccessMessage(res.detail);
                            setAlertType(SUCCESS_STATUS);
                            setLoading(false);
                            setSuccessRes(true);
                            setShowHideUploadContainer({ show: false });
                        }
                        if (!res.success) {
                            setShowSuccessMessage(res.detail);
                            setAlertType(FAILED_STATUS);
                            setLoading(false);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {

                    });
            }
        }
    }

    const sampleFormat = () => {
        window.open(`${process.env.REACT_APP_SAMPLE_FORMAL_URL}sample_format/ComplaintSample.xlsx`, '_self')
    }

    const addComplaint = () => {
        navigate('/add-complaint');
    }

    const pageChange = (page) => {
        setPage(page + 1);
        getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateRangeValue, page + 1);
    }

    const onViewComplaintDetails = (id) => {
        navigate(showActive ? `/view-direct-complaint` : `/view-agency-complaint`, { state: { id: id } });
    }

    const onUpdateComplaint = (item) => {
        if (item?.record_status === SUBMITTED_STATUS) {
            navigate('/update-complaint', { state: { id: item.id } });
        }
        else {
            navigate('/add-complaint', { state: { id: item.id, isDraftMode: item.record_status } });
        }
    }

    const clearFilters = () => {
        setCategoryValue('');
        setComplaintTypeValue('');
        setComplaintStatusValue('');
        setComplaintStatusValue('');
        setRecordStatusValue('');
        setStartDate('');
        setEndDate('');
        setDateRangeValue();
        setIsOpen(false);
        setClearDateValue(false);
        setTimeout(() => {
            setClearDateValue(true);
        }, 1)
        setPage(1);
        getComplaintsListing('', '', '', '', '', '', 1);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const downloadAsExcelSheet = () => {
        setLoading(true);
        Get(getComplaintsList, token, `?page=${page}&is_active=${showActive}&search=${searchValue}&export=${true}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(SUCCESS_STATUS);
                    setSuccessRes(true);
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(FAILED_STATUS);
                    setSuccessRes(true);
                }
            });
    };

    const hideElement = () => {
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
        const element3 = document.getElementById('dropdownHidden3');
        if (element3) {
            element3.classList.remove('show');
        }
        const element4 = document.getElementById('dropdownHidden4');
        if (element4) {
            element4.classList.remove('show');
        }
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={5000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-6">
                        <div> <span className="head_1 cursor" onClick={() => { navigate('/complaints-dashboard') }}>Complaints Dashboard</span> / <span className="head_3">Complaint Management</span></div>
                        <div className="head_2">Complaint Management</div>
                    </div>
                    <div className="col-md-6 text-right">
                        {((role === COMPANY_ADMIN) || (role === USER && (permissionsObj?.writeAccess))) &&
                            <><button className='btn-cancel border-0' onClick={sampleFormat}>Sample Format</button>
                                <button className='btn-add-data mr-15 mt-0' onClick={() => { setShowHideUploadContainer({ show: true }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); }}>Bulk Upload</button>
                                <button className='onboard_btn set-onbBtn-wd border-0' onClick={addComplaint}>Add Complaint</button></>
                            }
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-10">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Direct Complaints</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Agency Complaints</p>
                        </div>
                    </div>

                    <div className="col-md-2 text-right">
                        <button className='export_btn' onClick={downloadAsExcelSheet}>
                            <img src={EditSvg} alt='editImg' /> Export
                        </button>
                    </div>
                </div>

                <div className="spill_filter_options">
                    {showFilters ? <><div className="spill_filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn ${complaintTypeValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Complaint Type
                                <img className="ml-8" src={complaintTypeValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden2' aria-labelledby="dropdownMenuButton1">
                                {complaintsData.complaintType?.length > 0 && complaintsData.complaintType.map((item, index) => {
                                    return (
                                        <a className="dropdown-item cursor" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, item.value, complaintStatusValue, recordStatusValue, dateRangeValue, 1) }}>
                                            <div className="">
                                                {complaintTypeValue === item?.value ? <img src={FilterTickIcon} /> : ''}
                                                <label className={` cursor ${complaintTypeValue === item.value ? 'selected-filter-color ml-8' : ''}`}>{item.value}</label>
                                            </div>
                                        </a>
                                    );
                                })}

                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, '', complaintStatusValue, recordStatusValue, dateRangeValue, 1) }}>Clear</button>
                                </div>
                            </div>

                            {showActive &&
                                <><button className={`btn dropdown-toggle ${complaintStatusValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Complaint Status
                                    <img className="ml-8" src={complaintStatusValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                                </button>
                                    <div className="dropdown-menu" id='dropdownHidden3' aria-labelledby="dropdownMenuButton1">
                                        <a className="dropdown-item cursor" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, OPEN_STATUS, recordStatusValue, dateRangeValue, 1) }}>
                                            <div className="">
                                                {complaintStatusValue === 'Open' ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                                <label className={` cursor ${complaintStatusValue === 'Open' ? 'selected-filter-color ml-8' : ''}`}>Open</label>
                                            </div>
                                        </a>
                                        <a className="dropdown-item cursor" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, CLOSED_STATUS, recordStatusValue, dateRangeValue, 1) }}>
                                            <div className="">
                                                {complaintStatusValue === 'Closed' ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                                <label className={` cursor ${complaintStatusValue === 'Closed' ? 'selected-filter-color ml-8' : ''}`}>Closed</label>
                                            </div>
                                        </a>
                                        <div className="mt-2 float-right">
                                            <button type="button" className="btn-clear" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, '', recordStatusValue, dateRangeValue, 1) }}>Clear</button>
                                        </div>
                                    </div>
                                </>}
                            {showActive && <>

                                <button className={`btn dropdown-toggle ${recordStatusValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Record Status
                                    <img className="ml-8" src={recordStatusValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                                </button>
                                <div className="dropdown-menu" id='dropdownHidden4' aria-labelledby="dropdownMenuButton1">
                                    <a className="dropdown-item cursor" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, DRAFT_STATUS, dateRangeValue, 1) }}>
                                        <div className="">
                                            {recordStatusValue === DRAFT_STATUS ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                            <label className={` cursor ${recordStatusValue === 'Draft' ? 'selected-filter-color ml-8' : ''}`}>Draft</label>
                                        </div>
                                    </a>
                                    <a className="dropdown-item cursor" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, SUBMITTED_STATUS, dateRangeValue, 1) }}>
                                        <div className="">
                                            {recordStatusValue === SUBMITTED_STATUS ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                            <label className={` cursor ${recordStatusValue === 'Submitted' ? 'selected-filter-color ml-8' : ''}`}>Submitted</label>
                                        </div>
                                    </a>
                                    <div className="mt-2 float-right">
                                        <button type="button" className="btn-clear" onClick={(e) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, '', dateRangeValue, 1) }}>Clear</button>
                                    </div>
                                </div>
                            </>}

                            <button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${dateRangeValue ? 'selected-filter-color' : ''}`}>
                                {dateRangeValue ? startDate + ' - ' + endDate : 'Complaint Date Range'}
                                <img className="ml-8" src={dateRangeValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpen && (
                                <ul className="spill-datepicker">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateString, 1) }} onClear={() => { setDateRangeValue(); toggleDropdown(); getComplaintsListing(searchValue, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, '', 1) }} />}
                                </ul>
                            )}
                        </div>
                    </div>

                        <div className="spill_clear_filter">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder={showActive ? 'Search by Complaint Name or Facility Name' : 'Search by Facility Name'} onChange={(event) => { getComplaintsListing(event.target.value, categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateRangeValue, page); setSearchValue(event.target.value) }} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); getComplaintsListing('', categoryValue, complaintTypeValue, complaintStatusValue, recordStatusValue, dateRangeValue, page); setSearchValue(''); }} />
                        </div>}

                </div>

                <Table columns={columnsTable} data={complaintsListData} onPageChange={pageChange} currentPage={currentPage} />

            </div>

            <ConfirmationModal
                show={showHideChangeStatusComplaints.show}
                onHide={() => setshowHideChangeStatusComplaints({ show: false, id: '' })}
                onConfirm={deleteComplaint}
                message="Are you sure you would like to delete this Complaint?" />

            <Modal show={showHideUploadContainer.show} dialogClassName="uploadModal" onHide={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>
                <Modal.Header className="sideOnRight" closeButton>
                    <div className='inputFieldHead'>Upload File</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0">
                    {showUpload && <FileUploader onFileUpload={onFileChange} />
                    }
                    {!showUpload && showProcessing && (
                        <FileUploadProgressContainer
                            type='imageProcessing'
                            convertBytesToKb={convertBytesToKb}
                            currentFile={currentFile}
                            setShowUpload={setShowUpload}
                            setFile={setFile}
                            setCurrentFile={setCurrentFile}>
                            <ChangingProgressProvider values={[0, 25, 50, 75, 100]}>
                                {percentage => (
                                    <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                )}
                            </ChangingProgressProvider>
                        </FileUploadProgressContainer>
                    )}

                    {!showUpload && showFileContainer && (
                        <FileUploadProgressContainer
                            type='uploadedImage'
                            convertBytesToKb={convertBytesToKb}
                            currentFile={currentFile}
                            setShowUpload={setShowUpload}
                            setFile={setFile}
                            setCurrentFile={setCurrentFile}>
                            <img src={DownloadCompleteImg} />
                        </FileUploadProgressContainer>
                    )}
                    <div className="modal-footer text-center">
                        <button type="button" className="btn-cancel-popup" onClick={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>Cancel</button>
                        <button type="button" className="btn-upload border-0" onClick={handleFileUpload} disabled={fileData === '' || showProcessing}>Upload</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ComplaintsManagement;