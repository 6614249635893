import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import errorIcon from "../../../../../assets/images/error_icon.svg";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import data from '../../../../../mockData/mockData';
import complaintsData from '../../../../../mockData/complaintsMockData/index';
import { Post, Get, Put } from "../../../../../constants/apiMethods";
import { getFacilities, complaintBaseUrl, getComplaintsList ,getStateListing } from "../../../../../constants/apiRoutes";
import LoadingSpinner from '../../../../../components/Loader';
import AlertNotifier from '../../../../../components/Notifications/AlertNotifier';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router';
import { Modal } from 'react-bootstrap';
import CheckboxSelect from '../../../../../components/formBuilder/CheckboxSelect';
import {
    CLOSED_STATUS,
    OTHERS_VALUE,
    NULL_VALUE,
    AM_MERIDIAN,
    PM_MERIDIAN,
    OPEN_STATUS,
    HOUR,
    MINUTES,
    MERIDIAN,
    CITY,
    STATE_ID,
    FACILITY_ID,
    REQUIRED_VALIDATION,
    MINLENGTH_VALIDATION,
    DATE_OF_CLOSURE,
    CATEGORY_VARIABLE,
    DATE_OF_DISCOVERY,
    FIRST_NAME,
    LAST_NAME,
    ZIP_CODE,
    STATE,
    PHONE_NUMBER,
    SPILL_TYPE,
    STEP_ONE_SUBMITTED_STATUS,
    FAILED_STATUS,
    MMDDYYYY_FORMAT,
    MAXLENGTH_VALIDATION,
    SUBMITTED_STATUS
} from '../../../../../constants/Global';
import {
    DRAFT_STATUS,
    COMPLAINT_ID,
    COMPLAINT_TYPE,
    COMPLAINT_NAME,
    COMPLAINT_STATUS,
    PHYSICAL_ADDRESS,
    COMPLAINT_RECEIVER,
    DATE_OF_COMPLAINT,
    COMPLAINT_TYPE_OTHER,
    COMPLAINT_SOURCE,
    COMPLAINT_SOURCE_OTHER,
    OTHER_SOURCES,
} from '../../../../../constants/Complaints';

const ComplaintDetails = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [isFieldRequired, setIsFieldRequired] = useState(false);
    const [closureDate, setClosureDate] = useState(null);
    const [discoveryDate, setDiscoveryDate] = useState(null);
    const [selectedComplaintType, setSelectedComplaintType] = useState(null);
    const [stateList, setStateList] = useState([]);
    const [selectedComplaintState, setSelectedComplaintState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [complaintType, setComplaintType] = useState(complaintsData.complaintType);
    const [checkIfOtherLocationIsRequired, setcheckIfOtherLocationIsRequired] = useState(false);
    const [checkIfOtherComplaintTypeIsRequired, setcheckIfOtherComplaintTypeIsRequired] = useState(false);
    const [checkIfOtherComplaintSourceIsRequired, setcheckIfOtherComplaintSourceIsRequired] = useState(false);
    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [selectedMeridian, setSelectedMeridian] = useState('');
    const [recordStatus, setRecordStatus] = useState('');
    const [changeEditMode, setEditMode] = useState(props.editMode);
    const [showCancelModal, setshowHideAddCancelPopup] = useState(false);
    const [newFacility, setNewFacility] = useState(false);
    const [complaintSourceArr, setComplaintSourceArr] = useState([]);
    const token = localStorage.getItem('access_token');
    const [isDraft, setIsDraft] = useState();
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        trigger,
        clearErrors,
        formState: { errors },
    } = useForm();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        getStateList();
        if (props.isDraftMode === DRAFT_STATUS) {
            setEditMode(true);
        }
        (props.editMode || props.isDraftMode === DRAFT_STATUS) && getDetailsById();
    }, []);

    const getDetailsById = () => {
        const step_one_submitted = localStorage.getItem(STEP_ONE_SUBMITTED_STATUS);
        let complaint_id = '';
        if (step_one_submitted) {
            complaint_id = localStorage.getItem(COMPLAINT_ID);
        }
        let id = step_one_submitted ? complaint_id : location.state.id
        setLoading(true);
        Get(getComplaintsList, token, '?id=' + id, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setValue(COMPLAINT_NAME, data?.data?.complaint_name);
                    setValue(COMPLAINT_STATUS, data?.data?.complaint_status);
                    if (getValues(COMPLAINT_STATUS) === CLOSED_STATUS) {
                        setValue(DATE_OF_CLOSURE, data?.data?.date_of_closure);
                        setClosureDate(data?.data?.date_of_closure ? new Date(data?.data?.date_of_closure) : null);
                        setIsFieldRequired(true);
                    }
                    setIsDraft(data?.data?.record_status);
                    setValue(CATEGORY_VARIABLE, data?.data?.spill_category);
                    setValue(DATE_OF_DISCOVERY, data?.data?.date_of_discovery);
                    setValue(FIRST_NAME, data?.data?.first_name);
                    setValue(LAST_NAME, data?.data?.last_name);
                    setValue(PHYSICAL_ADDRESS, data?.data?.physical_address);
                    setValue(ZIP_CODE, data?.data?.zip_code);
                    setValue(CITY, data?.data?.city);
                    setValue(STATE, data?.data?.state_name);
                    setValue(PHONE_NUMBER, normalizeInput(data?.data?.phone_number));
                    setValue(COMPLAINT_RECEIVER, data?.data?.complaint_receiver);
                    setValue(DATE_OF_COMPLAINT, data?.data?.date_of_complaint);
                    setDiscoveryDate(data?.data?.date_of_complaint ? new Date(data?.data?.date_of_complaint) : null);
                    if (data?.data?.time) {
                        const splitTime = data?.data?.time.split(':');
                        const splitMeridian = splitTime[1].split(' ');
                        let hourObj = {
                            value: splitTime[0] ? splitTime[0] : '',
                            label: splitTime[0] ? splitTime[0] : '',
                            name: splitTime[0] ? splitTime[0] : '',
                        }
                        let minuteObj = {
                            value: splitMeridian[0] ? splitMeridian[0] : '',
                            label: splitMeridian[0] ? splitMeridian[0] : '',
                            name: splitMeridian[0] ? splitMeridian[0] : '',
                        }
                        let meridianObj = {
                            value: splitMeridian[1] ? splitMeridian[1] : '',
                            label: splitMeridian[1] ? splitMeridian[1] : '',
                            name: splitMeridian[1] ? splitMeridian[1] : '',
                        }
                        setValue(HOUR, hourObj);
                        setSelectedHour(hourObj);
                        setValue(MINUTES, minuteObj);
                        setSelectedMinutes(minuteObj);
                        setValue(MERIDIAN, meridianObj);
                        setSelectedMeridian(meridianObj);
                    }
                    let stateObj = {
                        value: data?.data?.state ? data?.data?.state : '',
                        label: data?.data?.state ? data?.data?.state : '',
                        id: data?.data?.state_id ? data?.data?.state_id : NULL_VALUE,
                    }
                    let complaintTypeObj = {
                        value: data?.data?.complaint_type ? data?.data?.complaint_type : '',
                        label: data?.data?.complaint_type ? data?.data?.complaint_type : '',
                        id: data?.data?.complaint_id ? data?.data?.complaint_id : NULL_VALUE,
                    }
                    let locationObj = {
                        value: data?.data?.facility_name ? data?.data?.facility_name : OTHERS_VALUE,
                        label: data?.data?.facility_name ? data?.data?.facility_name : OTHERS_VALUE,
                        id: data?.data?.facility_id ? data?.data?.facility_id : NULL_VALUE,
                    }
                    setValue(STATE_ID, stateObj);
                    setSelectedComplaintState(stateObj);
                    getLocationById(data?.data?.state_id);
                    setValue(FACILITY_ID, locationObj);
                    setValue(COMPLAINT_TYPE, complaintTypeObj);
                    setSelectedComplaintType(complaintTypeObj);
                    setSelectedLocation(locationObj);
                    if (data?.data?.facility_name === null) {
                        setValue('location', data?.data?.location);
                    }
                    setValue(SPILL_TYPE, data?.data?.spill_type);
                    setComplaintSourceArr(data?.data?.complaint_source ?? []);
                    setValue(COMPLAINT_SOURCE, data?.data?.complaint_source);
                    if (data?.data?.complaint_source && data.data.complaint_source.includes(OTHER_SOURCES)) {
                        setValue(COMPLAINT_SOURCE_OTHER, data?.data?.complaint_source_other);
                    }
                    if (data?.data?.complaint_type === OTHERS_VALUE) {
                        setValue(COMPLAINT_TYPE_OTHER, data?.data?.complaint_type_other);
                    }
                }
            });
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const handleChange = ({ target: { value } }) => {
        setPhoneNumber(prevState => (normalizeInput(value, prevState.phone)));
        const phoneNo = normalizeInput(value);
        setValue(PHONE_NUMBER, phoneNo);
        trigger(PHONE_NUMBER);
    }

    const onCheckboxChange = (e) => {
        const value = e.value;
        let updatedArr;

        setComplaintSourceArr((prevArr) => {
            if (!prevArr.includes(value)) {
                updatedArr = [...prevArr, value];
            } else {
                updatedArr = prevArr.filter((el) => el !== value);
            }

            if (updatedArr.includes(OTHER_SOURCES)) {
                setcheckIfOtherComplaintSourceIsRequired(true);
            }

            if (!(updatedArr.includes(OTHER_SOURCES))) {
                setcheckIfOtherComplaintSourceIsRequired(false);
            }

            setValue(COMPLAINT_SOURCE, updatedArr);
            trigger(COMPLAINT_SOURCE, updatedArr);
            return updatedArr;
        });
    };

    const onSubmit = (data) => {
        setLoading(true);
        if (!changeEditMode) {
            data.facility_id = data.facility_id === NULL_VALUE ? null : data.facility_id;
            if (data.facility_id) {
                delete data.location;
            }
            data.time = (data.meridian === AM_MERIDIAN || data.meridian === PM_MERIDIAN) ? data.hour + ':' + data?.minutes + ' ' + data.meridian : null;
            delete data.hour;
            delete data.minutes;
            delete data.meridian;
        }

        data.record_status = recordStatus;

        const step_one_submitted = localStorage.getItem(STEP_ONE_SUBMITTED_STATUS);
        // if (step_one_submitted) {
        //     let complaint_id = localStorage.getItem(COMPLAINT_ID);
        // }
        if (!changeEditMode) {
            Post(complaintBaseUrl, token, data, false)
                .then((response) => response)
                .then((data) => {
                    if (data.success) {
                        if (recordStatus === DRAFT_STATUS) {
                            navigate('/complaints-management');
                        } else {
                            props.next(data);
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType(FAILED_STATUS);
                        setSuccessRes(true);
                    }
                    setLoading(false);
                });
        } else {
            const hour = (data?.hour?.name) ? data?.hour?.name : data?.hour;
            const minutes = (data?.minutes?.name) ? data?.minutes?.name : data?.minutes;
            const meridian = (data?.meridian?.name) ? data?.meridian?.name : data?.meridian;
            data.time = (meridian === AM_MERIDIAN || meridian === PM_MERIDIAN) ? hour + ':' + minutes + ' ' + meridian : null;
            delete data.hour;
            delete data.minutes;
            delete data.meridian;
            data.state_id = data?.state_id?.id ? data?.state_id?.id : data?.state_id;
            data.complaint_type = selectedComplaintType.value;
            if (newFacility) {
                data.facility_id = data.facility_id;
                if (data.facility_id === NULL_VALUE) {
                    data.facility_id = null;
                }
            } else {
                data.facility_id = data.facility_id.id ? data.facility_id.id : data.facility_id;
                if (data.facility_id === NULL_VALUE) {
                    data.facility_id = null;
                }
            }

            if (data?.facility_id) {
                delete data.location;
            }

            const step_one_submitted = localStorage.getItem(STEP_ONE_SUBMITTED_STATUS);
            let complaint_id = '';
            if (step_one_submitted) {
                complaint_id = localStorage.getItem(COMPLAINT_ID);
            }
            let id = step_one_submitted ? complaint_id : location.state.id
            Put(complaintBaseUrl + id + '/', token, data, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (recordStatus === DRAFT_STATUS) {
                            navigate('/complaints-management');
                        } else {
                            props.next(data);
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType(FAILED_STATUS);
                        setSuccessRes(true);
                    }
                });
        }
    }

    const onCancel = () => {
        navigate('/complaints-management');
    }

    const captureClick = () => {
        setIsSubmittedOnce(true);
    }

    const getStateList = () => {
        setLoading(true);
        Get(getStateListing, token, `?sort=name`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    const stateData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    setStateList(stateData);
                }
            });
    }

    const getLocationById = (id) => {
        setLoading(true);
        Get(getFacilities, token, `?min=${true}&state=${id}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(true);
                let transformData = data;
                let updatedData = transformData.map(item => ({
                    ...item,
                    value: `${item.facility_name}`,
                    label: `${item.facility_name}`
                }));
                const otherLocationObject = { id: 'null', facility_name: 'Others', value: 'Others', label: 'Others' };
                const newArray = [otherLocationObject, ...updatedData];
                setFacilities(newArray);
                setLoading(false);
            });
    }

    const onStatusRadioChange = (e) => {
        const { value } = e.target;
        setValue(COMPLAINT_STATUS, value);
        trigger(COMPLAINT_STATUS);
        if (value === OPEN_STATUS) {
            setIsFieldRequired(false);
            clearErrors(DATE_OF_CLOSURE);
        } else {
            setIsFieldRequired(true);
        }
    }

    const setDateOfClosureValidation = (dateObj) => {
        const transformDate = moment(dateObj).format(MMDDYYYY_FORMAT);
        setValue(DATE_OF_CLOSURE, transformDate);
        trigger(DATE_OF_CLOSURE);
    }

    const setDateOfComplaintValidation = (dateObj) => {
        const transformDate = moment(dateObj).format(MMDDYYYY_FORMAT);
        setValue(DATE_OF_COMPLAINT, transformDate);
        trigger(DATE_OF_COMPLAINT);
    }

    const onSelectionChange = (selection, { name }) => {
        setValue(name, selection.value);
        trigger(name);
        if (name === HOUR) {
            setSelectedHour(selection)
        }
        if (name === MINUTES) {
            setSelectedMinutes(selection)
        }
        if (name === MERIDIAN) {
            setSelectedMeridian(selection)
        }
        if (name === COMPLAINT_TYPE) {
            setValue(name, selection.value);
            setSelectedComplaintType((selection));
            trigger(name);
            if (selection.value === OTHERS_VALUE) {
                setcheckIfOtherComplaintTypeIsRequired(true);
            } else {
                setcheckIfOtherComplaintTypeIsRequired(false);
            }
        }
        if (name === CITY) {
            setValue(name, selection);
            setSelectedCity((selection));
            trigger(name);
        }
        if (name === STATE_ID) {
            setSelectedComplaintState(selection);
            getLocationById(selection.id);
            setValue(name, selection.id);
            trigger(name);
            setSelectedLocation('');
        }
        if (name === FACILITY_ID) {
            setSelectedLocation(selection);
            setValue(name, selection.id ? selection?.id : null);
            trigger(name);
            setNewFacility(true);
            if (selection.value === OTHERS_VALUE) {
                setcheckIfOtherLocationIsRequired(true);
            } else {
                setcheckIfOtherLocationIsRequired(false);
            }
        }
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : ''}
            {successRes ? <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} /> : ''}
            <form className="mt-30" onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>

                    <div className='row'>

                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Complaint Name*</div>
                            <input
                                type='text'
                                className='form-control'
                                name='complaint_name'
                                placeholder='Type here'
                                {...register('complaint_name', { required: true })}
                            />
                            {errors.complaint_name?.type === "required" && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Complaint Status*</div>
                            <div className='rdImpactdGroup mt-16'>
                                <Controller
                                    name='complaint_status'
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                type='radio'
                                                id='complaint_status_closed'
                                                value={'Closed'}
                                                name='spill_status'
                                                onChange={(e) => onStatusRadioChange(e)}
                                                checked={getValues(COMPLAINT_STATUS) === CLOSED_STATUS}
                                            />

                                            <label htmlFor={'complaint_status_closed'}>Closed</label>

                                            <input
                                                type='radio'
                                                id='complaint_status_open'
                                                value={'Open'}
                                                name='complaint_status'
                                                onChange={(e) => onStatusRadioChange(e)}
                                                checked={getValues(COMPLAINT_STATUS) === OPEN_STATUS}
                                            />
                                            <label htmlFor={'complaint_status_open'}>Open</label>
                                        </>
                                    )}
                                    rules={{ required: 'This field is mandatory' }}
                                />
                                {errors.complaint_status && (
                                    <p className="error mt-2"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                                )}
                            </div>
                        </div>

                        {getValues(COMPLAINT_STATUS) === CLOSED_STATUS && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Date of Closure*</div>
                            <Controller
                                name='date_of_closure'
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={closureDate}
                                        onChange={(date) => { setClosureDate(date); setDateOfClosureValidation(date) }}
                                        className='form-control'
                                        placeholderText='Choose the closure date'
                                        dateFormat="MM-dd-yyyy"
                                        minDate={discoveryDate}
                                        maxDate={today}
                                    />
                                )}
                                rules={{ required: isFieldRequired }}
                            />
                            {isFieldRequired && errors.date_of_closure && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>}

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>State*</div>
                            <Controller
                                name="state_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="state"
                                        placeholder='Choose the State where the spill occurred'
                                        options={stateList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedComplaintState}
                                    />
                                )}
                                rules={{ required: true }}
                            />
                            {errors.state_id && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Facility Name*</div>
                            <Controller
                                name="facility_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="location"
                                        placeholder='Select Facility'
                                        options={facilities}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedLocation}
                                    />
                                )}
                                rules={{ required: true }}
                            />
                            {errors.facility_id && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>

                        {selectedLocation?.value === OTHERS_VALUE && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Please type in the Location{checkIfOtherLocationIsRequired && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name="location"
                                placeholder='Type here'
                                {...register("location", { required: checkIfOtherLocationIsRequired })}
                            />
                            {errors.location?.type === "required" && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>}

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Complaint Type*</div>
                            <Controller
                                name="complaint_type"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="complaint_type"
                                        placeholder='Choose the Complaint Type'
                                        options={complaintType}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedComplaintType}
                                    />
                                )}
                                rules={{ required: true }}
                            />
                            {errors.complaint_type && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        {selectedComplaintType?.value === OTHERS_VALUE && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Details about the Complaint{checkIfOtherComplaintTypeIsRequired && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name="complaint_type_other"
                                placeholder='Type here'
                                {...register("complaint_type_other", { required: checkIfOtherComplaintTypeIsRequired })}
                            />
                            {errors.complaint_type_other?.type === "required" && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>}

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Date of Complaint*</div>
                            <Controller
                                name='date_of_complaint'
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={discoveryDate}
                                        onChange={(date) => { setDiscoveryDate(date); setDateOfComplaintValidation(date) }}
                                        className='form-control'
                                        placeholderText='Choose Date'
                                        dateFormat="MM-dd-yyyy"
                                        maxDate={closureDate ? closureDate : today}
                                    />
                                )}
                                rules={{ required: true }}
                            />
                            {errors.date_of_complaint && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>

                    </div>

                </div>

                <div className='container_div mt-30'>

                    <div className='row'>

                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Time of Complaint{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <div className='row mt-16'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="hour"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="hour"
                                                placeholder='Hour'
                                                options={data.Hours}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedHour}
                                            />
                                        )}
                                        rules={{ required: isFieldRequired }}
                                    />
                                    {isFieldRequired && errors.hour && (
                                        <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="minutes"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="minutes"
                                                placeholder='Minutes'
                                                options={data.Minutes}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMinutes}
                                            />
                                        )}
                                        rules={{ required: isFieldRequired }}
                                    />
                                    {isFieldRequired && errors.minutes && (
                                        <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="meridian"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="meridian"
                                                placeholder='Meridian'
                                                options={data.Meridian}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMeridian}
                                            />
                                        )}
                                        rules={{ required: isFieldRequired }}
                                    />
                                    {isFieldRequired && errors.meridian && (
                                        <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 pt-2'>
                            <div className='inputFieldHead'>Who received the complaint?{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name='complaint_receiver'
                                placeholder='Type here'
                                {...register('complaint_receiver', { required: isFieldRequired })}
                            />
                            {isFieldRequired && errors.complaint_receiver && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6 pt-2 mt-30'>
                            <div className='inputFieldHead'>How the Complaint was Received?{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <Controller
                                name={`complaint_source`}
                                control={control}
                                render={({ field }) => (
                                    <CheckboxSelect
                                        data={complaintsData.complaintSources}
                                        colSize={4}
                                        index={1}
                                        name='complaint_source'
                                        onChange={onCheckboxChange}
                                        checkedValues={complaintSourceArr}
                                    />
                                )}
                                rules={{ required: isFieldRequired }} // Validation rule for required
                            />
                            {isFieldRequired && errors?.complaint_source && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>
                        {(complaintSourceArr?.includes('Other Sources') ?? false) && <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Details about the Source{checkIfOtherComplaintSourceIsRequired && getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name="complaint_source_other"
                                placeholder='Type here'
                                {...register("complaint_source_other", { required: checkIfOtherComplaintSourceIsRequired && getValues(COMPLAINT_STATUS) === CLOSED_STATUS })}
                            />
                            {errors.complaint_source_other?.type === "required" && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>}

                    </div>

                </div>

                <div className='containerHeading'>Complainant Details</div>
                <div className='container_div'>

                    <div className='row'>

                        <div className='col-md-6 pt-2'>
                            <div className='inputFieldHead'>First Name{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name='first_name'
                                placeholder='Type here'
                                {...register('first_name', { required: isFieldRequired })}
                            />
                            {isFieldRequired && errors.first_name && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6 pt-2'>
                            <div className='inputFieldHead'>Last Name{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name='last_name'
                                placeholder='Type here'
                                {...register('last_name', { required: isFieldRequired })}
                            />
                            {isFieldRequired && errors.last_name && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Physical Address{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='physical_address'
                                placeholder='Add Address here like B-17, Alexa Road, near Church Gate'
                                rows="3"
                                {...register("physical_address", { maxLength: 500, required: isFieldRequired })}
                            >
                            </textarea>

                            {errors.physical_address && errors.physical_address.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> Maximum 500 characters are allowed </p>
                            )}

                            {isFieldRequired && errors.physical_address && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Zip Code{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name='zip_code'
                                placeholder='Type here'
                                {...register("zip_code", {
                                    minLength: 5,
                                    maxLength: 5,
                                    required: isFieldRequired,
                                    pattern: /^[0-9]{5}$/  // Pattern for exactly 5 digits
                                })}
                            />

                            {errors.zip_code && errors.zip_code.type === MINLENGTH_VALIDATION && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> Minimum 5 digits are allowed </p>
                            )}
                            {errors.zip_code && errors.zip_code.type === MAXLENGTH_VALIDATION && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> Maximum 5 digits are allowed </p>
                            )}
                            {errors.zip_code && errors.zip_code.type === 'pattern' && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> Please enter a valid Zip Code </p>
                            )}
                            {isFieldRequired && errors?.zip_code?.type === REQUIRED_VALIDATION && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>City{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <input
                                type='text'
                                className='form-control'
                                name='city'
                                placeholder='Type here'
                                {...register('city', { required: isFieldRequired })}
                            />
                            {isFieldRequired && errors.city && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>State{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='state'
                                        placeholder='Type here'
                                        {...register('state', { required: isFieldRequired })}
                                    />
                                )}
                                rules={{ required: isFieldRequired }}
                            />
                            {isFieldRequired && errors.state && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}

                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Phone Number{getValues(COMPLAINT_STATUS) === CLOSED_STATUS && '*'}</div>
                            <Controller
                                name='phone_number'
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type='text'
                                        {...field}
                                        className='form-control'
                                        placeholder='Please type phone number'
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleChange(e);
                                        }}
                                    />
                                )}
                                rules={{ required: isFieldRequired, minLength: 14 }}
                            />
                            {errors.phone_number && errors.phone_number.type === MINLENGTH_VALIDATION && isSubmittedOnce && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> Please Enter 10 digit Phone Number</p>
                            )}
                            {isFieldRequired && (errors.phone_number?.type === REQUIRED_VALIDATION) && (
                                <p className="error"> <img src={errorIcon} alt='errorIcon' /> This field is mandatory </p>
                            )}
                        </div>

                    </div>

                </div>

                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={() => setshowHideAddCancelPopup(true)}>Cancel</button>
                    {isDraft !== SUBMITTED_STATUS && <button type="submit" className='btn-add-data mr-15' onClick={() => setRecordStatus('Draft')}>Save As Draft</button>}
                    <button type="submit" className='btn-proceed in-blck mt-30' onClick={() => {captureClick(); setRecordStatus('Submitted')}}>Proceed</button>
                </div>

            </form>

            <Modal show={showCancelModal} dialogClassName="reportConfrmModal" onHide={() => setshowHideAddCancelPopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText width_text">
                        <div>Are you sure you want to cancel it ?</div>
                        <div className="mt-2">By canceling, you will lose all the information you've {!changeEditMode ? 'entered' : 'updated'}.</div>
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideAddCancelPopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setshowHideAddCancelPopup(false); onCancel() }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ComplaintDetails;