import React, { useState } from 'react'
import CreateCompanyDetails from './CreateCompanyDetails';
import PlanAndAdminDetails from './PlanAndAdminDetails';
import { Steps } from 'antd';
import BtnCrossIcon from '../../../../assets/images/FormImages/btnCross.svg';
import { useLocation, useNavigate } from 'react-router';
import './CreateCompany.css';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';
import { Modal } from 'react-bootstrap';

const CreateCompany = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [current, setCurrent] = useState(0);
    const [responseFromDetail, setResponseFromDetails] = useState('');
    const [initialDetailsFilled, setIntialDetailsFilled] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [resDetail, setResDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showHideFormFilled, setshowHideFormFilled] = useState({ show: false });
    const [stepperActivate, setStepperActivate] = useState(false);
    const companyId = localStorage.getItem('companyId');

    const steps = [
        { label: 'Company Details' },
        { label: 'Plan and Admin Details' },
    ];

    const handleSteps = (e) => {

        if (initialDetailsFilled && current == 1) {
            setshowHideFormFilled({ show: true });
        } else if (!props.editMode && current == 0 && !initialDetailsFilled) {
            setAlertType('failed');
            setSuccessRes(true);
            setResDetail('Please fill Company Details');
        } else if (props.editMode && current == 0) {
            // setCurrent(1);
            setAlertType('failed');
            setSuccessRes(true);
            setResDetail('Please click on the Proceed button below');
        } else if (!props.editMode && initialDetailsFilled) {
            setAlertType('failed');
            setSuccessRes(true);
            setResDetail('Please click on the Proceed button below');
        }
    }

    const handleCompanyOnboard = () => {
        navigate('/company-list');
    }

    const next = (res) => {
        setIntialDetailsFilled(true);
        setCurrent(current + 1);
        setResponseFromDetails(res);
        setStepperActivate(true);
        localStorage.setItem('step_one_submitted', true);
        localStorage.setItem('companyId', res.id);
    };

    const items = steps.map((item) => ({
        key: item.label,
        title: item.label,
    }));

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <div>
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={resDetail} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div className="head_1">
                        <span className='click_head_1 cursor' onClick={handleCompanyOnboard}>Onboard Management</span> /
                        <span>{props.editMode ? 'Update Company details' : 'Onboard Company'} </span>
                    </div>
                    <div className="head_2">{props.editMode ? 'Update Onboarding Details' : 'Onboard Company'} </div>
                </div>

                <Steps current={current} items={items} className='margin-top-36 custom-steps' onChange={() => handleSteps(current)} />

                {current < steps.length - 1 && (
                    <CreateCompanyDetails isEditMode={props.editMode ? props.editMode : stepperActivate} next={next} id={companyId ? companyId : location?.state?.id} active={location?.state?.is_active} />
                )}
                {current === steps.length - 1 && (
                    <PlanAndAdminDetails isEditMode={props.editMode} responseFromDetail={responseFromDetail} active={location?.state?.is_active} />
                )}

                {/* <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15'>Cancel</button>
                    {(current < steps.length - 1) ?
                        <button type="button" className='btn-proceed in-blck mt-30' onClick={() => next()}>Proceed</button>
                        : <button type="button" className='btn-event-incident in-blck mt-30' data-bs-toggle="modal" data-bs-target="#onboardModal"> {props.editMode ? 'Update Company' : 'Onboard Company'}</button>

                    }
                </div> */}
            </div>

            <div className="modal fade" id="onboardModal" tabIndex="-1" role="dialog" aria-labelledby="onboardModalLabel" aria-hidden="true">
                <div className="modal-dialog reportConfrmModal" role="document">
                    <div className="modal-content">
                        <div className="modal-header sideOnRight">
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={BtnCrossIcon} />
                            </button>
                        </div>
                        <div className="modal-body reportText">
                            Are you sure you would like to {props.editMode ? 'update the onboarding details ?' : 'onboard this company ?'}
                        </div>
                        <div className="modal-footer middle">
                            <button type="button" className="btn-no" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn-yes" data-bs-dismiss="modal" onClick={handleCompanyOnboard}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showHideFormFilled.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideFormFilled({ show: false, viewData: {} })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        After proceeding to Company Details, {props.editMode ? 'updated' : ''} Plan and Admin Details data will be lost. Are you sure you want to continue?
                    </div>
                    <Modal.Footer className='content-center'>
                        <button type="button" className="btn-no" onClick={() => setshowHideFormFilled({ show: false })}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setCurrent(current - 1); setshowHideFormFilled({ show: false }) }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default CreateCompany;