import ReportableSpillsIcon from '../assets/images/ReportableSpills.svg';
import NonReportableSpillsIcon from '../assets/images/NonReportableSpills.svg';
import SpilledOilVolumeIcon from '../assets/images/SpilledOilVolume.svg';
import SpilledProducedWaterIcon from '../assets/images/SpilledProducedWater.svg';

const SpillsDashboardCount = [
    { name: 'Reportable Spills', icon: <img src={ReportableSpillsIcon} alt='ReportableSpillsIcon' /> },
    { name: 'Non-Reportable Spills', icon: <img src={NonReportableSpillsIcon} alt='NonReportableSpillsIcon' /> },
    { name: 'Spilled Oil Volume', icon: <img src={SpilledOilVolumeIcon} alt='SpilledOilVolumeIcon' /> },
    { name: 'Spilled Produced Water', icon: <img src={SpilledProducedWaterIcon} alt='SpilledProducedWaterIcon' /> },
];

const SpillType = [
    { id: 1, name: 'Historical', label: 'Historical' },
    { id: 2, name: 'Recent', label: 'Recent' },
];

const FluidType = [
    { id: 1, name: 'Oil', label: 'Oil' },
    { id: 2, name: 'Produced Water', label: 'Produced Water' },
];

const spillsGraphCustomizationData = {
  data: {
      "spillsCharts": [
          {
              "id": "Reportable Spills Total & Intensity",
              "name": "Reportable Spills Total & Intensity",
              "label": "Reportable Spills Total & Intensity",
              "checked": true,
          },
          {
              "id": "Historical versus Recent Spills Total",
              "name": "Historical versus Recent Spills Total",
              "label": "Historical versus Recent Spills Total",
              "checked": true,
          },
          {
              "id": "Volume of Oil & Produced Water Spills",
              "name": "Volume of Oil & Produced Water Spills",
              "label": "Volume of Oil & Produced Water Spills",
              "checked": true
          },
        //   {
        //       "id": "Volume of Oil & Produced Water Heat Map",
        //       "name": "Volume of Oil & Produced Water Heat Map",
        //       "label": "Volume of Oil & Produced Water Heat Map",
        //       "checked": true
        //   },
        //   {
        //       "id": "Reportable & non-reportable spills",
        //       "name": "Reportable & non-reportable spills",
        //       "label": "Reportable & non-reportable spills",
        //       "checked": true
        //   }
      ]
  }
}

const DummyUIData = [
    {
        "success": true,
        "paginator": {
          "total_pages": 1,
          "current_page": 1,
          "next_page": 1,
          "prev_page": null,
          "total_count": 1
        },
        "data": [
          {
            "deleted_on": null,
            "created_on": "02-16-2024",
            "updated_on": "02-27-2024",
            "id": 625,
            "date_of_discovery": "02-20-2024",
            "category": "Reportable",
            "facility": "Facility 184",
            "location": null,
            "spill_type": "Historical",
            "spill_status": "Open",
            "record_status": "Submitted",
            "oil_spill": 12,
            "produced_water": 12,
            "investigation_id": 613,
            "name": "Texas Spill 7",
            "state_name": "Colorado",
            "facility_id": 461057,
            "facility_type": null
          },
        ]
      }
]

export default {
    SpillsDashboardCount, SpillType, FluidType, DummyUIData, spillsGraphCustomizationData
}