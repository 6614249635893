import React, { useState, useEffect, useRef } from "react";
import './AddRoles.css';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import CheckboxSingleSelect from "../../../components/formBuilder/CheckboxSingleSelect";
import BackwardIcon from '../../../assets/images/Backward.svg';
import { useLocation, useNavigate } from "react-router";
import { Get, Post, Put } from "../../../constants/apiMethods";
import { masterModulesList, addUpdateRole, getRoles } from "../../../constants/apiRoutes";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import LoadingSpinner from "../../../components/Loader";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import InfoIcon from '../../../assets/images/Info.svg';
import errorIcon from "../../../assets/images/error_icon.svg";
import infoIcon from '../../../assets/images/Info.svg';

const AddRoles = (props) => {
    const isSidebarShow = JSON.parse(localStorage.getItem('isSidebarShow'));
    const [toggle, setToggle] = useState(false);
    const [show, setShow] = useState(false);
    const location = useLocation();
    const target = useRef(null);
    const [userRole, setUserRole] = useState();
    const [successRes, setSuccessRes] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const [showActive, setShowActive] = useState(true);
    const [modulesList, setModulesList] = useState([]);
    const [loading, setLoading] = useState();
    const navigate = useNavigate();
    const [finalRolesArr, setFinalRolesArr] = useState([]);
    const [alertType, setAlertType] = useState('');
    const token = localStorage.getItem('access_token');
    const [showAddUpdateRole, setShowAddUpdateRole] = useState({ show: false });
    let hover = false;

    useEffect(() => {
        props.editMode && getRoleDetailsById(1);
        getMasterModulesList(1);
        transformModuleList();
    }, [toggle]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues
    } = useForm();

    const setUserDetails = (data) => {
        setFinalRolesArr(data.permissions);
        setValue('name', data.name);

    }

    const getRoleDetailsById = (page) => {
        setLoading(true);
        Get(getRoles, token, `?page=${page}&is_active=${location.state.showActive}&id=${location.state.id}`, false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    setUserRole(data.data);
                    setUserDetails(data.data);
                    setLoading(false);
                }
            });
    }

    const transformModuleList = () => {
        let transformedArr = [];
        transformedArr = modulesList && modulesList.map(ele => ({
            'moduleName': ele.name,
            'id': ele.id,
            'dashboardChecked': false,
            'exportAccess': false,
            'readAccess': false,
            'writeAccess': false,
        }))
        setFinalRolesArr(transformedArr);
    }

    const resetForm = () => {
        const updatedData = finalRolesArr.map(item => ({
            ...item,
            dashboardChecked: false,
            readAccess: false,
            writeAccess: false,
            exportAccess: false
        }));

        setFinalRolesArr(updatedData);
        setValue('name', '');
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const onSubmit = () => {
        setShowAddUpdateRole({ show: true })
    };

    const callMethod = () => {
        setShowAddUpdateRole({ show: false })
        props.editMode ? updateRole() : addRole()
    }

    const getMasterModulesList = (page) => {
        setLoading(true);
        Get(masterModulesList, token, `?page=${page}&is_active=${showActive}`, false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    setModulesList(data.data);
                    setLoading(false);
                    setToggle(true);
                }
            });
    }

    const addRole = () => {
        let body = {
            name: getValues('name'),
            permissions: finalRolesArr
        }
        setLoading(true);
        Post(`${addUpdateRole}/`, token, body, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setSuccessDetail('New Role and access created');
                    setSuccessRes(true);
                    setAlertType('success');
                    setLoading(false);
                    setToggle(true);
                    resetForm();
                    setTimeout(() => {
                        if (isSidebarShow) {
                            navigate('/roles-list'); 
                        } else {
                            navigate('/company-admin');
                        }
                    }, 2000)
                }
                if (!data.success) {
                    setSuccessDetail(data.detail);
                    setSuccessRes(true);
                    setAlertType('failed');
                    setLoading(false);
                }
            }).catch((res) => {
            })
    }

    const updateRole = () => {
        let body = {
            name: getValues('name'),
            permissions: finalRolesArr
        }
        setLoading(true);
        Put(`${addUpdateRole}/${location.state.id}/`, token, body, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setSuccessDetail(data.detail);
                    setSuccessRes(true);
                    setAlertType('success');
                    setLoading(false);
                    setToggle(true);
                    resetForm();
                    setTimeout(() => {
                        if (isSidebarShow) {
                            navigate('/roles-list'); 
                        } else {
                            navigate('/company-admin');
                        }
                    }, 2000)
                }
                if (!data.success) {
                    setSuccessDetail(data.detail);
                    setSuccessRes(true);
                    setAlertType('failed');
                    setLoading(false);
                }

            }).catch((res) => {
            })
    }

    const onChange = (e, index) => {
        let updatedData;
        if (e.target.name === 'dashboardChecked') {
            updatedData = [...finalRolesArr];
            updatedData[index][e.target.name] = e.target.checked;
        }

        if (e.target.name === 'readAccess') {
            updatedData = [...finalRolesArr];
            updatedData[index]['dashboardChecked'] = e.target.checked === false ? true : e.target.checked;
            updatedData[index]['readAccess'] = e.target.checked;
        }

        if (e.target.name === 'writeAccess') {
            updatedData = [...finalRolesArr];
            updatedData[index]['dashboardChecked'] = e.target.checked === false ? true : e.target.checked;
            updatedData[index]['readAccess'] = e.target.checked === false ? true : e.target.checked;
            updatedData[index]['writeAccess'] = e.target.checked;
        }
        setFinalRolesArr(updatedData);
    }

    const checkSelectAll = () => {
        return finalRolesArr.every(item =>
            item.dashboardChecked === true &&
            item.writeAccess === true &&
            item.readAccess === true &&
            item.exportAccess === true
        );
    }

    const routeToCompanyAdmin = () => {
        if (isSidebarShow) {
            navigate('/roles-list'); 
        } else {
            navigate('/company-admin');
        }
    }

    const onAllModuleSelect = (e) => {
        let updatedData;
        if (e.target.name === 'dashboardChecked') {
            updatedData = finalRolesArr.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
        }

        if (e.target.name === 'readAccess') {
            updatedData = finalRolesArr.map(item => ({
                ...item,
                ['dashboardChecked']: e.target.checked === false ? true : e.target.checked,
                ['readAccess']: e.target.checked,
            }));
        }

        if (e.target.name === 'writeAccess') {
            updatedData = finalRolesArr.map(item => ({
                ...item,
                ['dashboardChecked']: e.target.checked === false ? true : e.target.checked,
                ['readAccess']: e.target.checked === false ? true : e.target.checked,
                ['writeAccess']: e.target.checked,
            }));
        }
        setFinalRolesArr(updatedData);
    }

    const allDashboardAccessSelected = () => {
        return finalRolesArr.every(item => item.dashboardChecked === true);
    }

    const allReadAccessSelected = () => {
        return finalRolesArr.every(item => item.readAccess === true);
    }

    const allWriteAccessSelected = () => {
        return finalRolesArr.every(item => item.writeAccess === true);
    }

    const allExportAccessSelected = () => {
        return finalRolesArr.every(item => item.exportAccess === true);
    }

    const popoverBottom = (
        <Popover id="popover-positioned-bottom" title="Popover bottom" style={{ position: 'relative', top: '50px' }} className="popuuu" placement="bottom">
            <div className="headTolltip">How Access Control Panel works?</div>
            <hr className="hr-class-role" />
            <div className="reportCat">Description</div>
            <div className="descrpTolltip">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
        </Popover>
    );


    const selectAll = (e) => {
        const updatedData = finalRolesArr.map(item => ({
            ...item,
            dashboardChecked: e.target.checked,
            readAccess: e.target.checked,
            writeAccess: e.target.checked,
            exportAccess: e.target.checked
        }));
        // Update the state with the new array
        setFinalRolesArr(updatedData);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="full-cont">
                    {loading ? <LoadingSpinner /> : null}
                    {successRes ? <AlertNotifier alertType={alertType} timer={4000} alertText={successDetail} setVisibility={setVisibility} /> : ''}
                    <div className='containerHeading'><img src={BackwardIcon} className="cursor" onClick={routeToCompanyAdmin} alt="backwardImage" />    {props.editMode ? 'Update Role and Access' : 'Create Role and Access'}</div>
                    <div className='row mt-30 light-container'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Role*</div>
                            <input type='text' className='form-control' name="name" id="name" placeholder='Type role name here'
                                {...register("name", { required: true, maxLength: 50 },
                                )}
                            />
                            {errors.name?.type == "required" && (
                                <p className="error"> <img src={errorIcon} alt="errorImg" /> This field is mandatory </p>
                            )}
                            {errors.name && errors.name.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} alt="errorImg" /> Maximum 50 characters are allowed </p>
                            )}
                        </div>
                    </div>

                    <div className="container-div">
                        <span className='containerHeading mb-3'>Access Control Panel
                        <OverlayTrigger placement="bottom" overlay={popoverBottom}>
                        <img className="cursor info-icon-class" src={infoIcon} alt={'alternateIcon'}
                        />
                    </OverlayTrigger>
                            </span>
                        {/* <span className="flex select-top"> <CheckboxSingleSelect name='selectAll' onChange={(e) => selectAll(e)} checked={checkSelectAll()} />Select All</span> */}
                    </div>

                    <div className="light-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Modules</th>
                                    <th scope="col">
                                        <span className="flex"><span><CheckboxSingleSelect name='dashboardChecked' disabled={allReadAccessSelected()} checked={allDashboardAccessSelected()} onChange={(e) => onAllModuleSelect(e)} /></span>Dashboard</span>
                                    </th>
                                    <th scope="col">
                                        <span className="flex"><span><CheckboxSingleSelect name='readAccess' disabled={allReadAccessSelected() && allWriteAccessSelected()} checked={allReadAccessSelected()} onChange={(e) => onAllModuleSelect(e)} /></span>Read</span>
                                    </th>
                                    <th scope="col">
                                        <span className="flex"><span><CheckboxSingleSelect name='writeAccess' checked={allWriteAccessSelected()} onChange={(e) => onAllModuleSelect(e)} /></span>Read-Write</span>
                                    </th>
                                    <th scope="col">
                                        {/* <span className="flex"><span><CheckboxSingleSelect name='exportAccess' checked={allExportAccessSelected()} onChange={(e) => onAllModuleSelect(e)} /></span>Export</span> */}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>



                                {finalRolesArr.map((item, index) => {
                                    return (
                                        <tr className="brdrtop" key={index}>

                                            <td>{item.moduleName}</td>

                                            <td className="pl">{item.moduleName && <CheckboxSingleSelect item={item} index={index} disabled={item.readAccess} checked={item.dashboardChecked} name={'dashboardChecked'} label={item.moduleName} onChange={(e, index, item) => onChange(e, index, item)} moduleName={item.dashboardChecked} />}</td>
                                            <td className="pl">{item.moduleName && <CheckboxSingleSelect item={item} index={index} disabled={item.readAccess && item.writeAccess} checked={item.readAccess} name={'readAccess'} label={item.moduleName} onChange={(e, index, item) => onChange(e, index, item)} moduleName={item.readAccess} />}</td>
                                            <td className="pl">{item.moduleName && <CheckboxSingleSelect item={item} index={index} checked={item.writeAccess} name={'writeAccess'} label={item.moduleName} onChange={(e, index, item) => onChange(e, index, item)} moduleName={item.writeAccess} />}</td>
                                            {/* <td className="pl">{item.moduleName && <CheckboxSingleSelect item={item} index={index} checked={item.exportAccess} name={'exportAccess'} label={item.moduleName} onChange={(e, index, item) => onChange(e, index, item)} moduleName={item.exportAccess} />}</td> */}

                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                    <div className="text-right">
                        <button type="button" className='btn-add-data mr-15' onClick={()=> {navigate('/company-admin')}}>Cancel</button>
                        <button type="submit" className='btn-event-incident in-blck mt-30' >{props.editMode ? 'Update' : 'Create'}</button>
                    </div>
                </div>
            </form>

            

            {/* <Overlay target={target.current} show={show} placement="bottom" className='overlay-access-control'>
                {(props) => (
                    <Tooltip  {...props}>
                        <div className="reportCat">How Access Control Panel works?</div>
                        <hr className="mt-mb-2" />
                        <div className="headTolltip">Lorem Ipsum</div>
                        <div className="descrpTolltip">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
                    </Tooltip>
                )}
            </Overlay> */}

            <Modal show={showAddUpdateRole.show} dialogClassName="reportConfrmModal" onHide={() => setShowAddUpdateRole({ show: false })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {props.editMode ? 'update' : 'create'}<br /> the Role and Access ?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" data-bs-dismiss="modal" onClick={() => setShowAddUpdateRole({ show: false })}>No</button>
                        <button type="button" className="btn-yes" data-bs-dismiss="modal" onClick={() => callMethod()}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddRoles;