import React, { useRef, useState, useEffect } from 'react';
import mockData from '../../../mockData/mockData';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import DownloadAlertImg from '../../../assets/images/downloadImageAlert.svg';
import "react-circular-progressbar/dist/styles.css";
import CheckboxSelect from '../../../components/formBuilder/CheckboxSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import { Post, Delete, ImagePost } from "../../../constants/apiMethods";
import { fileUpload, deleteImage, updateEvent, permanentDestroyEvent, getDataById } from "../../../constants/apiRoutes";
import './IncidentInvestigationDetails.css';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { useDispatch, useSelector } from 'react-redux';
import { addIncident } from '../../../store/slice/incidentSlice';
import { Controller, useForm } from "react-hook-form";
import errorIcon from "../../../assets/images/error_icon.svg";
import { Modal } from "react-bootstrap";
import FileUploader from '../../../components/FileUploader';
import EditUploadedFiles from '../../../components/FileUpload/EditUploadedFiles';
import FilePreviewFinal from '../../../components/FileUpload/FilePreviewFinal';
import {
    FAILED_STATUS,
    SUCCESS_STATUS,
    IMAGE_JPEG_FORMAT,
    IMAGE_JPG_FORMAT,
    TOO_LARGE_FILE,
    IMAGE_PNG_FORMAT,
    PDF_FORMAT,
    XLSX_FILE_FORMAT,
    XLSX_FILE_SPREADSHEET_FORMAT,
    XLSX_VND_FORMAT,
    CSV_FILE_FORMAT,
    DOCX_FILE_FORMAT,
    DOC_MS_WORD_FILE_FORMAT
} from '../../../constants/Global';

function IncidentInvestigationDetails({
    editMode,
    responseFromDetail,
    incidentUpdateId
}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedInUserName = localStorage.getItem('name');
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem('access_token');
    const [alertType, setAlertType] = useState('');
    const [conclusionArr, setConclusionArr] = useState([]);
    const [correctiveArr, setCorrectiveArr] = useState([]);
    const [filesArr, setFilesArr] = useState([]);
    const [imageUserId, setImageUserId] = useState(responseFromDetail.result.id);
    const inputFile = useRef(null);
    const [images, setImages] = useState([]);
    const [incidentObj, setIncidentObj] = useState(responseFromDetail.result.formdata);
    const [initialSubmission, setInitialSubmission] = useState(true);
    const [initiallyReportedBy, setInitiallyReportedBy] = useState('');
    const [draftMode, setDraftMode] = useState(false);
    const [showHideLogout, setshowHideLogoutPopup] = useState(false);
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [docFormatType, setDocFormatType] = useState('');
    const [isDraft, setIsDraft] = useState();
    const [documentArr, setDocumentArr] = useState([]);

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {
        if (editMode) {
            setValue('eventDescription', responseFromDetail.result.formdata.eventDescription);
            setValue('correctiveMoreDetails', responseFromDetail.result.formdata.correctiveMoreDetails);
            setValue('investigationMoreDetails', responseFromDetail.result.formdata.investigationMoreDetails);
        }
        setImages(images);
        editMode && getDetailsById();
    }, [incidentObj]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    // const dataFromRedux = useSelector((state)=> {
    //     dispatch(addIncident({...state.incidents[0], step_one_submitted: true}));
    // });
    // console.log('data from redux', dataFromRedux)

    const getDetailsById = () => {
        let data = initialSubmission === true ? false : false;
        setInitialSubmission(data);
        let body = {
            id: location.state.id
        }
        Post(getDataById, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.code === 200) {
                    const updatedDocuments = data.result[0]?.image && data.result[0]?.image.length > 0 && data.result[0]?.image.map(doc => {
                        const extension = doc.img_name.split('.').pop();
                        return { ...doc, extension };
                    });
                    setImages(updatedDocuments ? updatedDocuments : []);
                    setInitiallyReportedBy(data.result[0]?.safetyformdata[0]?.formdata?.reportedby)
                    setIsDraft(data.result[0]?.safetyformdata[0]?.draft);
                }
            });
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const deleteImg = (image) => {
        if (image && image.uploadimg === 'too large') {
            const currImages = images.filter((item) => item.id !== image.id);
            setImages(currImages);
        }
        if (image && image.uploadimg !== 'too large') {
            setLoading(true);
            let data = {
                id: image.id
            }
            Delete(deleteImage, token, data, true)
                .then((res) => {
                    setLoading(false);
                    if (res.success) {
                        setSuccessDetail(res.message);
                        setAlertType('success');
                        setSuccessRes(true);
                        const currImages = images.filter((item) => item.id !== image.id);
                        setImages(currImages);
                    }
                    if (!res.success) {
                        setSuccessDetail(res.detail);
                        setAlertType('failed');
                        setSuccessRes(true);
                    }
                })
                .catch((error) => {
                });
        }
    }

    const onChange = (e) => {
        const obj = { ...incidentObj };
        const updatedObject = { ...obj, [e.target.name]: e.target.value };
        setIncidentObj(updatedObject);
    }

    const onCancel = () => {
        setLoading(true);
        let data = {
            id: imageUserId
        }
        if (editMode) {
            navigate('/event-list');
        }
        if (!editMode) {
            Post(permanentDestroyEvent, token, data, true)
                .then((res) => {
                    setLoading(false);
                    if (res.success) {
                        setTimeout(() => {
                            navigate('/event-list');
                        }, 2000)
                    }
                })
                .catch((error) => {
                })
        }
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const handleFileUpload = (file) => {
        let imageType = file[0].type;
        const allowedCharactersRegex = /^[a-zA-Z0-9_()-. ]+$/;

        if ([IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, PDF_FORMAT, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT, CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT].includes(imageType)) {
            setLoading(true);

            if (file[0].size > 26214400) {
                let data = {
                    formid: '000',
                    image_link: TOO_LARGE_FILE
                }
                const updatedArray = [...images, data];
                setImages(updatedArray);
                setLoading(false);
            }

            if (file[0].name && !allowedCharactersRegex.test(file[0].name)) {
                setSuccessDetail('Document name should not contain special characters!');
                setAlertType(FAILED_STATUS);
                setLoading(false);
                setSuccessRes(true);
                return;
            }

            if (file[0].size < 26214400) {
                setLoading(true);
                const formData = new FormData();
                formData.append(`image[]`, file[0]);
                formData.append('img_name', file[0].name);
                formData.append('image_size', file[0].size);
                formData.append('formid', imageUserId);
                ImagePost(fileUpload, token, formData, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res?.message);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            let extension = res.result[0].uploadimg.split('.').pop();
                            let extensionObj = { ...res.result[0], extension };
                            const updatedArray = [...images, extensionObj];
                            setImages(updatedArray);
                            const documentItemArr = [...documentArr, res.upload_id];
                            setDocumentArr(documentItemArr);
                        }
                        if (!res.success) {
                            setSuccessDetail(res.message);
                            setAlertType(FAILED_STATUS);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred while uploading image:', error);
                    });
            }
        }
        else {
            setSuccessDetail('Document type not supported!');
            setAlertType(FAILED_STATUS);
            setLoading(false);
            setSuccessRes(true);
        }
    }

    const onFileChange = (files) => {
        let itemArr = [...filesArr];
        const filePath = handleFileUpload(files);
        setFilesArr((prevElements) => [...prevElements, filePath]);
        itemArr.push(filePath);

        if (inputFile.current) {
            inputFile.current.value = null;
        }
    }

    const onCheckboxInvChange = (e, name) => {
        const id = e.id;
        let itemArr = editMode ? incidentObj.investigationConclusion : [...conclusionArr];
        if (!itemArr.includes(id)) {
            itemArr.push(id);
        }
        else if (itemArr.includes(id)) {
            itemArr = itemArr.filter((el) => el !== id);
        }
        setConclusionArr(itemArr);
        const obj = { ...incidentObj };
        const updatedObject = { ...obj, [name]: itemArr };
        setIncidentObj(updatedObject);
    }

    const onCheckboxCorChange = (e, name) => {
        const id = e.id;
        let itemArr = editMode ? incidentObj.correctiveAction : [...correctiveArr];
        if (!itemArr.includes(id)) {
            itemArr.push(id);
        }
        else if (itemArr.includes(id)) {
            itemArr = itemArr.filter((el) => el !== id);
        }
        setCorrectiveArr(itemArr);
        const obj = { ...incidentObj };
        const updatedObject = { ...obj, [name]: itemArr };
        setIncidentObj(updatedObject);
    }

    const showPreview = (item) => {
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        if (item?.img_name) {
            setImageName(item?.img_name ? item?.img_name : item?.image_name);
            setImageToPreview(`${imgUrl}${item?.uploadimg}`)
        }
        if (item?.image_link) {
            setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
            setImageToPreview(`${imgUrl}${item?.image_link}`)
        }
    }

    const onSubmit = (data, e) => {
        e.preventDefault()
        setshowHideLogoutPopup(true);
    }

    const handleUpdateIncident = (val) => {
        setLoading(true);
        let data = {
            "id": editMode ? incidentUpdateId : imageUserId,
            "userid": 1,
            "type": 'incident',
            "draft": val ? 1 : 0,
            "formdata": {
                "date": incidentObj.date,
                "reportedby": editMode ? initiallyReportedBy : loggedInUserName,
                "updatedby": '',
                "name": incidentObj.name,
                "location": incidentObj.location,
                "location_id": incidentObj.location_id,
                "state": incidentObj.state,
                "state_id": incidentObj.state_id,
                "hour": incidentObj.hour,
                "minutes": incidentObj.minutes,
                "meridian": incidentObj.meridian,
                "time": incidentObj.hour + ':' + incidentObj.minutes + ' ' + incidentObj.meridian,
                "correctiveAction": incidentObj.correctiveAction,
                "investigationConclusion": incidentObj.investigationConclusion,
                "eventDescription": getValues('eventDescription'),
                "correctiveMoreDetails": getValues('correctiveMoreDetails'),
                "investigationMoreDetails": getValues('investigationMoreDetails'),
            },
            "initialSubmission": initialSubmission,
            'incidentdata': [responseFromDetail.result.incidentdata[0]]
        };
        if (!incidentObj.location) {
            data.formdata.other_location = incidentObj.other_location;
        }

        Post(updateEvent, token, data, true)
            .then((res) => {
                if (res.success) {
                    setSuccessDetail(`Investigation details are ${editMode ? 'updated' : 'added'} successfully`)
                    setAlertType('success');
                    setSuccessRes(true);
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/event-list');
                        localStorage.removeItem('step_one_submitted');
                        localStorage.removeItem('userId');
                    }, 2000)
                }
                if (!res.success) {
                    setSuccessDetail(res.message);
                    setAlertType('failed');
                    setSuccessRes(true);
                }
                setLoading(false);
            })
            .catch((error) => {
            });
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>

                    <div className='row'>

                        <div className='col-md-12'>
                            <div className='inputFieldHead'>Share the description of the Incident<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                name='eventDescription'
                                className='form-control'
                                placeholder='Add incident details'
                                rows="2"
                                onChange={onChange}
                                {...register("eventDescription", { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.eventDescription && errors.eventDescription.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Investigation Conclusion</div>
                            <div className='row'>
                                <CheckboxSelect
                                    data={mockData.InvestigationConclusion}
                                    colSize={4}
                                    index={1}
                                    name='investigationConclusion'
                                    onChange={onCheckboxInvChange}
                                    checkedValues={incidentObj.investigationConclusion}
                                />
                            </div>
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Add more details<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='investigationMoreDetails'
                                placeholder='Add more details'
                                rows="2"
                                onChange={onChange}
                                {...register("investigationMoreDetails", { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.investigationMoreDetails && errors.investigationMoreDetails.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}

                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Corrective Actions</div>
                            <div className='row'>
                                <CheckboxSelect
                                    data={mockData.CorrectiveActions}
                                    colSize={4}
                                    index={2}
                                    name='correctiveAction'
                                    onChange={onCheckboxCorChange}
                                    checkedValues={incidentObj.correctiveAction}
                                />

                            </div>
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Add more details<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='correctiveMoreDetails'
                                placeholder='Add more details'
                                rows="2"
                                onChange={onChange}
                                {...register("correctiveMoreDetails", { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.correctiveMoreDetails && errors.correctiveMoreDetails.type === "maxLength" && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Upload Supporting Documentation</div>
                            <FileUploader onFileUpload={onFileChange} />
                            <div className='wthoutSpcl'>Up to 25 MB - File name without special characters</div>
                        </div>

                        <div className='col-md-6 img-container'>
                            {images.map((image, i) => (
                                <div key={i}>
                                    {image.uploadimg === 'too large' &&
                                        <EditUploadedFiles
                                            type='alertImageUpload'
                                            deleteImg={deleteImg}
                                            index={i}
                                            image={image}
                                            showPreview={showPreview} >
                                            <img src={DownloadAlertImg} />
                                        </EditUploadedFiles>}
                                    {image.uploadimg !== 'too large' &&
                                        <EditUploadedFiles
                                            type='uploadedImage'
                                            deleteImg={deleteImg}
                                            index={i}
                                            image={image}
                                            showPreview={showPreview}>
                                            <img src={DownloadCompleteImg} />
                                        </EditUploadedFiles>}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={() => onCancel()}>Cancel</button>
                    {isDraft !== 0 && <button type="button" className='btn-add-data mr-15' onClick={() => { handleUpdateIncident(setDraftMode(true)) }}>Save As Draft</button>}
                    <button type="submit" className='btn-event-incident in-blck mt-30'>{editMode ? 'Update' : 'Submit'} Incident</button>
                </div>
            </form>


            <Modal size="lg"
                dialogClassName="modal-90w"
                show={showPreviewImg}
                onHide={() => setShowPreviewImg(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                    {imageName}
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    {['pdf'].includes(docFormatType) && <FilePreviewFinal pdfFile={imageToPreview ? imageToPreview : ''} />}
                    {['jpg', 'jpeg', 'png'].includes(docFormatType) && <div className="modal-body reportText">
                        <img src={imageToPreview} className="img-preview" />
                    </div>}
                </Modal.Body>
            </Modal>

            <Modal show={showHideLogout} dialogClassName="reportConfrmModal" onHide={() => setshowHideLogoutPopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {editMode ? 'update' : 'add'} this Incident?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideLogoutPopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { handleUpdateIncident(setDraftMode(false)); setshowHideLogoutPopup(false) }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default IncidentInvestigationDetails
