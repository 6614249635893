import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import { Delete2, Delete3, Get, ImagePost, Put } from '../../../constants/apiMethods';
import { county, facility, facilityUpload } from '../../../constants/apiRoutes';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import ForwardPagination from '../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../assets/images/BackwardPagination.svg';
import ThreeDotsIcon from '../../../assets/images/FormImages/threeDots.svg';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import UploadIcon from '../../../assets/images/FormImages/upload.svg';
import CrossImg from '../../../assets/images/crossImage.svg';
import DeleteImg from '../../../assets/images/deleteImg.svg';
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import EditSvg from '../../../assets/images/FormImages/edit.svg';
import data from '../../../mockData/mockData';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import SelectDropdownAction from '../../../components/formBuilder/SelectDropdownAction';
import { Modal } from 'react-bootstrap';
import ChangingProgressProvider from '../../../components/ChangingProgressProvider/ChangingProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import './Facility.css';
import FileUploader from '../../../components/FileUploader';
import { XLSX_FILE_FORMAT } from '../../../constants/Global';

const FacilityManagement = () => {

    const navigate = useNavigate();
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);
    const getPage = localStorage.getItem('getPage');
    const token = localStorage.getItem("access_token");
    const [page, setPage] = useState(1);
    const [facilityListData, setFacilityList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showActive, setShowActive] = useState(getPage == 1 ? false : true);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideChangeStatusFacility, setshowHideChangeStatusFacility] = useState({ show: false, id: '' });
    const [filesArr, setFilesArr] = useState([]);
    const [fileData, setFile] = useState('');
    const [showUpload, setShowUpload] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showFileContainer, setShowFileContainer] = useState(false);
    const [showHideUploadContainer, setShowHideUploadContainer] = useState({ show: false });
    const [currentFile, setCurrentFile] = useState('');
    const [showFilters, setFilters] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [countyData, setCountyData] = useState([]);
    const [countyValue, setCountyValue] = useState('');
    const [countyId, setSelectedCountyId] = useState(0);
    const [sourceValue, setSource] = useState('');

    useEffect(() => {
        getFacilityListing(page, '', 0, '');
        getCountyList();
    }, [showActive])

    setTimeout(() => {
        localStorage.removeItem('getPage');
    }, 1);

    const getFacilityListing = (page, searchValue, countyId, source) => {
        hideElement();
        setLoading(true);
        setSelectedCountyId(countyId);
        setSource(source);
        Get(facility, token, `?page=${page}&is_active=${showActive}&search=${searchValue}&county=${countyId}&source=${source}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setFacilityList(data.data);
                    setCurrentPage(data.pagination_info);
                }
            });
    };

    const getCountyList = () => {
        Get(county, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    setCountyData(data);
                }
            });
    };

    const changeFacilityStatus = () => {
        setSuccessRes(false);
        setLoading(true);
        const body = {
            is_active: true
        }
        if (showActive) {
            Delete2(facility, token, showHideChangeStatusFacility?.id, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusFacility({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data) {
                        getFacilityListing(page, '', countyId, sourceValue);
                        setAlertType('success');
                        setShowSuccessMessage('Facility Deactivated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        } else {
            Put(`${facility}/${showHideChangeStatusFacility.id}/`, token, body, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusFacility({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data) {
                        getFacilityListing(page, '', countyId, sourceValue);
                        setAlertType('success');
                        setShowSuccessMessage('Facility Activated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        }
    }

    const UploadContainer = (props) => {
        return (
            <>
                <div className={`row boxWidth ml-20 mt-30 ${props.type == 'imageProcessing' ? 'percentProcess' : props.type == 'uploadedImage' ? 'imageUpload' : ''}`}>
                    <div className={`col-md-2 ${props.type == 'imageProcessing' ? 'bgPercentProcessColor' : props.type == 'uploadedImage' ? 'bgUploadCompleted' : ''}`}>
                        <div>{props.children}</div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-11">
                                <div className="imageName">{currentFile && currentFile.name}</div>
                                <div className="imageSize">{convertBytesToKb(currentFile.size)} KB</div>
                            </div>
                            <div className="col-md-1 flex contentCenter cursor" onClick={() => { setShowUpload(true); setFile(''); setCurrentFile(''); }}>
                                <img src={props.type == 'imageProcessing' ? CrossImg : DeleteImg} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const onFileChange = (files) => {
        setShowFileContainer(false);
        setCurrentFile(files[0]);
        setFile(files[0]);
        setFilesArr(files[0]);
        setShowUpload(false);
        setShowProcessing(true);
        setTimeout(() => {
            setShowProcessing(false);
            setShowFileContainer(true);
        }, 5000)
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const handleFileUpload = () => {
        const selectedFile = fileData;
        if (selectedFile) {
            const fileName = selectedFile.name;
            const fileType = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        if (fileType !== XLSX_FILE_FORMAT) {
            setSuccessRes(true);
            setAlertType('failed');
            setShowSuccessMessage('Invalid file type. Please select a .xlsx file.');
            setLoading(false);
            return;
        }
        if (fileType === XLSX_FILE_FORMAT) {
        setLoading(true);
        const formData = new FormData();
        formData.append(`file`, fileData);
        ImagePost(facilityUpload, token, formData, false)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setShowSuccessMessage(res.detail);
                    setAlertType('success');
                    setLoading(false);
                    setSuccessRes(true);
                    setShowHideUploadContainer({ show: false });
                }
                if (!res.success) {
                    setShowSuccessMessage(res.detail);
                    setAlertType('failed');
                    setLoading(false);
                    setSuccessRes(true);
                }
            })
            .catch((error) => {

            });
        }
    }
    }

    const sampleFormat = () => {
        window.open(`${process.env.REACT_APP_SAMPLE_FORMAL_URL}sample_format/FacilitySample.xlsx`, '_self')
    }

    const addFacility = () => {
        navigate('/add-facility');
    }

    const pageChange = (page) => {
        getFacilityListing(page + 1, searchValue, countyId, sourceValue);
    }

    const onViewCompanyDetails = (id) => {
        navigate('/view-facility', { state: { id: id, is_active: showActive } });
    }

    const onUpdateFacility = (id) => {
        navigate('/update-facility', { state: { id: id, is_active: showActive } });
    }

    const clearFilters = () => {
        setCountyValue('');
        setSelectedCountyId(0);
        setSource('');
        getFacilityListing(page, '', 0, '');
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const downloadAsExcelSheet = () => {
        setLoading(true);
        Get(facility, token, `?page=${page}&is_active=${showActive}&search=${searchValue}&county=${countyId}&source=${sourceValue}&export=${true}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType('success');
                    setSuccessRes(true);
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType('failed');
                    setSuccessRes(true);
                }
            });
    };

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={5000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-6">
                        <div className="head_2">Facility Management</div>
                    </div>
                    <div className="col-md-6 text-right">
                        <button className='btn-cancel border-0' onClick={sampleFormat}>Sample Format</button>
                        <button className='btn-add-data mr-15 mt-0' onClick={() => { setShowHideUploadContainer({ show: true }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); }}>Bulk Upload</button>
                        <button className='onboard_btn set-onbBtn-wd border-0' onClick={addFacility}>Add Facility</button>
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-10">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active Facilities</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated Facilities</p>

                        </div>
                    </div>

                    <div className="col-md-2 text-right">
                        <button className='export_btn' onClick={downloadAsExcelSheet}>
                            <img src={EditSvg} alt='editImg' /> Export
                        </button>
                    </div>
                </div>

                <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${countyValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                County
                                <img className="ml-8" src={countyValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu overflw-county" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                                {countyData?.map((county, i) => (
                                    <a className="dropdown-item cursor" onClick={(e) => { getFacilityListing(page, searchValue, county?.id, sourceValue); setCountyValue(county?.name) }}>
                                        <div className="">
                                            {countyValue == county?.name ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${countyValue == county?.name ? 'selected-filter-color ml-8' : ''}`}>{county?.name}</label>
                                        </div>
                                    </a>
                                ))}
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getFacilityListing(page, searchValue, 0, sourceValue); setCountyValue('') }}>Clear</button>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${sourceValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Source
                                <img className="ml-8" src={sourceValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden2' aria-labelledby="dropdownMenuButton2">
                                <a className="dropdown-item cursor" onClick={(e) => { getFacilityListing(page, searchValue, countyId, 'M') }}>
                                    <div className="">
                                        {sourceValue == 'M' ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${sourceValue == 'M' ? 'selected-filter-color ml-8' : ''}`}>Manual</label>
                                    </div>
                                </a>
                                <a className="dropdown-item cursor" onClick={(e) => { getFacilityListing(page, searchValue, countyId, 'S') }}>
                                    <div className="">
                                        {sourceValue == 'S' ? <img src={FilterTickIcon} /> : ''}
                                        <label className={` cursor ${sourceValue == 'S' ? 'selected-filter-color ml-8' : ''}`}>Scrapped</label>
                                    </div>
                                </a>
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getFacilityListing(page, searchValue, countyId, '') }}>Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by typing Facility Name, Facility Id, Location Id" onChange={(event) => { getFacilityListing(page, event.target.value, countyId, sourceValue); setSearchValue(event.target.value) }} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); getFacilityListing(page, '', countyId, sourceValue); setSearchValue(''); }} />
                        </div>}

                </div>

                <>
                    {/* {facilityListData?.length > 0 ? */}
                    <><div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Facility ID</th>
                                    <th scope="col">Facility Name</th>
                                    <th scope="col">County</th>
                                    <th scope="col">Location ID</th>
                                    <th scope="col">{showActive ? 'Added On' : 'Deactivated On'}</th>
                                    <th scope="col">Source</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {facilityListData?.length > 0 && facilityListData.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="brdrtop">
                                                <td>{item?.facility_id ? item?.facility_id : 'NA'}</td>
                                                <td>{item?.facility_name ? item?.facility_name : 'NA'}</td>
                                                <td>{item?.county?.name ? item?.county?.name : 'NA'}</td>
                                                <td>{item?.location_id ? item?.location_id : 'NA'}</td>
                                                <td>{showActive ? item?.created_on ? moment(item?.created_on).format('MM-DD-YYYY') : 'NA' : item?.deleted_date ? moment(item?.deleted_date).format('MM-DD-YYYY') : 'NA'}</td>
                                                <td>{item?.source ? item?.source : 'NA'}</td>
                                                <td>
                                                    {showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.CompanyDeactiveActionItems} onDeactivate={() => setshowHideChangeStatusFacility({ show: true, id: item.id })} onView={() => onViewCompanyDetails(item.id)} onUpdate={() => onUpdateFacility(item?.id)} isImg={true} />}
                                                    {!showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.CompanyActiveActionItems} onActivate={() => setshowHideChangeStatusFacility({ show: true, id: item.id })} onView={() => onViewCompanyDetails(item.id)} onUpdate={() => onUpdateFacility(item?.id)} isImg={true} />}

                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}

                            </tbody>
                        </table>
                        {facilityListData?.length == 0 && <div className="row">
                            <div className="col-md-12">
                                <div className="text-center no-data">No Data Found</div>
                            </div>
                        </div>}
                    </div>
                        {facilityListData?.length > 0 && <ReactPaginate
                            forcePage={currentPage.current_page - 1}
                            pageCount={currentPage.total_count / 5}
                            marginPagesDisplayed={2}
                            onPageChange={(e) => pageChange(e.selected)}
                            containerClassName={'pagination-body'}
                            previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
                            nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
                            previousLinkClassName={'page'}
                            breakClassName={'page'}
                            nextLinkClassName={'page'}
                            pageClassName={'page'}
                            disabledClassName={'disabled'}
                            activeClassName={'active'} />}
                    </>
                </>

            </div>

            <Modal show={showHideChangeStatusFacility.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusFacility({ show: false, id: '' })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} this Facility?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusFacility({ show: false, id: '' })}>No</button>
                        <button type="button" className="btn-yes" onClick={changeFacilityStatus}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <Modal show={showHideUploadContainer.show} dialogClassName="uploadModal" onHide={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>
                <Modal.Header className="sideOnRight" closeButton>
                    <div className='inputFieldHead'>Upload File</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0">
                {showUpload && <FileUploader onFileUpload={onFileChange} />
                    }
                    {!showUpload && showProcessing && <UploadContainer type='imageProcessing' >
                        <ChangingProgressProvider values={[0, 25, 50, 75, 100]}>
                            {percentage => (
                                <CircularProgressbar value={percentage} text={`${percentage}%`} />
                            )}
                        </ChangingProgressProvider>
                    </UploadContainer>}

                    {!showUpload && showFileContainer && <UploadContainer type='uploadedImage' >
                        <img src={DownloadCompleteImg} />
                    </UploadContainer>}
                    <div className="modal-footer">
                        <button type="button" className="btn-cancel-popup" onClick={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>Cancel</button>
                        <button type="button" className="btn-upload border-0" onClick={handleFileUpload} disabled={fileData == '' || showProcessing}>Upload</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default FacilityManagement