import React from "react";
import './NoDataAdded.css';
import { useNavigate } from "react-router";

const NoDataAdded = ({type, mainText, subText}) => {
    const navigate = useNavigate();

    const handleCreate = () => {
        if (type == 'users') {
        navigate('/add-users');
        } else {
        navigate('/add-company-role');
        }
    }

    return (
        <div className="container pt-5 pb-5 mt-4">
            {type=='users' && <p className="banner-heading">You have no {type == 'users' ? 'added users' : 'active roles'}</p>}
            {type == 'users' ?<> 
            <p className="banner-heading">Let’s add some users</p> 
            <p className="banner-sub-heading">In order to add users you need to first create roles</p>
             </>: <></>
            }
            {type == 'role' ? <><p className="banner-heading">{mainText}</p></> :
            <></>}
            {type == 'role' ? <><p className="banner-sub-heading">{subText}</p></> :
            <></>}
            <div className="btn-group">
                <ul className="li-item">
                    <li className="pb-2">
                        <button className='btn-proceed-roles' onClick={handleCreate}>{type == 'users' ? 'Add User' : 'Add Roles and Access'}</button>
                    </li>
                    {/* <li>
                        <button className='btn-cancel-skip border-0' >Skip All Steps</button>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default NoDataAdded;