import React from "react";

const Remediation = () => {
    return(
        <div>
            <h2 className="progress-text-container">
                Work in progress for Remediation Module!
            </h2>
        </div>
    )
}

export default Remediation;