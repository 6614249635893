import React from "react";
import { useNavigate } from "react-router-dom";
import "./ModuleInfo.css";

const ModuleInfo = ({moduleName, moduleDesc, reportButton, permissions}) => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');

    const handleClick = () => {
        navigate('/event-list');
    }

    const navigateToManHours = () => {
        navigate('/man-hours-list');
    }
    return(
        <div className="module-info-container-width">
            <div className="module-info-container">
                <div>
                    <h3>
                        {moduleName}
                    </h3>
                    <p>
                        {moduleDesc}
                        <span className="litico-desc">LITICO<sup>TM</sup> Your Partner in Risk Management</span>
                    </p>
                </div>
                {reportButton && <div className="btn-class-man-hours">
                    {(role == 'company_admin') && (moduleName === 'Safety') && <button className='man-hours-btn' onClick={navigateToManHours}>Man Hours</button>}
                    {((moduleName === 'Safety') && (role == 'company_admin') || ((moduleName === 'Safety') && role == 'user' && (permissions?.readAccess || permissions?.writeAccess))) && <button className='report_customize_btn border-0' onClick={handleClick}>Event/ Incident Management</button>}
                </div>}
            </div>
        </div>
    )
}

export default ModuleInfo;