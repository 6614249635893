export const DRAFT_STATUS = 'Draft';
export const COMPLAINT_ID = 'complaint_id';
export const COMPLAINT_TYPE = 'complaint_type';
export const COMPLAINT_NAME = 'complaint_name';
export const COMPLAINT_STATUS = 'complaint_status';
export const PHYSICAL_ADDRESS = 'physical_address';
export const COMPLAINT_RECEIVER = 'complaint_receiver';
export const DATE_OF_COMPLAINT = 'date_of_complaint';
export const COMPLAINT_TYPE_OTHER = 'complaint_type_other';
export const COMPLAINT_SOURCE = 'complaint_source';
export const COMPLAINT_SOURCE_OTHER = 'complaint_source_other';
export const OTHER_SOURCES = 'Other Sources';
export const COMPLAINT_STATUS_CLOSED = 'complaint_status_closed';
export const COMPLAINT_STATUS_OPEN = 'complaint_status_open';
export const DESCRIBE_LOCATION_KEY = 'describe_location_of_concern';
export const DETAILED_DESCRIPTION_KEY= 'detailed_description_of_the_issue';
export const DETAILED_DESCRIPTION_RESOLUTION = 'detailed_description_of_the_proposed_resolution';
