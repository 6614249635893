import { useEffect } from "react";
import { useNavigate } from "react-router";

const ProtectedRoute = (props) => {
    const { Component } = props;
    const moduleName = props.moduleName;
    const navigate = useNavigate();

    const superUserRoutes = ['Subscription', 'Onboarding', 'StateCity',
        'LiticoAdminProfile'];

    const companyAdminRoutes = ['CompanyAdmin', 'CompanyAdminProfile', 'ManHours', 'ContractorManagement', 'FacilityManagement',
        'Safety', 'Air', 'Benchmarking', 'Compliance', 'ESG', 'M&A', 'Esg', 'Overview', 'Permitting', 'Faq', 'Contact', 'Feedback']

    useEffect(() => {
        const role = localStorage.getItem('role');
        var isModulePresent;

        if (role === 'super_admin') {
            isModulePresent = superUserRoutes.includes(moduleName);
            if (!isModulePresent) {
                navigate('/login');
                localStorage.clear();
            }
        }

        if (role === 'company_admin') {
            isModulePresent = companyAdminRoutes.includes(moduleName);
            if (!isModulePresent) {
                navigate('/login');
                localStorage.clear();
            }
        }

        if (role === 'user') {
            let permissions = JSON.parse(localStorage.getItem('permissions'));
            // permissions[1] = {'moduleName' : 'CompanyAdminProfile'}
            let modulesArr = permissions && permissions?.map(item => item.moduleName);
            isModulePresent = modulesArr.includes(moduleName);

            if (!isModulePresent) {
                navigate('/login');
                localStorage.clear();
            }
        }
        if (!role) {
            navigate('/login');
        }
    })

    return (
        <div>
            <Component editMode={props.editMode} />
        </div>
    )
}

export default ProtectedRoute;