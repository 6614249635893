import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/Loader";
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import { Steps } from "antd";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { getComplaintsList } from "../../../../constants/apiRoutes";
import { Get } from "../../../../constants/apiMethods";
import ComplaintDetails from "./ComplaintDetails";
import ComplaintInvestigationDetails from "./InvestigationDetails";
import './index.css';
import { ACCESS_TOKEN, FAILED_STATUS, STEP_ONE_SUBMITTED_STATUS, SUCCESS_STATUS } from '../../../../constants/Global';
import { COMPLAINT_ID } from '../../../../constants/Complaints';

const AddUpdateComplaint = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [resDetail, setResDetail] = useState('');
    const [responseFromDetail, setResponseFromDetails] = useState('');
    const [current, setCurrent] = useState(0);
    const [viewData, setViewData] = useState('');
    const [stepperActivate, setStepperActivate] = useState(false);
    const [initialDetailsFilled, setIntialDetailsFilled] = useState(false);
    const [showHideError, setshowHideError] = useState({ show: false });
    const token = localStorage.getItem(ACCESS_TOKEN);

    useEffect(() => {
        (props.editMode) && getDetailsById();
    }, []);

    const getDetailsById = () => {
        Get(getComplaintsList, token, '?id=' + location.state.id, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                   setViewData(data?.data);
                }
            });
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const steps = [
        { label: 'Complaint Details' },
        { label: 'Investigation Details' },
    ];

    const items = steps.map((item) => ({
        key: item.label,
        title: item.label,
    }));

    const handleSteps = (e) => {
        if (initialDetailsFilled && current === 1) {
            setshowHideError({ show: true });
        } else if (!props.editMode && current === 0 && !initialDetailsFilled) {
            setAlertType(FAILED_STATUS);
            setSuccessRes(true);
            setResDetail('Please fill Complaint Details');
        } else if (props.editMode && current === 0) {
            setAlertType(FAILED_STATUS);
            setSuccessRes(true);
            setResDetail('Please click on the Proceed button below');
        } else if (!props.editMode && initialDetailsFilled) {
            setAlertType(FAILED_STATUS);
            setSuccessRes(true);
            setResDetail('Please click on the Proceed button below');
        }
    }

    const next = (res) => {
        setCurrent(current + 1);
        setIntialDetailsFilled(true);
        setResponseFromDetails(res);
        localStorage.setItem(STEP_ONE_SUBMITTED_STATUS, true);
        localStorage.setItem(COMPLAINT_ID, res?.complaint_id);
        setStepperActivate(true);
        setResDetail(res.detail);
        setAlertType(SUCCESS_STATUS);
        setSuccessRes(true);
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={resDetail} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div> <span className='head_1 cursor' onClick={() => { navigate('/complaints-dashboard') }}>Complaints Dashboard</span> / <span className='head_1 cursor' onClick={() => { navigate('/complaints-management') }}>Complaint Management</span> / {location?.state?.view === 'fromView' && <span className='head_1 cursor' onClick={() => { navigate('/view-direct-complaint', { state: { id: location?.state?.id } }) }}>View Complaint / </span>} <span className='head_3'>{props.editMode ? 'Update Complaint Details' : 'Add Complaint'} </span></div>
                    <div className="head_2">{props.editMode ? `Update ${viewData?.complaint_name ? viewData?.complaint_name : ''}` : 'Add Complaint'} </div>
                </div>

                <Steps current={current} items={items} className='margin-top-36' onChange={() => handleSteps(current)} />

                {current < steps.length - 1 && (
                    <ComplaintDetails
                        editMode={props.editMode ? props.editMode : stepperActivate}
                        isDraftMode={location?.state?.isDraftMode}
                        next={next}
                    />
                )}
                {current === steps.length - 1 && (
                    <ComplaintInvestigationDetails
                        editMode={props.editMode}
                        responseFromDetail={responseFromDetail}
                        isDraftMode={location?.state?.isDraftMode}
                    />
                )}
            </div>

            <Modal show={showHideError.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideError({ show: false, viewData: {} })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        After proceeding to Complaint Details, {props.editMode ? 'updated' : ''} Investigation Details data will be lost. Are you sure you want to continue?
                    </div>
                    <Modal.Footer className='content-center'>
                        <button type="button" className="btn-no" onClick={() => setshowHideError({ show: false })}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setCurrent(current - 1); setshowHideError({ show: false }) }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AddUpdateComplaint;