import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DropCollapseDown from '../../assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../assets/images/DropCollapseDownSelected.svg';
import FilterTickIcon from '../../assets/images/FilterTickIcon.svg';
import complianceCommonMockData from '../../mockData/Compliance/index';
import './index.css';
import AlertNotifier from "../Notifications/AlertNotifier";

const ComplianceSubModuleDropdown = () => {
    const capitalizeFirstLetter = (moduleName) => {
        return moduleName.charAt(0).toUpperCase() + moduleName.slice(1);
    }

    const location = useLocation();
    const navigate = useNavigate();
    const [successDetail, setSuccessDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [complianceSubModule, setComplianceSubModule] = useState('');
    const [subModulePath, setSubModulePath] = useState(location.pathname.split('/')[1]);
    const [subModuleName, setSubModuleName] = useState(capitalizeFirstLetter(subModulePath.split('-')[0]));

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const handleSubModuleSwitch = (element) => {
        switch (element.name) {
            case 'Spills':
                localStorage.setItem('selectedSubModule', 'Spills');
                navigate('/spills-dashboard');
                break;
            case 'Remediation':
                setSuccessDetail('Remediation is under progress');
                setAlertType('success');
                setSuccessRes(true);
                break;
            case 'Complaints':
                localStorage.setItem('selectedSubModule', 'Complaints');
                navigate('/complaints-dashboard');
                break;
            case 'Production':
                setSuccessDetail('Production is under progress');
                setAlertType('success');
                setSuccessRes(true);
                break;
            case 'Inspection':
                setSuccessDetail('Inspection is under progress');
                setAlertType('success');
                setSuccessRes(true);
                break;
            case 'Wildlife':
                setSuccessDetail('Wildlife is under progress');
                setAlertType('success');
                setSuccessRes(true);
                break;
            default:
                break;
        }
    }

    return (
        <div className="sub-module-dropdown-class">
            {successRes ? <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} /> : ''}
            <button className={`btn dropdown-toggle selectedSubModule bg-gray`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {capitalizeFirstLetter(subModuleName)}
                <img className="ml-8" src={complianceSubModule ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
            </button>
            <div className="dropdown-menu bg-gray" id='dropdownHidden4' aria-labelledby="dropdownMenuButton1">
                {complianceCommonMockData.map((element, index) => (
                    <a className="dropdown-item cursor" key={index} onClick={() => handleSubModuleSwitch(element)}>
                        <div>
                            {subModuleName === element.name ? <img src={FilterTickIcon} /> : ''}
                            <label className={`cursor ${subModuleName === element.name ? 'selected-filter-color ml-8' : ''}`}>
                                {element.name}
                            </label>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default ComplianceSubModuleDropdown;