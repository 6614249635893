import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import infoIcon from '../../../../assets/images/Info.svg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Post, Get } from "../../../../constants/apiMethods";
import {
    complaintsDashboardCount, customizeGraphToggle, numberOfComplaintsGraphAPI
} from "../../../../constants/apiRoutes";
import CheckboxSelect from "../../../../components/formBuilder/CheckboxSelect";
import TabContainerMenu from "../../../../components/Miscellaneous/TabContainerModal";
import DashboardCount from "../../../Safety/Dashboard/DashboardCount/DashboardCount";
import ModuleInfo from "../../../../components/ModuleInfo/ModuleInfo";
import data from '../../../../mockData/mockData';
import complaintsData from '../../../../mockData/complaintsMockData/index';
import CustomizeButtonIcon from '../../../../assets/images/CustomizeButtonIcon.svg';
import ExportButtonIcon from '../../../../assets/images/ExportButtonIcon.svg';
import DropDownData from '../../../../mockData/DropdownData';
import "./index.css";
import Drag from '../../../DragNDrop/Drag';
import LoadingSpinner from "../../../../components/Loader";
import html2canvas from 'html2canvas';
import colorPalleteData from '../../../../constants/themePallete';

import DropCollapseDown from '../../../../assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../../../assets/images/DropCollapseDownSelected.svg';
import FilterTickIcon from '../../../../assets/images/FilterTickIcon.svg';
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import { useNavigate } from 'react-router';
import ReactGA from "react-ga4";
import ComplianceSubModuleDropdown from "../../../../components/ComplianceSubModuleDropdown";
import ComplaintsDataModal from "../ComplaintsDataModal";

const Dashboard = () => {
    const role = localStorage.getItem('role');
    let permissionsObj;
    const indicatorsData = [];
    const chartsData = JSON.parse(localStorage.getItem('graphCustomization'));
    const complaintsChartData = chartsData?.data?.complaintsChartData ? chartsData?.data?.complaintsChartData : complaintsData?.complaintGraphs;

    if (role === 'user') {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        let safetyPermissionObject = permissions.filter(item => item.moduleName.toLowerCase() === 'compliance');

        permissionsObj = {
            dashboardAccess: safetyPermissionObject[0].dashboardChecked,
            exportAccess: safetyPermissionObject[0].exportAccess,
            readAccess: safetyPermissionObject[0].readAccess,
            writeAccess: safetyPermissionObject[0].writeAccess
        }
    }

    const [show, setShow] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const [yearsData, setYearsData] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const token = localStorage.getItem('access_token');
    const [finalArr, setFinalArr] = useState([]);
    const [dashboardCountObj, setDashboardCountObj] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [handleLocalCutomizationPopUp, setLocalCustomizationPopUp] = useState(false);
    const [globalModal, setGlobalModal] = useState(false);
    const [leadShow, setLeadShow] = useState(false);
    const target = useRef(null);

    const [numberOfComplaintsFilteredArrayBars, setNumberOfComplaintsFilteredArrayBars] = useState([]);

    const [numberOfComplaintsGraphData, setNumberOfComplaintsGraphData] = useState([]);

    const [numberOfComplaintsSelectedIds, setNumberOfComplaintsSelectedIds] = useState('');

    const [selectedGraphTypeFilter, setSelectedGraphTypeFilter] = useState('');

    const [selectedLaggingGlobalFilters, setSelectedLaggingGlobalFilters] = useState([]);

    const [complaintCharts, setComplaintCharts] = useState(complaintsChartData);

    const [numberOfComplaintsDataset, setNumberOfComplaintsDataset] = useState([]);

    const [numberOfComplaintsNearestFifty, setNumberOfComplaintsNearestFifty] = useState(50);

    const [globalFilterDuration, setGlobalFilterDuration] = useState('yearly');
    const [globalFilterApplied, setGlobalFilterApplied] = useState(false);

    const [initialLaggingIndicator, setInitialLaggingIndicator] = useState([]);
    const [initialGlobalLaggingFilter, setInitialGlobalLaggingFilter] = useState([]);

    const [successRes, setSuccessRes] = useState(false);
    const [resDetail, setResDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [indicators, setIndicators] = useState([]);

    //chart type dropdown metadata
    const [numberOfComplaintsChartType, setNumberOfComplaintsChartType] = useState('bar');
    const [selectedNumberOfComplaintsChart, setSelectedNumberOfComplaintsChart] = useState(3);

    const [numberOfComplaintsContData, setNumberOfComplaintsContData] = useState('');
    const [numberOfComplaintsCombData, setNumberOfComplaintsCombData] = useState('');

    const [barDatasetLimit, setBarDatasetLimit] = useState();

    const [actualNumberOfComplaintsTypeData, setActualNumberOfComplaintsTypeData] = useState();
    const [numberOfComplaintsDatasetForFilter, setNumberOfComplaintsDatasetForFilter] = useState([]);

    const [graphList, setGraphList] = useState(['Number of Complaints']);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const navigate = useNavigate();

    const generateNumberOfComplaintsDatasets = (chartType, label, data, color, dataAxisLabel) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [color],
            borderColor: [color],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: dataAxisLabel,
            pointRadius: 3,
            type: chartType,
        };
        return {
            ...commonProperties
        };
    }

    const calculateCumulativeTrendLine = (graphName, graphData) => {
        if (graphData?.length > 0) {
            let sum = 0;
            return graphData?.map(value => sum += value);
        }
        else return graphData;
    }

    const numberOfComplaintsBarDatasets = [
        generateNumberOfComplaintsDatasets('bar', 'Direct Complaints', numberOfComplaintsContData, colorPalleteData.graphYellowShade, 'left-side'),
        generateNumberOfComplaintsDatasets('bar', 'Agency Complaints', numberOfComplaintsCombData, colorPalleteData.graphPurpleShade, 'left-side'),
    ];

    const numberOfComplaintsLineDatasets = [
        generateNumberOfComplaintsDatasets('line', 'Direct Complaints', calculateCumulativeTrendLine('Agency', numberOfComplaintsContData), colorPalleteData.graphYellowShade, 'right-side'),
        generateNumberOfComplaintsDatasets('line', 'Agency Complaints', calculateCumulativeTrendLine('Direct', numberOfComplaintsCombData), colorPalleteData.graphPurpleShade, 'right-side'),
    ];

    useEffect(() => {
        const combinedNumberOfComplaintsDatasets = [
            ...numberOfComplaintsBarDatasets,
            ...numberOfComplaintsLineDatasets,
        ];
        setNumberOfComplaintsDataset(combinedNumberOfComplaintsDatasets);
        setActualNumberOfComplaintsTypeData(combinedNumberOfComplaintsDatasets);
        setNumberOfComplaintsFilteredArrayBars(combinedNumberOfComplaintsDatasets);
        setNumberOfComplaintsDatasetForFilter(combinedNumberOfComplaintsDatasets);
    }, [numberOfComplaintsContData, numberOfComplaintsCombData]);

    let numberOfComplaintsDatasetArr = numberOfComplaintsFilteredArrayBars;

    useEffect(() => {
        ReactGA.event({
            category: 'Safety PageView',
            action: 'Page Loaded',
            label: 'SafetyPage - ' + localStorage.getItem('name'),
        });
        setIndicators(indicatorsData);
    }, []);

    useEffect(() => {
        getDashboardCount('yearly');
        getInjuryGraphData('Number of Complaints', 'yearly');
        getSelectedLaggingDataCharts();
    }, []);

    useEffect(() => {

        if (selectedGraphTypeFilter === 'Number of Complaints') {

            if (numberOfComplaintsSelectedIds.length === 1 && numberOfComplaintsSelectedIds[0] === 10) {
                numberOfComplaintsDatasetArr = numberOfComplaintsDatasetForFilter.filter(item => item.label !== 'Agency Complaints');
            }

            if (numberOfComplaintsSelectedIds.length === 1 && numberOfComplaintsSelectedIds[0] === 11) {
                numberOfComplaintsDatasetArr = numberOfComplaintsDatasetForFilter.filter(item => item.label !== 'Direct Complaints');
            }

            if (numberOfComplaintsSelectedIds.length === 2 || numberOfComplaintsSelectedIds.length === 0) {
                numberOfComplaintsDatasetArr = numberOfComplaintsDatasetForFilter;
            }
            setNumberOfComplaintsDataset(numberOfComplaintsDatasetArr);
            setActualNumberOfComplaintsTypeData(numberOfComplaintsDatasetArr);
            setSelectedNumberOfComplaintsChart(3);
        }

    }, [numberOfComplaintsGraphData, numberOfComplaintsChartType]);

    const onFilter = (categoryValue) => {
        setGlobalFilterDuration(categoryValue);
        categoryValue = categoryValue || 'yearly';
        hideElement();
        if (categoryValue) {
            getDashboardCount(categoryValue);
            getInjuryGraphData('Number of Complaints', categoryValue);
            getSelectedLaggingDataCharts();
        }
        else {
            hideElement();
        }
    };

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
    }

    useLayoutEffect(() => {

        if (selectedGraphTypeFilter === 'Number of Complaints') {
            let filteredArray = [...numberOfComplaintsGraphData];
            setNumberOfComplaintsGraphData(filteredArray);
        }

    }, [numberOfComplaintsSelectedIds]);

    const transformDashCount = (res) => {
        const transformData = res?.length > 0 && res?.map((item, index) => {
            const icon = complaintsData?.complaintsDashboardCount?.find((dItem) => dItem.name === item.type)
            return {
                ...item,
                icon: icon.icon
            }
        })
        setFinalArr(transformData);
    }

    const getSelectedEmployeeIds = (data, name) => {
        setSelectedGraphTypeFilter(name);
        if (name === 'Number of Complaints') {
            setNumberOfComplaintsSelectedIds(data);
        }
    }

    const getDashboardCount = (filterDuration) => {
        Get(complaintsDashboardCount+`?period=${filterDuration}`, token, '', true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setDashboardCountObj(data.result);
                    transformDashCount(data.result);
                    setLoading(false);
                }
            });
    };

    const calculateNearestFifty = (value, key) => {
        const maxCombinedCount = value.reduce((max, item) => {
            return item[key] > max ? item[key] : max;
        }, 0);
        const nearestMultipleOf50 = Math.ceil(maxCombinedCount / 50) * 50;
        return nearestMultipleOf50;
    }

    const calculateSumOfArray = (array) => {
        let sum = 0;

        for (let i = 0; i < array.length; i++) {
            sum += array[i];
        }
        return sum;
    }
    
    function nearestFiftyCeilingValue(graphTotal) {
        const nearestMultiple = Math.ceil(graphTotal / 20);
        const nearestValue = nearestMultiple * 20;
        return nearestValue;
    }

    const getInjuryGraphData = (graphType, filterDuration) => {
        Get(numberOfComplaintsGraphAPI+`?period=${filterDuration}`, token, ``, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    if (filterDuration === 'quarterly') {
                        data.data = data.data?.map(item => {
                            return {
                                ...item,
                                "year": `Q${item.quarter}, ${item.year}`,
                                "selectYear": `${item.year}`,
                            };
                        });
                    }
                    if (filterDuration === 'monthly') {
                        data.data = data.data?.map(item => {
                            const abbreviatedMonth = monthNames[parseInt(item.month) - 1];
                            return {
                                ...item,
                                "year": `${abbreviatedMonth}, ${item.year}`,
                                "selectYear": `${item.year}`,
                            };
                        });
                    }


                    setNumberOfComplaintsGraphData(data.data);
                    let numberOfComplaintsContData = data.data?.map(ele => { return ele.Direct });
                    let numberOfComplaintsCombData = data.data?.map(ele => { return ele.Agency });
                    setNumberOfComplaintsContData(numberOfComplaintsContData);
                    setNumberOfComplaintsCombData(numberOfComplaintsCombData);
                    setLoading(false);
                    let agencySum = calculateSumOfArray(numberOfComplaintsContData);
                    let directSum = calculateSumOfArray(numberOfComplaintsCombData);
                    const maxDataset = Math.max(agencySum, directSum);
                    const barLimit = nearestFiftyCeilingValue(maxDataset);
                    setBarDatasetLimit(barLimit);
                }
            });
    }


    const capitalizeFirstLetter = (str) => {
        return str.replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const NumberOfComplaintsChart = {
        labels: numberOfComplaintsGraphData && numberOfComplaintsGraphData?.map(item => item.year),
        datasets: numberOfComplaintsDataset,
    }

    const onSelect = (selectedList, selectedItem) => {
        // to be used in the future for the API Integration
    }

    const onRemove = (selectedList, removedItem) => {
        // to be used in the future for the API Integration
    }

    const handleExport = async () => {
        const body = document.body;
        const html = document.documentElement;

        const windowWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
        const windowHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        await html2canvas(document.body, { width: windowWidth, height: windowHeight }).then((canvas) => {
            // Convert the canvas to a data URL
            const screenshotData = canvas.toDataURL('image/png');

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = screenshotData;
            downloadLink.download = 'Screenshot.png';

            // Trigger the download
            downloadLink.click();
        });
        setLoading(false);
    }

    const handleDropdownSelect = (e) => {
        // TODO document why this arrow function is empty
    }

    const getSelectedLaggingDataCharts = () => {
        const selectedIndicators = complaintCharts?.filter(indicator => indicator.checked).map(indicator => indicator.id)
        setSelectedLaggingGlobalFilters(selectedIndicators);
        setGraphList(selectedIndicators);
    }

    const handleGlobalShow = () => {
        setInitialLaggingIndicator(complaintCharts);
        setInitialGlobalLaggingFilter(selectedLaggingGlobalFilters);
        setGlobalModal(!globalModal);
    }

    const handleGlobalFilterClose = () => {
        if (!globalFilterApplied) {
            setTimeout(() => {
                setComplaintCharts(initialLaggingIndicator);
                setSelectedLaggingGlobalFilters(initialGlobalLaggingFilter);
            }, 500)
        }
    }

    const selectAllGlobalFilters = () => {
        setGlobalModal(true);

        let selectedComplaintCharts = complaintCharts.map((item, index) => {
            return item.id
        })
        let allCheckedComplaintCharts = complaintCharts.map((item, index) => {
            return { ...item, checked: true }
        })

        setComplaintCharts(allCheckedComplaintCharts);
        setSelectedLaggingGlobalFilters(selectedComplaintCharts);
        setGlobalFilterApplied(false);
    }

    const deselectAllGlobalFilters = () => {
        setGlobalModal(true);

        let selectedComplaintCharts = complaintCharts.map((item, index) => {
            return ''
        })
        let allCheckedComplaintCharts = complaintCharts.map((item, index) => {
            return { ...item, checked: false }
        })

        setComplaintCharts(allCheckedComplaintCharts);
        setSelectedLaggingGlobalFilters(selectedComplaintCharts);
        setGlobalFilterApplied(false);
    }

    const applyGlobalCustomization = () => {
        setGraphList(selectedLaggingGlobalFilters);
        setGlobalFilterApplied(true);
        setResDetail('Filter applied successfully!');
        setAlertType('success');
        setSuccessRes(true);
        setGlobalModal(false);
        let graphCustomizationData = JSON.parse(localStorage.getItem('graphCustomization'))
        graphCustomizationData.data.complaintsChartData = complaintCharts;
        Post(customizeGraphToggle, token, graphCustomizationData, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    localStorage.setItem('graphCustomization', JSON.stringify(data.data));
                }
            });
    }


    const onLaggingDataChange = (e) => {
        setGlobalModal(true);
        let itemArr = complaintCharts && complaintCharts.map((ele, index) => {
            if (ele.id === e.id) {
                return { ...ele, checked: !ele.checked }; // Toggle the checked property
            }
            return ele;
        });
        setComplaintCharts(itemArr);
        const selectedIds = itemArr.filter(indicator => indicator.checked).map(indicator => indicator.id);
        setSelectedLaggingGlobalFilters(selectedIds);
        setGlobalFilterApplied(false);
    }

    const handleLocalCustomization = (e) => {
        // to be used in the future for the API Integration
    }

    const handleLocalResetCustomization = (e) => {
        // to be used in the future for the API Integration
    }

    const onBarClick = (graphName, year, dataIndex) => {
        if (graphName === 'Number of Complaints') {
            if (numberOfComplaintsSelectedIds[0] === 10 )
            {
                setModalHeading('Direct Complaint');
            }
            if (numberOfComplaintsSelectedIds[0] === 11) {
                setModalHeading('Agency Complaint');
            }
            else {
                setModalHeading(dataIndex ? 'Agency Complaint' : 'Direct Complaint');
            }
            
        }
        setShow(!show);
        setSelectedYear(year);
    }

    const NumberOfComplaintsOptions = (leftAxisRange, rightAxisRange) => {

        const stepsDistance = leftAxisRange/5;

        return {
            responsive: true,
            onClick: (_, elements, arg) => {
                if (elements.length > 0) {
                    const clickedBarIndex = elements[0]?.datasetIndex;
                    const barIndex = elements[0]?.index;
                    const yearSelected = globalFilterDuration === 'yearly' ? numberOfComplaintsGraphData[barIndex]?.year : numberOfComplaintsGraphData[barIndex]?.selectYear;
                    onBarClick('Number of Complaints', yearSelected, clickedBarIndex);
                }
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    onClick: (click, legendItem, legend) => {
                        const datasets = legend.legendItems.map((dataset, index) => {
                            return dataset.text;
                        })

                        const index = datasets.indexOf(legendItem.text);
                        if (legend.chart.isDatasetVisible(index) === true) {
                            legend.chart.hide(index);
                        } else {
                            legend.chart.show(index);
                        }
                    },
                    labels: {
                        usePointStyle: true,
                        font: {
                            family: 'Montserrat',
                        },
                        generateLabels: (chart) => {
                            let visibility = [];
                            for (let i = 0; i < chart.data.datasets.length; i++) {
                                if (chart.isDatasetVisible(i) === true) {
                                    visibility.push(false);
                                }
                                else {
                                    visibility.push(true);
                                }
                            }
                            let pointStyle = [];
                            chart.data.datasets.forEach(dataset => {
                                if (dataset.type === 'line') {
                                    pointStyle.push('line');
                                }
                                else {
                                    pointStyle.push('rect')
                                }
                            })

                            return chart.data.datasets.map((dataset, index) => ({
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor,
                                strokeStyle: dataset.borderColor,
                                pointStyle: pointStyle[index],
                                hidden: visibility[index]
                            })
                            )
                        }
                    }
                },
                title: {
                    display: false,
                    text: 'Number of complaints'
                }
            },
            scales: {
                x: {
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    ticks: { display: true },
                },
                'left-side': {
                    type: 'linear', 
                    position: 'left',
                    min: 0,
                    beginAtZero: true,
                    max: leftAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                    },
                    title: {
                        display: true,
                        text: 'Total complaints count',
                        font: {
                            family: 'Montserrat',
                        }
                    },
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    // ticks: { display: true },
                },
                'right-side': {
                    type: 'linear',
                    position: 'right',
                    min: 0,
                    max: rightAxisRange,
                    beginAtZero: true,
                    ticks: {
                        stepSize: stepsDistance,
                    },
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Cumulative complaints Count',
                        font: {
                            family: 'Montserrat',
                        }
                    }
                }
            },

            aspectRatio: 3,
        }
    }

    const updateChartType = (graphName, type) => {
        switch (graphName) {
            case 'Number of Complaints':
                let numberOfComplaintsDatasetArray = actualNumberOfComplaintsTypeData;
                if (type === 1) {
                    numberOfComplaintsDatasetArray = numberOfComplaintsDatasetArray.filter(item => item.type === 'line');
                    setNumberOfComplaintsDataset(numberOfComplaintsDatasetArray);
                }
                if (type === 2) {
                    numberOfComplaintsDatasetArray = numberOfComplaintsDatasetArray.filter(item => item.type === 'bar');
                    setNumberOfComplaintsDataset(numberOfComplaintsDatasetArray);
                }
                if (type === 3) {
                    setNumberOfComplaintsDataset(numberOfComplaintsDatasetArray);
                }
                setSelectedNumberOfComplaintsChart(type);
                break;

            default:
                break;
        }
    };

    const onFilterChartType = (type, graphName) => {
        updateChartType(graphName, type);
    };

    const dummyGraphArr = [
        {
            id: 'Number of Complaints',
            name: 'Number of Complaints',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: true,
            numberOfComplaintsTypeData: complaintsData.numberOfComplaintsFilterData,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            chartData: NumberOfComplaintsChart,
            // chartOptions: complaintsChartData.RecordableInjuryOptions(recordableInjuryNearestFifty, 500),
            chartOptions: NumberOfComplaintsOptions(barDatasetLimit, barDatasetLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedNumberOfComplaintsChart,
            showGraph: true
        },
    ];

    const graphArr = dummyGraphArr;

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <div className="full-cont">
            <div className="background-F8F8FA">
                {loading && <LoadingSpinner />}
                {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={resDetail} setVisibility={setVisibility} />}
                <div>
                    <ModuleInfo moduleName={'Operational Compliance'} moduleDesc={''} reportButton={true} permissions={permissionsObj} />
                </div>
                <div>
                    <div className="mt-5 px-3">
                        <div className="global-customize-container">
                            <ComplianceSubModuleDropdown />
                            <div>
                                <Button size="sm" className="btn-light margin-right-15 bg-white" onClick={handleGlobalShow}><img src={CustomizeButtonIcon} className="mx-1 my-1" />Customize</Button>
                                <Button size="sm" className="btn-light margin-right-15 bg-white" onClick={handleExport}><img src={ExportButtonIcon} className="mx-1 my-1" />Export</Button>
                                <div className="dropdown">
                                    <button className={`btn dropdown-toggle ${globalFilterDuration ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Choose Duration
                                        <img className="ml-8" src={globalFilterDuration ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                                    </button>
                                    <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                                        <a className="dropdown-item cursor" onClick={(e) => { onFilter('yearly') }}>
                                            <div className="">
                                                {globalFilterDuration === 'yearly' ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                                <label className={` cursor ${globalFilterDuration === 'yearly' ? 'selected-filter-color ml-8' : ''}`}>Yearly</label>
                                            </div>
                                        </a>
                                        <a className="dropdown-item cursor" onClick={(e) => { onFilter('quarterly') }}>
                                            <div className="">
                                                {globalFilterDuration === 'quarterly' ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                                <label className={` cursor ${globalFilterDuration === 'quarterly' ? 'selected-filter-color ml-8' : ''}`}>Quarterly</label>
                                            </div>
                                        </a>
                                        <a className="dropdown-item cursor" onClick={(e) => { onFilter('monthly') }}>
                                            <div className="">
                                                {globalFilterDuration === 'monthly' ? <img src={FilterTickIcon} alt='FilterTickIcon' /> : ''}
                                                <label className={` cursor ${globalFilterDuration === 'monthly' ? 'selected-filter-color ml-8' : ''}`}>Monthly</label>

                                            </div>
                                        </a>
                                        <div className="mt-2 float-right">
                                            <button type="button" className="btn-clear" onClick={(e) => { onFilter('yearly') }}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                                {(role == 'company_admin') || (role == 'user' && (permissionsObj?.readAccess || permissionsObj?.writeAccess)) ?
                                <button type="submit" className='success-btn' onClick={() => navigate('/complaints-management')}>Complaint Management</button> : null}
                            </div>
                        </div>
                    </div>

                    <div className="header-tab-container">
                        <DashboardCount data={finalArr} filterDuration={globalFilterDuration} type={'Complaints'} />
                    </div>

                    {graphList && !graphList.some(item => item.trim()) && <p className="py-4 my-2 heading-font text-center pt-20 pb-20">No Graph Selected</p>}

                    <Drag graphArr={graphArr} graphList={graphList} moduleName={'Operational Compliance'} />

                    <Modal show={show} dialogClassName="listModal" onHide={() => setShow(false)}>
                        <Modal.Header className="sideOnRight" closeButton>
                            <div className="head_2 ml-36">{`Number of ${modalHeading}s`}</div>
                        </Modal.Header>
                        <Modal.Body className="auth_modal_header border-0 mx-auto -mt-20">
                            <div className="modal-body">
                                <ComplaintsDataModal selectedYear={selectedYear} modalHeading={modalHeading} />
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={globalModal} onHide={() => setGlobalModal(!globalModal)}>
                        <Modal.Header closeButton onClick={() => handleGlobalFilterClose()}>
                            <Modal.Title className="pop-up-heading">I want to view below charts on my Dashboard</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pad-bottom-0">
                            <Row className="">
                                <Col sm={2} className="">
                                    <span>List of Charts</span>
                                </Col>

                                <Col sm={10} className="text-right">
                                    <button className="btn-select-all" onClick={() => selectAllGlobalFilters()}>Select All
                                    </button>

                                </Col>

                            </Row>
                            <div className="height-185 mt-30">
                                <Row>
                                    <Col md={12}>
                                        <CheckboxSelect index={2} data={complaintCharts} colSize={6} checkedValues={selectedLaggingGlobalFilters} onChange={onLaggingDataChange} />
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="padding-top-0">
                            <button className="btn-add-data mr-15 mt-4 border-0" onClick={() => deselectAllGlobalFilters()}>Reset
                            </button>
                            <button className='report_btn border-0 mt-4' onClick={applyGlobalCustomization}>Customize
                            </button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={handleLocalCutomizationPopUp} onHide={() => setLocalCustomizationPopUp(!handleLocalCutomizationPopUp)}>
                        <Modal.Header closeButton>
                            <Modal.Title className="pop-up-heading">Compare incident data for</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TabContainerMenu states={data.States}
                                quarters={data.Quarters}
                                months={DropDownData.Months}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                setFromDate={setFromDate}
                                setToDate={setToDate} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleLocalResetCustomization}>
                                Reset
                            </Button>
                            <Button variant="success" onClick={handleLocalCustomization}>
                                Customize
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;