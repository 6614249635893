import React, { useEffect, useMemo, useState } from 'react';
import data from '../../../mockData/mockData';
import SelectDropdownAction from '../../../components/formBuilder/SelectDropdownAction';
import ThreeDotsIcon from '../../../assets/images/FormImages/threeDots.svg';
import Pagination from "../../../components/Pagination/Pagination";
import ViewUpdateIcon from '../../../assets/images/FormImages/view-updateIcon.svg';
import ActivateIcon from '../../../assets/images/activate:deactivate.svg';
import ForwardPagination from '../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../assets/images/BackwardPagination.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import './UserList.css';
import { getRoleList, singleUser, user } from '../../../constants/apiRoutes';
import { Delete2, Get, Put } from '../../../constants/apiMethods';
import LoadingSpinner from '../../../components/Loader';
import moment from 'moment';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { Modal } from 'react-bootstrap';
import NoDataAdded from '../NoDataAdded/NoDataAdded';
import DateRangePicker from '../../../components/formBuilder/DateRangePicker';

const UserList = () => {

    const [showActive, setShowActive] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userListData, setUserList] = useState([]);
    const [showHideChangeStatusUser, setshowHideChangeStatusUser] = useState({ show: false, id: '' });
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const token = localStorage.getItem("access_token");
    const isSidebarShow = localStorage.getItem('isSidebarShow');
    const [showFilters, setFilters] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [rolesValue, setRolesValue] = useState('');
    const [roleId, setSelectedRoleId] = useState(0);
    const [rolesData, setRolesData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [addedDateRangeValue, setAddedDateRangeValue] = useState(['', '']);
    const [addedStartDate, setAddedStartDate] = useState('');
    const [addedEndDate, setAddedEndDate] = useState('');
    const [clearDateValue, setClearDateValue] = useState(true);
    const navigate = useNavigate();
    let PageSize = 10;

    const [actionItem, setActionItem] = useState([
        { name: 'View/Update', label: 'View/Update', icon: <img src={ViewUpdateIcon} /> },
        { name: 'Activate', id: 2, label: 'Activate', icon: <img src={ActivateIcon} /> }
    ]);

    useEffect(() => {
        getUserList(page, searchValue, roleId, ['', '']);
        getRoleListing();
    }, [showActive]);

    const getUserList = (page, searchValue, roleId, addedDateRangeValue) => {
        if (addedDateRangeValue) {
            setAddedDateRangeValue(addedDateRangeValue);
            setAddedStartDate(addedDateRangeValue[0]);
            setAddedEndDate(addedDateRangeValue[1]);
        }
        hideElement();
        setLoading(true);
        Get(user, token, `?page=${page}&is_active=${showActive}&search=${searchValue}&role_id=${roleId}&added_on_start=${addedDateRangeValue[0]}&added_on_end=${addedDateRangeValue[1]}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    const listData = data?.data?.data?.map((resp) => {
                        return { ...resp, stateArrayValue: 3 }
                    })
                    setUserList(listData);
                    setCurrentPage(data.data.pagination_info);
                }
            });
    };

    const getRoleListing = () => {
        Get(getRoleList, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setRolesData(data?.data?.data);
                }
            });
    };

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const pageChange = (page) => {
        setPage(page + 1);
        getUserList(page + 1, searchValue, roleId, addedDateRangeValue);
    }

    const navigateToAddUser = () => {
        navigate('../add-users');
    }

    const onUpdate = (item) => {
        navigate('/update-users', { state: { id: item.id, is_active: showActive } });
    }

    const changeUserStatus = () => {
        setSuccessRes(false);
        setLoading(true);
        const body = {
            is_active: true
        }
        if (showActive) {
            Delete2(singleUser, token, showHideChangeStatusUser?.id, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusUser({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data.success) {
                        getUserList(page, searchValue, roleId, addedDateRangeValue);
                        setAlertType('success');
                        setShowSuccessMessage(data.detail);
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        } else {
            Put(`${singleUser}/${showHideChangeStatusUser.id}/`, token, body, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusUser({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data.success) {
                        getUserList(page, searchValue, roleId, addedDateRangeValue);
                        setAlertType('success');
                        setShowSuccessMessage('User Activated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        }
    }

    const clearFilters = () => {
        setRolesValue('');
        setSelectedRoleId(0);
        setAddedDateRangeValue('');
        setAddedStartDate('');
        setAddedEndDate('');
        setIsOpen(false);
        setClearDateValue(false);
        setTimeout(() => {
            setClearDateValue(true);
        }, 1)
        getUserList(page, '', 0, ['', '']);
    }

    const showMoreStates = (item) => {
        const newState = userListData.map(obj => {
            if (obj.id === item.id) {
                return { ...obj, stateArrayValue: obj.locations.length };
            }
            return obj;
        });
        setUserList(newState);
    }

    const showLessStates = (item) => {
        const newState = userListData.map(obj => {
            if (obj.id === item.id) {
                return { ...obj, stateArrayValue: 3 };
            }
            return obj;
        });
        setUserList(newState);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className={`mt-10 ${isSidebarShow == 'true' ? 'full-cont' : ''}`}>

            {isSidebarShow && <div className="row">
                    <div className="col-md-9">
                        <span className="head_2">User Details</span>
                    </div>
                    <div className="col-md-3 text-right">
                        <button type="button" className="btn-event-incident in-blck" onClick={navigateToAddUser}>Add Users</button>
                    </div>
                </div>}

                <div className="row inside_head">
                    <div className="col-md-8">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active Users</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated Users</p>

                        </div>
                    </div>
                    {!isSidebarShow && <div className="col-md-4 text-right">
                        <button type="button" className="btn-add-user" onClick={navigateToAddUser}>Add Users</button>
                    </div>}
                </div>

                
                {isSidebarShow && <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${rolesValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Role
                                <img className="ml-8" src={rolesValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu overflw-county" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                                {rolesData?.map((role, i) => (
                                    <a className="dropdown-item cursor" onClick={(e) => { getUserList(page, searchValue, role?.id, addedDateRangeValue); setRolesValue(role?.name) }}>
                                        <div className="">
                                            {rolesValue == role?.name ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${rolesValue == role?.name ? 'selected-filter-color ml-8' : ''}`}>{role?.name}</label>
                                        </div>
                                    </a>
                                ))}
                                <div className="mt-2 float-right">
                                    <button type="button" className="btn-clear" onClick={(e) => { getUserList(page, searchValue, 0, addedDateRangeValue); setRolesValue('') }}>Clear</button>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown-container">
                            <button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${addedStartDate && addedEndDate != '' ? 'selected-filter-color' : ''}`}>
                                {addedStartDate && addedEndDate ? addedStartDate + ' - ' + addedEndDate : 'Add User Date Range'}
                                <img className="ml-8" src={addedStartDate && addedEndDate ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpen && (
                                <ul className="dropdown-list">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getUserList(page, '',  roleId, dateString) }} onClear={() => { setAddedStartDate(''); setAddedEndDate(''); toggleDropdown(); getUserList(page, searchValue, roleId, ['', '']) }} />}
                                </ul>
                            )}
                        </div>

                    </div>

                        <div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by typing User Name, Email Id" onChange={(event) => { getUserList(page, event.target.value, roleId, addedDateRangeValue); setSearchValue(event.target.value) }} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); getUserList(page, '', roleId, addedDateRangeValue); setSearchValue(''); }} />
                        </div>}

                </div>}


                <>
                    {userListData?.length > 0 ? <><div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email Id</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">State</th>
                                    <th scope="col">{showActive ? 'Added on' : 'Deactivated on'}</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {userListData.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="brdrtop">
                                                <td>{item?.name}</td>
                                                <td>{item?.username}</td>
                                                <td>{item?.role?.name ? item?.role?.name : 'NA'}</td>
                                                <td>{item.locations.slice(0, item.stateArrayValue).map((state, locationIndex) => { return <div>{state?.name}</div> })}
                                                    {(item?.locations?.length > item.stateArrayValue) ?
                                                        <div className="link-color" onClick={() => showMoreStates(item)}>+ {item?.locations?.length - item?.stateArrayValue} {item?.locations?.length - item?.stateArrayValue > 1 ? 'States' : 'State'}</div> :
                                                        item?.locations?.length > 3 ? <div className="link-color" onClick={() => showLessStates(item)}>View Less</div> : ''}
                                                </td>
                                                <td>{showActive ? item?.created_on ? moment(item?.created_on).format('MM-DD-YYYY') : 'NA' :
                                                item?.deleted_on ? moment(item?.deleted_on).format('MM-DD-YYYY') : 'NA' }</td>
                                                <td>
                                                    {showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleDeactiveActionItems} onDeactivate={() => setshowHideChangeStatusUser({ show: true, id: item.id })} onUpdate={() => onUpdate(item)} isImg={true} />}
                                                    {!showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleActiveActionItems} onActivate={() => setshowHideChangeStatusUser({ show: true, id: item.id })} onUpdate={() => onUpdate(item)} isImg={true} />}
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                        <ReactPaginate
                            forcePage={currentPage.current_page - 1}
                            pageCount={currentPage.total_count / 5}
                            pageRange={2}
                            marginPagesDisplayed={2}
                            onPageChange={(e) => pageChange(e.selected)}
                            containerClassName={'pagination-body'}
                            previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
                            nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
                            previousLinkClassName={'page'}
                            breakClassName={'page'}
                            nextLinkClassName={'page'}
                            pageClassName={'page'}
                            disabledClassName={'disabled'}
                            activeClassName={'active'}
                        />
                    </> : showActive ? <NoDataAdded type='users' /> : 
                     <div className="container pt-5 pb-5 mt-4">
                    <div className='no-data'>No Data Found</div>
                 </div>
                }
                </>

                <Modal show={showHideChangeStatusUser.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusUser({ show: false, id: '' })}>
                    <Modal.Header className="sideOnRight" closeButton>
                    </Modal.Header>
                    <Modal.Body className="auth_modal_header border-0 mx-auto">
                        <div className="modal-body reportText">
                            Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} this User?
                        </div>
                        <Modal.Footer>
                            <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusUser({ show: false, id: '' })}>No</button>
                            <button type="button" className="btn-yes" onClick={changeUserStatus}>Yes</button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>


            </div>
        </>
    )
}

export default UserList;