import React, { useState, useEffect } from 'react';
import "./ReportEvent.css";
import InvestigationDetails from './InvestigationDetails';
import { useNavigate, useLocation } from 'react-router-dom';
import EventDetails from './EventDetails';
import { useForm } from "react-hook-form";
import { Steps } from 'antd';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { Modal } from "react-bootstrap";

function ReportEvent(props) {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [successRes, setSuccessRes] = useState(false);
    const [resDetail, setResDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [responseFromDetail, setResponseFromDetails] = useState('');
    const [eventObjVal, setEventObjVal] = useState({});
    const [incidentArrData, setIncidentArrData] = useState({});
    const [incidentUpdateId, setIncidentUpdateId] = useState('');
    const [showHideDelete, setshowHideDelete] = useState({ show: false });
    const [initialDetailsFilled, setIntialDetailsFilled] = useState(false);
    const [stepperActivate, setStepperActivate] = useState(false);

    const [incidentObj, setIncidentObj] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleSteps = (e) => {
        
        if (initialDetailsFilled && current == 1) {
            setshowHideDelete({ show: true });
        } else if (!props.editMode && current == 0 && !initialDetailsFilled) {
            setAlertType('failed');
            setSuccessRes(true);
            setResDetail('Please fill Event Details');
        } else if (props.editMode && current == 0) {
            // setCurrent(1);
            setAlertType('failed');
            setSuccessRes(true);
            setResDetail('Please click on the Proceed button below');
        } else if (!props.editMode && initialDetailsFilled) {
            setAlertType('failed');
            setSuccessRes(true);
            setResDetail('Please click on the Proceed button below');
        }
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const steps = [
        { label: 'Event Details' },
        { label: 'Investigation Details' },
    ];

    useEffect(() => {

    }, []
    )

    const next = (res) => {
        setIntialDetailsFilled(true);
        setLoading(true);
        setCurrent(current + 1);
        localStorage.setItem('step_one_submitted', true);
        localStorage.setItem('userId', res.result.id);
        setStepperActivate(true);
        current === 0 ? setResDetail(`Event details are ${props.editMode ? 'updated' : 'added'} successfully`): (current === 1 ? setResDetail(`Investigation details are ${props.editMode ? 'updated' : 'added'} successfully`) : setResDetail(''));
        setAlertType('success');
        setSuccessRes(true);
        setResponseFromDetails(res);
        setLoading(false);
    };

    const items = steps.map((item) => ({
        key: item.label,
        title: item.label,
    }));

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={resDetail} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div> <span className='head_1 cursor' onClick={()=> {navigate('/safety')}}>Safety Dashboard</span> / <span className='head_1 cursor' onClick={()=> {navigate('/event-list')}}>Event and Incident Management</span> / <span className='head_3'>{props.editMode ? 'Update Event' : 'Add Event'} </span></div>
                    <div className="head_2">{props.editMode ? 'Update Event' : 'Add Event'} </div>
                </div>

                <Steps current={current} items={items} className='margin-top-36' onChange={() => handleSteps(current)} />

                {current < steps.length - 1 && (
                    <EventDetails
                        editMode={props.editMode ? props.editMode : stepperActivate}
                        incidentObj={incidentObj}
                        next={next}
                        eventObjVal={eventObjVal}
                        incidentArrData={incidentArrData}
                        incidentUpdateId={incidentUpdateId}
                        />
                )}
                {current === steps.length - 1 && (
                    <InvestigationDetails
                        editMode={props.editMode}
                        responseFromDetail={responseFromDetail}
                        incidentUpdateId={incidentUpdateId}
                        />
                )}

            </div>

            <Modal show={showHideDelete.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideDelete({ show: false, viewData: {} })}>
					<Modal.Header className="sideOnRight" closeButton>
					</Modal.Header>
					<Modal.Body className="auth_modal_header border-0 mx-auto">
						<div className="modal-body reportText">
                        After proceeding to Event Details, {props.editMode ? 'updated' : ''} Investigation Details data will be lost. Are you sure you want to continue?
						</div>
						<Modal.Footer className='content-center'>
							<button type="button" className="btn-no" onClick={() => setshowHideDelete({ show: false})}>No</button>
							<button type="button" className="btn-yes" onClick={() => {setCurrent(current-1); setshowHideDelete({ show: false})} }>Yes</button>
						</Modal.Footer>
					</Modal.Body>
				</Modal>
        </div>
    )
}

export default ReportEvent;
