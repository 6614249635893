import React, { useState } from 'react';
import '../../css/Global.css';
import ModuleInfo from "../../components/ModuleInfo/ModuleInfo";
import overviewChartData from '../../mockData/OverviewChartData';
import BarChart from "../Safety/Dashboard/charts/BarChart";
import './Overview.css';
import { Chart } from "react-google-charts";
import { Modal } from 'antd';
import pattern from 'patternomaly';

const Overview = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [barIndex, setBarIndex] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onBarClick = (event) => {
    setIsModalOpen(!isModalOpen);
    setBarIndex(event.index);
  }

  const modalChart = {
    labels: ['2018', '2019', '2020', '2021', '2022', '2023'],
    datasets: [
      {
        label: 'Compliance complaints',
        data: [18, 12, 8, 9, 12, 3, 9],
        backgroundColor: [
          pattern.draw('diagonal', '#17becf', '#FFFFFF'),
          // 'rgb(0, 0, 0, 1)'
        ],
        borderColor: [
          'rgb(68, 114, 196)'
          // 'rgb(0, 0, 0, 1)'
        ],
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 15,
        barPercentage: 0.5,
      },
      {
        label: 'Compliance background',
        data: [20, 7, 5, 3, 6, 5, 6],
        backgroundColor: [
          'rgb(0, 0, 0, 0.2)'
          // 'rgb(0, 0, 0, 1)'
        ],
        borderColor: [
          'rgb(0, 0, 0, 0.2)'
          // 'rgb(0, 0, 0, 1)'
        ],
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 15,
        barPercentage: 0.5,
      },
      {
        label: 'Compliance background',
        type: 'line',
        data: [20, 7, 5, 3, 6, 5, 6],
        backgroundColor: [
          'rgb(0, 0, 0, 0.2)'
          // 'rgb(0, 0, 0, 1)'
        ],
        borderColor: [
          'rgb(0, 0, 0, 0.2)'
          // 'rgb(0, 0, 0, 1)'
        ],
        borderWidth: 1,
        borderRadius: 5,
        barThickness: 15,
        barPercentage: 0.5,
      }
    ],
  };

  const modalChartOptions = {
    responsive: true,
    onClick: (_, elements) => {
      if (elements.length > 0) {
        const clickedBarIndex = elements[0];
        onBarClick(clickedBarIndex);
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        onClick: (click, legendItem, legend) => {
          const datasets = legend.legendItems.map((dataset, index) => {
            return dataset.text;
          })

          const index = datasets.indexOf(legendItem.text);
          if (legend.chart.isDatasetVisible(index) === true) {
            legend.chart.hide(index);
          } else {
            legend.chart.show(index);
          }
        },
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            let visibility = [];
            for (let i = 0; i < chart.data.datasets.length; i++) {
              if (chart.isDatasetVisible(i) === true) {
                visibility.push(false);
              }
              else {
                visibility.push(true);
              }
            }
            let pointStyle = [];
            chart.data.datasets.forEach(dataset => {
              pointStyle.push('rect')
            })

            return chart.data.datasets.map((dataset, index) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.borderColor,
              pointStyle: pointStyle[index],
              hidden: visibility[index]
            })
            )
          }
        }
      },

      title: {
        display: false,
        text: 'Compliance Complaints'
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: true,
          text: 'Number of Complaints',
        },
      },
    },

  }

  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];

  const rows = [
    [
      "Completeness Check",
      "Completeness Check",
      "Completeness Check",
      new Date(2023, 0, 2),
      new Date(2023, 0, 4), // yyyy dd mmm
      null,
      100,
      null,
    ],
    [
      "Recommendation From Director",
      "Recommendation From Director",
      "recommendation from director",
      new Date(2023, 0, 4),
      new Date(2023, 0, 8), // yyyy dd mmm
      null,
      100,
      null,
    ],
    [
      "Approval by Commission",
      "Approval by Commission",
      "approval by commission",
      new Date(2023, 0, 9),
      new Date(2023, 0, 12), // yyyy dd mmm
      null,
      100,
      null,
    ],
  ];

  const data = [columns, ...rows];

  const options = {
    height: 400,
    gantt: {
      trackHeight: 30,
    },
  };

  return (
    <div>
      <ModuleInfo moduleName={'Overview'} moduleDesc={''} reportButton={false} />

      <div className="safety-near-miss-container">
        {/* Near Miss and Observation Graph*/}
        <p className="graph-heading">Safety - Near Miss & Observations</p>
        <BarChart
          data={overviewChartData.SafetyNearMissChart}
          options={overviewChartData.SafetyNearMissChartOptions} />
      </div>

      <div className="partial-width-graph-container">
        <div className="horizontal-overview-container">
          <div className="sub-graph-container">
            {/* Compliance Graph */}
            <p className="graph-heading">Compliance Complaints</p>
            <BarChart
              data={overviewChartData.complianceChart}
              options={overviewChartData.complianceChartOptions} />
          </div>
          <div>

          </div>
          <div className="sub-graph-container">

            {/* <DualAxes {...config} /> */}

            {/* Permittance Graph */}
            <p className="graph-heading">Permitting - Expiring Permits</p>
            <Chart
              chartType="Gantt"
              width="100%"
              height="50%"
              data={data}
              options={options} />
            {/* <BarChart
              data={overviewChartData.permittanceChart}
              options={overviewChartData.permittanceChartOptions} /> */}
          </div>
        </div>
      </div>

      <div className="partial-width-graph-container">
        <div className="horizontal-overview-container">
          <div className="sub-graph-container">
            {/* Merger and Aquisition Graph */}
            <p className="graph-heading">Mergers & Acquisitions - Permit Approvals</p>
            <BarChart
              data={overviewChartData.mergerAcquisitionsChart}
              options={overviewChartData.mergerAcquisitionsChartOptions} />
          </div>
          <div className="sub-graph-container">
            {/* Air GHG Emissions Graph */}
            <p className="graph-heading">Air - Greenhouse Gas Emissions</p>
            <BarChart
              data={overviewChartData.airChart}
              options={overviewChartData.airChartOptions} />
          </div>
        </div>
      </div>

      <div className="partial-width-graph-container">
        <div className="horizontal-overview-container">
          <div className="sub-graph-container">
            {/* ESG Graph */}
            <p className="graph-heading">ESG - Charitable Contributions</p>
            <BarChart
              data={overviewChartData.esgChart}
              options={overviewChartData.esgChartOptions} />
          </div>
          <div className="sub-graph-container">
            {/* Benchmarking Graph */}
            <p className="graph-heading">Benchmarking - Spills Count & Intensity</p>
            <BarChart
              data={overviewChartData.benchmarkingChart}
              options={overviewChartData.benchmarkingChartOptions} />
          </div>
        </div>
      </div>

      <div className="partial-width-graph-container">
        <div className="horizontal-overview-container">
          <div className="sub-graph-container">
            <p className="graph-heading">Clickable bar chart</p>
            <BarChart
              data={modalChart}
              options={modalChartOptions} />
          </div>
        </div>
      </div>

      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>{barIndex}</p>
      </Modal>
    </div>
  )
}

export default Overview;