import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './module.formBuilder.css';
import DropCollapseDown from '../../../src/assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../../src/assets/images/DropCollapseDownSelected.svg';
import FilterTickIcon from '../../../src/assets/images/FilterTickIcon.svg';

const DropdownSelect = ({ onFilterChartType, graphName, selectedFilter }) => {

    return (
        <div className="dropdown">
            
            <button className={`btn dropdown-toggle ${selectedFilter ? 'selected-filter-color' : ''}`} 
            type="button" id="dropdownMenuButton1" style={{ paddingRight: graphName === 'Near Miss and Observation' ? '40px' : undefined }}
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Choose Chart
                <img className="ml-8" src={selectedFilter ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
            </button>
            <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                <a className="dropdown-item cursor" onClick={(e) => { onFilterChartType(1, graphName) }}>
                    <div className="">
                        {selectedFilter == 1 ? <img src={FilterTickIcon} /> : ''}
                        <label className={` cursor ${selectedFilter == 1 ? 'selected-filter-color ml-8' : ''}`}>Trend Line</label>
                    </div>
                </a>
                <a className="dropdown-item cursor" onClick={(e) => { onFilterChartType(2, graphName) }}>
                    <div className="">
                        {selectedFilter == 2 ? <img src={FilterTickIcon} /> : ''}
                        <label className={` cursor ${selectedFilter == 2 ? 'selected-filter-color ml-8' : ''}`}>Number</label>
                    </div>
                </a>
                <a className="dropdown-item cursor" onClick={(e) => { onFilterChartType(3, graphName) }}>
                    <div className="">
                        {selectedFilter == 3 ? <img src={FilterTickIcon} /> : ''}
                        <label className={` cursor ${selectedFilter == 3 ? 'selected-filter-color ml-8' : ''}`}>Trend & Number</label>
                    </div>
                </a>
                {/* <div className="mt-2 float-right">
                    <button type="button" className="btn-clear" onClick={(e) => { onFilter(1) }}>Clear</button>
                </div> */}
            </div>
        </div>
        // <Dropdown onSelect={handler}>
        //     <Dropdown.Toggle variant="light" id="dropdown-basic">
        //         { type=== 'yearly' && <span>
        //         {'Choose Duration'} <img src={ArrowDownDark} />
        //         </span>}
        //         { type!== 'yearly' && <span>
        //         {'Choose Chart'} <img src={ArrowDownDark} />
        //         </span>}
        //     </Dropdown.Toggle>

        //     <Dropdown.Menu >
        //         {data && data.map((item, index) => (
        //             <Dropdown.Item key={item.id} eventKey={item.filter_number}>{item.name}</Dropdown.Item>
        //         ))}
        //     </Dropdown.Menu>
        // </Dropdown>
    )
}

export default DropdownSelect;