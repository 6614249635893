import React, { Fragment, useState, useEffect } from "react";
import { Container, Alert } from 'react-bootstrap';
import SuccessIcon from '../../assets/images/SuccessIcon.svg';
import BtnCrossIcon from '../../assets/images/FormImages/btnCross.svg';
import FailedToastIcon from '../../assets/images/FailedToastIcon.svg';
import './AlertNotifier.css';

const AlertNotifier = ({ timer, alertText, addStyles, setVisibility, alertType }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShow(false);
            setVisibility(false);
        }, timer);
    }, [])

    return (
        <div className="outer">
        <div className="inner">
            {show &&
                <div className={`alert-class ${addStyles}`}>
                    <Alert variant={alertType === 'success' ? 'success' : alertType === 'failed' ? 'danger' : ''} className='alert-box' >
                        {alertType === 'success' ? <img src={SuccessIcon} /> : alertType === 'failed' ? <img src={FailedToastIcon} /> : ''}
                        <span>{' ' + alertText}</span>
                    </Alert>
                </div>
            }
        </div>
        </div>
    )
}

export default AlertNotifier;