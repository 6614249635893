import { React } from "react";
import { Pie } from 'react-chartjs-2';
import { Chart, Tooltip, Title, Legend, ArcElement } from "chart.js";
import PropTypes from 'prop-types';

Chart.register(
    Tooltip, Title, ArcElement, Legend,
);

const PieChart = ({ data, options, pieChartData, showTable }) => {
    return (
        <div className="container">
            <div className="row pie-graph-background-card">
                {!showTable &&
                    <>
                    <p style={{color: '#ffffff'}}>No Recorded Data Found</p>
                    </>
                }
                {showTable && <>
                <div className="col-md-5">
                    <Pie data={data} options={options} className="mt-3"/>
                </div>

                <div className="col-md-7 mt-4">
                    <ul>
                        {pieChartData && pieChartData.map((item, index) => (
                            <li key={index} className="body-part-item pb-1">
                                <span className="dot" style={{backgroundColor: `${item.legend_color}`}}></span>{' '}{item.body_part + ': '+ item.body_part_per + ` (${item.body_part_count})`} <span className="font-9"><em>{item.Urgent ? 'Need Urgent Attention' : ''}</em></span></li>
                        ))}
                    </ul>
                </div>
                </> }
            </div>
        </div>
    )
}

PieChart.propTypes = {
    data: PropTypes.array.isRequired, // Example: Ensure data is an array and is required
    options: PropTypes.object.isRequired, // Example: Ensure options is an object and is required
    pieChartData: PropTypes.array.isRequired, // Example: Ensure pieChartData is an array and is required
    showTable: PropTypes.bool.isRequired, // Example: Ensure showTable is a boolean and is required
};

export default PieChart;