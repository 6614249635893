import React from "react";
import { Bars } from 'react-loader-spinner'
import "./loader.css";
const LoadingSpinner = () => {
  return (
    <>
      <div className="loader">
        <Bars
          className="loader"
          heigth="100"
          width="100"
          color='#173454'
          ariaLabel='loading'
        />
      </div>
    </>
  );
};

export default LoadingSpinner;
