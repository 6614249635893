//Safety Near Miss and Observation Chart
const SafetyNearMissChart = {
    labels: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
    datasets: [
        {
            type: 'bar',
            label: 'Near Miss',
            data: [5, 3.5, 6, 4, 3.5, 6, 5, 2, 3, 3.5, 4],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 20,
            barPercentage: 0.5,
        },
        {
            type: 'bar',
            label: 'Observations',
            data: [1, 1, 3, 1, 2, 4, 5, 4, 3, 4, 1, 3, 3],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 20,
            barPercentage: 0.5,
        },
        {
            type: 'line',
            label: 'Near Miss Trend',
            data: [5, 9, 17, 20, 23, 32, 40, 42, 52, 55, 60, 66],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 1,
        },
        {
            type: 'line',
            label: 'Observation Trend',
            data: [4, 8, 11, 14, 16, 17, 21, 24.5, 25, 32, 34, 38, 42],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
        },
    ],
};

const SafetyNearMissChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    xValue: 5.5,
                    yValue: 35,
                    backgroundColor: 'rgba(245,245,245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        if (dataset.type === 'line') {
                            pointStyle.push('line');
                        }
                        else {
                            pointStyle.push('rect')
                        }
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'Fire'
        }
    },
}

// compliance chart data
const complianceChart = {
    labels: ['2018', '2019', '2020', '2021', '2022', '2023'],
    datasets: [
        {
            label: 'Compliance complaints',
            data: [2, 0, 1, 0, 5, 3],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        }
    ],
};

const complianceChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    xValue: 2.5,
                    yValue: 2,
                    backgroundColor: 'rgba(245,245,245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        pointStyle.push('rect')
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },

        title: {
            display: false,
            text: 'Compliance Complaints'
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Number of Complaints',
            },
        },
    },

}

// permittance chart data
const permittanceChart = {
    labels: ['2020', '2021', '2022', '2023', '2024', '2025'],
    datasets: [
        {
            label: 'Client A',
            data: [2, 0, 1, 0, 5, 3],
            backgroundColor: [
                'rgb(68, 114, 196)'
            ],
            borderColor: [
                'rgb(68, 114, 196)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        },
        {
            label: 'Client B',
            data: [1, 2, 0, 0, 3, 4],
            backgroundColor: [
                'rgb(238,125,49)'
            ],
            borderColor: [
                'rgb(238,125,49)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        },
        {
            label: 'Client C',
            data: [0, 0, 0, 4, 2, 3],
            backgroundColor: [
                'rgb(85,130,52)'
            ],
            borderColor: [
                'rgb(85,130,52)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        }
    ],
};

const permittanceChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    xValue: 2.5,
                    yValue: 2,
                    backgroundColor: 'rgba(245,245,245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        pointStyle.push('rect')
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'Expiring Permits'
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Expiring Permits',
            },
        },
    },
}

// mergers and acquisitions chart data
const mergerAcquisitionsChart = {
    labels: ['2020', '2021', '2022', '2023', '2024', '2025'],
    datasets: [
        {
            label: 'Client A',
            data: [2, 0, 1, 0, 5, 3],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        },
        {
            label: 'Client B',
            data: [1, 2, 0, 0, 3, 4],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        },
        {
            label: 'Client C',
            data: [0, 0, 0, 4, 2, 3],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        }
    ],
};

const mergerAcquisitionsChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    xValue: 2.5,
                    yValue: 2,
                    backgroundColor: 'rgba(245, 245, 245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        pointStyle.push('rect')
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'Permit Approvals'
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Number of Approved Permits',
            },
        },
    },
}

// Air chart data
const airChart = {
    labels: ['2018', '2019', '2020', '2021', '2022', '2023'],
    datasets: [
        {
            label: 'DJ Bassin Emissions (mt CO2-e)',
            type: 'bar',
            data: [80000, 100000, 130000, 150000, 80000],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        },
        {
            label: 'Permian Emissions (mt CO2-e)',
            type: 'bar',
            data: [10000, 35000, 80000, 90000, 60000],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            barPercentage: 0.5,
        },
        {
            type: 'line',
            label: 'Permian Emissions Intensity (mt/BoE)',
            data: [180000, 182000, 240000, 235000, 180000],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 2,
        },
        {
            type: 'line',
            label: 'DJ Basin Emissions (mt CO2-e)',
            data: [92000, 80000, 75000, 65000, 60000],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 2,
        },
    ],
};

const airChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    xValue: 2.5,
                    // yValue: 20,
                    backgroundColor: 'rgba(245,245,245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }

                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        if (dataset.type === 'line') {
                            pointStyle.push('line');
                        }
                        else {
                            pointStyle.push('rect')
                        }
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'Emissions (mt CO2-e)'
        }
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            beginAtZero: true,
            stacked: true,
            title: {
                display: true,
                text: 'Emissions (mt CO2-e)',
            },
        },
    },
}

// ESG chart data
const esgChartData = [65000, 116000, 94000, 32000, 72000];
const esgChart = {
    labels: ['2023', '2022', '2021', '2020', '2019'],
    datasets: [
        {
            data: esgChartData,
            label: 'Charitable Contributions in dollars ($)',
            backgroundColor:
                'rgb(0, 0, 0, 1)',
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            // borderWidth: 1,
            borderRadius: 5,
            borderWidth: 1,
            barThickness: 15,
            barPercentage: 0.5,

        },
    ],
};

const esgChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    // xValue: 100,
                    yValue: 2,
                    backgroundColor: 'rgba(245,245,245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        pointStyle.push('rect')
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'ESG Charitable contributions'
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Contributions per year',
            },
        },
    },
    indexAxis: 'y'
}


// ESG chart data
const permitChart = {
    labels: ['2023', '2022', '2021', '2020', '2019'],
    datasets: [
        {
            data: esgChartData,
            label: 'Charitable Contributions in dollars ($)',

            backgroundColor: [
                'rgba(255, 26, 104, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(0, 0, 0, )'
            ],

            borderColor: [
                'rgba(255, 26, 104, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(0, 0, 0, 1)'
            ],

            // borderWidth: 1,
            borderRadius: 10,
            borderWidth: 1,
            barThickness: 15,
            barPercentage: 0.5,
            borderSkipped: false
        },
    ],
};

const todayLine = {
    id: 'todayLine',
    afterDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, chartArea: { top, bottom }, scales: { x} } = chart;
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.strokeStyle = 'rgba(255, 26, 104, 1)';
        ctx.setLineDash([6, 6]);
        ctx.moveto(x.getPixelForValue(new Date()), top);
        ctx.moveto(x.getPixelForValue(new Date()), bottom);
        ctx.stroke();
        ctx.setLineDash([]);

    }
}

const assignedTasks = {
    id: 'assignedTasks',
    afterDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data, scales: { y } } = chart;
        ctx.font = 'border 12px sans-serif';
        ctx.fillStyle = 'black';
        ctx.textBaseline = 'middle';
        ctx.fillStyle('Text', 10, y.getPixelForValue(0));
        ctx.fillStyle(data.datasets[0].data[0].name, 10, y.getPixelForValue(0))
    }
}


const permitChartOptions = {
    responsive: true,
    plugins: [
        todayLine,
        assignedTasks
    ],

    scales: {
        indexAxis: 'y',
        x: {
            position: 'top',
            type: 'time',
            time: {
                unit: 'day'
            },
            min: '2022-10-01',
            max: '2022-10-31',
        }
    },
}

// benchmarking chart data
const benchmarkingChart = {
    labels: ['2019', '2020', '2021', '2022', '2023'],
    datasets: [
        {
            type: 'bar',
            label: 'Spill Incident Count - Client A',
            data: [5, 6, 4, 2, 1],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            maxBarThickness: 25,
        },
        {
            type: 'bar',
            label: 'Spill Incident Count - Client B',
            data: [3, 8, 4, 3, 4],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            maxBarThickness: 25,
        },
        {
            type: 'bar',
            label: 'Spill Incident Count - Client C',
            data: [2, 4, 0, 2, 1],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'

            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 1,
            borderRadius: 5,
            barThickness: 15,
            maxBarThickness: 25,
        },
        {
            type: 'line',
            label: 'Spills Intensity - Client A',
            data: [7, 8.2, 6.2, 5, 6],
            backgroundColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 1)'
            ],
            borderWidth: 2,
        },
        {
            type: 'line',
            label: 'Spills Intensity - Client B',
            data: [2.1, 8, 1.8, 2.2, 0.6],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'

            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 2,
        },
        {
            type: 'line',
            label: 'Spills Intensity - Client C',
            data: [2, 3.5, 1.7, 2.4, 1],
            backgroundColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderColor: [
                'rgb(0, 0, 0, 0.2)'
            ],
            borderWidth: 2,
        },
    ],
}

const benchmarkingChartOptions = {
    responsive: true,
    plugins: {
        annotation: {
            annotations: {
                label1: {
                    type: 'label',
                    xValue: 2,
                    yValue: 4,
                    backgroundColor: 'rgba(245,245,245)',
                    content: ['Coming Soon'],
                    font: {
                        size: 18
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        if (dataset.type === 'line') {
                            pointStyle.push('line');
                        }
                        else {
                            pointStyle.push('rect')
                        }
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'Lost Time Injuries'
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Number of incidents',
            },
        },
    },
}

export default {
    complianceChart, complianceChartOptions, permittanceChart, permittanceChartOptions, mergerAcquisitionsChart,
    mergerAcquisitionsChartOptions, airChart, airChartOptions, esgChart, esgChartOptions, benchmarkingChart,
    benchmarkingChartOptions, SafetyNearMissChart, SafetyNearMissChartOptions, permitChart, permitChartOptions
}