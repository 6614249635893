import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import DropdownSelect from "../../../../components/formBuilder/DropdownSelect";
import mockData from '../../../../mockData/mockData';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DateRangePicker from "../../../../components/formBuilder/DateRangePicker";
import Popover from 'react-bootstrap/Popover';
import "./GraphTopBar.css";
import ArrowDownDark from '../../../../assets/images/ArrowDownDark.svg';
import DragIconDark from '../../../../assets/images/DragIconDark.svg';
import DragIconWhite from '../../../../assets/images/DragIconWhite.svg';
import TabContainerModal from "../../../../components/Miscellaneous/TabContainerModal";
import RecordableIncidentsModal from "../../../../components/Miscellaneous/RecordableIncidentsModal/RecordableIncidentsModal";


const GraphTopBar = ({ graphName,
    graphType,
    trendData,
    icon,
    employeeDdn,
    subHeading,
    onSelectSidebarIcon,
    onFilterChartType,
    stateDdn,
    stateDdnData,
    selectedFilter,
    spillTypeDdn,
    spillTypeDdnData,
    fluidTypeDdn,
    fluidTypeDdnData,
    onSelectType,
    numberOfComplaintsTypeData }) => {

    const [open, setOpen] = useState(false);
    const [recordableData, setRecordableData] = useState(mockData.RecordableInjuriesFilterData);
    const [firstAidData, setFirstAidData] = useState(mockData.FirstAidInjuryFilterData);
    const [lostTimeData, setLostTimeData] = useState(mockData.LostTimeInjuryFilterData);
    const [numberOfComplaintsData, setNumberOfComplaintsData] = useState(numberOfComplaintsTypeData);
    const [selectedRecordableIds, setSelectedRecordableIds] = useState([]);
    const [selectedFirstAidIds, setSelectedFirstAidIds] = useState([]);
    const [selectedLostTimeIds, setSelectedLostTimeIds] = useState([]);
    const [selectedNumberOfComplaintIds, setSelectedNumberOfComplaintIds] = useState([]);
    const [actualRecordableIds, setActualRecordableIds] = useState([]);
    const [actualFirstAidIds, setActualFirstAidIds] = useState([]);
    const [actualLostTimeIds, setActualLostTimeIds] = useState([]);
    const [actualNumberOfComplaintIds, setActualNumberOfComplaintIds] = useState([]);
    const [reportableSpillsTotalAndIntensityData, setReportableSpillsTotalAndIntensityData] = useState(stateDdnData);
    const [selectedReportableSpillsTotalAndIntensityIds, setSelectedReportableSpillsTotalAndIntensityIds] = useState([]);
    const [actualReportableSpillsTotalAndIntensityIds, setActualReportableSpillsTotalAndIntensityIds] = useState([]);
    const [historicalVersusRecentSpillsData, setHistoricalVersusRecentSpillsData] = useState(stateDdnData);
    const [selectedHistoricalVersusRecentSpillsIds, setSelectedHistoricalVersusRecentSpillsIds] = useState([]);
    const [actualHistoricalVersusRecentSpillsIds, setActualHistoricalVersusRecentSpillsIds] = useState([]);
    const [volumeOfOilAndProducedWaterData, setVolumeOfOilAndProducedWaterData] = useState(stateDdnData);
    const [selectedVolumeOfOilAndProducedWaterIds, setSelectedVolumeOfOilAndProducedWaterIds] = useState([]);
    const [actualVolumeOfOilAndProducedWaterIds, setActualVolumeOfOilAndProducedWaterIds] = useState([]);
    const [historicalVersusRecentSpillsTypeData, setHistoricalVersusRecentSpillsTypeData] = useState(spillTypeDdnData);
    const [selectedHistoricalVersusRecentSpillsTypeIds, setSelectedHistoricalVersusRecentSpillsTypeIds] = useState([]);
    const [actualHistoricalVersusRecentSpillsTypeIds, setActualHistoricalVersusRecentSpillsTypeIds] = useState([]);
    const [volumeOfOilAndProducedWaterTypeData, setVolumeOfOilAndProducedWaterTypeData] = useState(fluidTypeDdnData);
    const [selectedVolumeOfOilAndProducedWaterTypeIds, setSelectedVolumeOfOilAndProducedWaterTypeIds] = useState([]);
    const [actualVolumeOfOilAndProducedWaterTypeIds, setActualVolumeOfOilAndProducedWaterTypeIds] = useState([]);

    useEffect(() => {

        if (graphName === 'Reportable Spills Total & Intensity') {
            let updatedReportableSpillsTotalAndIntensityData = reportableSpillsTotalAndIntensityData.map((item, index) => {
                return { ...item, checked: false }
            })
            setReportableSpillsTotalAndIntensityData(updatedReportableSpillsTotalAndIntensityData);
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            let updatedHistoricalVersusRecentSpillsData = historicalVersusRecentSpillsData.map((item, index) => {
                return { ...item, checked: false }
            })
            setHistoricalVersusRecentSpillsData(updatedHistoricalVersusRecentSpillsData);
            let updatedHistoricalVersusRecentSpillsTypeData = historicalVersusRecentSpillsTypeData.map((item, index) => {
                return { ...item, checked: false }
            })
            setHistoricalVersusRecentSpillsTypeData(updatedHistoricalVersusRecentSpillsTypeData);
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            let updatedVolumeOfOilAndProducedWaterData = volumeOfOilAndProducedWaterData.map((item, index) => {
                return { ...item, checked: false }
            })
            setHistoricalVersusRecentSpillsData(updatedVolumeOfOilAndProducedWaterData);
            let updatedVolumeOfOilAndProducedWaterTypeData = volumeOfOilAndProducedWaterTypeData.map((item, index) => {
                return { ...item, checked: false }
            })
            setVolumeOfOilAndProducedWaterTypeData(updatedVolumeOfOilAndProducedWaterTypeData);
        }

    }, [])

    const showModal = () => {
        setOpen(!open);
    };

    const handleChartTypeDropdown = (graphName, type) => {
        if (graphName === 'Recordable Injury') {
            setSelectedRecordableIds(actualRecordableIds);
            const updatedArray = recordableData.map(item => ({
                ...item,
                checked: actualRecordableIds.includes(item.id)
            }));
            setRecordableData(updatedArray);
        }

        if (graphName === 'First Aid Injury') {
            setSelectedFirstAidIds(actualFirstAidIds);
            const updatedArray = firstAidData.map(item => ({
                ...item,
                checked: actualFirstAidIds.includes(item.id)
            }));
            setFirstAidData(updatedArray);
        }

        if (graphName === 'Lost Time Injury') {
            setSelectedLostTimeIds(actualLostTimeIds);
            const updatedArray = lostTimeData.map(item => ({
                ...item,
                checked: actualLostTimeIds.includes(item.id)
            }));
            setLostTimeData(updatedArray);
        }

        if (graphName === 'Number of Complaints') {
            setSelectedNumberOfComplaintIds(actualNumberOfComplaintIds);
            const updatedArray = numberOfComplaintsData?.map(item => ({
                ...item,
                checked: actualNumberOfComplaintIds?.includes(item.id)
            }));
            setNumberOfComplaintsData(updatedArray);
        }

        if (graphName === 'Reportable Spills Total & Intensity') {
            setSelectedReportableSpillsTotalAndIntensityIds(actualReportableSpillsTotalAndIntensityIds);
            const updatedArray = reportableSpillsTotalAndIntensityData.map(item => ({
                ...item,
                checked: actualReportableSpillsTotalAndIntensityIds.includes(item.id)
            }));
            setReportableSpillsTotalAndIntensityData(updatedArray);
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            if (type != 'spillType') {
                setSelectedHistoricalVersusRecentSpillsIds(actualHistoricalVersusRecentSpillsIds);
                const updatedArray = historicalVersusRecentSpillsData.map(item => ({
                    ...item,
                    checked: actualHistoricalVersusRecentSpillsIds.includes(item.id)
                }));
                setHistoricalVersusRecentSpillsData(updatedArray);
            } else {
                setSelectedHistoricalVersusRecentSpillsTypeIds(actualHistoricalVersusRecentSpillsTypeIds);
                const updatedArray = historicalVersusRecentSpillsTypeData.map(item => ({
                    ...item,
                    checked: actualHistoricalVersusRecentSpillsTypeIds.includes(item.id)
                }));
                setHistoricalVersusRecentSpillsTypeData(updatedArray);
            }
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            if (type != 'fluidType') {
                setSelectedVolumeOfOilAndProducedWaterIds(actualVolumeOfOilAndProducedWaterIds);
                const updatedArray = volumeOfOilAndProducedWaterData.map(item => ({
                    ...item,
                    checked: actualVolumeOfOilAndProducedWaterIds.includes(item.id)
                }));
                setVolumeOfOilAndProducedWaterData(updatedArray);
            } else {
                setSelectedVolumeOfOilAndProducedWaterTypeIds(actualVolumeOfOilAndProducedWaterTypeIds);
                const updatedArray = volumeOfOilAndProducedWaterTypeData.map(item => ({
                    ...item,
                    checked: actualVolumeOfOilAndProducedWaterTypeIds.includes(item.id)
                }));
                setVolumeOfOilAndProducedWaterTypeData(updatedArray);
            }
        }

    }

    const selectAll = (type) => {
        if (graphName === 'Recordable Injury') {
            return recordableData.every(item => item.checked === true);
        }

        if (graphName === 'First Aid Injury') {
            return firstAidData.every(item => item.checked === true);
        }

        if (graphName === 'Lost Time Injury') {
            return lostTimeData.every(item => item.checked === true);
        }

        if (graphName === 'Number of Complaints') {
            return numberOfComplaintsData.every(item => item.checked === true);
        }

        if (graphName === 'Reportable Spills Total & Intensity') {
            return reportableSpillsTotalAndIntensityData.every(item => item.checked === true);
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            if (type != 'spillType') {
                return historicalVersusRecentSpillsData.every(item => item.checked === true);
            } else {
                return historicalVersusRecentSpillsTypeData.every(item => item.checked === true);
            }
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            if (type != 'fluidType') {
                return volumeOfOilAndProducedWaterData.every(item => item.checked === true);
            } else {
                return volumeOfOilAndProducedWaterTypeData.every(item => item.checked === true);
            }
        }
    }

    const onAllFeaturesSelectSpillType = (e, type) => {
        showElement1();
        if (graphName === 'Historical versus Recent Spills Total') {
            const updatedData = historicalVersusRecentSpillsTypeData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setHistoricalVersusRecentSpillsTypeData(updatedData)
            const selectedIds = historicalVersusRecentSpillsTypeData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedHistoricalVersusRecentSpillsTypeIds(selectedIds);
            } else {
                setSelectedHistoricalVersusRecentSpillsTypeIds([]);
            }
        }
        if (graphName === 'Volume of Oil & Produced Water Spills') {
            const updatedData = volumeOfOilAndProducedWaterTypeData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setVolumeOfOilAndProducedWaterTypeData(updatedData)
            const selectedIds = volumeOfOilAndProducedWaterTypeData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedVolumeOfOilAndProducedWaterTypeIds(selectedIds);
            } else {
                setSelectedVolumeOfOilAndProducedWaterTypeIds([]);
            }
        }
    }

    const onAllFeaturesSelect = (e, type) => {
        showElement();
        if (graphName === 'Recordable Injury') {
            const updatedData = recordableData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setRecordableData(updatedData)
            const selectedIds = recordableData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedRecordableIds(selectedIds);
            } else {
                setSelectedRecordableIds([]);
            }
        }

        if (graphName === 'First Aid Injury') {
            const updatedData = firstAidData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setFirstAidData(updatedData)
            const selectedIds = firstAidData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedFirstAidIds(selectedIds);
            } else {
                setSelectedFirstAidIds([]);
            }
        }

        if (graphName === 'Lost Time Injury') {
            const updatedData = lostTimeData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setLostTimeData(updatedData)
            const selectedIds = lostTimeData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedLostTimeIds(selectedIds);
            } else {
                setSelectedLostTimeIds([]);
            }
        }

        if (graphName === 'Number of Complaints') {
            const updatedData = numberOfComplaintsData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setNumberOfComplaintsData(updatedData)
            const selectedIds = numberOfComplaintsData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedNumberOfComplaintIds(selectedIds);
            } else {
                setSelectedNumberOfComplaintIds([]);
            }
        }

        if (graphName === 'Reportable Spills Total & Intensity') {
            const updatedData = reportableSpillsTotalAndIntensityData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setReportableSpillsTotalAndIntensityData(updatedData)
            const selectedIds = reportableSpillsTotalAndIntensityData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedReportableSpillsTotalAndIntensityIds(selectedIds);
            } else {
                setSelectedReportableSpillsTotalAndIntensityIds([]);
            }
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            const updatedData = historicalVersusRecentSpillsData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setHistoricalVersusRecentSpillsData(updatedData)
            const selectedIds = historicalVersusRecentSpillsData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedHistoricalVersusRecentSpillsIds(selectedIds);
            } else {
                setSelectedHistoricalVersusRecentSpillsIds([]);
            }
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            const updatedData = volumeOfOilAndProducedWaterData.map(item => ({
                ...item,
                [e.target.name]: e.target.checked,
            }));
            // Update the state with the new array
            setVolumeOfOilAndProducedWaterData(updatedData)
            const selectedIds = volumeOfOilAndProducedWaterData?.map((resp) => {
                return resp.id
            })
            if (e.target.checked) {
                setSelectedVolumeOfOilAndProducedWaterIds(selectedIds);
            } else {
                setSelectedVolumeOfOilAndProducedWaterIds([]);
            }
        }

        // showElement();
    }

    const onChangeSpillType = (e, index, type) => {
        showElement1();
        if (graphName === 'Historical versus Recent Spills Total') {
            const updatedData = [...historicalVersusRecentSpillsTypeData];
            updatedData[index].checked = e.target.checked;
            setHistoricalVersusRecentSpillsTypeData(updatedData);
            let empSelectedIds = historicalVersusRecentSpillsTypeData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedHistoricalVersusRecentSpillsTypeIds(selectedIds);
        }
        if (graphName === 'Volume of Oil & Produced Water Spills') {
            const updatedData = [...volumeOfOilAndProducedWaterTypeData];
            updatedData[index].checked = e.target.checked;
            setVolumeOfOilAndProducedWaterTypeData(updatedData);
            let empSelectedIds = volumeOfOilAndProducedWaterTypeData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedVolumeOfOilAndProducedWaterTypeIds(selectedIds);
        }
    }

    const onChange = (e, index, type) => {
        showElement();
        if (graphName === 'Recordable Injury') {
            const updatedData = [...recordableData];
            updatedData[index].checked = e.target.checked;
            setRecordableData(updatedData);
            let empSelectedIds = recordableData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedRecordableIds(selectedIds);
        }

        if (graphName === 'First Aid Injury') {
            const updatedData = [...firstAidData];
            updatedData[index].checked = e.target.checked;
            setFirstAidData(updatedData);
            let empSelectedIds = firstAidData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedFirstAidIds(selectedIds);
        }

        if (graphName === 'Lost Time Injury') {
            const updatedData = [...lostTimeData];
            updatedData[index].checked = e.target.checked;
            setLostTimeData(updatedData);
            let empSelectedIds = lostTimeData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedLostTimeIds(selectedIds);
        }

        if (graphName === 'Number of Complaints') {
            const updatedData = [...numberOfComplaintsData];
            updatedData[index].checked = e.target.checked;
            setNumberOfComplaintsData(updatedData);
            let complaintSelectedIds = numberOfComplaintsData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = complaintSelectedIds.filter(x => x != '');
            setSelectedNumberOfComplaintIds(selectedIds);
        }

        if (graphName === 'Reportable Spills Total & Intensity') {
            const updatedData = [...reportableSpillsTotalAndIntensityData];
            updatedData[index].checked = e.target.checked;
            setReportableSpillsTotalAndIntensityData(updatedData);
            let empSelectedIds = reportableSpillsTotalAndIntensityData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedReportableSpillsTotalAndIntensityIds(selectedIds);
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            const updatedData = [...historicalVersusRecentSpillsData];
            updatedData[index].checked = e.target.checked;
            setHistoricalVersusRecentSpillsData(updatedData);
            let empSelectedIds = historicalVersusRecentSpillsData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedHistoricalVersusRecentSpillsIds(selectedIds);
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            const updatedData = [...volumeOfOilAndProducedWaterData];
            updatedData[index].checked = e.target.checked;
            setVolumeOfOilAndProducedWaterData(updatedData);
            let empSelectedIds = volumeOfOilAndProducedWaterData?.map((resp) => {
                if (resp.checked) {
                    return resp.id
                } else {
                    return ''
                }
            })
            const selectedIds = empSelectedIds.filter(x => x != '');
            setSelectedVolumeOfOilAndProducedWaterIds(selectedIds);
        }

        // showElement();
    }

    const onSelectionDone = (type) => {
        if (graphName === 'Recordable Injury') {
            onSelectSidebarIcon(selectedRecordableIds, graphName);
            setActualRecordableIds(selectedRecordableIds);
        }

        if (graphName === 'First Aid Injury') {
            onSelectSidebarIcon(selectedFirstAidIds, graphName);
            setActualFirstAidIds(selectedFirstAidIds);
        }

        if (graphName === 'Lost Time Injury') {
            onSelectSidebarIcon(selectedLostTimeIds, graphName);
            setActualLostTimeIds(selectedLostTimeIds);
        }

        if (graphName === 'Number of Complaints') {
            onSelectSidebarIcon(selectedNumberOfComplaintIds, graphName);
            setActualNumberOfComplaintIds(selectedNumberOfComplaintIds);
        }

        if (graphName === 'Reportable Spills Total & Intensity') {
            onSelectSidebarIcon(selectedReportableSpillsTotalAndIntensityIds, graphName);
            setActualReportableSpillsTotalAndIntensityIds(selectedReportableSpillsTotalAndIntensityIds);
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            if (type != 'spillType') {
                const updatedData = historicalVersusRecentSpillsTypeData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setHistoricalVersusRecentSpillsTypeData(updatedData);
                setActualHistoricalVersusRecentSpillsTypeIds([]);
                onSelectSidebarIcon(selectedHistoricalVersusRecentSpillsIds, graphName);
                setActualHistoricalVersusRecentSpillsIds(selectedHistoricalVersusRecentSpillsIds);
            } else {
                const updatedData = historicalVersusRecentSpillsData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setHistoricalVersusRecentSpillsData(updatedData);
                setActualHistoricalVersusRecentSpillsIds([]);
                onSelectType(selectedHistoricalVersusRecentSpillsTypeIds, graphName);
                setActualHistoricalVersusRecentSpillsTypeIds(selectedHistoricalVersusRecentSpillsTypeIds);
            }
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            if (type != 'fluidType') {
                const updatedData = volumeOfOilAndProducedWaterTypeData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setVolumeOfOilAndProducedWaterTypeData(updatedData);
                setActualVolumeOfOilAndProducedWaterTypeIds([]);
                onSelectSidebarIcon(selectedVolumeOfOilAndProducedWaterIds, graphName);
                setActualVolumeOfOilAndProducedWaterIds(selectedVolumeOfOilAndProducedWaterIds);
            } else {
                const updatedData = volumeOfOilAndProducedWaterData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setVolumeOfOilAndProducedWaterData(updatedData);
                setActualVolumeOfOilAndProducedWaterIds([]);
                onSelectType(selectedVolumeOfOilAndProducedWaterTypeIds, graphName);
                setActualVolumeOfOilAndProducedWaterTypeIds(selectedVolumeOfOilAndProducedWaterTypeIds);
            }
        }

        hideElement();
        hideElement1();

    }

    const onClearFeature = (type) => {
        if (graphName === 'Recordable Injury') {
            const updatedData = recordableData?.map((resp) => {
                return { ...resp, checked: false }
            })
            setRecordableData(updatedData);
            setSelectedRecordableIds([1, 2, 3]);
            onSelectSidebarIcon([1, 2, 3], graphName);
            setActualRecordableIds([]);
        }

        if (graphName === 'First Aid Injury') {
            const updatedData = firstAidData?.map((resp) => {
                return { ...resp, checked: false }
            })
            setFirstAidData(updatedData);
            setSelectedFirstAidIds([4, 5, 6]);
            onSelectSidebarIcon([4, 5, 6], graphName);
            setActualFirstAidIds([]);
        }

        if (graphName === 'Lost Time Injury') {
            const updatedData = lostTimeData?.map((resp) => {
                return { ...resp, checked: false }
            })
            setLostTimeData(updatedData);
            setSelectedLostTimeIds([7, 8, 9]);
            onSelectSidebarIcon([7, 8, 9], graphName);
            setActualLostTimeIds([]);
        }

        if (graphName === 'Number of Complaints') {
            const updatedData = numberOfComplaintsData?.map((resp) => {
                return { ...resp, checked: false }
            })
            setNumberOfComplaintsData(updatedData);
            setSelectedNumberOfComplaintIds([10, 11]);
            onSelectSidebarIcon([10, 11], graphName);
            setActualNumberOfComplaintIds([]);
        }

        if (graphName === 'Reportable Spills Total & Intensity') {
            const updatedData = reportableSpillsTotalAndIntensityData?.map((resp) => {
                return { ...resp, checked: false }
            })
            setReportableSpillsTotalAndIntensityData(updatedData);
            setSelectedReportableSpillsTotalAndIntensityIds([7, 8, 9]);
            onSelectSidebarIcon([7, 8, 9], graphName);
            setActualReportableSpillsTotalAndIntensityIds([]);
        }

        if (graphName === 'Historical versus Recent Spills Total') {
            if (type != 'spillType') {
                const updatedData = historicalVersusRecentSpillsData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setHistoricalVersusRecentSpillsData(updatedData);
                setSelectedHistoricalVersusRecentSpillsIds([7, 8, 9]);
                onSelectSidebarIcon([7, 8, 9], graphName);
                setActualHistoricalVersusRecentSpillsIds([]);
            } else {
                const updatedData = historicalVersusRecentSpillsTypeData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setHistoricalVersusRecentSpillsTypeData(updatedData);
                setSelectedHistoricalVersusRecentSpillsTypeIds([7, 8, 9]);
                onSelectSidebarIcon([7, 8, 9], graphName);
                // onSelectType([7, 8, 9], graphName)
                setActualHistoricalVersusRecentSpillsTypeIds([]);
            }
        }

        if (graphName === 'Volume of Oil & Produced Water Spills') {
            if (type != 'fluidType') {
                const updatedData = volumeOfOilAndProducedWaterData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setVolumeOfOilAndProducedWaterData(updatedData);
                setSelectedVolumeOfOilAndProducedWaterIds([7, 8, 9]);
                onSelectSidebarIcon([7, 8, 9], graphName);
                setActualVolumeOfOilAndProducedWaterIds([]);
            } else {
                const updatedData = volumeOfOilAndProducedWaterTypeData?.map((resp) => {
                    return { ...resp, checked: false }
                })
                setVolumeOfOilAndProducedWaterTypeData(updatedData);
                setSelectedVolumeOfOilAndProducedWaterTypeIds([7, 8, 9]);
                onSelectSidebarIcon([7, 8, 9], graphName);
                setActualVolumeOfOilAndProducedWaterTypeIds([]);
            }
        }

        hideElement();
        hideElement1();
    }

    const hideElement = () => {
        const element1 = document.getElementById(`dropdownHidden ${graphName}`);
        if (element1) {
            element1.classList.remove('show');
        }
    }

    const hideElement1 = () => {
        const element1 = document.getElementById(`dropdownHidden1 ${graphName}`);
        if (element1) {
            element1.classList.remove('show');
        }
    }

    const showElement = () => {
        const element1 = document.getElementById(`dropdownHidden ${graphName}`);
        if (element1) {
            element1.classList.add('show');
        }
    }

    const showElement1 = () => {
        const element1 = document.getElementById(`dropdownHidden1 ${graphName}`);
        if (element1) {
            element1.classList.add('show');
        }
    }

    const popoverBottom = (
        <Popover id="popover-positioned-bottom" title="Popover bottom" style={{ position: 'relative', top: '50px' }} className="popuuu" placement="bottom">
            <div className="headTolltip">How this chart will help you?</div>
            <hr className="mt-mb-2" />
            <div className="reportCat">Description</div>
            <div className="descrpTolltip">
                Under the guidelines of the Occupational Safety and Health Administration (OSHA) in the United States,
                a recordable injury or illness is defined as any workplace injury that requires medical treatment beyond first aid,
                results in loss of consciousness, restricts work, or causes a worker to be transferred to another job. Deaths, and certain injuries
                diagnosed by a physician or other licensed health care professional, like significant injuries such as fractures, punctures, lacerations,
                or those that require sutures, are also recordable.
            </div>
        </Popover>
    );

    return (
        <>
            {graphName !== 'Recordable Injury' && <TabContainerModal open={open} showModal={showModal} />}
            {graphName === 'Recordable Injury' && <RecordableIncidentsModal open={open} showModal={showModal} />}
            <div className="d-flex">
                <div className="p-2">
                    {graphType === 'PieChart' && <p className="heading-text-white">{graphName}</p>}
                    {graphType !== 'PieChart' && <p className="heading-text-black">{graphName}</p>}
                </div>
                <div className="flex-grow-1 padding-6">
                    <OverlayTrigger placement="bottom" overlay={popoverBottom}>
                        <img className="cursor" src={icon} alt={'alternateIcon'}
                        />
                    </OverlayTrigger>
                </div>
                <div className="p-2">
                    {((graphType !== 'PieChart') && (graphType !== 'HorizontalChart')) &&
                        <DropdownSelect
                            classNamePrefix="an-simple-select"
                            data={trendData}
                            onFilterChartType={onFilterChartType}
                            graphName={graphName}
                            selectedFilter={selectedFilter}
                        />
                    }
                </div>
                {employeeDdn && <div className="p-2">
                    <div className="dropdown">

                        <Button className="btn-light dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            onClick={() => handleChartTypeDropdown(graphName)}
                        >
                            {graphName === 'Number of Complaints' ? 'Complaint Type' : 'Choose Type'} <img src={ArrowDownDark} />

                        </Button>
                        {graphName === 'Recordable Injury' &&
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1" id={`dropdownHidden ${graphName}`} >
                                <a className="dropdown-item">
                                    <div className="form-check">
                                        <label
                                            className="form-check-label selectAll"
                                            htmlFor='Checkme1'
                                        >Select All
                                        </label>

                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='checked'
                                            id="Checkme1"
                                            checked={selectAll()}
                                            onChange={(e) => { onAllFeaturesSelect(e) }} />
                                    </div>
                                </a>
                                {recordableData.map((item, index) => (
                                    <a className="dropdown-item" key={index}>
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor={`Checkme ${index}`}> {item?.label} </label>

                                            <input className="form-check-input" type="checkbox" value={item.id}
                                                id={`Checkme ${index}`}
                                                name={item.name}
                                                checked={item?.checked}
                                                onChange={(e) => onChange(e, index, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                </div>
                            </div>
                        }
                        {graphName === 'First Aid Injury' &&
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2" id={`dropdownHidden ${graphName}`}>
                                <a className="dropdown-item">
                                    <div className="form-check">
                                        <label
                                            className="form-check-label selectAll"
                                            htmlFor="Checkme2">Select All
                                        </label>

                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='checked'
                                            id="Checkme2"
                                            checked={selectAll()}
                                            onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {firstAidData.map((item, index) => (
                                    <a className="dropdown-item" key={index}>
                                        <div className="form-check">
                                            <label
                                                className="form-check-label"
                                                htmlFor={`Checkme ${item.id}`}
                                            >{item?.label}
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.id}
                                                id={`Checkme ${item.id}`}
                                                name={item.name}
                                                checked={item?.checked}
                                                onChange={(e) => onChange(e, index, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                </div>
                            </div>
                        }

                        {graphName === 'Lost Time Injury' &&
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3" id={`dropdownHidden ${graphName}`}>
                                <a className="dropdown-item">
                                    <div className="form-check">
                                        <label
                                            className="form-check-label selectAll"
                                            htmlFor="Checkme3">Select All
                                        </label>

                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='checked'
                                            id="Checkme3"
                                            checked={selectAll()}
                                            onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {lostTimeData.map((item, index) => (
                                    <a className="dropdown-item" key={index}>
                                        <div className="form-check">
                                            <label
                                                className="form-check-label"
                                                htmlFor={`Checkme ${item.id}`}>{item?.label}
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.id}
                                                id={`Checkme ${item.id}`}
                                                name={item.name}
                                                checked={item?.checked}
                                                onChange={(e) => onChange(e, index, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                </div>
                            </div>
                        }

                        {graphName === 'Number of Complaints' &&
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2" id={`dropdownHidden ${graphName}`}>
                                <a className="dropdown-item">
                                    <div className="form-check">
                                        <label
                                            className="form-check-label selectAll"
                                            htmlFor="Checkme3">Select All
                                        </label>

                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='checked'
                                            id="Checkme3"
                                            checked={selectAll()}
                                            onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {numberOfComplaintsData.map((item, index) => (
                                    <a className="dropdown-item" key={index}>
                                        <div className="form-check">
                                            <label
                                                className="form-check-label"
                                                htmlFor={`Checkme ${item.id}`}>{item?.label}
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item.id}
                                                id={`Checkme ${item.id}`}
                                                name={item.name}
                                                checked={item?.checked}
                                                onChange={(e) => onChange(e, index, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>}

                {stateDdn &&
                    <>
                        <div className="p-2">
                            <div className="dropdown">

                                <Button className="btn-light dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    onClick={() => handleChartTypeDropdown(graphName)}
                                >
                                    Choose State <img src={ArrowDownDark} />

                                </Button>
                                <>

                                {graphName == 'Reportable Spills Total & Intensity' && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2" id={`dropdownHidden ${graphName}`}>
                                    <a className="dropdown-item">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label selectAll"
                                                htmlFor="Checkme6">Select All
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='checked'
                                                id="Checkme6"
                                                checked={selectAll()}
                                                onChange={(e) => onAllFeaturesSelect(e)} />
                                        </div>
                                    </a>
                                    {(reportableSpillsTotalAndIntensityData).map((item, index) => (
                                        <a className="dropdown-item" key={index}>
                                            <div className="form-check">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`Checkme6 ${item.id}`}
                                                >{item?.label}
                                                </label>

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.id}
                                                    id={`Checkme6 ${item.id}`}
                                                    name={item.name}
                                                    checked={item?.checked}
                                                    onChange={(e) => onChange(e, index, item)} />
                                            </div>
                                        </a>
                                    ))}
                                    <div className="flex mt-4">
                                        <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                        <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                    </div>
                                </div>}

                                {graphName == 'Historical versus Recent Spills Total' && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3" id={`dropdownHidden ${graphName}`}>
                                    <a className="dropdown-item">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label selectAll"
                                                htmlFor="Checkme7">Select All
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='checked'
                                                id="Checkme7"
                                                checked={selectAll()}
                                                onChange={(e) => onAllFeaturesSelect(e)} />
                                        </div>
                                    </a>
                                    {(historicalVersusRecentSpillsData).map((item, index) => (
                                        <a className="dropdown-item" key={index}>
                                            <div className="form-check">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`Checkme7 ${item.id}`}
                                                >{item?.label}
                                                </label>

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.id}
                                                    id={`Checkme7 ${item.id}`}
                                                    name={item.name}
                                                    checked={item?.checked}
                                                    onChange={(e) => onChange(e, index, item)} />
                                            </div>
                                        </a>
                                    ))}
                                    <div className="flex mt-4">
                                        <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                        <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                    </div>
                                </div>}

                                {graphName == 'Volume of Oil & Produced Water Spills' && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton4" id={`dropdownHidden ${graphName}`}>
                                    <a className="dropdown-item">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label selectAll"
                                                htmlFor="Checkme8">Select All
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='checked'
                                                id="Checkme8"
                                                checked={selectAll()}
                                                onChange={(e) => onAllFeaturesSelect(e)} />
                                        </div>
                                    </a>
                                    {(volumeOfOilAndProducedWaterData).map((item, index) => (
                                        <a className="dropdown-item" key={index}>
                                            <div className="form-check">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`Checkme8 ${item.id}`}
                                                >{item?.label}
                                                </label>

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.id}
                                                    id={`Checkme8 ${item.id}`}
                                                    name={item.name}
                                                    checked={item?.checked}
                                                    onChange={(e) => onChange(e, index, item)} />
                                            </div>
                                        </a>
                                    ))}
                                    <div className="flex mt-4">
                                        <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                        <button type="button" className="btn-done" onClick={onSelectionDone}>Done</button>
                                    </div>
                                </div>}

                                </>
                            </div>
                        </div>

                        {spillTypeDdn && <div className="p-2">
                            <div className="dropdown">

                                <Button className="btn-light dropdown-toggle" id="dropdownMenuButton5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    onClick={() => handleChartTypeDropdown(graphName, 'spillType')}
                                >
                                    Spill Type <img src={ArrowDownDark} />

                                </Button>
                                <><div className="dropdown-menu" aria-labelledby="dropdownMenuButton5" id={`dropdownHidden1 ${graphName}`}>
                                    <a className="dropdown-item">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label selectAll"
                                                htmlFor="Checkme9">Select All
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='checked'
                                                id="Checkme9"
                                                checked={selectAll('spillType')}
                                                onChange={(e) => onAllFeaturesSelectSpillType(e, 'spillType')}
                                            />
                                        </div>
                                    </a>
                                    {historicalVersusRecentSpillsTypeData.map((item, index) => (
                                        <a className="dropdown-item" key={index}>
                                            <div className="form-check">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`Checkme9 ${item.id}`}
                                                >{item?.label}
                                                </label>

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.id}
                                                    id={`Checkme9 ${item.id}`}
                                                    name={item.name}
                                                    checked={item?.checked}
                                                    onChange={(e) => onChangeSpillType(e, index, item, 'spillType')}
                                                />
                                            </div>
                                        </a>
                                    ))}
                                    <div className="flex mt-4">
                                        <button type="button" className="btn-clear" onClick={() => onClearFeature('spillType')}>Clear</button>
                                        <button type="button" className="btn-done" onClick={() => onSelectionDone('spillType')}>Done</button>
                                    </div>
                                </div>
                                </>
                            </div>
                        </div>}

                        {fluidTypeDdn && <div className="p-2">
                            <div className="dropdown">

                                <Button className="btn-light dropdown-toggle" id="dropdownMenuButton6" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    onClick={() => handleChartTypeDropdown(graphName, 'fluidType')}
                                >
                                    Fluid Type <img src={ArrowDownDark} />

                                </Button>
                                <><div className="dropdown-menu" aria-labelledby="dropdownMenuButton6" id={`dropdownHidden1 ${graphName}`}>
                                    <a className="dropdown-item">
                                        <div className="form-check">
                                            <label
                                                className="form-check-label selectAll"
                                                htmlFor="Checkme10">Select All
                                            </label>

                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='checked'
                                                id="Checkme10"
                                                checked={selectAll('fluidType')}
                                                onChange={(e) => onAllFeaturesSelectSpillType(e, 'fluidType')}
                                            />
                                        </div>
                                    </a>
                                    {volumeOfOilAndProducedWaterTypeData.map((item, index) => (
                                        <a className="dropdown-item" key={index}>
                                            <div className="form-check">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`Checkme10 ${item.id}`}
                                                >{item?.label}
                                                </label>

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.id}
                                                    id={`Checkme10 ${item.id}`}
                                                    name={item.name}
                                                    checked={item?.checked}
                                                    onChange={(e) => onChangeSpillType(e, index, item, 'fluidType')}
                                                />
                                            </div>
                                        </a>
                                    ))}
                                    {/* {numberOfComplaintsTypeData.map((item, index) => (
                                        <a className="dropdown-item" key={index}>
                                            <div className="form-check">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`Checkme ${item.id}`}
                                                >{item?.label}
                                                </label>

                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.id}
                                                    id={`Checkme ${item.id}`}
                                                    name={item.name}
                                                    checked={item?.checked}
                                                // onChange={(e) => onChange(e, index, item)} 
                                                />
                                            </div>
                                        </a>
                                    ))} */}
                                    <div className="flex mt-4">
                                        <button type="button" className="btn-clear" onClick={() => onClearFeature('fluidType')}>Clear</button>
                                        <button type="button" className="btn-done" onClick={() => onSelectionDone('fluidType')}>Done</button>
                                    </div>
                                </div>
                                </>
                            </div>
                        </div>}

                        {/* <div className="p-2">
                            <button className="customize-btn" onClick={showModal}>
                                <img src={CustomizeButtonIcon} className="margin-5-4" />
                                Customize
                            </button>
                        </div> */}
                    </>
                }

                {/* {graphType !== 'PieChart' && <div className="p-2">
                    <Button size="sm" className="bg-white border light-blue"
                    onClick={showModal}
                >
                    <img src={CustomizeButtonIcon} className="margin-5-4" />
                Customize
                </Button></div>} */}
                {graphType === 'PieChart' && <div>
                    <DateRangePicker />
                </div>}
                {graphName !== 'Near Miss and Observation' && <div className="padding-12-15"><img src={graphType === 'PieChart' ? DragIconWhite : DragIconDark} /></div>}
            </div>
            {graphType === 'PieChart' && <div className="px-2 sub-heading-text"><p>{subHeading}</p></div>}
            {graphType === 'PieChart' && <hr className="hr-border" />}
        </>
    )
}

export default GraphTopBar;