import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Get } from "../../../constants/apiMethods";
import LoadingSpinner from "../../../components/Loader";
import moment from "moment";
import './ViewFacility.css';
import { facility } from "../../../constants/apiRoutes";

const ViewFacility = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [viewData, setViewData] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        getDetailsById();
    }, []);

    const getDetailsById = () => {
        setLoading(true);
        Get(facility, token, `?is_active=${location.state.is_active}&id=${location.state.id}`, false)
            .then((response) => response)
            .then((data) => {
                if (data) {
                    setLoading(false);
                    setViewData(data);
                }
            });
    };

    const handleFacility = () => {
        !location.state.is_active ? localStorage.setItem('getPage', 1) : localStorage.setItem('getPage', 0);
        navigate('/facility-management');
    }

    const handleClick = () => {
        navigate('/update-facility', { state: { id: location.state.id, is_active: location.state.is_active}  });
    }

    return (
        <div className="full-cont">
            {loading && <LoadingSpinner />}
            <div className="row">
                <div className="col-md-7">
                    <div className="head_1"><span className='click_head_1 cursor fw-400' onClick={handleFacility}>Facility Management</span> / {location.state.is_active ? 'Active ' : 'Deactivated '} Facility List / <span>View Facility</span></div>
                    <div className="head_2">{viewData?.facility_name}</div>
                </div>
                <div className="col-md-5 text-right">
                    {/* <button type="button" className='btn-export no_margin mr-15'>Export</button> */}
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>
            </div>
            <hr className="defaultLine" />

            <div className='containerHeading'>System Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Added By</div>
                        <p className="text-content word-break">{viewData?.added_by?.name ? viewData?.added_by?.name : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Added On</div>
                        <p className="text-content word-break">{viewData?.created_on ? moment(viewData?.created_on).format('MM-DD-YYYY') : 'NA'}</p>
                    </div>
                    
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>{location.state.is_active ? 'Last Updated By' : 'Deactivated By' }</div>
                        <p className="text-content word-break">{location.state.is_active ? viewData?.updated_by?.name ? viewData?.updated_by?.name : 'NA' : viewData?.deleted_by?.name ? viewData?.deleted_by?.name : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>{location.state.is_active ? 'Last Updated On' : 'Deactivated On' }</div>
                        <p className="text-content word-break">{location.state.is_active ? viewData?.updated_on ? moment(viewData?.updated_on).format('MM-DD-YYYY') : 'NA' : viewData?.deleted_date ? moment(viewData?.deleted_date).format('MM-DD-YYYY') : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Source</div>
                        <p className="text-content word-break">{viewData?.source ? viewData?.source : 'NA'}</p>
                    </div>
                </div>
            </div>

            <div className='containerHeading'>Facility Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Facility Id</div>
                        <p className="text-content word-break">{viewData?.facility_id ? viewData?.facility_id : ''}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Range</div>
                        <p className="text-content word-break">{viewData?.range ? viewData?.range : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Qtr Qtr</div>
                        <p className="text-content word-break">{viewData?.qtr_qtr ? viewData?.qtr_qtr : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>County</div>
                        <p className="text-content word-break">{viewData?.county?.name ? viewData?.county?.name : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>API Number</div>
                        <p className="text-content word-break">{viewData?.api_sequence_number ? viewData?.api_sequence_number : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Latitude</div>
                        <p className="text-content word-break">{viewData?.lat ? viewData?.lat : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Longitude</div>
                        <p className="text-content word-break">{viewData?.long ? viewData?.long : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Township</div>
                        <p className="text-content word-break">{viewData?.township ? viewData?.township : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Section</div>
                        <p className="text-content word-break">{viewData?.section ? viewData?.section : 'NA'}</p>
                    </div>
                </div>
            </div>

            <div className="col-md-12 text-right mt-30">
                    {/* <button type="button" className='btn-export no_margin mr-15'>Export</button> */}
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>

        </div>
    )
}

export default ViewFacility;