import React, { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import "./Header.css";
import '../Layout.css';
import { useNavigate } from "react-router";
import { logOut } from "../../../constants/apiRoutes";
import { Post } from "../../../constants/apiMethods";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "../../Loader";
import AlertNotifier from "../../Notifications/AlertNotifier";


const Header = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const name = localStorage.getItem('name');
  const role = localStorage.getItem('role');
  const [loading, setLoading] = useState(false);
  const [dropTitle, setDropTitle] = useState(name);
  const [showHideLogout, setshowHideLogoutPopup] = useState(false);
  const [successRes, setSuccessRes] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState('');

  const navigateToChangePassword = () => {
    navigate('../change-password');
  }

  const logOutUser = () => {
    setshowHideLogoutPopup(false);
    setLoading(true);
    Post(logOut, token, '', false)
      .then((response) => response)
      .then((data) => {
        // if (data.success) {
        setShowSuccessMessage('User Logged out successfully');
        setAlertType('success');
        setSuccessRes(true);
        setTimeout(() => {
          localStorage.clear();
          navigate('../login');
          setLoading(false);
          window.location.reload();
        }, 2000)
        // }
      });
  }

  const navigateToMyProfile = () => {
    if (role == 'super_admin') {
      navigate('../litico-admin-profile')
    } else if (role == 'company_admin') {
      navigate('../company-admin-profile')
    } else if (role == 'user') {
      navigate('../user-profile')
    }
  }

  const setVisibility = (data) => {
    setSuccessRes(data);
  }

  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      {successRes ? <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} /> : null}
      <Navbar bg="white" expand="lg" className="padding-right-50">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <div className="dropdown p-2 mt-10">
							<button className="btn dropdown-toggle fs-16" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{dropTitle}
								<img className="ml-8" src={DropCollapseDown} alt="dropdownIcon" />
							</button>
							<div className="dropdown-menu" id='dropdownHidden8' aria-labelledby="dropdownMenuButton1">
								<a className="dropdown-item cursor" onClick={navigateToMyProfile}>
									<div className="">
										<label className="cursor">My Profile</label>
									</div>
								</a>
								<a className="dropdown-item cursor" onClick={navigateToChangePassword}>
									<div className="">
										<label className="cursor">Change Password</label>
									</div>
								</a>
                <a className="dropdown-item cursor" onClick={() => setshowHideLogoutPopup(true)}>
									<div className="">
										<label className="cursor">Log out</label>
									</div>
								</a>
							</div>
						</div>

          </Nav>

        </Navbar.Collapse>
      </Navbar>

      <Modal show={showHideLogout} dialogClassName="reportConfrmModal" onHide={() => setshowHideLogoutPopup(false)}>
        <Modal.Header className="sideOnRight" closeButton>
        </Modal.Header>
        <Modal.Body className="auth_modal_header border-0 mx-auto">
          <div className="modal-body reportText">
            Are you sure you would like to Log out?
          </div>
          <Modal.Footer>
            <button type="button" className="btn-no" onClick={() => setshowHideLogoutPopup(false)}>No</button>
            <button type="button" className="btn-yes" onClick={logOutUser}>Yes</button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>

  )
}

export default Header;