import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import './index.css';

const FilePreviewFinal = (props) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  console.log('pdf file', props.pdfFile);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className='pdf-div-class'>
        {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
      <Document file={props.pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        {/* <Page pageNumber={pageNumber} /> */}
        {Array.apply(null, Array(numPages)).map((x, i) => i + 1)
        .map((page) => {
            return (
                <Page 
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                />
            );
        })}
      </Document>
      
    </div>
  );
}

export default FilePreviewFinal;