import React, { useCallback } from 'react';
import UploadIcon from '../assets/images/FormImages/upload.svg';
import { useDropzone } from 'react-dropzone';

const FileUploader = ({ onFileUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    // function to integrate with the dropped files, e.g., upload to a server
    onFileUpload(acceptedFiles);
  }, [onFileUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Specified accepted file types
  });

  return (
    <div className='supportDcmnt' {...getRootProps()}>
      <img className="mt-30" src={UploadIcon} alt='uploadIcon' />
      <div className='dragTitle'>Drag and drop files here</div>
      <div className='orIcon'>or</div>
      <button type="button" className='btn-add-data'>Browse files</button>
      <input {...getInputProps()} type="file" id="img" name="file" accept="image/jpg, image/jpeg" />
    </div>
  );
};

export default FileUploader;
