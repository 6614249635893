import React, { useState, useMemo, useEffect } from 'react';
import DropCollapseDown from '../../../../assets/images/DropCollapseDown.svg';
import ClearIcon from '../../../../assets/images/FormImages/clear_icon.svg';
import SearchIcon from '../../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../../assets/images/FormImages/clear.svg';
import BtnCrossIcon from '../../../../assets/images/FormImages/btnCross.svg';
import DropCollapseDownSelected from '../../../../assets/images/DropCollapseDownSelected.svg';
import DateRangePicker from "../../../../components/formBuilder/DateRangePicker";
import data from '../../../../mockData/mockData';
import Pagination from "../../../../components/Pagination/Pagination";
import SelectDropdownAction from "../../../../components/formBuilder/SelectDropdownAction";
import DownloadIcon from '../../../../assets/images/FormImages/download.svg';
import FadedDownloadDoc from '../../../../assets/images/FormImages/fadedDownloadIcon.svg';
import ThreeDotsIcon from '../../../../assets/images/FormImages/threeDots.svg';
import OnboardingData from '../../../../mockData/OnboardingData/mockData';
import './CompanyListing.css';
import { useNavigate } from 'react-router';
import ReactPaginate from "react-paginate";
import ForwardPagination from '../../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../../assets/images/BackwardPagination.svg';
import { companyList, getStateList } from '../../../../constants/apiRoutes';
import { Delete2, Delete3, Get, Put } from '../../../../constants/apiMethods';
import moment from 'moment';
import LoadingSpinner from '../../../../components/Loader';
import { Modal } from 'react-bootstrap';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';

const CompanyListing = () => {
    const navigate = useNavigate();
    const isDeactivated = localStorage.getItem('isDeactivated');
    const planFeatures = OnboardingData.planFeatures;
    const [loading, setLoading] = useState(false);
    const [companyListData, setCompanyList] = useState([]);
    const [showIncident, setShowIncident] = useState(false);
    const [showFilters, setFilters] = useState(true);
    const [showActive, setShowActive] = useState(isDeactivated == 1 ? false : true);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [statesListData, setStatesList] = useState([]);
    const [selectedModulesId, setSelectedModulesId] = useState([]);
    const [selectedModuleString, setSelectedModuleString] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState(['', '']);
    const [clearDateValue, setClearDateValue] = useState(true);
    const [showHideChangeStatusCompany, setshowHideChangeStatusCompany] = useState({ show: false, id: '' });
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [actualModuleIds, setActualModuleIds] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const token = localStorage.getItem("access_token");
    let PageSize = 10;

    useEffect(() => {
        setIsOpen(false);
        getCompanyListing(page, '', '', ['', '']);
        getStatesList();
        localStorage.removeItem('step_one_submitted');
        localStorage.removeItem('companyId');
    }, [showActive])

    setTimeout(() => {
        localStorage.removeItem('isDeactivated');
    }, 1)

    const getCompanyListing = (page, searchValue, selectedModulesId, dateRangeValue) => {
        hideElement();
        if (dateRangeValue) {
            setDateRangeValue(dateRangeValue);
            setStartDate(dateRangeValue[0]);
            setEndDate(dateRangeValue[1]);
        }
        let selectedModuleStr = '';
        selectedModuleStr = selectedModulesId.toString();
        setSelectedModuleString(selectedModuleStr);
        setLoading(true);
        Get(companyList, token, `?page=${page}&name=${searchValue}&state=${selectedModuleStr}&is_active=${showActive}&start_date=${dateRangeValue[0]}&end_date=${dateRangeValue[1]}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    const listData = data.data?.map((resp) => {
                        return { ...resp, stateArrayValue: 3 }
                    });
                    setCompanyList(listData);
                    setCurrentPage(data.pagination_info);
                    setActualModuleIds(selectedModulesId);
                }
            });
    };

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const getStatesList = () => {
        Get(getStateList + `?is_active=true&min=true&sort=name`, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const stateListData = data.data?.map((resp) => {
                        return { ...resp, checked: false }
                    })
                    setStatesList(stateListData);
                }
            });
    }

    const handleOnboard = () => {
        navigate('/create-company');
    }

    const pageChange = (page) => {
        getCompanyListing(page + 1, '', selectedModulesId, dateRangeValue);
    }

    const onChange = (e, index) => {
        const updatedData = [...statesListData];
        updatedData[index].checked = e.target.checked;
        setStatesList(updatedData);
        let selectedIds = statesListData?.map((resp) => {
            if (resp.checked) {
                return resp.id
            } else {
                return ''
            }
        })
        selectedIds = selectedIds.filter(x => x != '');
        setSelectedModulesId(selectedIds);
    }

    const selectAll = () => {
        return statesListData.every(item => item.checked === true);
    }

    const onAllFeaturesSelect = (e) => {
        const updatedData = statesListData.map(item => ({
            ...item,
            [e.target.name]: e.target.checked,
        }));
        // Update the state with the new array
        setStatesList(updatedData);
        const selectedIds = statesListData?.map((resp) => {
            return resp.id
        })
        if (e.target.checked) {
            setSelectedModulesId(selectedIds);
        } else {
            setSelectedModulesId([]);
        }
    }

    const handleStateDropdown = () => {
        setSelectedModulesId(actualModuleIds);
        const updatedArray = statesListData.map(item => ({
            ...item,
            checked: actualModuleIds.includes(item.id)
        }));
        setStatesList(updatedArray);
    }

    const onClearFeature = () => {
        const updatedData = statesListData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setStatesList(updatedData);
        setSelectedModulesId([]);
        getCompanyListing(page, '', [], dateRangeValue);
    }

    const changeCompanyStatus = () => {
        setSuccessRes(false);
        setLoading(true);
        const body = {
            is_active: true
        }
        if (showActive) {
            Delete3(companyList, token, `?id=${showHideChangeStatusCompany?.id}`, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusCompany({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data) {
                        getCompanyListing(page, searchValue, selectedModulesId, dateRangeValue);
                        setAlertType('success');
                        setShowSuccessMessage('Company Deactivated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        } else {
            Put(`${companyList}/${showHideChangeStatusCompany.id}/`, token, body, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusCompany({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data) {
                        getCompanyListing(page, searchValue, selectedModulesId, dateRangeValue);
                        setAlertType('success');
                        setShowSuccessMessage('Company Activated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        }
    }


    const deleteDataFromList = (id) => {
        // to be used while API integration
        // Delete(deleteEventListData, token, id)
        // 	.then((response) => response)
        // 	.then((data) => {
        // 	});
    };

    const handleClick = () => {
        //to be used while API integration  
    }

    const onViewCompanyDetails = (id) => {
        navigate('/company-details', { state: { id: id, is_active: showActive } });
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const onUpdateCompanyDetails = (id) => {
        navigate('/update-company', { state: { id: id, is_active: showActive } });
    }

    const clearFilters = () => {
        setDateRangeValue('');
        setStartDate('');
        setEndDate('');
        const removeAllChecked = statesListData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setStatesList(removeAllChecked);
        setSelectedModuleString('');
        setSelectedModulesId([]);
        setIsOpen(false);
        setClearDateValue(false);
        setTimeout(() => {
            setClearDateValue(true);
        }, 1)
        getCompanyListing(page, searchValue, [], ['', '']);
    }

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
    }

    const showMoreIncidents = (item) => {
        const newState = companyListData.map(obj => {
            if (obj.id === item.id) {
                return { ...obj, stateArrayValue: obj.state.length };
            }
            return obj;
        });
        setCompanyList(newState);
    }

    const showLessIncidents = (item) => {
        const newState = companyListData.map(obj => {
            if (obj.id === item.id) {
                return { ...obj, stateArrayValue: 3 };
            }
            return obj;
        });
        setCompanyList(newState);
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={4000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-10">
                        <div className="head_2">Onboard Management</div>
                    </div>
                    <div className="col-md-2 text-right">
                        <button className='onboard_btn set-onbBtn-wd border-0' onClick={handleOnboard}>Onboard Company</button>
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-12">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active Companies</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated Companies</p>

                        </div>
                    </div>
                </div>

                <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown-container">
                            <button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${startDate && endDate != '' ? 'selected-filter-color' : ''}`}>
                                {startDate && endDate ? startDate + ' - ' + endDate : 'Onboarding Date Range'}
                                <img className="ml-8" src={startDate && endDate ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpen && (
                                <ul className="dropdown-list">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getCompanyListing(1, searchValue, selectedModulesId, dateString) }} onClear={() => { setStartDate(''); setEndDate(''); toggleDropdown(); getCompanyListing(1, searchValue, selectedModulesId, ['', '']) }} />}
                                </ul>
                            )}
                        </div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${selectedModuleString != '' ? 'selected-filter-color' : ''}`} onClick={handleStateDropdown} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                State
                                <img className="ml-8" src={selectedModuleString != '' ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton2">
                                <a className="dropdown-item" href="#">
                                    <div className="form-check">
                                        <label className="form-check-label selectAll" for="Checkme1">Select All</label>
                                        <input className="form-check-input" type="checkbox" id="Checkme1" name='checked' checked={selectAll()} onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {statesListData.map((item, i) => (
                                    <a className="dropdown-item" href="#">
                                        <div className="form-check">
                                            <label className="form-check-label" for={`Checkme ${i}`}>{item?.name}</label>
                                            <input className="form-check-input" type="checkbox" value={item.id} id={`Checkme ${i}`} name={item.name} checked={item?.checked} onChange={(e) => onChange(e, i, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={() => { getCompanyListing(page, searchValue, selectedModulesId, dateRangeValue) }}>Done</button>
                                </div>
                            </div>
                        </div>

                    </div><div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by company name" onChange={(event) => getCompanyListing(page, event.target.value, selectedModulesId, dateRangeValue)} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); setSearchValue(''); getCompanyListing(page, searchValue, selectedModulesId, dateRangeValue) }} />
                        </div>}

                </div>

                <>
                    {/* {companyListData?.length > 0 ? */}
                    <><div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Admin Name</th>
                                    <th scope="col">State</th>
                                    <th scope="col">{showActive ? 'Last Login Date' : 'Deactivation Date'}</th>
                                    <th scope="col">Date of Onboarding</th>
                                    <th scope="col">Days Left</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {companyListData?.length > 0 && companyListData.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="brdrtop">
                                                <td>{item?.name}</td>
                                                <td>{item?.company_admin?.name ? item?.company_admin?.name : 'NA'}</td>
                                                <td>{item.state.slice(0, item.stateArrayValue).map((state, locationIndex) => { return <div>{state}</div> })}
                                                    {(item?.state?.length > item.stateArrayValue) ?
                                                        <div className="link-color" onClick={() => showMoreIncidents(item)}>+ {item?.state?.length - item?.stateArrayValue} {item?.state?.length - item?.stateArrayValue > 1 ? 'States' : 'State'}</div> :
                                                        item?.state?.length > 3 ? <div className="link-color" onClick={() => showLessIncidents(item)}>View Less</div> : ''}
                                                </td>
                                                <td>{showActive ? item?.last_login_date ? moment(item?.last_login_date).format('MM-DD-YYYY') : 'NA' : item?.deactivated_date ? moment(item?.deactivated_date).format('MM-DD-YYYY') : 'NA'}</td>
                                                <td>{item?.date_of_onboarding ? moment(item?.date_of_onboarding).format('MM-DD-YYYY') : 'NA'}</td>
                                                <td>{item?.days_left ? item?.days_left + ' Days' : 'NA'}</td>
                                                <td>
                                                    <img className="editIcn" src={FadedDownloadDoc} />
                                                    {/* <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.ActionItems} onDelete={() => deleteDataFromList(item.id)} onView={() => onViewCompanyDetails(item.id)} isImg={true} /> */}
                                                    {showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.CompanyDeactiveActionItems} onDeactivate={() => setshowHideChangeStatusCompany({ show: true, id: item.id })} onView={() => onViewCompanyDetails(item.id)} onUpdate={() => onUpdateCompanyDetails(item?.id)} isImg={true} />}
                                                    {!showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.CompanyActiveActionItems} onActivate={() => setshowHideChangeStatusCompany({ show: true, id: item.id })} onView={() => onViewCompanyDetails(item.id)} onUpdate={() => onUpdateCompanyDetails(item?.id)} isImg={true} />}

                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}

                            </tbody>
                        </table>
                        {companyListData?.length == 0 && <div className="row">
                            <div className="col-md-12">
                                <div className="text-center no-data">No Data Found</div>
                            </div>
                        </div>}
                    </div>
                        {companyListData?.length > 0 && <ReactPaginate
                            forcePage={currentPage.current_page - 1}
                            pageCount={currentPage.total_count / 5}
                            marginPagesDisplayed={2}
                            onPageChange={(e) => pageChange(e.selected)}
                            containerClassName={'pagination-body'}
                            previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
                            nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
                            previousLinkClassName={'page'}
                            breakClassName={'page'}
                            nextLinkClassName={'page'}
                            pageClassName={'page'}
                            disabledClassName={'disabled'}
                            activeClassName={'active'} />}
                    </>
                </>

            </div>

            <Modal show={showHideChangeStatusCompany.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusCompany({ show: false, id: '' })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} this Company?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusCompany({ show: false, id: '' })}>No</button>
                        <button type="button" className="btn-yes" onClick={changeCompanyStatus}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default CompanyListing;