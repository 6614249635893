import React from "react";

const Air = () => {
  return (
    <div>
      <h2 className="progress-text-container">
        Work in progress for Air Module!
      </h2>
    </div>
  )
}

export default Air;