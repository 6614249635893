import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import BarChart from "./charts/BarChart";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import infoIcon from '../../../assets/images/Info.svg';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Post } from "../../../constants/apiMethods";
import {
    safetyDashboardCount, nearMissChartData, singleBarChartData,
    injuryGraphData, horizontalTypeInjuryChartData, impactedBodyParts, customizeGraphToggle
} from "../../../constants/apiRoutes";
import CheckboxSelect from "../../../components/formBuilder/CheckboxSelect";
import TabContainerMenu from "../../../components/Miscellaneous/TabContainerModal";
import GraphTopBar from "./GraphTopBar/GraphTopBar";
import DashboardCount from "./DashboardCount/DashboardCount";
import ModuleInfo from "../../../components/ModuleInfo/ModuleInfo";
import data from '../../../mockData/mockData';
import safetyChartData from "../../../mockData/SafetyChartData";
import CustomizeButtonIcon from '../../../assets/images/CustomizeButtonIcon.svg';
import ExportButtonIcon from '../../../assets/images/ExportButtonIcon.svg';
import DropDownData from '../../../mockData/DropdownData';
import "./Dashboard.css";
import Drag from '../../DragNDrop/Drag';
import LoadingSpinner from "../../../components/Loader";
import html2canvas from 'html2canvas';
import colorPalleteData from '../../../constants/themePallete';

import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import ReactGA from "react-ga4";

const Dashboard = () => {
    const role = localStorage.getItem('role');
    let permissionsObj;
    const indicatorsData = JSON.parse(localStorage.getItem('graphCustomization'));
    const leadingIndicatorsData = indicatorsData?.data?.leadingIndicators ? indicatorsData?.data?.leadingIndicators : data?.LeadingIndicators;
    const laggingIndicatorsData = indicatorsData?.data?.laggingIndicators ? indicatorsData?.data?.laggingIndicators : data?.LaggingIndicators;

    if (role == 'user') {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        //safety
        let safetyPermissionObject = permissions.filter(item => item.moduleName.toLowerCase() === 'safety');

        permissionsObj = {
            dashboardAccess: safetyPermissionObject[0].dashboardChecked,
            exportAccess: safetyPermissionObject[0].exportAccess,
            readAccess: safetyPermissionObject[0].readAccess,
            writeAccess: safetyPermissionObject[0].writeAccess
        }
    }

    const token = localStorage.getItem('access_token');
    const [finalArr, setFinalArr] = useState([]);
    const [dashboardCountObj, setDashboardCountObj] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [handleLocalCutomizationPopUp, setLocalCustomizationPopUp] = useState(false);
    const [localModal, setLocalShow] = useState(false);
    const [globalModal, setGlobalModal] = useState(false);
    const [leadShow, setLeadShow] = useState(false);
    const [leadingIndicatorData, setLeadingIndicatorData] = useState('');
    const target = useRef(null);
    const [filterValue, setFilterValue] = useState('Number & Trend');
    const [labelYearData, setLabelYearData] = useState([]);
    const [nearMissData, setNearMissData] = useState([]);
    const [observationData, setObservationData] = useState([]);
    const [fatalitiesGraphData, setFatalitiesGraphData] = useState();
    const [fireGraphData, setFireGraphData] = useState();
    const [vehicleAccidentGraphData, setVehicleAccidentGraphData] = useState();
    const [vandalismGraphData, setVandalismGraphData] = useState();
    const [propertyDamageGraphData, setPropertyDamageGraphData] = useState();
    const [firstAidInjuryGraphData, setFirstAidInjuryGraphData] = useState(); // setting first aid data response from api
    const [lostTimeInjuryGraphData, setLostTimeInjuryGraphData] = useState();

    const [recordableFilteredArrayBars, setRecordableFilteredArrayBars] = useState([]);
    const [firstAidFilteredArrayBars, setFirstAidFilteredArrayBars] = useState([]); // setting first aid duplicate response from api
    const [lostTimeFilteredArrayBars, setLostTimeFilteredArrayBars] = useState([]);


    const [recordableInjuryGraphData, setRecordableInjuryGraphData] = useState([]);

    const [recordableInjurySelectedEmpIds, setRecordableInjurySelectedEmpIds] = useState('');
    const [firstAidInjurySelectedEmpIds, setFirstAidInjurySelectedEmpIds] = useState('');
    const [lostTimeInjurySelectedEmpIds, setLostTimeInjurySelectedEmpIds] = useState('');
    const [recordableHorizontalData, setRecordableHorizontalData] = useState([]);
    const [firstAidHorizontalData, setFirstAidHorizontalData] = useState([]);
    const [selectedGraphTypeFilter, setSelectedGraphTypeFilter] = useState('');

    const [firstAidPieChartData, setFirstAidPieChartData] = useState([]);
    const [recordablePieChartData, setRecordablePieChartData] = useState([]);

    const [selectedLeadingGlobalFilters, setSelectedLeadingGlobalFilters] = useState([]);
    const [selectedLaggingGlobalFilters, setSelectedLaggingGlobalFilters] = useState([]);

    const [leadingIndicators, setLeadingIndicators] = useState(leadingIndicatorsData);
    const [laggingIndicators, setLaggingIndicators] = useState(laggingIndicatorsData);

    const [leadingGraphList, setLeadingGraphList] = useState([]);

    const [recordableDataset, setRecordableDataset] = useState([]);
    const [firstAidDataset, setFirstAidDataset] = useState([]);
    const [lostTimeDataset, setLostTimeDataset] = useState([]);

    const [nearMissNearestTwentyFive, setNearMissNearestTwentyFive] = useState('');
    const [recordableInjuryNearestFifty, setRecordableInjuryNearestFifty] = useState('');
    const [firstAidInjuryNearestTwenty, setFirstAidInjuryNearestTwenty] = useState('');
    const [lostTimeInjuryNearestFifty, setLostTimeInjuryNearestFifty] = useState('');
    const [fatalitiesNearestFifty, setFatalitiesNearestFifty] = useState('');
    const [fireNearestFifty, setFireNearestFifty] = useState('');
    const [vehicleAccidentNearestFifty, setVehicleAccidentNearestFifty] = useState('');
    const [propDamageNearestFifty, setPropDamageNearestFifty] = useState('');
    const [vandalismNearestFifty, setVandalismNearestFifty] = useState('');
    const [globalFilterDuration, setGlobalFilterDuration] = useState(1);
    const [globalFilterApplied, setGlobalFilterApplied] = useState(false);

    const [initialLeadingIndicator, setInitialLeadingIndicator] = useState([]);
    const [initialLaggingIndicator, setInitialLaggingIndicator] = useState([]);
    const [initialGlobalLeadingFilter, setInitialGlobalLeadingFilter] = useState([]);
    const [initialGlobalLaggingFilter, setInitialGlobalLaggingFilter] = useState([]);

    const [successRes, setSuccessRes] = useState(false);
    const [resDetail, setResDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [indicators, setIndicators] = useState([]);
    const [showRecordablePieTable, setShowRecordablePieTable] = useState(true);
    const [showFirstAidPieTable, setShowFirstAidPieTable] = useState(true);

    //chart type dropdown metadata
    const [recordableChartType, setRecordableChartType] = useState('bar');
    const [firstAidChartType, setFirstAidChartType] = useState('bar');
    const [lostTimeChartType, setLostTimeChartType] = useState('bar');

    const [selectedNearMissChart, setSelectedNearMissChart] = useState(3);
    const [selectedRecordableChart, setSelectedRecordableChart] = useState(3);
    const [selectedFirstAidChart, setSelectedFirstAidChart] = useState(3);
    const [selectedLostTimeChart, setSelectedLostTimeChart] = useState(3);
    const [selectedFatalityChart, setSelectedFatalityChart] = useState(3);
    const [selectedFireChart, setSelectedFireChart] = useState(3);
    const [selectedVehAccChart, setSelectedVehAccChart] = useState(3);
    const [selectedPropDamageChart, setSelectedPropDamageChart] = useState(3);
    const [selectedVandalChart, setSelectedVandalChart] = useState(3);

    const [showNearMissGraph, setShowNearMissGraph] = useState(false);
    const [showRecordableGraph, setShowRecordableGraph] = useState(false);
    const [showLostTimeGraph, setShowLostTimeGraph] = useState(false);
    const [showFirstAidGraph, setShowFirstAidGraph] = useState(false);
    const [showFatalityGraph, setShowFatalityGraph] = useState(false);
    const [showVehAccGraph, setShowVehAccGraph] = useState(false);
    const [showFireGraph, setShowFireGraph] = useState(false);
    const [showPropDamageGraph, setShowPropDamageGraph] = useState(false);
    const [showVandalismGraph, setShowVandalismGraph] = useState(false);

    const [recordableEmpData, setRecordableEmpData] = useState('');
    const [recordableContData, setRecordableContData] = useState('');
    const [recordableCombData, setRecordableCombData] = useState('');
    const [firstAidEmpData, setFirstAidEmpData] = useState('');
    const [firstAidContData, setFirstAidContData] = useState('');
    const [firstAidCombData, setFirstAidCombData] = useState('');
    const [lostTimeEmpData, setLostTimeEmpData] = useState('');
    const [lostTimeContData, setLostTimeContData] = useState('');
    const [lostTimeCombData, setLostTimeCombData] = useState('');

    const [fatalityData, setFatalityData] = useState('');
    const [fireData, setFireData] = useState('');
    const [vehAccData, setVehAccData] = useState('');
    const [propDamageData, setPropDamageData] = useState('');
    const [vandalData, setVandalData] = useState('');

    const [nearMissDataset, setNearMissDataset] = useState([]);
    const [fatalityDataset, setFatalityDataset] = useState([]);
    const [fireGraphDataset, setFireGraphDataset] = useState([]);
    const [vehAccGraphDataset, setVehAccGraphDataset] = useState([]);
    const [propDamageGraphDataset, setPropDamageGraphDataset] = useState([]);
    const [vandalGraphDataset, setVandalGraphDataset] = useState([]);

    const [recordableTrirEmpData, setRecordableTrirEmpData] = useState([]);
    const [recordableTrirContData, setRecordableTrirContData] = useState([]);
    const [recordableTrirCombData, setRecordableTrirCombData] = useState([]);

    const [lostTimeLtirEmpData, setLostTimeLtirEmpData] = useState([]);
    const [lostTimeLtirContData, setLostTimeLtirContData] = useState([]);
    const [lostTimeLtirCombData, setLostTimeLtirCombData] = useState([]);

    const [trirLimit, setTrirLimit] = useState('');
    const [ltirLimit, setLtirLimit] = useState('');
    const [firstAidTrendlineLimit, setFirstAidTrendlineLimit] = useState();
    const [nearMissTrendlineLimit, setNearMissTrendlineLimit] = useState(40);
    const [fatalityTrendlineLimit, setFatalityTrendlineLimit] = useState();
    const [fireTrendlineLimit, setFireTrendlineLimit] = useState();
    const [vehAccTrendlineLimit, setVehAccTrendlineLimit] = useState();
    const [propDamageTrendlineLimit, setPropDamageTrendlineLimit] = useState();
    const [vandalismTrendlineLimit, setVandalismTrendlineLimit] = useState();

    const [actualRecordableTypeData, setActualRecordableTypeData] = useState();
    const [actualLostTimeTypeData, setActualLostTimeTypeData] = useState();
    const [actualFirstAidTypeData, setActualFirstAidTypeData] = useState();

    const [graphList, setGraphList] = useState(
        [
            'Recordable Injury',
            'First Aid Injury',
            'Lost Time Injury',
            'Fatalities',
            'Recordable Injury Types',
            'First Aid Injury Types',
            'Impact on Body Part Due to Recordable',
            'Impact on Body Part Due to First Aid',
            'Fire',
            'Vehicle Accident',
            'Property Damage Incident',
            'Vandalism/Theft'
        ]);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const generateNearMissDatasets = (chartType, label, data, color, isBarType) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [color],
            borderColor: [color],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: 'left-side',
            pointRadius: 3
        };

        if (isBarType) {
            return {
                type: 'bar',
                ...commonProperties
            };
        } else {
            return {
                type: chartType,
                ...commonProperties
            };
        }
    };

    const generateRecordableDatasets = (chartType, label, data, color, isBarType, dataAxisLabel) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [color],
            borderColor: [color],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: dataAxisLabel,
            pointRadius: 3,
        };

        if (isBarType) {
            return {
                type: 'bar',
                ...commonProperties
            };
        } else {
            return {
                type: chartType,
                ...commonProperties
            };
        }
    }

    const generateCommonDatasets = (chartType, label, data, color, isBarType, dataAxisLabel) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [color],
            borderColor: [color],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: dataAxisLabel,
            pointRadius: 3,
        };

        if (isBarType) {
            return {
                type: 'bar',
                ...commonProperties
            };
        } else {
            return {
                type: chartType,
                ...commonProperties
            };
        }
    };

    const calculateCumulativeTrendLine = (graphName, graphData) => {
        if (graphData.length > 0) {
            let sum = 0;
            return graphData?.map(value => sum += value);
        }
        else return graphData;
    }

    useEffect(() => {

        const combinedDatasets = [
            ...barDatasets,
            ...lineDatasets,
        ];
        setNearMissDataset(combinedDatasets);

        const combinedRecordableDatasets = [
            ...recordableBarDatasets,
            ...recordableLineDatasets,
        ];
        // setDupFirstAidGraphData(combinedRecordableDatasets);
        setRecordableDataset(combinedRecordableDatasets);
        setActualRecordableTypeData(combinedRecordableDatasets);
        setRecordableFilteredArrayBars(combinedRecordableDatasets);

        const combinedFirstAidDatasets = [
            ...firstAidBarDatasets,
            ...firstAidLineDatasets,
        ];
        setFirstAidDataset(combinedFirstAidDatasets);
        setActualFirstAidTypeData(combinedFirstAidDatasets);
        setFirstAidFilteredArrayBars(combinedFirstAidDatasets);

        const combinedLostTimeDatasets = [
            ...lostTimeBarDatasets,
            ...lostTimeLineDatasets,
        ];
        setLostTimeDataset(combinedLostTimeDatasets);
        setActualLostTimeTypeData(combinedLostTimeDatasets);
        setLostTimeFilteredArrayBars(combinedLostTimeDatasets);

        const combinedFatalityDatasets = [
            ...fatalityBarDatasets,
            ...fatalityLineDatasets,
        ];
        setFatalityDataset(combinedFatalityDatasets);

        const combinedFireDatasets = [
            ...fireBarDatasets,
            ...fireLineDatasets,
        ];
        setFireGraphDataset(combinedFireDatasets);

        const combinedVehAccDatasets = [
            ...vehAccBarDatasets,
            ...vehAccLineDatasets,
        ];
        setVehAccGraphDataset(combinedVehAccDatasets);

        const combinedPropDamageDatasets = [
            ...propDamageBarDatasets,
            ...propDamageLineDatasets,
        ];
        setPropDamageGraphDataset(combinedPropDamageDatasets);

        const combinedVandalDatasets = [
            ...vandalBarDatasets,
            ...vandalLineDatasets,
        ];
        setVandalGraphDataset(combinedVandalDatasets);

    }, [fireData, fatalityData, vehAccData, propDamageData, vandalData, nearMissData, observationData,
        recordableEmpData, recordableContData, recordableCombData,
        firstAidEmpData, firstAidContData, firstAidCombData,
        lostTimeEmpData, lostTimeContData, lostTimeCombData]);

    const barDatasets = [
        generateNearMissDatasets('bar', 'Near Misses', nearMissData, colorPalleteData.graphYellowShade, true),
        generateNearMissDatasets('bar', 'Observation', observationData, colorPalleteData.graphPurpleShade, true),
    ];
    const lineDatasets = [
        generateNearMissDatasets('line', 'Near Misses', calculateCumulativeTrendLine('near misses', nearMissData), colorPalleteData.graphYellowShade),
        generateNearMissDatasets('line', 'Observation', calculateCumulativeTrendLine('observation', observationData), colorPalleteData.graphPurpleShade),
    ];

    const recordableBarDatasets = [
        generateRecordableDatasets('bar', 'Employee', recordableEmpData, colorPalleteData.graphGreenShade, true, 'left-side'),
        generateRecordableDatasets('bar', 'Contractor', recordableContData, colorPalleteData.graphPurpleShade, true, 'left-side'),
        generateRecordableDatasets('bar', 'Combined', recordableCombData, colorPalleteData.graphYellowShade, true, 'left-side'),
    ];

    const recordableLineDatasets = [
        generateRecordableDatasets('line', 'Employee', calculateCumulativeTrendLine('Recordable Injury', recordableTrirEmpData), colorPalleteData.graphGreenShade, false, 'right-side'),
        generateRecordableDatasets('line', 'Contractor', calculateCumulativeTrendLine('Recordable Injury', recordableTrirContData), colorPalleteData.graphPurpleShade, false, 'right-side'),
        generateRecordableDatasets('line', 'Combined', calculateCumulativeTrendLine('Recordable Injury', recordableTrirCombData), colorPalleteData.graphYellowShade, false, 'right-side'),
    ];

    const firstAidBarDatasets = [
        generateRecordableDatasets('bar', 'Employee', firstAidEmpData, colorPalleteData.graphGreenShade, true, 'left-side'),
        generateRecordableDatasets('bar', 'Contractor', firstAidContData, colorPalleteData.graphPurpleShade, true, 'left-side'),
        generateRecordableDatasets('bar', 'Combined', firstAidCombData, colorPalleteData.graphYellowShade, true, 'left-side'),
    ];

    const firstAidLineDatasets = [
        generateRecordableDatasets('line', 'Employee', calculateCumulativeTrendLine('First Aid Injury', firstAidEmpData), colorPalleteData.graphGreenShade, false, 'right-side'),
        generateRecordableDatasets('line', 'Contractor', calculateCumulativeTrendLine('First Aid Injury', firstAidContData), colorPalleteData.graphPurpleShade, false, 'right-side'),
        generateRecordableDatasets('line', 'Combined', calculateCumulativeTrendLine('First Aid Injury', firstAidCombData), colorPalleteData.graphYellowShade, false, 'right-side'),
    ];

    const lostTimeBarDatasets = [
        generateRecordableDatasets('bar', 'Employee', lostTimeEmpData, colorPalleteData.graphGreenShade, true, 'left-side'),
        generateRecordableDatasets('bar', 'Contractor', lostTimeContData, colorPalleteData.graphPurpleShade, true, 'left-side'),
        generateRecordableDatasets('bar', 'Combined', lostTimeCombData, colorPalleteData.graphYellowShade, true, 'left-side'),
    ];

    const lostTimeLineDatasets = [
        generateRecordableDatasets('line', 'Employee', calculateCumulativeTrendLine('Lost Time Injury', lostTimeLtirEmpData), colorPalleteData.graphGreenShade, false, 'right-side'),
        generateRecordableDatasets('line', 'Contractor', calculateCumulativeTrendLine('Lost Time Injury', lostTimeLtirContData), colorPalleteData.graphPurpleShade, false, 'right-side'),
        generateRecordableDatasets('line', 'Combined', calculateCumulativeTrendLine('Lost Time Injury', lostTimeLtirCombData), colorPalleteData.graphYellowShade, false, 'right-side'),
    ];

    const fatalityBarDatasets = [
        generateCommonDatasets('bar', 'Fatalities', fatalityData, colorPalleteData.graphYellowShade, true, 'left-side'),
    ];

    const fatalityLineDatasets = [
        generateCommonDatasets('line', 'Fatalities', calculateCumulativeTrendLine('fatality', fatalityData), colorPalleteData.graphYellowShade, false, 'right-side'),
    ];

    const fireBarDatasets = [
        generateCommonDatasets('bar', 'Fires', fireData, colorPalleteData.graphPurpleShade, true, 'left-side'),
    ];

    const fireLineDatasets = [
        generateCommonDatasets('line', 'Fires', calculateCumulativeTrendLine('fire', fireData), colorPalleteData.graphPurpleShade, false, 'right-side'),
    ];

    const vehAccBarDatasets = [
        generateCommonDatasets('bar', 'Vehicle Accidents', vehAccData, colorPalleteData.graphGreenShade, true, 'left-side'),
    ];

    const vehAccLineDatasets = [
        generateCommonDatasets('line', 'Vehicle Accidents', calculateCumulativeTrendLine('vehicle accident', vehAccData), colorPalleteData.graphGreenShade, false, 'right-side'),
    ];

    const propDamageBarDatasets = [
        generateCommonDatasets('bar', 'Property Damages', propDamageData, colorPalleteData.graphYellowShade, true, 'left-side'),
    ];

    const propDamageLineDatasets = [
        generateCommonDatasets('line', 'Property Damages', calculateCumulativeTrendLine('property damage', propDamageData), colorPalleteData.graphYellowShade, false, 'right-side'),
    ];

    const vandalBarDatasets = [
        generateCommonDatasets('bar', 'Vandalism/Theft', vandalData, colorPalleteData.graphPurpleShade, true, 'left-side'),
    ];

    const vandalLineDatasets = [
        generateCommonDatasets('line', 'Vandalism/Theft', calculateCumulativeTrendLine('vandalism', vandalData), colorPalleteData.graphPurpleShade, false, 'right-side'),
    ];

    let recordableDatasetArr = recordableFilteredArrayBars;

    let firstAidDatasetArr = firstAidFilteredArrayBars;

    let lostTimeDatasetArr = lostTimeFilteredArrayBars;

    useEffect(() => {
        ReactGA.event({
            category: 'Safety PageView',
            action: 'Page Loaded',
            label: 'SafetyPage - ' + localStorage.getItem('name'),
        });
        setIndicators(indicatorsData);
    }, []);

    useEffect(() => {
        getDashboardCount(1);
        getLeadingIndicatorGraphData(1);
        getInjuryGraphData('Recordable Injury', 1);
        getInjuryGraphData('First Aid Injury', 1);
        getInjuryGraphData('Lost Time Injury', 1);
        getSingleBarChartData('Fatality', 1);
        getHorizontalTypeInjuryGraphData('Recordable Injury', 1);
        getHorizontalTypeInjuryGraphData('First Aid Injury', 1);
        getImpactedBodyParts('First Aid Injury', 1);
        getImpactedBodyParts('Recordable Injury', 1);
        getSingleBarChartData('Fire', 1);
        getSingleBarChartData('Vehicle Accident', 1);
        getSingleBarChartData('Property Damage', 1);
        getSingleBarChartData('Vandalism/Theft', 1);
        getSelectedLeadingDataCharts();
        getSelectedLaggingDataCharts();
    }, []);

    useEffect(() => {

        if (selectedGraphTypeFilter === 'Recordable Injury') {

            if (recordableInjurySelectedEmpIds.length === 1 && recordableInjurySelectedEmpIds[0] === 1) {
                recordableDatasetArr = recordableDatasetArr.filter(item => item.label !== 'Contractor' && item.label !== 'Combined');
            }

            if (recordableInjurySelectedEmpIds.length === 1 && recordableInjurySelectedEmpIds[0] === 2) {
                recordableDatasetArr = recordableDatasetArr.filter(item => item.label !== 'Employee' && item.label !== 'Combined');
            }

            if (recordableInjurySelectedEmpIds.length === 1 && recordableInjurySelectedEmpIds[0] === 3) {
                recordableDatasetArr = recordableDatasetArr.filter(item => item.label !== 'Employee' && item.label !== 'Contractor');
            }

            if (recordableInjurySelectedEmpIds.length === 2 && recordableInjurySelectedEmpIds.includes(1) && recordableInjurySelectedEmpIds.includes(2)) {
                recordableDatasetArr = recordableDatasetArr.filter(item => item.label !== 'Combined');
            }

            if (recordableInjurySelectedEmpIds.length === 2 && recordableInjurySelectedEmpIds.includes(2) && recordableInjurySelectedEmpIds.includes(3)) {
                recordableDatasetArr = recordableDatasetArr.filter(item => item.label !== 'Employee');
            }

            if (recordableInjurySelectedEmpIds.length === 2 && recordableInjurySelectedEmpIds.includes(1) && recordableInjurySelectedEmpIds.includes(3)) {
                recordableDatasetArr = recordableDatasetArr.filter(item => item.label !== 'Contractor');
            }

            if (recordableInjurySelectedEmpIds.length === 3 || recordableInjurySelectedEmpIds.length === 0) {
                recordableDatasetArr = recordableDatasetArr;
            }
            setRecordableDataset(recordableDatasetArr);
            setActualRecordableTypeData(recordableDatasetArr);
            setSelectedRecordableChart(3);
        }

        if (selectedGraphTypeFilter === 'First Aid Injury') {

            if (firstAidInjurySelectedEmpIds.length === 1 && firstAidInjurySelectedEmpIds[0] === 4) {
                firstAidDatasetArr = firstAidDatasetArr.filter(item => item.label !== 'Contractor' && item.label !== 'Combined');
            }

            if (firstAidInjurySelectedEmpIds.length === 1 && firstAidInjurySelectedEmpIds[0] === 5) {
                firstAidDatasetArr = firstAidDatasetArr.filter(item => item.label !== 'Employee' && item.label !== 'Combined');
            }

            if (firstAidInjurySelectedEmpIds.length === 1 && firstAidInjurySelectedEmpIds[0] === 6) {
                firstAidDatasetArr = firstAidDatasetArr.filter(item => item.label !== 'Employee' && item.label !== 'Contractor');
            }

            if (firstAidInjurySelectedEmpIds.length === 3 || firstAidInjurySelectedEmpIds.length === 0) {
                firstAidDatasetArr = firstAidDatasetArr;
            }

            if (firstAidInjurySelectedEmpIds.length === 2 && firstAidInjurySelectedEmpIds.includes(4) && firstAidInjurySelectedEmpIds.includes(5)) {
                firstAidDatasetArr = firstAidDatasetArr.filter(item => item.label !== 'Combined');
            }

            if (firstAidInjurySelectedEmpIds.length === 2 && firstAidInjurySelectedEmpIds.includes(5) && firstAidInjurySelectedEmpIds.includes(6)) {
                firstAidDatasetArr = firstAidDatasetArr.filter(item => item.label !== 'Employee');
            }

            if (firstAidInjurySelectedEmpIds.length === 2 && firstAidInjurySelectedEmpIds.includes(4) && firstAidInjurySelectedEmpIds.includes(6)) {
                firstAidDatasetArr = firstAidDatasetArr.filter(item => item.label !== 'Contractor');
            }
            setFirstAidDataset(firstAidDatasetArr);
            setActualFirstAidTypeData(firstAidDatasetArr);
            setSelectedFirstAidChart(3);
        }



        if (selectedGraphTypeFilter === 'Lost Time Injury') {

            if (lostTimeInjurySelectedEmpIds.length === 1 && lostTimeInjurySelectedEmpIds[0] === 7) {
                lostTimeDatasetArr = lostTimeDatasetArr.filter(item => item.label !== 'Contractor' && item.label !== 'Combined');
            }

            if (lostTimeInjurySelectedEmpIds.length === 1 && lostTimeInjurySelectedEmpIds[0] === 8) {
                lostTimeDatasetArr = lostTimeDatasetArr.filter(item => item.label !== 'Employee' && item.label !== 'Combined');
            }

            if (lostTimeInjurySelectedEmpIds.length === 1 && lostTimeInjurySelectedEmpIds[0] === 9) {
                lostTimeDatasetArr = lostTimeDatasetArr.filter(item => item.label !== 'Employee' && item.label !== 'Contractor');
            }

            if (lostTimeInjurySelectedEmpIds.length === 3 || lostTimeInjurySelectedEmpIds.length === 0) {
                lostTimeDatasetArr = lostTimeDatasetArr;
            }

            if (lostTimeInjurySelectedEmpIds.length === 2 && lostTimeInjurySelectedEmpIds.includes(7) && lostTimeInjurySelectedEmpIds.includes(8)) {
                lostTimeDatasetArr = lostTimeDatasetArr.filter(item => item.label !== 'Combined');
            }

            if (lostTimeInjurySelectedEmpIds.length === 2 && lostTimeInjurySelectedEmpIds.includes(8) && lostTimeInjurySelectedEmpIds.includes(9)) {
                lostTimeDatasetArr = lostTimeDatasetArr.filter(item => item.label !== 'Employee');
            }

            if (lostTimeInjurySelectedEmpIds.length === 2 && lostTimeInjurySelectedEmpIds.includes(7) && lostTimeInjurySelectedEmpIds.includes(9)) {
                lostTimeDatasetArr = lostTimeDatasetArr.filter(item => item.label !== 'Contractor');
            }
            setLostTimeDataset(lostTimeDatasetArr);
            setActualLostTimeTypeData(lostTimeDatasetArr);
            setSelectedLostTimeChart(3);
        }

    }, [recordableInjuryGraphData, firstAidInjuryGraphData, lostTimeInjuryGraphData,
        recordableChartType, firstAidChartType, lostTimeChartType]);

    const onFilter = (categoryValue) => {
        setGlobalFilterDuration(categoryValue);
        categoryValue = categoryValue ? categoryValue : 1;
        hideElement();
        if (categoryValue) {
            // setLoading(true);
            getDashboardCount(categoryValue);
            getLeadingIndicatorGraphData(categoryValue);
            getInjuryGraphData('Recordable Injury', categoryValue);
            getInjuryGraphData('First Aid Injury', categoryValue);
            getInjuryGraphData('Lost Time Injury', categoryValue);
            getSingleBarChartData('Fatality', categoryValue);
            getHorizontalTypeInjuryGraphData('Recordable Injury', categoryValue);
            getHorizontalTypeInjuryGraphData('First Aid Injury', categoryValue);
            getImpactedBodyParts('First Aid Injury', categoryValue);
            getImpactedBodyParts('Recordable Injury', categoryValue);
            getSingleBarChartData('Fire', categoryValue);
            getSingleBarChartData('Vehicle Accident', categoryValue);
            getSingleBarChartData('Property Damage', categoryValue);
            getSingleBarChartData('Vandalism/Theft', categoryValue);
            getSelectedLeadingDataCharts();
            getSelectedLaggingDataCharts();
        }
        else {
            hideElement();
        }
    };

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
        const element3 = document.getElementById('dropdownHidden3');
        if (element3) {
            element3.classList.remove('show');
        }
    }

    useLayoutEffect(() => {

        if (selectedGraphTypeFilter === 'Recordable Injury') {
            let filteredArray = [...recordableInjuryGraphData];
            setRecordableInjuryGraphData(filteredArray);
        }

        if (selectedGraphTypeFilter === 'First Aid Injury') {
            let filteredArray = [...firstAidInjuryGraphData];
            setFirstAidInjuryGraphData(filteredArray);
        }

        if (selectedGraphTypeFilter === 'Lost Time Injury') {
            let filteredArray = [...lostTimeInjuryGraphData];
            setLostTimeInjuryGraphData(filteredArray);
        }

    }, [recordableInjurySelectedEmpIds, firstAidInjurySelectedEmpIds, lostTimeInjurySelectedEmpIds]);

    const transformDashCount = (res) => {
        const transformData = res?.length > 0 && res?.map((item, index) => {
            const icon = data?.DashboardCount.find((dItem) => dItem.name === item.incident_type)
            return {
                ...item,
                icon: icon.icon
            }
        })
        setFinalArr(transformData);
    }

    const getSelectedEmployeeIds = (data, name) => {

        setSelectedGraphTypeFilter(name);
        if (name === 'Recordable Injury') {
            setRecordableInjurySelectedEmpIds(data);
        }

        if (name === 'First Aid Injury') {
            setFirstAidInjurySelectedEmpIds(data);
        }

        if (name === 'Lost Time Injury') {
            setLostTimeInjurySelectedEmpIds(data);
        }
    }

    const getDashboardCount = (filterDuration) => {
        let body = {
            choose_duration: filterDuration,
        }
        // setLoading(true);
        Post(safetyDashboardCount, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setDashboardCountObj(data.result);
                    transformDashCount(data.result);
                    setLoading(false);
                }
            });
    };

    const calculateNearestTwenty = (value, key) => {
        const maxCombinedCount = value.reduce((max, item) => {
            return item[key] > max ? item[key] : max;
        }, 0);
        const nearestMultipleOf20 = Math.ceil(maxCombinedCount / 20) * 20;
        return nearestMultipleOf20;
    }

    const calculateNearestTwentyFive = (value) => {
        const combinedValues = value.reduce(
            (acc, obj) => acc.concat(obj.near_miss, obj.observation),
            []
        );

        const maxCombinedValue = Math.max(...combinedValues);
        const nearestMultipleOf25 = Math.ceil(maxCombinedValue / 25) * 25;
        return nearestMultipleOf25;
    }

    function nearestMultipleOf5000(amount) {
        const multiple = 5000;
        const nearestMultiple = Math.ceil(amount / multiple) * multiple;
        return nearestMultiple;
    }

    function nearestMultipleOf20(amount) {
        const multiple = 20;
        const nearestMultiple = Math.ceil(amount / multiple) * multiple;
        return nearestMultiple;
    }

    const getInjuryGraphData = (injury, filterDuration) => {
        let body = {
            incidentType: injury,
            choose_duration: filterDuration,
        }
        // setLoading(true);
        Post(injuryGraphData, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    let transformData = data?.result;
                    if (filterDuration === 2) {
                        transformData = transformData.map(item => {
                            return {
                                ...item,
                                "year": `Q${item.period}, ${item.year}`,
                            };
                        });
                    }
                    if (filterDuration === 3) {
                        transformData = transformData.map(item => {
                            const abbreviatedMonth = monthNames[parseInt(item.period) - 1];
                            return {
                                ...item,
                                "year": `${abbreviatedMonth}, ${item.year}`,
                            };
                        });
                    }
                    if (injury === 'First Aid Injury') {
                        const allValuesAreZero = transformData.every(item => item.employee_count === 0 && item.contractor_count === 0 && item.combined_count === 0);
                        if (allValuesAreZero) {
                            setShowFirstAidGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowFirstAidGraph(true);
                        }
                        
                        setFirstAidInjuryNearestTwenty(calculateNearestTwenty(transformData, 'combined_count'));

                        
                        // setFirstAidFilteredArrayBars(transformData);
                        setFirstAidInjuryGraphData(transformData);
                        let firstAidEmpData = transformData.map(ele => { return ele.employee_count });
                        let firstAidContData = transformData.map(ele => { return ele.contractor_count });
                        let firstAidCombData = transformData.map(ele => { return ele.combined_count });

                        const firstAidSumEmpData = firstAidEmpData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const firstAidSumContData = firstAidContData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const firstAidSumCombData = firstAidCombData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                        const firstAidMax = Math.max(firstAidSumEmpData, firstAidSumContData, firstAidSumCombData);

                        let maxfirstAidInjuryRate = Math.max(firstAidMax);
                        let firstTimeGraphLimit = nearestMultipleOf20(maxfirstAidInjuryRate);
                        setFirstAidTrendlineLimit(firstTimeGraphLimit);

                        setFirstAidEmpData(firstAidEmpData);
                        setFirstAidContData(firstAidContData);
                        setFirstAidCombData(firstAidCombData);

                    }
                    if (injury === 'Lost Time Injury') {
                        const allValuesAreZero = transformData.every(item => item.employee_count === 0 && item.contractor_count === 0 && item.combined_count === 0);
                        if (allValuesAreZero) {
                            setShowLostTimeGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowLostTimeGraph(true);
                        }
                        setLostTimeInjuryNearestFifty(calculateNearestTwenty(transformData, 'combined_count'));
                        // setLostTimeFilteredArrayBars(transformData);
                        setLostTimeInjuryGraphData(transformData);
                        let lostTimeEmpData = transformData.map(ele => { return ele.employee_count });
                        let lostTimeContData = transformData.map(ele => { return ele.contractor_count });
                        let lostTimeCombData = transformData.map(ele => { return ele.combined_count });
                        setLostTimeEmpData(lostTimeEmpData);
                        setLostTimeContData(lostTimeContData);
                        setLostTimeCombData(lostTimeCombData);

                        let lostTimeLtirEmpData = transformData.map(ele => { return ele.injuryrate_employee });
                        let lostTimeLtirContData = transformData.map(ele => { return ele.injuryrate_contractor });
                        let lostTimeLtirCombData = transformData.map(ele => { return ele.injuryrate_combined });

                        const lostTimeSumEmpData = lostTimeLtirEmpData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const lostTimeSumContData = lostTimeLtirContData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const lostTimeSumCombData = lostTimeLtirCombData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                        const lostTimeMax = Math.max(lostTimeSumEmpData, lostTimeSumContData, lostTimeSumCombData);

                        let maxLostTimeInjuryRate = Math.max(lostTimeMax);
                        let lostTimeGraphLimit = nearestMultipleOf5000(maxLostTimeInjuryRate);
                        setLtirLimit(lostTimeGraphLimit);

                        setLostTimeLtirEmpData(lostTimeLtirEmpData);
                        setLostTimeLtirContData(lostTimeLtirContData);
                        setLostTimeLtirCombData(lostTimeLtirCombData);

                    }
                    if (injury === 'Recordable Injury') {
                        const allValuesAreZero = transformData.every(item => item.employee_count === 0 && item.contractor_count === 0 && item.combined_count === 0);
                        if (allValuesAreZero) {
                            setShowRecordableGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowRecordableGraph(true);
                        }
                        setRecordableInjuryNearestFifty(calculateNearestTwenty(transformData, 'combined_count'));
                        // setRecordableFilteredArrayBars(transformData);
                        setRecordableInjuryGraphData(transformData);
                        let recordableEmpData = transformData.map(ele => { return ele.employee_count });
                        let recordableContData = transformData.map(ele => { return ele.contractor_count });
                        let recordableCombData = transformData.map(ele => { return ele.combined_count });

                        let recordableTrirEmpData = transformData.map(ele => { return ele.injuryrate_employee });
                        let recordableTrirContData = transformData.map(ele => { return ele.injuryrate_contractor });
                        let recordableTrirCombData = transformData.map(ele => { return ele.injuryrate_combined });

                        const recordableSumEmpData = recordableTrirEmpData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const recordableSumContData = recordableTrirContData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        const recordableSumCombData = recordableTrirCombData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                        const recordableMax = Math.max(recordableSumEmpData, recordableSumContData, recordableSumCombData);

                        let recordableGraphLimit = nearestMultipleOf5000(recordableMax);
                        setTrirLimit(recordableGraphLimit);

                        setRecordableTrirEmpData(recordableTrirEmpData);
                        setRecordableTrirContData(recordableTrirContData);
                        setRecordableTrirCombData(recordableTrirCombData);

                        setRecordableEmpData(recordableEmpData);
                        setRecordableContData(recordableContData);
                        setRecordableCombData(recordableCombData);

                    }
                    setLoading(false);
                }
            });
    }

    const getImpactedBodyParts = (injury, filterDuration) => {
        let body = {
            incidentType: injury,
            choose_duration: filterDuration,
        }
        // setLoading(true);
        Post(impactedBodyParts, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    if (injury === 'First Aid Injury') {
                        let result = data.result;
                        const valueMoreThanZero = data.result.some(item => parseFloat(item.body_part_per) > 0);
                        const newArray = result.map((obj) => ({
                            ...obj,  // Copy existing key-value pairs
                            legend_color: assignColor(obj.body_part)
                        }));
                        setFirstAidPieChartData(newArray);
                        setShowFirstAidPieTable(valueMoreThanZero);
                    }
                    if (injury === 'Recordable Injury') {
                        let result = data.result;
                        const valueMoreThanZero = data.result.some(item => parseFloat(item.body_part_per) > 0);
                        const newArray = result.map((obj) => ({
                            ...obj,  // Copy existing key-value pairs
                            legend_color: assignColor(obj.body_part)
                        }));
                        setRecordablePieChartData(newArray);
                        setShowRecordablePieTable(valueMoreThanZero);
                    }
                    setLoading(false);
                }
            });
    }


    const capitalizeFirstLetter = (str) => {
        return str.replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const getHorizontalTypeInjuryGraphData = (injury, filterDuration) => {
        let body = {
            incidentType: injury,
            choose_duration: filterDuration,
        }
        // setLoading(true);
        Post(horizontalTypeInjuryChartData, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    if (injury === 'Recordable Injury') {
                        let transformData = data.result;
                        const transformedData = Object.keys(transformData[0]).map(key => ({
                            incident_type: capitalizeFirstLetter(key.replace(/_/g, ' ')),
                            incident_count: transformData[0][key],
                            // background_color: (transformData[0][key] >= 0 && transformData[0][key] <= 2 ) ? colorPalleteData.graphGreenShade : ( (transformData[0][key] >= 2 && transformData[0][key] <= 5) ? colorPalleteData.graphPurpleShade : colorPalleteData.graphYellowShade),
                            // label_value: (transformData[0][key] >= 0 && transformData[0][key] <= 2 ) ? 'Under Control' : ( (transformData[0][key] >= 2 && transformData[0][key] <= 5) ? 'Moderate' : 'Need Urgent Attention')
                        }));
                        const maxIncidentCount = transformedData.reduce((max, obj) => (obj.incident_count > max ? obj.incident_count : max), transformedData[0].incident_count);
                        const newArrayWithAdditionalKey = transformedData.map(obj => ({
                            ...obj,
                            background_color: obj.incident_count === maxIncidentCount ? colorPalleteData.graphYellowShade : colorPalleteData.graphPurpleShade
                        }));
                        setRecordableHorizontalData(newArrayWithAdditionalKey);
                    }
                    if (injury === 'First Aid Injury') {
                        let transformData = data.result;
                        const transformedData = Object.keys(transformData[0]).map(key => ({
                            incident_type: capitalizeFirstLetter(key.replace(/_/g, ' ')),
                            incident_count: transformData[0][key],
                            background_color: (transformData[0][key] >= 0 && transformData[0][key] <= 2) ? colorPalleteData.graphGreenShade : ((transformData[0][key] >= 2 && transformData[0][key] <= 5) ? colorPalleteData.graphPurpleShade : colorPalleteData.graphYellowShade),
                            // label_value: (transformData[0][key] >= 0 && transformData[0][key] <= 2 ) ? 'Under Control' : ( (transformData[0][key] >= 2 && transformData[0][key] <= 5) ? 'Moderate' : 'Need Urgent Attention')
                        }));
                        const maxIncidentCount = transformedData.reduce((max, obj) => (obj.incident_count > max ? obj.incident_count : max), transformedData[0].incident_count);
                        const newArrayWithAdditionalKey = transformedData.map(obj => ({
                            ...obj,
                            background_color: obj.incident_count === maxIncidentCount ? colorPalleteData.graphYellowShade : colorPalleteData.graphPurpleShade
                        }));
                        setFirstAidHorizontalData(newArrayWithAdditionalKey);
                    }
                    setLoading(false);
                }
            });
    }

    const getSingleBarChartData = (incident, filterDuration) => {
        let body = {
            incidentType: incident,
            choose_duration: filterDuration,
        }
        // setLoading(true);
        Post(singleBarChartData, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    let transformData = data.result;
                    if (filterDuration === 2) {
                        transformData = transformData.map(item => {
                            return {
                                ...item,
                                "year": `Q${item.period}, ${item.year}`,
                            };
                        });
                    }
                    if (filterDuration === 3) {
                        transformData = transformData.map(item => {
                            const abbreviatedMonth = monthNames[parseInt(item.period) - 1];
                            return {
                                ...item,
                                "year": `${abbreviatedMonth}, ${item.year}`,
                            };
                        });
                    }
                    if (incident === 'Fatality') {
                        const allValuesAreZero = transformData.every(item => item.fatality_count === 0);
                        if (allValuesAreZero) {
                            setShowFatalityGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowFatalityGraph(true);
                        }
                        setFatalitiesNearestFifty(calculateNearestTwenty(transformData, 'fatality_count'));
                        setFatalitiesGraphData(transformData);
                        let fatalityData = transformData.map(ele => { return ele.fatality_count });

                        const fatalitySumData = fatalityData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            
                        let fatalityGraphLimit = nearestMultipleOf20(fatalitySumData);
                        setFatalityTrendlineLimit(fatalityGraphLimit);

                        setFatalityData(fatalityData);
                    }
                    if (incident === 'Fire') {
                        const allValuesAreZero = transformData.every(item => item.fire_count === 0);
                        if (allValuesAreZero) {
                            setShowFireGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowFireGraph(true);
                        }
                        setFireNearestFifty(calculateNearestTwenty(transformData, 'fire_count'));
                        setFireGraphData(transformData);
                        let fireData = transformData.map(ele => { return ele.fire_count });

                        const fireSumData = fireData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            
                        let fireGraphLimit = nearestMultipleOf20(fireSumData);
                        setFireTrendlineLimit(fireGraphLimit);

                        setFireData(fireData);
                    }
                    if (incident === 'Vehicle Accident') {
                        const allValuesAreZero = transformData.every(item => item.vehicle_accident_count === 0);
                        if (allValuesAreZero) {
                            setShowVehAccGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowVehAccGraph(true);
                        }
                        setVehicleAccidentNearestFifty(calculateNearestTwenty(transformData, 'vehicle_accident_count'));
                        setVehicleAccidentGraphData(transformData);
                        let vehAccData = transformData.map(ele => { return ele.vehicle_accident_count });

                        const vehAccSumData = vehAccData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            
                        let vehAccGraphLimit = nearestMultipleOf20(vehAccSumData);
                        setVehAccTrendlineLimit(vehAccGraphLimit);

                        setVehAccData(vehAccData);
                    }
                    if (incident === 'Vandalism/Theft') {
                        const allValuesAreZero = transformData.every(item => item.vandalism_theft_count === 0);
                        if (allValuesAreZero) {
                            setShowVandalismGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowVandalismGraph(true);
                        }
                        setVandalismNearestFifty(calculateNearestTwenty(transformData, 'vandalism_theft_count'));
                        setVandalismGraphData(transformData);
                        let vandalData = transformData.map(ele => { return ele.vandalism_theft_count });

                        const vandalSumData = vandalData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            
                        let vandalGraphLimit = nearestMultipleOf20(vandalSumData);
                        setVandalismTrendlineLimit(vandalGraphLimit);

                        setVandalData(vandalData);
                    }
                    if (incident === 'Property Damage') {
                        const allValuesAreZero = transformData.every(item => item.property_damage_count === 0);
                        if (allValuesAreZero) {
                            setShowPropDamageGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowPropDamageGraph(true);
                        }
                        setPropDamageNearestFifty(calculateNearestTwenty(transformData, 'property_damage_count'));
                        setPropertyDamageGraphData(transformData);
                        let propDamageData = transformData.map(ele => { return ele.property_damage_count });

                        const propDamageSumData = propDamageData?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            
                        let propDamageGraphLimit = nearestMultipleOf20(propDamageSumData);
                        setPropDamageTrendlineLimit(propDamageGraphLimit);

                        setPropDamageData(propDamageData);
                    }
                    setLoading(false);
                }
            });
    };

    const getLeadingIndicatorGraphData = (filterDuration) => {

        let body = {
            choose_duration: filterDuration,
        }
        // setLoading(true);
        Post(nearMissChartData, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    let transformData = data.result;
                    if (filterDuration === 2) {
                        transformData = transformData.map(item => {
                            return {
                                ...item,
                                "year": `Q${item.period}, ${item.year}`,
                            };
                        });
                    }
                    if (filterDuration === 3) {
                        transformData = transformData.map(item => {
                            const abbreviatedMonth = monthNames[parseInt(item.period) - 1];
                            return {
                                ...item,
                                "year": `${abbreviatedMonth}, ${item.year}`,
                            };
                        });
                    }
                    setNearMissNearestTwentyFive(calculateNearestTwentyFive(transformData));
                    setLeadingIndicatorData(transformData);
                    transformLeadingGraphData(transformData);
                    setLoading(false);
                }
            });
    }

    const transformLeadingGraphData = (data) => {
        const allValuesAreZero = data.every(item => item.near_miss === 0 && item.observation === 0);
        if (allValuesAreZero) {
            setShowNearMissGraph(false);
        }
        if (!allValuesAreZero) {
            setShowNearMissGraph(true);
        }
        let labelYearData = data.map(obj => obj.year);
        setLabelYearData(labelYearData);
        let nearMissData = data.map(obj => obj.near_miss);
        setNearMissData(nearMissData)
        let observationData = data.map(obj => obj.observation);
        setObservationData(observationData);

    }

    const assignColor = (bodyPart) => {
        switch (bodyPart) {
            case 'Head':
                return colorPalleteData.graphGreenShade;

            case 'Hand':
                return colorPalleteData.graphPurpleShade;

            case 'Foot':
                return colorPalleteData.graphYellowShade;

            case 'Arm':
                return colorPalleteData.darkBlueShade;

            case 'Leg':
                return colorPalleteData.darkGreenShade;

            case 'Trunk':
                return colorPalleteData.darkGreyShade;

            case 'Eye':
                return colorPalleteData.darkRedShade;

            default:
                return '#fff';
        }
    }

    const LeadingIndicatorChart = {
        labels: leadingIndicatorData && leadingIndicatorData.map(item => item.year),
        datasets: nearMissDataset,
    };

    const RecordableInjuryChart = {
        labels: recordableInjuryGraphData && recordableInjuryGraphData.map(item => item.year),
        datasets: recordableDataset,
    }

    const FirstTimeInjuryChart = {
        labels: firstAidInjuryGraphData && firstAidInjuryGraphData.map(item => item.year),
        datasets: firstAidDataset,
    }

    const LostTimeInjuryChart = {
        labels: lostTimeInjuryGraphData && lostTimeInjuryGraphData.map(item => item.year),
        datasets: lostTimeDataset,
    }

    const FatalitiesChart = {
        // labels: ['2018', '2019', '2020', '2021', '2022', '2023'],
        labels: fatalitiesGraphData && fatalitiesGraphData.map(item => item.year),
        datasets: fatalityDataset
    };

    const FireChart = {
        labels: fireGraphData && fireGraphData.map(item => item.year),
        datasets: fireGraphDataset
    };

    const VehicleAccidentChart = {
        labels: vehicleAccidentGraphData && vehicleAccidentGraphData.map(item => item.year),
        datasets: vehAccGraphDataset
    };

    const PropertyDamagechart = {
        labels: propertyDamageGraphData && propertyDamageGraphData.map(item => item.year),
        datasets: propDamageGraphDataset
    };

    const VandalismChart = {
        labels: vandalismGraphData && vandalismGraphData.map(item => item.year),
        datasets: vandalGraphDataset
    };

    const HorizontalRecordableChartData = {
        labels: recordableHorizontalData && recordableHorizontalData.map(item => item.incident_type),
        datasets: [
            {
                data: recordableHorizontalData && recordableHorizontalData.map(item => item.incident_count),
                // label: recordableHorizontalData && recordableHorizontalData.map(item => item.label_value),
                backgroundColor: recordableHorizontalData && recordableHorizontalData.map(item => item.background_color),
                borderColor: [
                    "rgba(0,0,0,0)",
                ],
                borderWidth: 1,
                borderRadius: 5,
            },
        ],
    }

    const HorizontalFirstAidChartData = {
        labels: firstAidHorizontalData && firstAidHorizontalData.map(item => item.incident_type),
        datasets: [
            {
                data: firstAidHorizontalData && firstAidHorizontalData.map(item => item.incident_count),
                // label: recordableHorizontalData && recordableHorizontalData.map(item => item.label_value),
                backgroundColor: firstAidHorizontalData && firstAidHorizontalData.map(item => item.background_color),
                borderColor: [
                    "rgba(0,0,0,0)",
                ],
                borderWidth: 1,
                borderRadius: 5,
            },
        ],
    }

    const RecordableImpactedBodyPartData = {
        labels: recordablePieChartData && recordablePieChartData.map(item => item.body_part),
        datasets: [
            {
                data: recordablePieChartData && recordablePieChartData.map(item => item.body_part_count),
                backgroundColor: [
                    colorPalleteData.graphGreenShade,
                    colorPalleteData.graphPurpleShade,
                    colorPalleteData.graphYellowShade,
                    colorPalleteData.darkBlueShade,
                    colorPalleteData.darkGreenShade,
                    colorPalleteData.darkGreyShade,
                    colorPalleteData.darkRedShade
                ],
                borderColor: [
                    colorPalleteData.graphGreenShade,
                    colorPalleteData.graphPurpleShade,
                    colorPalleteData.graphYellowShade,
                    colorPalleteData.darkBlueShade,
                    colorPalleteData.darkGreenShade,
                    colorPalleteData.darkGreyShade,
                    colorPalleteData.darkRedShade
                ],
                borderWidth: 1,
            },
        ],
    }

    const FirstAidImpactedBodyPartData = {
        labels: firstAidPieChartData && firstAidPieChartData.map(item => item.body_part),
        datasets: [
            {
                data: firstAidPieChartData && firstAidPieChartData.map(item => item.body_part_count),
                backgroundColor: [
                    colorPalleteData.graphGreenShade,
                    colorPalleteData.graphPurpleShade,
                    colorPalleteData.graphYellowShade,
                    colorPalleteData.darkBlueShade,
                    colorPalleteData.darkGreenShade,
                    colorPalleteData.darkGreyShade,
                    colorPalleteData.darkRedShade
                ],
                borderColor: [
                    colorPalleteData.graphGreenShade,
                    colorPalleteData.graphPurpleShade,
                    colorPalleteData.graphYellowShade,
                    colorPalleteData.darkBlueShade,
                    colorPalleteData.darkGreenShade,
                    colorPalleteData.darkGreyShade,
                    colorPalleteData.darkRedShade
                ],
                borderWidth: 1,
            },
        ],
    }

    const onSelect = (selectedList, selectedItem) => {
        // to be used in the future for the API Integration
    }

    const onRemove = (selectedList, removedItem) => {
        // to be used in the future for the API Integration
    }

    const handleExport = async () => {
        // setLoading(true);

        // Capture the entire window
        const body = document.body;
        const html = document.documentElement;

        const windowWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
        const windowHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        await html2canvas(document.body, { width: windowWidth, height: windowHeight }).then((canvas) => {
            // Convert the canvas to a data URL
            const screenshotData = canvas.toDataURL('image/png');

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = screenshotData;
            downloadLink.download = 'Screenshot.png';

            // Trigger the download
            downloadLink.click();
        });
        setLoading(false);
    }

    const handleDropdownSelect = (e) => {
        setFilterValue(e.filter_number);
    }

    const getSelectedLeadingDataCharts = () => {
        const selectedIndicators = leadingIndicators?.filter(indicator => indicator.checked).map(indicator => indicator.id)
        setSelectedLeadingGlobalFilters(selectedIndicators);
        setLeadingGraphList(selectedIndicators);
    }

    const getSelectedLaggingDataCharts = () => {
        const selectedIndicators = laggingIndicators?.filter(indicator => indicator.checked).map(indicator => indicator.id)
        setSelectedLaggingGlobalFilters(selectedIndicators);
        setGraphList(selectedIndicators);
    }

    const handleGlobalShow = () => {
        setInitialLeadingIndicator(leadingIndicators);
        setInitialGlobalLeadingFilter(selectedLeadingGlobalFilters);
        setInitialLaggingIndicator(laggingIndicators);
        setInitialGlobalLaggingFilter(selectedLaggingGlobalFilters);
        setGlobalModal(!globalModal);
    }

    const handleGlobalFilterClose = () => {
        if (!globalFilterApplied) {
            setTimeout(() => {
                setLeadingIndicators(initialLeadingIndicator);
                setLaggingIndicators(initialLaggingIndicator);
                setSelectedLeadingGlobalFilters(initialGlobalLeadingFilter);
                setSelectedLaggingGlobalFilters(initialGlobalLaggingFilter);
            }, 500)
        }
    }

    const selectAllGlobalFilters = () => {
        setGlobalModal(true);
        let selectedLeadingIndicators = leadingIndicators.map((item, index) => {
            return item.id
        })
        let allCheckedLeadingIndicators = leadingIndicators.map((item, index) => {
            return { ...item, checked: true }
        })
        let selectedLaggingIndicators = laggingIndicators.map((item, index) => {
            return item.id
        })
        let allCheckedLaggingIndicators = laggingIndicators.map((item, index) => {
            return { ...item, checked: true }
        })

        setLeadingIndicators(allCheckedLeadingIndicators);
        setLaggingIndicators(allCheckedLaggingIndicators);

        // setGraphList(selectedLaggingIndicators);
        // setLeadingGraphList(selectedLeadingIndicators);

        setSelectedLeadingGlobalFilters(selectedLeadingIndicators);
        setSelectedLaggingGlobalFilters(selectedLaggingIndicators);
        setGlobalFilterApplied(false);
    }

    const deselectAllGlobalFilters = () => {
        setGlobalModal(true);
        let selectedLeadingIndicators = leadingIndicators.map((item, index) => {
            return ''
        })
        let allCheckedLeadingIndicators = leadingIndicators.map((item, index) => {
            return { ...item, checked: false }
        })
        let selectedLaggingIndicators = laggingIndicators.map((item, index) => {
            return ''
        })
        let allCheckedLaggingIndicators = laggingIndicators.map((item, index) => {
            return { ...item, checked: false }
        })

        setLeadingIndicators(allCheckedLeadingIndicators)
        setLaggingIndicators(allCheckedLaggingIndicators);

        // setGraphList(selectedLaggingIndicators);
        // setLeadingGraphList(selectedLeadingIndicators);

        setSelectedLeadingGlobalFilters(selectedLeadingIndicators);
        setSelectedLaggingGlobalFilters(selectedLaggingIndicators);
        setGlobalFilterApplied(false);
    }

    const applyGlobalCustomization = () => {
        setGraphList(selectedLaggingGlobalFilters);
        setLeadingGraphList(selectedLeadingGlobalFilters);
        setGlobalFilterApplied(true);
        setResDetail('Filter applied successfully!');
        setAlertType('success');
        setSuccessRes(true);
        setGlobalModal(false);
        let body = {
            data: {
                leadingIndicators,
                laggingIndicators
            }
        }
        Post(customizeGraphToggle, token, body, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    localStorage.setItem('graphCustomization', JSON.stringify(data.data));
                }
            });
    }

    const onLeadingChange = (e) => {
        setGlobalModal(true);
        let itemArr = leadingIndicators && leadingIndicators.map((ele, index) => {
            if (ele.id === e.id) {
                return { ...ele, checked: !ele.checked }; // Toggle the checked property
            }
            return ele;
        });
        setLeadingIndicators(itemArr);
        const selectedIds = itemArr.filter(indicator => indicator.checked).map(indicator => indicator.id);
        setSelectedLeadingGlobalFilters(selectedIds);
        setGlobalFilterApplied(false);
        // setLeadingGraphList(selectedIds);
    }

    const onLaggingDataChange = (e) => {
        setGlobalModal(true);
        let itemArr = laggingIndicators && laggingIndicators.map((ele, index) => {
            if (ele.id === e.id) {
                return { ...ele, checked: !ele.checked }; // Toggle the checked property
            }
            return ele;
        });
        setLaggingIndicators(itemArr);
        const selectedIds = itemArr.filter(indicator => indicator.checked).map(indicator => indicator.id);
        // console.log('checkedItems', selectedIds);
        setSelectedLaggingGlobalFilters(selectedIds);
        setGlobalFilterApplied(false);
        // setGraphList(selectedIds);
    }

    const handleLocalCustomization = (e) => {
        // to be used in the future for the API Integration
    }

    const handleLocalResetCustomization = (e) => {
        // to be used in the future for the API Integration
    }

    const updateChartType = (graphName, type) => {

        switch (graphName) {
            case 'Fatalities':
                if (type === 1) {
                    setFatalityDataset(fatalityLineDatasets);
                }
                if (type === 2) {
                    setFatalityDataset(fatalityBarDatasets);
                }
                if (type === 3) {
                    const combinedDatasets = [
                        ...fatalityLineDatasets,
                        ...fatalityBarDatasets,
                    ];
                    setFatalityDataset(combinedDatasets);
                }
                setSelectedFatalityChart(type);
                break;
            case 'Fire':
                if (type === 1) {
                    setFireGraphDataset(fireLineDatasets);

                }
                if (type === 2) {
                    setFireGraphDataset(fireBarDatasets);

                }
                if (type === 3) {
                    const combinedDatasets = [
                        ...fireLineDatasets,
                        ...fireBarDatasets,
                    ];
                    setFireGraphDataset(combinedDatasets);
                }
                setSelectedFireChart(type);
                break;
            case 'Vehicle Accident':
                if (type === 1) {
                    setVehAccGraphDataset(vehAccLineDatasets);
                }
                if (type === 2) {
                    setVehAccGraphDataset(vehAccBarDatasets);

                }
                if (type === 3) {
                    const combinedDatasets = [
                        ...vehAccLineDatasets,
                        ...vehAccBarDatasets,
                    ];
                    setVehAccGraphDataset(combinedDatasets);
                }
                setSelectedVehAccChart(type);
                break;
            case 'Property Damage Incident':
                if (type === 1) {
                    setPropDamageGraphDataset(propDamageLineDatasets);

                }
                if (type === 2) {
                    setPropDamageGraphDataset(propDamageBarDatasets);

                }
                if (type === 3) {
                    const combinedDatasets = [
                        ...propDamageLineDatasets,
                        ...propDamageBarDatasets,
                    ];
                    setPropDamageGraphDataset(combinedDatasets);
                }
                setSelectedPropDamageChart(type);
                break;
            case 'Vandalism/Theft':
                if (type === 1) {
                    setVandalGraphDataset(vandalLineDatasets);

                }
                if (type === 2) {
                    setVandalGraphDataset(vandalBarDatasets);

                }
                if (type === 3) {
                    const combinedDatasets = [
                        ...vandalLineDatasets,
                        ...vandalBarDatasets,
                    ];
                    setVandalGraphDataset(combinedDatasets);
                }
                setSelectedVandalChart(type);
                break;
            case 'Near Miss and Observation':
                if (type === 1) {
                    setNearMissDataset(lineDatasets);
                }
                if (type === 2) {
                    setNearMissDataset(barDatasets);
                }
                if (type === 3) {
                    const combinedDatasets = [
                        ...lineDatasets,
                        ...barDatasets,
                    ];
                    setNearMissDataset(combinedDatasets);
                }
                setSelectedNearMissChart(type);
                break;
            case 'Recordable Injury':
                let recordableDatasetArray = actualRecordableTypeData;
                if (type === 1) {
                    recordableDatasetArray = recordableDatasetArray.filter(item => item.type === 'line');
                    setRecordableDataset(recordableDatasetArray);
                }
                if (type === 2) {
                    recordableDatasetArray = recordableDatasetArray.filter(item => item.type === 'bar');
                    setRecordableDataset(recordableDatasetArray);
                }
                if (type === 3) {
                    setRecordableDataset(recordableDatasetArray);
                }
                setSelectedRecordableChart(type);
                break;
            case 'First Aid Injury':
                let firstAidDatasetArray = actualFirstAidTypeData;
                if (type === 1) {
                    firstAidDatasetArray = firstAidDatasetArray.filter(item => item.type === 'line');
                    setFirstAidDataset(firstAidDatasetArray);
                }
                if (type === 2) {
                    firstAidDatasetArray = firstAidDatasetArray.filter(item => item.type === 'bar');
                    setFirstAidDataset(firstAidDatasetArray);
                }
                if (type === 3) {
                    setFirstAidDataset(firstAidDatasetArray);
                }
                setSelectedFirstAidChart(type);
                break;
            case 'Lost Time Injury':
                let lostTimeDatasetArray = actualLostTimeTypeData;
                if (type === 1) {
                    lostTimeDatasetArray = lostTimeDatasetArray.filter(item => item.type === 'line');
                    setLostTimeDataset(lostTimeDatasetArray);
                }
                if (type === 2) {
                    lostTimeDatasetArray = lostTimeDatasetArray.filter(item => item.type === 'bar');
                    setLostTimeDataset(lostTimeDatasetArray);
                }
                if (type === 3) {
                    setLostTimeDataset(lostTimeDatasetArray);
                }
                setSelectedLostTimeChart(type);
                break;
            default:
                break;
        }
    };

    const onFilterChartType = (type, graphName) => {
        updateChartType(graphName, type);
    };

    const dummyGraphArr = [
        {
            id: 'Recordable Injury',
            name: 'Recordable Injury',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: true,
            empDdnData: data.empDdnData,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            chartData: RecordableInjuryChart,
            chartOptions: safetyChartData.RecordableInjuryOptions(recordableInjuryNearestFifty, trirLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedRecordableChart,
            showGraph: showRecordableGraph
        },

        {
            id: 'Lost Time Injury',
            name: 'Lost Time Injury',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: true,
            empDdnData: data.empDdnData,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            chartData: LostTimeInjuryChart,
            chartOptions: safetyChartData.LostTimeInjuryOptions(lostTimeInjuryNearestFifty, ltirLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedLostTimeChart,
            showGraph: showLostTimeGraph,
        },

        {
            id: 'First Aid Injury',
            name: 'First Aid Injury',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: true,
            empDdnData: data.empDdnData,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            chartData: FirstTimeInjuryChart,
            chartOptions: safetyChartData.FirstTimeInjuryOptions(firstAidInjuryNearestTwenty, firstAidTrendlineLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedFirstAidChart,
            showGraph: showFirstAidGraph
        },

        {
            id: 'Fatalities',
            name: 'Fatalities',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: false,
            empDdnData: data.empDdnData,
            chartData: FatalitiesChart,
            chartOptions: safetyChartData.FatalitiesOptions(fatalitiesNearestFifty, fatalityTrendlineLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedFatalityChart,
            showGraph: showFatalityGraph
        },

        {
            id: 'Combined Horizontal Charts',
            chartArr: [
                {
                    id: 'Recordable Injury Types',
                    name: 'Recordable Injury Types',
                    graphType: 'HorizontalChart',
                    target: target,
                    overLayShow: leadShow,
                    setOverlayShow: setLeadShow,
                    handleDropdownSelect: handleDropdownSelect,
                    setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                    icon: infoIcon,
                    employeeDdn: false,
                    empDdnData: data.empDdnData,
                    chartData: HorizontalRecordableChartData,
                    chartOptions: safetyChartData.HorizontalRecordableOptions

                },

                {
                    id: 'First Aid Injury Types',
                    name: 'First Aid Injury Types',
                    graphType: 'HorizontalChart',
                    target: target,
                    overLayShow: leadShow,
                    setOverlayShow: setLeadShow,
                    handleDropdownSelect: handleDropdownSelect,
                    setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                    icon: infoIcon,
                    employeeDdn: false,
                    empDdnData: data.empDdnData,
                    chartData: HorizontalFirstAidChartData,
                    chartOptions: safetyChartData.HorizontalFirstAidOptions
                }]
        },

        {
            id: 'Combined Pie Charts',
            chartArr: [
                {
                    id: 'Impact on Body Part Due to Recordable',
                    name: 'Impact on Body Part Due to Recordable',
                    subName: 'Impact on Body Part',
                    graphType: 'PieChart',
                    target: target,
                    overLayShow: leadShow,
                    setOverlayShow: setLeadShow,
                    handleDropdownSelect: handleDropdownSelect,
                    setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                    icon: infoIcon,
                    employeeDdn: false,
                    empDdnData: data.empDdnData,
                    showTable: showRecordablePieTable,
                    chartData: RecordableImpactedBodyPartData,
                    chartOptions: safetyChartData.PieChartOptions,
                    pieChartData: recordablePieChartData,
                    subHeading: 'Due to Recordable Injury'
                },

                {
                    id: 'Impact on Body Part Due to First Aid',
                    name: 'Impact on Body Part Due to First Aid',
                    subName: 'Impact on Body Part',
                    graphType: 'PieChart',
                    target: target,
                    overLayShow: leadShow,
                    setOverlayShow: setLeadShow,
                    handleDropdownSelect: handleDropdownSelect,
                    setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                    icon: infoIcon,
                    employeeDdn: false,
                    empDdnData: data.empDdnData,
                    showTable: showFirstAidPieTable,
                    chartData: FirstAidImpactedBodyPartData,
                    chartOptions: safetyChartData.PieChartFirstAidOptions,
                    pieChartData: firstAidPieChartData,
                    subHeading: 'Due to First Aid Injury'
                }]
        },

        {
            id: 'Fire',
            name: 'Fire',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: false,
            empDdnData: data.empDdnData,
            chartData: FireChart,
            chartOptions: safetyChartData.FireOptions(fireNearestFifty, fireTrendlineLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedFireChart,
            showGraph: showFireGraph
        },

        {
            id: 'Vehicle Accident',
            name: 'Vehicle Accident',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: false,
            empDdnData: data.empDdnData,
            chartData: VehicleAccidentChart,
            chartOptions: safetyChartData.VehicleDamageOptions(vehicleAccidentNearestFifty, vehAccTrendlineLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedVehAccChart,
            showGraph: showVehAccGraph
        },

        {
            id: 'Property Damage Incident',
            name: 'Property Damage Incident',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: false,
            empDdnData: data.empDdnData,
            chartData: PropertyDamagechart,
            chartOptions: safetyChartData.PropertyDamageOptions(propDamageNearestFifty, propDamageTrendlineLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedPropDamageChart,
            showGraph: showPropDamageGraph
        },

        {
            id: 'Vandalism',
            name: 'Vandalism/Theft',
            graphType: 'BarChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            employeeDdn: false,
            empDdnData: data.empDdnData,
            chartData: VandalismChart,
            chartOptions: safetyChartData.VandalismOptions(vandalismNearestFifty, vandalismTrendlineLimit),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedVandalChart,
            showGraph: showVandalismGraph
        },
    ];

    const customizedLocalData = indicatorsData?.data?.dragData?.map((resp) => {
        if (resp?.id == 'Recordable Injury') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                empDdnData: data.empDdnData,
                onSelectSidebarIcon: getSelectedEmployeeIds,
                chartData: RecordableInjuryChart,
                chartOptions: safetyChartData.RecordableInjuryOptions(recordableInjuryNearestFifty, trirLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedRecordableChart,
                showGraph: showRecordableGraph
            }
        } else if (resp?.id == 'Lost Time Injury') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                empDdnData: data.empDdnData,
                onSelectSidebarIcon: getSelectedEmployeeIds,
                chartData: LostTimeInjuryChart,
                chartOptions: safetyChartData.LostTimeInjuryOptions(lostTimeInjuryNearestFifty, ltirLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedLostTimeChart,
                showGraph: showLostTimeGraph,
            }
        } else if (resp?.id == 'First Aid Injury') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                empDdnData: data.empDdnData,
                onSelectSidebarIcon: getSelectedEmployeeIds,
                chartData: FirstTimeInjuryChart,
                chartOptions: safetyChartData.FirstTimeInjuryOptions(firstAidInjuryNearestTwenty, firstAidTrendlineLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedFirstAidChart,
                showGraph: showFirstAidGraph
            }
        } else if (resp?.id == 'Fatalities') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                empDdnData: data.empDdnData,
                chartData: FatalitiesChart,
                chartOptions: safetyChartData.FatalitiesOptions(fatalitiesNearestFifty, fatalityTrendlineLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedFatalityChart,
                showGraph: showFatalityGraph
            }
        } else if (resp?.id == 'Combined Horizontal Charts' && resp?.chartArr[0]?.id == 'Recordable Injury Types') {
            while (resp?.chartArr.length > 0) {
                resp?.chartArr.pop();
            }
            // resp?.chartArr.length = 0
            // return {
            //     ...resp,
            //     graphType: 'HorizontalChart',
            //     overLayShow: leadShow,
            //     setOverlayShow: setLeadShow,
            //     handleDropdownSelect: handleDropdownSelect,
            //     setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            //     employeeDdn: false,
            //     empDdnData: data.empDdnData,
            //     chartData: HorizontalRecordableChartData,
            //     chartOptions: safetyChartData.HorizontalRecordableOptions,
            // }
            resp?.chartArr.push({
                id: 'Recordable Injury Types',
                name: 'Recordable Injury Types',
                graphType: 'HorizontalChart',
                target: target,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: HorizontalRecordableChartData,
                chartOptions: safetyChartData.HorizontalRecordableOptions
            })

            resp?.chartArr.push({
                id: 'First Aid Injury Types',
                name: 'First Aid Injury Types',
                graphType: 'HorizontalChart',
                target: target,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: HorizontalFirstAidChartData,
                chartOptions: safetyChartData.HorizontalFirstAidOptions
            })
            return { ...resp }
        } else if (resp?.id == 'Combined Horizontal Charts' && resp?.chartArr[1]?.id == 'First Aid Injury Types') {
            // return {
            //     ...resp,
            //     graphType: 'HorizontalChart',
            //     overLayShow: leadShow,
            //     setOverlayShow: setLeadShow,
            //     handleDropdownSelect: handleDropdownSelect,
            //     setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            //     employeeDdn: false,
            //     empDdnData: data.empDdnData,
            //     chartData: HorizontalFirstAidChartData,
            //     chartOptions: safetyChartData.HorizontalFirstAidOptions
            // }

            resp?.chartArr.push({
                id: 'First Aid Injury Types',
                name: 'First Aid Injury Types',
                graphType: 'HorizontalChart',
                target: target,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: HorizontalFirstAidChartData,
                chartOptions: safetyChartData.HorizontalFirstAidOptions
            })
            return { ...resp }
        } else if (resp?.id == 'Combined Pie Charts' && resp?.chartArr[0]?.id == 'Impact on Body Part Due to Recordable') {
            while (resp?.chartArr.length > 0) {
                resp?.chartArr.pop();
            }
            resp?.chartArr.push({
                id: 'Impact on Body Part Due to Recordable',
                name: 'Impact on Body Part Due to Recordable',
                subName: 'Impact on Body Part',
                graphType: 'PieChart',
                target: target,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                showTable: showRecordablePieTable,
                chartData: RecordableImpactedBodyPartData,
                chartOptions: safetyChartData.PieChartOptions,
                pieChartData: recordablePieChartData,
                subHeading: 'Due to Recordable Injury'
            })

            resp?.chartArr.push({
                id: 'Impact on Body Part Due to First Aid',
                name: 'Impact on Body Part Due to First Aid',
                subName: 'Impact on Body Part',
                graphType: 'PieChart',
                target: target,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                showTable: showFirstAidPieTable,
                chartData: FirstAidImpactedBodyPartData,
                chartOptions: safetyChartData.PieChartFirstAidOptions,
                pieChartData: firstAidPieChartData,
                subHeading: 'Due to First Aid Injury'
            })
            return { ...resp }
        } else if (resp?.id == 'Combined Pie Charts' && resp?.chartArr[1]?.id == 'Impact on Body Part Due to First Aid') {
            return {
                ...resp,
                subName: 'Impact on Body Part',
                graphType: 'PieChart',
                target: target,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                showTable: showFirstAidPieTable,
                chartData: FirstAidImpactedBodyPartData,
                chartOptions: safetyChartData.PieChartFirstAidOptions,
                pieChartData: firstAidPieChartData,
                subHeading: 'Due to First Aid Injury'
            }
        } else if (resp?.id == 'Vehicle Accident') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: VehicleAccidentChart,
                chartOptions: safetyChartData.VehicleDamageOptions(vehicleAccidentNearestFifty, vehAccTrendlineLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedVehAccChart,
                showGraph: showVehAccGraph
            }
        } else if (resp?.id == 'Fire') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: FireChart,
                chartOptions: safetyChartData.FireOptions(fireNearestFifty, fireTrendlineLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedFireChart,
                showGraph: showFireGraph
            }
        } else if (resp?.id == 'Property Damage Incident') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: PropertyDamagechart,
                chartOptions: safetyChartData.PropertyDamageOptions(propDamageNearestFifty, propDamageTrendlineLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedPropDamageChart,
                showGraph: showPropDamageGraph
            }
        } else if (resp?.id == 'Vandalism') {
            return {
                ...resp,
                overLayShow: leadShow,
                setOverlayShow: setLeadShow,
                handleDropdownSelect: handleDropdownSelect,
                setLocalCustomizationPopUp: setLocalCustomizationPopUp,
                icon: infoIcon,
                employeeDdn: false,
                empDdnData: data.empDdnData,
                chartData: VandalismChart,
                chartOptions: safetyChartData.VandalismOptions(vandalismNearestFifty, vandalismTrendlineLimit),
                onFilterChartType: onFilterChartType,
                selectedFilter: selectedVandalChart,
                showGraph: showVandalismGraph
            }
        }
    })


    const graphArr = indicatorsData?.data?.dragData ? customizedLocalData : dummyGraphArr;
    // const graphArr = dummyGraphArr;


    // const graphArr = dragData;

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <div className="full-cont">
        <div className="background-F8F8FA">
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={resDetail} setVisibility={setVisibility} />}
            <div>
                <ModuleInfo moduleName={'Safety'} moduleDesc={''} reportButton={true} permissions={permissionsObj} />
            </div>
            <div>
                <div className="mt-5 px-3">
                    <div className="global-customize-container">
                        <div>
                            <p>Quick Stats</p>
                        </div>
                        <div>
                            <Button size="sm" className="btn-light margin-right-15 bg-white" onClick={handleGlobalShow}><img src={CustomizeButtonIcon} className="mx-1 my-1" />Customize</Button>
                            <Button size="sm" className="btn-light margin-right-15 bg-white" onClick={handleExport}><img src={ExportButtonIcon} className="mx-1 my-1" />Export</Button>
                            <div className="dropdown">
                                <button className={`btn dropdown-toggle ${globalFilterDuration ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Choose Duration
                                    <img className="ml-8" src={globalFilterDuration ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                                </button>
                                <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                                    <a className="dropdown-item cursor" onClick={(e) => { onFilter(1) }}>
                                        <div className="">
                                            {globalFilterDuration == 1 ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${globalFilterDuration == 1 ? 'selected-filter-color ml-8' : ''}`}>Yearly</label>
                                        </div>
                                    </a>
                                    <a className="dropdown-item cursor" onClick={(e) => { onFilter(2) }}>
                                        <div className="">
                                            {globalFilterDuration == 2 ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${globalFilterDuration == 2 ? 'selected-filter-color ml-8' : ''}`}>Quarterly</label>
                                        </div>
                                    </a>
                                    <a className="dropdown-item cursor" onClick={(e) => { onFilter(3) }}>
                                        <div className="">
                                            {globalFilterDuration == 3 ? <img src={FilterTickIcon} /> : ''}
                                            <label className={` cursor ${globalFilterDuration == 3 ? 'selected-filter-color ml-8' : ''}`}>Monthly</label>

                                        </div>
                                    </a>
                                    <div className="mt-2 float-right">
                                        <button type="button" className="btn-clear" onClick={(e) => { onFilter(1) }}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-tab-container">
                    <DashboardCount data={finalArr} filterDuration={globalFilterDuration} type='Safety' />
                </div>

                {leadingGraphList && leadingGraphList?.includes('Near Miss and Observation') && <div className="header-tab-container mt-5">
                    <p className="heading-font padding-class">Leading Data Indicators</p>
                </div>}

                {leadingGraphList && leadingGraphList?.includes('Near Miss and Observation') && showNearMissGraph &&
                    <div className="near-miss-graph-container">
                        <GraphTopBar
                            graphName={'Near Miss and Observation'}
                            graphType={'BarChart'}
                            trendData={DropDownData.GraphTypes}
                            target={target}
                            overLayShow={leadShow}
                            setOverlayShow={setLeadShow}
                            handleDropdownSelect={handleDropdownSelect}
                            setLocalCustomizationPopUp={setLocalCustomizationPopUp}
                            icon={infoIcon}
                            employeeDdn={false}
                            empDdnData={data.EmpDdnData}
                            onFilterChartType={onFilterChartType}
                            selectedFilter={selectedNearMissChart} />

                        {/* Leading data indicator chart */}
                        <BarChart
                            data={LeadingIndicatorChart}
                            options={safetyChartData.LeadingIndicatorOptions(nearMissNearestTwentyFive, nearMissTrendlineLimit)} />
                    </div>}
                {!showNearMissGraph && <p className="no-data-graph-container" style={{ width: '94%' }}>No Recorded Data Found for Leading Data Indicators</p>}
                {/* {console.log('graph list', graphList, 'selected global leading indicators', selectedLaggingGlobalFilters)}; */}
                {graphList && graphList.some(item => item.trim() !== '') && <div className="header-tab-container mt-5">
                    <p className="heading-font padding-class">Lagging Data Indicators</p>
                </div>}

                {graphList && !graphList.some(item => item.trim()) && (!leadingGraphList?.includes('Near Miss and Observation')) && <p className="py-4 my-2 heading-font text-center pt-20 pb-20">No Graph Selected</p>}

                <Drag graphArr={graphArr} graphList={graphList} />



                <Modal show={globalModal} onHide={() => setGlobalModal(!globalModal)}>
                    <Modal.Header closeButton onClick={() => handleGlobalFilterClose()}>
                        <Modal.Title className="pop-up-heading">I want to view below charts on my Dashboard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pad-bottom-0">
                        <Row className="">
                            <Col sm={12} className="text-right">
                                <button className="btn-select-all" onClick={() => selectAllGlobalFilters()}>Select All
                                </button>
                            </Col>
                            <Col sm={12} className="margin-bottom-20">
                                <span className="pop-up-body-heading">Leading Data Indicators</span>
                            </Col>
                        </Row>
                        <div className="height-185">
                            <Row>
                                <Col md={12}>
                                    <CheckboxSelect index={1} data={leadingIndicators} checkedValues={selectedLeadingGlobalFilters} colSize={6} onChange={onLeadingChange} />
                                </Col>
                            </Row>
                            <hr className="mt-4" />
                            <p className="pop-up-body-heading pt-1 margin-bottom-20">Lagging Data Indicators</p>
                            <Row>
                                <Col md={12}>
                                    <CheckboxSelect index={2} data={laggingIndicators} colSize={6} checkedValues={selectedLaggingGlobalFilters} onChange={onLaggingDataChange} />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="padding-top-0 move-right">
                        <button className="btn-add-data mr-15 mt-4 border-0" onClick={() => deselectAllGlobalFilters()}>Reset
                        </button>
                        <button className='report_btn border-0 mt-4' onClick={applyGlobalCustomization}>Customize
                        </button>
                    </Modal.Footer>
                </Modal>


                <Modal show={handleLocalCutomizationPopUp} onHide={() => setLocalCustomizationPopUp(!handleLocalCutomizationPopUp)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="pop-up-heading">Compare incident data for</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TabContainerMenu states={data.States}
                            quarters={data.Quarters}
                            months={DropDownData.Months}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            setFromDate={setFromDate}
                            setToDate={setToDate} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={handleLocalResetCustomization}>
                            Reset
                        </Button>
                        <Button variant="success" onClick={handleLocalCustomization}>
                            Customize
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
        </div>
    )
}

export default Dashboard;