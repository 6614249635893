import React, { useState } from "react";
import { Modal } from 'antd';
import mockData from '../../../mockData/mockData';
import Select from 'react-select';
import './RecordableIncidentsModal.css';
import BtnCrossIcon from '../../../../src/assets/images/FormImages/btnCross.svg';

const RecordableIncidentsModal = ({ open, showModal }) => {
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [tabPosition, setTabPosition] = useState('left');
    const [incidentValue, setIncidentValue] = useState("");
    const changeTabPosition = (e) => {
        setTabPosition(e.target.value);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            showModal(!open)
        }, 3000);
    };

    const handleCancel = () => {
        showModal(false);
    };

    return (
            <Modal
                title="Compare Recordable Injury type"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width={800}
                closeIcon={<img src={BtnCrossIcon}/>}
                className="modalStyle"
                bodyStyle={{ height: 350, border: '1px solid #F2F2F4', borderRadius: '5px', fontFamily: 'Montserrat' }}
                footer={[
                    <button type="button" className='report_btn mr-15 border-0'>Customize</button>,
                ]}
            >
                <div className="padding-20">
                    <div className="row">
                        <p className="tab-heading-font">Select the duration for comparison</p>
                        <p className="pb-3">You can choose upto three years</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="tab-from-to-heading-font">From</p>
                          
                        </div>
                        <div className="col-md-6">
                            <p className="tab-from-to-heading-font">To</p>
                         
                        </div>
                    </div>
                    <div className="row">
                        <p className="tab-heading-font pt-4">Choose the Injury for comparison</p>
                        <Select classNamePrefix="an-simple-select" placeholder='Choose the event' options={mockData.IncidentType} onChange={(incident) => setIncidentValue(incident)} />
                    </div>
                </div>

            </Modal>
    )
}

export default RecordableIncidentsModal;