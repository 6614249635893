import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import data from '../../../mockData/mockData';
import { Controller, useForm } from "react-hook-form";
import './IncidentDetails.css';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckboxSelect from '../../../components/formBuilder/CheckboxSelect';
import errorIcon from "../../../assets/images/error_icon.svg";
import { Post, Get } from "../../../constants/apiMethods";
import { reportEvent, updateEvent, getDataById, getFacilities, getStateListing } from "../../../constants/apiRoutes";
import LoadingSpinner from '../../../components/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { useDispatch, useSelector } from 'react-redux';
import { addIncident } from '../../../store/slice/incidentSlice';
import ReactGA from "react-ga4";

function IncidentDetails({
    incidentObjVal,
    incidentArrData,
    incidentUpdateId,
    next,
    editMode,
    imageData
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedInUserName = localStorage.getItem('name');
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem('access_token');
    const [impactedValue, setImpactedValue] = useState("");
    const [otherLocationValue, setOtherLocationValue] = useState("");
    const [alertType, setAlertType] = useState('');
    const [draftMode, setDraftMode] = useState(false);
    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinutes, setSelectedMinutes] = useState('');
    const [selectedMeridian, setSelectedMeridian] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedIncidentType, setSelectedIncidentType] = useState('');
    const [selectedPersonImpacted, setSelectedPersonImpacted] = useState('');
    const [selectedJobTitle, setSelectedJobTitle] = useState('');
    const [selectedInjury, setSelectedInjury] = useState('');
    const [selectedBodyParts, setSelectedBodyParts] = useState('');
    const [incidentId, setIncidentId] = useState('');
    const [date, setDate] = useState(null);
    const [facilities, setFacilities] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [initialSubmission, setInitialSubmission] = useState(true);
    const [initiallyReportedBy, setInitiallyReportedBy] = useState('');
    const [stateId, setStateId] = useState('');
    const [checkIfOtherLocationIsRequired, setcheckIfOtherLocationIsRequired] = useState(false);
    const [incidentValidation, setIncidentValidation] = useState(true);
    const [isDraft, setIsDraft] = useState();
    const [incidentObj, setIncidentObj] = useState({
        // "id": imageUserId,
        "userid": 1,
        "type": 'incident',
        "draft": draftMode ? 1 : 0,
        "formdata": {
            "date": '',
            "reportedby": "",
            "updatedby": "",
            "name": '',
            "location": '',
            "state": '',
            "hour": '',
            "minutes": '',
            "meridian": '',
        },
        "incidentdata": [{
            incidentType: '',
            "affectedBodyParts": [],
            "injuryType": '',
            "personImpacted": '',
            "jobTitle": '',
        }],
    })

    useEffect(() => {
        getStateList();
        editMode && getDetailsById();
        editMode && getFacilityList();
        editMode && setIncidentObj({ ...incidentObjVal, incidentdata: incidentArrData });
        editMode && patchIncidentArrObj(incidentArrData);
        editMode && setIncidentId(incidentUpdateId);
    }, [incidentObjVal]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues,
        control,
        trigger
    } = useForm();

    const getStateList = () => {
        setLoading(true);
        Get(getStateListing, token, `?sort=name`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    const stateData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    // props.editMode && getManHourById(stateData, 'State');
                    setStateList(stateData);
                }
            });
    }

    const getFacilityList = () => {
        getLocationById(stateId);
    }

    const getLocationById = (id) => {
        setLoading(true);
        Get(getFacilities, token, `?min=${true}&state=${id}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(true);
                let transformData = data;
                let updatedData = transformData.map(item => ({
                    ...item,
                    value: `${item.facility_name}`,
                    label: `${item.facility_name}`
                }));
                const otherLocationObject = { id: null, facility_name: 'Others', value: 'Others', label: 'Others' };
                const newArray = [otherLocationObject, ...updatedData];
                setFacilities(newArray);
                // setFacilities(updatedData);


                setLoading(false);
                // }
            });
    }


    const handleDraft = () => {
        setDraftMode(true);
    }

    const handleProceed = () => {
        setDraftMode(false);
    }

    const onImpactedChange = (e) => {
        setImpactedValue(e.target.value);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    // if (dataFromRedux[0]?.step_one_submitted && dataFromRedux[1]?.step === 2) {
    //     return setStepOneData(dataFromRedux[0]);
    // }


    const onRadioChange = (e) => {
        setIncidentObj({ ...incidentObj, [e.target.name]: e.target.value });
        setValue(e.target.name, e.target.value);
        trigger(e.target.name);
        setSelectedPersonImpacted(e.target.value);
        // dispatch(addIncident({ payload: { [e.target.name]: e.target.value }, index: 0 }));
    }

    const onCheckboxChange = (e) => {
        const id = e.id;
        console.log(incidentObj)
        let itemArr = incidentObj && incidentObj.incidentdata[0].affectedBodyParts;
        if (!itemArr.includes(id)) {
            itemArr.push(id);
        }
        else if (itemArr.includes(id)) {
            itemArr = itemArr.filter((el) => el !== id);
        }
        const obj = { ...incidentObj };
        obj.incidentdata[0].affectedBodyParts = itemArr;
        setIncidentObj(obj);
        setValue('affectedBodyParts', itemArr);
        trigger('affectedBodyParts');
        // dispatch(addIncident({ payload: { ['affectedBodyParts']: itemArr }, index: 0 }));
        setSelectedBodyParts(itemArr);
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const formatDate = (date) => {
        if (date) {
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${month}-${day}-${year}`;
        }
        return '';
    };

    // const setDateInRedux = () => {
    //     const transDate = formatDate(date);
    //         dispatch(addIncident({ payload: { ['date']: transDate }, index: 0 }))
    // }

    const onSubmit = (e) => {
        const transDate = formatDate(date);
        setIncidentObj(e);
        let data = {
            "id": localStorage.getItem('step_one_submitted') ? localStorage.getItem('userId') : incidentId,
            "userid": 1,
            "type": 'incident',
            "draft": draftMode ? 1 : 0,
            "image": imageData,
            "formdata": {
                "date": transDate,
                "reportedby": editMode ? initiallyReportedBy : loggedInUserName,
                "updatedby": '',
                "name": getValues('name'),
                "location": editMode ? selectedLocation.value : e.location,
                "location_id": selectedLocation.id,
                "state": editMode ? selectedState.value : e.state,
                "state_id": selectedState.id,
                "hour": editMode ? selectedHour.value : e.hour,
                "minutes": editMode ? selectedMinutes.value : e.minutes,
                "meridian": editMode ? selectedMeridian.value : e.meridian,
                "time": editMode ? selectedHour.value : e.hour + ':' + editMode ? selectedMinutes.value : e.minutes + ' ' + editMode ? selectedMeridian.value : e.meridian,
                "correctiveAction": localStorage.getItem('step_one_submitted') ? e.correctiveAction : incidentObj.correctiveAction,
                "investigationConclusion": localStorage.getItem('step_one_submitted') ? e.investigationConclusion : incidentObj.investigationConclusion,
                "eventDescription": localStorage.getItem('step_one_submitted') ? e.eventDescription : incidentObj.eventDescription,
                "correctiveMoreDetails": localStorage.getItem('step_one_submitted') ? e.correctiveMoreDetails : incidentObj.correctiveMoreDetails,
                "investigationMoreDetails": localStorage.getItem('step_one_submitted') ? e.investigationMoreDetails : incidentObj.investigationMoreDetails,
            },
            "initialSubmission": initialSubmission,
            "incidentdata": [{
                incidentType: editMode ? selectedIncidentType.value : e.incidentType,
                "affectedBodyParts": editMode ? e.affectedBodyParts : e.affectedBodyParts,
                "injuryType": editMode ? selectedInjury.value : e.injuryType,
                "personImpacted": editMode ? selectedPersonImpacted : e.personImpacted,
                "jobTitle": editMode ? selectedJobTitle.value : e.jobTitle,
            }],
        };
        if (data.formdata.location == 'Others') {
            data.formdata.location = null;
            data.formdata.location_id = null;
            data.formdata.other_location = getValues('other_location');
        }
        // console.log('incident data', e);
        // dispatch(addIncident({...e, step_one_submitted: true}));
        setLoading(true);
        let apiCallMethod = editMode ? updateEvent : reportEvent;
        Post(apiCallMethod, token, data, true)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setLoading(false);
                    if (draftMode) {
                        setSuccessDetail('Incident saved successfully!');
                        setAlertType('success');
                        setSuccessRes(true);
                        setTimeout(() => {
                            navigate('/event-list')
                        }, 2000);
                    }
                    if (!draftMode) {
                        next(res);
                        ReactGA.event({
                            category: `(Incident ${editMode ? 'updated' : 'added'} successfully)`,
                            action: `(Incident details ${editMode ? 'updated' : 'added'} successfully)`,
                            label: 'By - ' + localStorage.getItem('name'),
                        });
                    }
                }

                if (!res.success) {
                    setLoading(false);
                    setSuccessDetail(res.message);
                    setAlertType('failed');
                    setSuccessRes(true);
                }
            })
            .catch((error) => {
            });
        // console.log('userid from proceed api', imageUserId);
    }

    const onSelectionChange = (selection, { name }) => {
        setValue(name, selection.value);
        trigger(name);
        // dispatch(addIncident({ payload: { [name]: selection.value }, index: 0 }));
        // dispatch(addIncident(selection.value, name));
        if (name === 'hour') {
            setSelectedHour(selection)
        }
        if (name === 'minutes') {
            setSelectedMinutes(selection)
        }
        if (name === 'meridian') {
            setSelectedMeridian(selection)
        }
        if (name === 'state') {
            setSelectedState(selection);
            getLocationById(selection.id);
            setSelectedLocation('');
        }
        if (name === 'location') {
            setSelectedLocation(selection);
            if (selection.value == 'Others') {
                setcheckIfOtherLocationIsRequired(true);
            } else {
                setcheckIfOtherLocationIsRequired(false);
            }
        }
        if (name === 'incidentType') {
            if (!['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(selection.value)) {
                setIncidentValidation(false);
                setSelectedPersonImpacted('');
                setSelectedJobTitle('');
                setSelectedInjury('');
                setSelectedBodyParts([]);
                incidentObj.incidentdata[0].affectedBodyParts = [];
                setValue('affectedBodyParts', []);
            }
            if (['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(selection.value)) {
                setIncidentValidation(true);
            }
            setSelectedIncidentType(selection);
        }
        if (name === 'personImpacted') {
            setSelectedPersonImpacted(selection)
        }
        if (name === 'jobTitle') {
            setSelectedJobTitle(selection)
        }
        if (name === 'injuryType') {
            setSelectedInjury(selection)
        }
        if (name === 'affectedBodyParts') {
            setSelectedBodyParts(selection)
        }
    }

    const onCancel = () => {
        navigate('/event-list');
    }

    const patchIncidentArrObj = (item) => {

        let injuryObj = {
            value: item.length > 0 && item[0].injuryType,
            label: item.length > 0 && item[0].injuryType,
        }
        let jobObj = {
            value: item.length > 0 && item[0].jobTitle,
            label: item.length > 0 && item[0].jobTitle,
        }
        let incidentTypeObj = {
            value: item.length > 0 && item[0].incidentType,
            label: item.length > 0 && item[0].incidentType,
        }
        setSelectedInjury(injuryObj);
        setSelectedJobTitle(jobObj);
        setSelectedIncidentType(incidentTypeObj);
        setSelectedPersonImpacted(item.length > 0 && item[0].personImpacted);
        setSelectedBodyParts(item.length > 0 && item[0].affectedBodyParts);

        setValue('incidentType', incidentTypeObj);
        setValue('jobTitle', jobObj);
        setValue('injuryType', injuryObj);
        setValue('personImpacted', item.length > 0 && item[0].personImpacted);
        setValue('affectedBodyParts', item.length > 0 && item[0].affectedBodyParts);
    }

    const setDateValidation = (dateObj) => {
        setValue('date', dateObj);
        trigger('date');
    }

    const getDetailsById = () => {
        const step_one_submitted = localStorage.getItem('step_one_submitted');
        let userId = '';
        if (step_one_submitted) {
            userId = localStorage.getItem('userId');
        }
        let body = {
            id: step_one_submitted ? userId : location.state.id
        }
        let data = initialSubmission === true ? false : false;
        setInitialSubmission(data);
        Post(getDataById, token, body, true)
            .then((response) => response)
            .then((res) => {
                if (res.success) {
                    let data = res.result[0].safetyformdata[0].formdata;
                    setInitiallyReportedBy(res.result[0].safetyformdata[0].formdata.reportedby);
                    setStateId(res.result[0].safetyformdata[0].formdata.state_id);
                    setIsDraft(res.result[0]?.safetyformdata[0]?.draft);
                    setValue('correctiveAction', data.correctiveAction);
                    setValue('investigationConclusion', data.investigationConclusion);
                    setValue('eventDescription', data.eventDescription);
                    setValue('correctiveMoreDetails', data.correctiveMoreDetails);
                    setValue('investigationMoreDetails', data.investigationMoreDetails);
                    setDate(data.date ? new Date(data.date) : null);
                    setDateValidation(data.date);
                    setValue('name', data.name);
                    trigger('name');
                    let hourObj = {
                        value: data.hour,
                        label: data.hour,
                    }
                    let minuteObj = {
                        value: data.minutes,
                        label: data.minutes,
                    }
                    let meridianObj = {
                        value: data.meridian,
                        label: data.meridian,
                    }
                    let stateObj = {
                        value: data.state,
                        label: data.state,
                        id: data.state_id,
                    }

                    let locationObj = {
                        value: data.location ? data.location : 'Others',
                        label: data.location ? data.location : 'Others',
                        id: data.location_id ? data.location_id : null,
                    }

                    setSelectedHour(hourObj);
                    setSelectedMinutes(minuteObj);
                    setSelectedMeridian(meridianObj);
                    setSelectedState(stateObj);
                    setSelectedLocation(locationObj);
                    setValue('hour', hourObj);
                    setValue('minutes', minuteObj);
                    setValue('meridian', meridianObj);
                    setValue('location', locationObj);
                    setValue('state', stateObj);
                    if (!data.location) {
                        setcheckIfOtherLocationIsRequired(true);
                        setValue('other_location', data.other_location);
                    }
                }


                if (step_one_submitted) {
                    patchIncidentArrObj(res.result[0].safetyformdata[0].incidentdata);
                    setIncidentObj({ incidentdata: res.result[0].safetyformdata[0].incidentdata })
                }

            });
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>
                    <div className='row'>
                        <div className='col-md-6 pt-2' >
                            {/* <div className='row'> */}
                            <div className='inputFieldHead'>Date of Occurrence*</div>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => { setDate(date); setDateValidation(date); }}
                                        className='form-control'
                                        placeholderText='Choose Date'
                                        dateFormat="MM-dd-yyyy"
                                        maxDate={today}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.date && (
                                <p className="error"> <img src={errorIcon} /> {errors.date.message} </p>
                            )}
                            {/* </div> */}
                        </div>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Time*</div>
                            <div className='row mt-16'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="hour"
                                        control={control}
                                        render={({ field }) => (
                                            <Select classNamePrefix="an-simple-select" name="hour"
                                                // value={incidentObj.hour}
                                                placeholder='Hour'
                                                options={data.Hours}
                                                // value={incidentObj.hour}
                                                // onChange={onSelectChange}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedHour}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {errors.hour && (
                                        <p className="error"> <img src={errorIcon} /> {errors.hour.message} </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="minutes"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="minutes"
                                                placeholder='Minutes'
                                                options={data.Minutes}
                                                // onChange={onSelectChange}
                                                // value={{label: incidentObj.minutes}}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMinutes}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {errors.minutes && (
                                        <p className="error"> <img src={errorIcon} /> {errors.minutes.message} </p>
                                    )}

                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="meridian"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="meridian"
                                                placeholder='Meridian'
                                                options={data.Meridian}
                                                // onChange={onSelectChange}
                                                // value={{label: incidentObj.meridian}}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={selectedMeridian}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {errors.meridian && (
                                        <p className="error"> <img src={errorIcon} /> {errors.meridian.message} </p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Name of the Incident*</div>
                            <input
                                type='text'
                                className='form-control'
                                name="name"
                                // value={incidentObj.name} 
                                placeholder='Type here For eg Refinery Fire'
                                // value={incidentObj.name}
                                // onChange={onChange}
                                {...register("name", { required: true })}

                            />
                            {errors.name?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Choose State*</div>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="state"
                                        placeholder='Choose the state where the incident occured'
                                        options={stateList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedState}
                                    // onChange={onSelectChange}
                                    // value={{label: incidentObj.state}}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.state && (
                                <p className="error"> <img src={errorIcon} /> {errors.state.message} </p>
                            )}
                        </div>
                    </div>

                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Location of Occurrence*</div>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="location"
                                        placeholder='Choose the location where the incident occured'
                                        options={facilities}
                                        // onChange={onSelectChange}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedLocation}
                                    // value={{label: incidentObj.location}}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.location && (
                                <p className="error"> <img src={errorIcon} /> {errors.location.message} </p>
                            )}
                        </div>
                        {selectedLocation?.value == 'Others' && <div className='col-md-6'>
                            <div className='inputFieldHead'>Please type in the Location*</div>
                            <input
                                type='text'
                                className='form-control'
                                name="other_location"
                                placeholder='Type here For eg Near basin'
                                {...register("other_location", { required: checkIfOtherLocationIsRequired })}
                            />
                            {errors.other_location?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}

                        </div>}
                    </div>

                </div>

                <div className='container_div'>
                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Incident Type*</div>
                            <Controller
                                name="incidentType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        placeholder='Choose the incident type'
                                        name={'incidentType'}
                                        options={data.IncidentType}
                                        // onChange={(e, val) => { onIncidentChange(e); onSelectChange(e, val) }}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedIncidentType}
                                    // value={{label: incidentObj.incidentType}}                
                                    />
                                )}
                                rules={{ required: true }} // Validation rule for required
                            />
                            {errors.incidentType && (
                                <p className="error"> <img src={errorIcon} /> {'This field is mandatory'} </p>
                            )}
                        </div>
                        {/* {console.log('typoasdijfas', selectedIncidentType)};
                        {console.log(['First Aid Injury', 'Lost Time Injury', 'Recordable Injury'].includes(selectedIncidentType))} */}
                        {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(selectedIncidentType.value) &&
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Type of Person Impacted*</div>
                                <div className='rdImpactdGroup mt-16'>
                                    <Controller
                                        name="personImpacted"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    type="radio"
                                                    id="employee"
                                                    name="personImpacted"
                                                    value="Employee"
                                                    checked={selectedPersonImpacted === 'Employee'}
                                                    // checked={incidentObj.personImpacted === 'Employee'} 
                                                    onChange={(e) => { onRadioChange(e); onImpactedChange(e) }}
                                                // onChange={(selection) => onSelectionChange(selection, field)}
                                                />
                                                <label htmlFor="employee">Employee</label>
                                                <input type="radio"
                                                    id="contractor"
                                                    name="personImpacted"
                                                    value="Contractor"
                                                    checked={selectedPersonImpacted === 'Contractor'}
                                                    // checked={incidentObj.personImpacted === 'Contractor'} 
                                                    onChange={(e) => { onRadioChange(e); onImpactedChange(e) }}
                                                // onChange={(selection) => onSelectionChange(selection, field)}
                                                />
                                                <label htmlFor="contractor">Contractor</label>
                                                <input type="radio"
                                                    id="not_applicable"
                                                    name="personImpacted"
                                                    value="NA"
                                                    checked={selectedPersonImpacted === 'NA'}
                                                    // checked={incidentObj.personImpacted === 'NA'} 
                                                    // onChange={(selection) => onSelectionChange(selection, field)}
                                                    onChange={(e) => { onRadioChange(e); onImpactedChange(e) }}
                                                />
                                                <label htmlFor="not_applicable<">Not Applicable</label>
                                            </>
                                        )}
                                        rules={{ required: incidentValidation }} // Validation rule for required
                                    />
                                     {errors.personImpacted && incidentValidation && (
                                    <p className="error"> <img src={errorIcon} /> {'This field is required'} </p>
                                )}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='row mt-30'>

                        {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(selectedIncidentType.value) &&
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Area of work of person*</div>
                                <Controller
                                    name="jobTitle"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            classNamePrefix="an-simple-select"
                                            placeholder='Choose the area of work of person'
                                            name={'jobTitle'}
                                            options={data.JobTitle}
                                            // onChange={onSelectChange}
                                            onChange={(selection) => onSelectionChange(selection, field)}
                                            value={selectedJobTitle}
                                        // value={{label: 'Petroleum Engineer'}}                                         
                                        />
                                    )}
                                    rules={{ required: incidentValidation }} // Validation rule for required
                                />
                                {errors.jobTitle && incidentValidation && (
                                    <p className="error"> <img src={errorIcon} /> {'This field is required'} </p>
                                )}
                            </div>}


                        {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(selectedIncidentType.value) &&
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Type of Injury*</div>
                                <Controller
                                    name="injuryType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            classNamePrefix="an-simple-select"
                                            placeholder='Choose the injury type'
                                            name={'injuryType'}
                                            options={data.InjuryTypes}
                                            // onChange={onSelectChange}
                                            onChange={(selection) => onSelectionChange(selection, field)}
                                            value={selectedInjury}
                                        // value={{label: incidentObj.injuryType}}
                                        />
                                    )}
                                    rules={{ required: incidentValidation }} // Validation rule for required
                                />
                                {errors.injuryType && incidentValidation && (
                                    <p className="error"> <img src={errorIcon} /> {'This field is required'} </p>
                                )}
                            </div>}
                    </div>

                    <div className='row mt-30'>

                        {!['Fire', 'Fatality', 'Property Damage', 'Near Miss', 'Observation', 'Vehicle Accident', 'Vandalism/Theft'].includes(selectedIncidentType.value) &&
                            <div className='col-md-6'>
                                <div className='inputFieldHead'>Affected Body Part*</div>
                                <Controller
                                    name="affectedBodyParts"
                                    control={control}
                                    render={({ field }) => (
                                        <CheckboxSelect
                                            classNamePrefix="an-simple-select"
                                            name={`bodyParts`}
                                            data={data.BodyParts}
                                            colSize={3}
                                            onChange={(e) => onCheckboxChange(e)}
                                            checkedValues={selectedBodyParts}
                                        // checkedValues={incidentObj.affectedBodyParts}

                                        />
                                    )}
                                    rules={{ required: incidentValidation }} // Validation rule for required
                                />
                                {errors.affectedBodyParts && incidentValidation && (
                                    <p className="error"> <img src={errorIcon} /> {'This field is required'} </p>
                                )}
                            </div>}

                    </div>
                </div>

                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={onCancel}>Cancel</button>
                    {isDraft !== 0 && <button type="submit" className='btn-add-data mr-15' onClick={handleDraft}>Save As Draft</button>}
                    <button type="submit" className='btn-proceed in-blck mt-30' onClick={handleProceed}>Proceed</button>
                </div>

            </form>
        </div>
    )
}

export default IncidentDetails
