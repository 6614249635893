import React, { useEffect, useState } from "react";
import moment from "moment";
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import { useNavigate, useLocation } from "react-router-dom";
import "./ViewReportDetails.css";
import { Post, Get } from "../../../constants/apiMethods";
import { getDataById, exportToDoc, exportToExcel } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import data from '../../../mockData/mockData';
import { saveAs } from 'file-saver';
import { Modal } from 'react-bootstrap';
import UploadedFileContainer from "../../../components/FileUpload/UploadedFileContainer";
import { NA_STATUS, MMDDYYYY_FORMAT, ACCESS_TOKEN, IMAGE_JPG_FORMAT } from '../../../constants/Global';
import ImagePreviewPopUp from "../../../components/ImagePreviewPopUp";

const ViewIncident = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [viewData, setViewData] = useState([]);
    const token = localStorage.getItem(ACCESS_TOKEN);
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [images, setImages] = useState([]);
    const [docFormatType, setDocFormatType] = useState('');
    const [imageObj, setImageObj] = useState();
    const handleClick = () => {
        navigate('/update-incident', { state: { id: location.state.id } });
    }

    useEffect(() => {
        getDetailsById();
    }, []);

    const getDetailsById = () => {
        let body = {
            id: location.state.id
        }
        setLoading(true);
        Post(getDataById, token, body, true)
            .then((response) => response)
            .then((data) => {
                if (data.code === 200) {
                    setLoading(false);
                    setViewData(data.result);
                    const updatedDocuments = data.result[0]?.image && data.result[0]?.image.length > 0 && data.result[0]?.image.map(doc => {
                        const extension = doc.img_name.split('.').pop();
                        return { ...doc, extension };
                    });
                    setImages(updatedDocuments ? updatedDocuments : []);
                }
            });
    };

    const downloadAsExcelSheet = (id = '') => {
        Get(exportToExcel, '', id, true)
            .then((response) => response)
            .then((data) => {
            });
        window.open(`http://34.196.139.67:8001/api/export/excel/${id}`, '_self')
    };

    const downloadAsWordDoc = (id = '') => {
        Get(exportToDoc, '', id, true)
            .then((response) => response)
            .then((data) => {
            });
        window.open(`http://34.196.139.67:8001/api/export/csv/${id}`, '_self')
    };

    const downloadImg = (imgUrl) => {
        saveAs(`${process.env.REACT_APP_IMAGE_URL + imgUrl?.uploadimg}`)
    }

    const showPreview = (item) => {
        setImageObj(item);
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        setImageName(item?.img_name);
        setImageToPreview(`${process.env.REACT_APP_IMAGE_URL + item.uploadimg}`)
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            <div className="full-cont">
                <div className="row">
                    <div className="col-md-7">
                        <div><span className="head_1 cursor" onClick={() => { navigate('/safety') }}>Safety Dashboard</span> / <span className="head_1 cursor" onClick={() => { navigate('/event-list') }}>Event and Incident Management</span> / <span className="head_3">View Incident</span></div>
                        <div className="head_2">{viewData[0]?.safetyformdata[0]?.formdata?.name}</div>
                    </div>
                    <div className="col-md-5 text-right">
                        <span className="mr-15">
                            {/* <SelectDropdownAction headingData={data.ExportHeading} data={data.Downloads} 
                    onDownloadAsExcelSheet={() => downloadAsExcelSheet()}
                     onDownloadAsWordDoc={() => downloadAsWordDoc(location.state.id)} isImg={false} /> */}
                        </span>
                        <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                    </div>
                </div>

                <hr className="defaultLine" />
                <div className='containerHeading'>System Details</div>
                <div className='container_div'>

                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Added By</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.reportedby ? viewData[0]?.safetyformdata[0]?.formdata?.reportedby : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Added On</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.created_at ? moment(viewData[0]?.safetyformdata[0]?.created_at).format(MMDDYYYY_FORMAT) : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Last Updated By</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.updatedby ? viewData[0]?.safetyformdata[0]?.formdata?.updatedby : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Last Updated On</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.updated_at ? moment(viewData[0]?.safetyformdata[0]?.updated_at).format(MMDDYYYY_FORMAT) : NA_STATUS}</p>
                        </div>
                    </div>
                </div>

                <div className='containerHeading'>Incident Details</div>

                <div className='container_div'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Date of Occurrence</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.date ? moment(viewData[0]?.safetyformdata[0]?.formdata?.date).format(MMDDYYYY_FORMAT) : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Time</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.hour ? viewData[0]?.safetyformdata[0]?.formdata?.hour + ':' + viewData[0]?.safetyformdata[0]?.formdata?.minutes + ' ' + viewData[0]?.safetyformdata[0]?.formdata?.meridian : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Name of the Incident</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.name ? viewData[0]?.safetyformdata[0]?.formdata?.name : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Location of Occurrence {viewData[0]?.safetyformdata[0]?.formdata?.location === null && '(Others)'}</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.location ? viewData[0]?.safetyformdata[0]?.formdata?.location : viewData[0]?.safetyformdata[0]?.formdata?.other_location}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Type of Person Impacted</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.incidentdata[0]?.personImpacted ? viewData[0]?.safetyformdata[0]?.incidentdata[0]?.personImpacted : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Area of work of person</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.incidentdata[0]?.jobTitle ? viewData[0]?.safetyformdata[0]?.incidentdata[0]?.jobTitle : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Incident Type</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.incidentdata[0].incidentType ? viewData[0]?.safetyformdata[0]?.incidentdata[0].incidentType : NA_STATUS}</p>
                        </div>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Type of Injury</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.incidentdata[0]?.injuryType ? viewData[0]?.safetyformdata[0]?.incidentdata[0]?.injuryType : NA_STATUS}</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='inputFieldHead'>Affected Body Part</div>
                            <div className="mb-3">
                                {viewData[0]?.safetyformdata[0]?.incidentdata[0]?.affectedBodyParts?.length > 0 ?
                                    viewData[0]?.safetyformdata[0]?.incidentdata[0]?.affectedBodyParts.map((item) =>
                                        data.BodyParts.map(val => (
                                            val.id === item && <span className="text-content border-box">{val.label}</span>
                                        ))
                                    ) : NA_STATUS}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='containerHeading'>Investigation Details</div>
                <div className='container_div'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='inputFieldHead mb-3 mt-3"'>Share the description of the Incident</div>
                            <p className="text-content">{viewData[0]?.safetyformdata[0]?.formdata?.eventDescription ? viewData[0]?.safetyformdata[0]?.formdata?.eventDescription : NA_STATUS}</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='inputFieldHead mb-3 mt-3'>Investigation Conclusion</div>
                            <div className="mb-3">
                                {viewData[0]?.safetyformdata[0]?.formdata?.investigationConclusion?.length > 0 ?
                                    viewData[0]?.safetyformdata[0]?.formdata?.investigationConclusion.map((item) =>
                                        data.InvestigationConclusion.map(res => (
                                            res.id === item && <span className="text-content border-box">{res.label}</span>
                                        ))
                                    ) : NA_STATUS}
                            </div>
                            <p className="text-content mt-2">
                                {viewData[0]?.safetyformdata[0]?.formdata?.investigationMoreDetails ? <div className='inputFieldHeadAddMore mb-3 mt-3'>Add More Deatils</div> : null}
                                {viewData[0]?.safetyformdata[0]?.formdata?.investigationMoreDetails}
                            </p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='inputFieldHead mb-3 mt-3'>Corrective Actions</div>
                            <div className="mb-3">
                                {viewData[0]?.safetyformdata[0]?.formdata?.correctiveAction ?
                                    viewData[0]?.safetyformdata[0]?.formdata?.correctiveAction.map((item) =>
                                        data.CorrectiveActions.map(val => (
                                            val.id === item && <span className="text-content border-box">{val.label}</span>
                                        ))
                                    ) : NA_STATUS}
                            </div>
                            {viewData[0]?.safetyformdata[0]?.formdata?.correctiveMoreDetails ? <div className='inputFieldHeadAddMore mb-3 mt-3'>Add More Deatils</div> : null}
                            <p className="text-content mt-2">{viewData[0]?.safetyformdata[0]?.formdata?.correctiveMoreDetails}</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='inputFieldHead mb-3 mt-3'>Supporting Documents</div>
                        </div>
                        {images?.length > 0 ? images.map((item, i) => (
                            <div className='col-md-6'>
                                <UploadedFileContainer
                                    type='uploadedImage'
                                    index={i}
                                    img={item}
                                    showPreview={showPreview}
                                    downloadImg={downloadImg}>
                                    <img src={DownloadCompleteImg} />
                                </UploadedFileContainer>
                            </div>
                        )) : <div className="ml-2">No image added</div>}
                    </div>
                </div>

                <div className="row mt-30">
                    <div className="col-md-12 text-right">
                        <span className="mr-15">
                            {/* <SelectDropdownAction headingData={data.ExportHeading} data={data.Downloads}
                                onDownloadAsExcelSheet={() => downloadAsExcelSheet()}
                                onDownloadAsWordDoc={() => downloadAsWordDoc()} isImg={false} /> */}
                        </span>
                        <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                    </div>
                </div>

                <ImagePreviewPopUp
                    showPreviewImg={showPreviewImg}
                    setShowPreviewImg={setShowPreviewImg}
                    downloadImg={downloadImg}
                    imageName={imageName}
                    imageObj={imageObj}
                    imageToPreview={imageToPreview}
                    docFormatType={docFormatType} />

            </div>
        </>
    )
}

export default ViewIncident;