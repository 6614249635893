import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import ForwardPagination from '../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../assets/images/BackwardPagination.svg';

const getClassNames = (column, item) => {
  const { field } = column;
  const value = item[field];
  let classNames = '';

  switch (field) {
    case 'record_status':
      classNames = value === 'Submitted' ? 'text-regular' : value === 'Draft' ? 'text-warn' : '';
      break;
    
    case 'complaint_status':
    case 'spill_status':
      classNames = value === 'Open' ? 'text-alert' : value === 'Closed' ? 'text-success' : '';
      break;

    default:
      break;
  }

  return classNames.trim();
};

const Table = ({ columns, data, onPageChange, currentPage, showPagination, itemsPerPage }) => {
  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} scope="col">
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.length > 0 ? (
              data.map((item, index) => (
                <tr className="brdrtop" key={index + 1}>
                  {columns.map((column, columnIndex) => (
                    <td key={columnIndex} className={getClassNames(column, item)}>
                      {column.render ? (
                        column.render(item)
                      ) : (
                        <span>{item[column.field]}</span>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length + 1} className="text-center no-data">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {showPagination && data?.length > 0 && (
          <ReactPaginate
            forcePage={currentPage.current_page - 1}
            pageCount={currentPage.total_count / 5}
            marginPagesDisplayed={2}
            onPageChange={(e) => onPageChange(e.selected)}
            containerClassName={'pagination-body'}
            previousLabel={
              <div className="label-cover">
                <img src={BackwardPagination} /> Previous
              </div>
            }
            nextLabel={
              <div className="label-cover">
                Next <img src={ForwardPagination} />
              </div>
            }
            previousLinkClassName={'page'}
            breakClassName={'page'}
            nextLinkClassName={'page'}
            pageClassName={'page'}
            disabledClassName={'disabled'}
            activeClassName={'active'}
          />
        )}
      </div>
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.array,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  showPagination: PropTypes.bool,
  itemsPerPage: PropTypes.number,
};

Table.defaultProps = {
  data: [],
  onPageChange: () => { },
  currentPage: 1,
  showPagination: true,
  itemsPerPage: 5,
};

export default Table;