import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import "./Drag.css";
import GraphTopBar from '../Safety/Dashboard/GraphTopBar/GraphTopBar';
import BarChart from '../Safety/Dashboard/charts/BarChart';
import data from '../../mockData/mockData';
import DropDownData from '../../mockData/DropdownData';
import PieChart from '../Safety/Dashboard/charts/PieChart';
import { customizeGraphToggle } from '../../constants/apiRoutes';
import { Post } from '../../constants/apiMethods';

function Drag({ graphArr, graphList, moduleName }) {

  const token = localStorage.getItem('access_token');
  const indicatorsData = JSON.parse(localStorage.getItem('graphCustomization'));
  const leadingIndicatorsData = indicatorsData?.data?.leadingIndicators ? indicatorsData?.data?.leadingIndicators : data?.LeadingIndicators;
  const laggingIndicatorsData = indicatorsData?.data?.laggingIndicators ? indicatorsData?.data?.laggingIndicators : data?.LaggingIndicators;
  const [graphs, updateGraphs] = useState(graphArr);
  function handleOnDragEnd(result) {
    if (moduleName != 'Operational Compliance') {
      if (!result.destination) return;

      const items = Array.from(graphs);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      updateGraphs(items);

      const dragList = items?.map((resp) => {
        return { id: resp?.id, name: resp?.name }
      })

      let body = {
        data: {
          leadingIndicators: leadingIndicatorsData,
          laggingIndicators: laggingIndicatorsData,
          dragData: items
        }
      }
      Post(customizeGraphToggle, token, body, false)
        .then((response) => response)
        .then((data) => {
          if (data.success) {
            localStorage.setItem('graphCustomization', JSON.stringify(data.data));
          }
        });
    }
  }

  useEffect(() => {
    updateGraphs(graphArr);
  }, [graphArr])

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="graph-id">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef} id={provided.id}>
            {graphs && graphs.map((graphItem, index) => {
              return (
                <Draggable key={graphItem.id} draggableId={graphItem.id} index={index}>
                  {(provided) => (
                    <li key={provided.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {graphItem.graphType === 'BarChart' && graphList && graphList.includes(graphItem.name) && graphItem.showGraph &&
                        <div className="graph-container-drag">
                          <GraphTopBar
                            id={graphItem.id}
                            graphName={graphItem.name}
                            graphType={graphItem.graphType}
                            trendData={DropDownData.GraphTypes}
                            target={graphItem.target}
                            overLayShow={graphs.leadShow}
                            setOverlayShow={graphs.setLeadShow}
                            handleDropdownSelect={graphs.handleDropdownSelect}
                            setLocalCustomizationPopUp={graphs.setLocalCustomizationPopUp}
                            icon={graphItem.icon}
                            employeeDdn={graphItem.employeeDdn}
                            onSelectSidebarIcon={graphItem.onSelectSidebarIcon}
                            empDdnData={data.EmpDdnData}
                            onFilterChartType={graphItem.onFilterChartType}
                            selectedFilter={graphItem.selectedFilter}
                            numberOfComplaintsTypeData={graphItem?.numberOfComplaintsTypeData} />

                          {/* Leading data indicator chart */}
                          <BarChart
                            data={graphItem.chartData}
                            options={graphItem.chartOptions} />
                        </div>}

                      {graphItem.graphType === 'BarChart' && graphList && graphList.includes(graphItem.name) && !graphItem.showGraph && <p className='no-data-graph-container' style={{ width: '100%' }}>No Recorded Data Found for {graphItem.name}</p>}

                      {graphItem.graphType === 'StackedChart' && graphList && graphList.includes(graphItem.name) && graphItem.showGraph &&
                        <div className="graph-container-drag">
                          <GraphTopBar
                            id={graphItem.id}
                            graphName={graphItem.name}
                            graphType={graphItem.graphType}
                            trendData={DropDownData.GraphTypes}
                            target={graphItem.target}
                            overLayShow={graphs.leadShow}
                            setOverlayShow={graphs.setLeadShow}
                            handleDropdownSelect={graphs.handleDropdownSelect}
                            setLocalCustomizationPopUp={graphs.setLocalCustomizationPopUp}
                            icon={graphItem.icon}
                            stateDdn={graphItem.stateDdn}
                            onSelectSidebarIcon={graphItem.onSelectSidebarIcon}
                            stateDdnData={graphItem.stateDdnData}
                            onFilterChartType={graphItem.onFilterChartType}
                            selectedFilter={graphItem.selectedFilter} />
                          <BarChart
                            data={graphItem.chartData}
                            options={graphItem.chartOptions} />
                        </div>}

                      {graphItem.graphType === 'StackedChart' && graphList && graphList.includes(graphItem.name) && !graphItem.showGraph && <p className='no-data-graph-container' style={{ width: '100%' }}>No Recorded Data Found for {graphItem.name}</p>}

                      {graphItem?.chartArr?.length !== 0 && (
                        <div className="row margin-container">
                          {graphItem?.chartArr?.map((item, index) => (
                            <div key={index} className="col-md-6">

                              {item.graphType === 'HorizontalChart' && graphList && graphList.includes(item.name) && (
                                <div className="horizontal-graph-container-drag">
                                  <GraphTopBar
                                    graphName={item.name}
                                    graphType={item.graphType}
                                    trendData={DropDownData.GraphTypes}
                                    target={item.target}
                                    handleDropdownSelect={item.handleDropdownSelect}
                                    setLocalCustomizationPopUp={item.setLocalCustomizationPopUp}
                                    icon={item.icon}
                                    employeeDdn={false}
                                  />
                                  <BarChart data={item.chartData} options={item.chartOptions} />
                                  <div className='bottom-label'>
                                    <p className="legend-heading">Number of Recordable Injury Types</p>
                                    <ul className="legend-title">
                                      <li className='box attention-shade'></li>
                                      <li>Need urgent attention</li>
                                    </ul>
                                  </div>
                                </div>
                              )}

                              {item.graphType === 'PieChart' && graphList && graphList.includes(item.name) && (
                                <div className='pie-graph-container-drag'>
                                  <div className="pie-graph-component">
                                    <GraphTopBar
                                      graphName={item.subName}
                                      graphType={item.graphType}
                                      trendData={DropDownData.GraphTypes}
                                      target={item.target}
                                      handleDropdownSelect={item.handleDropdownSelect}
                                      setLocalCustomizationPopUp={item.setLocalCustomizationPopUp}
                                      icon={item.icon}
                                      employeeDdn={false}
                                      subHeading={item.subHeading} />
                                    {/* recordable injury pie chart */}
                                    <PieChart
                                      data={item.chartData}
                                      options={item.chartOptions}
                                      pieChartData={item.pieChartData}
                                      showTable={item.showTable} />
                                  </div>
                                </div>
                              )}

                            </div>
                          ))}
                        </div>
                      )}

                      {graphItem.graphType === 'PatternChart' && graphList && graphList.includes(graphItem.name) && graphItem.showGraph &&
                        <div className="graph-container-drag">
                          <GraphTopBar
                            id={graphItem.id}
                            graphName={graphItem.name}
                            graphType={graphItem.graphType}
                            trendData={DropDownData.GraphTypes}
                            target={graphItem.target}
                            overLayShow={graphs.leadShow}
                            setOverlayShow={graphs.setLeadShow}
                            handleDropdownSelect={graphs.handleDropdownSelect}
                            setLocalCustomizationPopUp={graphs.setLocalCustomizationPopUp}
                            icon={graphItem.icon}
                            stateDdn={graphItem.stateDdn}
                            onSelectSidebarIcon={graphItem.onSelectSidebarIcon}
                            stateDdnData={graphItem.stateDdnData}
                            onFilterChartType={graphItem.onFilterChartType}
                            selectedFilter={graphItem.selectedFilter}
                            spillTypeDdn={graphItem.spillTypeDdn}
                            spillTypeDdnData={graphItem.spillTypeDdnData}
                            fluidTypeDdn={graphItem.fluidTypeDdn}
                            fluidTypeDdnData={graphItem.fluidTypeDdnData}
                            onSelectType={graphItem.onSelectType} />

                          <BarChart
                            data={graphItem.chartData}
                            options={graphItem.chartOptions} />
                        </div>}

                      {graphItem.graphType === 'PatternChart' && graphList && graphList.includes(graphItem.name) && !graphItem.showGraph && <p className='no-data-graph-container' style={{ width: '100%' }}>No Recorded Data Found for {graphItem.name}</p>}

                    </li>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </ul>

        )}
      </Droppable>
    </DragDropContext>
  );
}

export default Drag;
