import CrossImg from '../../../assets/images/crossImage.svg';
import DeleteImg from '../../../assets/images/deleteImg.svg';
import AlertIcon from '../../../assets/images/FormImages/alert.svg';
import ImagePreviewIcon from '../../../assets/images/PreviewImageIcon.svg';
import { IMAGE_JPEG_FORMAT } from '../../../constants/Global';
import './index.css';

const EditUploadedFiles = (props) => {
    const {showPreview, deleteImg} = props;
    return (
        <>
            <div className={`row boxWidth mt-30 ${props.type === 'imageProcessing' ? 'percentProcess' : props.type === 'uploadedImage' ? 'imageUpload' : props.type === 'alertImageUpload' ? 'alertUpload' : ''}`}>
                <div className={`col-md-2 ${props.type === 'imageProcessing' ? 'bgPercentProcessColor' : props.type === 'uploadedImage' ? 'bgUploadCompleted' : props.type === 'alertImageUpload' ? 'bgAlertCompleted' : ''}`}>
                    <div>{props.children}</div>
                </div>
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="imageName">{props?.image?.img_name ? props?.image?.img_name : (props?.image?.image_name ? props?.image?.image_name : props?.image?.usd_name ? props?.image?.usd_name : `Image ${props.index + 1}`)}</div>
                            <div className="imageSize">{props?.image?.usd_size ? props?.image?.usd_size : (props?.image?.image_size ? props?.image?.image_size : `21`)}KB</div>
                        </div>
                        <div className="col-md-2 flex contentCenter">
                            {['jpg', 'jpeg', 'png', 'pdf'].includes(props?.image?.extension) && <img src={ImagePreviewIcon} alt='alternateIcon' className="px-2 cursor" onClick={() => showPreview(props?.image, props.index)} />}
                            <img className={`${['xlsx', 'xls', 'csv', 'doc', 'docx'].includes(props?.image?.extension) ? `excluded-format` : `cursor`}`} alt='alternateIcon' src={props.type === 'imageProcessing' ? CrossImg : DeleteImg} onClick={() => {deleteImg(props?.image)}} />
                        </div>
                    </div>
                </div>
            </div>
            {props.type === 'alertImageUpload' &&
                <div className=''>
                    <img className="" src={AlertIcon} />
                    <span className='alertMsgs'>File is too large (max. 25 MB)</span>
                </div>
            }
        </>
    );
}

export default EditUploadedFiles;