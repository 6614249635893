import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import passHide from "../../../assets/images/passHide.svg";
import passShow from "../../../assets/images/passShow.svg";
import errorIcon from "../../../assets/images/error_icon.svg";
import { useForm } from "react-hook-form";
import { forgotPassword, login, verifyOtp } from "../../../constants/apiRoutes";
import { Post } from "../../../constants/apiMethods";
import '../Auth.css';
import graphData from '../../../mockData/mockData';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import LoadingSpinner from "../../../components/Loader";
import Auth from "../Auth";
import { Modal } from "react-bootstrap";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import ReactGA from "react-ga4";

const Login = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(30);
    const [showHideForgetPassword, setshowHideForgetPassword] = useState(false);
    const [showHideOtp, setshowHideOtp] = useState(false);
    const [codeNotMatchedError, setCodeNotMatchedError] = useState(false);
    const otpTitle = 'OTP Verification';
    const otpDescription = 'An OTP code has been sent to your email address, Kindly enter below to process your request.';
    const forgotPwdTitle = 'Forgot Password?';
    const forgotPwdDescription = 'Enter your registered email to process your password change request.';
    const resetPwdTitle = 'Password Reset Successfully';
    const resetPwdDesc = 'Your password has been changed successfully.';
    const resetPwdSubDesc = 'Use your new password to login.';
    const otpButtonTxt = 'Verify & Proceed';
    const forgotPwdBtnTxt = 'Send Request';
    const resetPwdBtnTxt = 'Login Now';
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        clearErrors: clearErrors2,
        reset: reset2
    } = useForm();

    const [message, setMessage] = useState('');
    const [showErrorNotifier, setShowErrorNotifier] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');
    const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
    const [showForgotPasswordNotifier, setShowForgotPasswordNotifier] = useState(false);
    const [showForgotPasswordMessage, setShowForgotPasswordMessage] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');

    useEffect(() => {
        // ReactGA.pageview(window.location.pathname);
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const onSubmit = (data) => {
        setLoading(true);
        setShowErrorNotifier(false);
        Post(login, null, data, false)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    localStorage.setItem('access_token', res.data.access_token);
                    localStorage.setItem('name', res.data.name);
                    localStorage.setItem('graphCustomization', JSON.stringify(graphData.graphCustomizationData));
                    setTimeout(() => {
                        if (res.data.super_admin) {
                            localStorage.setItem('role', 'super_admin');
                            localStorage.setItem('isSidebarShow', 'true');
                            navigate('/subscriptions');
                        } else if (res.data.company_admin) {
                            localStorage.setItem('graphCustomization', JSON.stringify(res.data.global_customisation ? res.data.global_customisation : graphData.graphCustomizationData));
                            localStorage.setItem('role', 'company_admin');
                            localStorage.setItem('permissions', JSON.stringify(res.data?.permissions));
                            if (res.data.role_created && res.data.user_created) {
                                localStorage.setItem('isSidebarShow', 'true');

                                let modulesArr = res.data.permissions && res.data.permissions?.map(item => item.moduleName);
                                if (modulesArr.includes('Safety') && modulesArr.includes('Compliance')) {
                                    navigate('/safety');
                                } else if (modulesArr.includes('Safety')) {
                                    navigate('/safety');
                                } else if (modulesArr.includes('Compliance')) {
                                    navigate('/spills-dashboard');
                                }


                            } else if (res.data.role_created && res.data.user_created == false) {
                                localStorage.setItem('isSidebarShow', 'false');
                                localStorage.setItem('getPage', 1);
                                navigate('/company-admin');
                            } else {
                                localStorage.setItem('isSidebarShow', 'false');
                                navigate('/company-admin');
                            }
                        } else if (res.data.role) {
                            localStorage.setItem('role', 'user');
                            localStorage.setItem('isSidebarShow', 'true');
                            const filteredArrayOfPermissions = res.data.role.permissions.filter(item => !(!item.dashboardChecked && !item.readAccess && !item.writeAccess && !item.exportAccess));
                            localStorage.setItem('permissions', JSON.stringify(filteredArrayOfPermissions));
                            localStorage.setItem('graphCustomization', JSON.stringify(res.data.global_customisation ? res.data.global_customisation : graphData.graphCustomizationData));
                            let modulesArr = filteredArrayOfPermissions && filteredArrayOfPermissions?.map(item => item.moduleName);
                            if (modulesArr.includes('Safety') && modulesArr.includes('Compliance')) {
                                navigate('/safety');
                            } else if (modulesArr.includes('Safety')) {
                                navigate('/safety');
                            } else if (modulesArr.includes('Compliance')) {
                                navigate('/spills-dashboard');
                            }
                            // if (!modulesArr.includes('safety')) {
                            //     let moduleToBeLoaded = modulesArr[0];
                            //     console.log(moduleToBeLoaded)
                            //     navigate(`/${moduleToBeLoaded}`);
                            // }
                        }
                    }, 1000)
                    setAlertType('success');
                    setShowSuccessNotifier(true);
                    setSuccessRes(true);
                    setShowErrorMessage('Login Successfully');
                    ReactGA.event({
                        // category: res.data.name,
                        action: 'logged in successfully',
                        label: res.data.name + '-' + data.username.replace(/@/g, '*'),
                        category: 'login',
                        // value: Number(data.username)
                    });
                } else {
                    setAlertType('failed');
                    setShowSuccessNotifier(true);
                    setSuccessRes(true);
                    setShowErrorMessage(res.detail);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const onSubmitForgotPassword = (data) => {
        setValue('username', data.username)
        setLoading(true);
        setShowForgotPasswordNotifier(false);
        Post(forgotPassword, null, data)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setshowHideForgetPassword(false);
                    setSeconds(30);
                    setshowHideOtp(true);
                    setAlertType('success');
                    setShowForgotPasswordNotifier(true);
                    setShowForgotPasswordMessage('Verification code sent to your email address');
                } else {
                    setShowForgotPasswordNotifier(true);
                    setAlertType('failed');
                    setShowForgotPasswordMessage(res.detail);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const onSubmitOtp = () => {
        setShowForgotPasswordNotifier(false);
        if (otp?.length < 6) {
            setTimeout(() => {
                setShowForgotPasswordNotifier(true);
                setAlertType('failed');
                setShowForgotPasswordMessage('Please enter 6 digit OTP');
            }, 1)
            return
        }
        // if (seconds == 0) {
        //     return
        // }
        setLoading(true);
        setShowErrorNotifier(false);
        setCodeNotMatchedError(false);
        const data = {
            username: getValues('username'),
            otp: otp
        }
        Post(verifyOtp, null, data)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    navigate('/reset-password', { state: { username: getValues('username'), otp: otp } });

                } else {
                    setAlertType('failed');
                    setShowForgotPasswordNotifier(true);
                    setShowForgotPasswordMessage(res.detail);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const resendOTP = () => {
        const data = {
            username: getValues('username')
        }
        onSubmitForgotPassword(data);
    };

    const clearData = (data) => {
        data.username = '';
        console.log(data.username)
    };

    const handleChange = event => {
        setMessage(event.target.value);
    };

    const handleClick = () => {
        setValue('username', '');
        // setMessage('');
    };

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showErrorMessage} setVisibility={setVisibility} />}
            <div className="conatainer">
                <div className="row">
                    <div className="col-md-6">
                        <Auth />
                    </div>
                    <div className="col-md-6">
                        <div className="onboarding-cont">
                            <div className="auth_form_container">
                                <div className="title_text">Login</div>
                                <form className="mt-30" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-container">

                                        <div className="">
                                            <div className='inputFieldHead mt-16'>Email Id</div>
                                            <input type='text'
                                                id='emailId'
                                                {...register("username", {
                                                    required: true,
                                                    pattern:
                                                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                })}
                                                className='form-control'
                                                placeholder='Enter your Email Id here'
                                            // onChange={handleChange}
                                            // value={message}
                                            />
                                            <div className="passFld">
                                                {getValues('username') ? <img
                                                    src={ClearSvg}
                                                    className="emailShowIcon cursor mt-10"
                                                    onClick={handleClick}
                                                    alt="clearIcon"
                                                /> : ''}
                                            </div>
                                            {errors.username?.type == "required" && (
                                                <p className="error"> <img src={errorIcon} alt="errorIcon" /> This field is mandatory </p>
                                            )}
                                            {errors.username?.type == "pattern" && (
                                                <p className="error">
                                                    <img src={errorIcon} alt="errorIcon" /> Please enter a valid Email Id
                                                </p>
                                            )}
                                        </div>

                                        <div className="">
                                            <div className='inputFieldHead mt-16'>Password</div>
                                            <input type='password'
                                                id='password'
                                                autoComplete="off"
                                                {...register("password", {
                                                    required: true
                                                })}
                                                className='form-control'
                                                placeholder='Enter Password' />
                                            <div className="passFld">
                                                <img
                                                    src={passHide}
                                                    className="passShowIcon cursor"
                                                    alt="passIcon"
                                                    onClick={(e) => {
                                                        var div = document.getElementById("password");
                                                        if (div.type === "password") {
                                                            div.type = "text";
                                                            e.target.src = passShow;
                                                        } else {
                                                            div.type = "password";
                                                            e.target.src = passHide;
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {/* {errors.password?.type == "required" && (
                                                <p className="error">
                                                    <img src={errorIcon} alt="errorIcon" /> This field is mandatory </p>
                                            )} */}
                                        </div>

                                    </div>

                                    <div className="text-center mt-30">
                                        <button type="submit" className="btn-auth-action border-0">Login</button>
                                    </div>

                                </form>


                                <div className="forgot_passwrd_txt text-center mt-16">Forgot Password? <span className="click_here cursor" onClick={() => { setshowHideForgetPassword(true); clearErrors2(['username']); reset2({ username: '' }) }}>Click here</span></div>

                                <Modal show={showHideForgetPassword} dialogClassName="reportConfrmModal" onHide={() => { setshowHideForgetPassword(false); setShowForgotPasswordNotifier(false); clearErrors2(['username']); reset2({ username: '' }) }}>
                                    <Modal.Header className="sideOnRight" closeButton>
                                    </Modal.Header>
                                    <Modal.Body className="auth_modal_header border-0 mx-auto">
                                        <div>
                                            <div className='auth_modal_heading'>{forgotPwdTitle}</div>
                                            <div className='auth_modal_descrip'>{forgotPwdDescription}
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit2(onSubmitForgotPassword)}>
                                            <div className="modal-body">
                                                <div className="modal-body-content">
                                                    <div className="input-title mb-2">Email Id</div>
                                                    <input type='text'
                                                        {...register2("username", {
                                                            required: true,
                                                            pattern:
                                                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        })}
                                                        className='form-control'
                                                        placeholder='Enter your email id' />
                                                    {errors2.username?.type == "required" && (
                                                        <p className="error">
                                                            <img src={errorIcon} alt="errorIcon" /> Email is required</p>
                                                    )}
                                                    {errors2.username?.type == "pattern" && (
                                                        <p className="error">
                                                            <img src={errorIcon} alt="errorIcon" /> Please enter a valid Email Id
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="forgot-modal-footer flex">
                                                <button type="submit" className="btn-auth-action border-0 wd-v-p mb-5">{forgotPwdBtnTxt}</button>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                    {showForgotPasswordNotifier && <AlertNotifier alertType={alertType} timer={2000} alertText={showForgotPasswordMessage} setVisibility={setVisibility} />}
                                </Modal>

                                <Modal show={showHideOtp} dialogClassName="verifyOtpModal" onHide={() => { setshowHideOtp(false); setShowForgotPasswordNotifier(false) }}>
                                    <Modal.Header className="sideOnRight" closeButton>
                                    </Modal.Header>
                                    <Modal.Body className="auth_modal_header border-0 mt-4">
                                        <div>
                                            <div className='auth_modal_heading'>{otpTitle}</div>
                                            <div className='auth_modal_descrip'>{otpDescription}
                                            </div>
                                        </div>
                                        <div className="flex mb-2">
                                            <div className="resend ml-6">Enter OTP</div>
                                            {seconds > 0 && <div className="resend ml-11">Resend OTP in <span className="timer">00:{seconds < 10 ? `0${seconds}` : seconds}</span></div>}
                                        </div>
                                        <div className="otpBoxContainer">
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                renderInput={(props) => <input {...props} />}
                                                inputStyle='otpBox'
                                            />
                                        </div>
                                        {codeNotMatchedError && (
                                            <p className="error ml-6">
                                                <img src={errorIcon} alt="errorIcon" /> Code not valid. Try again </p>
                                        )}
                                        <Modal.Footer className="auth_modal_footer">
                                            <button type="button" className="btn-auth-action border-0 wd-v-p" onClick={onSubmitOtp}>{otpButtonTxt}</button>
                                            <div className="forgot_passwrd_txt text-center mt-16">Didn't recieve the code?  {seconds == 0 ? <span className="click_here cursor" onClick={resendOTP}>Resend</span> : <span className="disable-resend">Resend</span>}</div>
                                        </Modal.Footer>
                                    </Modal.Body>
                                    {showForgotPasswordNotifier && <AlertNotifier alertType={alertType} timer={2000} alertText={showForgotPasswordMessage} setVisibility={setVisibility} />}
                                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
