import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { subscription, registerUser, companyList } from '../../../../constants/apiRoutes';
import errorIcon from "../../../../assets/images/error_icon.svg";
import { Get, Post, Put } from '../../../../constants/apiMethods';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import moment from "moment";
import LoadingSpinner from '../../../../components/Loader';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PlanAndAdminDetails = ({ isEditMode, responseFromDetail, active }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');
  const [showDates, setShowDates] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [successRes, setSuccessRes] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState('');
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [companyData, setCompanyData] = useState('');
  const [planExists, setPlanExists] = useState(false);
  const [viewData, setViewData] = useState(false);

  const token = localStorage.getItem('access_token');
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getPlans();
    isEditMode && getCompanyDetails();
  }, [])

  const getCompanyDetails = () => {
    setLoading(true);
    Get(companyList, token, `?id=${responseFromDetail?.id}&is_active=${active}`, false)
      .then((response) => response)
      .then((data) => {
        isEditMode = false;
        data?.data?.plan && setPlanExists(false);
        if (data && data?.data?.plan) {
          setViewData(data?.data);
          setPlanExists(true);
          setLoading(false);
          let obj = {
            name: data?.data?.plan?.name,
            label: data?.data?.plan?.name,
            value: data?.data?.plan?.name,
            id: data?.data?.plan?.id
          }
          setValue('plan_id', obj);
          setSelectedPlan(obj);
          setValue('name', data?.data?.company_admin?.name);
          setValue('email', data?.data?.company_admin?.username);
          setValue('subscription_start_date', data?.data?.subscription_start_date && moment(data?.data?.subscription_start_date).format('MM-DD-YYYY'));
          setValue('subscription_end_date', data?.data?.subscription_end_date && moment(data?.data?.subscription_end_date).format('MM-DD-YYYY'));
          setSubscriptionStartDate(data?.data?.subscription_start_date && moment(data?.data?.subscription_start_date).format('MM-DD-YYYY'));
          setSubscriptionEndDate(data?.data?.subscription_end_date && moment(data?.data?.subscription_end_date).format('MM-DD-YYYY'));
          setShowDates(true);
        }
      });
  };

  const getPlans = () => {
    setLoading(true);
    Get(`${subscription}/`, token, '', false)
      .then((response) => response)
      .then((data) => {
        setLoading(false);
        if (data.success) {
          const planData = data.data?.map((resp) => {
            return { ...resp, label: resp.name, value: resp.name }
          })
          setPlanList(planData);
        }
      });
  };

  const onSubmit = (data) => {
    setShowAddCompany({ show: true });
    data.user_company_id = responseFromDetail.id;
    setCompanyData(data);
  };

  const onSubmitCompanyDetails = () => {
    setShowAddCompany({ show: false });
    setLoading(true);
    if (!isEditMode || !planExists) {
      Post(registerUser, token, companyData, false)
        .then((response) => response)
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setAlertType('success');
            setShowSuccessMessage(`${!isEditMode ?  'Company Onboarded Successfully ' : 'Company Updated Successfully '}`);
            setSuccessRes(true);
            setTimeout(() => {
              navigate('../company-list');
            }, 2000);
          } else {
            setAlertType('failed');
            setShowSuccessMessage(data.detail);
            setSuccessRes(true);
          }
        });
    } else {
      let body = {
        subscription_end_date: getValues('subscription_end_date')
      }
      companyData.subscription_end_date = moment(companyData.subscription_end_date).format('MM-DD-YYYY')
      delete companyData.name;
      delete companyData.email;
      companyData.plan_id = selectedPlan.id;
      Put(`${companyList}/${responseFromDetail?.id}/`, token, companyData, false)
        .then((response) => response)
        .then((data) => {
          setLoading(false);
          if (data) {
            setAlertType('success');
            setShowSuccessMessage('Company Updated Successfully ');
            setSuccessRes(true);
            setTimeout(() => {
              navigate('../company-list');
            }, 2000);
          } else {
            setAlertType('failed');
            setShowSuccessMessage(data.detail);
            setSuccessRes(true);
          }
        });
    }
  }

  const calculateEndDate = (monthsToAdd) => {
    // Get the current date
    const currentDate = new Date();

    // Add x number of months to the current date
    const endDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));

    return endDate;
  }

  const onPlanChange = (selection, { name }) => {
    setValue(name, selection.id);
    trigger(name);
    setShowDates(true);
    const endDate = calculateEndDate(Number(selection?.duration));
    setSubscriptionEndDate(moment(endDate).format('MM-DD-YYYY'));
    setValue('subscription_start_date', subscriptionStartDate);
    setValue('subscription_end_date', moment(endDate).format('MM-DD-YYYY'));
    setSelectedPlan((selection));
  }

  const setVisibility = (data) => {
    setSuccessRes(data);
  }

  const onCancel = () => {
    navigate('../company-list');
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={showSuccessMessage} setVisibility={setVisibility} />}
      <div className='incLabel mt-30'>Plan Details</div>
      <form className="mt-30" onSubmit={handleSubmit(onSubmit)}>
        <div className='container_div'>

          <div className='row'>
            <div className='col-md-6'>
              <div className='inputFieldHead'>Choose Plan*</div>
              <Controller
                name="plan_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="an-simple-select"
                    placeholder='Choose the plan'
                    options={planList}
                    onChange={(selection) => onPlanChange(selection, field)}
                    value={selectedPlan}
                  />
                )}
                rules={{ required: 'This field is mandatory' }} // Validation rule for required
              />
              {errors.plan_id && (
                <p className="error"> <img src={errorIcon} /> {errors.plan_id.message} </p>
              )}
            </div>
          </div>

          {showDates && <div className='row mt-30'>
            <div className='col-md-6'>
              <div className='inputFieldHead'>Subscription Start Date*</div>
              <input type='text'
                className='form-control'
                name="startDate"
                placeholder='Subscription Start Date'
                value={subscriptionStartDate}
                {...register('subscription_start_date', {
                })}
                disabled />
            </div>
            <div className='col-md-6'>
              <div className='inputFieldHead'>Subscription End Date*</div>
              {/* <input type='text'
                className='form-control'
                name="endDate"
                placeholder='Subscrition End Date'
                value={subscriptionEndDate}
                {...register('subscription_end_date', {
                })}
                disabled={!isEditMode} /> */}
              <Controller
                name='subscription_end_date'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={new Date(getValues('subscription_end_date'))}
                    onChange={(date) => { setValue('subscription_end_date', date); setSubscriptionEndDate(date) }}
                    className='form-control'
                    placeholderText='Choose Date'
                    dateFormat="MM-dd-yyyy"
                    minDate={new Date(viewData?.subscription_end_date)}
                    disabled={!isEditMode}
                  />
                )}
                rules={{ required: 'This field is mandatory' }}
              />
            </div>
          </div>}
        </div>

        <div className='incLabel mt-30'>Admin Details</div>
        <div className='container_div'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='inputFieldHead'>Name*</div>
              <input type='text'
                className='form-control'
                name="name"
                placeholder='Type here'
                {...register('name', {
                  required: true,
                })}
                disabled={planExists}
              />
              {errors.name?.type == "required" && (
                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
              )}
            </div>

            <div className='col-md-6'>
              <div className='inputFieldHead'>Email id*</div>
              <input type='text'
                className='form-control'
                name="emailId"
                placeholder='Type here'
                {...register('email', {
                  required: true,
                })}
                disabled={planExists}
              />
              {errors.email?.type == "required" && (
                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
              )}
            </div>

          </div>
        </div>
        <div className='text-right'>
          <button type="button" className='btn-cancel border-0 mr-15' onClick={onCancel}>Cancel</button>
          <button type="submit" className='onboard_btn border-0 in-blck mt-30'>{!isEditMode ? 'Onboard' : 'Update'} Company</button>
        </div>
      </form>

      <Modal show={showAddCompany.show} dialogClassName="reportConfrmModal" onHide={() => setShowAddCompany({ show: false })}>
        <Modal.Header className="sideOnRight" closeButton>
        </Modal.Header>
        <Modal.Body className="auth_modal_header border-0 mx-auto">
          <div className="modal-body reportText wd-460">
            Are you sure you would to {!isEditMode ? 'onboard this company' : 'update the onboarding details'}?
          </div>
          <Modal.Footer>
            <button type="button" className="btn-no" onClick={() => setShowAddCompany({ show: false })}>No</button>
            <button type="button" className="btn-yes" onClick={onSubmitCompanyDetails}>Yes</button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default PlanAndAdminDetails;