import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FadedDownloadDoc from '../../../../assets/images/FormImages/fadedDownloadIcon.svg';
import EyeIcon from '../../../../assets/images/EyeIcon.svg';
import ForwardPagination from '../../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../../assets/images/BackwardPagination.svg';
import mockData from '../../../../mockData/SpillsData';
import './SpillsDataModal.css';
import { getSpillsList } from '../../../../constants/apiRoutes';
import { Get } from '../../../../constants/apiMethods';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import Tooltip from '../../../../components/Tooltip/index';

const SpillsDataModal = ({ data, selectedYear, modalHeading, selectedStateIdsForBars }) => {

  // console.log(selectedStateIdsForBars)

  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const [showAgencySpills, setShowAgencySpills] = useState(false);
  const [yearSpillsList, setYearSpillsList] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState('');
  const [spillType, setSpillType] = useState(1);
  const [fluidType, setFluidType] = useState(1);
  const [viewData, setViewData] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (modalHeading == 'Reportable Spills Total') {
      getSpillsListing(1, selectedYear, 'Reportable', '', '');
    } else if (modalHeading == 'Historical Spills Total') {
      getSpillsListing(1, selectedYear, '', 'Historical', '');
    } else if (modalHeading == 'Recent Spills Total') {
      getSpillsListing(1, selectedYear, '', 'Recent', '');
    } else if (modalHeading == 'Total Volume of Oil Spill') {
      getSpillsListing(1, selectedYear, '', '', 'Oil');
    } else if (modalHeading == 'Total Volume of Produced Water') {
      getSpillsListing(1, selectedYear, '', '', 'Produced Water');
    }
  }, [showAgencySpills]);

  const getSpillsListing = (page, year, category, spillType, fluidType) => {
    setCategory(category);
    setSpillType(spillType);
    setFluidType(fluidType);
    let selectedModuleStr = '';
        selectedModuleStr = selectedStateIdsForBars.toString();
    Get(getSpillsList, token, `?page=${page}&export=false&record_status=Submitted&view=popup&agency=${showAgencySpills}&year=${year}&category=${category}&spill_type=${spillType}&fluid_type=${fluidType}&state_id=${selectedModuleStr}`, true)
      .then((response) => response)
      .then((data) => {
        if (data.success) {
          setYearSpillsList(data?.data);
          setCurrentPage(data.paginator);
          setViewData(data);
        }
      });
  };

  const getListing = () => {
    setYearSpillsList(mockData?.DummyUIData[0]?.data);
    setCurrentPage(mockData?.DummyUIData[0].paginator);
  }

  const pageChange = (page) => {
    setPage(page + 1);
    getSpillsListing(page + 1, selectedYear, category, spillType, fluidType);
  }

  const onViewSpillDetails = (id) => {
    if (!showAgencySpills) {
      navigate('../view-spill', { state: { id: id } });
    } else {
      navigate('../view-agency-spill', { state: { id: id, year: selectedYear, modalHeading: modalHeading } });
    }
  }

  const truncateString = (str, maxLength) => {
    if (!str) {
      return '';
    }

    return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
  };

  return (

    <>
      <div className='flex headHeight'>
        <div className='ml-19'><span className='listHead'>Year: </span><span className='listData'>{selectedYear}</span> </div>
        {modalHeading == 'Total Volume of Oil Spill' || modalHeading == 'Total Volume of Produced Water'
          ? <div className='ml-27'><span className='listHead'>{modalHeading}: </span><span className='listData'>{viewData?.popup_count}.0 bbl</span></div>
          : <div className='ml-27'><span className='listHead'>Total {modalHeading == 'Historical Spills Total' ? 'Historical' : modalHeading == 'Recent Spills Total' ? 'Recent' : null} Spill Count: </span><span className='listData'>{viewData?.popup_count}</span></div>}
      </div>
      <div className="row inside_head_pad ml-6-">
        <div className='flex'>
        <p className={`cursor ${!showAgencySpills ? 'active-agency' : 'agency'}`} onClick={() => setShowAgencySpills(false)}>Internal Spills</p>
          <p className={`ml-20 cursor ${showAgencySpills ? 'active-agency' : 'agency'}`} onClick={() => setShowAgencySpills(true)}>Agency Spills</p>
        </div>
      </div>
      <div className="table-responsive mt-10 w-900">
        <table className="table">
          <thead>
            <tr>
              {!showAgencySpills && <th scope="col">Spill Name</th>}
              <th scope="col">Date of Discovery</th>
              <th scope="col">State</th>
              {!showAgencySpills && (modalHeading !== 'Reportable Spills Total') && <th scope="col">Spill Categroy</th>}
              {showAgencySpills && <th scope="col">Facility Id</th>}
              {showAgencySpills && (modalHeading !== 'Reportable Spills Total') && <th scope="col">Facility Type</th>}
              <th scope="col">Facility Name</th>
              {(modalHeading == 'Reportable Spills Total' || modalHeading == 'Total Volume of Oil Spill' || modalHeading == 'Total Volume of Produced Water') ? <th scope="col">Spill Type</th> : null}
              <th scope="col">Spill Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>

            {yearSpillsList?.length > 0 && yearSpillsList?.map((item, index) => {
              return (
                <>
                  <tr className="brdrtop" key={index + 1}>
                    {!showAgencySpills && <td><Tooltip text={item?.name ? item?.name : 'NA'}>
                      {item?.name ? item?.name : 'NA'}
                    </Tooltip></td>}
                    <td>{(item?.date_of_discovery ? moment(item?.date_of_discovery).format('MM-DD-YYYY') : 'NA')}</td>
                    <td>{item?.state_name ? item?.state_name : 'NA'}</td>
                    {!showAgencySpills && (modalHeading !== 'Reportable Spills Total') && <td>{item?.category ? item?.category : 'NA'}</td>}
                    {showAgencySpills && <td>{item?.facility_id ? item?.facility_id : 'NA'}</td>}
                    {showAgencySpills && (modalHeading !== 'Reportable Spills Total') && <td>{item?.facility_type ? item?.facility_type : 'NA'}</td>}
                    {/* <td><Tooltip text={item?.facility && item?.location === null ? item?.facility : `Others (${item?.location})`}>
                      <span>{item?.facility && item?.location === null ? truncateString(item?.facility, 15) : <span>{item?.location ? `Others (${truncateString(item?.location, 15)})` : 'NA'}</span>}</span>
                    </Tooltip></td> */}
                    <td>
                    <Tooltip text={item?.facility_name || item?.location ? (item?.facility_name || `Others (${item?.location})`) : 'NA'}>
                      <span>{item?.facility_name || item?.location ? (truncateString(item?.facility_name || `Others (${item?.location})`, 15)) : 'NA'}</span>
                    </Tooltip>
                  </td>
                    {(modalHeading == 'Reportable Spills Total' || modalHeading == 'Total Volume of Oil Spill' || modalHeading == 'Total Volume of Produced Water') ? <td>{item?.spill_type ? item?.spill_type : 'NA'}</td> : null}
                    <td className={`${item?.spill_status === 'Open' ? 'text-alert' : 'text-success'}`}>{item?.spill_status ? item?.spill_status : 'NA'}</td>
                    <td>
                      <img className="editIcn" src={FadedDownloadDoc} />
                      <img src={EyeIcon} className='editIcn cursor' onClick={() => onViewSpillDetails(item?.id)} />
                    </td>
                  </tr>
                </>
              );
            })}

          </tbody>
        </table>
        {yearSpillsList?.length == 0 && <div className="row">
          <div className="col-md-12">
            <div className="text-center no-data">No Data Found</div>
          </div>
        </div>}
      </div>
      {yearSpillsList?.length > 0 && <ReactPaginate
        forcePage={currentPage.current_page - 1}
        pageCount={currentPage.total_count / 5}
        marginPagesDisplayed={2}
        onPageChange={(e) => pageChange(e.selected)}
        containerClassName={'pagination-body'}
        previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
        nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
        previousLinkClassName={'page'}
        breakClassName={'page'}
        nextLinkClassName={'page'}
        pageClassName={'page'}
        disabledClassName={'disabled'}
        activeClassName={'active'} />}
    </>

  )
}

export default SpillsDataModal