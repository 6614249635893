import React, { useState, useEffect } from "react";
import { Steps } from 'antd';
import NoDataAdded from "./NoDataAdded/NoDataAdded";
import RolesList from "./RolesList/RolesList";
import UserList from "./UserList/UserList";
import BtnCrossIcon from '../../assets/images/FormImages/btnCross.svg';
import AlertNotifier from "../../components/Notifications/AlertNotifier";
import { useNavigate } from "react-router";

const CompanyAdmin = () => {

    const navigate = useNavigate();
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const getPage = localStorage.getItem('getPage');
    const [anyUserPresent, setAnyUserPresent] = useState(false);
    let roles = [{id: 1}];
    let users = [{ id: 1 }];

    const [current, setCurrent] = useState(getPage == 1 ? 1 : 0);

    useEffect(()=> {

    }, [anyUserPresent])

    setTimeout(() => {
        localStorage.removeItem('getPage');
    }, 1)

    const handleSteps = (e) => {
        if (current == 0) {
            setCurrent(current+1);  
        } else {
            setCurrent(current-1) 
        }
    }

    const steps = [
        { label: 'Roles and Access' },
        { label: 'User Details' },
    ];

    const next = () => {
        setCurrent(current + 1);
    };

    const previous = () => {
        setCurrent(current - 1);
    };

    const items = steps.map((item) => ({
        key: item.label,
        title: item.label,
    }));

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const comingSoon = () => {
        // setSuccessRes(true);
        // setAlertType('success');
        // setShowSuccessMessage('Work in progress');
        localStorage.setItem('isSidebarShow', 'true');
        // navigate('/safety');
        const arrayOfPermissions =  JSON.parse(localStorage.getItem('permissions'));

        let modulesArr = arrayOfPermissions && arrayOfPermissions?.map(item => item.moduleName);
        if (modulesArr.includes('Safety') && modulesArr.includes('Compliance')) {
            navigate('/safety');
        } else if (modulesArr.includes('Safety')) {
            navigate('/safety');
        } else if (modulesArr.includes('Compliance')) {
            navigate('/spills-dashboard');
        }
    }

    const setUserPresence = (value) => {
        setAnyUserPresent(value);
    }

    return (
        <>
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">

                <div className="row border_head_1">
                    <div className="col-md-10">
                        <div className="head_2">Onboarding User</div>
                    </div>
                    {(current < steps.length - 1) && anyUserPresent ? <div className="col-md-2 text-right">
                        <button className='report_btn border-0' onClick={() => next()}>Next</button>
                    </div> : ''}
                </div>

                <Steps current={current} items={items} className='margin-top-36' onChange={() => handleSteps(current)}/>

                {current < steps.length - 1 && (
                    roles.length === 0 ? <NoDataAdded /> : <RolesList checkIfUserPresent={setUserPresence} />
                )}
                {current === steps.length - 1 && (
                    users.length === 0 ? <NoDataAdded /> : <UserList />
                )}

                <div className='text-right'>
                    {(current < steps.length - 1) ?
                        <>
                            {roles.length > 0 && anyUserPresent && <button type="button" className='btn-proceed in-blck mt-30' onClick={() => next()}>Next</button>}
                        </>
                        : <>
                            <button type="button" className='btn-add-data mr-15' onClick={() => previous()}>Back</button>
                            <button type="button" className='btn-event-incident in-blck mt-30' data-bs-toggle="modal" data-bs-target="#submitModal">Done</button>
                        </>
                    }
                </div>

            </div>

            <div className="modal fade" id="submitModal" tabIndex="-1" role="dialog" aria-labelledby="submitModalLabel" aria-hidden="true">
                <div className="modal-dialog reportConfrmModal" role="document">
                    <div className="modal-content">
                        <div className="modal-header sideOnRight">
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={BtnCrossIcon} />
                            </button>
                        </div>
                        <div className="modal-body reportText">
                            You are about to Onboard, are you sure you want to submit all the details?
                        </div>
                        <div className="modal-footer middle mb-4">
                            <button type="button" className="btn-no" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn-yes" data-bs-dismiss="modal" onClick={comingSoon}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyAdmin;