import React, { useState, useEffect } from "react";
import './AddManHours.css';
import { useForm, Controller } from "react-hook-form";
import { Post, Get, Put } from "../../../constants/apiMethods";
import { addUpdateManHours, getContractorList, getStateListing, manHours } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import errorIcon from "../../../assets/images/error_icon.svg";
import { Modal } from 'react-bootstrap';
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import Select from 'react-select';
import data from '../../../mockData/mockData';
import BackwardIcon from '../../../assets/images/Backward.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const AddManHours = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [manHoursModal, setAddManHoursModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem('access_token');
    const [selectedState, setSelectedState] = useState('');
    const [yearState, setYearState] = useState('');
    const [monthState, setMonthState] = useState('');
    const [contractorState, setContractorState] = useState('');
    const [userTypeState, setUserTypeState] = useState('');
    const [contractorList, setContractorList] = useState('');
    const [stateList, setStateList] = useState('');
    const [viewData, setViewData] = useState('');

    const getCurrentYear = new Date().getFullYear();
    const last5Years = [];

    for (let i = 0; i < 5; i++) {
        last5Years.push({id: i, value: getCurrentYear - i, label: getCurrentYear - i});
    }


    useEffect(() => {
        setLoading(true);
        getContractorsList();
        getStateList();
        contractorList && stateList ? setLoading(false) : setLoading(true);
    }, []);

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues,
        control,
        trigger
    } = useForm();

    const getContractorsList = () => {
        setLoading(true);
        Get(getContractorList, token, `?is_active=${true}&min=${true}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const contractorData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    props.editMode && getManHourById(contractorData, 'Contractor');
                    setContractorList(contractorData);
                }
                setLoading(false);
            });
    }

    const getStateList = () => {
        setLoading(true);
        Get(getStateListing, token, `?sort=name`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    const stateData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    props.editMode && getManHourById(stateData, 'State');
                    setStateList(stateData);
                }
            });

    }

    const routeToCompanyAdmin = () => {
        if (props.editMode && viewData?.data?.user_type == 'Contractor') {
            localStorage.setItem('isContractor', 1);
        } else {
            localStorage.setItem('isContractor', 0);
        }
        navigate('/man-hours-list');
    }

    const onSelectionChange = (selection, { name }) => {
        setValue(name, selection.value);
        trigger(name);
        if (name === 'state') {
            setSelectedState(selection)
        }
        if (name === 'monthField') {
            setMonthState(selection)
        }
        if (name === 'yearField') {
            setYearState(selection)
        }
        if (name === 'userType') {
            setUserTypeState(selection)
        }
        if (name === 'contractor') {
            setContractorState(selection)
        }
    }

    const callMethod = () => {
        setAddManHoursModal(false);
        props.editMode ? updateManHours() : addManHours()
    }

    const addManHours = () => {
        let data = {
            mh_state_id: selectedState.id,
            year: yearState.value,
            month: monthState.value,
            // contractor_id: contractorState.id,
            user_type: userTypeState.value,
            man_hours: getValues('manHours'),
        }
        if (userTypeState.value === 'Contractor') {
            let updatedObject = {
                ...data,
                contractor_id: contractorState.id
            };
            data = updatedObject;
        }
        setLoading(true);
        Post(addUpdateManHours, token, data, false)
            .then((response) => response)
            .then((data) => {
                setAddManHoursModal(true);
                if (data.success) {
                    setLoading(false);
                    setAlertType('success');
                    setSuccessDetail(data.detail)
                    setAddManHoursModal(false);
                    setSuccessRes(true);
                    setTimeout(() => {
                        if (userTypeState.value == 'Contractor') {
                            localStorage.setItem('isContractor', 1);
                        } else {
                            localStorage.setItem('isContractor', 0);
                        }
                        routeToCompanyAdmin();
                    }, 1500);
                }
                if (!data.success) {
                    setLoading(false);
                    setAlertType('failed');
                    setSuccessDetail(data.detail)
                    setAddManHoursModal(false);
                    setSuccessRes(true);
                }
                else {

                }
            });
    }

    const updateManHours = () => {
        let data = {
            mh_state_id: selectedState.id,
            year: yearState.value,
            month: monthState.value,
            // contractor_id: contractorState.id,
            user_type: userTypeState.value,
            man_hours: getValues('manHours'),
        }
        setLoading(true);
        if (userTypeState.value === 'Contractor') {
            let updatedObject = {
                ...data,
                contractor_id: contractorState.id
            };
            data = updatedObject;
        }
        Put(`${addUpdateManHours}/${location.state.id}/`, token, data, false)
            .then((response) => response)
            .then((data) => {
                setAddManHoursModal(true);
                if (data.success) {
                    setLoading(false);
                    setAlertType('success');
                    setSuccessDetail(data.detail)
                    setAddManHoursModal(false);
                    setSuccessRes(true);
                    setTimeout(() => {
                        if (userTypeState.value == 'Contractor') {
                            localStorage.setItem('isContractor', 1);
                        } else {
                            localStorage.setItem('isContractor', 0);
                        }
                        routeToCompanyAdmin();
                    }, 1500);
                }
                if (!data.success) {
                    setLoading(false);
                    setAlertType('failed');
                    setSuccessDetail(data.detail)
                    setAddManHoursModal(false);
                    setSuccessRes(true);
                }
            });
    }

    const patchManHours = (res, item, type) => {
        let contractorObj;
        let stateObj;
        setValue('manHours', res.data.man_hours);
        trigger('manHours');
        let yearObj = {
            value: res.data.year,
            label: res.data.year,
        }
        let userTypeObj = {
            value: res.data.user_type,
            label: res.data.user_type,
        }
        if (type === 'Contractor' && res.data.user_type === 'Contractor') {
            contractorObj = {
                value: res.data.contractor,
                label: res.data.contractor,
                // value: item.filter(resContractor => resContractor.id === res.data.contractor_id)[0].name,
                // label: item.filter(resContractor => resContractor.id === res.data.contractor_id)[0].name,
                // id: item.filter(resContractor => resContractor.id === res.data.contractor_id)[0].id,
            }
            setContractorState(contractorObj);
            setValue('contractor', contractorObj);

        }
        else {
            stateObj = {
                // value: item.filter(resState => resState.id === res.data.mh_state_id)[0].name,
                // label: item.filter(resState => resState.id === res.data.mh_state_id)[0].name,
                // id: item.filter(resState => resState.id === res.data.mh_state_id)[0].id,
                value: res.data.state,
                label: res.data.state,
            }
            setSelectedState(stateObj);
            setValue('state', stateObj);
        }
        const selectedMonth = data.MonthData.find(item => item.value === res.data.month);

        setYearState(yearObj);
        setUserTypeState(userTypeObj);
        setMonthState(selectedMonth);


        setValue('yearField', yearObj);
        setValue('userType', userTypeObj);
        setValue('monthField', selectedMonth);
    }

    const onSubmit = () => {
        setAddManHoursModal(true);
    };

    const getManHourById = (res, type) => {
        setLoading(true);
        Get(manHours, token, `?is_active=${true}&id=${location.state.id}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    patchManHours(data, res, type);
                    setViewData(data);
                    setLoading(false);
                }
            });
    };

    return (
        <div className="full-cont">
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertText={successDetail} alertType={alertType} setVisibility={setVisibility} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border_head_1">
                    <div className="head_2"><img src={BackwardIcon} className="cursor" onClick={routeToCompanyAdmin} />{props.editMode ? 'Update Man Hours' : 'Add Man Hours'}</div>
                </div>
                <div className='row mt-30 light-container'>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>State*</div>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="state"
                                        placeholder='Choose State'
                                        options={stateList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={selectedState}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.state && (
                                <p className="error"> <img src={errorIcon} /> {errors.state.message} </p>
                            )}
                        </div>
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Year & Month*</div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Controller
                                        name="yearField"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="yearField"
                                                placeholder='Choose Year'
                                                options={last5Years}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={yearState}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {errors.yearField && (
                                        <p className="error"> <img src={errorIcon} /> {errors.yearField.message} </p>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <Controller
                                        name="monthField"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                classNamePrefix="an-simple-select"
                                                name="monthField"
                                                placeholder='Choose Month'
                                                options={data.MonthData}
                                                onChange={(selection) => onSelectionChange(selection, field)}
                                                value={monthState}
                                            />
                                        )}
                                        rules={{ required: 'This field is mandatory' }} // Validation rule for required
                                    />
                                    {errors.monthField && (
                                        <p className="error"> <img src={errorIcon} /> {errors.monthField.message} </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-30">
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>User Type*</div>
                            <Controller
                                name="userType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="userType"
                                        placeholder='Choose User Type'
                                        options={data.EmpDdnData}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={userTypeState}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.userType && (
                                <p className="error"> <img src={errorIcon} /> {errors.userType.message} </p>
                            )}
                        </div>
                        {userTypeState.value == 'Contractor' && <div className='col-md-6'>
                            <div className='inputFieldHead'>Contractor*</div>
                            <Controller
                                name="contractor"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        classNamePrefix="an-simple-select"
                                        name="contractor"
                                        placeholder='Choose Contractor'
                                        options={contractorList}
                                        onChange={(selection) => onSelectionChange(selection, field)}
                                        value={contractorState}
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.contractor && (
                                <p className="error"> <img src={errorIcon} /> {errors.contractor.message} </p>
                            )}
                        </div>}

                        <div className={userTypeState.value === 'Contractor' ? 'col-md-6 mt-30' : 'col-md-6'}>

                            <div className='inputFieldHead'>Man Hours*</div>
                            <input
                                type='number'
                                className='form-control'
                                name="manHours"
                                placeholder='Type here'
                                {...register("manHours", { required: true })}
                            />
                            {errors.manHours?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                        </div>
                        <div className='col-md-6'>

                        </div>
                    </div>
                </div>

                <div className='text-right'>
                    <button type="button" className='btn-add-data mr-15 border-0' onClick={routeToCompanyAdmin}>Cancel</button>
                    <button type="submit" className='btn-event-incident in-blck mt-30'>{props.editMode ? 'Update' : 'Submit'}</button>
                </div>

                <Modal show={manHoursModal} dialogClassName="reportConfrmModal" onHide={() => setAddManHoursModal(false)}>
                    <Modal.Header className="sideOnRight" closeButton>
                    </Modal.Header>
                    <Modal.Body className="auth_modal_header border-0 mx-auto">
                        <div className="modal-body reportText">
                            Are you sure you would like to {props.editMode ? 'Update' : 'Add'} the Man hours?
                        </div>
                        <Modal.Footer>
                            <button type="button" className="btn-no" data-bs-dismiss="modal" onClick={() => { setAddManHoursModal(false) }}>No</button>
                            <button type="button" className="btn-yes" onClick={callMethod}>Yes</button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </form>
        </div>

    )
}

export default AddManHours;