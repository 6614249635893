import Feedback from "react-bootstrap/esm/Feedback";
import Air from "../pages/Air/Air";
import Benchmarking from "../pages/Benchmarking/Benchmarking";
import Compliance from "../pages/Compliance/Complaince";
import Drag from "../pages/DragNDrop/Drag";
import Esg from "../pages/ESG/ESG";
import MAndA from "../pages/M&A/M&A";
import Overview from "../pages/Overview/Overview";
import Permitting from "../pages/Permitting/Permitting";
import Dashboard from "../pages/Safety/Dashboard/Dashboard";
import EventList from "../pages/Safety/EventList/EventList";
import ReportEvent from "../pages/Safety/ReportEvent/ReportEvent";
import ReportIncident from "../pages/Safety/ReportIncident/ReportIncident";
import ViewEvent from "../pages/Safety/ViewReportDetails/ViewEvent";
import ViewIncident from "../pages/Safety/ViewReportDetails/ViewIncident";
import CustomizeEventLabel from "../pages/Settings/LabelCustomization/CustomizeEventLabel";
import CustomizeIncidentLabel from "../pages/Settings/LabelCustomization/CustomizeIncidentLabel";
import Contact from "../pages/Support/Contact";
import Faq from "../pages/Support/Faq";
import Onboarding from "../pages/LiticoAdmin/CompanyOnboarding/CompanyListing/CompanyListing";
import StateCity from "../pages/LiticoAdmin/StateCity/StateCity";
import SubscriptionPlan from "../pages/LiticoAdmin/SubscriptionPlan/SubscriptionPlan";
import CreatePlan from "../pages/LiticoAdmin/SubscriptionPlan/CreatePlan/CreatePlan";
import CreateCompany from "../pages/LiticoAdmin/CompanyOnboarding/CreateCompany/CreateCompany";
import CreateUpdateStateCity from "../pages/LiticoAdmin/StateCity/CreateUpdateStateCity/CreateUpdateStateCity";
import LiticoAdminProfile from "../pages/LiticoAdmin/LiticoAdminProfile/LiticoAdminProfile";
import CompanyDetails from "../pages/LiticoAdmin/CompanyOnboarding/CompanyDetails/CompanyDetails";
import CompanyAdmin from "../pages/CompanyAdmin/CompanyAdmin";
import AddRoles from "../pages/CompanyAdmin/AddRoles/AddRoles";
import CompanyAdminProfile from "../pages/CompanyAdmin/CompanyAdminProfile/CompanyAdminProfile";
import AddUsers from "../pages/CompanyAdmin/AddUsers/AddUsers";
import ChangePassword from "../pages/Profile/ChangePassword/ChangePassword";
import AddManHours from "../pages/ManHours/AddManHours/AddManHours";
import ManHoursListing from "../pages/ManHours/ManHoursListing/ManHoursListing";
import ContractorManagement from "../pages/CompanyAdmin/Contractor/Contractor";
import FacilityManagement from "../pages/CompanyAdmin/Facility/Facility";
import RolesList from "../pages/CompanyAdmin/RolesList/RolesList";
import UserList from "../pages/CompanyAdmin/UserList/UserList";
import AddFacility from "../pages/CompanyAdmin/Facility/AddFacility";
import ViewFacility from "../pages/CompanyAdmin/Facility/ViewFacility";
import ProtectedRoute from "./ProtectedRoute";
import SpillsManagement from "../pages/Compliance/SpillsManagement/SpillsManagement";
import AddUpdateSpill from "../pages/Compliance/SpillsManagement/AddUpdateSpill/AddUpdateSpill";
import ViewSpill from "../pages/Compliance/SpillsManagement/ViewSpill/ViewSpill";
import Wildlife from "../pages/Compliance/Wildlife/Wildlife";
import Inspection from "../pages/Compliance/Inspection/Inspection";
import Production from "../pages/Compliance/Production/Production";
import Complaints from "../pages/Compliance/Complaints/Dashboard/index";
import Remediation from "../pages/Compliance/Remediation/Remediation";
import AddUpdateComplaint from "../pages/Compliance/Complaints/AddUpdateComplaint";
import ComplaintsManagement from "../pages/Compliance/Complaints/Management";
import ViewDirectComplaint from "../pages/Compliance/Complaints/ViewDirectComplaint";
import ViewAgencyComplaint from "../pages/Compliance/Complaints/ViewAgencyComplaint";
import SpillsDashboard from "../pages/Compliance/SpillsManagement/SpillDashboard/SpillDashboard";
import ViewAgencySpill from "../pages/Compliance/SpillsManagement/ViewAgencySpill/ViewAgencySpill";



export const PrivateRoutes = 
  [
    //user and company admin routes
    {
      'element': <ProtectedRoute Component={Dashboard} moduleName={'Safety'} />,
      'path': 'safety',
      'headerTitle': 'Dashboard',
    },
    {
      'element': <ProtectedRoute Component={EventList} moduleName={'Safety'} />,
      'path': 'event-list',
      'headerTitle': 'EventList',
    },
    {
      'element': <ProtectedRoute Component={ReportEvent} editMode={false} moduleName={'Safety'} />,
      'path': 'add-event',
      'headerTitle': 'ReportEvent',
    },
    {
      'element': <ProtectedRoute Component={ReportEvent} editMode={true} moduleName={'Safety'} />,
      'path': 'update-event',
      'headerTitle': 'ReportEvent',
    },
    {
      'element': <ProtectedRoute Component={ReportIncident} editMode={false} moduleName={'Safety'} />,
      'path': 'add-incident',
      'headerTitle': 'ReportIncident',
    },
    {
      'element': <ProtectedRoute Component={ReportIncident} editMode={true} moduleName={'Safety'} />,
      'path': 'update-incident',
      'headerTitle': 'UpdateIncident',
    },
    {
      'element': <ProtectedRoute Component={ViewEvent} moduleName={'Safety'} />,
      'path': 'event-details',
      'headerTitle': 'ViewEvent',
    },
    {
      'element': <ProtectedRoute Component={ViewIncident} moduleName={'Safety'} />,
      'path': 'incident-details',
      'headerTitle': 'ViewIncident',
    },
    {
      'element': <CustomizeEventLabel />,
      'path': 'customize-event-label',
      'headerTitle': 'ViewIncident',
    },
    {
      'element': <CustomizeIncidentLabel />,
      'path': 'customize-incident-label',
      'headerTitle': 'ViewIncident',
    },
    {
      'element': <Drag />,
      'path': 'drag',
      'headerTitle': 'Drag',
    },
    {
      'element': <ProtectedRoute Component={Air} moduleName={'Air'} />,
      'path': 'air',
      'headerTitle': 'Air',
    },
    {
      'element': <ProtectedRoute Component={Benchmarking} moduleName={'Benchmarking'} />,
      'path': 'benchmarking',
      'headerTitle': 'Benchmarking',
    },
    {
      'element': <ProtectedRoute Component={Compliance} moduleName={'Compliance'} />,
      'path': 'compliance',
      'headerTitle': 'Compliance',
    },
    {
      'element': <ProtectedRoute Component={Esg} moduleName={'ESG'} />,
      'path': 'esg',
      'headerTitle': 'Esg',
    },
    {
      'element': <ProtectedRoute Component={MAndA} moduleName={'M&A'} />,
      'path': 'm&a',
      'headerTitle': 'M&A',
    },
    {
      'element': <ProtectedRoute Component={Overview} moduleName={'Overview'} />,
      'path': 'overview',
      'headerTitle': 'Overview',
    },
    {
      'element': <ProtectedRoute Component={Permitting} moduleName={'Permitting'} />,
      'path': 'permitting',
      'headerTitle': 'Permitting',
    },
    {
      'element': <ProtectedRoute Component={Faq} moduleName={'Faq'} />,
      'path': 'faq',
      'headerTitle': 'Faq',
    },
    {
      'element': <ProtectedRoute Component={Contact} moduleName={'Contact'} />,
      'path': 'contact',
      'headerTitle': 'Contact',
    },
    {
      'element': <ProtectedRoute Component={Feedback} moduleName={'Feedback'} />,
      'path': 'feedback',
      'headerTitle': 'Feedback',
    },
    
    //Super User Routes
    {
      'element': <ProtectedRoute Component={CreatePlan} moduleName={'Subscription'} />,
      'path': 'create-subscription-plan',
      'headerTitle': 'CreatePlan',
    },
    {
      'element': <ProtectedRoute Component={SubscriptionPlan} moduleName={'Subscription'} />,
      'path': 'subscriptions',
      'headerTitle': 'Subscriptions',
    },
    {
      'element': <ProtectedRoute Component={Onboarding} moduleName={'Onboarding'} />,
      'path': 'company-list',
      'headerTitle': 'Onboarding',
    },
    {
      'element': <ProtectedRoute Component={StateCity} moduleName={'StateCity'} />,
      'path': 'state-city',
      'headerTitle': 'StateCity',
    }, 
    {
      'element': <ProtectedRoute Component={CreateCompany} editMode={false} moduleName={'Onboarding'}/>,
      'path': 'create-company',
      'headerTitle': 'CreateCompany',
    },
    {
      'element': <ProtectedRoute Component={CreateCompany} editMode={true} moduleName={'Onboarding'} />,
      'path': 'update-company',
      'headerTitle': 'UpdateCompany',
    },
    {
      'element': <ProtectedRoute Component={CreateUpdateStateCity} editMode={false} moduleName={'StateCity'} />,
      'path': 'create-state-city',
      'headerTitle': 'CreateUpdateStateCity',
    },
    {
      'element': <ProtectedRoute Component={CreateUpdateStateCity} editMode={true} moduleName={'StateCity'} />,
      'path': 'update-state-city',
      'headerTitle': 'CreateUpdateStateCity',
    },
    {
      'element': <ProtectedRoute Component={LiticoAdminProfile} moduleName={'LiticoAdminProfile'} />,
      'path': 'litico-admin-profile',
      'headerTitle': 'LiticoAdminProfile',
    },
    {
      'element': <ProtectedRoute Component={CompanyDetails} moduleName={'Onboarding'}  />,
      'path': 'company-details',
      'headerTitle': 'CompanyDetails',
    },
    //company admin routes
    {
      'element': <ProtectedRoute Component={CompanyAdmin} moduleName={'CompanyAdmin'} />,
      'path': 'company-admin',
      'headerTitle': 'CompanyAdmin',
    },
    {
      'element': <ProtectedRoute Component={RolesList} moduleName={'CompanyAdmin'} />,
      'path': 'roles-list',
      'headerTitle': 'RolesList',
    },
    {
      'element': <ProtectedRoute Component={AddRoles} moduleName={'CompanyAdmin'} editMode={false} />,
      'path': 'add-company-role',
      'headerTitle': 'AddCompanyRole',
    }, 
    {
      'element': <ProtectedRoute Component={AddRoles} moduleName={'CompanyAdmin'} editMode={true} />,
      'path': 'edit-company-role',
      'headerTitle': 'EditCompanyRole',
    }, 
    {
      'element': <ProtectedRoute Component={CompanyAdminProfile} moduleName={'CompanyAdminProfile'} />,
      'path': 'company-admin-profile',
      'headerTitle': 'CompanyAdminProfile',
    },
    {
      'element': <ProtectedRoute Component={CompanyAdminProfile} moduleName={'CompanyAdminProfile'} />,
      'path': 'user-profile',
      'headerTitle': 'UserProfile',
    },
    {
      'element': <ProtectedRoute Component={UserList} moduleName={'CompanyAdmin'} />,
      'path': 'users-list',
      'headerTitle': 'UserList',
    },
    {
      'element': <ProtectedRoute Component={AddUsers} moduleName={'CompanyAdmin'} editMode={false}/>,
      'path': 'add-users',
      'headerTitle': 'AddUsers',
    },
    {
      'element': <ProtectedRoute Component={AddUsers} moduleName={'CompanyAdmin'} editMode={true}/>,
      'path': 'update-users',
      'headerTitle': 'AddUsers',
    },
    {
      'element': <ProtectedRoute Component={ManHoursListing} moduleName={'ManHours'} />,
      'path': 'man-hours-list',
      'headerTitle': 'ManHours',
    },
    {
      'element': <ProtectedRoute Component={AddManHours} moduleName={'ManHours'} editMode={false}/>,
      'path': 'add-man-hours',
      'headerTitle': 'AddManHours',
    },
    {
      'element': <ProtectedRoute Component={AddManHours} moduleName={'ManHours'} editMode={true}/>,
      'path': 'update-man-hours',
      'headerTitle': 'AddManHours',
    },
    {
      'element': <ProtectedRoute Component={ContractorManagement} moduleName={'ContractorManagement'} />,
      'path': 'contractor-management',
      'headerTitle': 'ContractorManagement',
    }, 
    {
      'element': <ProtectedRoute Component={FacilityManagement} moduleName={'FacilityManagement'} />,
      'path': 'facility-management',
      'headerTitle': 'FacilityManagement',
    },
    {
      'element': <ProtectedRoute Component={AddFacility} moduleName={'FacilityManagement'} editMode={false}/>,
      'path': 'add-facility',
      'headerTitle': 'AddFacility',
    },
    {
      'element': <ProtectedRoute Component={AddFacility} moduleName={'FacilityManagement'} editMode={true}/>,
      'path': 'update-facility',
      'headerTitle': 'AddFacility',
    },
    {
      'element': <ProtectedRoute Component={ViewFacility} moduleName={'FacilityManagement'} />,
      'path': 'view-facility',
      'headerTitle': 'ViewFacility',
    },

    //Operational Compliance routes

    {
      'element': <ProtectedRoute Component={SpillsDashboard} moduleName={'Compliance'} />,
      'path': 'spills-dashboard',
      'headerTitle': 'SpillsDashboard',
    },
    {
      'element': <ProtectedRoute Component={SpillsManagement} moduleName={'Compliance'} />,
      'path': 'spills-management',
      'headerTitle': 'SpillsManagement',
    },
    {
      'element': <ProtectedRoute Component={AddUpdateSpill} moduleName={'Compliance'} editMode={false}/>,
      'path': 'add-spill',
      'headerTitle': 'AddUpdateSpill',
    },
    {
      'element': <ProtectedRoute Component={AddUpdateSpill} moduleName={'Compliance'} editMode={true}/>,
      'path': 'update-spill',
      'headerTitle': 'AddUpdateSpill',
    },
    {
      'element': <ProtectedRoute Component={ViewSpill} moduleName={'Compliance'} />,
      'path': 'view-spill',
      'headerTitle': 'ViewSpill',
    },
    {
      'element': <ProtectedRoute Component={ViewAgencySpill} moduleName={'Compliance'} />,
      'path': 'view-agency-spill',
      'headerTitle': 'ViewAgencySpill',
    },
    {
      'element': <ProtectedRoute Component={Remediation} moduleName={'Compliance'} />,
      'path': 'remediation',
      'headerTitle': 'Remediation',
    },
    {
      'element': <ProtectedRoute Component={Complaints} moduleName={'Compliance'} />,
      'path': 'complaints-dashboard',
      'headerTitle': 'ComplaintsDashboard',
    },
    {
      'element': <ProtectedRoute Component={AddUpdateComplaint} moduleName={'Compliance'} editMode={false} />,
      'path': 'add-complaint',
      'headerTitle': 'AddComplaint',
    },
    {
      'element': <ProtectedRoute Component={AddUpdateComplaint} moduleName={'Compliance'} editMode={true} />,
      'path': 'update-complaint',
      'headerTitle': 'UpdateComplaint',
    },
    {
      'element': <ProtectedRoute Component={ComplaintsManagement} moduleName={'Compliance'} />,
      'path': 'complaints-management',
      'headerTitle': 'ComplaintsManagement',
    },
    {
      'element': <ProtectedRoute Component={ViewDirectComplaint} moduleName={'Compliance'} />,
      'path': 'view-direct-complaint',
      'headerTitle': 'ViewDirectComplaint',
    },
    {
      'element': <ProtectedRoute Component={ViewAgencyComplaint} moduleName={'Compliance'} />,
      'path': 'view-agency-complaint',
      'headerTitle': 'ViewAgencyComplaint',
    },
    {
      'element': <ProtectedRoute Component={Production} moduleName={'Compliance'} />,
      'path': 'production',
      'headerTitle': 'Production',
    },
    {
      'element': <ProtectedRoute Component={Inspection} moduleName={'Compliance'} />,
      'path': 'inspection',
      'headerTitle': 'Inspection',
    },
    {
      'element': <ProtectedRoute Component={Wildlife} moduleName={'Compliance'} />,
      'path': 'wildlife',
      'headerTitle': 'Wildlife',
    },
    {
      'element': <ChangePassword />,
      'path': 'change-password',
      'headerTitle': 'ChangePassword',
    },
  ];