import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from "../../../../components/Loader";
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import ModuleInfo from "../../../../components/ModuleInfo/ModuleInfo";
import data from '../../../../mockData/mockData';
import spillsData from '../../../../mockData/SpillsData';
import spillsChartData from "../../../../mockData/SpillsChartData";
import CustomizeButtonIcon from '../../../../assets/images/CustomizeButtonIcon.svg';
import ExportButtonIcon from '../../../../assets/images/ExportButtonIcon.svg';
import DropCollapseDown from '../../../../assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../../../assets/images/DropCollapseDownSelected.svg';
import FilterTickIcon from '../../../../assets/images/FilterTickIcon.svg';
import infoIcon from '../../../../assets/images/Info.svg';
import Button from "react-bootstrap/Button";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router";
import DashboardCount from "../../../Safety/Dashboard/DashboardCount/DashboardCount";
import { Get, Post } from "../../../../constants/apiMethods";
import { customizeGraphToggle, getSpillsList, getStateListing, spillsDashboardCount, spillsDashboardGraphData } from "../../../../constants/apiRoutes";
import colorPalleteData from '../../../../constants/themePallete';
import Drag from "../../../DragNDrop/Drag";
import { Modal } from "react-bootstrap";
import SpillsDataModal from "../SpillsDataModal/SpillsDataModal";
import ComplianceSubModuleDropdown from "../../../../components/ComplianceSubModuleDropdown";
import pattern from 'patternomaly';
import "./SpillDashboard.css";
import CheckboxSelect from "../../../../components/formBuilder/CheckboxSelect";

const SpillsDashboard = () => {

    const role = localStorage.getItem('role');
    let permissionsObj;
    const chartsData = JSON.parse(localStorage.getItem('graphCustomization'));
    const spillsChartData = chartsData?.data?.spillsChartData ? chartsData?.data?.spillsChartData : spillsData?.spillsGraphCustomizationData?.data?.spillsCharts;

    if (role == 'user') {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        //safety
        let safetyPermissionObject = permissions.filter(item => item.moduleName.toLowerCase() === 'compliance');

        permissionsObj = {
            dashboardAccess: safetyPermissionObject[0].dashboardChecked,
            exportAccess: safetyPermissionObject[0].exportAccess,
            readAccess: safetyPermissionObject[0].readAccess,
            writeAccess: safetyPermissionObject[0].writeAccess
        }
    }

    const navigate = useNavigate();
    const target = useRef(null);
    const token = localStorage.getItem('access_token');
    const [resDetail, setResDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [loading, setLoading] = useState(false);
    const [globalFilterDuration, setGlobalFilterDuration] = useState('yearly');
    const [dashboardCountObj, setDashboardCountObj] = useState([]);
    const [finalArr, setFinalArr] = useState([]);
    const [spillsTotalAndIntensityIntData, setSpillsTotalAndIntensityEmpData] = useState('');
    const [spillsTotalAndIntensityTexasData, setSpillsTotalAndIntensityContData] = useState('');
    const [spillsTotalAndIntensityColoradoData, setSpillsTotalAndIntensityCombData] = useState('');
    const [showSpillsTotalAndIntensityGraph, setShowSpillsTotalAndIntensityGraph] = useState(false);
    const [spillsTotalAndIntensityInjuryNearestTwenty, setSpillsTotalAndIntensityInjuryNearestTwenty] = useState('');
    const [spillsTotalAndIntensityInjuryGraphData, setSpillsTotalAndIntensityInjuryGraphData] = useState();
    const [spillsTotalAndIntensityTrendlineLimit, setSpillsTotalAndIntensityTrendlineLimit] = useState();
    const [leadShow, setLeadShow] = useState(false);
    const [filterValue, setFilterValue] = useState('Number & Trend');
    const [handleLocalCutomizationPopUp, setLocalCustomizationPopUp] = useState(false);
    const [selectedGraphTypeFilter, setSelectedGraphTypeFilter] = useState('');
    const [spillsTotalAndIntensityInjurySelectedEmpIds, setSpillsTotalAndIntensityInjurySelectedEmpIds] = useState('');
    const [spillsTotalAndIntensityDataset, setSpillsTotalAndIntensityDataset] = useState([]);
    const [spillsTotalAndIntensityDatasetForFilter, setSpillsTotalAndIntensityDatasetForFilter] = useState([]);
    const [selectedSpillsTotalAndIntensityChart, setSelectedSpillsTotalAndIntensityChart] = useState(3);
    const [spillsTotalAndIntensityFilteredArrayBars, setSpillsTotalAndIntensityFilteredArrayBars] = useState([]);
    const [spillsTotalAndIntensityInjuryApiData, setSpillsTotalAndIntensityInjuryApiData] = useState([]);
    const [selectedStatesIdsForBars, setSelectedStatesIdsForBars] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [spillsTotalAndIntensityData, setSpillsTotalAndIntensityData] = useState([]);
    const [spillsIntensityNearestTwenty, setSpillsIntensityNearestTwenty] = useState('');
    const [selectedStateList, setSelectedStateList] = useState([]);
    const [show, setShow] = useState(false);
    const [barIndex, setBarIndex] = useState('');
    const [yearSpillsList, setYearSpillsList] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [modalHeading, setModalHeading] = useState('');
    const [globalModal, setGlobalModal] = useState(false);

    // historical versus recent spills
    const [showHistoricalVersusRecentSpillsGraph, setShowHistoricalVersusRecentSpillsGraph] = useState(false);
    const [historicalVersusRecentSpillsDataset, setHistoricalVersusRecentSpillsDataset] = useState([]);
    const [historicalVersusRecentSpillsTrendlineLimit, setHistoricalVersusRecentSpillsTrendlineLimit] = useState([]);
    const [historicalVersusRecentSpillsTotalLineDataForHistorical, setHistoricalVersusRecentSpillsTotalLineDataForHistorical] = useState([]);
    const [historicalVersusRecentSpillsTotalLineDataForRecent, setHistoricalVersusRecentSpillsTotalLineDataForRecent] = useState([]);
    const [historicalVersusRecentyDataHistorical, setHistoricalVersusRecentyDataHistorical] = useState([]);
    const [historicalVersusRecentyDataRecent, setHistoricalVersusRecentyDataRecent] = useState([]);
    const [selectedHistoricalVersusRecentChart, setSelectedHistoricalVersusRecentChart] = useState(3);
    const [historicalVersusRecentSpillsSelectedIds, setHistoricalVersusRecentSpillsSelectedIds] = useState('');
    const [selectedStateIdsForHistoricalVersusRecentSpills, setSelectedStateIdsForHistoricalVersusRecentSpills] = useState([]);
    const [spillsHistoricalVersusRecentSpillsDatasetForFilter, setSpillsHistoricalVersusRecentSpillsDatasetForFilter] = useState([]);
    const [historicalVersusRecentSpillsSelectedTypeIds, setHistoricalVersusRecentSpillsSelectedTypeIds] = useState('');
    const [selectedStateIdsForHistoricalVersusRecentTypeSpills, setSelectedStateIdsForHistoricalVersusRecentTypeSpills] = useState([]);
    const [historicalVersusRecentSpillTypeFilter, setHistoricalVersusRecentSpillTypeFilter] = useState(false);
    const [transformDataOfHistoricalVersusRecentSpillsGraph, setTransformDataOfHistoricalVersusRecentSpillsGraph] = useState([]);
    const [historicalVersusRecentSpillsApiData, setHistoricalVersusRecentSpillsApiData] = useState([]);

    // volume Of oil and produced water
    const [showVolumeOfOilAndProducedWaterGraph, setShowVolumeOfOilAndProducedWaterGraph] = useState(false);
    const [volumeOfOilAndProducedWatersDataset, setVolumeOfOilAndProducedWaterDataset] = useState([]);
    const [volumeOfOilAndProducedTrendlineLimit, setVolumeOfOilAndProducedTrendlineLimit] = useState([]);
    const [volumeOfOilAndProducedTotalLineDataForOil, setVolumeOfOilAndProducedTotalLineDataForOil] = useState([]);
    const [volumeOfOilAndProducedTotalLineDataForWater, setVolumeOfOilAndProducedTotalLineDataForWater] = useState([]);
    const [volumeOfOilAndProducedWaterOil, setVolumeOfOilAndProducedWaterOil] = useState([]);
    const [volumeOfOilAndProducedWaterWater, setVolumeOfOilAndProducedWaterWater] = useState([]);
    const [selectedVolumeOfOilAndProducedChart, setSelectedVolumeOfOilAndProducedChart] = useState(3);
    const [volumeOfOilAndProducedWaterSelectedIds, setVolumeOfOilAndProducedWaterSelectedIds] = useState('');
    const [selectedStateIdsForVolumeOfOilAndProducedWaterSpills, setSelectedStateIdsVolumeOfOilAndProducedWaterSpills] = useState([]);
    const [volumeOfOilAndProducedWaterDatasetForFilter, setVolumeOfOilAndProducedWaterDatasetForFilter] = useState([]);
    const [volumeOfOilAndProducedWaterSelectedTypeIds, setVolumeOfOilAndProducedWaterSelectedTypeIds] = useState('');
    const [selectedvolumeOfOilAndProducedWaterTypeSpills, setSelectedVolumeOfOilAndProducedWaterTypeSpills] = useState([]);
    const [volumeOfOilAndProducedWaterFilter, setVolumeOfOilAndProducedWaterFilter] = useState(false);
    const [transformDataOfVolumeOfOilAndProducedWaterSpills, setTransformDataOfVolumeOfOilAndProducedWaterSpills] = useState([]);
    const [volumeOfOilAndProducedWaterSpillsApiData, setVolumeOfOilAndProducedWaterSpillsApiData] = useState([]);

    //chart sate dropdown metadata
    const [spillsTotalAndIntensityIntType, setSpillsTotalAndIntensityIntType] = useState('bar');
    const [selectedStateIds, setSelectedStateIds] = useState([]);
    const [actualTotalAndIntensityData, setActualTotalAndIntensityData] = useState([]);
    const [actualHistoricalVersusRecentSpillsData, setActualHistoricalVersusRecentSpillsData] = useState([]);
    const [actualVolumeOfOilAndProducedWaterData, setActualVolumeOfOilAndProducedWaterData] = useState([]);

    const [globalFilterApplied, setGlobalFilterApplied] = useState(false);
    const [spillsCharts, setSpillsCharts] = useState(spillsChartData);
    const [selectedspillsChartsGlobalFilters, setSelectedspillsChartsGlobalFilters] = useState([]);
    const [initiaSpillsIndicator, setInitialSpillsIndicator] = useState([]);
    const [initialGlobalSpillsFilter, setInitialGlobalSpillsFilter] = useState([]);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const [graphList, setGraphList] = useState(
        [
            'Reportable Spills Total & Intensity',
            'Historical versus Recent Spills Total',
            'Volume of Oil & Produced Water Spills'
        ]);

    const getPatternForBar = (color, stack) => {
        if (stack) {
            if (stack == 'stack1') {
                return color;
            } else {
                return pattern.draw('diagonal-right-left', color);
            }
        } else {
            return color;
        }
    }

    const generateReportableSpillsTotalAndIntensityDatasets = (chartType, label, data, color, isBarType, dataAxisLabel) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [color],
            borderColor: [color],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: dataAxisLabel,
            pointRadius: 3,
        };

        if (isBarType) {
            return {
                type: 'bar',
                ...commonProperties
            };
        } else {
            return {
                type: chartType,
                ...commonProperties
            };
        }
    }

    const generateHistoricalVersusRecentSpillsTotalDatasets = (chartType, label, data, color, isBarType, dataAxisLabel, stack) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [getPatternForBar(color, stack)],
            borderColor: [getPatternForBar(color, stack)],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: dataAxisLabel,
            pointRadius: 3,
            stack: stack,
        };

        if (isBarType) {
            return {
                type: 'bar',
                ...commonProperties
            };
        } else {
            return {
                type: chartType,
                ...commonProperties
            };
        }
    }

    const generateVolumeOfOilAndProducedWaterDatasets = (chartType, label, data, color, isBarType, dataAxisLabel, stack) => {
        const commonProperties = {
            label: label,
            data: data,
            backgroundColor: [getPatternForBar(color, stack)],
            borderColor: [getPatternForBar(color, stack)],
            borderWidth: 2,
            borderRadius: 6,
            barThickness: 20,
            maxBarThickness: 15,
            barPercentage: 0.5,
            yAxisID: dataAxisLabel,
            pointRadius: 3,
            stack: stack,
        };

        if (isBarType) {
            return {
                type: 'bar',
                ...commonProperties
            };
        } else {
            return {
                type: chartType,
                ...commonProperties
            };
        }
    }

    const onBarClick = (graphName, year, dataIndex) => {
        if (graphName == 'Reportable Spills Total & Intensity') {
            let selectedStatesofReportable = [];
            if (selectedStateIds?.length == 0) {
                selectedStatesofReportable = [...selectedStateList];
            } else {
                selectedStatesofReportable = [...selectedStateIds];
            }
            const selectedStateIdsArrayofReportable = selectedStatesofReportable.map(name => {
                const item = stateList.find(item => item.name === name);
                return item ? item.id : null;
            });
            setModalHeading('Reportable Spills Total');
            setSelectedStatesIdsForBars(selectedStateIdsArrayofReportable)
        } else if (graphName == 'Historical versus Recent Spills Total') {
            let selectedStatesofHistorical = [];
            if (historicalVersusRecentSpillTypeFilter) {
                setSelectedStateIdsForHistoricalVersusRecentSpills([...selectedStateList]);
                selectedStatesofHistorical = [...selectedStateList];
            } else {
                if (selectedStateIdsForHistoricalVersusRecentSpills?.length == 0) {
                    selectedStatesofHistorical = [...selectedStateList];
                } else {
                    selectedStatesofHistorical = [...selectedStateIdsForHistoricalVersusRecentSpills];
                }
            }
            const selectedStateIdsArrayofHistorical = selectedStatesofHistorical.map(name => {
                const item = stateList.find(item => item.name === name);
                return item ? item.id : null;
            });
            setSelectedStatesIdsForBars(selectedStateIdsArrayofHistorical)
            if (dataIndex < selectedStatesofHistorical?.length) {
                setModalHeading('Historical Spills Total');
            } else {
                setModalHeading('Recent Spills Total');
            }
        } else if (graphName == 'Volume of Oil & Produced Water Spills') {
            let selectedStatesofVolume = [];
            if (volumeOfOilAndProducedWaterFilter) {
                setSelectedStateIdsVolumeOfOilAndProducedWaterSpills([...selectedStateList]);
                selectedStatesofVolume = [...selectedStateList];
            } else {
                if (selectedStateIdsForVolumeOfOilAndProducedWaterSpills?.length == 0) {
                    selectedStatesofVolume = [...selectedStateList];
                } else {
                    selectedStatesofVolume = [...selectedStateIdsForVolumeOfOilAndProducedWaterSpills];
                }
            }
            const selectedStateIdsArrayofVolume = selectedStatesofVolume.map(name => {
                const item = stateList.find(item => item.name === name);
                return item ? item.id : null;
            });
            setSelectedStatesIdsForBars(selectedStateIdsArrayofVolume)
            if (dataIndex < selectedStatesofVolume?.length) {
                setModalHeading('Total Volume of Oil Spill');
            } else {
                setModalHeading('Total Volume of Produced Water');
            }
        }
        setShow(!show);
        setSelectedYear(year);
    }

    const ReportableSpillsTotalAndIntensityOptions = (leftAxisRange, rightAxisRange, yearsData) => {

        const stepsDistance = leftAxisRange / 5;

        return {
            responsive: true,
            onClick: (_, elements) => {
                if (elements.length > 0) {
                    const clickedBarIndex = elements[0].index;
                    const clickedBarValue = yearsData[clickedBarIndex];

                    // Call any function with the clicked value
                    onBarClick('Reportable Spills Total & Intensity', clickedBarValue);
                }
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    onClick: (click, legendItem, legend) => {
                        const datasets = legend.legendItems.map((dataset, index) => {
                            return dataset.text;
                        })

                        const index = datasets.indexOf(legendItem.text);
                        if (legend.chart.isDatasetVisible(index) === true) {
                            legend.chart.hide(index);
                        } else {
                            legend.chart.show(index);
                        }
                    },
                    labels: {
                        usePointStyle: true,
                        font: {
                            family: 'Montserrat',
                        },
                        generateLabels: (chart) => {
                            let visibility = [];
                            for (let i = 0; i < chart.data.datasets.length; i++) {
                                if (chart.isDatasetVisible(i) === true) {
                                    visibility.push(false);
                                }
                                else {
                                    visibility.push(true);
                                }
                            }
                            let pointStyle = [];
                            chart.data.datasets.forEach(dataset => {

                                if (dataset.type === 'line') {
                                    pointStyle.push('line');
                                }
                                else {
                                    pointStyle.push('rect')
                                }
                            })

                            return chart.data.datasets.map((dataset, index) => ({
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor,
                                strokeStyle: dataset.borderColor,
                                pointStyle: pointStyle[index],
                                hidden: visibility[index]
                            })
                            )
                        }
                    }
                },
                title: {
                    display: false,
                    text: 'Reportable Spills Total & Intensity'
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    ticks: { display: true },
                },
                'left-side': {
                    stacked: true,
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    max: leftAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                        display: true
                    },
                    title: {
                        display: true,
                        text: 'Total Spill Count',
                        font: {
                            family: 'Montserrat',
                        }
                    },
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    // ticks: { display: true },
                },
                'right-side': {
                    type: 'linear',
                    position: 'right',
                    min: 0,
                    max: rightAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                        display: true
                    },
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Spill Intensity (bbl spill/ bbl Produced)',
                        font: {
                            family: 'Montserrat',
                        }
                    }
                }
            },
            aspectRatio: 3,
        }
    }

    const HistoricalVersusRecentSpillsTotalOptions = (leftAxisRange, rightAxisRange, yearsData) => {

        const stepsDistance = leftAxisRange / 5;

        return {
            responsive: true,
            onClick: (_, elements) => {
                if (elements.length > 0) {
                    const clickedBarIndex = elements[0].index;
                    const clickedBarValue = yearsData[clickedBarIndex];
                    const datasetIndex = elements[0]?.datasetIndex;
                    // Call any function with the clicked value
                    onBarClick('Historical versus Recent Spills Total', clickedBarValue, datasetIndex);
                }
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    onClick: (click, legendItem, legend) => {
                        const datasets = legend.legendItems.map((dataset, index) => {
                            return dataset.text;
                        })

                        const index = datasets.indexOf(legendItem.text);
                        if (legend.chart.isDatasetVisible(index) === true) {
                            legend.chart.hide(index);
                        } else {
                            legend.chart.show(index);
                        }
                    },
                    labels: {
                        usePointStyle: true,
                        font: {
                            family: 'Montserrat',
                        },
                        generateLabels: (chart) => {
                            let visibility = [];
                            for (let i = 0; i < chart.data.datasets.length; i++) {
                                if (chart.isDatasetVisible(i) === true) {
                                    visibility.push(false);
                                }
                                else {
                                    visibility.push(true);
                                }
                            }
                            let pointStyle = [];
                            chart.data.datasets.forEach(dataset => {
                                if (dataset.type === 'line') {
                                    pointStyle.push('line');
                                }
                                else {
                                    pointStyle.push('rect')
                                }
                            })

                            return chart.data.datasets.map((dataset, index) => ({
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor[0],
                                strokeStyle: dataset.borderColor[0],
                                pointStyle: pointStyle[index],
                                hidden: visibility[index]
                            })
                            )
                        }
                    }
                },
                title: {
                    display: false,
                    text: 'Historical versus Recent Spills Total'
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    ticks: { display: true },
                },
                'left-side': {
                    stacked: true,
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    max: leftAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                    },
                    title: {
                        display: true,
                        text: 'Total Spill Count',
                        font: {
                            family: 'Montserrat',
                        }
                    },
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    // ticks: { display: true },
                },
                'right-side': {
                    type: 'linear',
                    position: 'right',
                    min: 0,
                    max: rightAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                    },
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Cumulative Spill Count',
                        font: {
                            family: 'Montserrat',
                        }
                    }
                }
            },
            aspectRatio: 3,
        }
    }

    const VolumeOfOilAndProducedWaterOptions = (leftAxisRange, rightAxisRange, yearsData) => {

        const stepsDistance = leftAxisRange / 5;

        return {
            responsive: true,
            onClick: (_, elements) => {
                if (elements.length > 0) {
                    const clickedBarIndex = elements[0].index;
                    const clickedBarValue = yearsData[clickedBarIndex];
                    const datasetIndex = elements[0]?.datasetIndex;

                    // Call any function with the clicked value
                    onBarClick('Volume of Oil & Produced Water Spills', clickedBarValue, datasetIndex);
                }
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    onClick: (click, legendItem, legend) => {
                        const datasets = legend.legendItems.map((dataset, index) => {
                            return dataset.text;
                        })

                        const index = datasets.indexOf(legendItem.text);
                        if (legend.chart.isDatasetVisible(index) === true) {
                            legend.chart.hide(index);
                        } else {
                            legend.chart.show(index);
                        }
                    },
                    labels: {
                        usePointStyle: true,
                        font: {
                            family: 'Montserrat',
                        },
                        generateLabels: (chart) => {
                            let visibility = [];
                            for (let i = 0; i < chart.data.datasets.length; i++) {
                                if (chart.isDatasetVisible(i) === true) {
                                    visibility.push(false);
                                }
                                else {
                                    visibility.push(true);
                                }
                            }
                            let pointStyle = [];
                            chart.data.datasets.forEach(dataset => {
                                if (dataset.type === 'line') {
                                    pointStyle.push('line');
                                }
                                else {
                                    pointStyle.push('rect')
                                }
                            })

                            return chart.data.datasets.map((dataset, index) => ({
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor[0],
                                strokeStyle: dataset.borderColor[0],
                                pointStyle: pointStyle[index],
                                hidden: visibility[index]
                            })
                            )
                        }
                    }
                },
                title: {
                    display: false,
                    text: 'Volume of Oil & Produced Water Spills'
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    ticks: { display: true },
                },
                'left-side': {
                    stacked: true,
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    max: leftAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                    },
                    title: {
                        display: true,
                        text: 'Volume of Liquid (bbl)',
                        font: {
                            family: 'Montserrat',
                        }
                    },
                    grid: {
                        color: colorPalleteData.graphLineColor,
                    },
                    border: {
                        dash: [2, 4],
                    },
                    // ticks: { display: true },
                },
                'right-side': {
                    type: 'linear',
                    position: 'right',
                    min: 0,
                    max: rightAxisRange,
                    ticks: {
                        stepSize: stepsDistance,
                    },
                    beginAtZero: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Cumulative Spill Volume',
                        font: {
                            family: 'Montserrat',
                        }
                    }
                }
            },
            aspectRatio: 3,
        }
    }

    useEffect(() => {
        getStateList();
        getDashboardCount('yearly');
        getSelectedSpillCharts();
    }, []);

    useEffect(() => {

        const combinedSpillsTotalAndIntensityDatasets = [
            ...spillsTotalAndIntensityBarDatasets,
            ...spillsTotalAndIntensityLineDatasets,
        ];
        setSpillsTotalAndIntensityDataset(combinedSpillsTotalAndIntensityDatasets);
        setSpillsTotalAndIntensityFilteredArrayBars(combinedSpillsTotalAndIntensityDatasets);
        setSpillsTotalAndIntensityDatasetForFilter(combinedSpillsTotalAndIntensityDatasets);
        setActualTotalAndIntensityData(combinedSpillsTotalAndIntensityDatasets);

        const combinedHistoricalVersusRecentSpillsTotalBarDatasets = [
            ...historicalVersusRecentSpillsTotalBarDatasets,
            ...historicalVersusRecentSpillsTotalHistorical,
        ];
        setHistoricalVersusRecentSpillsDataset(combinedHistoricalVersusRecentSpillsTotalBarDatasets);
        setSpillsHistoricalVersusRecentSpillsDatasetForFilter(combinedHistoricalVersusRecentSpillsTotalBarDatasets);
        setActualHistoricalVersusRecentSpillsData(combinedHistoricalVersusRecentSpillsTotalBarDatasets);

        const combinedVolumeOfOilAndProducedWaterDatasets = [
            ...volumeOfOilAndProducedWaterSpillsBarDatasets,
            ...volumeOfOilAndProducedWaterSpills,
        ];
        setVolumeOfOilAndProducedWaterDataset(combinedVolumeOfOilAndProducedWaterDatasets);
        setVolumeOfOilAndProducedWaterDatasetForFilter(combinedVolumeOfOilAndProducedWaterDatasets);
        setActualVolumeOfOilAndProducedWaterData(combinedVolumeOfOilAndProducedWaterDatasets);

    }, [spillsTotalAndIntensityIntData, spillsTotalAndIntensityData, historicalVersusRecentyDataHistorical, historicalVersusRecentyDataRecent, historicalVersusRecentSpillsTrendlineLimit])

    const getStateList = () => {
        Get(getStateListing, token, `?sort=name`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const stateData = data.data.map(res => {
                        return {
                            ...res, label: res.name, value: res.name
                        }
                    })
                    setStateList(stateData);
                    getSpillGraphData('Reportable Spills Total & Intensity', 'yearly');
                    getSpillGraphData('Historical versus Recent Spills Total', 'yearly');
                    getSpillGraphData('Volume of Oil & Produced Water Spills', 'yearly');
                }
            });
    }

    const getSpillGraphData = (chartType, filterDuration) => {

        const body = chartType == 'Historical versus Recent Spills Total' ? 'historical' : chartType == 'Volume of Oil & Produced Water Spills' ? 'oil_water' : chartType == 'Reportable Spills Total & Intensity' ? '' : '';
        // Get(spillsDashboardGraphData, token, '', true)
        Get(spillsDashboardGraphData + `?chart_type=${body}&record_type=${filterDuration}`, token, '', true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    if (chartType === 'Reportable Spills Total & Intensity') {
                        let transformData = data?.data;
                        setSpillsTotalAndIntensityInjuryApiData(transformData);
                        const allValuesAreZero = transformData.every(item => item.Colorado === 0 && item.Texas === 0);
                        if (allValuesAreZero) {
                            setShowSpillsTotalAndIntensityGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowSpillsTotalAndIntensityGraph(true);
                        }

                        setSelectedStateList(data?.total_states);

                        if (filterDuration === 'quarterly') {
                            transformData = transformData.map(item => {
                                return {
                                    ...item,
                                    "year": `Q${item.quarter}, ${item.year}`,
                                };
                            });
                        }
                        if (filterDuration === 'monthly') {
                            transformData = transformData.map(item => {
                                const abbreviatedMonth = monthNames[parseInt(item.month) - 1];
                                return {
                                    ...item,
                                    "year": `${abbreviatedMonth}, ${item.year}`,
                                };
                            });
                        }

                        transformData = transformData.map(res => {
                            const total = data?.total_states.reduce((acc, response) => {
                                const value = res[response] || 0; // If value is undefined, use 0
                                return acc + value; // Accumulate the total
                            }, 0);

                            // Add 10 to the total
                            const updatedTotal = total;

                            // Return the transformed object with the updated total property
                            return {
                                ...res,
                                total: updatedTotal
                            };
                        });

                        // Calculate values and push them into a new array
                        const spillsTotalAndIntensityData = data?.total_states.map(res => {
                            const calculatedValuesArray = transformData.map(obj => + obj[res]);
                            return calculatedValuesArray
                        })

                        setSpillsTotalAndIntensityData(spillsTotalAndIntensityData);

                        setSpillsTotalAndIntensityInjuryNearestTwenty(calculateNearestTwenty(transformData, 'total'));
                        setSpillsIntensityNearestTwenty(calculateNearestTwenty(transformData, 'spill_intensity'));

                        setSpillsTotalAndIntensityInjuryGraphData(transformData);
                        let spillsTotalAndIntensityIntData = transformData.map(ele => { return ele.spill_intensity });
                        let spillsTotalAndIntensityTexasData = transformData.map(ele => { return ele.Alabama });
                        let spillsTotalAndIntensityColoradoData = transformData.map(ele => { return ele.Colorado });

                        setSpillsTotalAndIntensityEmpData(spillsTotalAndIntensityIntData);
                        setSpillsTotalAndIntensityContData(spillsTotalAndIntensityTexasData);
                        setSpillsTotalAndIntensityCombData(spillsTotalAndIntensityColoradoData);

                    }

                    if (chartType === 'Historical versus Recent Spills Total') {
                        let transformDataOfHistoricalVersusRecentSpills = data?.data;
                        setHistoricalVersusRecentSpillsApiData(transformDataOfHistoricalVersusRecentSpills);
                        const allValuesAreZero = transformDataOfHistoricalVersusRecentSpills.historical.every(item => item.Colorado === 0 && item.Texas === 0);
                        if (allValuesAreZero) {
                            setShowHistoricalVersusRecentSpillsGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowHistoricalVersusRecentSpillsGraph(true);
                        }

                        setSelectedStateList(data?.total_states);

                        if (filterDuration === 'quarterly') {
                            transformDataOfHistoricalVersusRecentSpills.historical = transformDataOfHistoricalVersusRecentSpills.historical.map(item => {
                                return {
                                    ...item,
                                    "year": `Q${item.quarter}, ${item.year}`,
                                };
                            });
                        }
                        if (filterDuration === 'monthly') {
                            transformDataOfHistoricalVersusRecentSpills.historical = transformDataOfHistoricalVersusRecentSpills.historical.map(item => {
                                const abbreviatedMonth = monthNames[parseInt(item.month) - 1];
                                return {
                                    ...item,
                                    "year": `${abbreviatedMonth}, ${item.year}`,
                                };
                            });
                        }

                        setTransformDataOfHistoricalVersusRecentSpillsGraph(transformDataOfHistoricalVersusRecentSpills.historical);

                        const historicalVersusRecentyDataForHistorical = data?.total_states.map(res => {
                            const calculatedValuesArray = transformDataOfHistoricalVersusRecentSpills.historical.map(obj => + obj[res]);
                            return calculatedValuesArray
                        })

                        setHistoricalVersusRecentyDataHistorical(historicalVersusRecentyDataForHistorical);

                        const historicalVersusRecentyDataForRecent = data?.total_states.map(res => {
                            const calculatedValuesArray = transformDataOfHistoricalVersusRecentSpills.recent.map(obj => + obj[res]);
                            return calculatedValuesArray
                        })

                        setHistoricalVersusRecentyDataRecent(historicalVersusRecentyDataForRecent);

                        const sumArrayForHistorical = historicalVersusRecentyDataForHistorical.reduce((acc, curr) => {
                            curr.forEach((value, index) => {
                                acc[index] = (acc[index] || 0) + value; // Add the current value to the corresponding index
                            });
                            return acc;
                        }, []);

                        const sumArrayForRecent = historicalVersusRecentyDataForRecent.reduce((acc, curr) => {
                            curr.forEach((value, index) => {
                                acc[index] = (acc[index] || 0) + value; // Add the current value to the corresponding index
                            });
                            return acc;
                        }, []);

                        const totalSumForHistorical = sumArrayForHistorical.reduce((acc, curr) => acc + curr, 0);

                        const totalSumForRecent = sumArrayForRecent.reduce((acc, curr) => acc + curr, 0);

                        if (totalSumForHistorical >= totalSumForRecent) {
                            var maxhistoricVersusRecentDataSumDataRate = Math.max(totalSumForHistorical);
                        } else {
                            var maxhistoricVersusRecentDataSumDataRate = Math.max(totalSumForRecent);
                        }

                        setHistoricalVersusRecentSpillsTotalLineDataForHistorical(sumArrayForHistorical);
                        setHistoricalVersusRecentSpillsTotalLineDataForRecent(sumArrayForRecent);

                        let historicVersusRecentDataSumDataGraphLimit = nearestMultipleOf50(maxhistoricVersusRecentDataSumDataRate);

                        setHistoricalVersusRecentSpillsTrendlineLimit(historicVersusRecentDataSumDataGraphLimit);

                    }

                    if (chartType === 'Volume of Oil & Produced Water Spills') {
                        let transformDataOfVolumeOfOilAndProducedWaterSpills = data?.data;
                        setVolumeOfOilAndProducedWaterSpillsApiData(transformDataOfVolumeOfOilAndProducedWaterSpills);
                        const allValuesAreZero = transformDataOfVolumeOfOilAndProducedWaterSpills.oil.every(item => item.Colorado === 0 && item.Texas === 0);
                        if (allValuesAreZero) {
                            setShowVolumeOfOilAndProducedWaterGraph(false);
                        }
                        if (!allValuesAreZero) {
                            setShowVolumeOfOilAndProducedWaterGraph(true);
                        }

                        setSelectedStateList(data?.total_states);

                        if (filterDuration === 'quarterly') {
                            transformDataOfVolumeOfOilAndProducedWaterSpills.oil = transformDataOfVolumeOfOilAndProducedWaterSpills.oil.map(item => {
                                return {
                                    ...item,
                                    "year": `Q${item.quarter}, ${item.year}`,
                                };
                            });
                        }
                        if (filterDuration === 'monthly') {
                            transformDataOfVolumeOfOilAndProducedWaterSpills.oil = transformDataOfVolumeOfOilAndProducedWaterSpills.oil.map(item => {
                                const abbreviatedMonth = monthNames[parseInt(item.month) - 1];
                                return {
                                    ...item,
                                    "year": `${abbreviatedMonth}, ${item.year}`,
                                };
                            });
                        }

                        setTransformDataOfVolumeOfOilAndProducedWaterSpills(transformDataOfVolumeOfOilAndProducedWaterSpills.oil);

                        const volumeOfOilAndProducedWaterForOil = data?.total_states.map(res => {
                            const calculatedValuesArray = transformDataOfVolumeOfOilAndProducedWaterSpills.oil.map(obj => + obj[res]);
                            return calculatedValuesArray
                        })

                        setVolumeOfOilAndProducedWaterOil(volumeOfOilAndProducedWaterForOil);

                        const volumeOfOilAndProducedWaterForWater = data?.total_states.map(res => {
                            const calculatedValuesArray = transformDataOfVolumeOfOilAndProducedWaterSpills.water.map(obj => + obj[res]);
                            return calculatedValuesArray
                        })

                        setVolumeOfOilAndProducedWaterWater(volumeOfOilAndProducedWaterForWater);

                        const sumArrayForOil = volumeOfOilAndProducedWaterForOil.reduce((acc, curr) => {
                            curr.forEach((value, index) => {
                                acc[index] = (acc[index] || 0) + value; // Add the current value to the corresponding index
                            });
                            return acc;
                        }, []);

                        const sumArrayForWater = volumeOfOilAndProducedWaterForWater.reduce((acc, curr) => {
                            curr.forEach((value, index) => {
                                acc[index] = (acc[index] || 0) + value; // Add the current value to the corresponding index
                            });
                            return acc;
                        }, []);

                        const totalSumForOil = sumArrayForOil.reduce((acc, curr) => acc + curr, 0);

                        const totalSumForWater = sumArrayForWater.reduce((acc, curr) => acc + curr, 0);

                        if (totalSumForOil >= totalSumForWater) {
                            var maxVolumeOfOilAndProducedDataSumDataRate = Math.max(totalSumForOil);
                        } else {
                            var maxVolumeOfOilAndProducedDataSumDataRate = Math.max(totalSumForWater);
                        }

                        setVolumeOfOilAndProducedTotalLineDataForOil(sumArrayForOil);
                        setVolumeOfOilAndProducedTotalLineDataForWater(sumArrayForWater);

                        let volumeOfOilAndProducedDataSumDataGraphLimit = nearestMultipleOf50(maxVolumeOfOilAndProducedDataSumDataRate);

                        setVolumeOfOilAndProducedTrendlineLimit(volumeOfOilAndProducedDataSumDataGraphLimit);

                    }

                    setLoading(false);
                }
            });
    }

    const getDashboardCount = (filterDuration) => {
        Get(spillsDashboardCount + `?record_type=${filterDuration}`, token, '', true)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setDashboardCountObj(data.result);
                    transformDashCount(data.result);
                }
            });
    };

    const transformDashCount = (res) => {
        const transformData = res?.length > 0 && res?.map((item, index) => {
            const icon = spillsData?.SpillsDashboardCount.find((dItem) => dItem.name === item.type)
            return {
                ...item,
                icon: icon?.icon
            }
        })
        setFinalArr(transformData);
    }

    const handleExport = async () => {
        // setLoading(true);

        // Capture the entire window
        const body = document.body;
        const html = document.documentElement;

        const windowWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
        const windowHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        await html2canvas(document.body, { width: windowWidth, height: windowHeight }).then((canvas) => {
            // Convert the canvas to a data URL
            const screenshotData = canvas.toDataURL('image/png');

            // Create a download link
            const downloadLink = document.createElement('a');
            downloadLink.href = screenshotData;
            downloadLink.download = 'Screenshot.png';

            // Trigger the download
            downloadLink.click();
        });
        setLoading(false);
    }

    const onFilter = (durationTime) => {
        setGlobalFilterDuration(durationTime);
        durationTime = durationTime ? durationTime : 'yearly';
        hideElement();
        if (durationTime) {
            getDashboardCount(durationTime);
            getSpillGraphData('Reportable Spills Total & Intensity', durationTime);
            getSpillGraphData('Historical versus Recent Spills Total', durationTime);
            getSpillGraphData('Volume of Oil & Produced Water Spills', durationTime);
            getSelectedSpillCharts();
        }
        else {
            hideElement();
        }
    };

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
        const element3 = document.getElementById('dropdownHidden3');
        if (element3) {
            element3.classList.remove('show');
        }
    }

    const navigateToList = () => {
        navigate('/spills-management');
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const calculateCumulativeTrendLine = (graphName, graphData) => {
        if (graphData !== []) {
            if (graphData?.length > 0) {
                let sum = 0;
                return graphData?.map(value => sum += value);
            }
            else return graphData;
        }
    }

    const calculateNearestTwenty = (value, key) => {
        const maxCombinedCount = value.reduce((max, item) => {
            return item[key] > max ? item[key] : max;
        }, 0);
        const nearestMultipleOf20 = Math.ceil(maxCombinedCount / 20) * 20;
        return nearestMultipleOf20;
    }

    function nearestMultipleOf20(amount) {
        const multiple = 20;
        const nearestMultiple = Math.ceil(amount / multiple) * multiple;
        return nearestMultiple;
    }

    function nearestMultipleOf50(amount) {
        const multiple = 50;
        const nearestMultiple = Math.ceil(amount / multiple) * multiple;
        return nearestMultiple;
    }

    const spillsTotalAndIntensityLineDatasets = [
        generateReportableSpillsTotalAndIntensityDatasets('line', 'Spill Intensity', spillsTotalAndIntensityIntData, colorPalleteData.graphGreenShade, false, 'right-side'),
    ];

    var labelsForReportableSpillsTotalAndIntensityGraph = selectedStateList.map(res => {
        return {
            name: res + ' Reportable Spill'
        }
    })

    const dataArraysForReportableSpillsTotalAndIntensityGraph = spillsTotalAndIntensityData;
    const colors = [colorPalleteData.graphPurpleShade, colorPalleteData.graphYellowShade, colorPalleteData.graphDarkGreenLine, colorPalleteData.graphBeigeGreenLine, colorPalleteData.graphRedLine];

    const spillsTotalAndIntensityBarDatasets = labelsForReportableSpillsTotalAndIntensityGraph.map((label, index) => {
        const data = dataArraysForReportableSpillsTotalAndIntensityGraph[index];
        const color = colors[index];
        return generateReportableSpillsTotalAndIntensityDatasets('bar', label?.name, data, color, true, 'left-side');
    });

    let spillsTotalAndIntensityDatasetArr = spillsTotalAndIntensityFilteredArrayBars;

    const ReportableSpillsTotalAndIntensityGraph = {
        labels: spillsTotalAndIntensityInjuryGraphData && spillsTotalAndIntensityInjuryGraphData.map(item => item.year),
        datasets: spillsTotalAndIntensityDataset,
    }

    var labelsForHistoricalVersusRecentSpillsTotalHistorical = selectedStateList.map((res, index) => {
        return {
            name: res + ' Historical Spill',
            stack: 'stack1',
            color: colors[index]
        }
    })

    var labelsForHistoricalVersusRecentSpillsTotalRecent = selectedStateList.map((res, index) => {
        return {
            name: res + ' Recent',
            stack: 'stack2',
            color: colors[index]
        }
    })

    var dummyRecentData = selectedStateList.map((res, index) => {
        return {
            data: [42, 78, 24, 39, 21]
        }
    })

    const extractedRecentDummyData = dummyRecentData.map(item => item.data);

    const sumArray = extractedRecentDummyData[0]?.map((_, index) => extractedRecentDummyData.reduce((acc, curr) => acc + curr[index], 0));

    var labelsForHistoricalVersusRecentSpillsTotal = (labelsForHistoricalVersusRecentSpillsTotalHistorical.concat(labelsForHistoricalVersusRecentSpillsTotalRecent));

    const historicalVersusRecentSpillsTotalBarDatasets = labelsForHistoricalVersusRecentSpillsTotal.map((label, index) => {
        const data = (historicalVersusRecentyDataHistorical.concat(historicalVersusRecentyDataRecent))[index];
        return generateHistoricalVersusRecentSpillsTotalDatasets('bar', label?.name, data, label?.color, true, 'left-side', label?.stack);
    });

    const historicalVersusRecentSpillsTotalHistorical = [
        generateHistoricalVersusRecentSpillsTotalDatasets('line', 'Historical', calculateCumulativeTrendLine('Historical versus Recent Spills Total', historicalVersusRecentSpillsTotalLineDataForHistorical), colorPalleteData.graphGreenLine, false, 'right-side'),
        generateHistoricalVersusRecentSpillsTotalDatasets('line', 'Recent', calculateCumulativeTrendLine('Historical versus Recent Spills Total', historicalVersusRecentSpillsTotalLineDataForRecent), colorPalleteData.graphPurpleLine, false, 'right-side'),
    ];

    const HistoricalVersusRecentSpillsTotalGraph = {
        labels: transformDataOfHistoricalVersusRecentSpillsGraph && transformDataOfHistoricalVersusRecentSpillsGraph.map(item => item.year),
        datasets: historicalVersusRecentSpillsDataset,
    }

    var labelsForVolumeOfOilAndProducedWaterOilSpills = selectedStateList.map((res, index) => {
        return {
            name: res + ' Oil Spills',
            stack: 'stack1',
            color: colors[index]
        }
    })

    var labelsForVolumeOfOilAndProducedWaterWaterSpills = selectedStateList.map((res, index) => {
        return {
            name: res + ' Produced Water Spills',
            stack: 'stack2',
            color: colors[index]
        }
    })

    var dummyVolumeOfOilAndProducedWaterData = selectedStateList.map((res, index) => {
        return {
            data: [9, 39, 34, 29, 11]
        }
    })

    const extractedVolumeOfOilAndProducedWaterDummyData = dummyVolumeOfOilAndProducedWaterData.map(item => item.data);

    const sumArrayOfVolumeOfOilAndProducedWater = extractedVolumeOfOilAndProducedWaterDummyData[0]?.map((_, index) => extractedVolumeOfOilAndProducedWaterDummyData.reduce((acc, curr) => acc + curr[index], 0));

    var labelsForVolumeOfOilAndProducedWaterSpillsTotal = (labelsForVolumeOfOilAndProducedWaterOilSpills.concat(labelsForVolumeOfOilAndProducedWaterWaterSpills));

    const volumeOfOilAndProducedWaterSpillsBarDatasets = labelsForVolumeOfOilAndProducedWaterSpillsTotal.map((label, index) => {
        const data = (volumeOfOilAndProducedWaterOil.concat(volumeOfOilAndProducedWaterWater))[index];
        return generateVolumeOfOilAndProducedWaterDatasets('bar', label?.name, data, label?.color, true, 'left-side', label?.stack);
    });

    const volumeOfOilAndProducedWaterSpills = [
        generateVolumeOfOilAndProducedWaterDatasets('line', 'Oil', calculateCumulativeTrendLine('Volume of Oil & Produced Water Spills', volumeOfOilAndProducedTotalLineDataForOil), colorPalleteData.graphGreenLine, false, 'right-side'),
        generateVolumeOfOilAndProducedWaterDatasets('line', 'Produced Water Spills', calculateCumulativeTrendLine('Volume of Oil & Produced Water Spills', volumeOfOilAndProducedTotalLineDataForWater), colorPalleteData.graphPurpleLine, false, 'right-side'),
    ];

    const VolumeOfOilAndProducedWaterSpillsGraph = {
        labels: transformDataOfVolumeOfOilAndProducedWaterSpills && transformDataOfVolumeOfOilAndProducedWaterSpills.map(item => item.year),
        datasets: volumeOfOilAndProducedWatersDataset,
    }

    const handleDropdownSelect = (e) => {
        setFilterValue(e.filter_number);
    }

    const getSelectedEmployeeIds = (data, name) => {

        setSelectedGraphTypeFilter(name);

        if (name === 'Reportable Spills Total & Intensity') {
            setSpillsTotalAndIntensityInjurySelectedEmpIds(data);
            const selectedStates = stateList.filter(item => data.includes(item?.id));

            const filterSelectedStates = selectedStates.map((item, index) => {
                return item?.name
            })

            setSelectedStateIds(filterSelectedStates);
        }

        if (name === 'Historical versus Recent Spills Total') {
            setHistoricalVersusRecentSpillsSelectedIds(data);
            const selectedStates = stateList.filter(item => data.includes(item?.id));

            const filterSelectedStates = selectedStates.map((item, index) => {
                return item?.name
            })

            setSelectedStateIdsForHistoricalVersusRecentSpills(filterSelectedStates);
            setHistoricalVersusRecentSpillTypeFilter(false);
        }

        if (name === 'Volume of Oil & Produced Water Spills') {
            setVolumeOfOilAndProducedWaterSelectedIds(data);
            const selectedStates = stateList.filter(item => data.includes(item?.id));

            const filterSelectedStates = selectedStates.map((item, index) => {
                return item?.name
            })

            setSelectedStateIdsVolumeOfOilAndProducedWaterSpills(filterSelectedStates);
            setVolumeOfOilAndProducedWaterFilter(false);
        }

    };

    const getSelectedTypeIds = (data, name) => {

        setSelectedGraphTypeFilter(name);

        if (name === 'Historical versus Recent Spills Total') {
            setHistoricalVersusRecentSpillsSelectedTypeIds(data);
            const selectedStates = spillsData.SpillType.filter(item => data.includes(item?.id));

            const filterSelectedStates = selectedStates.map((item, index) => {
                return item?.name
            })

            setSelectedStateIdsForHistoricalVersusRecentTypeSpills(filterSelectedStates);
            setHistoricalVersusRecentSpillTypeFilter(true);
        }

        if (name === 'Volume of Oil & Produced Water Spills') {
            setVolumeOfOilAndProducedWaterSelectedTypeIds(data);
            const selectedStates = spillsData.FluidType.filter(item => data.includes(item?.id));

            const filterSelectedStates = selectedStates.map((item, index) => {
                return item?.name
            })

            setSelectedVolumeOfOilAndProducedWaterTypeSpills(filterSelectedStates);
            setVolumeOfOilAndProducedWaterFilter(true);
        }

    };

    const updateChartType = (graphName, type) => {

        switch (graphName) {
            case 'Reportable Spills Total & Intensity':
                let reportableDatasetArray = [...actualTotalAndIntensityData];
                if (type === 1) {
                    reportableDatasetArray = reportableDatasetArray.filter(item => item.type === 'line');
                    setSpillsTotalAndIntensityDataset(reportableDatasetArray);
                }
                if (type === 2) {
                    reportableDatasetArray = reportableDatasetArray.filter(item => item.type === 'bar');
                    setSpillsTotalAndIntensityDataset(reportableDatasetArray);
                }
                if (type === 3) {
                    setSpillsTotalAndIntensityDataset(reportableDatasetArray);
                }
                setSelectedSpillsTotalAndIntensityChart(type);
                break;

            case 'Historical versus Recent Spills Total':
                let historicalVersusRecentSpillsDatasetArray = [...actualHistoricalVersusRecentSpillsData];
                if (type === 1) {
                    historicalVersusRecentSpillsDatasetArray = historicalVersusRecentSpillsDatasetArray.filter(item => item.type === 'line');
                    setHistoricalVersusRecentSpillsDataset(historicalVersusRecentSpillsDatasetArray);
                }
                if (type === 2) {
                    historicalVersusRecentSpillsDatasetArray = historicalVersusRecentSpillsDatasetArray.filter(item => item.type === 'bar');
                    setHistoricalVersusRecentSpillsDataset(historicalVersusRecentSpillsDatasetArray);
                }
                if (type === 3) {
                    setHistoricalVersusRecentSpillsDataset(historicalVersusRecentSpillsDatasetArray);
                }
                setSelectedHistoricalVersusRecentChart(type);
                break;

            case 'Volume of Oil & Produced Water Spills':
                let volumeOfOilAndProducedWaterDatasetArray = [...actualVolumeOfOilAndProducedWaterData];
                if (type === 1) {
                    volumeOfOilAndProducedWaterDatasetArray = volumeOfOilAndProducedWaterDatasetArray.filter(item => item.type === 'line');
                    setVolumeOfOilAndProducedWaterDataset(volumeOfOilAndProducedWaterDatasetArray);
                }
                if (type === 2) {
                    volumeOfOilAndProducedWaterDatasetArray = volumeOfOilAndProducedWaterDatasetArray.filter(item => item.type === 'bar');
                    setVolumeOfOilAndProducedWaterDataset(volumeOfOilAndProducedWaterDatasetArray);
                }
                if (type === 3) {
                    setVolumeOfOilAndProducedWaterDataset(volumeOfOilAndProducedWaterDatasetArray);
                }
                setSelectedVolumeOfOilAndProducedChart(type);
                break;

            default:
                break;

        }
    };

    const onFilterChartType = (type, graphName) => {
        updateChartType(graphName, type);
    };

    useEffect(() => {

        if (selectedGraphTypeFilter === 'Reportable Spills Total & Intensity') {

            const appendString = " Reportable Spill";

            if (selectedStateIds?.length == 0) {
                setSelectedStateIds([...selectedStateList]);
            }

            var newArray = [];
            newArray = selectedStateIds.map(item => item.toString() + appendString);
            let spillIntensityLineObject = spillsTotalAndIntensityDatasetForFilter.filter(item => item.type === 'line')
            var filteredArray = [];
            filteredArray = spillsTotalAndIntensityDatasetForFilter.filter(item => newArray.some(element => element === item?.label));
            filteredArray.push(spillIntensityLineObject[0]);

            let apiIntensityData = [...spillsTotalAndIntensityInjuryApiData];


            apiIntensityData = apiIntensityData.map(res => {
                const total = selectedStateIds.reduce((acc, response) => {
                    const value = res[response] || 0; // If value is undefined, use 0
                    return acc + value; // Accumulate the total
                }, 0);

                // Add 10 to the total
                const updatedTotal = total;

                // Return the transformed object with the updated total property
                return {
                    ...res,
                    total: updatedTotal
                };
            });

            let spillsTotalAndIntensityIntData = apiIntensityData.map(ele => { return ele.total });

            let lineData = filteredArray.filter(item => item.type == 'line')

            lineData[0].data = [...spillsTotalAndIntensityIntData];


            setSpillsTotalAndIntensityDataset(filteredArray);
            setActualTotalAndIntensityData(filteredArray);
            setSelectedSpillsTotalAndIntensityChart(3);
        }

        if (selectedGraphTypeFilter === 'Historical versus Recent Spills Total' && !historicalVersusRecentSpillTypeFilter) {
            setHistoricalVersusRecentSpillsDataset(spillsHistoricalVersusRecentSpillsDatasetForFilter);
            setActualHistoricalVersusRecentSpillsData(spillsHistoricalVersusRecentSpillsDatasetForFilter);

            var filteredArray = []

            filteredArray = spillsHistoricalVersusRecentSpillsDatasetForFilter.filter(item => selectedStateIdsForHistoricalVersusRecentSpills.some(element => element === item?.label.split(' ')[0]));
            if (selectedStateIdsForHistoricalVersusRecentSpills?.length == 0) {
                setSelectedStateIdsForHistoricalVersusRecentSpills([...selectedStateList]);
            }

            let historicalVersusRecentSpillsLineObject = spillsHistoricalVersusRecentSpillsDatasetForFilter.filter(item => item.type === 'line')
            filteredArray = filteredArray.concat(historicalVersusRecentSpillsLineObject);

            const historicalVersusRecentyDataForHistorical = selectedStateIdsForHistoricalVersusRecentSpills.map(res => {
                const calculatedValuesArray = historicalVersusRecentSpillsApiData.historical.map(obj => + obj[res]);
                return calculatedValuesArray
            });

            const sumArrayOfhistoricalVersusRecentyDataForHistorical = [];
            const lengthofhistoricalVersusRecentyDataForHistorical = historicalVersusRecentyDataForHistorical[0]?.length;
            if (historicalVersusRecentyDataForHistorical.every(arr => arr.length === lengthofhistoricalVersusRecentyDataForHistorical)) {
                // Iterate over the arrays and add the elements at the same index position
                for (let i = 0; i < lengthofhistoricalVersusRecentyDataForHistorical; i++) {
                    let sum = 0;
                    for (let j = 0; j < historicalVersusRecentyDataForHistorical.length; j++) {
                        sum += historicalVersusRecentyDataForHistorical[j][i];
                    }
                    sumArrayOfhistoricalVersusRecentyDataForHistorical.push(sum);
                }
            }

            const historicalVersusRecentyDataForRecent = selectedStateIdsForHistoricalVersusRecentSpills.map(res => {
                const calculatedValuesArray = historicalVersusRecentSpillsApiData.recent.map(obj => + obj[res]);
                return calculatedValuesArray
            })

            const sumArrayOfhistoricalVersusRecentyDataForRecent = [];
            const lengthofhistoricalVersusRecentyDataForRecent = historicalVersusRecentyDataForHistorical[0]?.length;
            if (historicalVersusRecentyDataForHistorical.every(arr => arr.length === lengthofhistoricalVersusRecentyDataForRecent)) {
                // Iterate over the arrays and add the elements at the same index position
                for (let i = 0; i < lengthofhistoricalVersusRecentyDataForRecent; i++) {
                    let sum = 0;
                    for (let j = 0; j < historicalVersusRecentyDataForRecent.length; j++) {
                        sum += historicalVersusRecentyDataForRecent[j][i];
                    }
                    sumArrayOfhistoricalVersusRecentyDataForRecent.push(sum);
                }
            }

            let lineDataHistorical = filteredArray.filter(item => item.label == 'Historical')

            lineDataHistorical[0].data = [...calculateCumulativeTrendLine('Historical versus Recent Spills Total', sumArrayOfhistoricalVersusRecentyDataForHistorical)];

            let lineDataRecent = filteredArray.filter(item => item.label == 'Recent')

            lineDataRecent[0].data = [...calculateCumulativeTrendLine('Historical versus Recent Spills Total', sumArrayOfhistoricalVersusRecentyDataForRecent)];

            setHistoricalVersusRecentSpillsDataset(filteredArray);
            setActualHistoricalVersusRecentSpillsData(filteredArray);
            setSelectedHistoricalVersusRecentChart(3);
        } else if (selectedGraphTypeFilter === 'Historical versus Recent Spills Total' && historicalVersusRecentSpillTypeFilter) {

            const combinedHistoricalVersusRecentSpillsTotalBarDatasets = [
                ...historicalVersusRecentSpillsTotalBarDatasets,
                ...historicalVersusRecentSpillsTotalHistorical,
            ];
            setHistoricalVersusRecentSpillsDataset(combinedHistoricalVersusRecentSpillsTotalBarDatasets);
            setActualHistoricalVersusRecentSpillsData(combinedHistoricalVersusRecentSpillsTotalBarDatasets);
            var filteredArray = []
            filteredArray = combinedHistoricalVersusRecentSpillsTotalBarDatasets.filter(item => selectedStateIdsForHistoricalVersusRecentTypeSpills.some(element => element === item?.label.split(' ')[1]));
            if (selectedStateIdsForHistoricalVersusRecentTypeSpills?.length == 0) {
                setSelectedStateIdsForHistoricalVersusRecentTypeSpills([...spillsData.SpillType]);
            }

            let historicalVersusRecentSpillsLineObject = combinedHistoricalVersusRecentSpillsTotalBarDatasets.filter(item => item.type === 'line')
            historicalVersusRecentSpillsLineObject = historicalVersusRecentSpillsLineObject.filter(item => selectedStateIdsForHistoricalVersusRecentTypeSpills.some(element => element === item?.label));

            filteredArray = filteredArray.concat(historicalVersusRecentSpillsLineObject)

            setHistoricalVersusRecentSpillsDataset(filteredArray);
            setActualHistoricalVersusRecentSpillsData(filteredArray);
            setSelectedHistoricalVersusRecentChart(3);
        }

        if (selectedGraphTypeFilter === 'Volume of Oil & Produced Water Spills' && !volumeOfOilAndProducedWaterFilter) {

            var filteredArray = []

            filteredArray = volumeOfOilAndProducedWaterDatasetForFilter.filter(item => selectedStateIdsForVolumeOfOilAndProducedWaterSpills.some(element => element === item?.label.split(' ')[0]));
            if (selectedStateIdsForVolumeOfOilAndProducedWaterSpills?.length == 0) {
                setSelectedStateIdsVolumeOfOilAndProducedWaterSpills([...selectedStateList]);
            }

            let volumeOfOilAndProducedWaterLineObject = volumeOfOilAndProducedWaterDatasetForFilter.filter(item => item.type === 'line')
            filteredArray = filteredArray.concat(volumeOfOilAndProducedWaterLineObject);

            const volumeOfOilAndProducedWaterForOil = selectedStateIdsForVolumeOfOilAndProducedWaterSpills.map(res => {
                const calculatedValuesArray = volumeOfOilAndProducedWaterSpillsApiData.oil.map(obj => + obj[res]);
                return calculatedValuesArray
            })

            const sumArrayOfvolumeOfOilAndProducedWaterForOil = [];
            const lengthofvolumeOfOilAndProducedWaterForOil = volumeOfOilAndProducedWaterForOil[0]?.length;
            if (volumeOfOilAndProducedWaterForOil.every(arr => arr.length === lengthofvolumeOfOilAndProducedWaterForOil)) {
                // Iterate over the arrays and add the elements at the same index position
                for (let i = 0; i < lengthofvolumeOfOilAndProducedWaterForOil; i++) {
                    let sum = 0;
                    for (let j = 0; j < volumeOfOilAndProducedWaterForOil.length; j++) {
                        sum += volumeOfOilAndProducedWaterForOil[j][i];
                    }
                    sumArrayOfvolumeOfOilAndProducedWaterForOil.push(sum);
                }
            }

            const volumeOfOilAndProducedWaterForWater = selectedStateIdsForVolumeOfOilAndProducedWaterSpills.map(res => {
                const calculatedValuesArray = volumeOfOilAndProducedWaterSpillsApiData.water.map(obj => + obj[res]);
                return calculatedValuesArray
            })

            const sumArrayOfvolumeOfOilAndProducedWaterForWater = [];
            const lengthofvolumeOfOilAndProducedWaterForWater = volumeOfOilAndProducedWaterForWater[0]?.length;
            if (volumeOfOilAndProducedWaterForWater.every(arr => arr.length === lengthofvolumeOfOilAndProducedWaterForWater)) {
                // Iterate over the arrays and add the elements at the same index position
                for (let i = 0; i < lengthofvolumeOfOilAndProducedWaterForWater; i++) {
                    let sum = 0;
                    for (let j = 0; j < volumeOfOilAndProducedWaterForWater.length; j++) {
                        sum += volumeOfOilAndProducedWaterForWater[j][i];
                    }
                    sumArrayOfvolumeOfOilAndProducedWaterForWater.push(sum);
                }
            }

            let lineDataOil = filteredArray.filter(item => item.label == 'Oil')

            lineDataOil[0].data = [...calculateCumulativeTrendLine('Volume of Oil & Produced Water Spills', sumArrayOfvolumeOfOilAndProducedWaterForOil)];

            let lineDataProducedWater = filteredArray.filter(item => item.label == 'Produced Water Spills')

            lineDataProducedWater[0].data = [...calculateCumulativeTrendLine('Volume of Oil & Produced Water Spills', sumArrayOfvolumeOfOilAndProducedWaterForWater)];

            setVolumeOfOilAndProducedWaterDataset(filteredArray);
            setActualVolumeOfOilAndProducedWaterData(filteredArray);
            setSelectedVolumeOfOilAndProducedChart(3);
        } else if (selectedGraphTypeFilter === 'Volume of Oil & Produced Water Spills' && volumeOfOilAndProducedWaterFilter) {

            const combinedVolumeOfOilAndProducedWaterDatasets = [
                ...volumeOfOilAndProducedWaterSpillsBarDatasets,
                ...volumeOfOilAndProducedWaterSpills,
            ];
            setVolumeOfOilAndProducedWaterDataset(combinedVolumeOfOilAndProducedWaterDatasets);
            setActualVolumeOfOilAndProducedWaterData(combinedVolumeOfOilAndProducedWaterDatasets);
            var filteredArray = []
            filteredArray = combinedVolumeOfOilAndProducedWaterDatasets.filter(item => selectedvolumeOfOilAndProducedWaterTypeSpills.some(element =>
                String(element).split(' ')[0] === item?.label.split(' ')[1]
            ));
            if (selectedvolumeOfOilAndProducedWaterTypeSpills?.length == 0) {
                setSelectedVolumeOfOilAndProducedWaterTypeSpills([...spillsData.FluidType]);
            }


            let historicalVersusRecentSpillsLineObject = combinedVolumeOfOilAndProducedWaterDatasets.filter(item => item.type === 'line')
            historicalVersusRecentSpillsLineObject = historicalVersusRecentSpillsLineObject.filter(item => selectedvolumeOfOilAndProducedWaterTypeSpills.some(element => {
                return String(element).split(' ')[0] === String(item?.label).split(' ')[0]
            }));

            filteredArray = filteredArray.concat(historicalVersusRecentSpillsLineObject)

            setVolumeOfOilAndProducedWaterDataset(filteredArray);
            setActualVolumeOfOilAndProducedWaterData(filteredArray);
            setSelectedVolumeOfOilAndProducedChart(3);
        }

    }, [selectedStateIds, selectedStateIdsForHistoricalVersusRecentSpills, selectedStateIdsForVolumeOfOilAndProducedWaterSpills,
        selectedStateIdsForHistoricalVersusRecentTypeSpills, selectedvolumeOfOilAndProducedWaterTypeSpills]);

    // useLayoutEffect(() => {

    //     if (selectedGraphTypeFilter === 'Reportable Spills Total & Intensity') {
    //         let filteredArray = [...spillsTotalAndIntensityInjuryGraphData];
    //         setSpillsTotalAndIntensityInjuryGraphData(filteredArray);
    //     }

    //     if (selectedGraphTypeFilter === 'Historical versus Recent Spills Total') {
    //         let filteredArray = [...historicalVersusRecentyDataHistorical];
    //         setHistoricalVersusRecentyDataHistorical(filteredArray);
    //     }

    // }, [spillsTotalAndIntensityInjurySelectedEmpIds, historicalVersusRecentSpillsSelectedIds]);

    const handleGlobalShow = () => {
        setInitialSpillsIndicator(spillsCharts);
        setInitialGlobalSpillsFilter(selectedspillsChartsGlobalFilters);
        setGlobalModal(!globalModal);
    }

    const getSelectedSpillCharts = () => {
        const selectedIndicators = spillsCharts?.filter(indicator => indicator.checked).map(indicator => indicator.id);
        setSelectedspillsChartsGlobalFilters(selectedIndicators);
        setGraphList(selectedIndicators);
    }

    const handleGlobalFilterClose = () => {
        if (!globalFilterApplied) {
            setTimeout(() => {
                setSpillsCharts(initiaSpillsIndicator);
                setSelectedspillsChartsGlobalFilters(initialGlobalSpillsFilter);
            }, 500)
        }
    }

    const selectAllGlobalFilters = () => {
        setGlobalModal(true);
        let selectedSpillsCharts = spillsCharts.map((item, index) => {
            return item.id
        })
        let allCheckedSpillsCharts = spillsCharts.map((item, index) => {
            return { ...item, checked: true }
        })

        setSpillsCharts(allCheckedSpillsCharts);

        setSelectedspillsChartsGlobalFilters(selectedSpillsCharts);
        setGlobalFilterApplied(false);
    }

    const deselectAllGlobalFilters = () => {
        setGlobalModal(true);
        let selectedSpillsCharts = spillsCharts.map((item, index) => {
            return ''
        })
        let allCheckedSpillsCharts = spillsCharts.map((item, index) => {
            return { ...item, checked: false }
        })

        setSpillsCharts(allCheckedSpillsCharts)

        setSelectedspillsChartsGlobalFilters(selectedSpillsCharts);
        setGlobalFilterApplied(false);
    }

    const applyGlobalCustomization = () => {
        setGraphList(selectedspillsChartsGlobalFilters);
        setGlobalFilterApplied(true);
        setResDetail('Filter applied successfully!');
        setAlertType('success');
        setSuccessRes(true);
        setGlobalModal(false);
        let graphCustomizationData = JSON.parse(localStorage.getItem('graphCustomization'))
        graphCustomizationData.data.spillsChartData = spillsCharts;
        Post(customizeGraphToggle, token, graphCustomizationData, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    localStorage.setItem('graphCustomization', JSON.stringify(graphCustomizationData));
                }
            });
    }

    const onSpillChartsChange = (e) => {
        setGlobalModal(true);
        let itemArr = spillsCharts && spillsCharts.map((ele, index) => {
            if (ele.id === e.id) {
                return { ...ele, checked: !ele.checked }; // Toggle the checked property
            }
            return ele;
        });
        setSpillsCharts(itemArr);
        const selectedIds = itemArr.filter(indicator => indicator.checked).map(indicator => indicator.id);
        setSelectedspillsChartsGlobalFilters(selectedIds);
        setGlobalFilterApplied(false);
    }

    const dummyGraphArr = [

        {
            id: 'Reportable Spills Total & Intensity',
            name: 'Reportable Spills Total & Intensity',
            graphType: 'StackedChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            stateDdn: true,
            stateDdnData: stateList,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            chartData: ReportableSpillsTotalAndIntensityGraph,
            chartOptions: ReportableSpillsTotalAndIntensityOptions(spillsTotalAndIntensityInjuryNearestTwenty, spillsIntensityNearestTwenty, spillsTotalAndIntensityInjuryGraphData?.map(item => item?.year)),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedSpillsTotalAndIntensityChart,
            showGraph: showSpillsTotalAndIntensityGraph
        },

        {
            id: 'Historical versus Recent Spills Total',
            name: 'Historical versus Recent Spills Total',
            graphType: 'PatternChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            stateDdn: true,
            stateDdnData: stateList,
            spillTypeDdn: true,
            spillTypeDdnData: spillsData.SpillType,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            onSelectType: getSelectedTypeIds,
            chartData: HistoricalVersusRecentSpillsTotalGraph,
            chartOptions: HistoricalVersusRecentSpillsTotalOptions(historicalVersusRecentSpillsTrendlineLimit, historicalVersusRecentSpillsTrendlineLimit, transformDataOfHistoricalVersusRecentSpillsGraph?.map(item => item?.year)),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedHistoricalVersusRecentChart,
            showGraph: showHistoricalVersusRecentSpillsGraph
        },

        {
            id: 'Volume of Oil & Produced Water Spills',
            name: 'Volume of Oil & Produced Water Spills',
            graphType: 'PatternChart',
            target: target,
            overLayShow: leadShow,
            setOverlayShow: setLeadShow,
            handleDropdownSelect: handleDropdownSelect,
            setLocalCustomizationPopUp: setLocalCustomizationPopUp,
            icon: infoIcon,
            stateDdn: true,
            stateDdnData: stateList,
            fluidTypeDdn: true,
            fluidTypeDdnData: spillsData.FluidType,
            onSelectSidebarIcon: getSelectedEmployeeIds,
            onSelectType: getSelectedTypeIds,
            chartData: VolumeOfOilAndProducedWaterSpillsGraph,
            chartOptions: VolumeOfOilAndProducedWaterOptions(volumeOfOilAndProducedTrendlineLimit, volumeOfOilAndProducedTrendlineLimit, transformDataOfVolumeOfOilAndProducedWaterSpills?.map(item => item?.year)),
            onFilterChartType: onFilterChartType,
            selectedFilter: selectedVolumeOfOilAndProducedChart,
            showGraph: showVolumeOfOilAndProducedWaterGraph
        },

    ];

    const graphArr = dummyGraphArr;

    return (
        <><div className="full-cont">
            <div className="background-F8F8FA">
                {loading && <LoadingSpinner />}
                {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={resDetail} setVisibility={setVisibility} />}
                <div>
                    <ModuleInfo moduleName={'Operational Compliance'} moduleDesc={''} reportButton={true} permissions={permissionsObj} />
                </div>
                <div>
                    <div className="mt-5 px-3">
                        <div className="global-customize-container">
                            <ComplianceSubModuleDropdown />
                            <div>
                                <Button size="sm" className="btn-light margin-right-15 bg-white" onClick={handleGlobalShow}><img src={CustomizeButtonIcon} className="mx-1 my-1" />Customize</Button>
                                <Button size="sm" className="btn-light margin-right-15 bg-white" onClick={handleExport}><img src={ExportButtonIcon} className="mx-1 my-1" />Export</Button>
                                <div className="dropdown mr-2">
                                    <button className={`btn dropdown-toggle ${globalFilterDuration ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Choose Duration
                                        <img className="ml-8" src={globalFilterDuration ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                                    </button>
                                    <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
                                        <a className="dropdown-item cursor" onClick={(e) => { onFilter('yearly'); }}>
                                            <div className="">
                                                {globalFilterDuration == 'yearly' ? <img src={FilterTickIcon} /> : ''}
                                                <label className={` cursor ${globalFilterDuration == 'yearly' ? 'selected-filter-color ml-8' : ''}`}>Yearly</label>
                                            </div>
                                        </a>
                                        <a className="dropdown-item cursor" onClick={(e) => { onFilter('quarterly'); }}>
                                            <div className="">
                                                {globalFilterDuration == 'quarterly' ? <img src={FilterTickIcon} /> : ''}
                                                <label className={` cursor ${globalFilterDuration == 'quarterly' ? 'selected-filter-color ml-8' : ''}`}>Quarterly</label>
                                            </div>
                                        </a>
                                        <a className="dropdown-item cursor" onClick={(e) => { onFilter('monthly'); }}>
                                            <div className="">
                                                {globalFilterDuration == 'monthly' ? <img src={FilterTickIcon} /> : ''}
                                                <label className={` cursor ${globalFilterDuration == 'monthly' ? 'selected-filter-color ml-8' : ''}`}>Monthly</label>

                                            </div>
                                        </a>
                                        <div className="mt-2 float-right">
                                            <button type="button" className="btn-clear" onClick={(e) => { onFilter('yearly'); }}>Clear</button>
                                        </div>
                                    </div>
                                </div>

                                {(role == 'company_admin') || (role == 'user' && (permissionsObj?.readAccess || permissionsObj?.writeAccess)) ?
                                    <button className='success-btn ml-0' onClick={navigateToList}>Spill Management</button> : null}

                            </div>
                        </div>
                    </div>

                    <div className="header-tab-container">
                        <DashboardCount data={finalArr} filterDuration={globalFilterDuration} type={'Spills'} />
                    </div>

                    {graphList && !graphList.some(item => item.trim()) && <p className="py-4 my-2 heading-font text-center pt-20 pb-20">No Graph Selected</p>}

                    <Drag graphArr={graphArr} graphList={graphList} moduleName={'Operational Compliance'} />

                </div>
            </div>
        </div>

            <Modal show={show} dialogClassName="listModal" onHide={() => setShow(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                    <div className="head_2 ml-36">{modalHeading}</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto -mt-20">
                    <div className="modal-body">
                        <SpillsDataModal data={yearSpillsList} selectedYear={selectedYear} modalHeading={modalHeading} selectedStateIdsForBars={selectedStatesIdsForBars} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={globalModal} onHide={() => setGlobalModal(!globalModal)} className="custom-modal-spills-customize">
                <Modal.Header closeButton onClick={() => handleGlobalFilterClose()}>
                    <Modal.Title className="pop-up-heading">I want to view below charts on my Dashboard</Modal.Title>
                </Modal.Header>

                <hr className="line-width" />

                <Modal.Body className="pad-bottom-0">
                    <Row className="">
                        <Col sm={12} className="text-right">
                            <button className="btn-select-all" onClick={() => selectAllGlobalFilters()}>Select All
                            </button>
                        </Col>
                        <Col sm={12} className="margin-bottom-20">
                            <span className="pop-up-body-heading">List of Charts</span>
                        </Col>
                    </Row>
                    <div className="">
                        <Row>
                            <Col md={12}>
                                <CheckboxSelect index={1} data={spillsCharts} colSize={6} checkedValues={selectedspillsChartsGlobalFilters} onChange={onSpillChartsChange} />
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>

                <hr className="mt-4 line-width" />

                <Modal.Footer className="padding-top-0 move-right mt--20">
                    <button className="btn-add-data mr-15 mt-4 border-0" onClick={() => deselectAllGlobalFilters()}>Reset
                    </button>
                    <button className='report_btn border-0 mt-4' onClick={applyGlobalCustomization}>Customize
                    </button>
                </Modal.Footer>
            </Modal>

        </>

    )
}

export default SpillsDashboard;