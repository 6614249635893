import React from 'react';
import { Modal } from 'react-bootstrap';
import './index.css';

const ConfirmationModal = ({ show, onHide, onConfirm, message }) => {

    return (
        <Modal show={show} onHide={onHide} dialogClassName="reportConfrmModal">
            <Modal.Header className="sideOnRight" closeButton>
            </Modal.Header>
            <Modal.Body className="auth_modal_header border-0 mx-auto">
                <div className="modal-body reportText">
                    {message}
                </div>
                <Modal.Footer>
                    <button type="button" className="btn-no" onClick={onHide}>No</button>
                    <button type="button" className="btn-yes" onClick={onConfirm}>Yes</button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmationModal;

