import { Modal } from 'react-bootstrap';
import ImageDownloadIcon from '../../assets/images/DownloadImageIcon.svg';
import FilePreviewFinal from '../FileUpload/FilePreviewFinal';

const ImagePreviewPopUp = (props) => {
    const {
        showPreviewImg,
        setShowPreviewImg,
        downloadImg,
        imageName,
        imageObj,
        imageToPreview,
        docFormatType
    } = props;

    return (
        <Modal size="lg"
            dialogClassName="modal-90w"
            show={showPreviewImg}
            onHide={() => setShowPreviewImg(false)}>
            <Modal.Header className="sideOnRight" closeButton>
                {imageName}
            </Modal.Header>
            <Modal.Body className="auth_modal_header border-0 mx-auto">
                {['pdf'].includes(docFormatType) && <FilePreviewFinal pdfFile={imageToPreview ? imageToPreview : ''} />}
                {['jpg', 'jpeg', 'png'].includes(docFormatType) && <div className="modal-body reportText">
                    <img src={imageToPreview} className="img-preview" />
                </div>}
            </Modal.Body>
        </Modal>
    );
}

export default ImagePreviewPopUp;