import React, { useState } from "react";
import CheckboxSelect from "../../../components/formBuilder/CheckboxSelect";
import data from '../../../mockData/mockData';
import UploadIcon from '../../../assets/images/FormImages/upload.svg';

const LabelEventCustomization = () => {
    const [editLabel, setEditLabel] = useState(true);
    const [editArray, setEditArray] = useState([false, false, false, false, false, false]);

    const onClickSetEditLabel = (value) => {
        const clone = [...editArray];
        clone[value] = true;
        setEditArray(clone);
    }

    const onChange = () => {
        // to be used in the future for the API Integration
    }

    return (
        <div>
            <div className='container_div'>
                <div className="row">
                    {!editArray[0] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(0) }}>Event Description<span className='limitText'>Limit 500</span></div>}
                    {editArray[0] && <input type='text' className='form-control mb-2' placeholder='Event Description' defaultValue='Event Description' />}
                    <input type='text' disabled={editLabel} className='form-control' placeholder='Event Description' />
                </div>

                <div className='row mt-30'>
                    <div className='col-md-12'>
                        {!editArray[1] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(1) }}>Investigation Conclusion</div>}
                        {editArray[1] && <input type='text' className='form-control mb-2' placeholder='Investigation Conclusion' defaultValue='Investigation Conclusion' />}
                    </div>
                    <div className="row">
                        <CheckboxSelect data={data.Failures}
                            colSize={4}
                            editLabel={editLabel}
                            onChange={onChange} />
                    </div>
                </div>


                <div className="row mt-30">
                    {!editArray[2] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(2) }}>Add more details<span className='limitText'>Limit 500</span></div>}
                    {editArray[2] && <input type='text' className='form-control mb-2' placeholder='Add more details' defaultValue='Add more details' />}
                    <input type='text' disabled={editLabel} className='form-control' placeholder='Add more details' />
                </div>


                <div className='row mt-30'>
                    <div className='col-md-12'>
                        {!editArray[3] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(3) }}>Corrective Actions</div>}
                        {editArray[3] && <input type='text' className='form-control mb-2' placeholder='Corrective Actions' defaultValue='Corrective Actions' />}
                    </div>
                    <div className="row">
                        <CheckboxSelect data={data.CorrectiveActions}
                            colSize={4}
                            editLabel={editLabel} 
                            onChange={onChange}/>
                    </div>
                </div>


                <div className="row mt-30">
                    {!editArray[4] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(4) }}>Add more details<span className='limitText'>Limit 500</span></div>}
                    {editArray[4] && <input type='text' className='form-control mb-2' placeholder='Add more details' defaultValue='Add more details' />}
                    <input type='text' disabled={editLabel} className='form-control' placeholder='Add investigation details' />
                </div>

                <div className="row mt-30">
                    {!editArray[5] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(5) }}>Upload Supporting Documentation</div>}
                    {editArray[5] && <input type='text' className='form-control mb-2' placeholder='Upload Supporting Documentation' defaultValue='Upload Supporting Documentation' />}
                    <div className="col-md-6">
                        <div className='supportDcmnt'>
                            <img className="mt-30" src={UploadIcon} />
                            <div className='dragTitle'>Drag and drop files here</div>
                            <div className='orIcon'>or</div>
                            <button type="button" className='btn-add-data mr-15'>Browse files</button>
                        </div>
                        <div className='wthoutSpcl'>Up to 25 MB - File name without special characters</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LabelEventCustomization;