import Login from "../pages/Auth/Login/Login";
import ResetPassword from "../pages/Auth/ResetPassword/ResetPassword";
import NotFound from "../pages/NotFound/NotFound";

export const PublicRoutes = 
  [
    {
      'element': <Login />,
      'path': '/login'
    },
    {
      'element': <ResetPassword />,
      'path': '/reset-password'
    },
    {
      'element': <NotFound />,
      'path': '*',
      'headerTitle': 'NotFound',
    }
  ];