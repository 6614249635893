export const CLOSED_STATUS = 'Closed';
export const OTHERS_VALUE = 'Others';
export const NULL_VALUE = 'null';
export const AM_MERIDIAN = 'AM';
export const PM_MERIDIAN = 'PM';
export const OPEN_STATUS = 'Open';
export const HOUR = 'hour';
export const MINUTES = 'minutes';
export const MERIDIAN = 'meridian';
export const CITY = 'city';
export const STATE_ID = 'state_id';
export const FACILITY_ID = 'facility_id';
export const REQUIRED_VALIDATION = 'required';
export const MAXLENGTH_VALIDATION = 'maxLength';
export const MINLENGTH_VALIDATION = 'minLength';
export const DATE_OF_CLOSURE = 'date_of_closure';
export const CATEGORY_VARIABLE = 'category';
export const DATE_OF_DISCOVERY = 'date_of_discovery';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const ZIP_CODE = 'zip_code';
export const STATE = 'state';
export const PHONE_NUMBER = 'phone_number';
export const SPILL_TYPE = 'spill_type';
export const STEP_ONE_SUBMITTED_STATUS = 'step_one_submitted';
export const FAILED_STATUS = 'failed';
export const SUCCESS_STATUS = 'success';
export const MMDDYYYY_FORMAT = 'MM-DD-YYYY';
export const NA_STATUS = 'NA';
export const ACCESS_TOKEN = 'access_token';
export const DRAFT_STATUS = 'Draft';
export const SUBMITTED_STATUS = 'Submitted';
export const IMAGE_JPG_FORMAT = 'image/jpg';
export const IMAGE_JPEG_FORMAT = 'image/jpeg';
export const IMAGE_PNG_FORMAT = 'image/png';
export const PDF_FORMAT = 'application/pdf';
export const XLSX_FILE_FORMAT = 'xlsx';
export const XLSX_FILE_SPREADSHEET_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const XLSX_VND_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV_FILE_FORMAT = 'text/csv';
export const DOCX_FILE_FORMAT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const DOC_MS_WORD_FILE_FORMAT = 'application/msword';
export const TOO_LARGE_FILE = 'too large';
export const USER_ID = 'userId';
export const USER = 'user';
export const PERMISSIONS = 'permissions';
export const ROLE = 'role';
export const SAFETY = 'safety';
export const SMALL_INCIDENT = 'incident';
export const SMALL_EVENT = 'event';
export const FIRST_CAP_INCIDENT = 'Incident';
export const FIRST_CAP_EVENT = 'Event';
export const COMPANY_ADMIN = 'company_admin';
export const OCCURRENCE_DATE_RANGE = 'Occurrence Date Range';
export const COMPLIANCE = 'compliance';