import React, { useEffect, useState } from "react";
import { DatePicker, Space, Modal } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ editLabel, onDateRangeSelection, dateRange, onClear }) => {

    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [selectedMainDateRange, setSelectedMainDateRange] = useState([null, null]);
    const [calendarOpen, setCalendarOpen] = useState(true);

    const handleClear = () => {
        // Clear the date range by setting it to an empty array
        setSelectedDateRange([null, null]);
      };

      const disabledDate = current => {
        // Disable dates after today
        return current && current > moment().endOf('day');
    };

      const toggleCalendar = () => {
        setCalendarOpen(false);
        setTimeout(() => {
        setCalendarOpen(true);
        })
      };

    return (
        <Space direction="vertical" className="mt-10 fullWidthAntPicker" size={12}>
            <RangePicker value={selectedDateRange} allowClear={dateRange} popupClassName="createDateRangePicker" format="MM-DD-YYYY" disabledDate={disabledDate} onChange={(dates, dateString) => { setSelectedDateRange(dates); setSelectedMainDateRange(dateString); onDateRangeSelection(dateString) }}
                renderExtraFooter={() =>
                    <div className="flex mt-2 mb-2 mr-2 float-right">
                        <button type="button" className="btn-clear bg-white" onClick={() => {handleClear(); toggleCalendar(); onClear()}}>Clear</button>
                        {/* <button type="button" className="btn-done border-0" onClick={() => {(selectedDateRange[0] !== null) && onDateRangeSelection(selectedMainDateRange); toggleCalendar()}}>Done</button> */}
                    </div>
                } />
        </Space>
    )
}

export default DateRangePicker;