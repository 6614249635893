import React from "react";
import { Col, Row } from 'react-bootstrap';

const RadioSelect = ({ data, editLabel, onRadioChange, name, colSize, checked }) => {
    return (
        <Row>
            {data.map((item, index) => (
                <Col md={colSize} key={index} className="py-2">
                    <input 
                    type="radio"
                    name={name}
                    id={item.id}
                    value={item.value} 
                    disabled={editLabel}
                    checked={checked}
                    onChange={onRadioChange} />
                <label htmlFor={item.id} className="px-2">{item.label}</label>
                </Col>
            )
            )}
        </Row>
    )
}

export default RadioSelect;