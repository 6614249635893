import React, { useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import Select from 'react-select';
import RadioSelect from '../../../components/formBuilder/RadioSelect';
import CheckboxSelect from '../../../components/formBuilder/CheckboxSelect';
import data from '../../../mockData/mockData';

function IncidentLabelDetails() {

    const [radioInput, setRadioInput] = useState([
        { label: 'Employee', name: 'impacted_person', value: 'Employee', id: 'employee' },
        { label: 'Contractor', name: 'impacted_person', value: 'Contractor', id: 'contractor' },
        { label: 'NA', name: 'impacted_person', value: 'NA', id: 'not_applicable' },
    ]);
    const [editLabel, setEditLabel] = useState(true);
    const [impactedValue, setImpactedValue] = useState(0);
    const [otherLocationValue, setOtherLocationValue] = useState("");
    const [injuryType, setInjuryType] = useState("");
    const [editArray, setEditArray] = useState([false, false, false, false, false, false, false, false, false, false]);


    const { register, control } = useForm({
        defaultValues: {
            test: [{ firstName: "Bill", lastName: "Luo" }]
        }
    });
    const {
        fields,
        append,
    } = useFieldArray({
        control,
        name: "test"
    });

    const onRadioChange = (ev) => {
        setImpactedValue(ev.target.value);
    };

    const onClickSetEditLabel = (value) => {
        const clone = [...editArray];
        clone[value] = true;
        setEditArray(clone);
    }

    const onChange = () => {
        // to be used in the future for the API Integration
    }
    
    return (
        <div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-6'>
                        {!editArray[0] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(0) }}>Date of Occurrence*</div>}
                        {editArray[0] && <input type='text' className='form-control' placeholder='Date of Occurrence*' defaultValue='Date of Occurrence'/>}
                       
                    </div>
                    <div className='col-md-6'>
                        {!editArray[1] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(1) }}>Time*</div>}
                        {editArray[1] && <input type='text' className='form-control' placeholder='Time*' defaultValue='Time'/>}
                        <div className='row mt-16'>
                            <div className='col-md-4'>
                                <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Date' options={data.Dates} />
                            </div>
                            <div className='col-md-4'>
                                <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Month' options={data.Months} />
                            </div>
                            <div className='col-md-4'>
                                <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Meridian' options={data.Meridian} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[2] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(2) }}>Name of the Incident*</div>}
                        {editArray[2] && <input type='text' className='form-control' placeholder='Name of the incident*' defaultValue='Name of the incident'/>}
                        <input type='text' disabled={editLabel} className='form-control mt-2' placeholder='Type here For eg Refinery Fire' />
                    </div>
                    <div className='col-md-6'>
                        {!editArray[3] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(3) }}>Location of Occurrence*</div>}
                        {editArray[3] && <input type='text' className='form-control' placeholder='Location of Occurrence*' defaultValue='Location of Occurrence'/>}
                        <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the location where the event occured' options={data.Locations} onChange={(location) => setOtherLocationValue(location)} />
                    </div>
                </div>

                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[4] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(4) }}>Please type in the Location*</div>}
                        {editArray[4] && <input type='text' className='form-control' placeholder='Please type in the Location*' defaultValue='Please type in the Location'/>}
                        <input type='text' disabled={editLabel} className='form-control mt-2' placeholder='Type here For eg Near basin' />
                    </div>

                </div>

            </div>

            <div className='container_div'>
                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[5] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(5) }}>Incident Type*</div>}
                        {editArray[5] && <input type='text' className='form-control' placeholder='Name of the Incident*' defaultValue='Name of the Incident'/>}
                        <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the incident type' options={data.Locations} onChange={(location) => setOtherLocationValue(location)} />
                    </div>

                    <div className='col-md-6'>
                        {!editArray[6] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(6) }}>Type of Person Impacted*</div>}
                        {editArray[6] && <input type='text' className='form-control' placeholder='Type of Person Impacted*' defaultValue='Type of Person Impacted'/>}
                        <div className='rdImpactdGroup'>
                            <RadioSelect data={radioInput} editLabel={editLabel} colSize={4} />
                        </div>
                    </div>

                </div>

                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[7] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(7) }}>Type of injury</div>}
                        {editArray[7] && <input type='text' className='form-control' placeholder='Type of injury*' defaultValue='Type of injury'/>}
                        <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the type of injury' options={data.InjuryTypes} onChange={(location) => setOtherLocationValue(location)} />
                    </div>

                    <div className='col-md-6'>
                        {!editArray[8] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(8) }}>Affected Body Part</div>}
                        {editArray[8] && <input type='text' className='form-control' placeholder='Affected Body Part' defaultValue='Affected Body Part'/>}

                        <div className='mt-2'>
                            <CheckboxSelect data={data.BodyParts} editLabel={editLabel} colSize={3} onChange={onChange} />
                        </div>
                    </div>

                </div>

                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[9] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(9) }}>Area of work of person*</div>}
                        {editArray[9] && <input type='text' className='form-control' placeholder='Area of work of person*' defaultValue='Area of work of person' />}
                        <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the area of work of person' options={data.JobTitle} onChange={(location) => setOtherLocationValue(location)} />
                    </div>

                </div>
            </div>

        </div>
    )
}

export default IncidentLabelDetails
