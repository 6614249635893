import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./EventList.css";
import { OverlayTrigger } from 'react-bootstrap';
import data from '../../../mockData/mockData';
import InfoIcon from '../../../assets/images/Info.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import ThreeDotsIcon from '../../../assets/images/FormImages/threeDots.svg';
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import GreenTickIcon from '../../../assets/images/FormImages/greenTick.svg';
import TickIcon from '../../../assets/images/FormImages/tick.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import ForwardPagination from '../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../assets/images/BackwardPagination.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import FadedDownloadDoc from '../../../assets/images/FormImages/fadedDownloadIcon.svg';
import EditSvg from '../../../assets/images/FormImages/edit.svg';
import SelectDropdownAction from "../../../components/formBuilder/SelectDropdownAction";
import DateRangePicker from "../../../components/formBuilder/DateRangePicker";
import { Delete, Get, Post, ImagePost } from "../../../constants/apiMethods";
import { deleteEventListData, exportToDoc, exportToExcel, duplicateEventListData, filter, safetyList, search, eventIncidentBulkUpload } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import ReactPaginate from "react-paginate";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import { Modal } from "react-bootstrap";
import ChangingProgressProvider from '../../../components/ChangingProgressProvider/ChangingProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import infoIcon from '../../../assets/images/Info.svg';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FileUploader from "../../../components/FileUploader";
import FileUploadProgressContainer from "../../../components/FileUpload/FileUploadProgressContainer";
import Popover from 'react-bootstrap/Popover';
import { MMDDYYYY_FORMAT, ACCESS_TOKEN, STEP_ONE_SUBMITTED_STATUS, SUCCESS_STATUS, FAILED_STATUS, USER_ID, USER, PERMISSIONS, ROLE, SAFETY, SMALL_EVENT, SMALL_INCIDENT, FIRST_CAP_INCIDENT, FIRST_CAP_EVENT, COMPANY_ADMIN, OCCURRENCE_DATE_RANGE, DRAFT_STATUS } from '../../../constants/Global';

const EventList = () => {
	let permissionsObj;
	const role = localStorage.getItem(ROLE);
	if (role === USER) {
		let permissions = JSON.parse(localStorage.getItem(PERMISSIONS));
		let safetyPermissionObject = permissions.filter(item => item.moduleName.toLowerCase() === SAFETY);
		permissionsObj = {
			dashboardAccess: safetyPermissionObject[0].dashboardChecked,
			exportAccess: safetyPermissionObject[0].exportAccess,
			readAccess: safetyPermissionObject[0].readAccess,
			writeAccess: safetyPermissionObject[0].writeAccess
		}
	}

	const [showEvent, setEventSelected] = useState(true);
	const [showFilters, setFilters] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [categoryValue, setCategoryValue] = useState('');
	const [incidentTypeValue, setIncidentTypeValue] = useState('');
	const [viewValue, setViewValue] = useState();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [dateRangeValue, setDateRangeValue] = useState('');
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1);
	const [safetyListData, setSafetyList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [clearDateValue, setClearDateValue] = useState(true);
	const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState('');
	const [alertType, setAlertType] = useState('');
	const [successRes, setSuccessRes] = useState(false);
	const [showHideDelete, setshowHideDelete] = useState({ show: false, viewData: {} });
	const [isOpen, setIsOpen] = useState(false);
	const token = localStorage.getItem(ACCESS_TOKEN);
	const [images, setImages] = useState([]);
	const [csvFiles, setCsvFiles] = useState('');
	const [fileData, setFile] = useState('');
	const [currentFile, setCurrentFile] = useState('');
	const [showUpload, setShowUpload] = useState(true);
	const [showProcessing, setShowProcessing] = useState(false);
	const [showFileContainer, setShowFileContainer] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState('');
	const [showHideUploadContainer, setShowHideUploadContainer] = useState({ show: false });
	let PageSize = 10;
	let hover = false;

	useEffect(() => {
	}, [images])

	useEffect(() => {
	}, [csvFiles])

	useEffect(() => {
		getSafetyList(1);
		localStorage.removeItem(STEP_ONE_SUBMITTED_STATUS);
		localStorage.removeItem(USER_ID);
	}, []);

	const convertBytesToKb = (bytes) => {
		return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
	};

	const onFileChange = (files) => {
		setShowFileContainer(false);
		setCurrentFile(files[0]);
		setFile(files[0]);
		setShowUpload(false);
		setShowProcessing(true);
		setTimeout(() => {
			setShowProcessing(false);
			setShowFileContainer(true);
		}, 5000)
	}

	const handleFileUpload = () => {
		setLoading(true);
		const formData = new FormData();
		formData.append(`file`, fileData);
		ImagePost(eventIncidentBulkUpload, token, formData, true)
			.then((res) => {
				setLoading(true);
				if (res.success) {
					setShowSuccessMessage(res.message);
					setAlertType(SUCCESS_STATUS);
					setLoading(false);
					setSuccessRes(true);
					setShowHideUploadContainer({ show: false });
				}
				if (!res.success) {
					setShowSuccessMessage(res.message);
					setAlertType(FAILED_STATUS);
					setLoading(false);
					setSuccessRes(true);
					setShowHideUploadContainer({ show: false });
				}
			})
			.catch((error) => {

			});
	}

	const sampleFormat = () => {
		window.open(`${process.env.REACT_APP_SAMPLE_FORMAL_URL}sample_format/safetybulkupload.csv`, '_self')
	}

	const getSafetyList = (page) => {
		setLoading(true);
		Get(safetyList, token, `?page=${page}`, true)
			.then((response) => response)
			.then((data) => {
				if (data.code === 200) {
					setLoading(false);
					const listData = data.result.data?.map((resp) => {
						return { ...resp, incidentArrayValue: 3 }
					})
					setSafetyList(listData);
					setCurrentPage(data.result);
				}
				if (data.code === 400) {
					setLoading(false);
					navigate('/login');
					localStorage.clear();
				}
			});
	};

	const pageChange = (page) => {
		if (searchValue || categoryValue || incidentTypeValue || (viewValue === 0 || viewValue === 1) || dateRangeValue[0] || dateRangeValue[1]) {
			onFilter(searchValue, categoryValue, incidentTypeValue, viewValue, dateRangeValue, page + 1)
		} else {
			getSafetyList(page + 1)
		}
	}

	const setVisibility = (data) => {
		setSuccessRes(data);
	}

	const onFilter = (searchValue, categoryValue, incidentTypeValue, viewValue, dateRangeValue, page) => {
		hideElement();
		setSearchValue(searchValue);
		setCategoryValue(categoryValue);
		setIncidentTypeValue(incidentTypeValue);
		setViewValue(viewValue);
		setDateRangeValue(dateRangeValue);
		setStartDate(dateRangeValue[0]);
		setEndDate(dateRangeValue[1]);
		if (searchValue || categoryValue || incidentTypeValue || (viewValue === 0 || viewValue === 1) || dateRangeValue[0] || dateRangeValue[1]) {
			const data = {
				searchkey: searchValue,
				category: categoryValue,
				incidentType: incidentTypeValue,
				view: Number(viewValue),
				start_date: dateRangeValue[0],
				end_date: dateRangeValue[1],
				page: page
			}
			setLoading(true);
			Post(filter, token, data, true)
				.then((response) => response)
				.then((data) => {
					setLoading(false);
					if (data.code === 200) {
						const listData = data.result.data?.map((resp) => {
							return { ...resp, incidentArrayValue: 3 }
						})
						setSafetyList(listData);
						setCurrentPage(data.result);
						hideElement();
					}
				});
		} else {
			getSafetyList(1);
			hideElement();
		}
	};

	const deleteDataFromList = () => {
		setShowSuccessNotifier(false);
		setSuccessRes(false);
		setLoading(true);
		const data = {
			id: showHideDelete?.viewData?.id
		}
		Delete(deleteEventListData, token, data, true)
			.then((response) => response)
			.then((data) => {
				setLoading(false);
				if (data.success) {
					getSafetyList(1);
					setShowSuccessNotifier(true);
					setSuccessRes(true);
					setAlertType(SUCCESS_STATUS);
					setshowHideDelete({ show: false, viewData: '' })
					setShowSuccessMessage(`${showHideDelete?.viewData?.type === SMALL_INCIDENT ? FIRST_CAP_INCIDENT : FIRST_CAP_EVENT} deleted successfully`);
				}
			});
	};

	const downloadAsExcelSheet = (id = '') => {
		Get(exportToExcel, '', id, true)
			.then((response) => response)
			.then((data) => {
			});
		window.open(`http://34.196.139.67:8001/api/export/excel/${id}`, '_self')
	};

	const downloadAsWordDoc = (id = '') => {
		setLoading(true);
		Post(exportToDoc, token, id, true)
			.then((response) => response)
			.then((data) => {
				if (data?.success) {
					setSuccessRes(true);
					setAlertType(SUCCESS_STATUS);
					setShowErrorMessage(data.message);
					setLoading(false);
				}
				if (!data?.success) {
					setSuccessRes(true);
					setAlertType(FAILED_STATUS);
					setShowErrorMessage(data.message);
					setLoading(false);
				}
			});
	};

	const showSelected = (value) => {
		setEventSelected(value);
	}

	const handleClick = () => {
		if (showEvent === true) {
			navigate('/add-event');
		} else {
			navigate('/add-incident');
		}
	}

	const clearFilters = () => {
		setCategoryValue('');
		setIncidentTypeValue('');
		setViewValue();
		setDateRangeValue('');
		setStartDate('');
		setEndDate('');
		setIsOpen(false);
		setClearDateValue(false);
		setTimeout(() => {
			setClearDateValue(true);
		}, 1)
		getSafetyList(1);
	}

	const showMoreIncidents = (item) => {
		const newState = safetyListData.map(obj => {
			if (obj.id === item.id) {
				return { ...obj, incidentArrayValue: obj.incidentdata.length };
			}
			return obj;
		});
		setSafetyList(newState);
	}

	const showLessIncidents = (item) => {
		const newState = safetyListData.map(obj => {
			if (obj.id === item.id) {
				return { ...obj, incidentArrayValue: 3 };
			}
			return obj;
		});
		setSafetyList(newState);
	}

	const onViewData = (item) => {
		navigate(item.type === SMALL_INCIDENT ? '/incident-details' : '/event-details', { state: { id: item?.id } });
	}

	const onUpdateData = (item) => {
		navigate(item.type === SMALL_INCIDENT ? '/update-incident' : '/update-event', { state: { id: item?.id } });
	}

	const hideElement = () => {
		const element1 = document.getElementById('dropdownHidden4');
		if (element1) {
			element1.classList.remove('show');
		}
		const element2 = document.getElementById('dropdownHidden2');
		if (element2) {
			element2.classList.remove('show');
		}
		const element3 = document.getElementById('dropdownHidden3');
		if (element3) {
			element3.classList.remove('show');
		}
	}

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const popoverBottom = (
		<Popover id="popover-positioned-bottom" title="Popover bottom" style={{ position: 'relative', top: '50px' }} className="popuuu" placement="bottom">
			<div className="reportCat">Reporting Categorisation</div>
			<hr className="mt-mb-2" />
			<div className="headTolltip">Event</div>
			<div className="descrpTolltip">An EVENT consists of 2 or more incidents at the same Time* and the same location.</div>
			<div className="headTolltip">Incident</div>
			<div className="descrpTolltip">An INCIDENT causes an impact on people or property, such as a first aid injury, recordable injury, lost Time* injury,
				fatality, property damage, fire, vandalism, or vehicle accident.</div>
		</Popover>
	);

	return (
		<div>
			{loading && <LoadingSpinner />}
			{successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showErrorMessage} setVisibility={setVisibility} />}
			<div className="full-cont">
				<div className="row border_head_1">
					<div className="col-md-6">
						<div> <span className="head_1 cursor" onClick={() => { navigate('/safety') }}>Safety Dashboard</span> / <span className="head_3">Event and Incident Management</span></div>
						<div className="head_2">Event and Incident Management</div>
					</div>
					<div className="col-md-6 text-right">
						{((role === COMPANY_ADMIN) || (role === USER && (permissionsObj?.writeAccess))) && <>
							<button className='btn-cancel border-0' onClick={sampleFormat}>Sample Format</button>
							<button className='btn-add-data mr-15 mt-0' onClick={() => { setShowHideUploadContainer({ show: true }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); }}>Bulk Upload</button>
							<button className='report-btn-add-event-incident border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setEventSelected(true)}>Add Event/ Incident</button>
						</>
						}
					</div>
				</div>

				<div className="row inside_head">
					<div className="col-md-10">
						<div className="head_2">List of Added Events and Incidents
							<OverlayTrigger placement="bottom" overlay={popoverBottom}>
								<img className="infoHead cursor" src={infoIcon} alt={'alternateIcon'}
								/>
							</OverlayTrigger>
						</div>

					</div>
					<div className="col-md-2 text-right">
						<button className='export_btn' onClick={() => downloadAsWordDoc()}>
							<img src={EditSvg} alt='editImg' /> Export
						</button>
						{/* <SelectDropdownAction headingData={data.ExportHeading} data={data.Downloads} onDownloadAsExcelSheet={() => downloadAsExcelSheet()} onDownloadAsWordDoc={() => downloadAsWordDoc()} isImg={false} /> */}
					</div>
				</div>

				<div className="filter_options">
					{showFilters ? <><div className="filter_options_1">
						<div className="filtrBy">Filter by</div>

						<div className="dropdown">
							<button className={`btn dropdown-toggle ${categoryValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Category
								<img className="ml-8" src={categoryValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
							</button>
							<div className="dropdown-menu" id='dropdownHidden4' aria-labelledby="dropdownMenuButton1">
								<a className="dropdown-item cursor" onClick={(e) => { onFilter(searchValue, SMALL_EVENT, incidentTypeValue, viewValue, dateRangeValue) }}>
									<div className="">
										{categoryValue === SMALL_EVENT ? <img src={FilterTickIcon} /> : ''}
										<label className={` cursor ${categoryValue === SMALL_EVENT ? 'selected-filter-color ml-8' : ''}`}>Event</label>
									</div>
								</a>
								<a className="dropdown-item cursor" onClick={(e) => { onFilter(searchValue, SMALL_INCIDENT, incidentTypeValue, viewValue, dateRangeValue) }}>
									<div className="">
										{categoryValue === SMALL_INCIDENT ? <img src={FilterTickIcon} /> : ''}
										<label className={` cursor ${categoryValue === SMALL_INCIDENT ? 'selected-filter-color ml-8' : ''}`}>Incident</label>
									</div>
								</a>
								<div className="mt-2 float-right">
									<button type="button" className="btn-clear" onClick={(e) => { onFilter(searchValue, '', incidentTypeValue, viewValue, dateRangeValue) }}>Clear</button>
								</div>
							</div>
						</div>

						<div className="dropdown">
							<button className={`btn dropdown-toggle ${incidentTypeValue ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Type
								<img className="ml-8" src={incidentTypeValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
							</button>
							<div className="dropdown-menu" id='dropdownHidden2' aria-labelledby="dropdownMenuButton2">
								{data.IncidentType.map((parts) => (
									<a className="dropdown-item cursor" onClick={() => { onFilter(searchValue, categoryValue, parts?.label, viewValue, dateRangeValue) }}>
										<div className="">
											{incidentTypeValue === parts?.label ? <img src={FilterTickIcon} /> : ''}
											<label className={` cursor ${incidentTypeValue === parts?.label ? 'selected-filter-color ml-8' : ''}`}>{parts?.label}</label>
										</div>
									</a>
								))}
								<div className="mt-2 float-right">
									<button type="button" className="btn-clear" onClick={(e) => { onFilter(searchValue, categoryValue, '', viewValue, dateRangeValue) }}>Clear</button>
								</div>
							</div>
						</div>

						<div className="dropdown">
							<button className={`btn dropdown-toggle ${viewValue === 0 || viewValue === 1 ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Added On
								<img className="ml-8" src={viewValue === 0 || viewValue === 1 ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
							</button>
							<div className="dropdown-menu" id='dropdownHidden3' aria-labelledby="dropdownMenuButton1">
								<a className="dropdown-item cursor" onClick={(e) => { onFilter(searchValue, categoryValue, incidentTypeValue, 0, dateRangeValue) }}>
									<div className="">
										{viewValue === 0 ? <img src={FilterTickIcon} /> : ''}
										<label className={` cursor ${viewValue === 0 ? 'selected-filter-color ml-8' : ''}`}>Added</label>
									</div>
								</a>
								<a className="dropdown-item cursor" onClick={(e) => { onFilter(searchValue, categoryValue, incidentTypeValue, 1, dateRangeValue) }}>
									<div className="">
										{viewValue === 1 ? <img src={FilterTickIcon} /> : ''}
										<label className={` cursor ${viewValue === 1 ? 'selected-filter-color ml-8' : ''}`}>Saved As Draft</label>
									</div>
								</a>
								<div className="mt-2 float-right">
									<button type="button" className="btn-clear" onClick={(e) => { onFilter(searchValue, categoryValue, incidentTypeValue, setViewValue(), dateRangeValue) }}>Clear</button>
								</div>
							</div>
						</div>

						<div className="dropdown-container">
							<button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${dateRangeValue ? 'selected-filter-color' : ''}`}>
								{dateRangeValue ? startDate + ' - ' + endDate : OCCURRENCE_DATE_RANGE}
								<img className="ml-8" src={dateRangeValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
							</button>
							{isOpen && (
								<ul className="dropdown-list">
									{clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { onFilter(searchValue, categoryValue, incidentTypeValue, viewValue, dateString) }} onClear={() => { setDateRangeValue(''); toggleDropdown(); onFilter(searchValue, categoryValue, incidentTypeValue, viewValue, '') }} />}
								</ul>
							)}
						</div>

					</div>
						<div className="filter_options_1">
							<div className="" onClick={clearFilters}>
								<button className='clear_btn'>
									<img className="editIcn" src={ClearIcon} />Clear filter
								</button>
							</div>
							<div className="cursor" onClick={() => setFilters(false)}>
								<img src={SearchIcon} alt="searchIcon" />
							</div>
						</div></> :

						<div className="searchBox">
							<input type="text" className="form-control search-input" placeholder="Search by typing Incident type or Added by or Name of the Event/Incident"
								onChange={(event) => onFilter(event.target.value, categoryValue, incidentTypeValue, viewValue, dateRangeValue)} value={searchValue} />
							<img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); setSearchValue(''); onFilter('', categoryValue, incidentTypeValue, viewValue, dateRangeValue) }} />
						</div>}

				</div>

				<div className="table-responsive">
					<><table className="table">
						<thead>
							<tr>
								<th scope="col">Occurred On</th>
								<th scope="col">Category</th>
								<th scope="col">Name</th>
								<th scope="col">Type</th>
								<th scope="col">Added By</th>
								<th scope="col">Added On</th>
								<th scope="col">Actions</th>
							</tr>
						</thead>

						<tbody>

							{safetyListData?.length > 0 && safetyListData.map((item, index) => {
								return (
									<>
										<tr className="brdrtop">
											<td>{moment(item?.formdata?.date).format(MMDDYYYY_FORMAT)}</td>
											<td>{item?.type.charAt(0).toUpperCase() + item?.type.slice(1)}</td>
											<td>{item?.formdata?.name}</td>
											<td>{item?.incidentdata.slice(0, item.incidentArrayValue).map((incident, incidentIndex) => { return <div>{incident?.incidentType}</div> })}
												{(item?.incidentdata?.length > item.incidentArrayValue) ?
													<div className="link-color" onClick={() => showMoreIncidents(item)}>+ {item?.incidentdata?.length - item.incidentArrayValue} {item?.incidentdata?.length - item.incidentArrayValue > 1 ? `${FIRST_CAP_INCIDENT}s` : FIRST_CAP_INCIDENT}</div> :
													item?.incidentdata?.length > 3 ? <div className="link-color" onClick={() => showLessIncidents(item)}>View Less</div> : ''}
											</td>
											<td>{item.formdata.reportedby}</td>
											<td>{item?.draft === 0 ? moment(item?.created_at).format(MMDDYYYY_FORMAT) : <div className="savedDraft">Saved As Draft</div>}</td>
											<td>
												<img className="editIcn" src={FadedDownloadDoc} />
												{item?.draft ?
												<SelectDropdownAction
													headingData={ThreeDotsIcon}
													itemData={item}
													data={data.DraftActionItems}
													// onView={() => onViewData(item)}
													onUpdate={() => onUpdateData(item)}
													onDelete={() => setshowHideDelete({ show: true, viewData: item })}
													isImg={true} />
												:
												<SelectDropdownAction
													headingData={ThreeDotsIcon}
													itemData={item}
													data={data.ActionItems}
													onView={() => onViewData(item)}
													onUpdate={() => onUpdateData(item)}
													onDelete={() => setshowHideDelete({ show: true, viewData: item })}
													isImg={true} />
												
											}
											</td>
										</tr>
									</>
								);
							})}

						</tbody>
					</table>
						{safetyListData?.length === 0 && <div className="row">
							<div className="col-md-12">
								<div className="text-center no-data">No Data Found</div>
							</div>
						</div>}
						{safetyListData?.length > 0 && <ReactPaginate
							forcePage={currentPage.current_page - 1}
							pageCount={currentPage.total / 10}
							pageRange={2}
							marginPagesDisplayed={2}
							onPageChange={(e) => pageChange(e.selected)}
							containerClassName={'pagination-body'}
							previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
							nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
							previousLinkClassName={'page'}
							breakClassName={'page'}
							nextLinkClassName={'page'}
							pageClassName={'page'}
							disabledClassName={'disabled'}
							activeClassName={'active'}
						/>}
					</>
				</div>

				<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title report_head" id="exampleModalLabel">Type of Reporting</h5>
								<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
									<img src={BtnCrossIcon} />
								</button>
							</div>
							<div className="modal-body text-center">
								<p className="reportHead">Please let us know whether the reporting is an Event or the Incident</p>
								<div className="mt-4">
									<button className={(showEvent ? 'selected_btn' : 'deselect_btn')} onClick={() => showSelected(true)}><img className="" src={showEvent ? GreenTickIcon : TickIcon} />Event</button>
									<div className="eventInfo"><img className="" src={InfoIcon} />An EVENT consists of 2 or more incidents at the same location.</div>
								</div>
								<div className="mt-4">
									<button className={(!showEvent ? 'selected_btn' : 'deselect_btn')} onClick={() => showSelected(false)}><img className="" src={!showEvent ? GreenTickIcon : TickIcon} />Incident</button>
									<div className="incidentInfo"><img className="" src={InfoIcon} />An INCIDENT causes an impact on people or property, such as a first aid injury, recordable injury,
										lost time injury, fatality, property damage, fire, vandalism, or vehicle accident.</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn-close-button" data-bs-dismiss="modal">Cancel</button>
								<button type="button" className="btn-proceed" data-bs-dismiss="modal" onClick={handleClick}>Proceed</button>
							</div>
						</div>
					</div>
				</div>

				<ConfirmationModal
					show={showHideDelete.show}
					onHide={() => setshowHideDelete({ show: false, viewData: '' })}
					onConfirm={deleteDataFromList}
					message={`Are you sure you would like to delete this ${showHideDelete.viewData.type}?`}
				/>

				<Modal show={showHideUploadContainer.show} dialogClassName="uploadModal" onHide={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>
					<Modal.Header className="sideOnRight" closeButton>
						<div className='inputFieldHead'>Upload File</div>
					</Modal.Header>
					<Modal.Body className="auth_modal_header border-0">
						{showUpload &&
							<FileUploader onFileUpload={onFileChange} />
						}
						{!showUpload && showProcessing && (
							<FileUploadProgressContainer
								type='imageProcessing'
								convertBytesToKb={convertBytesToKb}
								currentFile={currentFile}
								setShowUpload={setShowUpload}
								setFile={setFile}
								setCurrentFile={setCurrentFile}>
								<ChangingProgressProvider values={[0, 25, 50, 75, 100]}>
									{percentage => (
										<CircularProgressbar value={percentage} text={`${percentage}%`} />
									)}
								</ChangingProgressProvider>
							</FileUploadProgressContainer>
						)}

						{!showUpload && showFileContainer && (
							<FileUploadProgressContainer
								type='uploadedImage'
								convertBytesToKb={convertBytesToKb}
								currentFile={currentFile}
								setShowUpload={setShowUpload}
								setFile={setFile}
								setCurrentFile={setCurrentFile}>
								<img src={DownloadCompleteImg} />
							</FileUploadProgressContainer>
						)}
						<div className="modal-footer mr-48">
							<button type="button" className="btn-cancel-popup" onClick={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>Cancel</button>
							<button type="button" className="btn-upload border-0" onClick={handleFileUpload} disabled={fileData === '' || showProcessing}>Upload</button>
						</div>
					</Modal.Body>
				</Modal>

			</div>
		</div>
	)
}

export default EventList;