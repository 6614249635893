import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './CreateCompany.css'
import data from '../../../../mockData/mockData';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import errorIcon from "../../../../assets/images/error_icon.svg";
import DeleteIcon from '../../../../assets/images/FormImages/deleteIcon.svg';
import BtnCrossIcon from '../../../../assets/images/FormImages/btnCross.svg';
import { Get, Post, Put } from '../../../../constants/apiMethods';
import { companyList, getCityList, getStateList } from '../../../../constants/apiRoutes';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../../../components/Loader';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';
import { MINLENGTH_VALIDATION, REQUIRED_VALIDATION } from '../../../../constants/Global';


const CreateCompanyDetails = ({ isEditMode, next, id, active }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [citiesData, setCityData] = useState('');
  const [statesData, setStateData] = useState('');
  const [operatesDataList, setOperatesStateDataList] = useState([]);
  const [selectedCompanyLocation, setSelectedCompanyLocation] = useState([]);
  const [successRes, setSuccessRes] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
  const token = localStorage.getItem("access_token");

  const {
    control,
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      agency: [{ name: '', operator_id: '', url: '' }],
      address: [{ type_of_address: '', address_1: '', address_2: '', zip_code: '', city: '', state: '', company_phone_no: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'agency'
  });

  const { fields: group2Fields, append: appendGroup2, remove: removeGroup2 } = useFieldArray({
    control,
    name: 'address',
  });

  useEffect(() => {
    getOperatesStatesList();
    isEditMode && getCompanyDetails();
  }, [])

  const getCompanyDetails = () => {
    const step_one_submitted = localStorage.getItem('step_one_submitted');
    if (step_one_submitted) {
      active = true;
    }
    setLoading(true);
    Get(companyList, token, `?id=${id}&is_active=${active}`, false)
      .then((response) => response)
      .then((data) => {
        if (data) {
          setLoading(false);
          setValue('name', data.data.name);
          const addressData = data.data.address?.map((resp) => {
            let type_of_address = {
              name: resp.type_of_address,
              label: resp.type_of_address,
              value: resp.type_of_address,
            }
            return { ...resp, type_of_address }
          });
          setValue('address', addressData);
          setValue('agency', data.data.agency);
          const operatesData = data.data.company_locations?.map((resp) => {
            return { ...resp, label: resp.name, value: resp.name }
          })
          setValue('company_locations', operatesData);
          setSelectedCompanyLocation(operatesData);
        }
      });
  };

  const appendAgency = () => {
    const formData = getValues();
    const lastIndex = formData.agency.length - 1;

    if (formData.agency[lastIndex].name) {
      append({ name: '', operator_id: '', url: '' });
    } else {
      // alert("Please fill in the previous item before adding a new one.");
    }
  };

  const removeAgency = (index) => {
    remove(index);
  }

  const appendAdress = () => {
    const formData = getValues();
    const lastIndex = formData.address.length - 1;

    if (formData.address[lastIndex].address_1) {
      appendGroup2({ type_of_address: '', address_1: '', address_2: '', zip_code: '', city: '', state: '', company_phone_no: '' });
    }
    // else {
    //   alert("Please fill in the previous item before adding a new one.");
    // }
  };

  const removeAddress = (index) => {
    removeGroup2(index);
  }

  const onSubmit = (data) => {
    data.company_locations?.map((resp) => {
      return delete resp.name, delete resp.value, delete resp.label;
    })
    const valuesArray = data.company_locations?.flatMap(obj => Object.values(obj));

    data.company_locations = valuesArray;

    const modifiedArray = data.address.map(item => {
      const { type_of_address, ...rest } = item; // Destructure the object
      return {
        ...rest, // Keep other keys
        type_of_address: type_of_address.value // Keep only the 'value' property
      };
    });

    data.address = modifiedArray;
    setLoading(true);
    setSuccessRes(false);
    if (!isEditMode) {
      Post(`${companyList}/`, token, data, false)
        .then((response) => response)
        .then((data) => {
          setLoading(false);
          if (data.success) {
            next(data);
          } else {
            setAlertType('failed');
            setShowSuccessMessage(data.detail);
            setSuccessRes(true);
          }
        });
    } else {
      Put(`${companyList}/${id}/`, token, data, false)
        .then((response) => response)
        .then((data) => {
          setLoading(false);
          if (!data?.success) {
            setAlertType('failed');
            setShowSuccessMessage(data.detail);
            setSuccessRes(true);
          }
          if (data?.success) {
            next(data);
          }
        });
    }
  };

  const keypressNumber = (event) => {
    if (event.charCode >= 48 && event.charCode <= 57) {
      return event;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  const onChangeZipCode = (e, index) => {
    setZipcode(e.target.value);
    if (zipcode.length === 4) {
      getCitiesList(e.target.value, index);
    }
  }

  const onChangeCity = e => {
    setCityData(e.target.value);
  }

  const onChangeState = e => {
    setStateData(e.target.value);
  }

  const getIndexName = (index) => {
    const indexNameToShow = data.IndexData.filter((resp) => resp?.id == index)[0]?.label;

    return indexNameToShow;
  }

  const getCitiesList = (zip, index) => {
    Get(getCityList + `?page=1&zip_code=${zip}&is_active=true&untagged_cities=false`, token, '', false)
      .then((response) => response)
      .then((data) => {
        if (data.success) {
          setCityData(data?.data?.data[0]?.name);
          setValue(`address[${index}].city`, data?.data?.data[0]?.name);
          setValue(`address[${index}].city_id`, data?.data?.data[0]?.id);
          trigger(`address[${index}].city`);
          getStatesList(data?.data?.data[0]?.state_id, index);
        }
      });
  };

  const getStatesList = (stateId, index) => {
    Get(getStateList + `?page=1&id=${stateId}&is_active=true&min=true`, token, '', false)
      .then((response) => response)
      .then((data) => {
        // if (data.success) {
        setStateData(data?.name);
        setValue(`address[${index}].state`, data?.name);
        setValue(`address[${index}].state_id`, data?.id);
        trigger(`address[${index}].state`);
        // }
      });
  }

  const getOperatesStatesList = () => {
    Get(getStateList + `?is_active=true&min=true&sort=name`, token, '', false)
      .then((response) => response)
      .then((data) => {
        if (data.success) {
          const operatesData = data.data?.map((resp) => {
            return { ...resp, label: resp.name, value: resp.name }
          })
          setOperatesStateDataList(operatesData);
        }
      });
  }

  const onCompanyLocationChange = (selection, { name }) => {
    setValue(name, selection);
    setSelectedCompanyLocation((selection));
    trigger(name);
  }

  const onAddessTypeChange = (selection, { name }, index) => {
    setValue(name, selection);
    setSelectedAddressType((selection));
    trigger(name);
    // setValue(`address[${index}].type_of_address`, selection?.name);
  }

  const setVisibility = (data) => {
    setSuccessRes(data);
  }

  const onCancel = () => {
    navigate('../company-list');
  }

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };

  const handleChange = ({ target: { value } }, index) => {
    setCompanyPhone(prevState => (normalizeInput(value, prevState.phone)));
    const phoneNo = normalizeInput(value);
    setValue(`address[${index}].company_phone_no`, phoneNo);
    trigger(`address[${index}].company_phone_no`);
  }

  const handleDelete = (id) => {
    const newFeatures = selectedCompanyLocation.filter((item) => item.id !== id);
    setSelectedCompanyLocation(newFeatures);
    setValue('company_locations', newFeatures);
  }

  const captureClick = () => {
    setIsSubmittedOnce(true);
}

  return (
    <>
      {loading && <LoadingSpinner />}
      {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={showSuccessMessage} setVisibility={setVisibility} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='incLabel mt-30'>Company & Agency Details</div>
        <div className='container_div'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='inputFieldHead'>Name of the Company*</div>
              <input type='text'
                className='form-control'
                name='companyName'
                placeholder='Type here'
                {...register('name', {
                  required: true,
                })}
              />
              {errors.name?.type == "required" && (
                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
              )}
            </div>
          </div>

          {fields.map((question, index) => (
            <div className='row agency-container-color mt-4' key={question.id}>
              <div className='col-md-4'>
                <div className='inputFieldHead'>Name of the Agency*</div>
                <input type='text'
                  className='form-control'
                  name="agencyName"
                  placeholder='Type here'
                  {...register(`agency[${index}].name`, {
                    required: true
                  })}
                  control={control}
                />
                {errors.agency?.[index]?.name && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
              </div>
              <div className='col-md-4'>
                <div className='inputFieldHead'>Operator Id*</div>
                <input type='text'
                  className='form-control'
                  name="operatorId"
                  placeholder='Type here'
                  {...register(`agency[${index}].operator_id`, {
                    required: true
                  })}
                  control={control}
                />
                {errors.agency?.[index]?.operator_id && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
              </div>
              <div className='col-md-4'>
                <div className='inputFieldHead'>Agency Web URL*</div>
                <div className='flex'>
                  <input type='text'
                    className='form-control'
                    name="agencyWebUrl"
                    placeholder='Type here'
                    {...register(`agency[${index}].url`, {
                      required: true
                    })}
                    control={control}
                  />
                  {index != 0 && <span onClick={() => removeAgency(index)}><img className="editIcn cursor h-26 ml-20 mt-2" src={DeleteIcon} /></span>}
                </div>
                {errors.agency?.[index]?.url && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
              </div>
            </div>
          ))}

          <div className='text-right'>
            <button type="button" className='btn-add-incident mt-2' onClick={appendAgency}>Add Agency</button>
          </div>
        </div>

        {group2Fields.map((address, index) => (
          <>
            <div className='incLabel mt-30'>{getIndexName(index + 1)} Address</div>
            <div className='container_div' key={address.id}>
              {index != 0 && <div className='row'>
                <div className='col-md-12 text-right'>
                  <span onClick={() => removeAddress(index)}><img className="editIcn cursor h-26" src={DeleteIcon} /></span>
                </div>
              </div>}
              {/* {index != 0 && <span className='float-right' onClick={() => removeAddress(index)}><img className="editIcn cursor h-26" src={DeleteIcon} /></span>} */}
              <div className='row'>
                <div className='col-md-12'>
                  <div className='inputFieldHead'>Type Of Address*</div>
                  {/* <input type='text'
                    className='form-control'
                    name="typeofaddress"
                    placeholder='Type here'
                    {...register(`address[${index}].type_of_address`, {
                      required: true
                    })}
                    control={control}
                  /> */}
                  <Controller
                    name={`address[${index}].type_of_address`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        classNamePrefix="an-simple-select"
                        placeholder='Choose Address Type'
                        options={data?.AddressType}
                        onChange={(selection) => onAddessTypeChange(selection, field, index)}
                        value={field.value}
                      />
                    )}
                    rules={{ required: 'This field is mandatory' }}
                  />
                  {errors.address?.[index]?.type_of_address && (
                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                  )}
                </div>
                <div className='col-md-12 mt-30'>
                  <div className='inputFieldHead'>Address - Line 1*</div>
                  <input type='text'
                    className='form-control'
                    name="addressLine1"
                    placeholder='Type here'
                    {...register(`address[${index}].address_1`, {
                      required: true
                    })}
                    control={control}
                  />
                  {errors.address?.[index]?.address_1 && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                </div>
                <div className='col-md-12 mt-30'>
                  <div className='inputFieldHead'>Address - Line 2</div>
                  <input type='text'
                    className='form-control'
                    name="addressLine2"
                    placeholder='Type here'
                    {...register(`address[${index}].address_2`, {
                      required: false
                    })}
                    control={control}
                  />
                  {errors.address?.[index]?.address_2 && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                </div>
              </div>

              <div className='row mt-30'>

                <div className='col-md-6'>
                  <div className='inputFieldHead'>City*</div>
                  <Controller
                    name={`address[${index}].city`}
                    control={control}
                    render={({ field }) => (
                      <input type='text'
                        {...field}
                        className='form-control'
                        name="city"
                        placeholder='City'
                        control={control}
                      // value={citiesData}
                      // onChange={(e) => {
                      //   field.onChange(e);
                      //   onChangeCity(e);
                      // }}
                      />
                    )}
                    rules={{ required: 'This field is mandatory' }}
                  />
                  {errors.address?.[index]?.city && (
                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                  )}
                </div>

                <div className='col-md-6'>
                  <div className='inputFieldHead'>State*</div>
                  <Controller
                    name={`address[${index}].state`}
                    control={control}
                    render={({ field }) => (
                      <input type='text'
                        {...field}
                        className='form-control'
                        name="city"
                        placeholder='State'
                        control={control}
                      // value={statesData}
                      // onChange={(e) => {
                      //   field.onChange(e);
                      //   onChangeState(e);
                      // }}
                      />
                    )}
                    rules={{ required: 'This field is mandatory' }}
                  />
                  {errors.address?.[index]?.state && (
                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                  )}
                </div>
              </div>

              <div className='row mt-30'>

                <div className='col-md-6'>
                  <div className='inputFieldHead'>Zip Code*</div>
                  <Controller
                    name={`address[${index}].zip_code`}
                    control={control}
                    value={zipcode}
                    render={({ field }) => (
                      <input type='text'
                        {...field}
                        onKeyPress={(e) => {
                          keypressNumber(e);
                        }}
                        className='form-control'
                        placeholder='Type here'
                        maxLength={5}
                      // onChange={(e) => {
                      //   field.onChange(e);
                      //   onChangeZipCode(e, index);
                      // }}
                      />
                    )}
                    rules={{ required: 'This field is mandatory' }}
                  />
                  {errors.address?.[index]?.zip_code && (
                    <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                  )}
                </div>

                <div className='col-md-6'>
                  <div className='inputFieldHead'>Company Phone Number*</div>
                  {/* <div className='flex'><span className='countryCode'>303</span> */}
                  <Controller
                    name={`address[${index}].company_phone_no`}
                    control={control}
                    render={({ field }) => (
                      <input type='text'
                        {...field}
                        className='form-control'
                        name="companyPhoneNumber"
                        placeholder='Please type company phone number'
                        // value={companyPhone}
                        onChange={(e) => {
                          field.onChange(e);
                          handleChange(e, index);
                        }}
                      />
                    )}
                    rules={{ required: true, minLength: 14 }}
                  />
                  {/* <input type='text'
                      className='form-control'
                      name="companyPhoneNumber"
                      placeholder='Please type company phone number'
                      {...register(`address[${index}].company_phone_no`, {
                        required: true
                      })}
                      control={control}
                    /> */}
                  {/* </div> */}
                  {errors.address?.[index]?.company_phone_no.type === REQUIRED_VALIDATION && isSubmittedOnce && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                  {errors.address?.[index]?.company_phone_no && errors.address?.[index]?.company_phone_no.type === MINLENGTH_VALIDATION && (
                    <p className="error"> <img src={errorIcon} alt='errorIcon' /> Please Enter 10 digit Phone Number</p>
                  )}
                </div>
              </div>

            </div>
          </>
        ))}

        <div className='text-right'>
          <button type="button" className='btn-add-incident' onClick={appendAdress}>Add New Address</button>
        </div>

        <div className='incLabel mt-30'>Company Locations</div>
        <div className='container_div'>
          <div className='col-md-6'>
            <div className='inputFieldHead'>States*</div>
            <Controller
              name="company_locations"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="an-simple-select"
                  placeholder='Choose states in which company operates'
                  options={operatesDataList}
                  onChange={(selection) => onCompanyLocationChange(selection, field)}
                  value={selectedCompanyLocation}
                  isMulti
                />
              )}
              rules={{ required: 'This field is mandatory' }}
            />
            {errors.company_locations && (
              <p className="error"> <img src={errorIcon} /> {errors.company_locations.message} </p>
            )}
          </div>
          <div className="row mt-30">
            <div className="col-md-12">
              {selectedCompanyLocation.map((item, index) => (
                <span key={index} className="line-h">
                  <span className="feature-wrapper">{item.name}<img className="cross-btn-size" src={BtnCrossIcon} onClick={() => handleDelete(item.id)} /></span>
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className='text-right'>
          {/* <button type="button" className='btn-cancel border-0 mr-15' onClick={onCancel}>Cancel</button> */}
          <button type="button" className='btn-cancel border-0 mr-15' onClick={onCancel}>Cancel</button>
          <button type="submit" className='btn-proceed in-blck mt-30' onClick={() => captureClick()}>Proceed</button>
        </div>
      </form>

    </>
  )
}

export default CreateCompanyDetails