//onboarding
export const login = 'v1/auth/login/';
export const forgotPassword = 'v1/auth/forget-password/';
export const verifyOtp = 'v1/auth/verify-fp-otp/';
export const resetPassword = 'v1/auth/reset-password/';
export const changePassword = 'v1/auth/change-password/';
export const logOut = 'v1/auth/logout/';
//report event or incident
export const reportEvent = 'api/storedata';
//update event or incident
export const updateEvent = 'api/updatedata';
//soft delete event or incident
export const destroyEvent = 'api/destroydata';
//permanent delete event or incident
export const permanentDestroyEvent = 'api/permanentdelete';
//delete event or incident
export const deleteImage = 'api/deletefile';
//upload file
export const fileUpload = 'api/uploadimage';
export const createCompanyContact = 'v1/profile/company-contact/';
export const registerUser = 'v1/auth/register/';
//get or update admin profile
export const profileUrl = 'v1/auth/profile/';
//get dashboard count data
export const safetyDashboardCount = 'api/quickstats';
//get single bar chart data
export const singleBarChartData = 'api/incidenttypecount';
//get single bar chart data
export const horizontalTypeInjuryChartData = 'api/typeofinjury';
//import bulk upload for event and incidents
export const eventIncidentBulkUpload = 'api/data/import';
//get injury graph data
export const injuryGraphData = 'api/injurygraphdata';
//impacted body parts graph
export const impactedBodyParts = 'api/impactedbodypart';
//get permission roles
export const getRoles = 'v1/auth/roles';
//customize global graph filter to show hide graphs
export const customizeGraphToggle = 'v1/auth/customisation/';
//add user role
export const addUpdateRole = 'v1/auth/role';
//get master modules list
export const masterModulesList = 'v1/master/modules';
//get contractor's list
export const getContractorList = 'v1/profile/contractor';
//get contractor's list
export const getStateListing = 'v1/master/state';
//activate or deactivate a user
export const activateDeactivateUser = 'v1/auth/role';
//man Hours
export const manHours = 'v1/profile/manhours';
export const addUpdateManHours = 'v1/profile/manhours/';
export const manHoursUpload = 'v1/profile/manhours-upload/';
//get near miss observation graph data
export const nearMissChartData = 'api/nearmissobservation';
//get dashboard counts
export const dashboardCountUrl = 'api/quickstats';
//get facilities
export const getFacilities = 'v1/profile/facility';
//incident-event
export const safetyList = 'api/allsafetydata';
export const search = 'api/search';
export const filter = 'api/filter';
export const deleteEventListData = 'api/destroydata';
export const duplicateEventListData = 'api/duplicate';
export const getDataById = 'api/getdata';
export const exportToExcel = 'api/export/excel';
export const exportToDoc = 'api/export/csv';
//subscription-plans
export const subscription = 'v1/master/plans';
export const createPlan = 'v1/master/plan/';
//modules
export const modules = 'v1/master/modules/';
//user
export const user = 'v1/auth/users';
export const singleUser = 'v1/auth/user'
//role
export const getRoleList = 'v1/auth/roles/';
//city
export const getCityList = 'v1/master/city/';
export const deleteCity = 'v1/master/city';
export const getStateList = 'v1/master/state/';
export const actionState = 'v1/master/state';
export const companyList = 'v1/profile/company';
//city-state
export const addCityState = 'v1/master/city-state/';
export const cityState = 'v1/master/city-state';
//contractor
export const contractor = 'v1/profile/contractor';
export const contractorUpload = 'v1/profile/contractor-upload/';
//county
export const county = 'v1/master/county/';
//facility
export const facility = 'v1/profile/facility';
export const facilityUpload = 'v1/master/facility-upload/';
//agency
export const agency = 'v1/master/agency/';
//spill
export const spill = 'v2/spill/';
export const spillInvestigation = 'v2/spill/investigation/';
export const uploadImageSpill = 'v2/spill/uploadsd/';
export const spillsFilter = 'api/filter';
export const getSpillsList = 'v2/spill';
export const spillUpload = 'v2/spill/spill-uploader/';
//complaint
export const complaintBaseUrl = 'v2/complaint/';
export const getComplaintsList = 'v2/complaint';
export const addUpdateComplaintInvestigation = complaintBaseUrl + 'investigation/';
export const uploadComplaintDocs = complaintBaseUrl + 'upload/';
export const complaintDocUploader = complaintBaseUrl + 'complaint-uploader/';
export const complaintsDashboardCount = complaintBaseUrl + 'quickstats';
export const numberOfComplaintsGraphAPI = complaintBaseUrl + 'get-complaint-dashboard';

//complaints complany added data
export const addComplaintsAdditionalInfo = 'v2/complaint/additional-info/';
export const getEditComplaintsAdditionalInfo = 'v2/complaint/additional-information';

//get dashboard count data spills
export const spillsDashboardCount = 'v2/spill/quickstats';
export const spillsDashboardGraphData = 'v2/spill/get-spill-dashboard'
//get dashboard count data spills
export const spillsAdditionalInfo = 'v2/spill/additional-info/';
export const getSpillsAdditionalInfo = 'v2/spill/additional-information';