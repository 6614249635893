import React, { useState } from "react";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import { Navigate } from "react-router-dom";

const Layout = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('role');
    const isSidebarShow = localStorage.getItem('isSidebarShow');

    const handleSidebar = (value) => {
        setIsOpen(value);
    };
    
    if (token) {
        return (
                <div className="container-fluid no_pad">
                    <div className="row">
                        {isSidebarShow == 'true' ? <div className={`no_pad margin-0 fixed-height-of-container ${isOpen ? "col-md-2" : "col-md-1"}`}>
                            <Sidebar onSelectSidebarIcon={handleSidebar} />
                        </div> : <div className="contain-area"></div>}
                        <div className={`fixed-height-of-container ${isOpen ? 'col-md-10 no_pad' : 'col-md-11 no_pad '}`}>
                            <div>
                                <Header />
                            </div>
                            <div>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
        )
    } else {
        return (
            <Navigate replace to="/login" />
        );
    }
}

export default Layout;