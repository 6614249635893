import colorPalleteData from '../constants/themePallete';

const LeadingIndicatorOptions = (leftAxisRange, rightAxisRange) => {
    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })
                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Fire'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                title: {
                    display: true,
                    text: 'Number of Leading data indicators',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },

            },
            'right-side': {
                type: 'linear',
                position: 'right',
                suggestedMin: 0,
                suggestedMax: rightAxisRange,
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Cumulative Trend Line',
                    font: {
                        family: 'Montserrat',
                    }
                },

            }
        },
        aspectRatio: 3,
    }
}

// recordable injury chart metadata

const RecordableInjuryOptions = (leftAxisRange, rightAxisRange) => {
    return {
        responsive: true,
        plugins: {

            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Recordable Injuries'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                beginAtZero: true,
                max: leftAxisRange,
                title: {
                    display: true,
                    text: 'Number of Recordable Injuries',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'TRIR',
                    font: {
                        family: 'Montserrat',
                    }
                }
            }
        },

        aspectRatio: 3,
    }
}

// lost time injury chart metadata

const LostTimeInjuryOptions = (leftAxisRange, rightAxisRange) => {
    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Lost Time Injuries'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Lost Time Injury Incidents',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'LTIR',
                    font: {
                        family: 'Montserrat',
                    }
                }
            }
        },
        aspectRatio: 3,
    }
}

// first time injury chart metadata
const FirstTimeInjuryOptions = (leftAxisRange, rightAxisRange) => {

    const stepsDistance = leftAxisRange/5;

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'First Time Injuries'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                title: {
                    display: true,
                    text: 'Number of First Aid Injury',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                // ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Cumulative Trend Line',
                    font: {
                        family: 'Montserrat',
                    }
                }
            }
        },
        aspectRatio: 3,
    }
}

const FatalitiesOptions = (leftAxisRange, rightAxisRange) => {

    const stepsDistance = leftAxisRange/5;

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Fatalities'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                title: {
                    display: true,
                    text: 'Number of Fatalities',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                // ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: '',
                    font: {
                        family: 'Montserrat',
                    }
                },
            }
        },
        aspectRatio: 3,
    }
}

// fire chart metadata
const FireOptions = (leftAxisRange, rightAxisRange) => {

    const stepsDistance = leftAxisRange/5;

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Fire'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                title: {
                    display: true,
                    text: 'Number of Fire',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                // ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Cumulative Trend Line',
                    font: {
                        family: 'Montserrat',
                    }
                },
            }
        },
        aspectRatio: 3,
    }
}


const LineChart = {
    labels: ['2020', '2021', '2022'],
    datasets: [
        // {
        //     type: 'bar',
        //     label: 'Fires',
        //     data: [4, 1, 8],
        //     backgroundColor: [
        //         colorPalleteData.graphPurpleShade
        //     ],
        //     borderColor: [
        //         colorPalleteData.graphPurpleShade
        //     ],
        //     borderWidth: 1,
        //     borderRadius: 10,
        //     barThickness: 30,
        //     barPercentage: 0.5,
        //     yAxisID: 'left-side'
        // },
        {
            type: 'line',
            label: 'Fire',
            data: [4, 5, 13],
            backgroundColor: [
                colorPalleteData.graphGreenLine
            ],
            borderColor: [
                colorPalleteData.graphGreenLine
            ],
            borderWidth: 1,
            borderRadius: 10,
            barThickness: 30,
            barPercentage: 0.5,
            yAxisID: 'right-side'
        },
        {
            type: 'line',
            label: 'Fire',
            data: [6, 7, 16],
            backgroundColor: [
                colorPalleteData.graphPurpleLine
            ],
            borderColor: [
                colorPalleteData.graphPurpleLine
            ],
            borderWidth: 1,
            borderRadius: 10,
            barThickness: 30,
            barPercentage: 0.5,
            yAxisID: 'right-side'
        },
        {
            type: 'line',
            label: 'Fire',
            data: [8, 8, 17],
            backgroundColor: [
                colorPalleteData.graphYellowLined
            ],
            borderColor: [
                colorPalleteData.graphYellowLine
            ],
            borderWidth: 1,
            borderRadius: 10,
            barThickness: 30,
            barPercentage: 0.5,
            yAxisID: 'right-side'
        },
    ],
};

const LineOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Montserrat',
                },
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        if (dataset.type === 'line') {
                            pointStyle.push('line');
                        }
                        else {
                            pointStyle.push('rect')
                        }
                    })

                    return chart.data.datasets.map((dataset, index) => ({
                        text: dataset.label,
                        fillStyle: dataset.backgroundColor,
                        strokeStyle: dataset.borderColor,
                        pointStyle: pointStyle[index],
                        hidden: visibility[index]
                    })
                    )
                }
            }
        },
        title: {
            display: false,
            text: 'Fire'
        }
    },
    scales: {
        x: {
            grid: {
                color: colorPalleteData.graphLineColor,
            },
            border: {
                dash: [2, 4],
            },
            ticks: { display: true },
        },
        'left-side': {
            type: 'linear',
            position: 'left',
            min: 0,
            max: 20,
            title: {
                display: true,
                text: 'Number of Fire',
                font: {
                    family: 'Montserrat',
                }
            },
            grid: {
                color: colorPalleteData.graphLineColor,
            },
            border: {
                dash: [2, 4],
            },
            ticks: { display: true },
        },
        'right-side': {
            type: 'linear',
            position: 'right',
            min: 0,
            max: 20,
            beginAtZero: true,
            grid: {
                display: false
            },
            title: {
                display: true,
                text: 'Cumulative Trend Line',
                font: {
                    family: 'Montserrat',
                }
            },
        }
    },
    aspectRatio: 3,
}

// vehicle accidents chart metadata
const VehicleDamageOptions = (leftAxisRange, rightAxisRange) => {

    const stepsDistance = leftAxisRange/5;

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Number of Vehicle Accident'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                title: {
                    display: true,
                    text: 'Number of Vehicle Accident',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                // ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Cumulative Trend Line',
                    font: {
                        family: 'Montserrat',
                    }
                }
            }
        },
        aspectRatio: 3,
    }
}

// property damage chart metadata

const PropertyDamageOptions = (leftAxisRange, rightAxisRange) => {

    const stepsDistance = leftAxisRange/5;

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Number of Property Damange Incident'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: { display: true },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                title: {
                    display: true,
                    text: 'Number of Property Damage Incident',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                // ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Cumulative Trend Line',
                    font: {
                        family: 'Montserrat',
                    }
                }
            }
        },
        aspectRatio: 3,
    }
}

// vandalism chart metadata
const VandalismOptions = (leftAxisRange, rightAxisRange) => {

    const stepsDistance = leftAxisRange/5;

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                onClick: (click, legendItem, legend) => {
                    const datasets = legend.legendItems.map((dataset, index) => {
                        return dataset.text;
                    })

                    const index = datasets.indexOf(legendItem.text);
                    if (legend.chart.isDatasetVisible(index) === true) {
                        legend.chart.hide(index);
                    } else {
                        legend.chart.show(index);
                    }
                },
                labels: {
                    usePointStyle: true,
                    font: {
                        family: 'Montserrat',
                    },
                    generateLabels: (chart) => {
                        let visibility = [];
                        for (let i = 0; i < chart.data.datasets.length; i++) {
                            if (chart.isDatasetVisible(i) === true) {
                                visibility.push(false);
                            }
                            else {
                                visibility.push(true);
                            }
                        }
                        let pointStyle = [];
                        chart.data.datasets.forEach(dataset => {
                            if (dataset.type === 'line') {
                                pointStyle.push('line');
                            }
                            else {
                                pointStyle.push('rect')
                            }
                        })

                        return chart.data.datasets.map((dataset, index) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            strokeStyle: dataset.borderColor,
                            pointStyle: pointStyle[index],
                            hidden: visibility[index]
                        })
                        )
                    }
                }
            },
            title: {
                display: false,
                text: 'Vandalism/Theft'
            }
        },
        scales: {
            x: {
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                ticks: {
                    display: true
                },
            },
            'left-side': {
                type: 'linear',
                position: 'left',
                min: 0,
                max: leftAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                title: {
                    display: true,
                    text: 'Number of Vandalism/Theft',
                    font: {
                        family: 'Montserrat',
                    }
                },
                grid: {
                    color: colorPalleteData.graphLineColor,
                },
                border: {
                    dash: [2, 4],
                },
                // ticks: { display: true },
            },
            'right-side': {
                type: 'linear',
                position: 'right',
                min: 0,
                max: rightAxisRange,
                ticks: {
                    stepSize: stepsDistance,
                },
                beginAtZero: true,
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Cumulative Trend Line',
                    font: {
                        family: 'Montserrat',
                    }
                },
                ticks: {
                    display: true
                }
            }
        },
        aspectRatio: 3,
    }
}

// horizontal fire chart metadata
const HorizontalRecordableOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Montserrat',
                },
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(false);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        if (dataset.type === 'line') {
                            pointStyle.push('line');
                        }
                        else {
                            pointStyle.push('rect')
                        }
                    })

                    // return chart.data.datasets.map((dataset, index) => 

                    // ({
                    //     text: dataset.label,
                    //     fillStyle: dataset.backgroundColor,
                    //     strokeStyle: dataset.borderColor,
                    //     pointStyle: pointStyle[index],
                    //     hidden: visibility[index]
                    // })

                    // )
                }
            }
        },
        title: {
            display: true,
            text: 'Number of Recordable Injury Type (2023)',
            position: 'bottom',
            font: {
                family: 'Montserrat',
            }
        },
    },
    scales: {
        x: {
            grid: {
                color: colorPalleteData.graphLineColor,
            },
            border: {
                dash: [2, 4],
            },
            ticks: { display: true },
        },
        y: {
            grid: {
                color: colorPalleteData.graphLineColor,
            },
            border: {
                dash: [2, 4],
            },
            ticks: { display: true },
        }
    },
    // aspectRatio: 3,
};

const HorizontalFirstAidOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onClick: (click, legendItem, legend) => {
                const datasets = legend.legendItems.map((dataset, index) => {
                    return dataset.text;
                })

                const index = datasets.indexOf(legendItem.text);
                if (legend.chart.isDatasetVisible(index) === true) {
                    legend.chart.hide(index);
                } else {
                    legend.chart.show(index);
                }
            },
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Montserrat',
                },
                generateLabels: (chart) => {
                    let visibility = [];
                    for (let i = 0; i < chart.data.datasets.length; i++) {
                        if (chart.isDatasetVisible(i) === true) {
                            visibility.push(false);
                        }
                        else {
                            visibility.push(true);
                        }
                    }
                    let pointStyle = [];
                    chart.data.datasets.forEach(dataset => {
                        if (dataset.type === 'line') {
                            pointStyle.push('line');
                        }
                        else {
                            pointStyle.push('rect')
                        }
                    })
                }
            }
        },
        title: {
            display: true,
            text: 'Number of First Aid Injury (2023)',
            position: 'bottom',
            font: {
                family: 'Montserrat',
            }
        }
    },
    scales: {
        x: {
            grid: {
                color: colorPalleteData.graphLineColor,
            },
            border: {
                dash: [2, 4],
            },
            ticks: { display: true },
        },
        y: {
            grid: {
                color: colorPalleteData.graphLineColor,
            },
            border: {
                dash: [2, 4],
            },
            ticks: { display: true },
        }
    },
    // aspectRatio: 3,
}

// pie chart metadata
const PieChartOptions = {
    scales: {
    },
    plugins: {
        datalabels: {
            color: 'white'
        },
        legend: {
            display: false,
            position: 'bottom',
            labels: {
                color: 'white'
            }
        },
        title: {
            font: {
                family: 'Montserrat',
            }
        }
    }
};

// pie chart first aid metadata
const PieChartFirstAidOptions = {
    scales: {
    },
    plugins: {
        datalabels: {
            color: 'white'
        },
        legend: {
            display: false,
            position: 'bottom',
            labels: {
                color: 'white'
            }
        },
        title: {
            font: {
                family: 'Montserrat',
            }
        }
    }
};


export default {
    LeadingIndicatorOptions, RecordableInjuryOptions, LostTimeInjuryOptions, FirstTimeInjuryOptions,
    FatalitiesOptions, FireOptions, VehicleDamageOptions, PropertyDamageOptions,
    VandalismOptions, HorizontalFirstAidOptions, PieChartOptions, PieChartFirstAidOptions,
    LineChart, LineOptions, HorizontalRecordableOptions,
}