import React, { useState } from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import Select from 'react-select';
import RadioSelect from '../../../components/formBuilder/RadioSelect';
import CheckboxSelect from '../../../components/formBuilder/CheckboxSelect';
import data from '../../../mockData/mockData';

function EventLabelDetails() {
    const [radioInput, setRadioInput] = useState([
        { label: 'Employee', name: 'impacted_ques', value: 'Yes', id: 'yes' },
        { label: 'Contractor', name: 'impacted_ques', value: 'No', id: 'no' },
    ]);
    const [editLabel, setEditLabel] = useState(true);
    const [impactedValue, setImpactedValue] = useState(0);
    const [otherLocationValue, setOtherLocationValue] = useState("");
    const [injuryType, setInjuryType] = useState("");
    const [editArray, setEditArray] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);
    const [message, setMessage] = useState('Initial value');

    const { register, control } = useForm({
        defaultValues: {
            test: [{ firstName: "Bill", lastName: "Luo" }]
        }
    });
    const {
        append,
    } = useFieldArray({
        control,
        name: "test"
    });

    const onRadioChange = (ev) => {
        setImpactedValue(ev.target.value);
    };

    const onClickSetEditLabel = (value) => {
        const clone = [...editArray];
        clone[value] = true;
        setEditArray(clone);
    }

    const onChange = () => {
        // to be used in the future for the API Integration
    }

    return (
        <div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-6'>
                        {!editArray[0] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(0) }}>Date of Occurrence*</div>}
                        {editArray[0] && <input type='text' className='form-control' placeholder='Date of Occurrence*' defaultValue='Date of Occurrence' />}
                    
                    </div>
                    <div className='col-md-6'>
                        {!editArray[1] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(1) }}>Time*</div>}
                        {editArray[1] && <input type='text' className='form-control' placeholder='Time*' defaultValue='Time' />}
                        <div className='row mt-16'>
                            <div className='col-md-4'>
                                <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Date' options={data.Dates} />
                            </div>
                            <div className='col-md-4'>
                                <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Month' options={data.Months} />
                            </div>
                            <div className='col-md-4'>
                                <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Meridian' options={data.Meridian} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[2] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(2) }}>Name of the Event*</div>}
                        {editArray[2] && <input type='text' className='form-control' placeholder='Name of the event*' defaultValue='Name of the event' />}
                        <input type='text' disabled={editLabel} className='form-control mt-2' placeholder='Type here For eg Refinery Fire' />
                    </div>
                    <div className='col-md-6'>
                        {!editArray[3] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(3) }}>Location of Occurrence*</div>}
                        {editArray[3] && <input type='text' className='form-control' placeholder='Location of Occurrence*' defaultValue='Location of Occurrence' />}
                        <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the location where the event occured' options={data.Locations} onChange={(location) => setOtherLocationValue(location)} />
                    </div>
                </div>

                <div className='row mt-30'>
                    <div className='col-md-6'>
                        {!editArray[4] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(4) }}>Please type in the Location*</div>}
                        {editArray[4] && <input type='text' className='form-control' placeholder='Please type in the Location*' defaultValue='Please type in the Location' />}
                        <input type='text' disabled={editLabel} className='form-control mt-2' placeholder='Type here For eg Refinery Fire' />
                    </div>

                </div>

            </div>

            <div className='incidentCont mt-30 '>
                <div className='incLabel'>Incident</div>
                <div className='container_div'>
                    <div className='row'>
                        <div className='col-md-6'>
                            {!editArray[5] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(5) }}>Date of Occurrence*</div>}
                            {editArray[5] && <input type='text' className='form-control' placeholder='Date of Occurrence*' defaultValue='Date of Occurrence' />}
                            
                        </div>
                        <div className='col-md-6'>
                            {!editArray[6] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(6) }}>Time*</div>}
                            {editArray[6] && <input type='text' className='form-control' placeholder='Time*' defaultValue='Time' />}
                            <div className='row mt-16'>
                                <div className='col-md-4'>
                                    <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Date' options={data.Dates} />
                                </div>
                                <div className='col-md-4'>
                                    <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Month' options={data.Months} />
                                </div>
                                <div className='col-md-4'>
                                    <Select isDisabled={editLabel} classNamePrefix="an-simple-select" placeholder='Meridian' options={data.Meridian} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            {!editArray[7] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(7) }}>Type of Person Impacted*</div>}
                            {editArray[7] && <input type='text' className='form-control' placeholder='Types of Person Impacted*' defaultValue='Types of Person Impacted' />}
                            <div className='rdImpactdGroup mt-16'>
                                <RadioSelect data={radioInput}
                                    editLabel={editLabel}
                                    onChange={onRadioChange}
                                    colSize={3}
                                />
                            </div>
                        </div>

                        <div className='col-md-6'>
                            {!editArray[8] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(8) }}>Name of the Event*</div>}
                            {editArray[8] && <input type='text' className='form-control' placeholder='Name of the Event*' defaultValue='Name of the Event' />}
                            <input type='text' disabled={editLabel} className='form-control mt-2' placeholder='Type here For eg Refinery Fire' />
                        </div>
                    </div>

                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            {!editArray[9] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(9) }}>Incident Type*</div>}
                            {editArray[9] && <input type='text' className='form-control' placeholder='Incident Type*' defaultValue='Incident Type' />}
                            <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the incident type' options={data.IncidentType} onChange={(location) => setOtherLocationValue(location)} />
                        </div>
                        <div className='col-md-6'>
                            {!editArray[10] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(10) }}>Type of injury*</div>}
                            {editArray[10] && <input type='text' className='form-control' placeholder='Type of injury*' defaultValue='Type of injury' />}
                            <Select isDisabled={editLabel} className='mt-2' classNamePrefix="an-simple-select" placeholder='Choose the injury type' options={data.InjuryTypes} onChange={(location) => setOtherLocationValue(location)} />
                        </div>
                    </div>
                    <div className='row mt-30'>
                        <div className='col-md-6'>
                            {!editArray[11] && <div className='inputFieldHead' onDoubleClick={() => { onClickSetEditLabel(11) }}>Affected Body Part*</div>}
                            {editArray[11] && <input type='text' className='form-control' placeholder='Affected Body Part*' defaultValue='Affected Body Part' />}
                            <div className='mt-2'>
                                <CheckboxSelect 
                                data={data.BodyParts} 
                                editLabel={editLabel}
                                colSize={3}
                                onChange={onChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default EventLabelDetails
