import react from 'react';
import { Form } from 'react-bootstrap';

const CheckboxSingleSelect = ({checked, label, name, index, item, onChange, disabled}) => {
    return (
        <Form>
        <Form.Group controlId="checkbox">
            <Form.Check
                className="custom-checkbox"
                inline
                key={index}
                label={label}
                index={index}
                name={name}
                checked={checked}
                type={'checkbox'}
                id={`inline-${'checkbox'}-1`}
                disabled={disabled}
                onChange={(e) => onChange(e, index, item)}
            />
        </Form.Group>
    </Form>
    )
}

export default CheckboxSingleSelect;