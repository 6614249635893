import React, { useState, useEffect } from "react";
import './LiticoAdminProfile.css';
import { Controller, useForm } from "react-hook-form";
import { Put, Get } from "../../../constants/apiMethods";
import { profileUrl } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import errorIcon from "../../../assets/images/error_icon.svg";
import { Modal } from 'react-bootstrap';
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import { useNavigate } from "react-router";

const LiticoAdminProfile = () => {

    const [showHideChangePassword, setshowHideChangePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [successDetail, setSuccessDetail] = useState('');
    const [viewData, setViewData] = useState({});
    const [companyPhone, setCompanyPhone] = useState('');
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
    const token = localStorage.getItem('access_token');
    const navigate = useNavigate();

    useEffect(() => {
        getAdminProfile();
    }, []);

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues,
        trigger
    } = useForm();

    const setAdminProfile = (data) => {
        setValue('name', data.name);
        setValue('contact', data.contact);
        setValue('username', data.username);
    }

    const proceedToProfile = () => {
        let data = {
            name: getValues('name'),
            contact: getValues('contact'),
            username: getValues('username'),
        }
        setLoading(true);
        Put(profileUrl, token, data, false)
            .then((response) => response)
            .then((data) => {
                setshowHideChangePassword(true);
                if (data.success) {
                    setLoading(false);
                    setAlertType('success');
                    setSuccessDetail(data.detail)
                    setshowHideChangePassword(false);
                    setSuccessRes(true);
                    localStorage.setItem('name', getValues('name'));
                    // navigate('../subscriptions');
                    // window.history.back();
                    window.location.reload();
                }
            });
    }

    const onSubmit = () => {
        setshowHideChangePassword(true);
    };

    const validateContactNumber = (value) => {
        const isValid = /^\d{10}$/.test(value);
        return isValid;
    };

    const getAdminProfile = () => {
        setLoading(true);
        Get(profileUrl, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setViewData(data.data);
                    setAdminProfile(data.data);
                    setLoading(false);
                }
            });
    };

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const handleChange = ({ target: { value } }) => {
        setCompanyPhone(prevState => (normalizeInput(value, prevState.phone)));
        const phoneNo = normalizeInput(value);
        setValue(`contact`, phoneNo);
        trigger(`contact`);
    }

    const navigateToPlan = () => {
        // navigate('../subscriptions');
        window.history.back();
    }

    const captureClick = () => {
        setIsSubmittedOnce(true);
    }

    return (
        <div className="full-cont">
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertText={successDetail} alertType={alertType} setVisibility={setVisibility} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="border_head_1">
                    <div className="head_2">{'My Profile'}</div>
                </div>
                <div className='row mt-30 light-container'>
                    <div className='col-md-6'>
                        <div className='inputFieldHead'>Name</div>
                        <input type='text' id="name" className='form-control' name="name" placeholder='Type here'
                            {...register("name", { required: true })}
                        />
                        {errors.name?.type == "required" && (
                            <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                        )}
                    </div>
                    <div className='col-md-6'>
                        <div className='inputFieldHead'>Phone Number</div>
                        <div className="row">
                            <div className='col-md-12'>
                                {/* <div className='flex'> */}
                                {/* <span className='countryCode'>303</span> */}
                                {/* <input type='text' className='form-control' name="contact" id="contact" placeholder='Please type phone number' onchange={(e) => { handleChange(e) }}
                                    {...register("contact", { required: true })}
                                /> */}
                                <Controller
                                    name='contact'
                                    control={control}
                                    render={({ field }) => (
                                        <input type='text'
                                            {...field}
                                            className='form-control'
                                            placeholder='Please type phone number'
                                            // value={companyPhone}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleChange(e);
                                            }}
                                        />
                                    )}
                                    rules={{ required: true, minLength: 14 }}
                                />
                                {/* </div> */}

                            </div>
                            {errors.contact?.type == "required" && isSubmittedOnce && (
                                <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                            )}
                            {errors.contact?.type && errors.contact?.type == 'minLength' && (
                                <p className="error"> <img src={errorIcon} /> Please enter a valid 10-digit phone number </p>
                            )}
                        </div>

                    </div>
                </div>

                <div className='row mt-30 light-container'>
                    <div className='col-md-6'>
                        <div className='inputFieldHead'>Email ID</div>
                        <input type='email' className='form-control' disabled={true} name="username" id="username" placeholder='abc@gmail.com'
                            {...register("username")}
                        />
                        {errors.username?.type == "required" && (
                            <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                        )}
                    </div>
                </div>

                <div className='text-right'>
                    <button className='btn-cancel border-0 mr-15' onClick={navigateToPlan}>Cancel</button>
                    <button type="submit" className='btn-event-incident in-blck mt-30' onClick={() => captureClick()}> {'Update'}</button>
                </div>

                <Modal show={showHideChangePassword} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangePassword(false)}>
                    <Modal.Header className="sideOnRight" closeButton>
                    </Modal.Header>
                    <Modal.Body className="auth_modal_header border-0 mx-auto">
                        <div className="modal-body reportText">
                            Are you sure you want to update your profile?
                        </div>
                        <Modal.Footer>
                            <button type="button" className="btn-no" data-bs-dismiss="modal" onClick={() => { setshowHideChangePassword(false) }}>No</button>
                            <button type="button" className="btn-yes" onClick={() => { proceedToProfile() }}>Yes</button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </form>
        </div>

    )
}

export default LiticoAdminProfile;