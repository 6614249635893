const baseURL = process.env.REACT_APP_BASE_URL;

export const Get = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        data ? `${baseURL}${Url}/${data}` : `${baseURL}${Url}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
        }
      ).then((res) => res.json());
      if (response) {
        resolve(response);
        if(response.detail == 'Could not Validate Credentials') {
          window.location.href = "/login";
        }
        if(response.code == '400') {
          window.location.href = "/login";
        }
      } else {
        reject(response);
        if (response.statusCode == 401) {
          window.location.href = "/#/login";
        }
      }
    } catch (error) {
    }
  });
};

export const Post = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}`, {
        body: data ? JSON.stringify(data) : "",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "POST",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
        if(response.detail == 'Could not Validate Credentials') {
          window.location.href = "/login";
        }
        if((response.code == '400' || response.code == '401')) {
          window.location.href = "/login";
        }
      } else {
        reject(response);
      }
    } catch (error) {
    }
  });
};

export const ImagePost = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}`, {
        body: data ? data : "",
        headers: {
        "Accept": 'application/json',
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "POST",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      console.log('error from api method', error);
    }
  });
};

export const Put = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}`, 
      {
        body: data ? JSON.stringify(data) : "",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "PUT",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
    }
  });
};

export const ImagePut = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}`, {
        body: data ? data : "",
        headers: {
        "Accept": 'application/json',
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "PUT",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
      console.log('error from api method', error);
    }
  });
};

export const Delete = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}`, {
        body: data ? JSON.stringify(data) : "",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "DELETE",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
    }
  });
};

export const Delete2 = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}/${data}/`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "DELETE",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
    }
  });
};

export const Delete3 = (Url, token, data, baseUrlFlag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${baseURL}${Url}/${data}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        method: "DELETE",
      }).then((res) => res.json());
      if (response) {
        resolve(response);
      } else {
        reject(response);
      }
    } catch (error) {
    }
  });
};
