import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Get } from "../../../../constants/apiMethods";
import { getSpillsList } from "../../../../constants/apiRoutes";
import DownloadCompleteImg from '../../../../assets/images/downloadComplete.svg';
import { saveAs } from 'file-saver';
import "./ViewSpill.css";
import LoadingSpinner from "../../../../components/Loader";
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import UploadedFileContainer from "../../../../components/FileUpload/UploadedFileContainer";
import ImagePreviewPopUp from "../../../../components/ImagePreviewPopUp";
import { ACCESS_TOKEN, FAILED_STATUS, IMAGE_JPG_FORMAT, NA_STATUS, NULL_VALUE } from '../../../../constants/Global';

const ViewSpill = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [viewData, setViewData] = useState([]);
    const token = localStorage.getItem(ACCESS_TOKEN);
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [imageObj, setImageObj] = useState();
    const [docFormatType, setDocFormatType] = useState('');
    const [images, setImages] = useState([]);

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    const handleClick = () => {
        navigate('/update-spill', { state: { id: viewData?.id, view: 'fromView' } });
    }

    useEffect(() => {
        getDetailsById();
    }, []);

    const getDetailsById = () => {
        let body = {
            id: location.state.id
        }
        setLoading(true);
        Get(getSpillsList, token, `?id=${body.id}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setViewData(data.data);
                    const updatedDocuments = data.data?.supporting_documents && data.data?.supporting_documents.length > 0 && data.data?.supporting_documents.map(doc => {
                        const extension = doc.usd_name.split('.').pop();
                        return { ...doc, extension };
                    });
                    setImages(updatedDocuments ? updatedDocuments : []);
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType(FAILED_STATUS);
                    setSuccessRes(true);
                }
            });
    };

    const showPreview = (item) => {
        setImageObj(item);
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        setImageName(item?.usd_name);
        setImageToPreview(`${imgUrl}${item?.usd_path}`)
    }

    const downloadImg = (imgUrl) => {
        saveAs(`${process.env.REACT_APP_IMAGE_URL + imgUrl?.usd_path}`);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <div className="full-cont">
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={3000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="row">
                <div className="col-md-7">
                    <div><span className="head_1 cursor" onClick={() => { navigate('/spills-dashboard') }}>Spills Dashboard</span> / <span className="head_1 cursor" onClick={() => { navigate('/spills-management') }}>Spill Management</span> / <span className="head_3">View Spill Details</span></div>
                    <div className="head_2">{viewData?.name ? viewData?.name : 'Spill detail'}</div>
                </div>
                <div className="col-md-5 text-right">
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>
            </div>
            <div>
            </div>
            <hr className="defaultLine" />

            <div className='containerHeading'>System Detail</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Added By</div>
                        <p className="text-content">{viewData?.added_by ? viewData?.added_by : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Added On</div>
                        <p className="text-content">{viewData?.created_on ? viewData?.created_on : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Last Updated By</div>
                        <p className="text-content">{viewData?.updated_by ? viewData?.updated_by : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Last Updated On</div>
                        <p className="text-content">{viewData?.updated_on ? viewData?.updated_on : NA_STATUS}</p>
                    </div>
                </div>
            </div>

            <div className='containerHeading'>Spill Basic Details</div>

            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Spill Name</div>
                        <p className="text-content">{viewData?.name ? viewData?.name : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Spill Status</div>
                        <p className="text-content">{viewData?.spill_status ? viewData?.spill_status : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Date of Closure</div>
                        <p className="text-content">{viewData?.date_of_closure ? viewData?.date_of_closure : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Spill Category</div>
                        <p className="text-content">{viewData?.spill_category ? viewData?.spill_category : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Reportable (Agency)</div>
                        <p className="text-content">
                            {viewData?.agency ?
                                viewData?.agency.map((item, i) => (
                                    <React.Fragment key={i}>
                                        <span className="">{item}</span>
                                        {i < viewData.agency.length - 1 && <span>, </span>}
                                    </React.Fragment>
                                )) : NA_STATUS}
                        </p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>State</div>
                        <p className="text-content">{viewData?.state ? viewData?.state : NA_STATUS}</p>
                    </div>

                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Facility Name</div>
                        <p className="text-content">{!viewData?.location ? viewData?.facility_name : <span>Others ({viewData?.location})</span>}</p>
                    </div>
                </div>

            </div>

            <div className='container_div'>
                <div className='row mb-1'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Discovery Date</div>
                        <p className="text-content">{viewData?.date_of_discovery ? viewData?.date_of_discovery : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Time</div>
                        <p className="text-content">{viewData?.time ? viewData?.time : NA_STATUS}</p>
                    </div>

                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Spill Type</div>
                        <p className="text-content">{viewData?.spill_type ? viewData?.spill_type : NA_STATUS}</p>
                    </div>

                    <div className='col-md-5'>
                        <div className='inputFieldHead'>Fluid Type </div>
                        <p className="text-content">
                            {viewData?.fluid_type ?
                                viewData?.fluid_type.map((item, i) => (
                                    <React.Fragment key={i}>
                                        <span className="">{item}</span>
                                        {i < viewData.fluid_type.length - 1 && <span>, </span>}
                                    </React.Fragment>
                                )) : NA_STATUS}
                        </p>
                    </div>
                </div>

                {(viewData?.oil_spill_volume_released !== undefined || viewData?.oil_spill_volume_recovered !== undefined || viewData?.produced_water_volume_released !== undefined) &&
                    <div className='row mb-1'>
                        {viewData?.oil_spill_volume_released !== undefined && viewData?.oil_spill_volume_released !== null && viewData?.oil_spill_volume_released !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Oil Volume Released (bbl)</div>
                                <p className="text-content">{viewData?.oil_spill_volume_released !== null ? viewData?.oil_spill_volume_released : NA_STATUS}</p>
                            </div>
                        }
                        {viewData?.oil_spill_volume_recovered !== undefined && viewData?.oil_spill_volume_recovered !== null && viewData?.oil_spill_volume_recovered !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Oil Volume Recovered (bbl)</div>
                                <p className="text-content">{viewData?.oil_spill_volume_recovered !== null ? viewData?.oil_spill_volume_recovered : NA_STATUS}</p>
                            </div>
                        }
                        {viewData?.produced_water_volume_released !== undefined && viewData?.produced_water_volume_released !== null && viewData?.produced_water_volume_released !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Produced Water Volume Released (bbl)</div>
                                <p className="text-content">{viewData?.produced_water_volume_released !== null ? viewData?.produced_water_volume_released : NA_STATUS}</p>
                            </div>
                        }
                    </div>
                }

                {(viewData?.produced_water_volume_recovered !== undefined || viewData?.drilling_fluid_volume_released !== undefined || viewData?.drilling_fluid_volume_recovered !== undefined) && <div className='row mb-1'>
                    {viewData?.produced_water_volume_recovered !== undefined && viewData?.produced_water_volume_recovered !== null && viewData?.produced_water_volume_recovered !== 0 
                    && <div className='col-md-4'>
                        <div className='inputFieldHead'>Produced Water Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.produced_water_volume_recovered ? viewData?.produced_water_volume_recovered : NA_STATUS}</p>
                    </div>}

                    {viewData?.drilling_fluid_volume_released !== undefined && viewData?.drilling_fluid_volume_released !== null && viewData?.drilling_fluid_volume_released !== 0 
                    &&
                        <div className='col-md-4'>
                            <div className='inputFieldHead'>Drilling Fluid Volume Released (bbl)</div>
                            <p className="text-content">{viewData?.drilling_fluid_volume_released !== null ? viewData?.drilling_fluid_volume_released : NA_STATUS}</p>
                        </div>
                    }

                    {viewData?.drilling_fluid_volume_recovered !== undefined && viewData?.drilling_fluid_volume_recovered !== null && viewData?.drilling_fluid_volume_recovered !== 0 
                    && <div className='col-md-4'>
                        <div className='inputFieldHead'>Drilling Fluid Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.drilling_fluid_volume_recovered ? viewData?.drilling_fluid_volume_recovered : NA_STATUS}</p>
                    </div>}
                </div>}

                {(viewData?.condensate_spill_volume_released !== undefined || viewData?.condensate_spill_volume_recovered !== undefined || viewData?.ep_waste_spill_volume_released !== undefined) &&
                    <div className='row mb-1'>
                        {viewData?.condensate_spill_volume_released !== undefined && viewData?.condensate_spill_volume_released !== null && viewData?.condensate_spill_volume_released !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Condensate Volume Released (bbl)</div>
                                <p className="text-content">{viewData?.condensate_spill_volume_released !== null ? viewData?.condensate_spill_volume_released : NA_STATUS}</p>
                            </div>
                        }

                        {viewData?.condensate_spill_volume_recovered !== undefined && viewData?.condensate_spill_volume_recovered !== null && viewData?.condensate_spill_volume_recovered !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Condensate Volume Recovered (bbl)</div>
                                <p className="text-content">{viewData?.condensate_spill_volume_recovered !== null ? viewData?.condensate_spill_volume_recovered : NA_STATUS}</p>
                            </div>
                        }

                        {viewData?.ep_waste_spill_volume_released !== undefined && viewData?.ep_waste_spill_volume_released !== null && viewData?.ep_waste_spill_volume_released !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Other E&P Waste Volume Released (bbl)</div>
                                <p className="text-content">{viewData?.ep_waste_spill_volume_released !== null ? viewData?.ep_waste_spill_volume_released : NA_STATUS}</p>
                            </div>
                        }
                    </div>
                }

                {(viewData?.ep_waste_spill_volume_recovered !== undefined || viewData?.flow_back_fluid_spill_volume_released !== undefined || viewData?.flow_back_fluid_spill_volume_recovered !== undefined)
                    &&
                    <div className='row mb-1'>
                        {viewData?.ep_waste_spill_volume_recovered !== undefined && viewData?.ep_waste_spill_volume_recovered !== null && viewData?.ep_waste_spill_volume_recovered !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Other E&P Waste Volume Recovered (bbl)</div>
                                <p className="text-content">{viewData?.ep_waste_spill_volume_recovered !== null ? viewData?.ep_waste_spill_volume_recovered : NA_STATUS}</p>
                            </div>
                        }
                        {viewData?.flow_back_fluid_spill_volume_released !== undefined && viewData?.flow_back_fluid_spill_volume_released !== null && viewData?.flow_back_fluid_spill_volume_released !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Flow Back Fluid Volume Released (bbl)</div>
                                <p className="text-content">{viewData?.flow_back_fluid_spill_volume_released !== null ? viewData?.flow_back_fluid_spill_volume_released : NA_STATUS}</p>
                            </div>
                        }
                        {viewData?.flow_back_fluid_spill_volume_recovered !== undefined && viewData?.flow_back_fluid_spill_volume_recovered !== null && viewData?.flow_back_fluid_spill_volume_recovered !== 0 &&
                            <div className='col-md-4'>
                                <div className='inputFieldHead'>Flow Back Fluid Volume Recovered (bbl)</div>
                                <p className="text-content">{viewData?.flow_back_fluid_spill_volume_recovered !== null ? viewData?.flow_back_fluid_spill_volume_recovered : NA_STATUS}</p>
                            </div>
                        }
                    </div>
                }
            </div>


            <div className='containerHeading'>Investigation Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead'>Spill Description</div>
                        <p className="text-content">{viewData?.spill_description ? viewData?.spill_description : NA_STATUS}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead mb-3 mt-3'>Investigation Conclusion</div>
                        <div className="mb-3">
                            {viewData?.investigation_conclusion && viewData?.investigation_conclusion.length > 0 ?
                                viewData?.investigation_conclusion.map((item, index) =>
                                    <span className="text-content border-box" key={index}>{item}</span>
                                ) : NA_STATUS}
                        </div>
                        <p className="text-content">{viewData?.more_details}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead mt-3'>Supporting Documents</div>
                    </div>
                    {images?.length > 0 ? images.map((item, i) => (
                        <div className='col-md-6' key={item?.id}>
                            <UploadedFileContainer
                                type='uploadedImage'
                                index={i}
                                img={item}
                                showPreview={showPreview}
                                downloadImg={downloadImg}
                                >
                                <img src={DownloadCompleteImg} alt='DownloadCompleteImg' />
                            </UploadedFileContainer>
                        </div>
                    )) : <div className="ml-2">No image added</div>}
                </div>
            </div>
            <div className="row mt-30">
                <div className="col-md-12 text-right">
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>
            </div>

            <ImagePreviewPopUp
                showPreviewImg={showPreviewImg}
                setShowPreviewImg={setShowPreviewImg}
                downloadImg={downloadImg}
                imageName={imageName}
                imageObj={imageObj}
                imageToPreview={imageToPreview}
                docFormatType={docFormatType} />

        </div>
    )
}
export default ViewSpill;