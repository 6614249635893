const Months = [{ name: 'Select All', id: 12, label: 'Select All' },
{ name: 'Jan', id: 0, label: 'Jan' }, { name: 'Feb', id: 1, label: 'Feb' },
{ name: 'Mar', id: 2, label: 'Mar' }, { name: 'April', id: 3, label: 'April' },
{ name: 'May', id: 4, label: 'May' }, { name: 'June', id: 5, label: 'June' },
{ name: 'Jul', id: 6, label: 'Jul' }, { name: 'Aug', id: 7, label: 'Aug' },
{ name: 'Sept', id: 8, label: 'Sept' }, { name: 'Oct', id: 9, label: 'Oct' },
{ name: 'Nov', id: 10, label: 'Nov' }, { name: 'Dec', id: 11, label: 'Dec' }];

const GraphTypes = [
    { name: 'Trend Line', id: 0, label: 'Trend Line' },
    { name: 'Number', id: 1, label: 'Number' },
    { name: 'Trend & Number', id: 2, label: 'Trend & Number' }
];

const Intervals = [
    { name: 'Yearly', id: 0, label: 'Yearly' },
    { name: 'Quarterly', id: 1, label: 'Quarterly' },
    { name: 'Monthly', id: 2, label: 'Monthly' }
];

const EmpDdnData = [
    { value: 'employees', label: 'Employees' },
    { value: 'contractors', label: 'Contractors' },
    { value: 'combined', label: 'Combined' },
];



export default {
    Months, GraphTypes, Intervals, EmpDdnData
}