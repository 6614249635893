import ImagePreviewIcon from '../../../assets/images/PreviewImageIcon.svg';
import ImageDownloadIcon from '../../../assets/images/DownloadImageIcon.svg';

const UploadedFileContainer = (props) => {
    const { showPreview, downloadImg } = props;
    return (
        <div className='row spill-boxWidth imageUpload margin-left-0 margin-bottom-20'>
            <div className='col-12 col-md-2 bgUploadCompleted'>
                <div>{props.children}</div>
            </div>
            <div className="col-12 col-md-10">
                <div className="row">
                    <div className="col-12 col-md-9">
                        <div className="imageName">{props?.img?.usd_name ? props?.img?.usd_name : (props?.image?.image_name ? props?.image?.image_name : (props?.img?.img_name ? props?.img?.img_name :`Image ${props.index + 1}.${props.img?.extension}`))}</div>
                        <div className="imageSize">{props?.img?.usd_size ? props?.img?.usd_size : (props?.image?.image_size ? props?.image?.image_size : (props?.img?.image_size ? props?.img?.image_size : `21`))}KB</div>
                    </div>
                    <div className="col-12 col-md-3 d-flex">
                        {['jpg', 'jpeg', 'png', 'pdf'].includes(props?.img?.extension) && <img src={ImagePreviewIcon} className="px-2 cursor" onClick={() => showPreview(props?.img, props.index)} alt='alternateIcon' />}
                        <img src={ImageDownloadIcon} className={`${['xlsx', 'xls', 'csv', 'doc', 'docx'].includes(props?.img?.extension) ? `excluded-format-view` : `cursor`}`} onClick={() => { downloadImg(props?.img) }} alt='alternateIcon' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadedFileContainer;