import React, { useState } from 'react';
import passHide from "../../../assets/images/passHide.svg";
import passShow from "../../../assets/images/passShow.svg";
import errorIcon from "../../../assets/images/error_icon.svg";
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import { Put } from '../../../constants/apiMethods';
import { changePassword } from '../../../constants/apiRoutes';
import { useForm } from 'react-hook-form';
import LoadingSpinner from '../../../components/Loader';
import { Modal } from 'react-bootstrap';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import { useNavigate } from "react-router";
import './ChangePassword.css';

const ChangePassword = () => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const [loading, setLoading] = useState(false);
    const [showHideChangePassword, setshowHideChangePassword] = useState(false);
    const [showNotifier, setshowNotifier] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const token = localStorage.getItem('access_token');

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useForm();

    const onSubmit = (data) => {
        setValue('old_password', data.old_password);
        setValue('password', data.password);
        setValue('confirm_password', data.confirm_password);
        setshowHideChangePassword(true);
    };

    const onSubmitChangePasswordData = () => {
        const body = {
            old_password: getValues('old_password'),
            password: getValues('password'),
            confirm_password: getValues('confirm_password')
        }
        setLoading(true);
        setshowNotifier(false);
        Put(changePassword, token, body, false)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setshowHideChangePassword(false);
                    setshowNotifier(true);
                    setAlertType('success');
                    setShowMessage(res.detail);
                    localStorage.clear();
                    setTimeout(() => {
                        navigate('../login');
                    }, 2000)
                } else {
                    setshowNotifier(true);
                    setshowHideChangePassword(false);
                    setAlertType('failed');
                    setShowMessage(res.detail);
                }
            })
            .catch((error) => {
                setshowNotifier(true);
                setAlertType('failed');
                setShowMessage(error.detail);
                setLoading(false);
            });
    }

    const setVisibility = (data) => {
        setshowNotifier(data);
    };

    const navigateToDashboard = () => {
        if (role == 'super_admin') {
            // navigate('../subscriptions');
            window.history.back();
        } else {
            // navigate('../safety');
            window.history.back();
        }
    }

    return (
        <>
            {loading && <LoadingSpinner />}
            {showNotifier && <AlertNotifier alertType={alertType} timer={2000} alertText={showMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div className="head_2">{'Change Password'}</div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='row mt-30 light-container'>
                        <div className="form-container">

                            <div className="">
                                <div className='inputFieldHead mt-16'>Old Password</div>
                                <input type='password'
                                    id="old_password"
                                    autoComplete="off"
                                    {...register("old_password", {
                                        required: true,
                                    })}
                                    className='form-control'
                                    placeholder='Password' />
                                {/* <div className="passFld">
                                    <img
                                        src={passShow}
                                        className="passShowIcon cursor"
                                        onClick={(e) => {
                                            var div = document.getElementById("old_password");
                                            if (div.type === "password") {
                                                div.type = "text";
                                                e.target.src = passHide;
                                            } else {
                                                div.type = "password";
                                                e.target.src = passShow;
                                            }
                                        }}
                                    />
                                </div> */}
                            </div>
                            {errors.old_password?.type == "required" && (
                                <p className="error"> <img src={errorIcon} /> Old Password is required</p>
                            )}

                            <div className="">
                                <div className='inputFieldHead mt-16'>New Password</div>
                                <input type='password'
                                    id='password'
                                    autoComplete="off"
                                    {...register("password", {
                                        required: true,
                                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/,
                                    })}
                                    className='form-control'
                                    placeholder='Password' />
                                {/* <div className="passFld">
                                    <img
                                        src={passShow}
                                        className="passShowIcon cursor"
                                        onClick={(e) => {
                                            var div = document.getElementById("password");
                                            if (div.type === "password") {
                                                div.type = "text";
                                                e.target.src = passHide;
                                            } else {
                                                div.type = "password";
                                                e.target.src = passShow;
                                            }
                                        }}
                                    />
                                </div> */}

                            </div>
                            {errors.password?.type == "required" && (
                                <p className="error">
                                    <img src={errorIcon} /> New Password is required </p>
                            )}

                            {errors.password?.type == "pattern" && (
                                <p className="error">
                                    <img src={errorIcon} /> Password must be 8 characters long, 1 upper case, 1 lower case, 1 special character, 1 numeral
                                </p>
                            )}

                            <div className="">
                                <div className='inputFieldHead mt-16'>Confirm Password</div>
                                <input type='password'
                                    id='confirm_password'
                                    autoComplete="off"
                                    {...register("confirm_password", {
                                        required: true,
                                    })}
                                    className='form-control'
                                    placeholder='Password' />
                                <div className="passFld">
                                    <img
                                        src={passHide}
                                        className="passShowIcon cursor"
                                        onClick={(e) => {
                                            var div = document.getElementById("confirm_password");
                                            if (div.type === "password") {
                                                div.type = "text";
                                                e.target.src = passShow;
                                            } else {
                                                div.type = "password";
                                                e.target.src = passHide;
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                            {errors.confirm_password?.type == "required" && (
                                <p className="error">
                                    <img src={errorIcon} /> Confirm Password is required </p>
                            )}

                            {watch("confirm_password") !== watch("password") && getValues("confirm_password") && (
                                <p className="error">
                                    <img src={errorIcon} /> Password and Confirm Password should be same </p>
                            )}
                        </div>
                    </div>

                    <div className='text-right'>
                        <button className='btn-cancel border-0 mr-15' onClick={navigateToDashboard}>Cancel</button>
                        <button type="submit" className='btn-event-incident border-0 mt-30'> {'Change Password'}</button>
                    </div>

                </form>
            </div>


            <Modal show={showHideChangePassword} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangePassword(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you want to change your password?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideChangePassword(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={onSubmitChangePasswordData}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </>

    )
}

export default ChangePassword