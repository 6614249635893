import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import data from '../../../../mockData/mockData';
import { Get } from "../../../../constants/apiMethods";
import { companyList, getCityList, getStateListing } from "../../../../constants/apiRoutes";
import LoadingSpinner from "../../../../components/Loader";
import moment from "moment";
import './CompanyDetails.css';

const CompanyDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [viewData, setViewData] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const token = localStorage.getItem("access_token");

    useEffect(() => {
        getDetailsById();
    }, []);

    const getDetailsById = () => {
        // let body = {
        //     id: location.state.id
        // }
        setLoading(true);
        Get(companyList, token, `?is_active=${location.state.is_active}&id=${location.state.id}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    setViewData(data.data);
                    getStateList();
                    getCityListing();
                }
            });
    };

    const getStateList = () => {
        setLoading(true);
        Get(getStateListing, token, '?min=true&sort=name', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setStateList(data?.data);
                    setLoading(false);
                }
            });
    }

    const getCityListing = () => {
        setLoading(true);
        Get(getCityList, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setCityList(data?.data?.data);
                    setLoading(false);
                }
            });
    }

    const getStateName = (state_id) => {
        const stateToShow = stateList.filter((resp) => resp?.id == state_id)[0]?.name;
        return stateToShow;
    }

    const getCityName = (city_id) => {
        const cityToShow = cityList.filter((resp) => resp?.id == city_id)[0]?.name;
        return cityToShow;
    }

    const handleCompanyOnboard = () => {
        !location.state.is_active ? localStorage.setItem('isDeactivated', 1) : localStorage.setItem('isDeactivated', 0);
        navigate('/company-list');
    }

    const handleClick = () => {
        navigate('/update-company', { state: { id: location.state.id, is_active: location.state.is_active}  });
    }

    const getIndexName = (index) => {
        const indexNameToShow = data.IndexData.filter((resp) => resp?.id == index)[0]?.label;
    
        return indexNameToShow;
    }

    return (
        <div className="full-cont">
            {loading && <LoadingSpinner />}
            <div className="row">
                <div className="col-md-7">
                    <div className="head_1"><span className='click_head_1 cursor fw-400' onClick={handleCompanyOnboard}>Onboard Management</span> / {location.state.is_active ? 'Active Companies' : 'Deactivated Companies'} / <span>View Details</span></div>
                    <div className="head_2">{viewData?.name}</div>
                </div>
                <div className="col-md-5 text-right">
                    {/* <button type="button" className='btn-export no_margin mr-15'>Export</button> */}
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>
            </div>
            <hr className="defaultLine" />

            <div className='containerHeading'>Company Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Company Name</div>
                        <p className="text-content word-break">{viewData?.name ? viewData?.name : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Company's ID</div>
                        <p className="text-content word-break">{viewData?.name ? viewData?.id : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Onboarding Date</div>
                        <p className="text-content word-break">{viewData?.date_of_onboarding ? moment(viewData?.date_of_onboarding).format('MM-DD-YYYY') : 'NA'}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>{location.state.is_active ? 'Last Login Date' : 'Deactivation Date'}</div>
                        <p className="text-content word-break">{location.state.is_active ? viewData?.last_login_date ? moment(viewData?.last_login_date).format('MM-DD-YYYY') : 'NA' : viewData?.date_of_deactivation ? moment(viewData?.date_of_deactivation).format('MM-DD-YYYY') : 'NA' }</p>
                    </div>
                    {/* <div className='col-md-2'>
                        <div className='inputFieldHead'>Last Login Time</div>
                        <p className="text-content word-break">{viewData?.last_login_time ? viewData?.last_login_time : 'NA'}</p>
                    </div> */}
                </div>
            </div>

            <div className='containerHeading'>Agency Details</div>
            <div className='container_div'>
                {viewData?.agency?.length > 0 ? viewData?.agency?.map((item, index) => {
                    return (
                        <div className='row'>
                            <div className='col-md-4'>
                                {index === 0 ? <div className='inputFieldHead'>Name of the Agency</div> : ''}
                                <p className="text-content word-break">{item?.name}</p>
                            </div>
                            <div className='col-md-4'>
                                {index === 0 ? <div className='inputFieldHead'>Agency Web URL</div> : ''}
                                <p className="text-content word-break">{item?.url}</p>
                            </div>
                            <div className='col-md-4'>
                                {index === 0 ? <div className='inputFieldHead'>Operator Id</div> : ''}
                                <p className="text-content word-break">{item?.operator_id}</p>
                            </div>
                        </div>
                    );
                }) :
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='inputFieldHead'>Name of the Agency</div>
                            <p className="text-content word-break">-</p>
                        </div>
                        <div className='col-md-4'>
                            <div className='inputFieldHead'>Agency Web URL</div>
                            <p className="text-content word-break">-</p>
                        </div>
                        <div className='col-md-4'>
                            <div className='inputFieldHead'>Opertaor Id Date</div>
                            <p className="text-content word-break">-</p>
                        </div>
                    </div>}
            </div>

            {viewData?.address?.map((item, index) => {
                return (
                    <>
                        <div className='containerHeading'>{getIndexName(index + 1)} Address</div>
                        <div className='container_div'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Type Of Address</div>
                                    <p className="text-content word-break">{item?.type_of_address ? item?.type_of_address : 'NA'}</p>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Address</div>
                                    <p className="text-content word-break">{item?.address_1 ? item?.address_1 + ' ' + item?.address_2 : ''}</p>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Zip Code</div>
                                    <p className="text-content word-break">{item?.zip_code ? item?.zip_code : 'NA'}</p>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>City</div>
                                    <p className="text-content word-break">{item?.city ? (item?.city) : 'NA'}</p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>State</div>
                                    <p className="text-content word-break">{item?.state ? (item?.state) : 'NA'}</p>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Company Phone Number</div>
                                    <p className="text-content word-break">{item?.company_phone_no ? item?.company_phone_no : 'NA'}</p>
                                </div>
                            </div>
                        </div>
                    </>
                );
            })}

            <div className='containerHeading'>Company Locations</div>
            <div className='container_div'>
                {viewData?.company_locations?.map((item, index) => {
                    return (
                        <div className='row'>
                            <div className='col-md-3'>
                                {index === 0 ? <div className='inputFieldHead'>States</div> : ''}
                                <p className="text-content word-break">{item?.name ? item?.name : 'NA'} </p>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className='containerHeading'>Plan Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='inputFieldHead'>Plan Name</div>
                        <p className="text-content word-break">{viewData?.plan?.name ? viewData?.plan?.name : 'NA'}</p>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputFieldHead'>Subscription Start Date</div>
                        <p className="text-content word-break">{viewData?.subscription_start_date ? moment(viewData?.subscription_start_date).format('MM-DD-YYYY') : 'NA'}</p>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputFieldHead'>Subscription End Date</div>
                        <p className="text-content word-break">{viewData?.subscription_end_date ? moment(viewData?.subscription_end_date).format('MM-DD-YYYY') : 'NA'}</p>
                    </div>
                </div>
            </div>

            <div className='containerHeading'>Admin Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='inputFieldHead'>Name</div>
                        <p className="text-content word-break">{viewData?.company_admin?.name ? viewData?.company_admin?.name : 'NA'}</p>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputFieldHead'>Email ID</div>
                        <p className="text-content word-break">{viewData?.company_admin?.username ? viewData?.company_admin?.username : 'NA'}</p>
                    </div>
                </div>
            </div>

            <div className="col-md-12 text-right mt-30">
                    {/* <button type="button" className='btn-export no_margin mr-15'>Export</button> */}
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>

        </div>
    )
}

export default CompanyDetails;