import { createSlice } from '@reduxjs/toolkit';
import { clearAllUsers } from '../actions';

const incidentSlice = createSlice({
    name: 'createIncident',
    initialState: [],
    reducers: {
        addIncident(state, action) {
            state.push(action.payload)
        },
        removeIncident(state, action) {
            // let userIndex = state.indexOf(action.payload);
            state.splice(action.payload, 1);
        },
        clearAllIncidents(state, action) {
            return [];
        },
    },
    extraReducers(builder) {
        builder.addCase(incidentSlice.actions.clearAllIncidents, ()=> {
            return [];
        });
    }

    // extraReducers(builder) {
    //     builder.addCase(clearAllUsers, ()=> {
    //         return [];
    //     });
    // }
});


export default incidentSlice.reducer;
export const { addIncident, removeIncident, clearAllIncidents } = incidentSlice.actions;