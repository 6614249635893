import React, { useEffect, useState } from "react";
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import EditIcon from '../../../assets/images/FormImages/editIcon.svg';
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import UploadIcon from '../../../assets/images/FormImages/upload.svg';
import CrossImg from '../../../assets/images/crossImage.svg';
import DeleteImg from '../../../assets/images/deleteImg.svg';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import FilterTickIcon from '../../../assets/images/FilterTickIcon.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import ChangingProgressProvider from '../../../components/ChangingProgressProvider/ChangingProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import data from '../../../mockData/mockData';
import OnboardingData from '../../../mockData/OnboardingData/mockData';
import './StateCity.css';
import "../SubscriptionPlan/SubscriptionPlan.css";
import { useNavigate } from "react-router";
import { Delete2, Get, Put } from "../../../constants/apiMethods";
import { actionState, cityState, deleteCity, getCityList, getStateListing } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import { Modal } from "react-bootstrap";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import moment from "moment";

const StateCity = () => {
    const navigate = useNavigate();
    const citiesAndZipcode = OnboardingData.citiesAndZipcode;
    const token = localStorage.getItem("access_token");
    const getPage = localStorage.getItem('getPage');
    const [showActive, setShowActive] = useState(getPage == 1 ? false : true);
    const [showFilters, setFilters] = useState(true);
    const [loading, setLoading] = useState(false);
    const [stateCityListData, setStateCityList] = useState([]);
    const [showHideCitiesName, setShowHideCitiesNameModal] = useState({ show: false, citiesData: [], data: {} });
    const [showHideChangeStatusState, setshowHideChangeStatusState] = useState({ show: false, id: '' });
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [stateValue, setStateValue] = useState('');
    const [selectedCitiesId, setSelectedCitiesId] = useState([]);
    const [selectedCityString, setSelectedCityString] = useState('');

    useEffect(() => {
        getStateCityList('', '', '');
        getStatesForFilter();
        getCitiesForFilter();
    }, [showActive])

    setTimeout(() => {
        localStorage.removeItem('getPage');
    }, 1)

    const getStateCityList = (searchValue, stateValue, selectedCitiesId) => {
        hideElement();
		setStateValue(stateValue);
        let selectedCityStr = '';
        selectedCityStr = selectedCitiesId.toString();
        setSelectedCityString(selectedCityStr);
        setLoading(true);
        Get(getStateListing, token, `?is_active=${showActive}&name=${searchValue ? searchValue : stateValue}&city=${selectedCityStr}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data) {
                    setStateCityList(data.data);
                }
            });
    };

    const getStatesForFilter = () => {
        setLoading(true);
        Get(getStateListing, token, `?min=true&sort=name`)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data) {
                    setStateData(data.data);
                }
            });
    };

    const getCitiesForFilter = () => {
        setLoading(true);
        Get(deleteCity, token, `?min=true`)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data) {
                    const cityListData = data.data?.map((resp) => {
                        return { ...resp, checked: false }
                    });
                    setCityData(cityListData);
                }
            });
    };

    const changeStateCityStatus = () => {
        setShowSuccessNotifier(false);
        setSuccessRes(false);
        setLoading(true);
        const body = {
            is_active: true
        }
        if (showActive) {
            Delete2(actionState, token, showHideChangeStatusState?.id, false)
                .then((response) => response)
                .then((data) => {
                    if (data.success) {
                        getStateCityList(searchValue, stateValue, selectedCitiesId);
                        setLoading(false);
                        setshowHideChangeStatusState({ show: false, id: '' });
                        setShowSuccessNotifier(true);
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(`State Deactivated successfully`);
                    }
                });
        } else {
            Put(`${cityState}/${showHideChangeStatusState.id}/`, token, body, false)
                .then((response) => response)
                .then((data) => {
                    if (data.success) {
                        getStateCityList(searchValue, stateValue, selectedCitiesId);
                        setLoading(false);
                        setshowHideChangeStatusState({ show: false, id: '' });
                        setShowSuccessNotifier(true);
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(`State Activated successfully`);
                    }
                });
        }
    }

    const onChange = (e, index) => {
        const updatedData = [...cityData];
        updatedData[index].checked = e.target.checked;
        setCityData(updatedData);
        let selectedIds = cityData?.map((resp) => {
            if (resp.checked) {
                return resp.id
            } else {
                return ''
            }
        })
        selectedIds = selectedIds.filter(x => x != '');
        setSelectedCitiesId(selectedIds);
        // showElement();
    }

    const selectAll = () => {
        return cityData?.every(item => item.checked === true);
    }


    const onAllFeaturesSelect = (e) => {
        const updatedData = cityData.map(item => ({
            ...item,
            [e.target.name]: e.target.checked,
        }));
        setCityData(updatedData);
        const selectedIds = cityData?.map((resp) => {
            return resp.id
        })
        if (e.target.checked) {
            setSelectedCitiesId(selectedIds);
        } else {
            setSelectedCitiesId([]);
        }
        // showElement();
    }

    const onClearFeature = () => {
        const updatedData = cityData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setCityData(updatedData);
        // setCityData([]);
        getStateCityList('', '', '');
    }

    const clearFilters = () => {
        const removeAllChecked = cityData?.map((resp) => {
            return { ...resp, checked: false }
        })
        setCityData(removeAllChecked);
        setSelectedCityString('');
        setSelectedCitiesId([]);
        getStateCityList('', '', '');
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const hideElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.remove('show');
        }
        const element2 = document.getElementById('dropdownHidden2');
        if (element2) {
            element2.classList.remove('show');
        }
    }

    const showElement = () => {
        const element1 = document.getElementById('dropdownHidden1');
        if (element1) {
            element1.classList.add('show');
        }
    }

    const handleNewState = () => {
        navigate('/create-state-city');
    }

    const handleEditState = (id) => {
        navigate('/update-state-city' , { state: { id: id, is_active: showActive } });
    }

    const handleClick = () => {
        //to be used while API integration  
    }

    const UploadContainer = (props) => {
        return (
            <>
                <div className={`row boxWidth ml-20 mt-30 ${props.type == 'imageProcessing' ? 'percentProcess' : props.type == 'uploadedImage' ? 'imageUpload' : ''}`}>
                    <div className={`col-md-2 ${props.type == 'imageProcessing' ? 'bgPercentProcessColor' : props.type == 'uploadedImage' ? 'bgUploadCompleted' : ''}`}>
                        <div>{props.children}</div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-11">
                                <div className="imageName">State Excel.xlsl</div>
                                <div className="imageSize">13 MB</div>
                            </div>
                            <div className="col-md-1 flex contentCenter">
                                <img src={props.type == 'imageProcessing' ? CrossImg : DeleteImg} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">

                <div className="row border_head_1">
                    <div className="col-md-6">
                        <div className="head_2">State & City</div>
                    </div>
                    <div className="col-md-6 text-right">
                        <button className='btn-cancel border-0'>Sample Format</button>
                        <button className='btn-add-data mr-15 mt-0' data-bs-toggle='modal' data-bs-target='#uploadExcelModal'>Bulk Upload</button>
                        <button className='onboard_btn set-onbBtn-wd border-0' onClick={handleNewState}>Add New State</button>
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-12">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active States</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated States</p>
                        </div>
                    </div>
                </div>

                <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${stateValue ? 'selected-filter-color' : ''}`}  type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                State
								<img className="ml-8" src={stateValue ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden1' aria-labelledby="dropdownMenuButton1">
								{stateData?.map((state, i) => (
									<a className="dropdown-item cursor" onClick={(e) => { getStateCityList(searchValue, state?.name, selectedCitiesId) }}>
										<div className="">
											{stateValue == state?.name ? <img src={FilterTickIcon} /> : ''}
											<label className={` cursor ${stateValue == state?.name ? 'selected-filter-color ml-8' : ''}`}>{state?.name}</label>
										</div>
									</a>
								))}
								<div className="mt-2 float-right">
									<button type="button" className="btn-clear" onClick={(e) => { getStateCityList(searchValue, '', selectedCitiesId) }}>Clear</button>
								</div>
							</div>
                        </div>

                        <div className="dropdown">
                            <button className={`btn dropdown-toggle ${selectedCityString != '' ? 'selected-filter-color' : ''}`} type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                City
                                <img className="ml-8" src={selectedCityString != '' ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            <div className="dropdown-menu" id='dropdownHidden2' aria-labelledby="dropdownMenuButton2">
                                <a className="dropdown-item">
                                    <div className="form-check">
                                        <label className="form-check-label selectAll" for="Checkme1">Select All</label>
                                        <input className="form-check-input" type="checkbox" id="Checkme1" name='checked' checked={selectAll()} onChange={(e) => onAllFeaturesSelect(e)} />
                                    </div>
                                </a>
                                {cityData?.map((item, i) => (
                                    <a className="dropdown-item" href="#">
                                        <div className="form-check">
                                            <label className="form-check-label" for={`Checkme ${i}`}>{item?.name}</label>
                                            <input className="form-check-input" type="checkbox" value={item.id} id={`Checkme ${i}`} name={item.name} checked={item?.checked} onChange={(e) => onChange(e, i, item)} />
                                        </div>
                                    </a>
                                ))}
                                <div className="flex mt-4">
                                    <button type="button" className="btn-clear" onClick={onClearFeature}>Clear</button>
                                    <button type="button" className="btn-done" onClick={() => { getStateCityList(searchValue, stateValue, selectedCitiesId) }}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            {/* <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div> */}
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by typing States" onChange={(event) => { getStateCityList(event.target.value, stateValue, selectedCitiesId); setSearchValue(event.target.value) }} />
                            <img className="cursor" src={ClearSvg} onClick={() => {setFilters(true); setSearchValue(''); getStateCityList(searchValue, stateValue, selectedCitiesId);}} />
                        </div>}

                </div>

                {(stateCityListData?.length > 0) ?
                    <>
                        {stateCityListData.map((item, index) => {
                            return (
                        <div className='container_div'>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='plan-name'>{item?.name}</div>
                                </div>
                                <div className='col-md-6 text-right'>
                                    <div className='change-plan-status cursor' onClick={() => { setshowHideChangeStatusState({ show: true, id: item?.id }) }}>{showActive ? 'Deactivate' : 'Activate'}</div>
                                    <img className="editIcn cursor ml-8" src={EditIcon} onClick={() => handleEditState(item?.id)}/>
                                </div>
                            </div>

                            <div className='row mt-30'>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Created on</div>
                                    <p className="text-content">{item?.created_on !== null ? moment(item?.created_on).format('MM-DD-YYYY') : 'NA'}</p>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Last updated on</div>
                                    <p className="text-content">{item?.updated_on !== null ? moment(item?.updated_on).format('MM-DD-YYYY') : 'NA'}</p>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputFieldHead'>Created by</div>
                                    <p className="text-content">{item?.created_by ? item?.created_by?.name : 'NA'}</p>
                                </div>
                            </div>

                            <div className='row mt-30'>
                                <div className='col-md-12'>
                                    <div className='inputFieldHead'>Cities ( Zip code) within the state</div>
                                    <div className='flex'>
                                        {/* <p className="text-content mt-16"> {item?.cities.slice(0, 6).map((item, index) => (
                                            <span key={index}>
                                                <span className="feature-wrapper">{item.name} ({item?.zip_code})</span>
                                            </span>
                                        ))}</p> */}
                                        <p className="text-content single-plan-container"> {item?.cities.slice(0, 6).map((item, index) => (
                        <span key={index}>
                            <span className="feature-wrapper plan-label">{item?.name} ({item?.zip_code})</span>
                        </span>
                    ))}</p>
                                        {item?.cities?.length > 6 && <p className='additional-features cursor mt-10' onClick={() => setShowHideCitiesNameModal({ show: true, citiesData: item.cities, data: item })}>+ {item?.cities?.length - 6} {item?.cities?.length < 1 ? 'Cities' : 'City'}</p>}
                                    </div>
                                </div>
                            </div>

                        </div>
                            );
                        })}
                    </> :
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center no-data">No Data Found</div>
                        </div>
                    </div>
                }

            </div>

            <Modal show={showHideChangeStatusState.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusState({ show: false, id: '' })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} this State?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusState({ show: false, id: '' })}>No</button>
                        <button type="button" className="btn-yes" onClick={changeStateCityStatus}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <Modal show={showHideCitiesName.show} dialogClassName="modulesModal" onHide={() => setShowHideCitiesNameModal({ show: false, citiesData: [], data: {} })}>
                <Modal.Header className="sideOnRight" closeButton>
                {/* <div className='feature-modal-head'>Cities and Zip code</div> */}
                    <div className='feature-modal-head'>Cities and Zip code of {showHideCitiesName?.data?.name}</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0">
                    <p className="text-content single-plan-container"> {showHideCitiesName.citiesData.map((item, index) => (
                        <span key={index}>
                            <span className="feature-wrapper plan-label">{item.name} ({item.zip_code})</span>
                        </span>
                    ))}</p>
                </Modal.Body>
            </Modal>

            <div className="modal fade" id="uploadExcelModal" tabIndex="-1" role="dialog" aria-labelledby="uploadExcelModalLabel" aria-hidden="true">
                <div className="modal-dialog reportConfrmModal" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <div className='inputFieldHead'>Upload File</div>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <img src={BtnCrossIcon} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='supportDcmnt'>
                                <img className="mt-30" src={UploadIcon} />
                                <div className='dragTitle'>Drag and drop files here</div>
                                <div className='orIcon'>or</div>
                                <button type="button" className='btn-add-data mr-15'>Browse files</button>
                            </div>
                            <UploadContainer type='imageProcessing' >
                                <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                                    {percentage => (
                                        <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                    )}
                                </ChangingProgressProvider>
                            </UploadContainer>

                            <UploadContainer type='uploadedImage' >
                                <img src={DownloadCompleteImg} />
                            </UploadContainer>
                        </div>
                        <div className="modal-footer mr-48">
                            <button type="button" className="btn-cancel-popup" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn-upload border-0" data-bs-dismiss="modal" onClick={handleClick}>Upload</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default StateCity;