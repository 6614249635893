import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Get } from "../../../../constants/apiMethods";
import { getComplaintsList } from "../../../../constants/apiRoutes";
import DownloadCompleteImg from '../../../../assets/images/downloadComplete.svg';
import { saveAs } from 'file-saver';
import "./index.css";
import LoadingSpinner from "../../../../components/Loader";
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import UploadedFileContainer from "../../../../components/FileUpload/UploadedFileContainer";
import ImagePreviewPopUp from "../../../../components/ImagePreviewPopUp";
import { NA_STATUS, CLOSED_STATUS, OTHERS_VALUE } from '../../../../constants/Global';
import { OTHER_SOURCES } from '../../../../constants/Complaints';


const ViewDirectComplaint = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [viewData, setViewData] = useState([]);
    const token = localStorage.getItem("access_token");
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [imageObj, setImageObj] = useState();
    const [docFormatType, setDocFormatType] = useState('');
    const [images, setImages] = useState([]);

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    const handleClick = () => {
        navigate('/update-complaint', { state: { id: viewData?.id, view: 'fromView' } });
    }

    useEffect(() => {
        getDetailsById();
    }, []);

    const getDetailsById = () => {
        setLoading(true);
        let body = {
            id: location?.state?.id
        }
        Get(getComplaintsList, token, `?id=${body.id}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setViewData(data.data);
                    const updatedDocuments = data.data?.supporting_documents && data.data?.supporting_documents.length > 0 && data.data?.supporting_documents.map(doc => {
                        const extension = doc.usd_name.split('.').pop();
                        return { ...doc, extension };
                    });
                    setImages(updatedDocuments ? updatedDocuments : []);
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType('failed');
                    setSuccessRes(true);
                }
                setLoading(false);
            });
    };

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const showPreview = (item) => {
        setImageObj(item);
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        setImageName(item?.usd_name);
        setImageToPreview(`${imgUrl}${item?.usd_path}`)
    }

    const downloadImg = (imgUrl) => {
        window.open(`${process.env.REACT_APP_IMAGE_URL+imgUrl?.usd_path}`);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <div className="full-cont">
            {loading ? <LoadingSpinner /> : ''}
            {successRes ? <AlertNotifier alertType={alertType} timer={3000} alertText={showSuccessMessage} setVisibility={setVisibility} /> : ''}
            <div className="row">
                <div className="col-md-7">
                    <div><span className="head_1 cursor" onClick={() => { navigate('/complaints-dashboard') }}>Complaints Dashboard</span> / <span className="head_1 cursor" onClick={() => { navigate('/complaints-management') }}>Complaint Management</span> / <span className="head_3">View Complaint</span></div>
                    <div className="head_2">{viewData?.complaint_name ? viewData?.complaint_name : 'Complaint detail'}</div>
                </div>
                <div className="col-md-5 text-right">
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>
            </div>
            <div>
            </div>
            <hr className="defaultLine" />

            <div className='containerHeading-complaint'>System Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Added By</div>
                        <p className="text-content">{viewData?.added_by ? viewData?.added_by : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Added On</div>
                        <p className="text-content">{viewData?.created_on ? viewData?.created_on : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Last Updated By</div>
                        <p className="text-content">{viewData?.updated_by ? viewData?.updated_by : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Last Updated On</div>
                        <p className="text-content">{viewData?.updated_on ? viewData?.updated_on : NA_STATUS}</p>
                    </div>
                </div>
            </div>

            <div className='containerHeading-complaint'>Complaint Details</div>

            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Complaint Name</div>
                        <p className="text-content">{viewData?.complaint_name ? viewData?.complaint_name : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Complaint Status</div>
                        <p className="text-content">{viewData?.complaint_status ? viewData?.complaint_status : NA_STATUS}</p>
                    </div>
                    {viewData?.complaint_status === CLOSED_STATUS && <div className='col-md-3'>
                        <div className='inputFieldHead'>Date of Closure</div>
                        <p className="text-content">{viewData?.date_of_closure ? viewData?.date_of_closure : NA_STATUS}</p>
                    </div>}
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>State</div>
                        <p className="text-content">{viewData?.state ? viewData?.state : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Facility Name</div>
                        <p className="text-content">{viewData?.location === null ? viewData?.facility_name : <span>Others ({viewData?.location})</span>}</p>
                    </div>

                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Complaint Type</div>
                        <p className="text-content">{viewData?.complaint_type ? (viewData?.complaint_type === OTHERS_VALUE ? `${OTHERS_VALUE} (${viewData?.complaint_type_other})` : viewData?.complaint_type) : NA_STATUS}</p>
                    </div>

                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Date of Complaint</div>
                        <p className="text-content">{viewData?.date_of_complaint ? viewData?.date_of_complaint : NA_STATUS}</p>
                    </div>
                </div>

            </div>

            <div className='container_div mt-30'>
                <div className='row mb-1'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Time of Complaint</div>
                        <p className="text-content">{viewData?.time ? viewData?.time : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Who received the complaint</div>
                        <p className="text-content">{viewData?.complaint_receiver ? viewData?.complaint_receiver : NA_STATUS}</p>
                    </div>

                    <div className='col-md-4'>
                        <div className='inputFieldHead'>How the Complaint was Received</div>
                        <p className="text-content">
                            {viewData?.complaint_source ?
                                viewData?.complaint_source
                                    .sort((a, b) => (a === "Other Sources" ? 1 : b === "Other Sources" ? -1 : 0)) // Sort the array
                                    .map((item, i, arr) => (
                                        <React.Fragment key={i}>
                                            <span className="">{item === OTHER_SOURCES ? `${OTHER_SOURCES} (${viewData?.complaint_source_other}) ` : item}</span>
                                            {i < arr.length - 1 && <span>, </span>}
                                        </React.Fragment>
                                    )) : NA_STATUS}
                        </p>
                    </div>
                </div>
            </div>

            <div className='containerHeading-complaint'>Complainant Details</div>

            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>First Name</div>
                        <p className="text-content">{viewData?.first_name ? viewData?.first_name : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Last Name</div>
                        <p className="text-content">{viewData?.last_name ? viewData?.last_name : NA_STATUS}</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='inputFieldHead'>Physical Address</div>
                        <p className="text-content">{viewData?.physical_address ? viewData?.physical_address : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Zip Code</div>
                        <p className="text-content">{viewData?.zip_code ? viewData?.zip_code : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>City</div>
                        <p className="text-content">{viewData?.city ? viewData?.city : NA_STATUS}</p>
                    </div>

                </div>

                <div className='row'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>State</div>
                        <p className="text-content">{viewData?.state_name ? viewData?.state_name : NA_STATUS}</p>
                    </div>

                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Phone Number</div>
                        <p className="text-content">{viewData?.phone_number ? normalizeInput(viewData?.phone_number) : NA_STATUS}</p>
                    </div>
                </div>

            </div>


            <div className='containerHeading-complaint'>Investigation Details</div>
            <div className='container_div'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead'>Describe Location of concern</div>
                        <p className="text-content">{viewData?.describe_location_of_concern ? viewData?.describe_location_of_concern : NA_STATUS}</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead'>Detailed Description of the Issue</div>
                        <p className="text-content">{viewData?.detailed_description_of_the_issue ? viewData?.detailed_description_of_the_issue : NA_STATUS}</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead'>Detailed Description of the Proposed Resolution</div>
                        <p className="text-content">{viewData?.detailed_description_of_the_proposed_resolution ? viewData?.detailed_description_of_the_proposed_resolution : NA_STATUS}</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='inputFieldHead mt-3'>Supporting Documents</div>
                    </div>
                    {images?.length > 0 ? images.map((item, i) => (
                        <div className='col-md-6' key={item?.id}>
                            <UploadedFileContainer
                                type='uploadedImage'
                                index={i}
                                img={item}
                                showPreview={showPreview}
                                downloadImg={downloadImg}
                                >
                                <img src={DownloadCompleteImg} alt='DownloadCompleteImg' />
                            </UploadedFileContainer>
                        </div>
                    )) : <div className="ml-2">No image added</div>}
                </div>
            </div>
            <div className="row mt-30">
                <div className="col-md-12 text-right">
                    <button type="button" className='btn-update in-blck' onClick={handleClick}>Update</button>
                </div>
            </div>

            <ImagePreviewPopUp
                showPreviewImg={showPreviewImg}
                setShowPreviewImg={setShowPreviewImg}
                downloadImg={downloadImg}
                imageName={imageName}
                imageObj={imageObj}
                imageToPreview={imageToPreview}
                docFormatType={docFormatType} />
        </div>
    )
}
export default ViewDirectComplaint;