import React, { useEffect, useState } from "react";
import './CreatePlan.css';
import Select from 'react-select';
import BtnCrossIcon from '../../../../assets/images/FormImages/btnCross.svg';
import errorIcon from "../../../../assets/images/error_icon.svg";
import AlertNotifier from "../../../../components/Notifications/AlertNotifier";
import OnboardingData from '../../../../mockData/OnboardingData/mockData';
import { useLocation, useNavigate } from "react-router";
import { Get, Post, Put } from "../../../../constants/apiMethods";
import { createPlan, modules, subscription } from "../../../../constants/apiRoutes";
import { Controller, useForm } from "react-hook-form";
import LoadingSpinner from "../../../../components/Loader";
import { Modal } from "react-bootstrap";

const CreatePlan = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [planFeatures, setPlanFeatures] = useState([]);
    const [modulesListData, setModulesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideCreatePlan, setShowHideCreatePlan] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [actionPage, setActionPage] = useState('');
    const [viewData, setViewData] = useState('');
    const [alertType, setAlertType] = useState('');
    const token = localStorage.getItem("access_token");
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        trigger,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (location.state.id) {
            setActionPage('Update Plan');
            getPlanDetails();
        } else {
            setActionPage('Create New Plan');
        }
        getModulesList();
    }, [])

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const getModulesList = () => {
        Get(modules, token, '', false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    const modulesData = data.data?.map((resp) => {
                        return { ...resp, label: resp.name, value: resp.name }
                    })
                    setModulesList(modulesData);
                }
            });
    };

    const onSubmit = (data) => {
        setValue('name', data.name);
        setValue('duration', data.duration);
        setValue('selectOption', data.selectOption);
        setShowHideCreatePlan(true);
    };

    const createSubscriptionPlan = () => {
        const selectedModulesId = getValues('selectOption')?.map((resp) => {
            return resp.id
        })
        // data.module_ids = [...selectedModulesId];
        let data = {
            name: getValues('name'),
            duration: getValues('duration'),
            module_ids: [...selectedModulesId],
        }
        setLoading(true);
        setSuccessRes(false);
        if (actionPage == 'Create New Plan') {
            Post(createPlan, token, data, false)
                .then((res) => {
                    setLoading(false);
                    setShowHideCreatePlan(false);
                    if (res.success) {
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage('The Plan has been added to the Subscription Plans');
                        setTimeout(() => {
                            navigate('/subscriptions');
                        }, 2000)
                    } else {
                        setSuccessRes(true);
                        setAlertType('failed');
                        setShowSuccessMessage(res.detail);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else {
            Put(`${subscription}/${location.state.id}/`, token, data, false)
                .then((res) => {
                    setLoading(false);
                    setShowHideCreatePlan(false);
                    if (res.success) {
                        setSuccessRes(true);
                        setAlertType('success');
                        setShowSuccessMessage(res.detail);
                        setTimeout(() => {
                            navigate('/subscriptions');
                        }, 2000)
                    } else {
                        setSuccessRes(true);
                        setAlertType('failed');
                        setShowSuccessMessage(res.detail);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }

    const getPlanDetails = () => {
        setLoading(true);
        Get(subscription, token, `?id=${location.state.id}&is_active=${location.state.is_active}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setViewData(data);
                    setPlanData(data);
                    setLoading(false);
                }
            });
    };

    const setPlanData = (data) => {
        setValue('name', data.data.name);
        setValue('duration', data.data.duration);
        const modulesData = data.data.modules?.map((resp) => {
            return { ...resp, label: resp.name, value: resp.name }
        })
        setValue('selectOption', modulesData);
        setPlanFeatures(modulesData);
    }

    const onFeaturesChange = (selection, { name }) => {
        setValue(name, selection);
        setPlanFeatures((selection));
        trigger(name);
    }

    const navigateToSubscription = () => {
        // setShow(true);
        navigate('/subscriptions');
    }

    const handleDelete = (id) => {
        const newFeatures = planFeatures.filter((item) => item.id !== id);
        setPlanFeatures(newFeatures);
        setValue('selectOption', newFeatures);
    }

    return (
        <div className="full-cont">
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="border_head_1">
                <div className="head_1 cursor"><span className="color-grey" onClick={navigateToSubscription}>Plans/</span> <span className="head_3">{actionPage == 'Update Plan' ? getValues('name') : 'Create New Plan'}</span></div>
                <div className="head_2">{actionPage}</div>
            </div>
            <form className="mt-30" onSubmit={handleSubmit(onSubmit)}>
                <div className='row mt-30 light-container'>
                    <div className='col-md-6'>
                        <div className='inputFieldHead'>Name of the Plan*</div>
                        <input type='text'
                            className='form-control'
                            name="planName"
                            placeholder='Type here'
                            {...register('name', {
                                required: true,
                            })}
                        />
                        {errors.name?.type == "required" && (
                            <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                        )}
                    </div>
                    <div className='col-md-6'>
                        <div className='inputFieldHead'>Duration of the Plan*</div>
                        <input type='number'
                            className='form-control'
                            name="eventName"
                            min="0"
                            placeholder='Type duration in Months like 12'
                            {...register('duration', {
                                required: true,
                            })}
                        />
                        {errors.duration?.type == "required" && (
                            <p className="error"> <img src={errorIcon} /> This field is mandatory </p>
                        )}
                    </div>
                </div>

                <div className='row mt-30 light-container'>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className='inputFieldHead'>Modules of the Plan*</div>
                            <Controller
                                name="selectOption"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix="an-simple-select"
                                        placeholder='Choose Modules for your Plan'
                                        options={modulesListData}
                                        onChange={(selection) => onFeaturesChange(selection, field)}
                                        value={planFeatures}
                                        isMulti
                                    />
                                )}
                                rules={{ required: 'This field is mandatory' }} // Validation rule for required
                            />
                            {errors.selectOption && (
                                <p className="error"> <img src={errorIcon} /> {errors.selectOption.message} </p>
                            )}
                        </div>
                    </div>
                    <div className="row mt-30">
                        <div className="col-md-12">
                            {planFeatures.map((item, index) => (
                                <span key={index} className="line-h">
                                    <span className="feature-wrapper">{item.name}<img className="cross-btn-size" src={BtnCrossIcon} onClick={() => handleDelete(item.id)} /></span>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='text-right'>
                    <button type="button" className="btn-cancel border-0 mr-15" onClick={navigateToSubscription}>Cancel</button>
                    {actionPage == 'Create New Plan' ?
                        <button type="submit" className='btn-event-incident in-blck mt-30'> {'Create Plan'}</button> :
                        <button type="submit" className='btn-event-incident in-blck mt-30'> {'Update Plan'}</button>}
                </div>
            </form>

            <Modal show={showHideCreatePlan} dialogClassName="reportConfrmModal" onHide={() => setShowHideCreatePlan(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {actionPage == 'Create New Plan' ? 'Create' : 'Update'} this Plan?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" data-bs-dismiss="modal" onClick={() => { setShowHideCreatePlan(false) }}>No</button>
                        <button type="button" className="btn-yes" onClick={createSubscriptionPlan}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default CreatePlan;