import React, { useState, useEffect } from 'react';
import IncidentDetails from './IncidentDetails';
import { useNavigate, useLocation } from 'react-router-dom';
import IncidentInvestigationDetails from './IncidentInvestigationDetails';
import { Steps } from 'antd';
import { Post } from "../../../constants/apiMethods";
import { getDataById } from "../../../constants/apiRoutes";
import { useForm } from "react-hook-form";
import './ReportIncident.css';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import LoadingSpinner from '../../../components/Loader';
import { useDispatch } from 'react-redux';
import { addIncident } from '../../../store/slice/incidentSlice';
import { Modal } from "react-bootstrap";

function ReportIncident(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem('access_token');
    const [current, setCurrent] = useState(0);
    const [viewData, setViewData] = useState([]);
    const [alertType, setAlertType] = useState('');
    const [responseFromDetail, setResponseFromDetails] = useState('');
    const [incidentObjVal, setIncidentObjVal] = useState({});
    const [incidentArrData, setIncidentArrData] = useState([]);
    const [incidentUpdateId, setIncidentUpdateId] = useState('');
    const [imageData, setImageData] = useState([]);
    const [initialDetailsFilled, setIntialDetailsFilled] = useState(false);
    const [stepperActivate, setStepperActivate] = useState(false);
    const [showHideDelete, setshowHideDelete] = useState({ show: false });
    // const [stepperPopUpMessage, setStepperPopUpMessage] = useState('');
    const [incidentObj, setIncidentObj] = useState({
        date: '',
        reportedby: '',
        location: '',
        name: '',
        type: 'incident',
        hour: '',
        minutes: '',
        meridian: '',
        incidentType: '',
        state: '',
        jobTitle: '',
        personImpacted: '',
        affectedBodyParts: [],
        injuryType: '',
        correctiveAction: [],
        investigationConclusion: [],
        investigationMoreDetails: '',
        correctiveMoreDetails: '',
        eventDescription: '',
    })

    useEffect(() => {
        props.editMode && getDetailsById();
        // console.log('report incident component', viewData);
        // setApiFormData(viewData && viewData[0].safetyformdata[0].formdata);
        console.log('incident obj', incidentObj);
        // incidentObj.date = viewData[0].safetyformdata[0].formdata.date;
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleSteps = (e) => {

        if (initialDetailsFilled && current == 1) {
            setshowHideDelete({ show: true });
        } else if (!props.editMode && current == 0 && !initialDetailsFilled) {
            setAlertType('failed');
            setSuccessRes(true);
            setSuccessDetail('Please fill Incident Details');
        } else if (props.editMode && current == 0) {
            // setCurrent(1);
            setAlertType('failed');
            setSuccessRes(true);
            setSuccessDetail('Please click on the Proceed button below');
        } else if (!props.editMode && initialDetailsFilled) {
            setAlertType('failed');
            setSuccessRes(true);
            setSuccessDetail('Please click on the Proceed button below');
        }
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const steps = [
        { label: 'Incident Details' },
        { label: 'Investigation Details' },
    ];

    const getDetailsById = () => {
        let body = {
            id: location.state.id
        }
        setLoading(true);
        Post(getDataById, token, body, true)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.code == 200) {
                    setViewData(data.result);
                    setIncidentObj(viewData?.[0]?.safetyformdata[0].formdata)
                    setIncidentObjVal(data.result[0]?.safetyformdata[0].formdata)
                    setImageData(data.result[0]?.image);
                    setIncidentUpdateId(data.result[0]?.safetyformdata[0].id)
                    setIncidentArrData(data.result[0]?.safetyformdata[0].incidentdata)
                    setIntialDetailsFilled(true);
                }
            });
    };

    const next = (res) => {
        setIntialDetailsFilled(true);
        setLoading(true);
        setCurrent(current + 1);
        localStorage.setItem('step_one_submitted', true);
        localStorage.setItem('userId', res.result.id);
        setStepperActivate(true);
        // dispatch(addIncident({
        //     formdata: incidentDetailsData.formdata,
        //     incidentdata: incidentDetailsData.incidentdata,
        //   }));
        current === 0 ? setSuccessDetail(`Incident details are ${props.editMode ? 'updated' : 'added'} successfully`) : (current === 1 ? setSuccessDetail(`Investigation details are ${props.editMode ? 'updated' : 'added'} successfully`) : setSuccessDetail(''));
        setAlertType('success');
        setSuccessRes(true);
        setResponseFromDetails(res);
        setLoading(false);
    }

    const items = steps.map((item) => ({
        key: item.label,
        title: item.label,
    }));

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="border_head_1">
                    <div><span className="head_1 cursor" onClick={() => { navigate('/safety') }}>Safety Dashboard</span> / <span className="head_1 cursor" onClick={() => { navigate('/event-list') }}>Event and Incident Management</span> / <span className='head_3'>{props.editMode ? 'Update Incident' : 'Add Incident'} </span></div>
                    <div className="head_2">{props.editMode ? 'Update Incident' : 'Add Incident'}</div>
                </div>
                <Steps current={current} items={items} className='margin-top-36' onChange={() => handleSteps(current)}>
                </Steps>

                {current < steps.length - 1 && (
                    <IncidentDetails
                        editMode={props.editMode ? props.editMode : stepperActivate}
                        apiData={viewData}
                        next={next}
                        incidentObjVal={incidentObjVal}
                        incidentArrData={incidentArrData}
                        incidentUpdateId={incidentUpdateId}
                    />
                )}
                {current === steps.length - 1 && (
                    <IncidentInvestigationDetails
                        editMode={props.editMode}
                        responseFromDetail={responseFromDetail}
                        incidentObjVal={incidentObjVal}
                        incidentArrData={incidentArrData}
                        incidentUpdateId={incidentUpdateId}
                    />
                )}

            </div>

            <Modal show={showHideDelete.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideDelete({ show: false, viewData: {} })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                    After proceeding to Incident Details, {props.editMode ? 'updated' : ''} Investigation Details data will be lost. Are you sure you want to continue?
                    </div>
                    <Modal.Footer className='content-center'>
                        <button type="button" className="btn-no" onClick={() => setshowHideDelete({ show: false })}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setCurrent(current - 1); setshowHideDelete({ show: false }) }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ReportIncident;
