import React from "react";
import Container from 'react-bootstrap/Container';
import UpIcon from '../../../../assets/images/Up.svg';
import DownIcon from '../../../../assets/images/Down.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./DashboardCount.css";

const DashboardCount = ({ data, filterDuration, type }) => {
    return (
            <div className="dashboard-container">
                {data && data?.map((item, index) => (
                    <div className="dashboard-item mt-2" key={index}>
                        <Container className="bg-white">
                            <Row>
                                <Col sm={3}>
                                    <span className="logo-item">{item.icon}</span>
                                </Col>
                                <Col sm={9} className="dashboard-count-heading">
                                    <p className="item-heading">{type === 'Safety'? item.incident_type : item?.type}</p>
                                    <p className="item-count">{type === 'Safety'? (item.incident_count) + '.00' : item?.count}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>
                                    <span className="item-footer-font">{item.percentage_difference}{' '}</span>
                                    <span className="item-footer-font">{item.percentage_difference > `0%` ? <img src={UpIcon} /> : item.percentage_difference < `0%` ? <img src={DownIcon} /> : ''}{`than last ${filterDuration === 'yearly' || filterDuration === 1  ? 'year' : (filterDuration === 'quarterly' || filterDuration === 2 ? 'quarter' : 'monthly')}`}</span>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                ))}
            </div>
    )
}

export default DashboardCount;