import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Get, ImagePost, Post, Put } from '../../../../constants/apiMethods';
import { getEditComplaintsAdditionalInfo, getComplaintsList, addComplaintsAdditionalInfo, uploadComplaintDocs } from '../../../../constants/apiRoutes';
import LoadingSpinner from '../../../../components/Loader';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';
import ForwardIcon from '../../../../assets/images/forwardIcon.svg';
import errorIcon from "../../../../assets/images/error_icon.svg";
import DownloadAlertImg from '../../../../assets/images/downloadImageAlert.svg';
import DownloadCompleteImg from '../../../../assets/images/downloadComplete.svg';
import ImageDownloadIcon from '../../../../assets/images/DownloadImageIcon.svg';
import "./index.css";
import { useForm } from 'react-hook-form';
import FileUploader from '../../../../components/FileUploader';
import { Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import EditUploadedFiles from '../../../../components/FileUpload/EditUploadedFiles';
import { CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT, FAILED_STATUS, IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, NA_STATUS, OTHERS_VALUE, PDF_FORMAT, SUCCESS_STATUS, TOO_LARGE_FILE, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT } from '../../../../constants/Global';
import ImagePreviewPopUp from '../../../../components/ImagePreviewPopUp';
import UploadedFileContainer from '../../../../components/FileUpload/UploadedFileContainer';
import FilePreviewFinal from '../../../../components/FileUpload/FilePreviewFinal';

const ViewAgencyComplaint = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem("access_token");
    const [viewData, setViewData] = useState();
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showAgencyData, setShowAgencyData] = useState(true);
    const [images, setImages] = useState([]);
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [ifComment2, setComment2] = useState(false);
    const [viewAdditionalInfoData, setViewAdditionalInfoData] = useState(false);
    const [imageObj, setImageObj] = useState();
    const [showEditView, setShowEditView] = useState(false);
    const [documentArr, setDocumentArr] = useState([]);
    const [docFormatType, setDocFormatType] = useState('');

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    useEffect(() => {
        getDetailsById();
    }, []);

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const getDetailsById = () => {
        setLoading(true);
        let body = {
            id: location?.state?.id
        }
        Get(getComplaintsList, token, `?id=${body.id}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setViewData(data.data);
                    if (data.data.additional_information_id != null) {
                        Get(getEditComplaintsAdditionalInfo, token, `${data.data.additional_information_id}/`, false)
                            .then((response) => response)
                            .then((data) => {
                                if (data.success) {
                                    const updatedDocuments = data?.data.additional_documents && data?.data.additional_documents.length > 0 && data?.data.additional_documents.map(doc => {
                                        const extension = doc.usd_name.split('.').pop();
                                        return { ...doc, extension };
                                    });
                                    setImages(updatedDocuments ? updatedDocuments : []);
                                    setViewAdditionalInfoData(data?.data);
                                    setValue('comment1', data?.data?.details?.comment1);
                                    setValue('comment2', data?.data?.details?.comment2);
                                }
                            });
                    }
                }
                if (!data.success) {
                    setShowSuccessMessage(data.detail);
                    setAlertType('failed');
                    setSuccessRes(true);
                }
                setLoading(false);
            });
    };

    const onSubmit = (data, e) => {
        if (location.state.id) {
            setLoading(true);
            var imagesIds = [];
            imagesIds = images?.map(res => {
                return {
                    id: res.upload_id ? res.upload_id : res.id
                }
            })

            var imageValuesArray = [];
            imageValuesArray = imagesIds?.map(obj => obj.id);

            let body = {
                complaint_id: location?.state?.id,
                details: {
                    comment1: data.comment1,
                    comment2: data.comment2,
                },
                documents: imageValuesArray,
            }

            if (viewData?.additional_information_id == null) {
                Post(addComplaintsAdditionalInfo, token, body, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('success');
                            setLoading(false);
                            setSuccessRes(true);
                            setShowEditView(showEditView);
                            getDetailsById();
                        }
                        if (!res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('failed');
                            setLoading(false);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {

                    });
            } else {
                Put(`${getEditComplaintsAdditionalInfo}/${viewData?.additional_information_id}/`, token, body, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('success');
                            setLoading(false);
                            setSuccessRes(true);
                            setShowEditView(false);
                            getDetailsById();
                        }
                        if (!res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('failed');
                            setLoading(false);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {

                    });
            }
        }
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const handleFileUpload = (files) => {
        let imageType = files[0].type;
        console.log(imageType);
        const allowedCharactersRegex = /^[a-zA-Z0-9_()-. ]+$/;

        if ([IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, PDF_FORMAT, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT, CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT].includes(imageType)) {
            setLoading(true);

            if (files[0].size > 26214400) {
                let data = {
                    formid: '000',
                    image_link: TOO_LARGE_FILE
                }
                const updatedArray = [...images, data];
                setImages(updatedArray);
                setLoading(false);
            }

            if (files[0].name && !allowedCharactersRegex.test(files[0].name)) {
                setSuccessDetail('Document name should not contain special characters!');
                setAlertType(FAILED_STATUS);
                setLoading(false);
                setSuccessRes(true);
                return;
            }

            if (files[0].size < 26214400) {
                setLoading(true);
                const formData = new FormData();
                formData.append(`file`, files[0]);
                formData.append('tag', 'Complaint Investigation');
                formData.append('usd_size', convertBytesToKb(files[0].size));
                ImagePost(uploadComplaintDocs, token, formData, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res?.detail);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            let extension = res.image_link.split('.').pop();
                            let extensionObj = { ...res, extension };
                            const updatedArray = [...images, extensionObj];
                            setImages(updatedArray);
                            const documentItemArr = [...documentArr, res.upload_id];
                            setDocumentArr(documentItemArr);
                        }
                        if (!res.success) {
                            setSuccessDetail(res.message);
                            setAlertType(FAILED_STATUS);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred while uploading image:', error);
                    });
            }
        }
        else {
            setSuccessDetail('Document type not supported!');
            setAlertType(FAILED_STATUS);
            setLoading(false);
            setSuccessRes(true);
        }
    }

    const backToGraph = () => {
        navigate('/complaints-management');
    }

    const showPreview = (item) => {
        setImageObj(item);
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        if (item?.usd_path) {
            setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
            setImageToPreview(`${imgUrl}${item?.usd_path}`)
        }
        if (item?.image_link) {
            setImageName(item?.image_name ? item?.image_name : item?.usd_name);
            setImageToPreview(`${imgUrl}${item?.image_link}`)
        }
    }

    const deleteImg = (image) => {
        if (image && image.image_link === 'too large') {
            // const currImages = images.filter((item) => (item.uploadsd_id ? item.uploadsd_id : item.id) !== data?.id);
            // setImages(currImages);
        }
        if (image && image.image_link !== 'too large') {
            setLoading(true);
            let data = {
                id: image.upload_id ? image.upload_id : image.id
            }
            setLoading(false);
            setSuccessDetail('Document Deleted Successfully');
            setAlertType('success');
            setLoading(false);
            setSuccessRes(true);
            var currImages;
            currImages = images.filter((item) => (item.upload_id ? item.upload_id : item.id) !== data.id);
            setImages(currImages);
        }
    }

    const downloadImg = (imgUrl) => {
        if (imgUrl?.image_link) {
            window.open(`${process.env.REACT_APP_IMAGE_URL + imgUrl?.image_link}`);
        }
        if (imgUrl?.usd_path) {
            window.open(`${process.env.REACT_APP_IMAGE_URL + imgUrl?.usd_path}`);
        }
    }

    return (
        <div className="full-cont">

            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={3000} alertText={successDetail} setVisibility={setVisibility} />}


            <div className="flex">
                <img className="frwrdImg cursor" src={ForwardIcon} onClick={backToGraph} />
                <div className="head_2 ml-15">
                    {`Agency Complaint by ${viewData?.first_name} ${viewData?.last_name} on ${viewData?.date_of_complaint}`}</div>
            </div>

            <div className="row inside_head_pad ml-6-">
                <div className='flex'>
                    <p className={`cursor ${showAgencyData ? 'active-plan' : 'plans'}`} onClick={() => setShowAgencyData(true)}>Agency Data</p>
                    <p className={`ml-20 cursor ${!showAgencyData ? 'active-plan' : 'plans'}`} onClick={() => setShowAgencyData(false)}>Company Added Data</p>
                </div>
            </div>

            {showAgencyData &&
                <div className='agency-div'>

                    <div className='containerHeading-complaint'>Complaint Details</div>

                    <div className='container_div'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='inputFieldHead'>Date of Complaint</div>
                                <p className="text-content">{viewData?.date_of_complaint ? viewData?.date_of_complaint : NA_STATUS}</p>
                            </div>
                            <div className='col-md-2'>
                                <div className='inputFieldHead'>Time</div>
                                <p className="text-content">{viewData?.time ? viewData?.time : NA_STATUS}</p>
                            </div>
                            <div className='col-md-3'>
                                <div className='inputFieldHead'>Location of Occurrence</div>
                                <p className="text-content">{viewData?.location === null ? viewData?.facility_name : <span>Others ({viewData?.location})</span>}</p>
                            </div>
                            <div className='col-md-3'>
                                <div className='inputFieldHead'>Complaint Type</div>
                                <p className="text-content">{viewData?.complaint_type ? (viewData?.complaint_type === OTHERS_VALUE ? `${OTHERS_VALUE} (${viewData?.complaint_type_other})` : viewData?.complaint_type) : NA_STATUS}</p>
                            </div>
                            <div className='col-md-3'>
                                <div className='inputFieldHead'>Is this an ongoing issue?</div>
                                <p className="text-content">{viewData?.complaint_status === 'Closed' ? 'No' : (viewData?.complaint_status === 'Open' ? 'Yes' : 'NA')}</p>
                            </div>
                        </div>

                    </div>

                    <div className='containerHeading-complaint'>Complainant Details</div>

                    <div className='container_div'>
                        <div className='row'>
                            <div className='col-md-2'>
                                <div className='inputFieldHead'>First Name</div>
                                <p className="text-content">{viewData?.first_name ? viewData?.first_name : NA_STATUS}</p>
                            </div>
                            <div className='col-md-2'>
                                <div className='inputFieldHead'>Last Name</div>
                                <p className="text-content">{viewData?.last_name ? viewData?.last_name : NA_STATUS}</p>
                            </div>
                            <div className='col-md-3'>
                                <div className='inputFieldHead'>Address</div>
                                <p className="text-content">{viewData?.physical_address ? viewData?.physical_address : NA_STATUS}</p>
                            </div>
                            <div className='col-md-2'>
                                <div className='inputFieldHead'>Zip Code</div>
                                <p className="text-content">{viewData?.zip_code ? viewData?.zip_code : NA_STATUS}</p>
                            </div>
                            <div className='col-md-2'>
                                <div className='inputFieldHead'>City</div>
                                <p className="text-content">{viewData?.city ? viewData?.city : NA_STATUS}</p>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col-md-2'>
                                <div className='inputFieldHead'>State</div>
                                <p className="text-content">{viewData?.state_name ? viewData?.state_name : NA_STATUS}</p>
                            </div>

                            <div className='col-md-2'>
                                <div className='inputFieldHead'>Phone Number</div>
                                <p className="text-content">{viewData?.phone_number ? normalizeInput(viewData?.phone_number) : NA_STATUS}</p>
                            </div>
                        </div>

                    </div>

                    <div className='containerHeading-complaint'>Investigation Details</div>
                    <div className='container_div'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead'>Describe Description of Location of Concern</div>
                                <p className="text-content">{viewData?.describe_location_of_concern ? viewData?.describe_location_of_concern : NA_STATUS}</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead'>Detailed Description of the Issue</div>
                                <p className="text-content">{viewData?.detailed_description_of_the_issue ? viewData?.detailed_description_of_the_issue : NA_STATUS}</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead'>Detailed Description of the Proposed Resolution</div>
                                <p className="text-content">{viewData?.detailed_description_of_the_proposed_resolution ? viewData?.detailed_description_of_the_proposed_resolution : NA_STATUS}</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead mt-3'>Supporting Documents</div>
                            </div>
                            {viewData?.supporting_documents?.length > 0 ? viewData?.supporting_documents.map((item, i) => (
                                <div className='col-md-6' key={item?.id}>
                                    <UploadedFileContainer type='uploadedImage' index={i} img={item} showPreview={showPreview} downloadImg={downloadImg}>
                                        <img src={DownloadCompleteImg} alt='DownloadCompleteImg' />
                                    </UploadedFileContainer>
                                </div>
                            )) : <div className="ml-2">No image added</div>}
                        </div>
                    </div>

                    <ImagePreviewPopUp
                        showPreviewImg={showPreviewImg}
                        setShowPreviewImg={setShowPreviewImg}
                        downloadImg={downloadImg}
                        imageName={imageName}
                        imageObj={imageObj}
                        imageToPreview={imageToPreview} />

                </div>
            }

            {!showAgencyData ?
                viewData?.additional_information_id == null || showEditView ?

                    <div className='agency-div'>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='container_div'>

                                <div className='row'>

                                    <div className='col-md-12'>
                                        <div className='inputFieldHead'>Detailed Description of the Proposed Resolution<span className='limitText'>Limit 500</span></div>
                                        <textarea
                                            type='text'
                                            className='form-control'
                                            name='comment1'
                                            placeholder='Add Details here'
                                            rows="2"
                                            {...register("comment1", { maxLength: 500 })}
                                        >
                                        </textarea>
                                        {errors.comment1 && errors.comment1.type === "maxLength" && (
                                            <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                                        )}
                                    </div>

                                    {(ifComment2 || getValues('comment2')) && <div className='col-md-12 mt-30'>
                                        <textarea
                                            type='text'
                                            className='form-control'
                                            name='comment2'
                                            placeholder='Add Details here'
                                            rows="2"
                                            {...register("comment2", { maxLength: 500 })}
                                        >
                                        </textarea>
                                        {errors.comment2 && errors.comment2.type === "maxLength" && (
                                            <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                                        )}
                                    </div>}

                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <button type="button" className='btn-add-data mr-15' onClick={() => { setComment2(!ifComment2); setValue('comment2', '') }}>{!ifComment2 ? 'Add more Comments' : 'Remove second Comment'}</button>
                                        </div>
                                    </div>


                                    <div className='col-md-6 mt-30'>
                                        <div className='inputFieldHead'>Upload Supporting Documentation</div>
                                        <FileUploader onFileUpload={handleFileUpload} />

                                        <div className='wthoutSpcl'>Up to 25 MB - File name without special characters</div>
                                    </div>

                                    <div className='col-md-6 img-container'>
                                        {images?.map((image, i) => (
                                            <div key={i}>
                                                {image.image_link === 'too large' && <EditUploadedFiles
                                                    type='alertImageUpload'
                                                    deleteImg={deleteImg}
                                                    index={i}
                                                    image={image}
                                                    showPreview={showPreview} >
                                                    <img src={DownloadAlertImg}
                                                    />
                                                </EditUploadedFiles>}
                                                {image.image_link !== 'too large' && <EditUploadedFiles
                                                    type='uploadedImage'
                                                    deleteImg={deleteImg}
                                                    index={i}
                                                    image={image}
                                                    showPreview={showPreview} >
                                                    <img src={DownloadCompleteImg}
                                                    />
                                                </EditUploadedFiles>}
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>

                            <div className='text-right'>
                                {showEditView && <button type="button" className='btn-cancel border-0 mr-15' onClick={() => { setShowEditView(false); getDetailsById() }}>Cancel</button>}
                                <button type="submit" className='btn-event-incident in-blck mt-30'>Save</button>
                            </div>
                        </form>

                    </div> :

                    <div className='container_div'>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead mb-3 mt-3'>Detailed Description of the Proposed Resolution</div>
                                <div className="mb-3">
                                    <p className="text-content"><span className='inputFieldHead'>{viewAdditionalInfoData?.details?.comment1 && '1.'} </span>{viewAdditionalInfoData?.details?.comment1 ?
                                        viewAdditionalInfoData?.details?.comment1 : NA_STATUS}</p>
                                </div>
                                {viewAdditionalInfoData?.details?.comment2 && <p className="text-content"><span className='inputFieldHead'>2. </span>{viewAdditionalInfoData?.details?.comment2 ?
                                    viewAdditionalInfoData?.details?.comment2 : NA_STATUS}</p>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead mt-3'>Supporting Documents</div>
                            </div>
                            {images?.length > 0 ? images.map((item, i) => (
                                <div className='col-md-6' key={item?.id}>
                                    <UploadedFileContainer
                                        type='uploadedImage'
                                        index={i}
                                        img={item}
                                        showPreview={showPreview}
                                        downloadImg={downloadImg}
                                    >
                                        <img src={DownloadCompleteImg} alt='DownloadCompleteImg' />
                                    </UploadedFileContainer>
                                </div>
                            )) : <div className="ml-2">No image added</div>}
                        </div>

                        <div className='text-right'>
                            <button type="button" className='btn-event-incident in-blck mt-30' onClick={() => setShowEditView(true)}>Edit</button>
                        </div>

                    </div> : ''}

            <Modal size="lg"
                dialogClassName="modal-90w"
                show={showPreviewImg}
                onHide={() => setShowPreviewImg(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                    {imageName}
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    {['pdf'].includes(docFormatType) && <FilePreviewFinal pdfFile={imageToPreview ? imageToPreview : ''} />}
                    {['jpg', 'jpeg', 'png'].includes(docFormatType) && <div className="modal-body reportText">
                        <img src={imageToPreview} className="img-preview" />
                    </div>}
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ViewAgencyComplaint;