import React, { useState } from "react";
import passHide from "../../../assets/images/passHide.svg";
import passShow from "../../../assets/images/passShow.svg";
import errorIcon from "../../../assets/images/error_icon.svg";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../../constants/apiRoutes";
import { Put } from "../../../constants/apiMethods";
import LoadingSpinner from "../../../components/Loader/index";
import '../Auth.css';
import Auth from "../Auth";
import { useNavigate, useLocation } from "react-router";
import { Modal } from "react-bootstrap";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [loading, setLoading] = useState(false);
    const [showHideResetPassword, setshowHideResetPassword] = useState(false);
    const resetPwdTitle = 'Password Reset Successfully';
    const resetPwdDesc = 'Your password has been changed successfully.';
    const resetPwdSubDesc = 'Use your new password to login.';
    const resetPwdBtnTxt = 'Login Now';
    const param1 = queryParams.get('vc');
    const param2 = queryParams.get('email');
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessNotifier, setShowSuccessNotifier] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');

    const backToLogin = () => {
        navigate('/login');
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        watch
    } = useForm();

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const onSubmit = (data) => {
        console.log(data)
        let body = {
            username: location?.state?.username ? location?.state?.username : param2,
            otp: String(location?.state?.otp ? location?.state?.otp : param1),
            password: data.password,
            confirm_password: data.confirm_password
        }
        console.log(body)
        setLoading(true);
        Put(resetPassword, null, body)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setshowHideResetPassword(true);
                }
                else {
                    setAlertType('failed');
                    setShowSuccessNotifier(true);
                    setSuccessRes(true);
                    setShowErrorMessage(res.detail);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showErrorMessage} setVisibility={setVisibility} />}
            <div className="conatainer">
                <div className="row">
                    <div className="col-md-6">
                        <Auth />
                    </div>
                    <div className="col-md-6">
                        <div className="onboarding-cont">
                            <div className="auth_form_container">
                                <div className="title_text">Reset your password</div>
                                <form className="mt-30" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-container">

                                        <div className="">
                                            <div className='inputFieldHead mt-16'>New Password</div>
                                            <input type='password'
                                                id="password"
                                                autoComplete="off"
                                                {...register("password", {
                                                    required: true,
                                                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/,
                                                })}
                                                className='form-control'
                                                placeholder='Password' />
                                            <div className="passFld">
                                                <img
                                                    src={passHide}
                                                    className="passShowIcon cursor"
                                                    alt="passIcon"
                                                    onClick={(e) => {
                                                        var div = document.getElementById("password");
                                                        if (div.type === "password") {
                                                            div.type = "text";
                                                            e.target.src = passShow;
                                                        } else {
                                                            div.type = "password";
                                                            e.target.src = passHide;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {errors.password?.type == "required" && (
                                            <p className="error"> <img src={errorIcon} alt="errorIcon" /> Password is required</p>
                                        )}
                                        {errors.password?.type == "pattern" && (
                                            <p className="error">
                                                <img src={errorIcon} alt="errorIcon" /> Password must be 8 characters long, 1 upper case, 1 lower case, 1 special character, 1 numeral
                                            </p>
                                        )}

                                        <div className="">
                                            <div className='inputFieldHead mt-16'>Confirm Password</div>
                                            <input type='password'
                                                id='confirm_password'
                                                autoComplete="off"
                                                {...register("confirm_password", {
                                                    required: true,
                                                })}
                                                className='form-control'
                                                placeholder='Password' />
                                            <div className="passFld">
                                                <img
                                                    src={passHide}
                                                    className="passShowIcon cursor"
                                                    alt="passIcon"
                                                    onClick={(e) => {
                                                        var div = document.getElementById("confirm_password");
                                                        if (div.type === "password") {
                                                            div.type = "text";
                                                            e.target.src = passShow;
                                                        } else {
                                                            div.type = "password";
                                                            e.target.src = passHide;
                                                        }
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        {errors.confirm_password?.type == "required" && (
                                            <p className="error">
                                                <img src={errorIcon} alt="errorIcon" /> Confirm Password is required </p>
                                        )}

                                        {watch("confirm_password") !== watch("password") && getValues("confirm_password") && (
                                            <p className="error">
                                                <img src={errorIcon} alt="errorIcon" /> Password and Confirm Password doesn't match </p>
                                        )}

                                        <div className="text-center mt-30">
                                            {/* <button type="submit" className="btn-auth-action border-0 mb-2" onClick={() => setshowHideResetPassword(true)}>Reset Password</button> */}
                                            <button type="submit" className="btn-auth-action border-0 mb-2">Reset Password</button>
                                            <div className="back-to-login cursor text-center mt-30" onClick={backToLogin}>Back to login</div>
                                        </div>

                                        <Modal show={showHideResetPassword} dialogClassName="reportConfrmModal" onHide={() => setshowHideResetPassword(false)}>
                                            <Modal.Header className="sideOnRight" closeButton>
                                            </Modal.Header>
                                            <Modal.Body className="auth_modal_header border-0 mx-auto">
                                                <div className='auth_modal_heading mb-3'>{resetPwdTitle}</div>
                                                <div className='auth_modal_descrip'>{resetPwdDesc}
                                                </div>
                                                <div className='auth_modal_descrip mb-1'>{resetPwdSubDesc}
                                                </div>
                                                <Modal.Footer>
                                                    <button type="button" className="btn-auth-action border-0 wd-v-p mb-5" onClick={backToLogin}>{resetPwdBtnTxt}</button>
                                                </Modal.Footer>
                                            </Modal.Body>
                                        </Modal>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
