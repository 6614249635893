import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FadedDownloadDoc from '../../../../assets/images/FormImages/fadedDownloadIcon.svg';
import EyeIcon from '../../../../assets/images/EyeIcon.svg';
import ForwardPagination from '../../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../../assets/images/BackwardPagination.svg';
import './index.css';
import { getComplaintsList } from '../../../../constants/apiRoutes';
import { Get } from '../../../../constants/apiMethods';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import Tooltip from '../../../../components/Tooltip/index';

const ComplaintsDataModal = ({ selectedYear, modalHeading }) => {
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const [showAgencyComplaints, setShowAgencyComplaints] = useState();
  const [yearComplaintsList, setYearComplaintsList] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (modalHeading === 'Agency Complaint') {
      setShowAgencyComplaints(true);
      getComplaintsListing(1, selectedYear, true);
      setShowAgencyComplaints(true);
    }
    if (modalHeading === 'Direct Complaint') {
      setShowAgencyComplaints(false);
      getComplaintsListing(1, selectedYear, false);
      setShowAgencyComplaints(false);
    }

  }, []);

  const getComplaintsListing = (page, year, agency) => {
    Get(getComplaintsList, token, `?page=${page}&year=${year}&agency=${agency}`, true)
      .then((response) => response)
      .then((data) => {
        if (data.success) {
          setYearComplaintsList(data?.data);
          setCurrentPage(data.paginator);
        }
      });
  };

  const pageChange = (page) => {
    setPage(page + 1);
    getComplaintsListing(page + 1, selectedYear, showAgencyComplaints);
  }

  const onViewComplaintDetails = (id) => {
    if (!showAgencyComplaints) {
      if (modalHeading === 'Direct Complaint') {
        navigate('../view-direct-complaint', { state: { id: id } });
      }
    } else {
      navigate('../view-agency-complaint', { state: { id: modalHeading === 'Agency Complaint' ? id : 0, year: selectedYear, modalHeading: modalHeading } });
    }
  }

  const truncateString = (str, maxLength) => {
    if (!str) {
      return '';
    }

    return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
  };

  return (

    <>
      <div className='flex headHeight'>
        <div className='ml-19'><span className='listHead'>Year: </span><span className='listData'>{selectedYear}</span> </div>
        <div className='ml-27'><span className='listHead'>{`Total Number of ${modalHeading}s: `}</span><span className='listData'>{currentPage?.total_count}</span></div>
      </div>

      <div className="table-responsive mt-10 w-900">
        <table className="table">
          <thead>
            <tr>
              {!showAgencyComplaints && <th scope="col">Complaint Name</th>}
              <th scope="col">Date of Complaint</th>
              <th scope="col">Location of Occurrence</th>
              <th scope="col">Complaint Type</th>
              {showAgencyComplaints && <th scope="col">Complainant Name</th>}
              {showAgencyComplaints && <th scope="col">Phone Number</th>}
              {!showAgencyComplaints && <th scope="col">Complaint Status</th>}
              {!showAgencyComplaints && <th scope="col">Complainant Name</th>}
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>

            {yearComplaintsList?.length > 0 && yearComplaintsList?.map((item, index) => {
              return (
                <tr className="brdrtop" key={index + 1}>
                  {!showAgencyComplaints && <td><Tooltip text={item?.complaint_receiver ? item?.complaint_receiver : 'NA'}>
                    {item?.complaint_receiver ? item?.complaint_receiver : 'NA'}
                  </Tooltip></td>}
                  <td>{(item?.date_of_complaint ? moment(item?.date_of_complaint).format('MM-DD-YYYY') : 'NA')}</td>
                  <td>
                    <Tooltip text={item?.facility_name || item?.location ? (item?.facility_name || `Others (${item?.location})`) : 'NA'}>
                      <span>{item?.facility_name || item?.location ? (truncateString(item?.facility_name || `Others (${item?.location})`, 15)) : 'NA'}</span>
                    </Tooltip>
                  </td>
                  <td><Tooltip text={item?.complaint_type ? item?.complaint_type : 'NA'}>
                    {truncateString(item?.complaint_type, 10) ? truncateString(item?.complaint_type, 10) : 'NA'}
                  </Tooltip></td>
                  {!showAgencyComplaints && <td className={item?.complaint_status === 'Open' ? 'text-alert' : item?.complaint_status === 'Closed' ? 'text-success' : ''}>{item?.complaint_status ? item?.complaint_status : 'NA'}</td>}
                  <td>{item?.complainant_name ? item?.complainant_name : 'NA'}</td>
                  {showAgencyComplaints && <td>{item?.phone_number ? item?.phone_number : 'NA'}</td>}

                  <td className='wider-td'>
                    <img className="editIcn" src={FadedDownloadDoc} />
                    <img src={EyeIcon} className='editIcn cursor' onClick={() => onViewComplaintDetails(item?.id)} />
                  </td>
                </tr>
              );
            })}

          </tbody>
        </table>
        {yearComplaintsList?.length === 0 && <div className="row">
          <div className="col-md-12">
            <div className="text-center no-data">No Data Found</div>
          </div>
        </div>}
      </div>
      {yearComplaintsList?.length > 0 && <ReactPaginate
        forcePage={currentPage.current_page - 1}
        pageCount={currentPage.total_count / 5}
        marginPagesDisplayed={2}
        onPageChange={(e) => pageChange(e.selected)}
        containerClassName={'pagination-body'}
        previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
        nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
        previousLinkClassName={'page'}
        breakClassName={'page'}
        nextLinkClassName={'page'}
        pageClassName={'page'}
        disabledClassName={'disabled'}
        activeClassName={'active'} />}
    </>

  )
}

export default ComplaintsDataModal;