import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../../../../components/Loader";
import AlertNotifier from "../../../../../components/Notifications/AlertNotifier";
import data from '../../../../../mockData/mockData';
import errorIcon from "../../../../../assets/images/error_icon.svg";
import DownloadAlertImg from '../../../../../assets/images/downloadImageAlert.svg';
import DownloadCompleteImg from '../../../../../assets/images/downloadComplete.svg';
import { useForm } from "react-hook-form";
import { Delete, Delete3, Get, ImagePost, Post, Put } from "../../../../../constants/apiMethods";
import { getComplaintsList, addUpdateComplaintInvestigation, uploadComplaintDocs, complaintBaseUrl } from "../../../../../constants/apiRoutes";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import './index.css';
import FileUploader from "../../../../../components/FileUploader";
import EditUploadedFiles from "../../../../../components/FileUpload/EditUploadedFiles";
// import { pdfjs } from 'react-pdf';

import {
    NULL_VALUE,
    FAILED_STATUS,
    SUBMITTED_STATUS,
    ACCESS_TOKEN,
    SUCCESS_STATUS,
    IMAGE_JPEG_FORMAT,
    IMAGE_JPG_FORMAT,
    TOO_LARGE_FILE,
    IMAGE_PNG_FORMAT,
    PDF_FORMAT,
    MAXLENGTH_VALIDATION,
    XLSX_FILE_FORMAT,
    XLSX_FILE_SPREADSHEET_FORMAT,
    XLSX_VND_FORMAT,
    CSV_FILE_FORMAT,
    DOCX_FILE_FORMAT,
    DOC_MS_WORD_FILE_FORMAT
} from '../../../../../constants/Global';
import {
    DRAFT_STATUS,
    COMPLAINT_ID,
    DESCRIBE_LOCATION_KEY,
    DETAILED_DESCRIPTION_KEY,
    DETAILED_DESCRIPTION_RESOLUTION,
} from '../../../../../constants/Complaints';
import FilePreview from "../../../../../components/FileUpload/FilePreview";
import FilePreviewFinal from "../../../../../components/FileUpload/FilePreviewFinal";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

const ComplaintInvestigationDetails = ({ editMode, responseFromDetail, isDraftMode }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [successDetail, setSuccessDetail] = useState('');
    const [images, setImages] = useState([]);
    const [filesArr, setFilesArr] = useState([]);
    const inputFile = useRef(null);
    const [showHideAddUpdateComplaint, setshowHideAddUpdatePopup] = useState(false);
    const [showConfirmDeletePopUp, setShowConfirmDeletePopUp] = useState(false);
    const [recordStatus, setRecordStatus] = useState('');
    const [viewData, setViewData] = useState();
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [docFormatType, setDocFormatType] = useState('');
    const [changeEditMode, setEditMode] = useState(editMode);
    const [showCancelModal, setshowHideAddCancelPopup] = useState(false);
    const [documentArr, setDocumentArr] = useState([]);
    const [currentImgContext, setCurrentImgContext] = useState();
    const token = localStorage.getItem(ACCESS_TOKEN);
    const [isDraft, setIsDraft] = useState();

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    useEffect(() => {
        if (isDraftMode === DRAFT_STATUS) {
            setEditMode(true);
        }
        (editMode || isDraftMode === DRAFT_STATUS) && getDetailsById();
    }, [])

    const getDetailsById = () => {
        setLoading(true);
        Get(getComplaintsList, token, '?id=' + responseFromDetail?.complaint_id, false)
            .then((response) => response)
            .then((resp) => {
                setLoading(false)
                if (resp.success) {
                    setViewData(resp?.data);
                    setValue(DESCRIBE_LOCATION_KEY, resp?.data?.describe_location_of_concern);
                    setValue(DETAILED_DESCRIPTION_KEY, resp?.data?.detailed_description_of_the_issue);
                    setValue(DETAILED_DESCRIPTION_RESOLUTION, resp?.data?.detailed_description_of_the_proposed_resolution);
                    setIsDraft(resp?.data?.record_status);
                    const updatedDocuments = resp?.data?.supporting_documents && resp?.data?.supporting_documents.length > 0 && resp?.data?.supporting_documents.map(doc => {
                        const extension = doc.usd_name.split('.').pop();
                        return { ...doc, extension };
                    });
                    setImages(updatedDocuments ? updatedDocuments : []);

                    if (resp?.data?.supporting_documents) {
                        resp.data.supporting_documents.forEach((item) => {
                            documentArr.push(item.id);
                        });
                    }
                }
            })
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        setshowHideAddUpdatePopup(true);
    }

    const onSubmitInvestigationDetails = (status) => {
        setRecordStatus(status);
        setLoading(true);
        const body = {
            complaint_id: Number(localStorage.getItem(COMPLAINT_ID)),
            describe_location_of_concern: getValues(DESCRIBE_LOCATION_KEY) ? getValues(DESCRIBE_LOCATION_KEY) : '',
            detailed_description_of_the_issue: getValues(DETAILED_DESCRIPTION_KEY) ? getValues(DETAILED_DESCRIPTION_KEY) : '',
            detailed_description_of_the_proposed_resolution: getValues(DETAILED_DESCRIPTION_RESOLUTION) ? getValues(DETAILED_DESCRIPTION_RESOLUTION) : '',
            record_status: status,
            documents: documentArr
        }

        if (!changeEditMode) {
            Post(addUpdateComplaintInvestigation, token, body, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (status === DRAFT_STATUS) {
                            navigate('/complaints-management');
                        } else {
                            setSuccessDetail(data.detail);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            setTimeout(() => {
                                navigate('/complaints-management');
                                setLoading(false);
                            }, 2000)
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType(FAILED_STATUS);
                        setSuccessRes(true);
                    }
                });
        } else {
            (viewData?.investigation_id ? Put(addUpdateComplaintInvestigation + viewData?.investigation_id, token, body, false) : Post(addUpdateComplaintInvestigation, token, body, false))
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        if (status === DRAFT_STATUS) {
                            navigate('/complaints-management');
                        } else {
                            setSuccessDetail(data.detail);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            setTimeout(() => {
                                navigate('/complaints-management');
                                setLoading(false);
                            }, 2000)
                        }
                    } else {
                        setSuccessDetail(data.detail);
                        setAlertType(FAILED_STATUS);
                        setSuccessRes(true);
                    }
                });
        }
    }

    const onFileChange = (e) => {
        let imageType = e.target.files[0].type;
        if (imageType === IMAGE_JPEG_FORMAT || imageType === IMAGE_JPG_FORMAT) {
            let itemArr = [...filesArr];
            const filePath = handleFileUpload(e);
            setFilesArr((prevElements) => [...prevElements, filePath]);
            itemArr.push(filePath);

            if (inputFile.current) {
                inputFile.current.value = null;
            }
        }
    }

    const handleFileUpload = (files) => {
        let imageType = files[0].type;
        const allowedCharactersRegex = /^[a-zA-Z0-9_()-. ]+$/;

        if ([IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, PDF_FORMAT, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT, CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT].includes(imageType)) {
            setLoading(true);

            if (files[0].size > 26214400) {
                let data = {
                    formid: '000',
                    image_link: TOO_LARGE_FILE
                }
                const updatedArray = [...images, data];
                setImages(updatedArray);
                setLoading(false);
            }

            if (files[0].name && !allowedCharactersRegex.test(files[0].name)) {
                setSuccessDetail('Document name should not contain special characters!');
                setAlertType(FAILED_STATUS);
                setLoading(false);
                setSuccessRes(true);
                return;
            }

            if (files[0].size < 26214400) {
                setLoading(true);
                const formData = new FormData();
                formData.append(`file`, files[0]);
                formData.append('tag', 'Complaint Investigation');
                formData.append('usd_size', convertBytesToKb(files[0].size));
                ImagePost(uploadComplaintDocs, token, formData, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res?.detail);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            let extension = res.image_link.split('.').pop();
                            let extensionObj = { ...res, extension };
                            const updatedArray = [...images, extensionObj];
                            setImages(updatedArray);
                            const documentItemArr = [...documentArr, res.upload_id];
                            setDocumentArr(documentItemArr);
                        }
                        if (!res.success) {
                            setSuccessDetail(res.message);
                            setAlertType(FAILED_STATUS);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred while uploading image:', error);
                    });
            }
        }
        else {
            setSuccessDetail('Document type not supported!');
            setAlertType(FAILED_STATUS);
            setLoading(false);
            setSuccessRes(true);
        }
    }

    const deleteImg = (image) => {
        if (image && image.image_link === TOO_LARGE_FILE) {
            const currImages = images.filter((item) => (item.upload_id ? item.upload_id : item.id) !== data.id);
            setImages(currImages);
        }
        if (image && image.image_link !== TOO_LARGE_FILE) {
            setLoading(true);
            let data = {
                id: image.upload_id ? image.upload_id : image.id
            }
            Delete3(uploadComplaintDocs + data.id, token, '', true)
                .then((res) => {
                    setLoading(false);
                    if (res.success) {
                        setSuccessDetail(res?.detail);
                        setAlertType(SUCCESS_STATUS);
                        setLoading(false);
                        setSuccessRes(true);
                        let currImages;
                        let docArr;
                        currImages = images.filter((item) => (item.upload_id ? item.upload_id : item.id) !== data.id);
                        docArr = documentArr.filter((item) => (item !== image.upload_id));
                        setImages(currImages);
                        setDocumentArr(docArr);
                    }
                    if (!res.success) {
                        setSuccessDetail(res.detail);
                        setAlertType(FAILED_STATUS);
                        setSuccessRes(true);
                    }
                    inputFile.current.value = NULL_VALUE;
                })
                .catch((error) => {
                });
        }
    }

    const onCancel = () => {
        if (!changeEditMode) {
            Delete(complaintBaseUrl + responseFromDetail?.complaint_id + '/?is_cancel=true', token, '', true)
                .then((res) => {
                    setLoading(false);
                    if (res.success) {
                        navigate('/complaints-management');
                    }
                })
                .catch((error) => {
                });
        } else {
            navigate('/complaints-management');
        }
    }

    const showPreview = (item) => {
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        if (item?.usd_path) {
            setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
            setImageToPreview(`${imgUrl}${item?.usd_path}`)
        }
        if (item?.image_link) {
            setImageName(item?.image_name ? item?.image_name : item?.usd_name);
            setImageToPreview(`${imgUrl}${item?.image_link}`)
        }
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    return (
        <>

            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container_div'>

                    <div className='row'>

                        <div className='col-md-12'>
                            <div className='inputFieldHead'>Describe Location of concern<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='describe_location_of_concern'
                                placeholder='Add Details here'
                                rows="3"
                                {...register(DESCRIBE_LOCATION_KEY, { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.describe_location_of_concern && errors.describe_location_of_concern.type === MAXLENGTH_VALIDATION && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Detailed Description of the Issue<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='detailed_description_of_the_issue'
                                placeholder='Add Details here'
                                rows="3"
                                {...register(DETAILED_DESCRIPTION_KEY, { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.detailed_description_of_the_issue && errors.detailed_description_of_the_issue.type === MAXLENGTH_VALIDATION && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-12 mt-30'>
                            <div className='inputFieldHead'>Detailed Description of the Proposed Resolution<span className='limitText'>Limit 500</span></div>
                            <textarea
                                type='text'
                                className='form-control'
                                name='detailed_description_of_the_proposed_resolution'
                                placeholder='Add Details here'
                                rows="3"
                                {...register(DETAILED_DESCRIPTION_RESOLUTION, { maxLength: 500 })}
                            >
                            </textarea>
                            {errors.detailed_description_of_the_proposed_resolution && errors.detailed_description_of_the_proposed_resolution.type === MAXLENGTH_VALIDATION && (
                                <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                            )}
                        </div>

                        <div className='col-md-6 mt-30'>
                            <div className='inputFieldHead'>Upload Supporting Documentation</div>
                            <FileUploader onFileUpload={handleFileUpload} />

                            <div className='wthoutSpcl'>Up to 25 MB - File name without special characters</div>
                        </div>

                        <div className='col-md-6 img-container'>
                            {images?.map((image, i) => (
                                <div key={i}>
                                    {image.image_link === TOO_LARGE_FILE &&
                                        <EditUploadedFiles
                                            type='alertImageUpload'
                                            deleteImg={deleteImg}
                                            index={i}
                                            image={image}
                                            showPreview={showPreview} >
                                            <img src={DownloadAlertImg}
                                            />
                                        </EditUploadedFiles>}
                                    {image.image_link !== TOO_LARGE_FILE &&
                                        <EditUploadedFiles
                                            type='uploadedImage'
                                            deleteImg={deleteImg}
                                            index={i}
                                            image={image}
                                            showPreview={showPreview}
                                        >
                                            <img src={DownloadCompleteImg}
                                            />
                                        </EditUploadedFiles>
                                    }
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

                <div className='text-right'>
                    <button type="button" className='btn-cancel border-0 mr-15' onClick={() => setshowHideAddCancelPopup(true)}>Cancel</button>
                    {isDraft !== SUBMITTED_STATUS && <button type="button" className='btn-add-data mr-15' onClick={() => { onSubmitInvestigationDetails(DRAFT_STATUS) }}>Save As Draft</button>}
                    <button type="submit" className='btn-event-incident in-blck mt-30' onClick={() => setRecordStatus(SUBMITTED_STATUS)}>{!editMode ? 'Submit Complaint' : 'Update Complaint'}</button>
                </div>
            </form>

            <Modal show={showHideAddUpdateComplaint} dialogClassName="reportConfrmModal" onHide={() => setshowHideAddUpdatePopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {!editMode ? 'add this Complaint' : 'update the Complaint'}?
                    </div>
                    <Modal.Footer className="js-center">
                        <button type="button" className="btn-no" onClick={() => setshowHideAddUpdatePopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setshowHideAddUpdatePopup(false); onSubmitInvestigationDetails(SUBMITTED_STATUS) }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <Modal size="lg"
                dialogClassName="modal-90w"
                show={showPreviewImg}
                onHide={() => setShowPreviewImg(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                    {imageName}
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    {['pdf'].includes(docFormatType) && <FilePreviewFinal pdfFile={imageToPreview ? imageToPreview : ''} />}
                    {['jpg', 'jpeg', 'png'].includes(docFormatType) && <div className="modal-body reportText">
                        <img src={imageToPreview} className="img-preview" />
                    </div>}
                </Modal.Body>
            </Modal>

            <Modal show={showCancelModal} dialogClassName="reportConfrmModal" onHide={() => setshowHideAddCancelPopup(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText width_text">
                        <div>Are you sure you want to cancel it ?</div>
                        <div className="mt-2">By canceling, you will lose all the information you've {!changeEditMode ? 'entered' : 'updated'}.</div>
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideAddCancelPopup(false)}>No</button>
                        <button type="button" className="btn-yes" onClick={() => { setshowHideAddCancelPopup(false); onCancel() }}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ComplaintInvestigationDetails;