import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Delete, Get, ImagePost, Post, Put } from '../../../../constants/apiMethods';
import { getSpillsAdditionalInfo, getSpillsList, spillsAdditionalInfo, uploadImageSpill } from '../../../../constants/apiRoutes';
import LoadingSpinner from '../../../../components/Loader';
import AlertNotifier from '../../../../components/Notifications/AlertNotifier';
import ForwardIcon from '../../../../assets/images/forwardIcon.svg';
import errorIcon from "../../../../assets/images/error_icon.svg";
import ImagePreviewIcon from '../../../../assets/images/PreviewImageIcon.svg';
import CrossImg from '../../../../assets/images/crossImage.svg';
import DeleteImg from '../../../../assets/images/deleteImg.svg';
import AlertIcon from '../../../../assets/images/FormImages/alert.svg';
import DownloadAlertImg from '../../../../assets/images/downloadImageAlert.svg';
import DownloadCompleteImg from '../../../../assets/images/downloadComplete.svg';
import ImageDownloadIcon from '../../../../assets/images/DownloadImageIcon.svg';
import "./ViewAgencySpill.css";
import moment from 'moment';
import { useForm } from 'react-hook-form';
import FileUploader from '../../../../components/FileUploader';
import { Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import EditUploadedFiles from '../../../../components/FileUpload/EditUploadedFiles';
import UploadedFileContainer from '../../../../components/FileUpload/UploadedFileContainer';
import FilePreviewFinal from '../../../../components/FileUpload/FilePreviewFinal';
import { CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT, FAILED_STATUS, IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, NA_STATUS, PDF_FORMAT, SUCCESS_STATUS, TOO_LARGE_FILE, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT } from '../../../../constants/Global';

const ViewAgencySpill = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const token = localStorage.getItem("access_token");
    const [viewData, setViewData] = useState();
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showAgencyData, setShowAgencyData] = useState(true);
    const [images, setImages] = useState([]);
    const [showPreviewImg, setShowPreviewImg] = useState(false);
    const [imageName, setImageName] = useState('');
    const [imageToPreview, setImageToPreview] = useState('');
    const [ifComment2, setComment2] = useState(false);
    const [viewAdditionalInfoData, setViewAdditionalInfoData] = useState(false);
    const [imageObj, setImageObj] = useState();
    const [showEditView, setShowEditView] = useState(false);
    const [docFormatType, setDocFormatType] = useState('');
    const [documentArr, setDocumentArr] = useState([]);
    const inputFile = useRef(null);

    const imgUrl = process.env.REACT_APP_IMAGE_URL;

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
        getValues,
        control,
        trigger
    } = useForm();

    useEffect(() => {
        getDetailsById();
        console.log(location?.state?.modalHeading)
    }, []);

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const getDetailsById = () => {
        let body = {
            id: location?.state?.id
        }
        setLoading(true);
        Get(getSpillsList, token, `?id=${body.id}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setViewData(data.data);
                    if (data.data.additional_information_id != null) {
                        Get(getSpillsAdditionalInfo, token, `${data.data.additional_information_id}/`, false)
                            .then((response) => response)
                            .then((data) => {
                                if (data.success) {
                                    setViewAdditionalInfoData(data?.data);
                                    setValue('comment1', data?.data?.details?.comment1);
                                    setValue('comment2', data?.data?.details?.comment2);
                                    const updatedDocuments = data?.data.additional_documents && data?.data.additional_documents.length > 0 && data?.data.additional_documents.map(doc => {
                                        const extension = doc.usd_name.split('.').pop();
                                        return { ...doc, extension };
                                    });
                                    setImages(updatedDocuments ? updatedDocuments : []);
                                    // setImages(data?.data?.additional_documents);
                                }
                            });
                    }
                }
            });
    };

    const onSubmit = (data, e) => {
        if (location.state.id) {
            setLoading(true);
            var imagesIds = [];
            imagesIds = images?.map(res => {
                return {
                    id: res.uploadsd_id ? res.uploadsd_id : res.id
                }
            })

            var imageValuesArray = [];
            imageValuesArray = imagesIds?.map(obj => obj.id);

            let body = {
                spill_id: location?.state?.id,
                details: {
                    comment1: data.comment1,
                    comment2: data.comment2,
                },
                documents: imageValuesArray,
            }

            if (viewData?.additional_information_id == null) {
                Post(spillsAdditionalInfo, token, body, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('success');
                            setLoading(false);
                            setSuccessRes(true);
                            setShowEditView(showEditView);
                            getDetailsById();
                        }
                        if (!res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('failed');
                            setLoading(false);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {

                    });
            } else {
                Put(`${getSpillsAdditionalInfo}/${viewData?.additional_information_id}/`, token, body, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('success');
                            setLoading(false);
                            setSuccessRes(true);
                            setShowEditView(false);
                            getDetailsById();
                        }
                        if (!res.success) {
                            setSuccessDetail(res.detail);
                            setAlertType('failed');
                            setLoading(false);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {

                    });
            }
        }
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    // const handleFileUpload = (files) => {
    //     let imageType = files[0].type;
    //     if (imageType == 'image/jpeg' || imageType == 'image/jpg') {
    //         setLoading(true);
    //         if (files[0].size > 26214400) {
    //             let data = {
    //                 // id: generateTwoDigitRandomId(),
    //                 formid: '000',
    //                 image_link: 'too large'
    //             }
    //             const updatedArray = [...images, data];
    //             setImages(updatedArray);
    //             setLoading(false);
    //         }
    //         if (files[0].size < 26214400) {
    //             setLoading(true);
    //             const formData = new FormData();
    //             formData.append(`file`, files[0]);
    //             formData.append('tag', 'Additional Info Spill');
    //             formData.append('usd_size', convertBytesToKb(files[0].size));
    //             ImagePost(uploadImageSpill, token, formData, true)
    //                 .then((res) => {
    //                     setLoading(false);
    //                     if (res.success) {
    //                         setSuccessDetail('Image added successfully!');
    //                         setAlertType('success');
    //                         setLoading(false);
    //                         setSuccessRes(true);
    //                         const updatedArray = [...images, res];
    //                         setImages(updatedArray);
    //                     }
    //                     if (!res.success) {
    //                         setSuccessDetail(res.message);
    //                         setAlertType('failed');
    //                         setLoading(false);
    //                         setSuccessRes(true);
    //                     }
    //                 })
    //                 .catch((error) => {

    //                 });
    //         }
    //     }
    // }

    const handleFileUpload = (files) => {
        let imageType = files[0].type;
        console.log(imageType);
        const allowedCharactersRegex = /^[a-zA-Z0-9_()-. ]+$/;

        if ([IMAGE_JPEG_FORMAT, IMAGE_JPG_FORMAT, IMAGE_PNG_FORMAT, PDF_FORMAT, XLSX_FILE_FORMAT, XLSX_FILE_SPREADSHEET_FORMAT, XLSX_VND_FORMAT, CSV_FILE_FORMAT, DOCX_FILE_FORMAT, DOC_MS_WORD_FILE_FORMAT].includes(imageType)) {
            setLoading(true);

            if (files[0].size > 26214400) {
                let data = {
                    formid: '000',
                    image_link: TOO_LARGE_FILE
                }
                const updatedArray = [...images, data];
                setImages(updatedArray);
                setLoading(false);
            }

            if (files[0].name && !allowedCharactersRegex.test(files[0].name)) {
                setSuccessDetail('Document name should not contain special characters!');
                setAlertType(FAILED_STATUS);
                setLoading(false);
                setSuccessRes(true);
                return;
            }

            if (files[0].size < 26214400) {
                setLoading(true);
                const formData = new FormData();
                formData.append(`file`, files[0]);
                formData.append('tag', 'Additional Info Spill');
                formData.append('usd_size', convertBytesToKb(files[0].size));
                ImagePost(uploadImageSpill, token, formData, true)
                    .then((res) => {
                        setLoading(false);
                        if (res.success) {
                            setSuccessDetail(res?.detail);
                            setAlertType(SUCCESS_STATUS);
                            setSuccessRes(true);
                            let extension = res.image_link.split('.').pop();
                            let extensionObj = { ...res, extension };
                            const updatedArray = [...images, extensionObj];
                            setImages(updatedArray);
                            const documentItemArr = [...documentArr, res.upload_id];
                            setDocumentArr(documentItemArr);
                        }
                        if (!res.success) {
                            setSuccessDetail(res.message);
                            setAlertType(FAILED_STATUS);
                            setSuccessRes(true);
                        }
                    })
                    .catch((error) => {
                        console.error('Error occurred while uploading image:', error);
                    });
            }
        }
        else {
            setSuccessDetail('Document type not supported!');
            setAlertType(FAILED_STATUS);
            setLoading(false);
            setSuccessRes(true);
        }
    }

    const backToGraph = () => {
        navigate('/spills-dashboard');
    }

    const showPreview = (item, index) => {
        // setImageObj(item);
        // setShowPreviewImg(true);
        // setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
        // setImageToPreview(`${imgUrl}${item?.usd_path ? item?.usd_path : item?.image_link}`)
        setImageObj(item);
        setShowPreviewImg(true);
        setDocFormatType(item?.extension);
        if (item?.usd_path) {
            setImageName(item?.usd_name ? item?.usd_name : item?.image_name);
            setImageToPreview(`${imgUrl}${item?.usd_path}`)
        }
        if (item?.image_link) {
            setImageName(item?.image_name ? item?.image_name : item?.usd_name);
            setImageToPreview(`${imgUrl}${item?.image_link}`)
        }
    }

    const deleteImg = (image) => {
        if (image && image.image_link === 'too large') {
            // const currImages = images.filter((item) => (item.uploadsd_id ? item.uploadsd_id : item.id) !== data?.id);
            // setImages(currImages);
        }
        if (image && image.image_link !== 'too large') {
            setLoading(true);
            let data = {
                id: image.uploadsd_id ? image.uploadsd_id : image.id
            }
            // Delete(uploadImageSpill + data.id, token, '', true)
            //     .then((res) => {
                    setLoading(false);
                    // if (res.success) {
                        setSuccessDetail('Document Deleted Successfully');
                        setAlertType('success');
                        setLoading(false);
                        setSuccessRes(true);
                        var currImages;
                        // if (!changeEditMode) {
                        currImages = images.filter((item) => (item.uploadsd_id ? item.uploadsd_id : item.id) !== data.id);
                        // } else {
                        //     currImages = images.filter((item) => item.id !== image.id);
                        // }

                        setImages(currImages);
                    // }
                    // if (!res.success) {
                    //     setSuccessDetail(res.detail);
                    //     setAlertType('failed');
                    //     setSuccessRes(true);
                    // }
                    // inputFile.current.value = null;
                // })
                // .catch((error) => {
                // });
        }
    }

    const downloadImg = (imgUrl) => {
        // saveAs(`${imgUrl?.usd_path}`, 'image.jpg')
        saveAs(`${process.env.REACT_APP_IMAGE_URL + imgUrl?.usd_path}`);
    }

    const UploadContainer = (props) => {
        return (
            <>
                <div className={`row imageBoxViewWidth margin-left-0 margin-bottom-20" ${props.type == 'imageProcessing' ? 'percentProcess' : props.type == 'uploadedImage' ? 'imageUpload' : props.type == 'alertImageUpload' ? 'alertUpload' : ''}`}>
                    <div className={`col-md-2 ${props.type == 'imageProcessing' ? 'bgPercentProcessColor' : props.type == 'uploadedImage' ? 'bgUploadCompleted' : props.type == 'alertImageUpload' ? 'bgAlertCompleted' : ''}`}>
                        <div>{props.children}</div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="imageName">{props?.image?.usd_name ? props?.image?.usd_name : (props.image?.image_name) ? (props.image?.image_name) : props?.img?.usd_name}</div>
                                <div className="imageSize">{props?.image?.usd_size ? props?.image?.usd_size : (props.image?.image_size) ? props.image?.image_size : props?.img?.usd_size}KB</div>
                            </div>
                            <div className="col-md-2 flex contentCenter">
                                <img src={ImagePreviewIcon} className="px-2 cursor" onClick={() => showPreview(props?.image ? props?.image : props?.img, props.index)} />
                                {props?.image && <img className='cursor' src={props.type == 'imageProcessing' ? CrossImg : DeleteImg} onClick={() => deleteImg(props.image)} />}
                                {props?.download == 'downloadImg' && <img src={ImageDownloadIcon} className="cursor" onClick={() => { downloadImg(props?.img) }} />}
                            </div>
                        </div>
                    </div>
                </div>
                {props.type == 'alertImageUpload' &&
                    <div className=''>
                        <img className="" src={AlertIcon} />
                        <span className='alertMsgs'>File is too large (max. 25 MB)</span>
                    </div>
                }
            </>
        );
    }

    return (
        <div className="full-cont">

            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={3000} alertText={successDetail} setVisibility={setVisibility} />}


            <div className="flex">
                <img className="frwrdImg cursor" src={ForwardIcon} onClick={backToGraph} />
                <div className="head_2 ml-15">
                    {`${location?.state?.modalHeading == 'Reportable Spills Total' ? 'Reportable Spill' : location?.state?.modalHeading == 'Historical Spills Total'
                        ? 'Historical Spill' : location?.state?.modalHeading == 'Recent Spills Total' ? 'Recent Spill' : location?.state?.modalHeading == 'Total Volume of Oil Spill' ? 'Oil Spill Volume'
                            : location?.state?.modalHeading == 'Total Volume of Produced Water' ? 'Produced Water Spill Volume' : 'View Agency Spill'} - ${viewData?.name ? viewData?.name : ''} 
                    ${viewData?.date_of_discovery ? NA_STATUS : ''} ${viewData?.date_of_discovery ? moment(viewData?.date_of_discovery).format('MM-DD-YYYY') : ''}`}</div>
            </div>

            <div className="row inside_head_pad ml-6-">
                <div className='flex'>
                    <p className={`cursor ${showAgencyData ? 'active-plan' : 'plans'}`} onClick={() => setShowAgencyData(true)}>Agency Data</p>
                    <p className={`ml-20 cursor ${!showAgencyData ? 'active-plan' : 'plans'}`} onClick={() => setShowAgencyData(false)}>Company Added Data</p>
                </div>
            </div>

            {showAgencyData && <div className='container_div'>

                <div className='yearText'>Year: {location?.state?.year}</div>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Date of Discovery</div>
                        <p className="text-content">{viewData?.date_of_discovery ? moment(viewData?.date_of_discovery).format('MM-DD-YYYY') : NA_STATUS}</p>
                    </div>
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>State</div>
                        <p className="text-content">{viewData?.state ? viewData?.state : NA_STATUS}</p>
                    </div>
                    {location?.state?.modalHeading != 'Reportable Spills Total' && <div className='col-md-2'>
                        <div className='inputFieldHead'>Facility Type</div>
                        <p className="text-content">{viewData?.facility_type ? viewData?.facility_type : NA_STATUS}</p>
                    </div>}
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Facility Name</div>
                        <p className="text-content">{viewData?.location === null ? viewData?.facility_name : <span>Others ({viewData?.location})</span>}</p>
                    </div>
                    {location?.state?.modalHeading == 'Reportable Spills Total' &&
                        <div className='col-md-2'>
                            <div className='inputFieldHead'>Spill Type</div>
                            <p className="text-content">{viewData?.spill_type ? viewData?.spill_type : NA_STATUS}</p>
                        </div>}
                    <div className='col-md-2'>
                        <div className='inputFieldHead'>Spill Status</div>
                        <p className="text-s">{viewData?.spill_status ? viewData?.spill_status : NA_STATUS}</p>
                    </div>

                    {viewData?.oil_spill_volume_released ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Oil Volume Released (bbl)</div>
                        <p className="text-content">{viewData?.oil_spill_volume_released ? viewData?.oil_spill_volume_released : NA_STATUS}</p>
                    </div> : ''}
                    {viewData?.oil_spill_volume_recovered ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Oil Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.oil_spill_volume_recovered ? viewData?.oil_spill_volume_recovered : NA_STATUS}</p>
                    </div> : ''}
                    {viewData?.produced_water_volume_released ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Produced Water Volume Released (bbl)</div>
                        <p className="text-content">{viewData?.produced_water_volume_released ? viewData?.produced_water_volume_released : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.produced_water_volume_recovered ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Produced Water Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.produced_water_volume_recovered ? viewData?.produced_water_volume_recovered : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.drilling_fluid_volume_released ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Drilling Fluid Volume Released (bbl)</div>
                        <p className="text-content">{viewData?.drilling_fluid_volume_released ? viewData?.drilling_fluid_volume_released : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.drilling_fluid_volume_recovered ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Drilling Fluid Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.drilling_fluid_volume_recovered ? viewData?.drilling_fluid_volume_recovered : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.condensate_spill_volume_released ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Condensate Volume Released (bbl)</div>
                        <p className="text-content">{viewData?.condensate_spill_volume_released ? viewData?.condensate_spill_volume_released : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.condensate_spill_volume_recovered ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Condensate Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.condensate_spill_volume_recovered ? viewData?.condensate_spill_volume_recovered : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.ep_waste_spill_volume_released ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Other E&P Waste Volume Released (bbl)</div>
                        <p className="text-content">{viewData?.ep_waste_spill_volume_released ? viewData?.ep_waste_spill_volume_released : NA_STATUS}</p>
                    </div> : ''}

                    {viewData?.ep_waste_spill_volume_recovered ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Other E&P Waste Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.ep_waste_spill_volume_recovered ? viewData?.ep_waste_spill_volume_recovered : NA_STATUS}</p>
                    </div> : ''}
                    {viewData?.flow_back_fluid_spill_volume_released ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Flow Back Fluid Volume Released (bbl)</div>
                        <p className="text-content">{viewData?.flow_back_fluid_spill_volume_released ? viewData?.flow_back_fluid_spill_volume_released : NA_STATUS}</p>
                    </div> : ''}
                    {viewData?.flow_back_fluid_spill_volume_recovered ? <div className='col-md-4'>
                        <div className='inputFieldHead'>Flow Back Fluid Volume Recovered (bbl)</div>
                        <p className="text-content">{viewData?.flow_back_fluid_spill_volume_recovered ? viewData?.flow_back_fluid_spill_volume_recovered : NA_STATUS}</p>
                    </div> : ''}

                </div>
            </div>}

            {!showAgencyData ?
                viewData?.additional_information_id == null || showEditView ?

                    <div className='container_div'>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='container_div'>

                                <div className='row'>

                                    <div className='col-md-12'>
                                        <div className='inputFieldHead'>Detailed Description of the Proposed Resolution<span className='limitText'>Limit 500</span></div>
                                        <textarea
                                            type='text'
                                            className='form-control'
                                            name='comment1'
                                            placeholder='Add Details here'
                                            rows="2"
                                            {...register("comment1", { maxLength: 500 })}
                                        >
                                        </textarea>
                                        {errors.comment1 && errors.comment1.type === "maxLength" && (
                                            <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                                        )}
                                    </div>

                                    {(ifComment2 || getValues('comment2')) && <div className='col-md-12 mt-30'>
                                        <textarea
                                            type='text'
                                            className='form-control'
                                            name='comment2'
                                            placeholder='Add Details here'
                                            rows="2"
                                            {...register("comment2", { maxLength: 500 })}
                                        >
                                        </textarea>
                                        {errors.comment2 && errors.comment2.type === "maxLength" && (
                                            <p className="error"> <img src={errorIcon} /> Maximum 500 characters are allowed </p>
                                        )}
                                    </div>}

                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <button type="button" className='btn-add-data mr-15' onClick={() => { setComment2(!ifComment2); setValue('comment2', '') }}>{!ifComment2 ? 'Add more Comments' : 'Remove second Comment'}</button>
                                        </div>
                                    </div>


                                    <div className='col-md-6 mt-30'>
                                        <div className='inputFieldHead'>Upload Supporting Documentation</div>
                                        <FileUploader onFileUpload={handleFileUpload} />

                                        <div className='wthoutSpcl'>Up to 25 MB - File name without special characters</div>
                                    </div>

                                    <div className='col-md-6 img-container'>
                                        {images?.map((image, i) => (
                                            <div key={i}>
                                                {image.image_link === 'too large' && <EditUploadedFiles
                                                    type='alertImageUpload'
                                                    deleteImg={deleteImg}
                                                    index={i}
                                                    image={image}
                                                    showPreview={showPreview} >
                                                    <img src={DownloadAlertImg}
                                                    />
                                                </EditUploadedFiles>}
                                                {image.image_link !== 'too large' && <EditUploadedFiles
                                                    type='uploadedImage'
                                                    deleteImg={deleteImg}
                                                    index={i}
                                                    image={image}
                                                    showPreview={showPreview} >
                                                    <img src={DownloadCompleteImg}
                                                    />
                                                </EditUploadedFiles>}
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>

                            <div className='text-right'>
                                {showEditView && <button type="button" className='btn-cancel border-0 mr-15' onClick={() => {setShowEditView(false);getDetailsById()}}>Cancel</button>}
                                <button type="submit" className='btn-event-incident in-blck mt-30'>Save</button>
                            </div>
                        </form>

                    </div> :

                    <div className='container_div'>

                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead mb-3 mt-3'>Detailed Description of the Proposed Resolution</div>
                                <div className="mb-3">
                                    <p className="text-content"><span className='inputFieldHead'>{viewAdditionalInfoData?.details?.comment1 && '1.'} </span>{viewAdditionalInfoData?.details?.comment1 ?
                                        viewAdditionalInfoData?.details?.comment1 : 'NA'}</p>
                                </div>
                                {viewAdditionalInfoData?.details?.comment2 && <p className="text-content"><span className='inputFieldHead'>2. </span>{viewAdditionalInfoData?.details?.comment2 ?
                                    viewAdditionalInfoData?.details?.comment2 : 'NA'}</p>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='inputFieldHead mt-3'>Supporting Documents</div>
                            </div>
                            {images?.length > 0 ? images.map((item, i) => (
                                <div className='col-md-6' key={item?.id}>
                                    <UploadedFileContainer
                                        type='uploadedImage'
                                        index={i}
                                        img={item}
                                        showPreview={showPreview}
                                        downloadImg={downloadImg}
                                    >
                                        <img src={DownloadCompleteImg} alt='DownloadCompleteImg' />
                                    </UploadedFileContainer>
                                </div>
                            )) : <div className="ml-2">No image added</div>}
                        </div>

                        <div className='text-right'>
                            <button type="button" className='btn-event-incident in-blck mt-30' onClick={() => setShowEditView(true)}>Edit</button>
                        </div>

                    </div> : ''}

                    <Modal size="lg"
                dialogClassName="modal-90w"
                show={showPreviewImg}
                onHide={() => setShowPreviewImg(false)}>
                <Modal.Header className="sideOnRight" closeButton>
                    {imageName}
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    {['pdf'].includes(docFormatType) && <FilePreviewFinal pdfFile={imageToPreview ? imageToPreview : ''} />}
                    {['jpg', 'jpeg', 'png'].includes(docFormatType) && <div className="modal-body reportText">
                        <img src={imageToPreview} className="img-preview" />
                    </div>}
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ViewAgencySpill