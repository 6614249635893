import React from 'react';
import LiticoIcon from '../../assets/images/LiticoIcon.svg';

const Auth = () => {
    return (
        <div className="content">
            <div className="welcomeCont">
                <img className="litico_main_icon" src={LiticoIcon} />
                <h3 className="mt-16">Welcome To LITICO</h3>
                {/* <div className="mt-16"></div> */}
            </div>
        </div>
    )
}

export default Auth