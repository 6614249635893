// state colors
const primaryShade = '#07AC2E';
const secondaryNavyShade = '#061E3A';
const secondaryBlueShade = '#173454';

// success or error shades
const successShade = '#07AC2E';
const errorShade = '#DF6574';

// graph bar shades
const graphGreenShade = '#11C5BE';
const graphPurpleShade = '#7275B3';
const graphYellowShade = '#EEAA5C';
const darkBlueShade = '#367EB9';
const darkGreenShade = '#64A97B';
const darkGreyShade = '#60646D';
const darkRedShade = '#DF6574';

// graph line shades
const graphGreenLine = '#3CDFD9';
const graphPurpleLine = '#9A9EEA';
const graphYellowLine = '#FBC78C';
const graphDarkGreenLine = '#11C5BE';
const graphBeigeGreenLine = '#64AA7A';
const graphRedLine = '#E3786C';

// black colors
const black1Shade = '#121E2C';
const black2Shade = '#50596B';
const black3Shade = '#282828';
const black4Shade = '#FFFFFF';

// grey colors
const gray3A6 = '#969BA6';
const gray3D7 = '#CACED7';
const gray4E1 = '#DFDFE1';
const grayEEE = '#EEEEEE';
const grayBEF = '#EAEBEF';
const gray2F4 = '#F2F2F4';
const gray0F0 = '#F0F0F0';

// graph line color
const graphLineColor = '#E0E2E7';

// transparent color
const transparentShade = '#FFFFFF';

export default {
    primaryShade, secondaryBlueShade, secondaryNavyShade, successShade, errorShade, graphGreenShade, graphPurpleShade, graphYellowShade,
    black1Shade, black2Shade, black3Shade, black4Shade, gray3A6, gray3D7, gray4E1, grayEEE, grayBEF, gray2F4, gray0F0, graphLineColor,
    graphGreenLine, graphPurpleLine, graphYellowLine, graphDarkGreenLine, graphBeigeGreenLine, graphRedLine, darkBlueShade, darkGreenShade, darkGreyShade, darkRedShade, transparentShade
}