import directComplaintsIcon from '../../assets/images/DirectComplaints.svg';
import agencyComplaintsIcon from '../../assets/images/AgencyComplaints.svg';
import airQualityComplaintsIcon from '../../assets/images/ComplaintAirQualityComplaints.svg';
import wasteManagementComplaintsIcon from '../../assets/images/ComplaintWasteManagementComplaints.svg';
import DeleteIcon from '../../assets/images/FormImages/deleteIcon.svg';
import ViewUpdateIcon from '../../assets/images/FormImages/view-updateIcon.svg';
import ViewIcon from '../../assets/images/FormImages/view.svg';

const complaintsDashboardCount = [
    { name: 'Direct Complaints', icon: <img src={directComplaintsIcon} alt='directComplaintsIcon' /> },
    { name: 'Agency Complaints', icon: <img src={agencyComplaintsIcon} alt='agencyComplaintsIcon' /> },
    { name: 'Air Quality/ Odor Complaints', icon: <img src={airQualityComplaintsIcon} alt='airQualityComplaintsIcon' /> },
    { name: 'Waste Management', icon: <img src={wasteManagementComplaintsIcon} alt='wasteManagementComplaintsIcon' /> },
];

const complaintType = [
    { id: 1, value: 'Others', label: 'Others' },
    { id: 2, value: 'Air Quality/ Odor', label: 'Air Quality/ Odor' },
    { id: 3, value: 'Dust', label: 'Dust' },
    { id: 4, value: 'Ground Water/ Water Well', label: 'Ground Water/ Water Well' },
    { id: 5, value: 'Lighting', label: 'Lighting' },
    { id: 6, value: 'Noise', label: 'Noise' },
    { id: 7, value: 'Royalties Payment', label: 'Royalties Payment' },
    { id: 8, value: 'Missing Production', label: 'Missing Production' },
    { id: 9, value: 'Spills/ Soil Contamination', label: 'Spills/ Soil Contamination' },
    { id: 10, value: 'Traffic', label: 'Traffic' },
    { id: 11, value: 'Waste Management/ Dumping', label: 'Waste Management/ Dumping' },
    { id: 12, value: 'Notice Letters', label: 'Notice Letters' },
]

const complaintSources = [
    { id: 'Email', value: 'Email', label: 'Email' },
    { id: 'Phone Call', value: 'Phone Call', label: 'Phone Call' },
    { id: 'In Person', value: 'In Person', label: 'In Person' },
    { id: 'Social Media', value: 'Social Media', label: 'Social Media' },
    { id: 'Other Sources', value: 'Other Sources', label: 'Other Sources' },
];

const pieComplaintTypeData = [
    { "body_part": "Air Quality/ Odor", "body_part_count": 13, "body_part_per": "1%", "Urgent": false },
    { "body_part": "Dust", "body_part_count": 13, "body_part_per": "2%", "Urgent": false },
    { "body_part": "Ground Water/ Water well", "body_part_count": 13, "body_part_per": "13%", "Urgent": false },
    { "body_part": "Reclaimation Detail", "body_part_count": 10, "body_part_per": "23%", "Urgent": false },
    { "body_part": "Lightning", "body_part_count": 10, "body_part_per": "12%", "Urgent": true },
    { "body_part": "Noise", "body_part_count": 10, "body_part_per": "12%", "Urgent": true },
    { "body_part": "Traffic", "body_part_count": 34, "body_part_per": "10%", "Urgent": false },
    { "body_part": "Property Damage", "body_part_count": 34, "body_part_per": "10%", "Urgent": false },
    { "body_part": "Royalties Payment/ Missing Production", "body_part_count": 34, "body_part_per": "10%", "Urgent": false },
    { "body_part": "Waste management/ Dumping", "body_part_count": 34, "body_part_per": "34%", "Urgent": false },
    { "body_part": "Notice Letters", "body_part_count": 34, "body_part_per": "34%", "Urgent": false },
    { "body_part": "Others", "body_part_count": 34, "body_part_per": "34%", "Urgent": false }
]

const horizontalComplaintTypeData = [
    {
        "Air Quality/ Odor": 34, "Dust": 34, "Ground Water/ Water well": 34, "Laceration": 11, "Noise": 64,
        "Property Damage": 64, "Royalties Payment/ Missing Production": 44, "Spills/ Soil Contamination": 14,
        "Traffic": 34, "Waste management/ Dumping": 13, "Notice Letters": 44, "Others": 24
    }
]

const complaintDraftItems = [
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} alt='ViewUpdateIcon' /> },
    { name: 'Delete', id: 2, label: 'Delete', icon: <img src={DeleteIcon} style={{ width: '18px', height: '20px' }} alt='DeleteIcon' /> }
];

const complaintActionItems = [
    { name: 'View', label: 'View', icon: <img src={ViewIcon} className='view-icon' alt='ViewIcon' /> },
    { name: 'Update', label: 'Update', icon: <img src={ViewUpdateIcon} alt='ViewUpdateIcon' /> },
    { name: 'Delete', label: 'Delete', icon: <img src={DeleteIcon} style={{ width: '18px', height: '20px' }} alt='DeleteIcon' /> }
];

const complaintDashboardObj = [
    { incident_type: 'Direct Complaints', incident_count: 28, percentage_difference: '2.3%', icon: <img src={directComplaintsIcon} alt='directComplaintsIcon' /> },
    { incident_type: 'Agency Complaints', incident_count: 8, percentage_difference: '2.3%', icon: <img src={agencyComplaintsIcon} alt='agencyComplaintsIcon' /> },
    { incident_type: 'Air Quality/ Odor Complaints', incident_count: 6, percentage_difference: '2.3%', icon: <img src={airQualityComplaintsIcon} alt='airQualityComplaintsIcon' /> },
    { incident_type: 'Waste Management', incident_count: 4, percentage_difference: '2.3%', icon: <img src={wasteManagementComplaintsIcon} alt='wasteManagementComplaintsIcon' /> }
];

const complaintGraphs = [
    {
        "name": "Number of Complaints",
        "label": "Number of Complaints",
        "id": "Number of Complaints",
        "checked": true,
    },
    // {
    //     "name": "Total Complaints per Complaint Type",
    //     "label": "Total Complaints per Complaint Type",
    //     "id": "Total Complaints per Complaint Type",
    //     "checked": true,
    // },
    // {
    //     "name": "Complaint Type",
    //     "label": "Complaint Type",
    //     "id": "Complaint Type",
    //     "checked": true,
    // },
    // {
    //     "name": "Total Complaints Heat Map",
    //     "label": "Total Complaints Heat Map",
    //     "id": "Total Complaints Heat Map",
    //     "checked": true,
    // }
]

const numberOfComplaintsGraphData = [
    {
        "year": 2022,
        "Direct": 1,
        "Agency": 1
    },
    {
        "year": 2023,
        "Direct": 33,
        "Agency": 2
    },
    {
        "year": 2024,
        "Direct": 198,
        "Agency": 2
    }
]


const numberOfComplaintsFilterData = [
    { id: 10, value: 'direct', label: 'Direct', name: 'Direct', checked: false },
    { id: 11, value: 'agency', label: 'Agency', name: 'Agency', checked: false },
];

export default {
    complaintsDashboardCount, complaintType, pieComplaintTypeData, horizontalComplaintTypeData, complaintSources,
    complaintActionItems, complaintDraftItems, complaintDashboardObj, complaintGraphs, numberOfComplaintsGraphData,
    numberOfComplaintsFilterData
};