import React, { useState, useEffect } from "react";
import SideThreeDots from '../../../assets/images/SideThreeDots.svg';
import NoDataAdded from "../NoDataAdded/NoDataAdded";
import { Get, Put, Delete2 } from "../../../constants/apiMethods";
import { getRoles, activateDeactivateUser } from "../../../constants/apiRoutes";
import LoadingSpinner from "../../../components/Loader";
import { useNavigate } from "react-router";
import ThreeDotsIcon from '../../../assets/images/FormImages/threeDots.svg';
import data from '../../../mockData/mockData';
import SelectDropdownAction from "../../../components/formBuilder/SelectDropdownAction";
import AlertNotifier from "../../../components/Notifications/AlertNotifier";
import ForwardPagination from '../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../assets/images/BackwardPagination.svg';
import ReactPaginate from "react-paginate";
import './RolesList.css';
import moment from "moment";
import { Modal } from "react-bootstrap";

const RolesList = ({ checkIfUserPresent }) => {
    const navigate = useNavigate();
    const isSidebarShow = localStorage.getItem('isSidebarShow');
    const [currentPage, setCurrentPage] = useState(1);
    const [showActive, setShowActive] = useState(true);
    const [roles, setRoles] = useState([]);
    const token = localStorage.getItem('access_token');
    const [loading, setLoading] = useState(false);
    const [successRes, setSuccessRes] = useState(false);
    const [successDetail, setSuccessDetail] = useState('');
    const [alertType, setAlertType] = useState('');
    const [anyRolePresent, setAnyRolePresent] = useState(false);
    const [showHideChangeStatusRole, setshowHideChangeStatusRole] = useState({ show: false, id: '' });

    useEffect(() => {
        // getRolesList(1, showActive);
        // initialLoad && getRolesList(1, false);
        // setInitialLoad(false);
        getRolesList(1, showActive);
        // getRolesList(1, );
        console.log('any role prsetn or not ', anyRolePresent);
    }, [showActive, anyRolePresent])

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const onActivate = () => {
        setLoading(true);
        let body = {
            is_active: true
        }
        Put(`${activateDeactivateUser}/${showHideChangeStatusRole.id}/`, token, body, false)
            .then((res) => {
                if (res.success) {
                    setSuccessDetail('Role activated successfully!');
                    setSuccessRes(true);
                    setAlertType('success');
                    setshowHideChangeStatusRole({ show: false, id: '' });
                    getRolesList(1, showActive);
                }
                setLoading(false);
            })
            .catch((error) => {
            });
    }

    const onDeactivate = () => {
        setLoading(true);
        Delete2(activateDeactivateUser, token, showHideChangeStatusRole?.id, false)
            .then((res) => {
                if (res.success) {
                    setSuccessDetail('Role deactivated successfully!');
                    setSuccessRes(true);
                    setAlertType('success');
                    setshowHideChangeStatusRole({ show: false, id: '' });
                    getRolesList(1, showActive);
                }
                setLoading(false);
            })
            .catch((error) => {
            });
    }

    const onUpdate = (item) => {
        navigate('/edit-company-role', { state: { id: item.id, showActive: showActive} });
    }

    const getRolesList = (page, active) => {
        setLoading(true);
        Get(getRoles, token, `?page=${page}&is_active=${active}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    setLoading(false);
                    setCurrentPage(data.data.pagination_info);
                    let transformData = data.data.data;
                    const newData = transformData && transformData.map(item => {
                        const hasTruePermission = item.permissions.some(permission => permission.dashboardChecked || permission.exportAccess || permission.writeAccess || permission.readAccess);

                        return { ...item, hasTruePermission };
                    });
                    setRoles(newData);
                    (showActive && data.data.data.length === 0) ? getSecondIteration(page, false) : setAnyRolePresent(true);
                    // { isSidebarShow == 'false' && checkIfUserPresent(true) };
                    if (isSidebarShow == 'false') {
                        checkIfUserPresent &&  checkIfUserPresent(true)
                    }
                }
            });
    }

    const getSecondIteration = (page, active) => {
        setLoading(true);
        Get(getRoles, token, `?page=${page}&is_active=${active}`, false)
            .then((response) => response)
            .then((data) => {
                if (data.success) {
                    if (data.data.data.length > 0) {
                        setAnyRolePresent(true)
                        checkIfUserPresent && checkIfUserPresent(true);
                    }
                    else {
                        setAnyRolePresent(false)
                        checkIfUserPresent(false);
                    }
                    setLoading(false);
                }
            });
    }

    const pageChange = (page) => {
        getRolesList(page.selected + 1, showActive);
    }

    return (
        <>
            <div className={`mt-10 ${isSidebarShow == 'true' ? 'full-cont' : ''}`}>
                {loading && <LoadingSpinner />}
                {successRes && <AlertNotifier timer={2000} alertType={alertType} alertText={successDetail} setVisibility={setVisibility} />}
                {isSidebarShow && anyRolePresent && <div className="row">
                    <div className="col-md-9">
                        <span className="head_2">Roles and Access</span>
                    </div>
                    <div className="col-md-3 text-right">
                        <button type="button" className="btn-add-roles-access" onClick={() => { navigate('/add-company-role') }}>Add Roles and Access</button>
                    </div>
                </div>}
                <div className="row inside_head">
                    <div className="col-md-8">
                        {anyRolePresent && <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active Roles</p>
                            <p className={`ml-40 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated Roles</p>

                        </div>}
                    </div>
                    {!isSidebarShow && anyRolePresent && <div className="col-md-4 text-right">
                        <button type="button" className="btn-add-user" onClick={() => { navigate('/add-company-role') }}>Add Roles and Access</button>
                    </div>}
                </div>

                {!anyRolePresent ? <NoDataAdded mainText={'You have not created any roles yet'} subText={anyRolePresent ? '' : 'In order to add users you need to first create roles'} type='role' /> : <></>}

                {anyRolePresent && roles.length <= 0 &&
                    <div className="container pt-5 pb-5 mt-4">
                        <div className='no-data'>No Data Found</div>
                    </div>
                }

                {anyRolePresent && roles.length > 0 && roles.map((item, index) => {
                    return (
                        <div className='container_div' key={index}>
                            <div className='row'>
                                <div className='col-md-10'>
                                    <div className='plan-name'>
                                        <span style={{ fontSize: '22px' }}>{item.name}</span>
                                        <span style={{ color: '#777777', marginLeft: '2rem', fontSize: '16px', fontFamily: 'Montserrat' }}>Created on</span>
                                        <span style={{ marginLeft: '2rem', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: '100' }}>{moment(item?.created_on).format('MM-DD-YYYY')}</span>
                                    </div>

                                </div>
                                <div className='col-md-2 text-right'>
                                    {showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleDeactiveActionItems} onDeactivate={() => setshowHideChangeStatusRole({ show: true, id: item?.id })} onUpdate={() => onUpdate(item)} isImg={true} />}
                                    {!showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleActiveActionItems} onActivate={() => setshowHideChangeStatusRole({ show: true, id: item?.id })} onUpdate={() => onUpdate(item)} isImg={true} />}
                                </div>
                            </div>

                            <div className='row mt-30'>
                                <div className='col-md-12'>
                                    <div className='inputFieldHead' style={{ color: '#777777' }}>Modules Accessible</div>
                                    <div className='flex'>
                                        <p className="text-content mt-16"> {!item.hasTruePermission &&
                                            <span className="feature-wrapper">No Permission Given</span>
                                        }</p>
                                        <p className="text-content mt-16"> {item.hasTruePermission && item.permissions.map((item, index) => (
                                            <span key={index}>
                                                {(item.dashboardChecked || item.exportAccess || item.readAccess || item.writeAccess) && <span className="feature-wrapper">{item.moduleName}</span>}
                                            </span>
                                        ))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
                }
                {/* {!anyRolePresent ?
                    // showActive ? <NoDataAdded mainText={'You have not created any roles yet'} subText={anyRolePresent ? '' : 'In order to add users you need to first create roles'} type='role' /> 
                    // :
                    <NoDataAdded mainText={'You have not created any roles yet'} subText={anyRolePresent ? '' : 'In order to add users you need to first create roles'} type='role' />
                    : 
                    
                    ( anyRolePresent && roles.length < 0 ? 
                        <div className="container pt-5 pb-5 mt-4">
                    <div className='no-data'>No Data Found</div>
                 </div>
                        : roles.map((item, index) => {
                        return (
                            <div className='container_div' key={index}>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <div className='plan-name'>
                                            <span style={{ fontSize: '22px' }}>{item.name}</span>
                                            <span style={{ color: '#777777', marginLeft: '2rem', fontSize: '16px', fontFamily: 'Montserrat' }}>Created on</span>
                                            <span style={{ marginLeft: '2rem', fontSize: '18px', fontFamily: 'Montserrat', fontWeight: '100' }}>{moment(item?.created_on).format('MM-DD-YYYY')}</span>
                                        </div>

                                    </div>
                                    <div className='col-md-2 text-right'>
                                        {showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleDeactiveActionItems} onDeactivate={() => onDeactivate(item)} onUpdate={() => onUpdate(item)} isImg={true} />}
                                        {!showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleActiveActionItems} onActivate={() => onActivate(item)} onUpdate={() => onUpdate(item)} isImg={true} />}
                                    </div>
                                </div>

                                <div className='row mt-30'>
                                    <div className='col-md-12'>
                                        <div className='inputFieldHead' style={{ color: '#777777' }}>Modules Accessible</div>
                                        <div className='flex'>
                                            <p className="text-content mt-16"> {item.permissions.map((item, index) => (
                                                <span key={index}>
                                                    <span className="feature-wrapper">{item.moduleName}</span>
                                                </span>
                                            ))}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    )
                    )} */}
                {anyRolePresent && roles.length > 0 && <ReactPaginate
                    // forcePage={currentPage.current_page}
                    pageCount={currentPage.total_count / 5}
                    pageRange={2}
                    marginPagesDisplayed={2}
                    onPageChange={(e) => pageChange(e)}
                    containerClassName={'pagination-body'}
                    previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
                    nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
                    previousLinkClassName={'page'}
                    breakClassName={'page'}
                    nextLinkClassName={'page'}
                    pageClassName={'page'}
                    disabledClassName={'disabled'}
                    activeClassName={'active'}
                />}


            </div>

            <Modal show={showHideChangeStatusRole.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusRole({ show: false, id: '' })}>
                    <Modal.Header className="sideOnRight" closeButton>
                    </Modal.Header>
                    <Modal.Body className="auth_modal_header border-0 mx-auto">
                        <div className="modal-body reportText">
                            Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} this Role?
                        </div>
                        <Modal.Footer>
                            <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusRole({ show: false, id: '' })}>No</button>
                            <button type="button" className="btn-yes" onClick={() => showActive ? onDeactivate() : onActivate()}>Yes</button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>

        </>
    )
}

export default RolesList;