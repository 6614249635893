import React, { useState, useEffect, useRef } from 'react';
import { Get, Put, Delete2, Post, ImagePost, Delete3 } from '../../../constants/apiMethods';
import { contractor, contractorUpload } from '../../../constants/apiRoutes';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../../components/Loader';
import AlertNotifier from '../../../components/Notifications/AlertNotifier';
import data from '../../../mockData/mockData';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import ForwardPagination from '../../../assets/images/ForwardPagination.svg';
import BackwardPagination from '../../../assets/images/BackwardPagination.svg';
import ThreeDotsIcon from '../../../assets/images/FormImages/threeDots.svg';
import errorIcon from "../../../assets/images/error_icon.svg";
import DeleteIcon from '../../../assets/images/FormImages/deleteIcon.svg';
import DropCollapseDown from '../../../assets/images/DropCollapseDown.svg';
import DropCollapseDownSelected from '../../../assets/images/DropCollapseDownSelected.svg';
import ClearIcon from '../../../assets/images/FormImages/clear_icon.svg';
import SearchIcon from '../../../assets/images/FormImages/search_icon.svg';
import ClearSvg from '../../../assets/images/FormImages/clear.svg';
import DownloadCompleteImg from '../../../assets/images/downloadComplete.svg';
import UploadIcon from '../../../assets/images/FormImages/upload.svg';
import BtnCrossIcon from '../../../assets/images/FormImages/btnCross.svg';
import CrossImg from '../../../assets/images/crossImage.svg';
import DeleteImg from '../../../assets/images/deleteImg.svg';
import { CircularProgressbar } from 'react-circular-progressbar';
import SelectDropdownAction from "../../../components/formBuilder/SelectDropdownAction";
import { Modal } from 'react-bootstrap';
import './Contractor.css';
import { useFieldArray, useForm } from 'react-hook-form';
import DateRangePicker from '../../../components/formBuilder/DateRangePicker';
import ChangingProgressProvider from '../../../components/ChangingProgressProvider/ChangingProgressProvider';
import FileUploader from '../../../components/FileUploader';
import { XLSX_FILE_FORMAT } from '../../../constants/Global';

const ContractorManagement = () => {

    const navigate = useNavigate();
    const inputFile = useRef(null);
    const token = localStorage.getItem("access_token");
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [contractorListData, setContractorList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [showHideChangeStatusContractor, setshowHideChangeStatusContractor] = useState({ show: false, id: '' });
    const [successRes, setSuccessRes] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showHideAddEditContractor, setshowHideAddEditContractor] = useState({ show: false, type: '', id: '' });
    const [showFilters, setFilters] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const [createdStartDate, setCreatedStartDate] = useState('');
    const [createdEndDate, setCreatedEndDate] = useState('');
    const [clearDateValue, setClearDateValue] = useState(true);
    const [createdDateRangeValue, setCreatedDateRangeValue] = useState(['', '']);
    const [modifiedStartDate, setModifiedStartDate] = useState('');
    const [modifiedEndDate, setModifiedEndDate] = useState('');
    const [modifiedDateRangeValue, setModifiedDateRangeValue] = useState(['', '']);
    const [filesArr, setFilesArr] = useState([]);
    const [fileData, setFile] = useState('');
    const [showUpload, setShowUpload] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showFileContainer, setShowFileContainer] = useState(false);
    const [showHideUploadContainer, setShowHideUploadContainer] = useState({ show: false });
    const [currentFile, setCurrentFile] = useState('');

    const {
        control,
        register,
        setValue,
        getValues,
        reset,
        trigger,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            contractor: [{ name: '' }],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'contractor'
    });

    useEffect(() => {
        getContractorListing(page, '', ['', ''], ['', '']);
    }, [showActive])

    const setContractorinEdit = (item) => {
        const contractorArr = [{ name: item?.name }]
        setValue('contractor', contractorArr);
    }

    const getContractorListing = (page, searchValue, createdDateRangeValue, modifiedDateRangeValue) => {
        if (createdDateRangeValue) {
            setCreatedDateRangeValue(createdDateRangeValue);
            setCreatedStartDate(createdDateRangeValue[0]);
            setCreatedEndDate(createdDateRangeValue[1]);
        }
        if (modifiedDateRangeValue) {
            setModifiedDateRangeValue(modifiedDateRangeValue);
            setModifiedStartDate(modifiedDateRangeValue[0]);
            setModifiedEndDate(modifiedDateRangeValue[1]);
        }
        setLoading(true);
        Get(contractor, token, `?page=${page}&is_active=${showActive}&name=${searchValue}&created_start_date=${createdDateRangeValue[0]}&created_end_date=${createdDateRangeValue[1]}&modified_start_date=${modifiedDateRangeValue[0]}&modified_end_date=${modifiedDateRangeValue[1]}`, false)
            .then((response) => response)
            .then((data) => {
                setLoading(false);
                if (data.success) {
                    setContractorList(data.data.data);
                    setCurrentPage(data.data.pagination_info);
                }
            });
    };

    const pageChange = (page) => {
        getContractorListing(page + 1, '', createdDateRangeValue, modifiedDateRangeValue);
    }

    const changeContractorStatus = () => {
        setSuccessRes(false);
        setLoading(true);
        const body = {
            is_active: true
        }
        if (showActive) {
            Delete3(contractor, token, `${showHideChangeStatusContractor?.id}`, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusContractor({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data.success) {
                        getContractorListing(page, '', createdDateRangeValue, modifiedDateRangeValue);
                        setAlertType('success');
                        setShowSuccessMessage('Contractor Deactivated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        } else {
            Put(`${contractor}/${showHideChangeStatusContractor.id}`, token, body, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    setshowHideChangeStatusContractor({ show: false, id: '' });
                    setSuccessRes(true);
                    if (data.success) {
                        getContractorListing(page, '', createdDateRangeValue, modifiedDateRangeValue);
                        setAlertType('success');
                        setShowSuccessMessage('Contractor Activated Successfully');
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                    }
                });
        }
    }

    const onSubmitContractor = (data) => {
        setLoading(true);
        setSuccessRes(false);
        if (showHideAddEditContractor.type == 'add') {
            Post(`${contractor}/`, token, data.contractor, false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        setAlertType('success');
                        setShowSuccessMessage('Contractor Added Successfully');
                        setSuccessRes(true);
                        setshowHideAddEditContractor({ show: false, type: '', id: '' });
                        getContractorListing(page, '', createdDateRangeValue, modifiedDateRangeValue);
                        reset();
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                        setSuccessRes(true);
                    }
                });
        } else {
            Put(`${contractor}/${showHideAddEditContractor.id}`, token, data.contractor[0], false)
                .then((response) => response)
                .then((data) => {
                    setLoading(false);
                    if (data.success) {
                        setAlertType('success');
                        setShowSuccessMessage('Contractor Updated Successfully');
                        setSuccessRes(true);
                        setshowHideAddEditContractor({ show: false, type: '', id: '' });
                        getContractorListing(page, '', createdDateRangeValue, modifiedDateRangeValue);
                        reset();
                    } else {
                        setAlertType('failed');
                        setShowSuccessMessage(data.detail);
                        setSuccessRes(true);
                    }
                });
        }
    };

    const appendContractor = () => {
        const formData = getValues();
        const lastIndex = formData.contractor.length - 1;

        if (formData.contractor[lastIndex].name) {
            append({ name: '' });
        } else {
            // alert("Please fill in the previous item before adding a new one.");
        }
    };

    const removeContractor = (index) => {
        remove(index);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown2 = () => {
        setIsOpenUpdate(!isOpenUpdate);
    };

    const clearFilters = () => {
        setCreatedDateRangeValue('');
        setCreatedStartDate('');
        setCreatedEndDate('');
        setModifiedDateRangeValue('');
        setModifiedStartDate('');
        setModifiedEndDate('');
        setIsOpen(false);
        setIsOpenUpdate(false);
        setClearDateValue(false);
        setTimeout(() => {
            setClearDateValue(true);
        }, 1)
        getContractorListing(page, searchValue, ['', ''], ['', '']);
    }

    const setVisibility = (data) => {
        setSuccessRes(data);
    }

    const sampleFormat = () => {
        window.open(`${process.env.REACT_APP_SAMPLE_FORMAL_URL}sample_format/contractor.xlsx`, '_self')
    }

    const UploadContainer = (props) => {
        return (
            <>
                <div className={`row boxWidth ml-20 mt-30 ${props.type == 'imageProcessing' ? 'percentProcess' : props.type == 'uploadedImage' ? 'imageUpload' : ''}`}>
                    <div className={`col-md-2 ${props.type == 'imageProcessing' ? 'bgPercentProcessColor' : props.type == 'uploadedImage' ? 'bgUploadCompleted' : ''}`}>
                        <div>{props.children}</div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-11">
                                <div className="imageName">{currentFile && currentFile.name}</div>
                                <div className="imageSize">{convertBytesToKb(currentFile.size)} KB</div>
                            </div>
                            <div className="col-md-1 flex contentCenter cursor" onClick={() => { setShowUpload(true); setFile(''); setCurrentFile(''); }}>
                                <img src={props.type == 'imageProcessing' ? CrossImg : DeleteImg} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const handleClick = () => {
        //to be used while API integration  
    }

    const convertBytesToKb = (bytes) => {
        return (bytes / 1024).toFixed(2); // Convert bytes to kilobytes and round to 2 decimal places
    };

    const onFileChange = (files) => {
        setShowFileContainer(false);
        setCurrentFile(files[0]);
        setFile(files[0]);
        setFilesArr(files[0]);
        setShowUpload(false);
        setShowProcessing(true);
        setTimeout(() => {
            setShowProcessing(false);
            setShowFileContainer(true);
        }, 5000)
    }

    const handleFileUpload = () => {
        const selectedFile = fileData;
        if (selectedFile) {
            const fileName = selectedFile.name;
            const fileType = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        if (fileType !== XLSX_FILE_FORMAT) {
            setSuccessRes(true);
            setAlertType('failed');
            setShowSuccessMessage('Invalid file type. Please select a .xlsx file.');
            setLoading(false);
            return;
        }
        if (fileType === XLSX_FILE_FORMAT) {
        setLoading(true);
        const formData = new FormData();
        formData.append(`file`, fileData);
        ImagePost(contractorUpload, token, formData, false)
            .then((res) => {
                setLoading(false);
                if (res.success) {
                    setShowSuccessMessage(res.detail);
                    setAlertType('success');
                    setLoading(false);
                    setSuccessRes(true);
                    setShowHideUploadContainer({ show: false });
                    //   const updatedArray = [...images, res.result[0]];
                    //   setImages(updatedArray);
                }
                if (!res.success) {
                    setShowSuccessMessage(res.detail);
                    setAlertType('failed');
                    setLoading(false);
                    setSuccessRes(true);
                }
            })
            .catch((error) => {

            });
        }
    }
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {successRes && <AlertNotifier alertType={alertType} timer={2000} alertText={showSuccessMessage} setVisibility={setVisibility} />}
            <div className="full-cont">
                <div className="row border_head_1">
                    <div className="col-md-6">
                        <div className="head_2">Contractor Management</div>
                    </div>
                    <div className="col-md-6 text-right">
                        <button className='btn-cancel border-0' onClick={sampleFormat}>Sample Format</button>
                        <button className='btn-add-data mr-15 mt-0' onClick={() => { setShowHideUploadContainer({ show: true }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); }}>Bulk Upload</button>
                        <button className='onboard_btn set-onbBtn-wd border-0' onClick={() => { setshowHideAddEditContractor({ show: true, type: 'add', id: '' }); reset() }}>Add Contractor</button>
                    </div>
                </div>

                <div className="row inside_head">
                    <div className="col-md-12">
                        <div className='flex bbtm'>
                            <p className={`cursor ${showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(true)}>Active Contractors</p>
                            <p className={`ml-20 cursor ${!showActive ? 'active-plan' : 'plans'}`} onClick={() => setShowActive(false)}>Deactivated Contractors</p>

                        </div>
                    </div>
                </div>

                <div className="filter_options">
                    {showFilters ? <><div className="filter_options_1">
                        <div className="filtrBy">Filter by</div>

                        <div className="dropdown-container">
                            <button onClick={toggleDropdown} className={`btn border-0 dateRangeBtn ${createdStartDate && createdEndDate != '' ? 'selected-filter-color' : ''}`}>
                                {createdStartDate && createdEndDate ? createdStartDate + ' - ' + createdEndDate : 'Add Date Range'}
                                <img className="ml-8" src={createdStartDate && createdEndDate ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpen && (
                                <ul className="dropdown-list">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getContractorListing(page, '', dateString, modifiedDateRangeValue) }} onClear={() => { setCreatedStartDate(''); setCreatedEndDate(''); toggleDropdown(); getContractorListing(page, searchValue, ['', ''], modifiedDateRangeValue) }} />}
                                </ul>
                            )}
                        </div>

                        <div className="dropdown-container ml-7">
                            <button onClick={toggleDropdown2} className={`btn border-0 dateRangeBtn ${modifiedStartDate && modifiedEndDate != '' ? 'selected-filter-color' : ''}`}>
                                {modifiedStartDate && modifiedEndDate ? modifiedStartDate + ' - ' + modifiedEndDate : showActive ? 'Update Date Range' : 'Deactivated Date Range'}
                                <img className="ml-8" src={modifiedStartDate && modifiedEndDate ? DropCollapseDownSelected : DropCollapseDown} alt="dropdownIcon" />
                            </button>
                            {isOpenUpdate && (
                                <ul className="dropdown-list">
                                    {clearDateValue && <DateRangePicker dateRange={clearDateValue} onDateRangeSelection={(dateString) => { getContractorListing(page, '', createdDateRangeValue, dateString) }} onClear={() => { setModifiedStartDate(''); setModifiedEndDate(''); toggleDropdown2(); getContractorListing(page, searchValue, createdDateRangeValue, ['', '']) }} />}
                                </ul>
                            )}
                        </div>

                    </div>
                        <div className="filter_options_1">
                            <div className="">
                                <button className='clear_btn' onClick={clearFilters}>
                                    <img className="editIcn" src={ClearIcon} />Clear filter
                                </button>
                            </div>
                            <div className="cursor" onClick={() => setFilters(false)}>
                                <img src={SearchIcon} alt="searchIcon" />
                            </div>
                        </div></> :

                        <div className="searchBox">
                            <input type="text" className="form-control search-input" placeholder="Search by typing Contractor Name" onChange={(event) => getContractorListing(page, event.target.value, createdDateRangeValue, modifiedDateRangeValue)} />
                            <img className="cursor" src={ClearSvg} onClick={() => { setFilters(true); setSearchValue(''); getContractorListing(page, searchValue, createdDateRangeValue, modifiedDateRangeValue); }} />
                        </div>}

                </div>

                <>

                    <><div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Contractor Name</th>
                                    <th scope="col">Added On</th>
                                    <th scope="col">Added By</th>
                                    <th scope="col">{showActive ? 'Last Updated Date' : 'Deactivated On'}</th>
                                    <th scope="col">{showActive ? 'Last Updated By' : 'Deactivated By'}</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                                {contractorListData?.length > 0 && contractorListData.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="brdrtop">
                                                <td>{item?.name}</td>
                                                <td>{item?.created_on ? moment(item?.created_on).format('MM-DD-YYYY') : 'NA'}</td>
                                                <td>{item?.created_by}</td>
                                                <td>{showActive ? item?.updated_on ? moment(item?.updated_on).format('MM-DD-YYYY') : 'NA' : item?.deactivated_on ? moment(item?.deactivated_on).format('MM-DD-YYYY') : 'NA'}</td>
                                                <td>{showActive ? item?.updated_by ? item?.updated_by : 'NA' : item?.deactivated_by ? item?.deactivated_by : 'NA'}</td>
                                                <td>
                                                    {/* <img className="editIcn" src={FadedDownloadDoc} /> */}
                                                    {/* <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.ActionItems} onDelete={() => deleteDataFromList(item.id)} onView={() => onViewCompanyDetails(item.id)} isImg={true} /> */}
                                                    {showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleDeactiveActionItems} onUpdate={() => { setshowHideAddEditContractor({ show: true, type: 'edit', id: item?.id }); setContractorinEdit(item) }} onDeactivate={() => setshowHideChangeStatusContractor({ show: true, id: item.id })} isImg={true} />}
                                                    {!showActive && <SelectDropdownAction headingData={ThreeDotsIcon} itemData={item} data={data.RoleActiveActionItems} onUpdate={() => { setshowHideAddEditContractor({ show: true, type: 'edit', id: item?.id }); setContractorinEdit(item) }} onActivate={() => setshowHideChangeStatusContractor({ show: true, id: item.id })} isImg={true} />}

                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}

                            </tbody>
                        </table>
                        {contractorListData?.length == 0 && <div className="row">
                            <div className="col-md-12">
                                <div className="text-center no-data">No Data Found</div>
                            </div>
                        </div>}
                    </div>
                        {contractorListData?.length > 0 && <ReactPaginate
                            forcePage={currentPage.current_page - 1}
                            pageCount={currentPage.total_count / 5}
                            marginPagesDisplayed={2}
                            onPageChange={(e) => pageChange(e.selected)}
                            containerClassName={'pagination-body'}
                            previousLabel={<div className="label-cover"><img src={BackwardPagination} /> Previous</div>}
                            nextLabel={<div className="label-cover">Next <img src={ForwardPagination} /></div>}
                            previousLinkClassName={'page'}
                            breakClassName={'page'}
                            nextLinkClassName={'page'}
                            pageClassName={'page'}
                            disabledClassName={'disabled'}
                            activeClassName={'active'} />}
                    </>
                </>

            </div>

            <Modal show={showHideChangeStatusContractor.show} dialogClassName="reportConfrmModal" onHide={() => setshowHideChangeStatusContractor({ show: false, id: '' })}>
                <Modal.Header className="sideOnRight" closeButton>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 mx-auto">
                    <div className="modal-body reportText">
                        Are you sure you would like to {showActive ? 'Deactivate' : 'Activate'} the Contractor?
                    </div>
                    <Modal.Footer>
                        <button type="button" className="btn-no" onClick={() => setshowHideChangeStatusContractor({ show: false, id: '' })}>No</button>
                        <button type="button" className="btn-yes" onClick={changeContractorStatus}>Yes</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <Modal show={showHideAddEditContractor.show} dialogClassName="addContractorModal" onHide={() => setshowHideAddEditContractor({ show: false, type: '', id: '' })}>
                <Modal.Header className="sideOnRight p-4" closeButton>
                    <Modal.Title>
                        {showHideAddEditContractor.type == 'add' ?
                            <>
                                <div className='head_2'>Add Contractor</div>
                                <div className='addModalText'>You can add more then one contractor at time</div>
                            </> :
                            <div className='head_2'>Update Contractor</div>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0 p-4 pt-0">
                    <form onSubmit={handleSubmit(onSubmitContractor)}>
                        {fields.map((contractor, index) => (
                            <div className='row mt-2' key={contractor.id}>
                                <div className='col-md-12'>
                                    <div className='inputFieldHead'>Name of the Contractor*</div>
                                    <div className='flex'>
                                        <input type='text'
                                            className='form-control'
                                            name="contractorName"
                                            placeholder='Type here'
                                            maxLength={50}
                                            {...register(`contractor[${index}].name`, {
                                                required: true
                                            })}
                                            control={control}
                                        />
                                        {(showHideAddEditContractor.type == 'add' && fields?.length > 1) && <span onClick={() => removeContractor(index)}><img className="editIcn cursor h-26 ml-20 mt-2" src={DeleteIcon} /></span>}
                                    </div>
                                    {errors.contractor?.[index]?.name && <p className="error"> <img src={errorIcon} /> This field is mandatory </p>}
                                </div>
                            </div>
                        ))}
                        {showHideAddEditContractor.type == 'add' && <button type="button" className='btn-add-data mr-15' onClick={appendContractor}>Add More</button>}
                        <Modal.Footer className='text-right'>
                            <button type="button" className="btn-no" onClick={() => setshowHideAddEditContractor({ show: false, type: '', id: '' })}>Cancel</button>
                            <button type="submit" className="btn-yes">{showHideAddEditContractor.type == 'add' ? 'Done' : 'Save'}</button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showHideUploadContainer.show} dialogClassName="uploadModal" onHide={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>
                <Modal.Header className="sideOnRight" closeButton>
                    <div className='inputFieldHead'>Upload File</div>
                </Modal.Header>
                <Modal.Body className="auth_modal_header border-0">
                    {showUpload && <FileUploader onFileUpload={onFileChange} />}
                    {!showUpload && showProcessing && <UploadContainer type='imageProcessing' >
                        <ChangingProgressProvider values={[0, 25, 50, 75, 100]}>
                            {percentage => (
                                <CircularProgressbar value={percentage} text={`${percentage}%`} />
                            )}
                        </ChangingProgressProvider>
                    </UploadContainer>}

                    {!showUpload && showFileContainer && <UploadContainer type='uploadedImage' >
                        <img src={DownloadCompleteImg} />
                    </UploadContainer>}
                    <div className="modal-footer">
                        <button type="button" className="btn-cancel-popup" onClick={() => { setShowHideUploadContainer({ show: false }); setShowUpload(true); setShowFileContainer(false); setShowProcessing(false); setFile('') }}>Cancel</button>
                        <button type="button" className="btn-upload border-0" onClick={handleFileUpload} disabled={fileData == '' || showProcessing}>Upload</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ContractorManagement;